import { QueryTuple } from "@apollo/client";
import { saveAs } from "file-saver";
import {
  Exact,
  InventoryItem,
  ListPurchaseOrdersDocument,
  ListPurchaseOrdersFilters,
  ListPurchaseOrdersQuery,
  ListPurchaseOrdersQueryVariables,
  PageInfo,
  Pagination,
  PurchaseOrder,
  PurchaseOrderStatus,
  useCreatePurchaseOrderMutation,
  useDeletePurchaseOrderMutation,
  Vendor,
} from "generated/graphql";
import { omit } from "lodash";
import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo } from "react";
import { useSessionStorage } from "react-use";
import { useImmerReducer } from "use-immer";
import { pascalize, toDate } from "utils/formatters";
import { generatePDF } from "./pdf";
import { generatePdfLabels } from "utils/labels";

const LABEL_TITLE = "PURCHASE ORDER";

export interface IFilters {
  vendors?: Pick<Vendor, "id" | "name">[];
  inventoryItems?: Pick<InventoryItem, "id" | "name">[];
  status?: PurchaseOrderStatus[];
}

interface StateIFace {
  filters: IFilters;
  pagination: PageInfo;
  showFilters: boolean;
  selected: Set<React.Key>;
  isCreating: boolean;
  isEditing: PurchaseOrder;
  isDeleting: PurchaseOrder;
}

type SetPagination = {
  type: Actions.SetPagination;
  data: { perPage: number; page: number };
};

type SetSelected = {
  type: Actions.SetSelected;
  data: Set<React.Key>;
};

type SetShowFilters = {
  type: Actions.SetShowFilters;
  data: boolean;
};

type SetFilters = {
  type: Actions.SetFilters;
  data: IFilters;
};

type SetIsCreating = {
  type: Actions.SetIsCreating;
  data: boolean;
};

type SetIsEditing = {
  type: Actions.SetIsEditing;
  data: PurchaseOrder;
};

type SetIsDeleting = {
  type: Actions.SetIsDeleting;
  data: PurchaseOrder;
};

type DispatcherAction =
  | SetShowFilters
  | SetSelected
  | SetPagination
  | SetFilters
  | SetIsCreating
  | SetIsEditing
  | SetIsDeleting;

enum Actions {
  SetFilters = "SET_FILTERS",
  SetShowFilters = "SET_SHOW_FILTERS",
  SetPagination = "SET_PAGINATION",
  SetSelected = "SET_SELECTED",
  SetIsAdding = "SET_IS",
  SetIsCreating = "SET_IS_CREATING",
  SetIsEditing = "SET_IS_EDITING",
  SetIsDeleting = "SET_IS_DELETING",
}

const initialState: StateIFace = {
  filters: null,
  showFilters: false,
  selected: new Set<React.Key>(),
  pagination: { perPage: 10, page: 1 },
  isCreating: false,
  isEditing: null,
  isDeleting: null,
};

const ctx = createContext<{
  query: QueryTuple<
    ListPurchaseOrdersQuery,
    Exact<{
      pagination?: Pagination;
      filters?: ListPurchaseOrdersFilters;
    }>
  >;
  state: StateIFace;
  dispatch: React.Dispatch<DispatcherAction>;
}>({ query: null, state: initialState, dispatch: () => null });

type ITableProvider<QT, FT> = PropsWithChildren<{
  query: QueryTuple<
    QT,
    Exact<{
      pagination?: Pagination;
      filters?: FT;
    }>
  >;
  sessionKey: string;
}>;

export const TableProvider = <QT, FT>({ children, sessionKey, query }: ITableProvider<QT, FT>) => {
  const [filters, setFilters] = useSessionStorage<{
    filters: IFilters;
    pagination: PageInfo;
  }>(sessionKey, {
    filters: initialState.filters,
    pagination: initialState.pagination,
  });

  const [state, dispatch] = useImmerReducer(
    (state: StateIFace, action: DispatcherAction) => {
      switch (action.type) {
        case Actions.SetPagination:
          state.pagination = action.data;
          break;

        case Actions.SetSelected:
          state.selected = action.data;
          break;

        case Actions.SetShowFilters:
          state.showFilters = action.data;
          break;

        case Actions.SetFilters:
          state.filters = action.data;
          break;

        case Actions.SetIsCreating:
          state.isCreating = action.data;
          break;

        case Actions.SetIsEditing:
          state.isEditing = action.data;
          break;

        case Actions.SetIsDeleting:
          state.isDeleting = action.data;
          break;

        default:
          throw new Error("problem with TableProvider");
      }
    },
    { ...initialState, ...filters }
  );

  useEffect(() => {
    setFilters({
      filters: state.filters,
      pagination: state.pagination,
    });
  }, [state, setFilters]);

  return (
    <ctx.Provider
      value={{
        query,
        state,
        dispatch,
      }}
    >
      {children}
    </ctx.Provider>
  );
};

export const useTable = () => {
  const { query, state, dispatch } = useContext(ctx);
  const [fetch, { data, loading, variables: qvariables }] = query;

  const mutationOpts = {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ListPurchaseOrdersDocument, variables: qvariables }],
  };

  const [deletePurchaseOrder] = useDeletePurchaseOrderMutation(mutationOpts);
  const [createPurchaseOrder] = useCreatePurchaseOrderMutation(mutationOpts);

  const setPagination = useCallback(
    (pagination: Pagination) => {
      const isChangingPerPage = !!(pagination?.perPage && pagination?.perPage !== state?.pagination?.perPage);
      const page = isChangingPerPage ? 1 : pagination?.page || state?.pagination?.page;
      const perPage = pagination?.perPage || state?.pagination?.perPage || 10;
      dispatch({
        type: Actions.SetPagination,
        data: {
          page,
          perPage,
        },
      });
    },
    [dispatch, state.pagination]
  );

  const setselected = useCallback(
    (rows: Set<React.Key>) => dispatch({ type: Actions.SetSelected, data: rows }),
    [dispatch]
  );

  const setShowFilters = (open: boolean) => {
    dispatch({ type: Actions.SetShowFilters, data: open });
  };

  const setFilters = (filters: IFilters) => {
    dispatch({ type: Actions.SetFilters, data: filters });
  };

  const removeFilter = (property: string, i?: number) => {
    setFilters(omit(state.filters, [property]));
  };

  const resetFilters = () => {
    dispatch({
      type: Actions.SetFilters,
      data: {},
    });
  };

  const setIsCreating = (isCreating: boolean) => {
    dispatch({ type: Actions.SetIsCreating, data: isCreating });
  };

  const setIsEditing = (purchaseOrder: PurchaseOrder) => {
    dispatch({ type: Actions.SetIsEditing, data: purchaseOrder });
  };

  const setIsDeleting = (purchaseOrder: PurchaseOrder) => {
    dispatch({ type: Actions.SetIsDeleting, data: purchaseOrder });
  };

  const variables: ListPurchaseOrdersQueryVariables = useMemo(
    () => ({
      filters: {
        vendorIds: state?.filters?.vendors?.map((v) => v.id) ?? [],
        inventoryItemIds: state?.filters?.inventoryItems?.map((i) => i.id) ?? [],
        status: state?.filters?.status ?? [],
      },
      pagination: state.pagination,
    }),

    [state.pagination, state.filters]
  );

  const selectedRows = useMemo(() => {
    return (
      data?.listPurchaseOrders?.purchaseOrders?.filter((purchaseOrder) => {
        return state.selected.has(purchaseOrder.id);
      }) ?? []
    );
  }, [data?.listPurchaseOrders?.purchaseOrders, state.selected]);

  return {
    ...state,
    data,
    loading,
    variables,
    selectedRows,
    setIsCreating,
    setIsEditing,
    setIsDeleting,
    setPagination,
    setselected,
    setShowFilters,
    setFilters,
    removeFilter,
    resetFilters,
    fetch,
    deletePurchaseOrder,
    createPurchaseOrder,
  };
};

export type RowActions =
  | "printLabel"
  | "setPrimary"
  | "generateLabels"
  | "deletePurchaseOrder"
  | "deletePurchaseOrders"
  | "editPurchaseOrder"
  | "generatePDF";

export const useRowActions = () => {
  const { selectedRows, setIsDeleting, setIsEditing } = useTable();

  const downloadPDF = async (po: PurchaseOrder) => {
    const url = await generatePDF({
      purchaseOrder: po,
      vendorContactName: `${po?.vendor?.contactFirstName} ${po?.vendor?.contactLastName}`,
      vendorCompanyName: po?.vendor?.name,
      vendorAddress: `${po?.vendor?.address1}\n${po?.vendor?.address2}`,
      vendorPhoneNumber: po?.vendor?.companyPhone,
      vendorEmail: po?.vendor?.contactEmail,
      companyName: po?.companyName,
      location: po?.shippingLocation,
      shippingDeliveryDate: toDate(po?.shippingDeliveryDate)?.toLocaleDateString(),
      shippingTerms: po?.shippingTerms,
      shippingMethod: po?.shippingMethod,
      priceDiscountPercent: po?.priceDiscountPercent,
      priceSalesTaxPercent: po?.priceSalesTaxPercent,
      priceOther: po?.priceOtherCost,
      shippingAndHandling: po?.shippingAndHandling,
      note: po?.note,
    });

    saveAs(url, `po#${po.number}_${pascalize(po?.vendor?.name ?? "vendor")}.pdf`);
  };

  const handleAction = (action: RowActions, row: PurchaseOrder) => {
    switch (action) {
      case "printLabel":
        generatePdfLabels(LABEL_TITLE, [row]);
        break;

      case "generateLabels":
        generatePdfLabels(LABEL_TITLE, selectedRows);
        break;

      case "editPurchaseOrder":
        setIsEditing(row);
        break;

      case "generatePDF":
        downloadPDF(row);

        break;

      case "deletePurchaseOrder":
        setIsDeleting(row);
        break;

      case "deletePurchaseOrders":
        break;

      default:
        break;
    }
  };

  return handleAction;
};
