import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { ButtonWithIcon } from "components/Buttons";
import { CenterModal } from "components/Modals/CenterModal";
import { SideModal } from "components/Modals/SideModal";
import TitleWithLoading from "components/TitleWithLoading";
import { ListStepsDocument, useDeleteStepsMutation, useListStepsLazyQuery } from "generated/graphql";
import { useToast } from "hooks/toast";
import React from "react";
import { P, PlainButton, RedButton } from "styles";
import AddStep from "./AddStep";
import { TableProvider, useTable } from "./context";
import EditStep from "./EditStep";
import { Filters, FiltersHeader } from "./Filters";
import Table from "./Table";

const DeleteSteps = () => {
  const toast = useToast();
  const { variables, isDeleting, setIsDeleting } = useTable();
  const [deleteSteps] = useDeleteStepsMutation({
    refetchQueries: [{ query: ListStepsDocument, variables }],
  });

  return (
    <>
      <P className="text-center my-8">
        {isDeleting?.length > 1 && `Are you sure you want to delete ${isDeleting?.length} steps?`}
        {isDeleting?.length === 1 && `Are you sure you want to delete step "${isDeleting?.[0]?.name}"?`}
      </P>

      <div className="grid grid-cols-2 gap-x-2">
        <PlainButton onClick={() => setIsDeleting(null)}>Cancel</PlainButton>
        <RedButton
          onClick={async () => {
            try {
              await deleteSteps({
                variables: { ids: isDeleting?.map((step) => step.id) },
              });

              setIsDeleting(null);

              toast.success(`${isDeleting?.length} steps removed`);
            } catch (err) {
              toast.error(err);
            }
          }}
        >
          Delete
        </RedButton>
      </div>
    </>
  );
};

const Steps = () => {
  const {
    setIsEditing,
    isEditing,
    isDeleting,
    setShowFilters,
    setIsCreating,
    setIsDeleting,
    showFilters,
    isCreating,
    loading,
    fetch,
    variables,
  } = useTable();
  return (
    <>
      <div className="py-2 px-2 flex-1 flex flex-col overflow-hidden">
        <div className="flex justify-between my-2">
          <TitleWithLoading title="Steps" loading={loading} refresh={() => fetch({ variables })} />

          <ButtonWithIcon className="bg-brand" icon={faPlusCircle} onClick={() => setIsCreating(true)}>
            Add Step
          </ButtonWithIcon>
        </div>
        <FiltersHeader />
        <Table />
      </div>
      <SideModal
        side="RIGHT"
        isOpen={isEditing !== null}
        onRequestClose={() => setIsEditing(null)}
        className="w-2/6 bg-white dark:bg-gray-800 p-4 flex flex-col"
      >
        <EditStep />
      </SideModal>{" "}
      <SideModal
        side="RIGHT"
        isOpen={isCreating}
        onRequestClose={() => setIsCreating(false)}
        className="w-1/4 bg-white dark:bg-gray-800 p-4"
      >
        <AddStep />
      </SideModal>
      <SideModal side="RIGHT" isOpen={showFilters} onRequestClose={() => setShowFilters(false)} className="w-1/4">
        <Filters />
      </SideModal>
      <CenterModal
        isOpen={isDeleting !== null}
        onRequestClose={() => setIsDeleting(null)}
        className="p-4 bg-white dark:bg-gray-800 rounded"
      >
        <DeleteSteps />
      </CenterModal>
    </>
  );
};

const Container = () => {
  const query = useListStepsLazyQuery();
  return (
    <TableProvider query={query} sessionKey="@factoryfinch/steps">
      <Steps />
    </TableProvider>
  );
};

export { Container as default };
