import React, { useMemo } from "react";
import { UserRole } from "generated/graphql";
import BaseBadge from "./Base";

export const UserRoleBadge: React.FC<{
  role: UserRole;
}> = ({ role }) => {
  const props = useMemo(() => {
    switch (role) {
      case UserRole.Owner:
        return {
          text: "Owner",
          style: "bg-transparent border border-green-400 dark:border-green-400",
          textStyle: "font-semibold border-green-400 text-xs text-center",
        };

      case UserRole.Admin:
        return {
          text: "Admin",
          style: "bg-transparent border border-green-400 dark:border-green-400",
          textStyle: "font-semibold border-green-400 text-xs text-center",
        };

      case UserRole.Employee:
        return {
          text: "Employee",
          style: "bg-transparent border border-brand dark:border-brand",
          textStyle: "font-semibold text-brand dark:text-brand text-xs text-center",
        };

      default:
        return {
          text: "Unknown",
          style: "bg-gray-400",
          textStyle: "font-semibold text-white text-xs text-center",
        };
    }
  }, [role]);

  return <BaseBadge {...props} />;
};
