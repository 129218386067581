import React from "react";
import TextInput from "components/Form/TextInput";
import NumberInput from "components/Form/NumberInput";
import { NumberFormatValues } from "react-number-format";
import { useUpdateEmployeeMutation, UpdateEmployeeInput, ListEmployeesDocument } from "generated/graphql";
import { useToast } from "hooks/toast";
import { useEmployeesTable } from "./context";
import { useForm, Controller } from "react-hook-form";
import { ScrollableForm } from "components/Form/ScrollableForm";
import { PhoneInput } from "components/Form/PhoneInput";

const EditEmployee: React.FC = () => {
  const toast = useToast();
  const { isEditing, setIsEditing, variables } = useEmployeesTable();
  const [updateEmployee] = useUpdateEmployeeMutation({
    refetchQueries: [{ query: ListEmployeesDocument, variables }],
  });
  const { register, handleSubmit, control, formState } = useForm<UpdateEmployeeInput>({
    defaultValues: {
      ...isEditing,
    },
  });

  const submit = async (formData: UpdateEmployeeInput) => {
    try {
      await updateEmployee({
        variables: {
          input: {
            id: isEditing?.id,
            name: formData?.name,
            phoneNumber: formData?.phoneNumber,
            email: formData?.email,
            code: formData?.code,
          },
        },
      });

      setIsEditing(null);
    } catch (err) {
      console.log(err);
      toast.error("Unable to add Access Code");
    }
  };

  return (
    <ScrollableForm
      title="Edit Employee"
      onSubmit={handleSubmit(submit)}
      onCancel={() => setIsEditing(null)}
      submitLabel="Save"
      formState={formState}
    >
      <TextInput className="my-2" placeholder="Name" {...register("name")} />
      <Controller
        control={control}
        name="code"
        render={({ field }) => (
          <NumberInput
            className="my-2"
            format="###"
            placeholder="Code"
            name={field.name}
            value={field.value}
            onValueChange={(value: NumberFormatValues) => field.onChange(value.value)}
          />
        )}
      />
      <Controller
        control={control}
        name="phoneNumber"
        render={({ field }) => <PhoneInput value={field.value} onChange={field.onChange} className="my-2" />}
      />
      <TextInput className="my-2" placeholder="Email" {...register("email")} />
    </ScrollableForm>
  );
};

export { EditEmployee as default };
