import React from "react";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { ButtonWithIcon } from "components/Buttons";
import Checkbox from "components/Form/Checkbox";
import { Confirmation } from "components/Modals/Confirmation";
import TitleWithLoading from "components/TitleWithLoading";
import { ListWorkflowsDocument, useDeleteWorkflowsMutation, useListWorkflowsLazyQuery } from "generated/graphql";
import { useToast } from "hooks/toast";
import { Link } from "react-router-dom";
import { Routes } from "routes";
import { H4, HR } from "styles";
import { TableProvider, useWorkflowsTable } from "./context";
import Table from "./Table";

const Workflows = () => {
  const { loading, setIsDeleting, isDeleting, setFilters, filters, variables, fetch } = useWorkflowsTable();
  const [deleteWorkflows] = useDeleteWorkflowsMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ListWorkflowsDocument, variables }],
  });
  const toast = useToast();
  return (
    <div className="py-2 px-2 flex-1 flex flex-col overflow-hidden">
      <div className="flex justify-between my-2">
        <TitleWithLoading title="Workflows" loading={loading} refresh={() => fetch({ variables })} />

        <Link to={Routes.CreateWorkflow} data-testid="create-workflow-link">
          <ButtonWithIcon className="bg-brand" icon={faPlusCircle}>
            Create Workflow
          </ButtonWithIcon>
        </Link>
      </div>
      <div>
        <Checkbox
          onChange={(e) => setFilters({ showDeleted: e.checked })}
          label="Show Deleted"
          checked={filters?.showDeleted}
        />
      </div>
      <Table />
      <Confirmation
        loading={loading}
        isOpen={isDeleting?.length > 0}
        onRequestClose={() => setIsDeleting(null)}
        onConfirm={async () => {
          try {
            await deleteWorkflows({
              variables: {
                ids: isDeleting?.map((workflow) => workflow?.id),
              },
            });
          } catch (err) {
            console.error(err);
            toast.error("Unable to delete products");
          } finally {
            setIsDeleting(null);
          }
        }}
      >
        <div className="my-4">
          <H4 className="py-4">Delete these workflows?</H4>
          <HR />

          {isDeleting?.map((workflow) => {
            return <div key={workflow.id}>{workflow.name}</div>;
          })}
        </div>
      </Confirmation>
    </div>
  );
};

const Container = () => {
  const query = useListWorkflowsLazyQuery({
    fetchPolicy: "network-only",
  });

  return (
    <TableProvider query={query} sessionKey="@factoryfinch/workflows">
      <Workflows />
    </TableProvider>
  );
};

export { Container as default };
