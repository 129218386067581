import React from "react";
import cx from "classnames";

const BaseBadge: React.FC<{
  style: string;
  textStyle: string;
  text: string;
}> = ({ style, textStyle, text }) => {
  return (
    <div
      className={cx(
        "py-1 px-2 rounded-full flex flex-row justify-center items-center truncate",
        style
      )}
    >
      <div className={cx(textStyle, "truncate")}>{text}</div>
    </div>
  );
};

export { BaseBadge as default };
