import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import styled from "styled-components";
import tw from "twin.macro";

interface CheckboxProps {
  className?: string;
  disabled?: boolean;
  checked?: boolean;
  label?: string;
  icon?: IconDefinition;

  checkedIcon?: IconDefinition;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onChange?: (e?: React.MouseEvent<HTMLButtonElement> & { checked: boolean }) => void;
  ["data-testid"]?: string;
}

const CheckboxWrapper = styled.button`
  ${tw`focus:outline-none select-none px-2 py-1 flex items-center`}

  &:disabled {
    ${tw`text-gray-500`}
  }
`;

const Checkbox: React.FC<CheckboxProps> = ({
  className = "",
  disabled = false,
  checked = false,
  onChange = () => {},
  label = "",
  icon = faSquare,
  checkedIcon = faCheckSquare,
  ...rest
}) => {
  return (
    <CheckboxWrapper
      data-testid={rest["data-testid"]}
      type="button"
      disabled={disabled}
      className={className}
      onClick={(e) => onChange({ ...e, checked: !checked })}
    >
      <FontAwesomeIcon icon={checked ? checkedIcon : icon} />
      {label && <div className="ml-2 text-left">{label}</div>}
    </CheckboxWrapper>
  );
};

export { Checkbox as default };
