import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InventoryTypeBadge from "components/Badges/InventoryItemType";
import Pagination from "components/Pagination";
import StyledTable from "components/Table";
import { EditColumns } from "components/Table/EditColumns";
import { EmptyRowsRenderer, FormatterWithCopy, LoadingRenderer, RowRenderer } from "components/Table/Renderers";
import { LocalStorageKeys } from "config";
import { LocationInventory } from "generated/graphql";
import {
  OptionalColumnsProvider,
  useColumns,
  ITableColumnDefinitions,
  buildDynamicColumn,
} from "hooks/useOptionalColumns";
import { sortBy } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Column,
  DataGridProps,
  FormatterProps,
  HeaderRendererProps,
  RowRendererProps,
  SortDirection,
} from "react-data-grid";
import { currency } from "utils/formatters";
import { useTable } from "./context";
import { MENU_ID, ContextMenu } from "./ContextMenu";
import { BooleanEditor, CurrencyEditor, NumberEditor } from "./Editors";
import { TableHeader } from "./TableHeader";

const columnDefinitions: ITableColumnDefinitions<LocationInventory> = {
  required: [
    {
      headerRenderer: (props: HeaderRendererProps<LocationInventory>) => {
        return (
          <div
            className="flex justify-center items-center h-full w-full text-brand cursor-pointer text-lg py-2"
            onClick={(e) => props.onAllRowsSelectionChange(!props?.allRowsSelected)}
          >
            <FontAwesomeIcon icon={props?.allRowsSelected ? faCheckSquare : faSquare} />
          </div>
        );
      },
      formatter: (props: FormatterProps) => {
        return (
          <div
            className="flex justify-center items-center h-full w-full text-brand cursor-pointer text-lg py-2 border-none"
            onClick={(e) => props.onRowSelectionChange(!props?.isRowSelected, e.shiftKey)}
          >
            <FontAwesomeIcon icon={props?.isRowSelected ? faCheckSquare : faSquare} />
          </div>
        );
      },

      key: "select-row",
      name: "",
      resizable: false,
      sortable: false,
      maxWidth: 35,
      width: 35,
    },
    {
      key: "name",
      name: "Name",
      resizable: true,

      width: 300,

      formatter: (props) => {
        const name = props?.row?.inventoryItem?.name;
        return (
          <FormatterWithCopy
            {...props}
            getValue={(r) => r?.inventoryItem?.name}
            render={(row) => (
              <div title={name} className={`truncate ${row?.inventoryItem?.deletedAt ? "text-gray-500" : ""}`}>
                {name}
              </div>
            )}
          />
        );
      },
    },
  ],
  optional: {
    type: {
      key: "type",
      name: "Type",
      minWidth: 100,
      sortable: true,
      resizable: true,
      headerCellClass: "text-center",
      formatter: (props) => {
        return (
          <div className="h-full flex flex-row items-center justify-center ">
            <InventoryTypeBadge type={props?.row?.inventoryItem?.type} />
          </div>
        );
      },
    },
    sku: {
      key: "sku",
      name: "SKU",
      minWidth: 100,
      resizable: true,
      formatter: (props) => <FormatterWithCopy {...props} getValue={(r) => r?.inventoryItem?.sku} />,
    },
    location: {
      key: "location",
      name: "Location",
      minWidth: 100,
      resizable: true,
      formatter: (props) => {
        return <div>{props?.row?.location?.name}</div>;
      },
    },
    units: {
      key: "units",
      name: "Units",
      minWidth: 100,
      resizable: true,
      formatter: (props) => {
        return <div>{props?.row?.inventoryItem?.units}</div>;
      },
    },
    valuePerUnit: {
      key: "valuePerUnit",
      name: "Value per unit",
      minWidth: 100,
      resizable: true,
      cellClass: "text-center",
      editor: (props) => (
        <CurrencyEditor
          {...props}
          getValue={(row) => row?.inventoryItem?.valuePerUnit}
          setValue={(row, valuePerUnit) => ({ ...row, inventoryItem: { ...row.inventoryItem, valuePerUnit } })}
        />
      ),
      editorOptions: {
        editOnClick: true,
      },
      formatter: (props) => {
        return <div>{currency(props?.row?.inventoryItem?.valuePerUnit)}</div>;
      },
    },
    onHand: {
      key: "onHand",
      name: "On Hand",
      minWidth: 100,
      resizable: true,
      cellClass: "text-center",
      headerCellClass: "text-center",
      editor: NumberEditor,
      editorOptions: {
        editOnClick: true,
      },
    },

    reOrderAt: {
      key: "reOrderAt",
      name: "Re-Order",
      minWidth: 100,
      resizable: true,
      cellClass: "text-center",
      headerCellClass: "text-center",
      editor: NumberEditor,
      editorOptions: {
        editOnClick: true,
      },
      formatter: (props) => {
        const value = props?.row?.reOrderAt ?? 0;
        const onHand = value % 1 === 0 ? value.toFixed(0) : value.toFixed(2);

        return <div>{onHand}</div>;
      },
    },
    ingredients: {
      key: "ingredients",
      name: "Ingredients",
      minWidth: 100,
      resizable: true,
      cellClass: "text-center",
      headerCellClass: "text-center",
      formatter: (props) => <div>{props?.row?.inventoryItem?.ingredients?.length ?? 0}</div>,
    },
    requiresStockTake: {
      key: "requiresStockTake",
      name: "Stock Take?",
      minWidth: 100,
      resizable: true,
      cellClass: "text-center",
      headerCellClass: "text-center",
      editor: BooleanEditor,
      editorOptions: {
        editOnClick: true,
      },
      formatter: (props) => {
        return <FontAwesomeIcon icon={props?.row?.requiresStockTake ? faCheckSquare : faSquare} />;
      },
    },
  },
};

const Table = () => {
  const { setPagination, pagination, listInventory, data, loading, setSelected, selected, variables } = useTable();
  const { keys, columns } = useColumns();

  useEffect(() => {
    listInventory({
      variables,
    });
  }, [listInventory, variables]);

  const [rows, setRows] = useState(data?.listLocationInventory?.inventory ?? []);
  const [[sortColumn, sortDirection], setSort] = useState<[string, SortDirection]>(["type", "NONE"]);

  const sortedRows = useMemo(() => {
    if (sortDirection === "NONE") return rows;

    let sortedRows = [...rows];

    switch (sortColumn) {
      case "type":
        sortedRows = sortBy(sortedRows, ["type"]);

        break;

      default:
        break;
    }

    return sortDirection === "DESC" ? sortedRows.reverse() : sortedRows;
  }, [rows, sortDirection, sortColumn]);

  const handleSort = useCallback((columnKey: string, direction: SortDirection) => {
    setSort([columnKey, direction]);
  }, []);

  const c = useMemo<Column<LocationInventory, unknown>[]>(() => {
    const opt = Array.from(keys)
      .filter((k) => columnDefinitions.optional?.[k] && !columns?.[k]?.hidden)
      .map((k) => columnDefinitions.optional?.[k]);
    const dynamic = Array.from(keys)
      .filter((k) => !columnDefinitions.optional?.[k] && !columns?.[k]?.hidden)
      .map((k) => columns[k]);

    return [
      ...columnDefinitions.required,
      ...opt,
      ...dynamic.map((col, idx) =>
        buildDynamicColumn(col, idx, (props: React.PropsWithChildren<FormatterProps<LocationInventory, unknown>>) => {
          const t = props?.row?.inventoryItem?.tags.find((t) => t?.tagId === col?.key);
          return <div className="truncate">{t?.value ?? ""}</div>;
        })
      ),
    ];
  }, [keys, columns]);

  useEffect(() => {
    setRows(data?.listLocationInventory?.inventory ?? []);
  }, [data?.listLocationInventory?.inventory]);

  return (
    <>
      <TableHeader />

      <div style={{ flex: "1 1 auto" }} className="overflow-auto">
        <StyledTable<React.FC<DataGridProps<LocationInventory>>>
          rowHeight={56}
          rowKeyGetter={(r: LocationInventory) => r?.id}
          rowRenderer={(props: RowRendererProps<LocationInventory>) => (
            <RowRenderer contextMenuId={MENU_ID} {...props} />
          )}
          onRowsChange={(rows: LocationInventory[]) => setRows(rows)}
          columns={c}
          onSort={handleSort}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          rows={sortedRows}
          selectedRows={selected}
          onSelectedRowsChange={setSelected}
          emptyRowsRenderer={() =>
            loading ? (
              <LoadingRenderer message="Fetching inventory" />
            ) : (
              <EmptyRowsRenderer message="No inventory items found" />
            )
          }
        />
      </div>
      <Pagination
        loading={loading}
        currentPage={pagination?.page ?? 1}
        totalPages={data?.listLocationInventory?.pageInfo?.totalPages}
        canNextPage={pagination?.page < data?.listLocationInventory?.pageInfo?.totalPages}
        canPreviousPage={data?.listLocationInventory?.pageInfo?.page > 1 ?? false}
        setPagination={setPagination}
      />
      <ContextMenu />
      <EditColumns<LocationInventory> optionalColumns={columnDefinitions.optional} />
    </>
  );
};

const Container = () => {
  return (
    <OptionalColumnsProvider
      storageKey={LocalStorageKeys.InventoryTableColumns}
      keys={[
        "sku",
        "type",
        "units",
        "valuePerUnit",
        "onHand",
        "reOrderAt",
        "ingredients",
        "requiresStockTake",
        "location",
      ]}
    >
      <Table />
    </OptionalColumnsProvider>
  );
};
export { Container as default };
