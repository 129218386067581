import React from "react";
import { Document, Image, Page, StyleSheet, Text, View, Font } from "@react-pdf/renderer";

Font.register({ family: "Lora", src: "/fonts/Lora-Regular.ttf", fontStyle: "normal", fontWeight: "normal" });

const styles = StyleSheet.create({
  page: {
    fontFamily: "Lora",
    fontSize: 11,
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  qrCodeContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  qrCode: {
    width: 200,
    height: 200,
  },
  title: {
    fontSize: 10,
  },
  line1: {
    fontSize: 20,
  },
  line2: {
    fontSize: 20,
  },
  line3: {
    fontSize: 20,
  },
});

export interface ILabelData {
  title: string;
  qrcode: string;
  line1: string;
  line2: string;
  line3: string;
}

const mmToPixels = (mm: number, ppi = 72) => mm * (ppi / 25.4);

const LabelSizes = {
  ThermalLabel4x6: { width: mmToPixels(101.6), height: mmToPixels(152.4) },
};
export const Labels = ({ labels }: { labels: ILabelData[] }) => (
  <Document>
    {labels?.map((label) => {
      return (
        <Page orientation="portrait" size={LabelSizes.ThermalLabel4x6} style={styles.page} wrap>
          <View>
            <Text style={styles.title}>{label?.title}</Text>
            <View style={styles.qrCodeContainer}>
              <Image style={styles.qrCode} source={label?.qrcode} />
            </View>
            <Text style={styles.line1}>{label?.line1}</Text>
            <Text style={styles.line2}>{label?.line2}</Text>
            <Text style={styles.line3}>{label?.line3}</Text>
          </View>
        </Page>
      );
    })}
  </Document>
);
