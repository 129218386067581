import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

type ITextInputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  label?: string;
};

const StyledInput = styled.input`
  ${tw`bg-white dark:bg-gray-800 rounded py-2 px-4 block w-full appearance-none leading-normal border border-gray-300 dark:border-gray-400 focus:border-blue-400 focus:outline-none focus:shadow-none text-lg`}

  &.error {
    ${tw`border border-brand`}
  }
  &::placeholder {
    ${tw`text-gray-400 dark:text-gray-600 text-lg`}
  }
  /* &:focus {
    ${tw`outline-none shadow-none`}
  } */

  &:disabled {
    ${tw`opacity-75 cursor-not-allowed text-gray-400 dark:text-gray-100`}
  }

  &:read-only {
    ${tw`opacity-75 cursor-not-allowed text-gray-400 dark:text-gray-100`}
  }
`;

const TextInput = React.forwardRef<HTMLInputElement, ITextInputProps>(
  ({ label, className, ...rest }: ITextInputProps, ref) => {
    if (!label) {
      return <StyledInput id={rest.name} {...rest} ref={ref} />;
    }

    return (
      <div className={className}>
        <label className="text-xs" htmlFor={rest.name}>
          {label}
        </label>
        <StyledInput id={rest.name} {...rest} ref={ref} />
      </div>
    );
  }
);

export { TextInput as default };
