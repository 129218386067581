import { Location, useListLocationsQuery } from "generated/graphql";
import { debounce } from "lodash";
import React, { useCallback, useState } from "react";
import { AsyncSelect, Tooltip } from "styles";
import { OptionProps, components } from "react-select";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const CustomOption = (props: OptionProps<Location, boolean>) => (
  <components.Option {...props}>
    <div className="flex flex-row items-center justify-between">
      <div>{props?.data?.name}</div>
      {props?.data?.primary && (
        <Tooltip title="This is your primary location" arrow>
          <span>
            <FontAwesomeIcon icon={faStar} />
          </span>
        </Tooltip>
      )}
    </div>
  </components.Option>
);

export const SelectLocation: React.FC<{
  disabled?: boolean;
  name?: string;
  className?: string;
  value?: Location;
  onChange: (value: Location | Location[]) => void;
  size?: "base" | "large";
  placeholder?: string;
  isMulti?: boolean;
  label?: string;
}> = ({
  isMulti = false,
  disabled = false,
  value,
  onChange,
  className = "",
  name = "",
  size = "base",
  placeholder = "Select Location...",
  label,
}) => {
  const { data, refetch, loading } = useListLocationsQuery();
  const [inputValue, setinputValue] = useState("");

  const handleInputChange = (newValue: string) => {
    setinputValue(newValue);
  };

  const fetchData = async (inputValue: string, callback: (items: Location[]) => void) => {
    const { data } = await refetch({ filters: { like: inputValue } });

    callback(data?.listLocations?.locations);
  };

  // eslint-disable-next-line
  const delayedQuery = useCallback(
    debounce((inputValue, callback) => fetchData(inputValue, callback), 500),
    [inputValue]
  );

  return (
    <div className={className}>
      {label && (
        <label htmlFor={name} className="text-xs">
          {label}
        </label>
      )}

      <AsyncSelect
        isMulti={isMulti}
        isDisabled={disabled}
        isClearable
        isLoading={loading}
        $textSize={size}
        placeholder={placeholder}
        name={name}
        cacheOptions
        value={value}
        loadOptions={delayedQuery}
        defaultOptions={data?.listLocations?.locations ?? []}
        onInputChange={handleInputChange}
        onChange={onChange}
        getOptionLabel={(opt: Location) => opt?.name}
        getOptionValue={(opt: Location) => opt?.id}
        components={{ Option: CustomOption }}
      />
    </div>
  );
};

export const SelectLocations: React.FC<{
  disabled?: boolean;
  name?: string;
  className?: string;
  value?: Location[];
  onChange: (value: Location[]) => void;
  size?: "base" | "large";
  placeholder?: string;

  label?: string;
}> = ({
  disabled = false,
  value,
  onChange,
  className = "",
  name = "",
  size = "base",
  placeholder = "Select Locations...",
  label,
}) => {
  const { data, refetch, loading } = useListLocationsQuery();
  const [inputValue, setinputValue] = useState("");

  const handleInputChange = (newValue: string) => {
    setinputValue(newValue);
  };

  const fetchData = async (inputValue: string, callback: (items: Location[]) => void) => {
    const { data } = await refetch({ filters: { like: inputValue } });

    callback(data?.listLocations?.locations);
  };

  // eslint-disable-next-line
  const delayedQuery = useCallback(
    debounce((inputValue, callback) => fetchData(inputValue, callback), 500),
    [inputValue]
  );

  return (
    <div className={className}>
      {label && (
        <label htmlFor={name} className="text-xs">
          {label}
        </label>
      )}

      <AsyncSelect
        isMulti
        isDisabled={disabled}
        isClearable
        isLoading={loading}
        $textSize={size}
        placeholder={placeholder}
        name={name}
        cacheOptions
        value={value}
        loadOptions={delayedQuery}
        defaultOptions={data?.listLocations?.locations ?? []}
        onInputChange={handleInputChange}
        onChange={onChange}
        getOptionLabel={(opt: Location) => opt?.name}
        getOptionValue={(opt: Location) => opt?.id}
        components={{ Option: CustomOption }}
      />
    </div>
  );
};
