import React from "react";
import Popover from "@material-ui/core/Popover";

const Hover: React.FC<
  React.PropsWithChildren<{ render: React.ReactNode; id: string }>
> = ({ children, render, id }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <div onMouseEnter={handlePopoverOpen}>{children}</div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div onMouseLeave={handlePopoverClose}>{render}</div>
      </Popover>
    </>
  );
};

export { Hover as default };
