import IconButtonMenu from "components/IconButtonMenu";
import InfoIcon from "components/InfoIcon";
import {
  GetOrganizationDocument,
  InvitationStatus,
  useDeleteInvitationMutation,
  useGetOrganizationQuery,
  useResendInvitationMutation,
} from "generated/graphql";
import { useToast } from "hooks/toast";
import React from "react";
import { H4, HR } from "styles";
import { Table } from "./styles";

const Invitations: React.FC = () => {
  const toast = useToast();
  const { data } = useGetOrganizationQuery();

  const mutationOpts = {
    refetchQueries: [{ query: GetOrganizationDocument }],
  };
  const [resend] = useResendInvitationMutation(mutationOpts);
  const [deleteInvite] = useDeleteInvitationMutation(mutationOpts);

  return (
    <div>
      <H4 className="h-10">
        Invitations
        <InfoIcon className="ml-4" text="Admins you have invited" />
      </H4>
      <HR className="my-4" />
      <Table>
        <thead>
          <tr>
            <th>Email</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data?.organization?.invitations
            ?.filter((i) => i.status !== InvitationStatus.Accepted)
            .map((invitation) => {
              return (
                <tr key={invitation?.id}>
                  <td>{invitation?.email}</td>
                  <td>{invitation?.status}</td>
                  <td>
                    <IconButtonMenu
                      options={[
                        {
                          label: "Resend",
                          onClick: async () => {
                            try {
                              await resend({
                                variables: { id: invitation?.id },
                              });
                              toast.success(`${invitation?.email} invitation resent`);
                            } catch (err) {
                              toast.error(`Unable to resend invitation to ${invitation?.email}`);
                            }
                          },
                        },
                        {
                          label: <div className="text-brand">Delete</div>,
                          onClick: async () => {
                            try {
                              await deleteInvite({
                                variables: { id: invitation?.id },
                              });
                              toast.success(`${invitation?.email} invitation deleted`);
                            } catch (err) {
                              toast.error(`Unable to resend invitation to ${invitation?.email}`);
                            }
                          },
                        },
                      ]}
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};

export { Invitations as default };
