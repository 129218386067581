import { faExclamationCircle, faShareSquare, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import React, { useMemo, useCallback } from "react";
import { Tooltip } from "styles";
import { useWorkflowEditor } from "./context";
import { StepNodeProps, StepTypes } from "./types";

const StepNode: React.FC<StepNodeProps> = ({ node }) => {
  const { errors, addChildStep, removeStep, selected } = useWorkflowEditor();
  const step = node?.properties?.step;
  const stepName = step?.name ?? "";
  const errs = errors?.nodes[node?.id];
  const isSelected = useMemo(() => selected?.id === node?.id, [selected?.id, node?.id]);

  const removeNode = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      removeStep(node);
    },
    [node, removeStep]
  );

  const addChild = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      addChildStep(node);
    },
    [node, addChildStep]
  );

  return (
    <div className={`px-2 py-2 rounded relative ${isSelected ? "bg-blue-200" : ""}`}>
      {errs?.length > 0 && (
        <div className="absolute rounded-full" style={{ top: "-12px", left: "-8px" }}>
          <Tooltip
            arrow
            placement="left"
            title={errs?.map((err, i) => (
              <div key={`err-${i}`} className="text-lg">
                {err}
              </div>
            ))}
          >
            <span className="flex h-6 w-6 relative">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-brand opacity-75"></span>
              <span className="relative z-10 inline-flex rounded-full text-brand bg-white text-2xl">
                <FontAwesomeIcon icon={faExclamationCircle} />
              </span>
            </span>
          </Tooltip>
        </div>
      )}
      {node?.type !== StepTypes.End && (
        <button
          type="button"
          className="absolute top-0 right-0 px-2 py-2 active:opacity-75 text-xs text-gray-700 dark:text-gray-700 cursor-pointer"
          onClick={removeNode}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      )}

      <div className="flex items-center font-semibold text-lg ">
        <div className={cx(stepName === "" ? "text-gray-400" : "text-gray-500 dark:text-gray-700")}>
          {stepName !== "" ? stepName : "Select a step..."}
        </div>
      </div>
      <div className="dark:text-gray-700">{step?.properties?.length ?? 0} properties</div>

      <div className="my-2 flex justify-end">
        {step?.promptOperatorToConsumeInventory && (
          <Tooltip arrow placement="bottom" title="This step consumes inventory">
            <span>
              <FontAwesomeIcon size="lg" icon={faShareSquare} className="text-gray-400" />
            </span>
          </Tooltip>
        )}
      </div>
      <div className="flex justify-between">
        {node?.type !== StepTypes.End && (
          <button type="button" className="text-blue-500 focus:outline-none" onClick={addChild}>
            Add Step...
          </button>
        )}
      </div>
    </div>
  );
};

export { StepNode as default };
