import { CountryOption, RegionOption, SelectCountry, SelectRegion } from "components/Form/CountryAndRegion";
import { PhoneInput } from "components/Form/PhoneInput";
import { ScrollableForm } from "components/Form/ScrollableForm";
import TextInput from "components/Form/TextInput";
import { useToast } from "hooks/toast";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { SubHeading } from "styles";

import { useLocationsTable } from "./context";

interface ICreateLocationForm {
  name: string;
  address1: string;
  address2: string;
  city: string;
  region: RegionOption;
  country: CountryOption;
  phone: string;
  primary: boolean;
}

const CreateLocation = () => {
  const toast = useToast();
  const { setIsCreating, createLocation } = useLocationsTable();
  const { handleSubmit, register, formState, control, watch, setValue } = useForm<ICreateLocationForm>();
  const onRequestClose = () => setIsCreating(false);
  const onSubmit = async (input: ICreateLocationForm) => {
    try {
      await createLocation({
        variables: {
          input: {
            name: input.name,
            address1: input.address1,
            address2: input.address2,
            city: input.city,
            region: input?.region?.name ?? null,
            country: input?.country?.countryName ?? null,
            phone: input.phone,
            primary: input.primary,
          },
        },
      });
      onRequestClose();
    } catch (err) {
      toast.error("Unable to create Location");
    }
  };
  const country = watch("country");

  useEffect(() => {
    setValue("region", null);
  }, [country, setValue]);

  return (
    <ScrollableForm
      title="Add Location"
      onSubmit={handleSubmit(onSubmit)}
      onCancel={onRequestClose}
      formState={formState}
    >
      <SubHeading>Name</SubHeading>
      <TextInput {...register("name", { required: true })} placeholder="ie. Andersonville Store" />

      <SubHeading>Address 1</SubHeading>
      <TextInput {...register("address1")} placeholder="ie. 123 Fake St." />

      <SubHeading>Address 2</SubHeading>
      <TextInput {...register("address2")} placeholder="ie. Suite 123" />

      <SubHeading>City</SubHeading>
      <TextInput {...register("city")} placeholder="ie. Austin" />

      <SubHeading>Country</SubHeading>
      <Controller
        control={control}
        name="country"
        render={({ field }) => {
          return <SelectCountry label="Country" onChange={field.onChange} value={field.value} />;
        }}
      />

      <SubHeading>Region</SubHeading>
      <Controller
        control={control}
        name="region"
        render={({ field }) => {
          return <SelectRegion label="Region" onChange={field.onChange} value={field.value} country={country} />;
        }}
      />

      <SubHeading>Phone</SubHeading>
      <Controller
        control={control}
        name="phone"
        render={({ field }) => {
          return (
            <PhoneInput
              // countryCode={country?.countryShortCode}
              value={field.value}
              onChange={field.onChange}
            />
          );
        }}
      />
    </ScrollableForm>
  );
};

export { CreateLocation as default };
