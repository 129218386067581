import React from "react";
import { EditStepForm } from "components/Views/Step/Steps/EditStep";
import { IStepFormData } from "components/Views/Step/Steps/StepForm";
import { useUpdateStepMutation } from "generated/graphql";
import { useToast } from "hooks/toast";
import { omit } from "lodash";
import { useWorkflowEditor } from "./context";

const EditStep = () => {
  const { setIsEditingStep, isEditingStep, updateSelectedStep } = useWorkflowEditor();

  const step = isEditingStep;
  const toast = useToast();
  const [update] = useUpdateStepMutation();

  const submit = async (data: IStepFormData) => {
    try {
      const resp = await update({
        variables: {
          input: {
            id: step?.id,
            name: data?.name,
            description: data?.description,
            points: data?.points,
            promptOperatorToConsumeInventory: data?.promptOperatorToConsumeInventory,
            properties: data?.properties.map((p) => omit(p, "__typename")),
          },
        },
      });

      updateSelectedStep(resp?.data?.updateStep);
      setIsEditingStep(null);
    } catch (err) {
      console.log(err);
      toast.error("Unable to update step");
    }
  };

  return <EditStepForm step={step} onSubmit={submit} onCancel={() => setIsEditingStep(null)} />;
};

export { EditStep as default };
