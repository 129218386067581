import React, { createContext, PropsWithChildren, useContext, useMemo, useCallback, useEffect } from "react";
import {
  Workflow,
  ListWorkflowsQuery,
  Exact,
  Pagination,
  ListWorkflowsQueryVariables,
  PageInfo,
  ListWorkflowsFilters,
  ListWorkflowsDocument,
  useDeleteWorkflowMutation,
  useCreateWorkflowMutation,
  useRestoreWorkflowMutation,
} from "generated/graphql";
import { useSessionStorage } from "react-use";
import { QueryTuple } from "@apollo/client";
import { useImmerReducer } from "use-immer";
import { omit } from "lodash";
import { getRouteWithParams, Routes } from "routes";
import { useHistory } from "react-router";

interface StateIFace {
  filters: ListWorkflowsFilters;
  pagination: PageInfo;
  showFilters: boolean;
  selected: Set<React.Key>;
  isCreating: boolean;
  isEditing: Workflow;
  isDeleting: Workflow[];
  isBulkDeleting: Workflow[];
}

type SetPagination = {
  type: Actions.SetPagination;
  data: { perPage: number; page: number };
};

type SetSelected = {
  type: Actions.SetSelected;
  data: Set<React.Key>;
};

type SetShowFilters = {
  type: Actions.SetShowFilters;
  data: boolean;
};

type SetFilters = {
  type: Actions.SetFilters;
  data: ListWorkflowsFilters;
};

type SetIsCreating = {
  type: Actions.SetIsCreating;
  data: boolean;
};

type SetIsDeleting = {
  type: Actions.SetIsDeleting;
  data: Workflow[];
};

type DispatcherAction = SetShowFilters | SetSelected | SetPagination | SetFilters | SetIsCreating | SetIsDeleting;

enum Actions {
  SetFilters = "SET_FILTERS",
  SetShowFilters = "SET_SHOW_FILTERS",
  SetPagination = "SET_PAGINATION",
  SetSelected = "SET_SELECTED",
  SetIsAdding = "SET_IS",
  SetIsCreating = "SET_IS_CREATING",

  SetIsDeleting = "SET_IS_DELETING",
  SetIsBulkDeleting = "SET_IS_BULK_DELETING",
}

const initialState: StateIFace = {
  filters: null,
  showFilters: false,
  selected: new Set<React.Key>(),
  pagination: { perPage: 10, page: 1 },
  isCreating: false,
  isEditing: null,
  isDeleting: null,
  isBulkDeleting: null,
};

const ctx = createContext<{
  query: QueryTuple<
    ListWorkflowsQuery,
    Exact<{
      pagination?: Pagination;
      filters?: ListWorkflowsFilters;
    }>
  >;
  state: StateIFace;
  dispatch: React.Dispatch<DispatcherAction>;
}>({ query: null, state: initialState, dispatch: () => null });

type ITableProvider<QT, FT> = PropsWithChildren<{
  query: QueryTuple<
    QT,
    Exact<{
      pagination?: Pagination;
      filters?: FT;
    }>
  >;
  sessionKey: string;
}>;

export const TableProvider = <QT, FT>({ children, sessionKey, query }: ITableProvider<QT, FT>) => {
  const [filters, setFilters] = useSessionStorage<{
    filters: ListWorkflowsFilters;
    pagination: PageInfo;
  }>(sessionKey, {
    filters: initialState.filters,
    pagination: initialState.pagination,
  });

  const [state, dispatch] = useImmerReducer(
    (state: StateIFace, action: DispatcherAction) => {
      switch (action.type) {
        case Actions.SetPagination:
          state.pagination = action.data;
          break;

        case Actions.SetSelected:
          state.selected = action.data;
          break;

        case Actions.SetShowFilters:
          state.showFilters = action.data;
          break;

        case Actions.SetFilters:
          state.filters = action.data;
          break;

        case Actions.SetIsCreating:
          state.isCreating = action.data;
          break;

        case Actions.SetIsDeleting:
          state.isDeleting = action.data;
          break;

        default:
          throw new Error("problem with Table Provider");
      }
    },
    { ...initialState, ...filters }
  );

  useEffect(() => {
    setFilters({
      filters: state.filters,
      pagination: state.pagination,
    });
  }, [state, setFilters]);

  return (
    <ctx.Provider
      value={{
        query,
        state,
        dispatch,
      }}
    >
      {children}
    </ctx.Provider>
  );
};

export const useWorkflowsTable = () => {
  const { query, state, dispatch } = useContext(ctx);
  const [fetch, { data, loading, variables: qvariables }] = query;

  const mutationOpts = {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ListWorkflowsDocument, variables: qvariables }],
  };

  const [deleteWorkflow] = useDeleteWorkflowMutation(mutationOpts);
  const [createWorkflow] = useCreateWorkflowMutation(mutationOpts);

  const setPagination = useCallback(
    (pagination: Pagination) => {
      const isChangingPerPage = !!(pagination?.perPage && pagination?.perPage !== state?.pagination?.perPage);
      const page = isChangingPerPage ? 1 : pagination?.page || state?.pagination?.page;
      const perPage = pagination?.perPage || state?.pagination?.perPage || 10;
      dispatch({
        type: Actions.SetPagination,
        data: {
          page,
          perPage,
        },
      });
    },
    [dispatch, state.pagination]
  );

  const setselected = useCallback(
    (rows: Set<React.Key>) => dispatch({ type: Actions.SetSelected, data: rows }),
    [dispatch]
  );

  const setShowFilters = (open: boolean) => {
    dispatch({ type: Actions.SetShowFilters, data: open });
  };

  const setFilters = (filters: ListWorkflowsFilters) => {
    dispatch({ type: Actions.SetFilters, data: filters });
  };

  const removeFilter = (property: string, i?: number) => {
    setFilters(omit(state.filters, [property]));
  };

  const resetFilters = () => {
    dispatch({
      type: Actions.SetFilters,
      data: {},
    });
  };

  const setIsCreating = (isCreating: boolean) => {
    dispatch({ type: Actions.SetIsCreating, data: isCreating });
  };

  const setIsDeleting = (step: Workflow[] | null) => {
    dispatch({ type: Actions.SetIsDeleting, data: step });
  };

  const variables: ListWorkflowsQueryVariables = useMemo(
    () => ({
      filters: state?.filters,
      pagination: state.pagination,
    }),

    [state.pagination, state.filters]
  );

  const selectedRows = useMemo(() => {
    return (
      data?.listWorkflows?.workflows?.filter((step) => {
        return state.selected.has(step.id);
      }) ?? []
    );
  }, [data?.listWorkflows?.workflows, state.selected]);

  return {
    ...state,
    data,
    loading,
    variables,
    selectedRows,
    setIsCreating,
    setIsDeleting,
    setPagination,
    setselected,
    setShowFilters,
    setFilters,
    removeFilter,
    resetFilters,
    fetch,
    deleteWorkflow,
    createWorkflow,
  };
};

export type RowActions =
  | "printLabel"
  | "generateLabels"
  | "editWorkflow"
  | "deleteWorkflow"
  | "deleteWorkflow"
  | "copyWorkflow"
  | "deleteWorkflows"
  | "restoreWorkflow";

export const useRowActions = () => {
  const history = useHistory();
  const { selectedRows, setIsDeleting } = useWorkflowsTable();
  const [restoreWorkflow] = useRestoreWorkflowMutation();
  const handleAction = (action: RowActions, row: Workflow) => {
    switch (action) {
      case "editWorkflow":
        history.push(getRouteWithParams(Routes.Workflow, { id: row.id }));
        break;

      case "copyWorkflow":
        history.push(getRouteWithParams(Routes.Workflow, { id: "new" }), {
          copyFrom: row.id,
        });

        break;

      case "deleteWorkflow":
        setIsDeleting([row]);
        break;

      case "deleteWorkflows":
        setIsDeleting(selectedRows);
        break;

      case "restoreWorkflow":
        restoreWorkflow({ variables: { id: row.id } });

        break;

      default:
        break;
    }
  };

  return handleAction;
};
