import GoSquared from "gosquared";

export const gosquared = new GoSquared({
  api_key: "RFIHQ01GC10BP7P2",
  site_token: "GSN-324091-T",
});

export const nodeEvent = (eventName: string, email: string) => {
  const person = gosquared.createPerson(`email:${email}`);
  person.trackEvent(eventName, {}, (err, resp) => {});
};

export const gsEvent = (eventName: string) => {
  window._gs("event", eventName);
  console.log(window);
};

export const gsIdentify = (userEmail: string) => {
  window._gs("identify", {
    email: userEmail,
  });
};
