import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Checkbox from "components/Form/Checkbox";
import React, { useMemo, useCallback } from "react";
import type { EditorProps } from "react-data-grid";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { useKey } from "react-use";
import { currency } from "utils/formatters";
import { useEditTable } from "./useEditTable";

const autoFocusAndSelect = (input: HTMLInputElement | null) => {
  input?.focus();
  input?.select();
};

export const TextEditor = <TRow extends { id: string }, TSummaryRow = unknown>({
  row,
  column,
  onRowChange,
  onClose,
}: EditorProps<TRow, TSummaryRow>) => {
  const { queueUpdate } = useEditTable();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = { ...row, [column.key]: event.target.value };
    queueUpdate(data);
    onRowChange(data);
  };

  return (
    <input
      className={`rdg-text-editor w-full ${column?.cellClass as string}`}
      ref={autoFocusAndSelect}
      value={row[column.key as keyof TRow] as unknown as string}
      onChange={handleChange}
      onBlur={() => onClose(true)}
    />
  );
};

export const NumberEditor = <TRow extends { id: string }, TSummaryRow = unknown>({
  row,
  column,
  onRowChange,
  onClose,
}: EditorProps<TRow, TSummaryRow>) => {
  const { queueUpdate, isEditMode } = useEditTable();

  const handleChange = (values: NumberFormatValues) => {
    const val = values?.floatValue ?? 0;
    const data = { ...row, [column.key]: val };
    queueUpdate(data);
    onRowChange(data);
  };

  if (!isEditMode) {
    return <div className={`rdg-text-editor ${column?.cellClass as string}`}>{row[column.key]}</div>;
  }

  return (
    <NumberFormat
      getInputRef={autoFocusAndSelect}
      className={`rdg-text-editor w-full ${column?.cellClass as string}`}
      value={row[column.key as keyof TRow] as unknown as number}
      onValueChange={handleChange}
      onBlur={() => onClose(true)}
    />
  );
};

export const CurrencyEditor = <TRow extends { id: string }, TSummaryRow = unknown>({
  row,
  column,
  onRowChange,
  onClose,
  getValue,
  setValue,
}: EditorProps<TRow, TSummaryRow> & {
  getValue?: (r: TRow) => string | number;
  setValue?: (r: TRow, val: number) => TRow;
}) => {
  const { queueUpdate, isEditMode } = useEditTable();
  const handleChange = (values: NumberFormatValues) => {
    const val = values?.floatValue ?? 0;
    const data = setValue ? setValue(row, val) : { ...row, [column.key]: val };
    queueUpdate(data);
    onRowChange(data);
  };

  const value = useMemo(
    () => (row?.[column?.key as keyof TRow] as unknown as number) || getValue(row) || 0,
    [column?.key, getValue, row]
  );
  if (!isEditMode) {
    return <div className={column?.cellClass as string}>{currency(value)}</div>;
  }

  return (
    <NumberFormat
      className={`rdg-text-editor w-full ${column?.cellClass as string}`}
      allowNegative={false}
      prefix="$"
      thousandSeparator
      decimalScale={2}
      allowEmptyFormatting={false}
      defaultValue={0}
      allowLeadingZeros={false}
      getInputRef={autoFocusAndSelect}
      value={value}
      onValueChange={handleChange}
      onBlur={() => onClose(true)}
    />
  );
};

export const BooleanEditor = <TRow extends { id: string }, TSummaryRow = unknown>({
  row,
  column,
  onRowChange,
  onClose,
}: EditorProps<TRow, TSummaryRow>) => {
  const { queueUpdate, isEditMode } = useEditTable();
  const val = row[column.key] as unknown as boolean;
  const handleChange = useCallback(
    (checked: boolean) => {
      const data = { ...row, [column.key]: checked };
      queueUpdate(data);
      onRowChange(data);
    },
    [column.key, queueUpdate, onRowChange, row]
  );
  useKey(
    "Enter",
    () => {
      if (isEditMode) {
        handleChange(!val);
      }
    },
    {},
    [handleChange]
  );

  if (!isEditMode) {
    return <FontAwesomeIcon icon={val ? faCheckSquare : faSquare} />;
  }

  return (
    <div className="w-full h-full flex justify-center items-center">
      <Checkbox className="text-blue-800 dark:text-blue-500" onChange={(e) => handleChange(e?.checked)} checked={val} />
    </div>
  );
};
