import React, { useCallback, useMemo, useState } from "react";
import cx from "classnames";
import ReactTagInput, { ReactTagInputProps } from "@pathofdev/react-tag-input";
import styled from "styled-components";
import tw from "twin.macro";
import Autosuggest from "react-autosuggest";
import {
  GetTagValuesDocument,
  GetTagValuesQuery,
  GetTagValuesQueryVariables,
  ListTagsDocument,
  ListTagsQuery,
  ListTagsQueryVariables,
  Tag,
  TagResourceType,
  useCreateTagMutation,
} from "generated/graphql";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { useApolloClient } from "@apollo/client";
import { useToast } from "hooks/toast";

export interface ITagInputValue {
  id?: string;
  value?: string | null;
  tag?: Tag;
}

export const StyledTag: React.FC<{ value: ITagInputValue; onRemove?: () => void }> = ({ value, onRemove }) => {
  return (
    <div className="flex items-center bg-gray-600 text-white rounded px-2 py-1 mr-1 mb-2 text-sm break-all">
      <div className="pr-2">
        <FontAwesomeIcon icon={faTag} size="sm" />
      </div>
      {!value?.value ? (
        <div className="font-semibold">{`${value?.tag?.name}`}</div>
      ) : (
        <div className="font-semibold">
          {`${value?.tag?.name}=`}
          <span className="font-normal">{`${value?.value}`}</span>
        </div>
      )}
      {onRemove && (
        <button type="button" className="ml-1 text-gray-300" onClick={onRemove}>
          <FontAwesomeIcon icon={faTimesCircle} size="sm" />
        </button>
      )}
    </div>
  );
};

export const TagInput = styled(
  ({ className, label, ...props }: ReactTagInputProps & { label?: string; className?: string }) => {
    return (
      <>
        {label && <label className="text-xs">{label}</label>}
        <div className={className}>
          <ReactTagInput {...props} placeholder={props?.placeholder} />
        </div>
      </>
    );
  }
)`
  ${tw`border border-gray-300 dark:border-gray-400 focus-within:border-blue-400 rounded outline-none appearance-none shadow-none focus-within:shadow-none`}
  min-height: 42px;
  .react-tag-input {
    ${tw`bg-transparent border-none`}
  }

  .react-tag-input__input {
  }

  .react-tag-input__input::placeholder {
    ${tw`text-gray-400 dark:text-gray-600 text-lg`}
  }
  .react-tag-input__input {
    ${tw`text-default dark:text-white`}
  }
  .react-tag-input__tag {
  }
`;

const theme = {
  containerOpen: "react-autosuggest__container--open",
  input: "react-autosuggest__input",
  inputOpen: "react-autosuggest__input--open",
  inputFocused: "react-autosuggest__input--focused",
  suggestionsContainer: "react-autosuggest__suggestions-container",
  suggestionsContainerOpen: "react-autosuggest__suggestions-container--open",
  suggestionFirst: "react-autosuggest__suggestion--first",
  sectionContainer: "react-autosuggest__section-container",
  sectionContainerFirst: "react-autosuggest__section-container--first",
  sectionTitle: "react-autosuggest__section-title",
  container: "react-autosuggest__container",
  suggestionHighlighted: "react-autosuggest__suggestion--highlighted",
  suggestion: "react-autosuggest__suggestion",
  suggestionsList: "react-autosuggest__suggestions-list",
};

const renderTagSuggestion = (tag: Tag) => {
  if (tag.id === "__new__") {
    return <div className="font-semibold">Create "{tag.name}"</div>;
  }

  return <div>{tag.name}</div>;
};

const renderValueSuggestion = (value: string) => <div>{value}</div>;

interface ITagInputWSuggest {
  size?: "sm" | "base";
  className?: string;
  label?: string;
  tagOnly?: boolean;
  onChange: (val: ITagInputValue) => void;
  onCreateTag?: (tag: Tag) => void;
  allowCreate?: boolean;
  autoFocus?: boolean;
}

export const _TagInputWSuggest: React.FC<ITagInputWSuggest> = ({
  label,
  onChange,
  onCreateTag = () => {},
  allowCreate = false,
  tagOnly = false,
  autoFocus = false,
  className,
  size,
}) => {
  const client = useApolloClient();
  const [createTag] = useCreateTagMutation();
  const [suggestions, setsuggestions] = useState<Tag[]>([]);
  const [valuesuggestions, setvaluesuggestions] = useState<string[]>([]);
  const [tagInputValue, settagInputValue] = useState("");
  const [valueInputValue, setvalueInputValue] = useState("");
  const [tag, settag] = useState<Tag>(null);
  const [shouldAutofocus, setShouldAutofocus] = useState(autoFocus);
  const onComplete = useCallback(
    (result: ITagInputValue) => {
      onChange(result);
      settagInputValue("");
      setvalueInputValue("");
      settag(null);
    },
    [onChange]
  );

  const onTagSuggestionsFetchRequested = useCallback(
    ({ value }) => {
      client
        .query<ListTagsQuery, ListTagsQueryVariables>({
          fetchPolicy: "network-only",
          query: ListTagsDocument,
          variables: { like: value },
        })
        .then((resp) => {
          const suggestions = resp?.data?.listTags ?? [];

          const create = allowCreate && suggestions.findIndex((s) => s?.name === value) === -1;

          setsuggestions(
            create
              ? [
                  ...suggestions,
                  {
                    id: "__new__",
                    name: value,
                  },
                ]
              : suggestions
          );
        });
    },
    [allowCreate, client]
  );

  const onTagSuggestionsClearRequested = () => {
    setsuggestions([]);
  };

  const onTagChange = (_: any, params: { newValue: string; method: string }) => {
    try {
      const value: Tag = JSON.parse(params.newValue);
      if (params.method === "down" && value) {
        settagInputValue(value?.name);
      }
    } catch (err) {
      settagInputValue(params.newValue);
    }
  };

  const onTagSuggestionSelected = async (_: any, data: any) => {
    if (data.suggestion.id === "__new__") {
      try {
        const resp = await createTag({
          variables: { input: { name: data.suggestion.name.trim() } },
        });
        const tag = resp.data.createTag;
        if (tagOnly) {
          onComplete({
            tag,
            value: null,
          });
        } else {
          settag(tag);
        }
        onCreateTag(tag);
      } catch (err) {}
    } else {
      if (tagOnly) {
        onComplete({
          tag: data?.suggestion,
          value: null,
        });
      } else {
        settag(data.suggestion);
      }
    }
  };

  const onValueSuggestionsFetchRequested = useCallback(
    ({ value }) => {
      client
        .query<GetTagValuesQuery, GetTagValuesQueryVariables>({
          query: GetTagValuesDocument,
          variables: {
            input: {
              tagId: tag.id,
              resourceType: TagResourceType.InventoryItem,
              like: value,
            },
          },
        })
        .then((resp) => {
          setvaluesuggestions(resp?.data?.getTagValues ?? []);
        });
    },
    [client, tag?.id]
  );

  const onValueSuggestionsClearRequested = () => {
    setvaluesuggestions([]);
  };

  const onValueChange = (_: any, { newValue }: any) => {
    setvalueInputValue(newValue);
  };

  if (tag === null) {
    return (
      <div className={className}>
        {label && <label className="text-xs">{label}</label>}
        <div
          className="rounded flex flex-row items-center bg-white dark:bg-gray-800 border focus-within:border-blue-400 border-gray-300 dark:border-gray-400"
          style={{ height: size === "sm" ? 32 : 42 }}
        >
          <div className="relative">
            <Autosuggest<Tag>
              theme={theme}
              suggestions={suggestions}
              onSuggestionsFetchRequested={onTagSuggestionsFetchRequested}
              onSuggestionsClearRequested={onTagSuggestionsClearRequested}
              getSuggestionValue={(suggestion: Tag) => JSON.stringify(suggestion)}
              onSuggestionSelected={onTagSuggestionSelected}
              renderSuggestion={renderTagSuggestion}
              inputProps={{
                autoFocus: shouldAutofocus,
                value: tagInputValue,
                placeholder: "Type a tag name...",
                onChange: onTagChange,
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={className}>
      {label && <label className="text-xs">{label}</label>}
      <div
        className="rounded flex flex-row items-center bg-white dark:bg-gray-800 border focus-within:border-blue-400 border-gray-300 dark:border-gray-400"
        style={{ height: size === "sm" ? 32 : 42 }}
      >
        <div
          className={cx("bg-gray-200 flex items-center h-full pl-2 text-gray-800", size === "sm" && "text-sm")}
          title={tag?.name}
          style={{ borderTopLeftRadius: 3, borderBottomLeftRadius: 3 }}
        >
          <div className="px-1">
            <FontAwesomeIcon icon={faTag} size="sm" />
          </div>
          <div className="truncate font-semi-bold" title={tag?.name}>
            {tag?.name}
          </div>
          <div className="px-1">=</div>
        </div>
        <div className="relative">
          <Autosuggest<string>
            theme={theme}
            suggestions={valuesuggestions}
            onSuggestionsFetchRequested={onValueSuggestionsFetchRequested}
            onSuggestionsClearRequested={onValueSuggestionsClearRequested}
            getSuggestionValue={(v: string) => v}
            onSuggestionSelected={(e, data) =>
              onComplete({
                tag,
                value: data.suggestion,
              })
            }
            renderSuggestion={renderValueSuggestion}
            inputProps={{
              onFocus: () => setShouldAutofocus(true),
              autoFocus: true,
              value: valueInputValue,
              placeholder: "Enter a tag value...",
              onChange: onValueChange,
              onKeyDown: (e) => {
                if (e.key === "Backspace" && valueInputValue === "") {
                  settag(null);
                }
              },
              onKeyPress: (e: any) => {
                if (e.key === "Enter") {
                  onComplete({
                    tag,
                    value: e?.target?.value !== "" ? e?.target?.value : null,
                  });
                }
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export const TagInputWSuggest = styled(_TagInputWSuggest)<ITagInputWSuggest>`
  .react-autosuggest__container--open {
  }

  .react-autosuggest__input {
    ${(props) => (props?.size === "sm" ? tw`text-sm` : "text-lg")}
    ${tw`w-full appearance-none leading-normal focus:outline-none focus:shadow-none flex-1 bg-transparent dark:text-white text-black w-full focus:outline-none focus:shadow-none px-2`}

    &::placeholder {
      ${tw`text-gray-400 dark:text-gray-600`}
    }
  }

  .react-autosuggest__input--open {
  }

  .react-autosuggest__input--focused {
  }

  .react-autosuggest__suggestions-container {
    ${tw`absolute left-0 bg-white z-50`}
    top: 33px;
  }

  .react-autosuggest__suggestions-container--open {
  }

  .react-autosuggest__suggestion--first {
  }

  .react-autosuggest__section-container {
  }

  .react-autosuggest__section-container--first {
  }

  .react-autosuggest__section-title {
  }

  .react-autosuggest__container {
    ${tw`w-full`}
  }

  .react-autosuggest__suggestion {
    ${(props) => (props?.size === "sm" ? tw`py-1 px-2 text-sm` : tw`py-2 px-2`)}
    ${tw`bg-white dark:bg-gray-800`}
  }

  .react-autosuggest__suggestion--highlighted {
    ${tw`bg-brand dark:bg-brand font-normal text-white cursor-pointer`}
  }

  .react-autosuggest__suggestions-list {
    ${tw`bg-brand border border-gray-100`}
  }
`;

export const MultiTagInputWSuggest: React.FC<{
  value: ITagInputValue[];
  onChange: (tags: ITagInputValue[]) => void;

  onRemove?: (tag: ITagInputValue, index: number) => void;
  allowCreate?: boolean;
  allowDuplicates?: boolean;
}> = ({
  value = [],
  onChange,

  onRemove = () => {},
  allowCreate = false,
  allowDuplicates = false,
}) => {
  const toast = useToast();
  const valueKeys = useMemo(() => new Set(value.map((v) => v?.tag?.id)), [value]);
  return (
    <div className="flex flex-wrap">
      {value.map((v, i) => {
        return (
          <div
            key={i}
            className="flex items-center bg-gray-600 text-white rounded px-2 py-1 mr-1 mb-2 text-sm break-all"
          >
            <div className="pr-2">
              <FontAwesomeIcon icon={faTag} size="sm" />
            </div>
            {!v?.value ? (
              <div className="font-semibold">{`${v?.tag?.name}`}</div>
            ) : (
              <div className="font-semibold">
                {`${v?.tag?.name}=`}
                <span className="font-normal">{`${v?.value}`}</span>
              </div>
            )}

            <button type="button" className="ml-1 text-gray-300" onClick={() => onRemove(value[i], i)}>
              <FontAwesomeIcon icon={faTimesCircle} size="sm" />
            </button>
          </div>
        );
      })}
      <TagInputWSuggest
        allowCreate={allowCreate}
        onCreateTag={(t) => toast.success(`Tag ${t.name} created`)}
        onChange={(val) => {
          if (allowDuplicates === false && valueKeys.has(val?.tag?.id)) {
            return;
          }

          onChange([...value, val]);
        }}
      />
    </div>
  );
};

export const SmallMultiTagInputWSuggest: React.FC<{
  value: ITagInputValue[];
  onChange: (tags: ITagInputValue[]) => void;
  onRemove?: (tag: ITagInputValue, index: number) => void;
  allowCreate?: boolean;
  allowDuplicates?: boolean;
}> = ({ value = [], onChange, onRemove = () => {}, allowCreate = false, allowDuplicates = false }) => {
  const toast = useToast();
  const valueKeys = useMemo(() => new Set(value.map((v) => v?.tag?.id)), [value]);
  return (
    <div className="flex flex-wrap">
      {value.map((v, i) => {
        return <StyledTag key={i} value={v} onRemove={() => onRemove(value[i], i)} />;
      })}
      <TagInputWSuggest
        size="sm"
        allowCreate={allowCreate}
        onCreateTag={(t) => toast.success(`Tag ${t.name} created`)}
        onChange={(val) => {
          if (allowDuplicates === false && valueKeys.has(val?.tag?.id)) {
            return;
          }

          onChange([...value, val]);
        }}
      />
    </div>
  );
};
