import {
  INode,
  ILink,
  IChart,
  INodeInnerDefaultProps,
} from "@mrblenny/react-flow-chart";
import { WorkflowStep, WorkflowVersion } from "generated/graphql";
import { v4 } from "uuid";

export interface IStepNode extends INode<WorkflowStep> {}

export const baseChart: IChart<undefined, WorkflowStep> = {
  offset: { x: 0, y: 0 },
  scale: 1,
  nodes: {},
  links: {},
  selected: {},
  hovered: {},
};

export enum StepTypes {
  Start = "output-only",
  Intermediate = "input-output",
  End = "input-only",
}

export interface StepNodeProps extends INodeInnerDefaultProps {
  node: IStepNode;
}

interface NewStartStepPropsIFace {
  position: { x: number; y: number };
}
export const newStartStep = (props?: NewStartStepPropsIFace): IStepNode => {
  const nodeId = v4();
  return {
    id: nodeId,
    type: StepTypes.Start,
    position: {
      x: 0,
      y: 0,
      ...props.position,
    },
    size: {
      width: 200,
      height: 139,
    },
    properties: {
      id: nodeId,
      depth: 0,
      step: null,
    },
    ports: {
      output: {
        id: "output",
        type: "output",
      },
    },
  };
};

interface NewChildStepPropsIFace {
  position: { x: number; y: number };
  properties: { depth: number };
}

export const newChildStep = (props?: NewChildStepPropsIFace) => {
  const nodeId = v4();
  return {
    id: nodeId,
    type: StepTypes.Intermediate,
    position: {
      x: 0,
      y: 0,
      ...props.position,
    },
    properties: {
      id: nodeId,
      ...props.properties,
    },
    ports: {
      input: {
        id: "input",
        type: "input",
      },
      output: {
        id: "output",
        type: "output",
      },
    },
  };
};

export const buildPorts = (type: StepTypes, workflowStep: WorkflowStep) => {
  switch (type) {
    case StepTypes.Start:
      return {
        output: {
          id: "output",
          type: "output",
          properties: {
            nodeId: workflowStep?.id,
            depth: workflowStep?.depth,
          },
        },
      };
    case StepTypes.Intermediate:
      return {
        input: {
          id: "input",
          type: "input",
          properties: {
            nodeId: workflowStep?.id,
            depth: workflowStep?.depth,
          },
        },
        output: {
          id: "output",
          type: "output",
          properties: {
            nodeId: workflowStep?.id,
            depth: workflowStep?.depth,
          },
        },
      };
    case StepTypes.End:
      return {
        input: {
          id: "input",
          type: "input",
          properties: {
            nodeId: workflowStep?.id,
            depth: workflowStep?.depth,
          },
        },
      };
  }
};

export const getDefaultNodePosition = (x: number = 0, y: number = 0) => {
  return { x: 225 * x + 75, y: y * 200 + 50 };
};

export const buildStep = (
  step: WorkflowStep,
  neighbor: number,
  type: StepTypes
): IStepNode => {
  const depth = step.depth ?? 0;
  const ports = buildPorts(type, step);
  const defaultPosition = getDefaultNodePosition(neighbor, depth);

  return {
    id: step.id,
    type,
    position: {
      x: step?.position?.x ?? defaultPosition.x,
      y: step?.position?.y ?? defaultPosition.y,
    },
    ports,
    properties: {
      ...step,
    },
  };
};

export const stepsToChartData = (
  data: WorkflowVersion
): IChart<undefined, WorkflowStep> => {
  const depths = data?.nodes?.reduce((acc, step) => {
    if (!acc[step.depth]) {
      acc[step.depth] = [step];
      return acc;
    }
    acc[step.depth].push(step);
    return acc;
  }, {} as Record<number, WorkflowStep[]>);
  if (!depths) {
    return { ...baseChart };
  }
  const maxdepth = Math.max(...Object.keys(depths).map((k) => parseInt(k, 10)));
  let nodes: Record<string, IStepNode> = {};
  for (let depth = 0; depth <= maxdepth; depth++) {
    const steps = depths[depth];
    for (let k = 0; k < steps?.length; k++) {
      const step = steps[k];

      switch (depth) {
        case 0:
          nodes[step.id] = buildStep(step, k, StepTypes.Start);
          continue;

        case maxdepth:
          nodes[step.id] = buildStep(step, k, StepTypes.End);
          continue;

        default:
          nodes[step.id] = buildStep(step, k, StepTypes.Intermediate);
          continue;
      }
    }
  }

  const links = data?.edges?.reduce((acc, edge) => {
    if (nodes?.[edge?.startId]?.type === StepTypes.Start) {
      acc[edge?.id] = {
        id: edge?.id,
        from: { nodeId: edge?.startId, portId: "output" },
        to: { nodeId: edge?.endId, portId: "input" },
      };
    }

    if (nodes?.[edge?.startId]?.type === StepTypes.Intermediate) {
      acc[edge?.id] = {
        id: edge?.id,
        from: { nodeId: edge?.startId, portId: "output" },
        to: { nodeId: edge?.endId, portId: "input" },
      };
    }
    return acc;
  }, {} as Record<string, ILink>);

  return {
    ...baseChart,
    nodes,
    links,
  };
};
