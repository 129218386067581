import React from "react";
import { SubHeading } from "styles";
import { useUpdateLocationMutation, ListLocationsDocument, Location } from "generated/graphql";
import { useLocationsTable } from "./context";
import { useForm, Controller } from "react-hook-form";
import { ScrollableForm } from "components/Form/ScrollableForm";
import TextInput from "components/Form/TextInput";
import { SelectCountry, SelectRegion, CountryOption, RegionOption, getCountry } from "components/Form/CountryAndRegion";
import NumberInput from "components/Form/NumberInput";
import { PhoneInput } from "components/Form/PhoneInput";

export interface IEditLocationForm {
  id: string;
  name: string;
  address1: string;
  address2: string;
  city: string;
  postalCode: string;
  region: RegionOption;
  country: CountryOption;
  phone: string;
  primary: boolean;
}

export const EditLocationForm: React.FC<{
  location: Location;
  onSubmit: (data: IEditLocationForm) => Promise<void>;
  onCancel: () => void;
}> = ({ location, onSubmit, onCancel }) => {
  const { handleSubmit, register, formState, control, watch, setValue } = useForm<IEditLocationForm>({
    defaultValues: {
      id: location?.id,
      name: location?.name,
      address1: location?.address1,
      address2: location?.address2,
      city: location?.city,
      postalCode: location?.postalCode,
      region: {
        name: location?.region,
      },
      country: getCountry(location?.country ?? "United States"),
      phone: location?.phone,
      primary: location?.primary,
    },
  });

  const country = watch("country");

  return (
    <ScrollableForm
      title="Edit Location"
      onSubmit={handleSubmit(onSubmit)}
      onCancel={onCancel}
      formState={formState}
      submitLabel="Update"
    >
      <SubHeading>Name</SubHeading>
      <TextInput {...register("name")} placeholder="ie. Andersonville Store" />

      <SubHeading>Address 1</SubHeading>
      <TextInput {...register("address1")} placeholder="ie. 123 Fake St." />

      <SubHeading>Address 2</SubHeading>
      <TextInput {...register("address2")} placeholder="ie. Suite 123" />

      <SubHeading>City</SubHeading>
      <TextInput {...register("city")} placeholder="ie. Austin" />

      <SubHeading>Country</SubHeading>
      <Controller
        control={control}
        name="country"
        render={({ field }) => {
          return (
            <SelectCountry
              label="Country"
              onChange={(v) => {
                setValue("region", null);
                field.onChange(v);
              }}
              value={field.value}
            />
          );
        }}
      />

      <SubHeading>Region</SubHeading>
      <Controller
        control={control}
        name="region"
        render={({ field }) => {
          return <SelectRegion label="Region" onChange={field.onChange} value={field.value} country={country} />;
        }}
      />

      <SubHeading>Postal Code</SubHeading>
      <Controller
        control={control}
        name="postalCode"
        render={({ field }) => {
          return <NumberInput onChange={field.onChange} value={field.value} />;
        }}
      />

      <SubHeading>Phone</SubHeading>
      <Controller
        control={control}
        name="phone"
        render={({ field }) => {
          return (
            <PhoneInput
              // countryCode={country?.countryShortCode}
              value={field.value}
              onChange={field.onChange}
            />
          );
        }}
      />

      <TextInput type="hidden" {...register("id")} />
    </ScrollableForm>
  );
};

const EditLocation = () => {
  const { isEditing, setIsEditing } = useLocationsTable();
  const [updateLocation] = useUpdateLocationMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ListLocationsDocument, variables: {} }],
  });

  const onRequestClose = () => setIsEditing(null);

  const onSubmit = async (input: IEditLocationForm) => {
    try {
      await updateLocation({
        variables: {
          input: {
            id: input?.id,
            name: input?.name,
            address1: input?.address1,
            address2: input?.address2,
            city: input?.city,
            postalCode: input?.postalCode,
            region: input?.region?.name ?? null,
            country: input?.country?.countryName ?? null,
            phone: input?.phone,
            primary: input?.primary,
          },
        },
      });
      onRequestClose();
    } catch (err) {
      console.log(err);
    }
  };

  return <EditLocationForm location={isEditing} onCancel={onRequestClose} onSubmit={onSubmit} />;
};

export { EditLocation as default };
