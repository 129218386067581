import { ScrollableForm } from "components/Form/ScrollableForm";
import { CustomerForm, ICreateCustomerForm } from "components/Views/Customer/Customers/Create";
import { Customer, useCreateCustomerMutation } from "generated/graphql";
import { useToast } from "hooks/toast";
import React from "react";
import { useForm } from "react-hook-form";

export const CreateCustomer: React.FC<{ onCancel: () => void; onCreate: (customer: Customer) => void }> = ({
  onCancel,
  onCreate,
}) => {
  const toast = useToast();
  const form = useForm<ICreateCustomerForm>();
  const [createCustomer] = useCreateCustomerMutation({
    // awaitRefetchQueries: true,
    // refetchQueries: [{ query: ListCustomersDocument, variables }],
  });
  const submit = async (data: ICreateCustomerForm) => {
    try {
      const resp = await createCustomer({ variables: { input: data } });
      onCreate(resp?.data?.createCustomer);
    } catch (err) {
      toast.error("Unable to create customer");
    }
  };

  return (
    <ScrollableForm
      onSubmit={form.handleSubmit(submit)}
      title="Create Customer"
      submitLabel="Create"
      onCancel={onCancel}
      formState={form.formState}
    >
      <CustomerForm form={form} />
    </ScrollableForm>
  );
};
