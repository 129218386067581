import React, { useMemo } from "react";
import { InventoryTransferStatus } from "generated/graphql";
import BaseBadge from "./Base";

const InventoryTransferStatusBadge: React.FC<{
  status: string;
}> = ({ status }) => {
  const props = useMemo(() => {
    switch (status) {
      case InventoryTransferStatus.Draft:
        return {
          text: "Draft",
          style: "bg-transparent border border-gray-600 dark:border-gray-400",
          textStyle: "font-semibold text-gray-600 dark:text-gray-300 text-xs text-center",
        };
      case InventoryTransferStatus.Pending:
        return {
          text: "Pending",
          style: "bg-transparent border border-blue-500 dark:border-blue-400",
          textStyle: "font-semibold text-blue-500 dark:text-blue-400 text-xs text-center",
        };

      case InventoryTransferStatus.InTransit:
        return {
          text: "In Transit",
          style: "bg-transparent border border-green-400 dark:border-green-400",
          textStyle: "font-semibold border-green-400 text-xs text-center",
        };

      case InventoryTransferStatus.Received:
        return {
          text: "Received",
          style: "bg-green-400",
          textStyle: "font-semibold text-white text-xs text-center",
        };

      case InventoryTransferStatus.Cancelled:
        return {
          text: "Cancelled",
          style: "bg-transparent border border-brand dark:border-brand",
          textStyle: "font-semibold text-brand dark:text-brand text-xs text-center",
        };
      default:
        return {
          text: "Unknown",
          style: "bg-gray-400",
          textStyle: "font-semibold text-white text-xs text-center",
        };
    }
  }, [status]);

  return <BaseBadge {...props} />;
};

export { InventoryTransferStatusBadge as default };
