import { faBolt, faCog, faEdit, faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonWithIcon, PlainBlueButton } from "components/Buttons";
import { ButtonLoading } from "components/Loading";
import { SelectPagination } from "components/Select/Pagination";
import { usePrompt } from "hooks/prompt";
import React from "react";
import { BlueButton, IconButton, Tooltip } from "styles";
import { useTable } from "./context";
import { useEditTable } from "./useEditTable";
import { useColumns } from "hooks/useOptionalColumns";

export const TableHeader = () => {
  const { hasUnsavedChanges, save, isEditMode, setEditMode, isSaving, reset } = useEditTable();
  const { setIsModifying } = useColumns();

  usePrompt(hasUnsavedChanges);
  const {
    setPagination,
    pagination,
    setShowFilters,
    filters,
    refresh,
    showQuickFilters,
    setShowQuickFilters,
    selected,
  } = useTable();

  const handleSave = async () => {
    await save();
    setEditMode(false);
  };

  const cancelEditMode = () => {
    reset();
    refresh();
  };

  return (
    <div className="flex justify-between items-center py-2">
      <div className="px-2">{selected.size > 0 && <div>{selected.size} rows selected</div>}</div>

      <div className="flex">
        {isEditMode ? (
          <div className="flex flex-row items-center">
            <PlainBlueButton className="mx-2" onClick={cancelEditMode}>
              Cancel
            </PlainBlueButton>
            <BlueButton disabled={!hasUnsavedChanges} type="button" onClick={handleSave}>
              {isSaving ? <ButtonLoading /> : "Save"}
            </BlueButton>
          </div>
        ) : (
          <ButtonWithIcon
            disabled={filters?.locations?.length !== 1}
            data-testid="btn-edit-mode"
            className="bg-gray-500"
            icon={faEdit}
            onClick={() => setEditMode(!isEditMode)}
            type="button"
          >
            Edit Mode
          </ButtonWithIcon>
        )}

        <div className="flex flex-row items-center px-2">
          <Tooltip arrow title={showQuickFilters ? `Hide quick filters` : `Show quick filters`}>
            <IconButton
              className={showQuickFilters ? `text-yellow-500 ` : ``}
              onClick={() => setShowQuickFilters(!showQuickFilters)}
            >
              <FontAwesomeIcon icon={faBolt} size="lg" />
            </IconButton>
          </Tooltip>

          <Tooltip arrow title="Filters">
            <IconButton className="" onClick={() => setShowFilters(true)}>
              <FontAwesomeIcon icon={faSlidersH} size="lg" />
            </IconButton>
          </Tooltip>

          <Tooltip arrow title="Edit Columns">
            <IconButton className="" onClick={() => setIsModifying(true)}>
              <FontAwesomeIcon icon={faCog} size="lg" />
            </IconButton>
          </Tooltip>
        </div>
        <SelectPagination value={pagination?.perPage} onChange={(perPage) => setPagination({ perPage })} />
      </div>
    </div>
  );
};
