import React, { useMemo } from "react";
import BaseBadge from "./Base";
import { CustomerOrderPaidStatus } from "generated/graphql";

export const PaidStatus: React.FC<{
  status: CustomerOrderPaidStatus;
}> = ({ status }) => {
  const props = useMemo(() => {
    switch (status) {
      case CustomerOrderPaidStatus.Paid:
        return {
          text: "Paid",
          style: "bg-green-400",
          textStyle: "font-semibold text-white text-xs text-center",
        };
      case CustomerOrderPaidStatus.Authorized:
        return {
          text: "Authorized",
          style: "bg-transparent border border-gray-600 dark:border-gray-400",
          textStyle: "font-semibold text-gray-600 dark:text-gray-300 text-xs text-center",
        };
      case CustomerOrderPaidStatus.PartiallyPaid:
        return {
          text: "Partially paid",
          style: "bg-transparent border border-gray-600 dark:border-gray-400",
          textStyle: "font-semibold text-gray-600 dark:text-gray-300 text-xs text-center",
        };
      case CustomerOrderPaidStatus.PartiallyRefunded:
        return {
          text: "Partially refunded",
          style: "bg-transparent border border-gray-600 dark:border-gray-400",
          textStyle: "font-semibold text-gray-600 dark:text-gray-300 text-xs text-center",
        };
      case CustomerOrderPaidStatus.Pending:
        return {
          text: "Pending",
          style: "bg-transparent border border-gray-600 dark:border-gray-400",
          textStyle: "font-semibold text-gray-600 dark:text-gray-300 text-xs text-center",
        };
      case CustomerOrderPaidStatus.Refunded:
        return {
          text: "Refunded",
          style: "bg-transparent border border-gray-600 dark:border-gray-400",
          textStyle: "font-semibold text-gray-600 dark:text-gray-300 text-xs text-center",
        };
      case CustomerOrderPaidStatus.Unknown:
        return {
          text: "Unknown",
          style: "bg-transparent",
          textStyle: "font-semibold text-gray-600 dark:text-gray-300 text-xs text-center",
        };
      case CustomerOrderPaidStatus.Voided:
        return {
          text: "Voided",
          style: "bg-transparent border border-gray-600 dark:border-gray-400",
          textStyle: "font-semibold text-gray-600 dark:text-gray-300 text-xs text-center",
        };

      default:
        return {
          text: "",
          style: "",
          textStyle: "",
        };
    }
  }, [status]);

  return <BaseBadge {...props} />;
};
