import React, { useState, useCallback } from "react";
import { Workflow, useListWorkflowsQuery } from "generated/graphql";
import { AsyncSelect } from "styles";
import { debounce } from "lodash";

export const SelectWorkflow: React.FC<{
  disabled?: boolean;
  name?: string;
  className?: string;
  value?: Workflow[] | Workflow | string;
  onChange: (value: Workflow | Workflow[]) => void;
  size?: "base" | "large";
  placeholder?: string;
  isMulti?: boolean;
}> = ({
  isMulti = false,
  disabled = false,
  value,
  onChange,
  className = "",
  name = "",
  size = "base",
  placeholder = "Select Workflow...",
}) => {
  const { data, refetch, loading } = useListWorkflowsQuery();
  const [inputValue, setinputValue] = useState("");

  const handleInputChange = (newValue: string) => {
    setinputValue(newValue);
  };

  const fetchData = async (inputValue: string, callback: (items: Workflow[]) => void) => {
    const { data } = await refetch({ filters: { like: inputValue } });
    callback(data?.listWorkflows?.workflows);
  };

  // eslint-disable-next-line
  const delayedQuery = useCallback(
    debounce((inputValue, callback) => fetchData(inputValue, callback), 500),
    [inputValue]
  );

  return (
    <AsyncSelect
      isMulti={isMulti}
      isDisabled={disabled}
      isClearable
      isLoading={loading}
      $textSize={size}
      placeholder={placeholder}
      name={name}
      className={className}
      cacheOptions
      value={value}
      loadOptions={delayedQuery}
      defaultOptions={data?.listWorkflows?.workflows ?? []}
      onInputChange={handleInputChange}
      onChange={onChange}
      getOptionLabel={(opt: Workflow) => opt?.name}
      getOptionValue={(opt: Workflow) => opt?.id}
    />
  );
};
