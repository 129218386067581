import React from "react";
import { SideModal } from "components/Modals/SideModal";
import TitleWithLoading from "components/TitleWithLoading";
import { TableProvider, useTable } from "./context";
import Filters, { FiltersHeader } from "./Filters";
import Table from "./Table";

// const MoreActionsMenu = () => {
//   const getOrgQuery = useGetOrganizationQuery();
//   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

//   const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   return (
//     <>
//       <button
//         type="button"
//         data-testid="btn-import-from-csv"
//         className="mx-3 px-3 py-2 border border-gray-700 rounded"
//         onClick={handleClick}
//       >
//         More Actions{" "}
//         <span className="ml-2">
//           <FontAwesomeIcon icon={faCaretDown} />
//         </span>
//       </button>
//     </>
//   );
// };

const View = () => {
  const { setShowFilters, showFilters, loading, refetch } = useTable();

  return (
    <>
      <div className="py-2 px-2 flex-1 flex flex-col overflow-hidden">
        <div className="flex justify-between my-2">
          <TitleWithLoading title="Invoices" loading={loading} refresh={refetch} />
          <div className="flex items-center">
            {/* <MoreActionsMenu /> */}

            {/* <ButtonWithIcon className="bg-brand" icon={faPlusCircle}>
              Create Invoice
            </ButtonWithIcon> */}
          </div>
        </div>

        <FiltersHeader />
        <Table />
      </div>

      <SideModal side="RIGHT" isOpen={showFilters} onRequestClose={() => setShowFilters(false)} className="w-1/4">
        <Filters />
      </SideModal>
    </>
  );
};

export const SalesOrderInvoices = () => {
  return (
    <TableProvider>
      <View />
    </TableProvider>
  );
};
