import React, { useRef, useState } from "react";
import { WorkflowVersion } from "generated/graphql";
import { RedButton, PlainButton, RedOutlineButton, SubHeading } from "styles";
import { animated, useSpring } from "react-spring";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft, faArrowRight, faWrench, faExpand, faCompress } from "@fortawesome/free-solid-svg-icons";
import { useFullscreen, useToggle } from "react-use";
import { FlowChart } from "@mrblenny/react-flow-chart";
import { useToast } from "hooks/toast";
import CanvasOuter from "components/Flowchart/CanvasOuter";
import Node from "components/Flowchart/Node";
import { ButtonLoading } from "components/Loading";
import { WorkflowEditorProvider, useWorkflowEditor } from "./context";
import StepNode from "./StepNode";
import Sidebar from "./Sidebar";
import { validateLink } from "./chart";
import PortCustom from "./PortCustom";
import TextInput from "components/Form/TextInput";
import { gsEvent } from "components/Events/events";

const WorkflowEditor: React.FC<{
  loading: boolean;
}> = ({ loading = false }) => {
  const { workflowVersion, chart, errors, addStartStep, setWorkflowName, removeLink, dirty, callbacks, save, saving } =
    useWorkflowEditor();

  const [hideDetails, sethideDetails] = useState(true);

  const linkActionsStyleProps = useSpring({
    transform: chart?.selected?.type === "link" ? "translateY(0%)" : "translateY(-100%)",
  });
  const detailsStyleProps = useSpring({
    transform: hideDetails ? "translateX(-150%)" : "translateX(0%)",
  });
  const showDetailsButtonStyleProps = useSpring({
    transform: !hideDetails ? "translateX(-150%)" : "translateX(0%)",
  });

  const toast = useToast();
  const ref = useRef(null);
  const [show, toggle] = useToggle(false);
  const isFullscreen = useFullscreen(ref, show, {
    onClose: () => toggle(false),
  });

  const saveWorkflow = async () => {
    if (errors?.chart?.length > 0) {
      toast.error(`Workflow is Invalid: ${errors?.chart?.join(", ")}`);
      return;
    }

    if (workflowVersion?.name === "") {
      toast.error(`Please give this workflow a name`);
      return;
    }

    try {
      await save();
      toast.success(workflowVersion.id ? `"${workflowVersion.name}" Updated` : `"${workflowVersion.name}" Created`);
      gsEvent("Saved Workflow");
    } catch (err) {
      toast.error("Unable to update workflow");
    }
  };

  if (loading) {
    return <div>Loading</div>;
  }

  return (
    <>
      <div className="px-2">
        <SubHeading>Name</SubHeading>
        <TextInput
          value={workflowVersion?.name}
          onChange={(e) => setWorkflowName(e.target.value)}
          placeholder="Workflow Name"
        />
        <SubHeading>Editor</SubHeading>
      </div>
      <div ref={ref} className="w-full bg-gray-400 overflow-hidden relative mt-2">
        <animated.div style={showDetailsButtonStyleProps} className="absolute top-0 left-0 z-20 my-4 text-white">
          <button
            onClick={() => sethideDetails(false)}
            style={{ width: "50px", height: "50px" }}
            className="bg-brand focus:outline-none"
          >
            <FontAwesomeIcon icon={faWrench} />
          </button>
        </animated.div>
        <animated.div
          style={detailsStyleProps}
          className="absolute top-0 left-0 z-20 my-4 mx-4 flex flex-col w-full md:w-1/2 lg:w-1/6 xl:w-1/8"
        >
          <RedButton className="my-2" onClick={() => addStartStep()}>
            Add Start Step
          </RedButton>

          <PlainButton onClick={() => sethideDetails(true)}>
            <span className="mr-1">
              <FontAwesomeIcon icon={faArrowCircleLeft} />
            </span>
            Hide
          </PlainButton>
        </animated.div>
        <FlowChart
          chart={chart}
          callbacks={callbacks}
          Components={{
            CanvasOuter: CanvasOuter,
            NodeInner: StepNode,
            Node: Node,
            Port: PortCustom,
          }}
          config={{
            validateLink: validateLink(toast),
          }}
        />
        );
        <Sidebar />
        <div
          className="absolute bottom-0 right-0 py-2 px-3 text-2xl hover:text-black cursor-pointer"
          onClick={() => toggle()}
        >
          <FontAwesomeIcon icon={isFullscreen ? faCompress : faExpand} />
        </div>
        <div
          className="absolute top-0 right-0 py-2 px-3 hover:text-black cursor-pointer"
          onClick={() => saveWorkflow()}
        >
          <RedButton disabled={!dirty || saving}>{saving ? <ButtonLoading /> : "Save"}</RedButton>
        </div>
        <animated.div
          style={linkActionsStyleProps}
          className="absolute top-0 left-0 right-0 bg-white dark:bg-gray-800 z-20 py-2 shadow-lg flex justify-end px-2"
        >
          <div className="flex-1 flex items-center text-lg font-semibold">
            <div className="mr-4">
              {chart?.nodes?.[chart?.links?.[chart?.selected?.id]?.from?.nodeId]?.properties?.step?.name ?? "No Name"}
            </div>
            <FontAwesomeIcon icon={faArrowRight} />
            <div className="ml-4">
              {chart?.nodes?.[chart?.links?.[chart?.selected?.id]?.to?.nodeId]?.properties?.step?.name ?? "No Name"}
            </div>
          </div>

          <RedOutlineButton onClick={() => removeLink(chart?.selected?.id)}>Remove Link</RedOutlineButton>
        </animated.div>
      </div>
    </>
  );
};

const Container: React.FC<{
  loading: boolean;
  workflowVersion: WorkflowVersion;
}> = ({ workflowVersion, loading }) => {
  return (
    <WorkflowEditorProvider workflowVersion={workflowVersion}>
      <WorkflowEditor loading={loading} />
    </WorkflowEditorProvider>
  );
};

export { Container as default };
