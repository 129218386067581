import React, { useMemo } from "react";
import BaseBadge from "./Base";

export const PickStatus: React.FC<{
  status: string;
}> = ({ status }) => {
  const props = useMemo(() => {
    switch (status) {
      case "NOT_PICKED":
        return {
          text: "Not Picked",
          style: "bg-transparent border border-gray-600 dark:border-gray-400",
          textStyle: "font-semibold text-gray-600 dark:text-gray-300 text-xs text-center",
        };

      case "PARTIALLY_PICKED":
        return {
          text: "Partially Picked",
          style: "bg-gray-300",
          textStyle: "font-semibold text-gray-800 text-xs text-center",
        };

      case "PICKED":
        return {
          text: "Picked",
          style: "bg-green-400",
          textStyle: "font-semibold text-white text-xs text-center",
        };

      default:
        return {
          text: "Unknown",
          style: "bg-gray-400",
          textStyle: "font-semibold text-white text-xs text-center",
        };
    }
  }, [status]);

  return <BaseBadge {...props} />;
};
