import {
  actions,
  IChart,
  IFlowChartCallbacks,
  ILink,
  IOnDeleteKey,
  IOnDragCanvasStop,
  IOnDragCanvasStopInput,
  IOnDragNodeStop,
  IOnDragNodeStopInput,
  IOnLinkClick,
  IOnLinkComplete,
  IOnLinkCompleteInput,
  IOnNodeClick,
  IOnNodeDoubleClick,
  IOnZoomCanvas,
  ISelectedOrHovered,
} from "@mrblenny/react-flow-chart";
import { LocalStorageKeys } from "config";
import {
  ListWorkflowsDocument,
  Step,
  StepEdgeInput,
  StepNodeInput,
  useCreateWorkflowMutation,
  useUpdateWorkflowMutation,
  WorkflowStep,
  WorkflowVersion,
} from "generated/graphql";
import { noop } from "lodash";
import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo } from "react";
import { useHistory } from "react-router";
import { useDeepCompareEffect, useSessionStorage } from "react-use";
import { getRouteWithParams, Routes } from "routes";
import { useImmerReducer } from "use-immer";
import { v4 } from "uuid";
import { IStepNode, newChildStep, newStartStep, stepsToChartData, StepTypes } from "./types";

const defaultWorkflow: WorkflowVersion = {
  id: "",
  name: "",
  nodes: [
    {
      id: "first-step",
      depth: 0,
      step: {
        id: null,
      },
    },
    {
      id: "last-step",
      depth: 1,
      step: {
        id: null,
      },
    },
  ],
  edges: [],
};

enum Actions {
  Init = "INIT",
  AddStartStep = "ADD_START_STEP",
  AddChildStep = "ADD_CHILD_STEP",
  AddLink = "ADD_LINK",
  RemoveStep = "REMOVE_STEP",
  RemoveLink = "REMOVE_LINK",
  UpdateStep = "UPDATE_STEP",
  SetIsEditingStep = "SET_IS_EDITING_STEP",
  SetWorkflowName = "SET_WORKFLOW_NAME",
  UpdateChart = "UPDATE_CHART",
  InvalidConfiguration = "INVALID_CONFIGURATION",
  SetSelected = "SET_SELECTED",
  Saved = "SAVED",
  OnDragCanvasStop = "ON_DRAG_CANVAS_STOP",
  OnZoomCanvas = "ON_ZOOM_CANVAS",
  OnLinkComplete = "ON_LINK_COMPLETE",
  OnDragNodeStop = "ON_DRAG_NODE_STOP",
}

interface Errors {
  chart: string[];
  nodes: Record<string, string[]>;
  ports: Set<string>;
}

type Saved = {
  type: Actions.Saved;
};

type AddChildStep = {
  type: Actions.AddChildStep;
  data: IStepNode;
};

type AddStartStep = {
  type: Actions.AddStartStep;
  data: IStepNode;
};

type Initialize = {
  type: Actions.Init;
  data: WorkflowVersion;
};

type SetWorkflowName = {
  type: Actions.SetWorkflowName;
  data: string;
};

type UpdateChart = {
  type: Actions.UpdateChart;
  data: IChart<undefined, WorkflowStep>;
};

type UpdateStep = {
  type: Actions.UpdateStep;
  data: IStepNode;
};

type SetIsEditingStep = {
  type: Actions.SetIsEditingStep;
  data: Step;
};

type RemoveStep = {
  type: Actions.RemoveStep;
  data: { nodes: Record<string, IStepNode>; links: Record<string, ILink> };
};

type RemoveLink = {
  type: Actions.RemoveLink;
  data: ILink;
};

type AddLink = {
  type: Actions.AddLink;
  data: ILink;
};

type InvalidConfiguration = {
  type: Actions.InvalidConfiguration;
  data: Errors;
};

type SetSelected = {
  type: Actions.SetSelected;
  data: ISelectedOrHovered;
};

type OnDragCanvasStop = {
  type: Actions.OnDragCanvasStop;
  data: IOnDragCanvasStopInput;
};

type OnLinkComplete = {
  type: Actions.OnLinkComplete;
  data: IOnLinkCompleteInput;
};

type OnZoomCanvas = {
  type: Actions.OnZoomCanvas;
  data: any;
};

type OnDragNodeStop = {
  type: Actions.OnDragNodeStop;
  data: IOnDragNodeStopInput;
};

type DispatcherAction =
  | Saved
  | AddStartStep
  | AddChildStep
  | Initialize
  | UpdateChart
  | InvalidConfiguration
  | UpdateStep
  | RemoveStep
  | SetSelected
  | RemoveLink
  | AddLink
  | OnDragCanvasStop
  | OnZoomCanvas
  | OnLinkComplete
  | OnDragNodeStop
  | SetWorkflowName
  | SetIsEditingStep;

interface StateIFace {
  dirty: boolean;
  workflowVersion: WorkflowVersion;
  chart?: IChart<undefined, WorkflowStep>;
  errors?: Errors;
  isEditingStep?: Step;
}

const initialState: StateIFace = {
  workflowVersion: defaultWorkflow,
  chart: stepsToChartData(defaultWorkflow),
  dirty: false,
  errors: {
    chart: [],
    nodes: {},
    ports: new Set<string>(),
  },
  isEditingStep: null,
};

const workflowEditorCtx = createContext<{
  state: StateIFace;
  dispatch: React.Dispatch<DispatcherAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

const getLastStepNode = (chart: IChart<undefined, WorkflowStep>) =>
  chart?.nodes?.[Object.keys(chart.nodes).find((nodeId) => chart.nodes[nodeId].type === StepTypes.End)];

const findNodesOfType = (chart: IChart<undefined, WorkflowStep>, type: StepTypes) =>
  Object.keys(chart.nodes).filter((nodeId) => chart?.nodes[nodeId]?.type === type);

const getStartSteps = (chart: IChart<undefined, WorkflowStep>) =>
  Object.keys(chart?.nodes ?? {})
    .filter((nodeId) => chart?.nodes[nodeId]?.type === StepTypes.Start)
    .map((nodeId) => chart?.nodes[nodeId]);

const calculateDepth = (
  chart: IChart<undefined, WorkflowStep, undefined, undefined>,
  n: IStepNode,
  count: number,
  results: number[]
) => {
  if (n.type === StepTypes.Start) {
    results.push(count);
  }

  const parentLinks = Object.keys(chart.links).filter((linkId) => chart.links[linkId].to.nodeId === n.id);

  if (parentLinks?.length === 0 && n.type) {
    results.push(-1);
  }

  parentLinks.forEach((linkId) => {
    calculateDepth(chart, chart.nodes[chart.links[linkId].from.nodeId], count + 1, results);
  });

  return Math.max(...results);
};

const getLinks = (chart: IChart<undefined, WorkflowStep>) =>
  Object.keys(chart.links).map((linkId) => chart.links[linkId]);

export const WorkflowEditorProvider: React.FC<PropsWithChildren<{ workflowVersion: WorkflowVersion }>> = ({
  children,
  workflowVersion,
}) => {
  const [workflowState, setworkflowState] = useSessionStorage<StateIFace>(
    LocalStorageKeys.WorkflowEditor,
    initialState
  );

  const [state, dispatch] = useImmerReducer((state: StateIFace, action: DispatcherAction) => {
    switch (action.type) {
      case Actions.SetWorkflowName:
        state.workflowVersion.name = action.data;
        state.dirty = true;
        break;
      case Actions.Saved:
        state.dirty = false;
        break;

      case Actions.Init:
        state.workflowVersion = action.data;
        state.chart = {
          ...stepsToChartData(action.data),
          selected: state?.chart?.selected ?? {},
          // ...position,
        };
        break;

      case Actions.UpdateChart:
        state.chart = { ...state.chart, ...action?.data };
        break;

      case Actions.OnLinkComplete:
        state.chart.links[action.data.linkId] = {
          id: action.data.linkId,
          from: {
            nodeId: action.data.fromNodeId,
            portId: action.data.fromPortId,
          },
          to: {
            nodeId: action.data.toNodeId,
            portId: action.data.toPortId,
          },
        };
        state.chart.nodes[action.data.toNodeId].properties.depth = calculateDepth(
          state.chart,
          state.chart.nodes[action.data.toNodeId],
          0,
          []
        );

        break;

      case Actions.OnDragNodeStop:
        state.dirty = true;
        break;

      case Actions.OnDragCanvasStop:
        break;
      case Actions.OnZoomCanvas:
        state.chart.scale = action?.data?.data?.scale;

        break;

      case Actions.AddStartStep:
      case Actions.AddChildStep:
      case Actions.UpdateStep:
        state.chart.nodes[action.data.id] = action.data;
        state.dirty = true;
        break;
      case Actions.AddLink:
        state.chart.links[action.data.id] = action.data;
        break;
      case Actions.RemoveLink:
        const toNode = state.chart.nodes[state.chart.links[action.data?.id]?.to?.nodeId];
        state.chart.links = Object.keys(state.chart.links)
          .filter((linkId) => state.chart.links[linkId].id !== action?.data?.id)
          .reduce((acc, linkId) => {
            acc[linkId] = state.chart.links[linkId];
            return acc;
          }, {} as Record<string, ILink>);
        if (toNode) {
          toNode.properties.depth = calculateDepth(state.chart, toNode, 0, []);
        }
        state.chart.selected = {};
        break;

      case Actions.RemoveStep:
        state.chart.nodes = action.data.nodes;
        state.chart.links = action.data.links;
        state.dirty = true;
        break;

      case Actions.InvalidConfiguration:
        state.errors = action.data;
        break;

      case Actions.SetSelected:
        state.chart.selected = action?.data;
        break;

      case Actions.SetIsEditingStep:
        state.isEditingStep = action.data;
        break;
      default:
        throw new Error("WorkflowEditorProvider error");
    }
  }, workflowState);

  useEffect(() => {
    if (!workflowVersion) {
      dispatch({ type: Actions.Init, data: defaultWorkflow });
    } else {
      dispatch({ type: Actions.Init, data: workflowVersion });
    }
  }, [dispatch, workflowVersion]);

  useEffect(() => {
    setworkflowState(state);

    return () => {
      window.sessionStorage.setItem(LocalStorageKeys.WorkflowEditor, JSON.stringify(initialState));
      window.sessionStorage.removeItem(LocalStorageKeys.WorkflowEditorPosition);
    };
  }, [state, setworkflowState]);

  return <workflowEditorCtx.Provider value={{ state, dispatch }}>{children}</workflowEditorCtx.Provider>;
};

export const useWorkflowEditor = () => {
  const { state, dispatch } = useContext(workflowEditorCtx);
  const history = useHistory();
  const [createWorkflow, createWorkflowMutation] = useCreateWorkflowMutation({
    refetchQueries: [{ query: ListWorkflowsDocument }],
  });
  const [updateWorkflow, updateWorkflowMutation] = useUpdateWorkflowMutation({
    refetchQueries: [{ query: ListWorkflowsDocument }],
  });

  const addStartStep = () => {
    const x = findNodesOfType(state.chart, StepTypes.Start)
      .map((nodeId) => state?.chart?.nodes[nodeId])
      .pop();
    const previousStartNode = x ? x : { position: { x: 75, y: 50 }, size: { width: 200 } };

    const newStep = newStartStep({
      position: {
        x: previousStartNode?.position?.x + previousStartNode?.size?.width + 25,
        y: previousStartNode?.position?.y,
      },
    });

    dispatch({ type: Actions.UpdateStep, data: newStep });
  };

  const addChildStep = (parent: IStepNode) => {
    const newChildDepth = parent?.properties?.depth + 1;
    const parentLinksAtDepth = getLinks(state.chart).filter(
      (link) =>
        link.from.nodeId === parent.id && state?.chart?.nodes?.[link?.to?.nodeId].properties?.depth === newChildDepth
    );

    const parentNode = {
      x: parent?.position?.x ?? 0,
      y: parent?.position?.y ?? 0,
      width: parent?.size?.width ?? 0,
      height: parent?.size?.height ?? 0,
      depth: parent?.properties?.depth ?? 0,
    };

    const child = newChildStep({
      position: {
        x: parentNode.x + (parentLinksAtDepth?.length ?? 0) * (parentNode.width + 25),
        y: parentNode.y + parentNode.height + 60,
      },
      properties: {
        depth: newChildDepth,
      },
    });

    dispatch({ type: Actions.AddChildStep, data: child });

    dispatch({
      type: Actions.AddLink,
      data: {
        id: v4(),
        from: {
          nodeId: parent?.id,
          portId: "output",
        },
        to: {
          nodeId: child?.id,
          portId: "input",
        },
      },
    });

    const lastStep = getLastStepNode(state.chart);
    dispatch({
      type: Actions.UpdateStep,
      data: {
        ...lastStep,
        position: {
          ...lastStep.position,
          y:
            child.position.y >= lastStep.position.y - parentNode.height
              ? parentNode.height + child.position.y + 50
              : lastStep.position.y,
        },
        properties: {
          ...lastStep.properties,
          depth:
            lastStep.properties.depth <= child.properties.depth
              ? child.properties.depth + 1
              : lastStep.properties.depth,
        },
      },
    });
  };

  const a: Record<string, any> = actions;

  const validateNode = useCallback(
    (node: IStepNode) => {
      const links = state?.chart?.links;
      const nodeLinks = Object.keys(links).reduce(
        (acc, linkId) => {
          if (links?.[linkId].to.nodeId === node.id && node.ports[links?.[linkId].to.portId]) {
            acc.incoming.push(links?.[linkId]);
          }

          if (links?.[linkId].from.nodeId === node.id && node.ports[links?.[linkId].from.portId]) {
            acc.outgoing.push(links?.[linkId]);
          }
          return acc;
        },
        {
          incoming: [],
          outgoing: [],
        } as { incoming: ILink[]; outgoing: ILink[] }
      );

      let errs = [];

      if (!node.properties?.step?.id) {
        errs.push("Must choose a step");
      }

      if (nodeLinks.incoming?.length < 1) {
        if (node.type === StepTypes.End || node.type === StepTypes.Intermediate) {
          errs.push("Missing incoming link");
        }
      }
      if (nodeLinks.outgoing?.length < 1) {
        if (node.type === StepTypes.Start || node.type === StepTypes.Intermediate) {
          errs.push("Missing outgoing link");
        }
      }

      return errs;
    },
    [state?.chart?.links]
  );

  const validate = useCallback(() => {
    const chart = state?.chart;
    const nodeIds = Object.keys(chart?.nodes);
    const errors: Errors = {
      chart: [],
      ports: new Set(),
      nodes: nodeIds.reduce((acc, nodeId) => {
        const errs = validateNode(state?.chart?.nodes?.[nodeId]);
        if (errs?.length > 0) {
          acc[nodeId] = errs;
        }
        return acc;
      }, {} as Record<string, string[]>),
    };

    if (nodeIds?.length < 1) {
      errors.chart.push("Must have at least 2 connected steps");
    }

    const nodeTypeCount = nodeIds?.reduce(
      (acc, nodeId) => {
        acc[state.chart.nodes[nodeId].type] += 1;
        return acc;
      },
      {
        [StepTypes.Start]: 0,
        [StepTypes.Intermediate]: 0,
        [StepTypes.End]: 0,
      } as Record<StepTypes, number>
    );

    if (nodeTypeCount[StepTypes.Start] < 1 || nodeTypeCount[StepTypes.End] < 1) {
      errors.chart.push("Must have at 1 start and 1 end step");
    }

    const nodePorts = nodeIds.flatMap((nodeId) => {
      return Object.keys(chart.nodes[nodeId]?.ports)?.map((portId) => {
        return {
          node: chart.nodes[nodeId],
          port: chart.nodes[nodeId]?.ports[portId],
        };
      });
    });

    const missing = nodePorts.filter((nodePort) => {
      const foundLink = Object.keys(chart.links).reduce((found, linkId, i) => {
        if (
          (nodePort.node.id === chart.links[linkId].from?.nodeId &&
            nodePort.port.id === chart.links[linkId].from?.portId) ||
          (nodePort.node.id === chart.links[linkId].to?.nodeId && nodePort.port.id === chart.links[linkId].to?.portId)
        ) {
          found = true;
        }
        return found;
      }, false);

      return !foundLink;
    });
    if (missing.length > 0) {
      errors.chart.push("Missing connections");
      missing?.forEach((m) => errors.ports.add(m?.node?.id + m?.port?.id));
    }

    const missingSteps = nodeIds
      .flatMap((nodeId) => nodeId)
      .filter((nodeId) => !chart?.nodes?.[nodeId]?.properties?.step?.id);

    if (missingSteps?.length > 0) {
      errors.chart.push("Missing Steps");
    }

    dispatch({
      type: Actions.InvalidConfiguration,
      data: errors,
    });
  }, [state.chart, dispatch, validateNode]);

  const removeStep = (node: IStepNode) => {
    const nodes = Object.keys(state.chart.nodes)
      .filter((nodeId) => nodeId !== node?.id)
      .reduce((acc, nodeId) => {
        acc[nodeId] = state.chart.nodes[nodeId];
        return acc;
      }, {} as Record<string, IStepNode>);

    const links = Object.keys(state.chart.links)
      .filter(
        (linkId) =>
          state.chart.links[linkId].from?.nodeId !== node?.id && state.chart.links[linkId].to?.nodeId !== node?.id
      )
      .reduce((acc, linkId) => {
        acc[linkId] = state.chart.links[linkId];
        return acc;
      }, {} as Record<string, ILink>);
    dispatch({ type: Actions.RemoveStep, data: { nodes, links } });
  };

  const removeLink = (id: string) => {
    dispatch({ type: Actions.RemoveLink, data: state.chart.links[id] });
  };

  const updateStep = (node: IStepNode) => {
    dispatch({ type: Actions.UpdateStep, data: node });
  };

  const updateSelectedStep = (step: Step) => {
    const node = selected as IStepNode;
    dispatch({
      type: Actions.UpdateStep,
      data: {
        ...node,
        properties: {
          ...node.properties,
          step,
        },
      },
    });
  };

  const selected = useMemo(() => {
    switch (state?.chart?.selected?.type) {
      case "node":
        return state.chart.nodes[state.chart.selected.id];
      case "link":
        return state.chart.links[state.chart.selected.id];

      default:
        return null;
    }
  }, [state?.chart?.selected, state?.chart?.nodes, state?.chart?.links]);

  const setSelected = (s: ISelectedOrHovered) => {
    dispatch({ type: Actions.SetSelected, data: s });
  };

  const setIsEditingStep = (s: Step) => {
    dispatch({ type: Actions.SetIsEditingStep, data: s });
  };

  const onDeleteKey: IOnDeleteKey = (chart) => {
    if (chart?.config?.readonly || state?.chart?.nodes?.[state?.chart?.selected?.id]?.type === StepTypes.End) {
      return;
    }

    if (state?.chart?.selected?.type === "node" && getStartSteps(state.chart)?.length > 1) {
      removeStep(state?.chart?.selected as IStepNode);
    }

    if (state?.chart?.selected?.type === "link") {
      removeLink(state?.chart?.selected?.id);
    }
  };

  const onNodeClick: IOnNodeClick = (input) => {
    setSelected({ type: "node", id: input?.nodeId });
  };

  const onLinkClick: IOnLinkClick = (input) => {
    setSelected({ type: "link", id: input?.linkId });
  };

  const onNodeDoubleClick: IOnNodeDoubleClick = (input) => {
    // setSelected({ type: "node", id: input?.nodeId });
    noop();
  };

  const onDragCanvasStop: IOnDragCanvasStop = (input) => {
    dispatch({ type: Actions.OnDragCanvasStop, data: input });
  };

  const onZoomCanvas: IOnZoomCanvas = (input) => {
    dispatch({ type: Actions.OnZoomCanvas, data: input });
  };

  const onLinkComplete: IOnLinkComplete = (input) => {
    const isValid = input.config.validateLink({ ...input, chart: state.chart });

    if (isValid) {
      dispatch({ type: Actions.OnLinkComplete, data: input });
    } else {
      dispatch({
        type: Actions.RemoveLink,
        data: state.chart.links[input.linkId],
      });
    }
  };

  const onDragNodeStop: IOnDragNodeStop = (input) => {
    dispatch({ type: Actions.OnDragNodeStop, data: input });
  };

  // Set up default callbacks
  const callbacks = Object.keys(actions).reduce<IFlowChartCallbacks>(
    (obj, key) => {
      obj[key] = (...args: any) => {
        const action = a[key];
        const newChartTransformer = action(...args);
        const newChart = newChartTransformer(state.chart);
        dispatch({ type: Actions.UpdateChart, data: newChart });
        return newChart;
      };
      return obj;
    },
    {
      onDragNode: null,
      onDragNodeStop: null,
      onDragCanvas: null,
      onCanvasDrop: null,
      onDragCanvasStop: null,
      onLinkStart: null,
      onLinkMove: null,
      onLinkComplete: null,
      onLinkCancel: null,
      onPortPositionChange: null,
      onLinkMouseEnter: null,
      onLinkMouseLeave: null,
      onLinkClick: null,
      onCanvasClick: null,
      onDeleteKey: null,
      onNodeClick: null,
      onNodeDoubleClick: null,
      onNodeMouseEnter: null,
      onNodeMouseLeave: null,
      onNodeSizeChange: null,
      onZoomCanvas: null,
    }
  );

  // override defaults
  callbacks.onLinkComplete = onLinkComplete;
  callbacks.onDeleteKey = onDeleteKey;
  callbacks.onNodeClick = onNodeClick;
  callbacks.onNodeDoubleClick = onNodeDoubleClick;
  callbacks.onLinkClick = onLinkClick;
  callbacks.onDragCanvasStop = onDragCanvasStop;
  callbacks.onZoomCanvas = onZoomCanvas;
  callbacks.onDragNodeStop = onDragNodeStop;

  const save = useCallback(async () => {
    const nodes: StepNodeInput[] = Object.keys(state.chart.nodes).map((nodeId) => {
      return {
        id: nodeId,
        stepId: state.chart?.nodes?.[nodeId]?.properties?.step?.id,
        depth: state.chart?.nodes?.[nodeId]?.properties?.depth,
        position: state.chart?.nodes?.[nodeId].position,
      };
    });
    const edges: StepEdgeInput[] = Object.keys(state.chart.links).map((link) => {
      return {
        from: state.chart?.links?.[link]?.from?.nodeId,
        to: state.chart.links[link]?.to?.nodeId,
      };
    });

    console.log(state.chart);

    if (state.workflowVersion.id !== "") {
      await updateWorkflow({
        variables: {
          input: {
            id: state.workflowVersion.id,
            name: state.workflowVersion.name,
            nodes,
            edges,
          },
        },
      });
    } else {
      const resp = await createWorkflow({
        variables: {
          input: {
            name: state?.workflowVersion?.name ?? "No Name",
            nodes,
            edges,
          },
        },
      });
      history.push(
        getRouteWithParams(Routes.Workflow, {
          id: resp?.data?.createWorkflow?.id,
        })
      );
    }
    dispatch({ type: Actions.Saved });
  }, [state.workflowVersion, state.chart, createWorkflow, updateWorkflow, dispatch, history]);

  const setWorkflowName = useCallback(
    (name: string) => {
      dispatch({ type: Actions.SetWorkflowName, data: name });
    },
    [dispatch]
  );

  useDeepCompareEffect(() => {
    validate();
  }, [state?.chart?.nodes, state?.chart?.links]);

  return {
    ...state,
    callbacks,
    setWorkflowName,
    setIsEditingStep,
    updateStep,
    updateSelectedStep,
    addChildStep,
    validate,
    validateNode,
    selected,
    removeStep,
    removeLink,
    addStartStep,
    setSelected,
    save,
    saving: createWorkflowMutation?.loading || updateWorkflowMutation?.loading,
  };
};
