import React, { useEffect, useState } from "react";
import { Tooltip, SubHeading, IconButton } from "styles";
import TextInput from "components/Form/TextInput";
import { useParams } from "react-router-dom";
import {
  useGetCustomerQuery,
  useUpdateCustomerMutation,
  useDeleteCustomerAddressMutation,
  GetCustomerDocument,
} from "generated/graphql";
import { useForm, Controller } from "react-hook-form";
import { useToast } from "hooks/toast";
import { PhoneInput } from "components/Form/PhoneInput";
import { SideModal } from "components/Modals/SideModal";
import { Address } from "components/Address";
import TitleWithLoading from "components/TitleWithLoading";
import { AddAddress } from "./AddAddress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Avatar from "@material-ui/core/Avatar";
import { faUser, faPlusCircle, faShippingFast, faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import { ScrollableForm } from "components/Form/ScrollableForm";
import { Routes } from "routes";
import { useHistory } from "react-router-dom";
import IconButtonMenu from "components/IconButtonMenu";
import { SelectSalesOrderInvoicePaymentTerm } from "components/Select/SalesOrder/SalesOrderInvoicePaymentTerms";

interface CustomerParams {
  id: string;
}

interface CustomerFormData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  company: string;
  idempotencyKey: string;
  paymentTerms: string;
  shipVia: string;
}

const Customer = () => {
  const history = useHistory();
  const toast = useToast();
  const { id } = useParams<CustomerParams>();
  const { data } = useGetCustomerQuery({
    variables: { id },
  });
  const [addAddress, setaddAddress] = useState(false);
  const [updateCustomer] = useUpdateCustomerMutation();
  const [deleteCustomerAddress] = useDeleteCustomerAddressMutation({
    refetchQueries: [
      {
        query: GetCustomerDocument,
        variables: { id },
      },
    ],
  });
  const { register, handleSubmit, control, reset, formState } = useForm<CustomerFormData>({
    defaultValues: {
      idempotencyKey: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      company: "",
      paymentTerms: "",
      shipVia: "",
    },
  });
  const customer = data?.customer;

  useEffect(() => {
    console.log(customer?.phone);
    if (customer) {
      reset({
        idempotencyKey: customer?.idempotencyKey,
        firstName: customer?.firstName,
        lastName: customer?.lastName,
        email: customer?.email,
        phone: customer?.phone,
        company: customer?.company,
        paymentTerms: customer?.paymentTerms,
        shipVia: customer?.shipVia,
      });
    }
  }, [customer, reset]);

  const submit = async (data: CustomerFormData) => {
    try {
      const resp = await updateCustomer({
        variables: {
          input: {
            id,
            ...data,
            paymentTerms: data?.paymentTerms ?? "",
          },
        },
      });
      toast.success(`Updated ${resp?.data?.updateCustomer?.firstName} ${resp?.data?.updateCustomer?.lastName}`);
    } catch (err) {
      toast.error("Unable to update customer");
    }
  };

  return (
    <div className="py-2 px-2 flex-1 flex flex-col">
      <div className="my-2">
        <div className="text-gray-400 font-semibold text-sm">Customer</div>
        {customer?.firstName && customer?.lastName && (
          <>
            <TitleWithLoading title={`${customer?.firstName} ${customer?.lastName}`} refresh={() => {}} />
            {customer?.company && (
              <span className="text-white bg-gray-600 rounded-full px-4 py-1 text-sm inline">{customer?.company}</span>
            )}
          </>
        )}
        {!customer?.firstName && !customer?.lastName && customer?.company && (
          <TitleWithLoading title={`${customer?.company}`} refresh={() => {}} />
        )}
        {!customer?.firstName && !customer?.lastName && !customer?.company && (
          <TitleWithLoading title={`No name`} refresh={() => {}} />
        )}
      </div>
      <ScrollableForm
        formState={formState}
        onSubmit={handleSubmit(submit)}
        onCancel={() => history.push(Routes.Customers)}
        submitLabel="Save"
        classNames={{ buttonContainer: "w-full lg:w-1/3" }}
      >
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-x-2">
          <div className="flex justify-center py-12 xl:col-span-1">
            <Avatar aria-controls="avatar-customer" aria-haspopup="true" style={{ width: 200, height: 200 }}>
              <FontAwesomeIcon icon={faUser} size="5x" />
            </Avatar>
          </div>
          <div className="xl:col-span-2 grid grid-cols-1 gap-x-2">
            <TextInput label="Customer #" className="my-2" placeholder="###" {...register("idempotencyKey")} />

            <div className="grid grid-cols-2 gap-x-2 gap-y-2">
              <TextInput label="First Name" className="my-2" placeholder="First Name" {...register("firstName")} />
              <TextInput label="Last Name" className="my-2" placeholder="Last Name" {...register("lastName")} />
            </div>
            <TextInput label="Company" className="my-2" placeholder="Company" {...register("company")} />

            <TextInput label="Email" className="my-2" placeholder="Email" {...register("email")} />

            <Controller
              control={control}
              name="phone"
              render={({ field }) => {
                console.log(field.value);
                return (
                  <PhoneInput
                    className="my-2"
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    placeholder="Phone Number"
                    label="Phone Number"
                  />
                );
              }}
            />

            <TextInput label="Ship Via" className="my-2" placeholder="Ship Via" {...register("shipVia")} />

            <Controller
              control={control}
              name="paymentTerms"
              render={({ field }) => {
                return (
                  <SelectSalesOrderInvoicePaymentTerm
                    className="my-2"
                    label="Payment Terms"
                    value={field.value}
                    onChange={field.onChange}
                  />
                );
              }}
            />

            <div>
              <SubHeading className="flex items-center">
                Addresses{" "}
                <IconButton type="button" className=" text-blue-500" onClick={() => setaddAddress(true)}>
                  <FontAwesomeIcon icon={faPlusCircle} />
                </IconButton>
              </SubHeading>
              <div className="grid grid-cols-3 gap-y-3 gap-x-3">
                {customer?.addresses?.map((address) => (
                  <div key={address?.id} className="p-4 border border-gray-300 shadow dark:border-gray-600 rounded ">
                    <div className="flex">
                      <div className="flex-1">
                        <Address key={address?.id} data={address} />
                      </div>
                      <IconButtonMenu
                        options={[
                          {
                            label: "Set as Shipping Address",
                            onClick: () => {
                              updateCustomer({
                                variables: {
                                  input: {
                                    id,
                                    shippingAddressId: address.id,
                                  },
                                },
                              });
                            },
                          },
                          {
                            label: "Set as Billing Address",
                            onClick: () => {
                              updateCustomer({
                                variables: {
                                  input: {
                                    id,
                                    billingAddressId: address.id,
                                  },
                                },
                              });
                            },
                          },
                          {
                            label: <div className="text-brand">Delete</div>,
                            onClick: () => {
                              deleteCustomerAddress({ variables: { id: address.id } });
                            },
                          },
                        ]}
                      />
                    </div>
                    <div className="flex flex-row my-1 justify-end">
                      {address?.id === customer?.shippingAddress?.id && (
                        <Tooltip arrow title="Shipping Address">
                          <div className="mx-2 text-gray-600 dark:text-gray-100">
                            <FontAwesomeIcon icon={faShippingFast} size="sm" />
                          </div>
                        </Tooltip>
                      )}
                      {address?.id === customer?.billingAddress?.id && (
                        <Tooltip arrow title="Billing Address">
                          <div className="mx-2 text-gray-600 dark:text-gray-100">
                            <FontAwesomeIcon icon={faMoneyBill} size="sm" />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </ScrollableForm>
      <SideModal isOpen={addAddress} side="RIGHT" onRequestClose={() => setaddAddress(false)}>
        <AddAddress customerId={id} onRequestClose={() => setaddAddress(false)} />
      </SideModal>
    </div>
  );
};

export { Customer as default };
