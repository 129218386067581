import { useApolloClient } from "@apollo/client";
import { LoadingWithLabel } from "components/Loading";
import TitleWithLoading from "components/TitleWithLoading";
import WorkflowEditor from "components/Views/Workflow/WorkflowEditor";
import {
  GetWorkflowDocument,
  GetWorkflowQuery,
  GetWorkflowQueryVariables,
  useGetWorkflowQuery,
  WorkflowVersion,
} from "generated/graphql";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

interface WorkflowQueryParams {
  id: string;
  copyFrom?: string;
}

const CreateNew = () => {
  const { id } = useParams<WorkflowQueryParams>();
  const location = useLocation<{ copyFrom?: string }>();
  const client = useApolloClient();
  const [loading, setloading] = useState(false);
  const [workflowVersion, setworkflowVersion] = useState<WorkflowVersion>(null);

  const fetch = useCallback(
    async (id: string) => {
      const resp = await client.query<GetWorkflowQuery, GetWorkflowQueryVariables>({
        fetchPolicy: "network-only",
        query: GetWorkflowDocument,
        variables: {
          id,
        },
      });
      return resp?.data?.workflow;
    },
    [client]
  );

  const fetchWorkflow = useCallback(async () => {
    setloading(true);
    try {
      if (id === "new" && location?.state?.copyFrom) {
        const { latestVersion } = await fetch(location?.state?.copyFrom);
        setworkflowVersion({
          ...latestVersion,
          id: "",
          name: `${latestVersion?.name} (copy)`,
        });
      }
    } catch (err) {
      setworkflowVersion(null);
    } finally {
      setloading(false);
    }
  }, [fetch, id, location]);

  useEffect(() => {
    fetchWorkflow();
  }, [fetchWorkflow]);

  return (
    <div className="flex-1 relative flex flex-col overflow-hidden">
      <div className="px-2">
        <TitleWithLoading title="Create a new Workflow" loading={loading} />
      </div>
      {loading ? (
        <LoadingWithLabel label="Loading Workflow" />
      ) : (
        <WorkflowEditor workflowVersion={workflowVersion} loading={loading} />
      )}
    </div>
  );
};

const EditExisting: React.FC = () => {
  const { id } = useParams<WorkflowQueryParams>();
  const { data, loading } = useGetWorkflowQuery({ variables: { id } });
  return (
    <div className="flex-1 relative flex flex-col overflow-hidden">
      <div className="px-2 flex flex-row items-center">
        <TitleWithLoading title="Edit Workflow" loading={loading} />
      </div>
      {loading ? (
        <LoadingWithLabel label="Loading Workflow" />
      ) : (
        <WorkflowEditor workflowVersion={data?.workflow?.latestVersion} loading={loading} />
      )}
    </div>
  );
};

const WorkflowDetails: React.FC = () => {
  const { id } = useParams<WorkflowQueryParams>();

  if (id === "new") {
    return <CreateNew />;
  }

  return <EditExisting />;
};

export { WorkflowDetails as default };
