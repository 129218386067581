import React from "react";
import { Select } from "styles";
import { CustomerOrderFulfillmentStatus } from "generated/graphql";

const options = [
  CustomerOrderFulfillmentStatus.Fulfilled,
  CustomerOrderFulfillmentStatus.NotFulfilled,
  CustomerOrderFulfillmentStatus.Partial,
  CustomerOrderFulfillmentStatus.Restocked,
  CustomerOrderFulfillmentStatus.Unknown,
];

export const getCustomerOrderFulfillmentStatusLabel = (type: CustomerOrderFulfillmentStatus) => {
  switch (type) {
    case CustomerOrderFulfillmentStatus.Fulfilled:
      return "Fulfilled";
    case CustomerOrderFulfillmentStatus.NotFulfilled:
      return "Not Fulfilled";
    case CustomerOrderFulfillmentStatus.Partial:
      return "Partial";
    case CustomerOrderFulfillmentStatus.Restocked:
      return "Restocked";
    case CustomerOrderFulfillmentStatus.Unknown:
      return "Unknown";

    default:
      return type;
  }
};

export const getCustomerOrderFulfillmentStatusOption = (status: CustomerOrderFulfillmentStatus) => {
  return { label: getCustomerOrderFulfillmentStatusLabel(status), value: status };
};

export const SelectCustomerOrderFulfillmentStatuses: React.FC<{
  value: CustomerOrderFulfillmentStatus[];
  onChange: (opt: CustomerOrderFulfillmentStatus[]) => void;
  placeholder?: string;
  label?: string;
}> = ({ value, onChange, label, placeholder = "Filter by Status..." }) => {
  return (
    <div className="w-full">
      {label && <label className="text-xs">{label}</label>}
      <Select
        placeholder={placeholder}
        isClearable
        value={value}
        isMulti
        options={options}
        onChange={onChange}
        getOptionValue={(opt: CustomerOrderFulfillmentStatus) => opt}
        getOptionLabel={(opt: CustomerOrderFulfillmentStatus) => getCustomerOrderFulfillmentStatusLabel(opt)}
      />
    </div>
  );
};

export const SelectCustomerOrderFulfillmentStatus: React.FC<{
  value: CustomerOrderFulfillmentStatus;
  onChange: (opt: CustomerOrderFulfillmentStatus) => void;
  placeholder?: string;
  label?: string;
}> = ({ value, onChange, label, placeholder = "Select Status..." }) => {
  return (
    <div className="w-full">
      {label && <label className="text-xs">{label}</label>}
      <Select
        placeholder={placeholder}
        isClearable
        value={getCustomerOrderFulfillmentStatusOption(value)}
        options={options.map(getCustomerOrderFulfillmentStatusOption)}
        onChange={(opt: { label: string; value: CustomerOrderFulfillmentStatus }) => onChange(opt?.value)}
      />
    </div>
  );
};
