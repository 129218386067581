import React, { createContext, useContext } from "react";
import { useLocalStorage } from "react-use";
import { LocalStorageKeys } from "config";
export enum Theme {
  Dark = "dark",
  Light = "light",
  System = "system",
}
const themeCtx = createContext<{
  isDarkMode: boolean;
  theme: Theme;
  settheme: (t: Theme) => void;
}>(null);

const setClass = (cls: Theme) => {
  document.querySelector("html").classList.remove("dark", "light");
  document.querySelector("html").classList.add(cls);
};

const ThemeProvider: React.FC<React.PropsWithChildren<any>> = ({
  children,
}) => {
  const [savedtheme, settheme] = useLocalStorage(
    LocalStorageKeys.Theme,
    Theme.System
  );
  const getSystemTheme = () =>
    window.matchMedia("(prefers-color-scheme: dark)").matches
      ? Theme.Dark
      : Theme.Light;
  switch (savedtheme) {
    case Theme.System:
      setClass(getSystemTheme());
      break;

    default:
      setClass(savedtheme);
  }

  const theme = savedtheme === Theme.System ? getSystemTheme() : savedtheme;
  const isDarkMode = theme === Theme.Dark;

  return (
    <themeCtx.Provider value={{ isDarkMode, theme, settheme }}>
      {children}
    </themeCtx.Provider>
  );
};

export { ThemeProvider };

export const useTheme = () => {
  return useContext(themeCtx);
};
