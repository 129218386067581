import { faShopify } from "@fortawesome/free-brands-svg-icons";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { pdf } from "@react-pdf/renderer";
import cx from "classnames";
import { FulfillmentStatus } from "components/Badges/FulfillmentStatus";
import { PaidStatus } from "components/Badges/PaidStatus";
import { PickStatus } from "components/Badges/PickStatus";
import { PhoneNumber } from "components/Form/PhoneInput";
import { ScrollableForm } from "components/Form/ScrollableForm";
import { ITagInputValue, MultiTagInputWSuggest } from "components/Form/TagInput";
import TextAreaInput from "components/Form/TextAreaInput";
import { BirdOnlyLogo } from "components/Logo";
import { BottomModal } from "components/Modals/BottomModal";
import { SelectCustomerOrderFulfillmentStatus } from "components/Select/SalesOrder/SalesOrderFulfillmentStatus";
import { SelectCustomerOrderPaidStatus } from "components/Select/SalesOrder/SalesOrderPaidStatus";
import { ConfirmRemoveTag } from "components/Tags/ConfirmRemove";
import TitleWithLoading from "components/TitleWithLoading";
import { IconWithTooltip, Tooltip } from "components/Tooltip";
import {
  CustomerOrderFulfillmentStatus,
  CustomerOrderPaidStatus,
  CustomerOrderPickStatus,
  GetCustomerOrderDocument,
  LineItem,
  ResourceTag,
  useRemoveTagFromResourceMutation,
  useUpdateCustomerOrderMutation,
} from "generated/graphql";
import { useToast } from "hooks/toast";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { colors, H4, IconButton } from "styles";
import { removeAtIndex } from "utils/filters";
import { shortId } from "utils/formatters";
import { SalesOrderAttachments } from "./Attachments";
import { CustomerOrderProvider, useCustomerOrder } from "./context";
import { CreateInvoice, EditInvoice, IEditInvoiceFormLineItem } from "./Invoice";
import { SalesOrderPDF } from "./SalesOrder.pdf";
import { Table } from "./styles";

interface ICustomerOrderFormData {
  notes: string;
  tags: ResourceTag[];
  paidStatus: CustomerOrderPaidStatus;
  pickStatus: CustomerOrderPickStatus;
  fulfillmentStatus: CustomerOrderFulfillmentStatus;
}

const SalesOrderLineItem: React.FC<{ lineItem: LineItem }> = ({ lineItem }) => {
  const [hidden, sethidden] = useState(true);
  return (
    <tr key={lineItem.id}>
      <td className={lineItem?.deletedAt ? "line-through" : ""}>
        <div className="pb-1 text-base">
          {lineItem?.inventoryItem?.inventoryItem?.name}{" "}
          <button type="button" className="focus:outline-none focus:shadow-none" onClick={() => sethidden(!hidden)}>
            <span style={{ fontSize: 9 }} className="text-blue-400">
              {hidden ? "Hide" : "Details"}
            </span>
          </button>
        </div>
        <div style={{ fontSize: 10 }} className={cx(!hidden && "hidden", "pl-2")}>
          {lineItem?.inventoryItem?.inventoryItem?.tags?.map((t) => (
            <div key={t?.id} className="flex items-center">
              <div className="font-semibold">{t?.name}</div>
              <div className="mx-1">=</div>
              <div>{t?.value}</div>
            </div>
          ))}
        </div>
      </td>
      <td>{lineItem?.inventoryItem?.onHand}</td>
      <td>{lineItem?.quantity}</td>
      <td>{lineItem?.picked}</td>
      <td>{lineItem?.fulfilled}</td>
    </tr>
  );
};

const CustomerOrder = () => {
  const [editInvoice, setEditInvoice] = useState<any>(null);
  const [createInvoice, setCreateInvoice] = useState<{ lineItems: IEditInvoiceFormLineItem[] }>(null);
  const [confirmRemoveTag, setconfirmRemoveTag] = useState<ITagInputValue>(null);
  const toast = useToast();
  const { data, loading, refetch } = useCustomerOrder();
  const { register, handleSubmit, setValue, watch, formState, control } = useForm<ICustomerOrderFormData>({
    defaultValues: {
      notes: "",
      paidStatus: null,
      pickStatus: null,
      fulfillmentStatus: null,
      tags: [],
    },
  });
  const tags = watch("tags");

  const salesOrder = data?.customerOrder;

  const [removeTag, removeTagMutation] = useRemoveTagFromResourceMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GetCustomerOrderDocument, variables: { id: data?.customerOrder?.id } }],
  });
  const [updateCustomerOrder] = useUpdateCustomerOrderMutation();

  const printPDF = useCallback(async () => {
    const blob = await pdf(SalesOrderPDF({ order: data?.customerOrder })).toBlob();

    window.open(URL.createObjectURL(blob), "_blank", "noreferrer");
  }, [data?.customerOrder]);

  useEffect(() => {
    if (data?.customerOrder) {
      setValue("notes", data?.customerOrder?.notes ?? "");
      setValue("tags", data?.customerOrder?.tags ?? []);
      setValue("paidStatus", data?.customerOrder?.paidStatus ?? null);
      setValue("pickStatus", data?.customerOrder?.pickStatus ?? null);
      setValue("fulfillmentStatus", data?.customerOrder?.fulfillmentStatus ?? null);
    }
  }, [setValue, data]);

  const submit = async (formData: ICustomerOrderFormData) => {
    try {
      await updateCustomerOrder({
        variables: {
          input: {
            id: data?.customerOrder?.id,
            notes: formData?.notes ?? "",
            fulfillmentStatus: formData?.fulfillmentStatus,
            paidStatus: formData?.paidStatus,
            tags:
              formData?.tags?.map((field) => ({
                tagId: field.tag.id,
                value: field.value,
              })) ?? null,
          },
        },
      });
      toast.success("Updated customer order!");
    } catch (err) {
      toast.error("Unable to update customer order");
    }
  };

  return (
    <>
      <div className="py-2 px-2 mb-4">
        <div className="flex items-center">
          <TitleWithLoading
            title={
              <>
                Sales Order <span className="ml-2 text-brand">{shortId(data?.customerOrder?.id)}</span>
              </>
            }
            loading={loading}
            refresh={() => refetch()}
          />
          <IconButton onClick={printPDF}>
            <IconWithTooltip title="Print" icon={faPrint} />
          </IconButton>

          <div className="flex">
            <div className="mx-1">
              <PickStatus status={data?.customerOrder?.pickStatus} />
            </div>
            <div className="mx-1">
              <FulfillmentStatus status={data?.customerOrder?.fulfillmentStatus} />
            </div>

            <div className="mx-1">
              <PaidStatus status={data?.customerOrder?.paidStatus} />
            </div>
          </div>
        </div>
        <div className="flex items-center">
          {data?.customerOrder?.source === "factoryfinch" && (
            <Tooltip title="This is a Factory Finch order">
              <BirdOnlyLogo color={colors.factoryfinchRed} width={15} />
            </Tooltip>
          )}
          {data?.customerOrder?.source === "shopify" && (
            <>
              <IconWithTooltip
                title="This is a Shopify order"
                icon={faShopify}
                iconProps={{ size: "lg", color: colors.shopifyGreen }}
              />
              <div className="ml-2">{`Order #${data?.customerOrder?.orderNumber}`}</div>
            </>
          )}
        </div>
      </div>
      <ScrollableForm
        formState={formState}
        onSubmit={handleSubmit(submit)}
        onCancel={() => {}}
        submitLabel="Save"
        classNames={{ buttonContainer: "w-full lg:w-1/3" }}
      >
        <div className="flex flex-wrap">
          <div className="w-full lg:w-1/2 px-1">
            <div className="my-4">
              <H4 className="mb-2">Customer</H4>
              <div>{`${data?.customerOrder?.customer?.firstName} ${data?.customerOrder?.customer?.lastName}`}</div>
              <div>{data?.customerOrder?.customer?.company}</div>
              <a className="text-blue-600" href={`tel:+1${data?.customerOrder?.customer?.phone}`}>
                <PhoneNumber
                  value={data?.customerOrder?.customer?.phone}
                  className="bg-transparent focus:outline-none block"
                />
              </a>
              <a className="text-blue-600" href={`mailto:${data?.customerOrder?.customer?.email}`}>
                {data?.customerOrder?.customer?.email}
              </a>
            </div>

            <div className="my-4">
              <H4 className="mb-2">Line Items {data?.customerOrder?.lineItems?.length}</H4>

              <Table>
                <thead>
                  <tr>
                    <th className="w-2/3 text-xs">Item Name</th>
                    <th>On Hand</th>
                    <th>Quantity</th>
                    <th>Picked</th>
                    <th>Fulfilled</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.customerOrder?.lineItems.map((lineItem) => (
                    <SalesOrderLineItem key={lineItem?.id} lineItem={lineItem} />
                  ))}
                </tbody>
              </Table>
            </div>

            <div className="my-4">
              {data?.customerOrder?.source === "factoryfinch" && (
                <>
                  <H4 className="mb-2">Status</H4>
                  <Controller
                    name="paidStatus"
                    control={control}
                    render={({ field }) => {
                      return (
                        <SelectCustomerOrderPaidStatus
                          label="Paid Status"
                          value={field.value}
                          onChange={field.onChange}
                        />
                      );
                    }}
                  />

                  <Controller
                    name="fulfillmentStatus"
                    control={control}
                    render={({ field }) => {
                      return (
                        <SelectCustomerOrderFulfillmentStatus
                          label="Fulfillment Status"
                          value={field.value}
                          onChange={field.onChange}
                        />
                      );
                    }}
                  />
                </>
              )}
            </div>

            <div className="my-4">
              <H4 className="mb-2">Notes</H4>
              <TextAreaInput
                className="form-textarea my-2 p-2 w-full border border-gray-600 rounded"
                rows={6}
                placeholder="Notes..."
                {...register("notes")}
              />
            </div>
          </div>
          <div className="w-full lg:w-1/2 px-1">
            <div className="my-4">
              <SalesOrderAttachments />
            </div>

            {/* <div className="my-4">
              <H4 className="mb-2">
                Invoices
                <AddButton
                  onClick={() =>
                    setCreateInvoice({
                      lineItems: salesOrder?.lineItems?.map((li) => {
                        return {
                          inventoryItem: li?.inventoryItem?.inventoryItem,
                          pricePerUnit: li?.inventoryItem?.inventoryItem?.valuePerUnit ?? 0,
                          quantity: li?.quantity ?? 0,
                          description: "",
                          units: li?.inventoryItem?.inventoryItem?.units,
                          modifiers: [],
                        };
                      }),
                    })
                  }
                />
              </H4>
              <Invoices data={data?.customerOrder?.invoices ?? []} onClick={setEditInvoice} />
            </div> */}
            <div className="my-4">
              <H4 className="mb-2">Tags</H4>
              <Controller
                name="tags"
                control={control}
                render={({ field }) => {
                  return (
                    <MultiTagInputWSuggest
                      value={field.value}
                      onChange={field.onChange}
                      onRemove={(v, i) => {
                        if (v.id) {
                          setconfirmRemoveTag(v);
                        } else {
                          setValue("tags", removeAtIndex(tags, i));
                        }
                      }}
                      allowCreate
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
      </ScrollableForm>
      <ConfirmRemoveTag
        resourceTag={confirmRemoveTag}
        loading={removeTagMutation.loading}
        onCancel={() => setconfirmRemoveTag(null)}
        onConfirm={async () => {
          try {
            await removeTag({ variables: { id: confirmRemoveTag.id } });
          } catch (err) {
            toast.error("Unable to remove tag");
          } finally {
            setconfirmRemoveTag(null);
          }
        }}
      />

      <BottomModal isOpen={editInvoice !== null} onRequestClose={() => setEditInvoice(null)}>
        {editInvoice && (
          <EditInvoice
            id={editInvoice?.id}
            onCancel={() => setEditInvoice(null)}
            onEdit={() => {
              setEditInvoice(null);
              refetch();
            }}
          />
        )}
      </BottomModal>

      <BottomModal isOpen={createInvoice !== null} onRequestClose={() => setCreateInvoice(null)}>
        {createInvoice && (
          <CreateInvoice
            orderId={salesOrder?.id}
            defaults={createInvoice}
            onCancel={() => setCreateInvoice(null)}
            onCreate={() => {
              setCreateInvoice(null);
              refetch();
            }}
          />
        )}
      </BottomModal>
    </>
  );
};

const Container = () => {
  return (
    <CustomerOrderProvider>
      <CustomerOrder />
    </CustomerOrderProvider>
  );
};

export { Container as default };
