import Inventory from "components/Views/Inventory/Inventory";
import InventoryTransfers from "components/Views/InventoryTransfer/InventoryTransfers";
import Locations from "components/Views/Location/Locations";
import PurchaseOrders from "components/Views/PurchaseOrders";
import CreatePurchaseRequest from "components/Views/PurchaseRequest/Create";
import PurchaseRequests from "components/Views/PurchaseRequest/List";
import Vendors from "components/Views/Vendor/Vendors";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Routes } from "routes";

const Fulfillment = () => {
  return (
    <Switch>
      <Route exact path={Routes.PurchaseRequests} component={PurchaseRequests} />
      <Route exact path={Routes.PurchaseRequests} component={CreatePurchaseRequest} />
      <Route exact path={Routes.PurchaseOrders} component={PurchaseOrders} />
      <Route exact path={Routes.InventoryTransfers} component={InventoryTransfers} />

      <Route exact path={Routes.PurchasingInventory} component={Inventory} />
      <Route exact path={Routes.PurchasingLocations} component={Locations} />
      <Route exact path={Routes.Vendors} component={Vendors} />
      <Route exact path={Routes.CreatePurchaseRequest} component={CreatePurchaseRequest} />

      <Redirect to={Routes.PurchaseOrders} />
    </Switch>
  );
};

export { Fulfillment as default };
