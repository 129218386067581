import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { ButtonWithIcon } from "components/Buttons";
import { SideModal } from "components/Modals/SideModal";
import TitleWithLoading from "components/TitleWithLoading";
import { useListLocationsLazyQuery } from "generated/graphql";
import React from "react";
import { TableProvider, useLocationsTable } from "./context";
import AddLocation from "./Create";
import DeleteLocation from "./Delete";
import EditLocation from "./EditLocation";
import Table from "./Table";

const Locations = () => {
  const { setIsCreating, isCreating, setIsEditing, isEditing, loading, fetch, variables } = useLocationsTable();

  return (
    <>
      <div className="py-2 px-2 flex-1 flex flex-col overflow-hidden">
        <div className="flex justify-between my-2">
          <TitleWithLoading title="Locations" loading={loading} refresh={() => fetch({ variables })} />

          <ButtonWithIcon className="bg-brand" icon={faPlusCircle} onClick={() => setIsCreating(true)}>
            Add Location
          </ButtonWithIcon>
        </div>

        <Table />
      </div>

      <SideModal side="RIGHT" isOpen={isCreating} onRequestClose={() => setIsCreating(false)}>
        <AddLocation />
      </SideModal>

      <SideModal side="RIGHT" isOpen={isEditing !== null} onRequestClose={() => setIsEditing(null)}>
        <EditLocation />
      </SideModal>
      <DeleteLocation />
    </>
  );
};

const Container = () => {
  const query = useListLocationsLazyQuery({
    fetchPolicy: "network-only",
  });
  return (
    <TableProvider query={query} sessionKey="@factoryfinch/locations">
      <Locations />
    </TableProvider>
  );
};

export { Container as default };
