import { ScrollableForm } from "components/Form/ScrollableForm";
import { AttachmentResourceType, ListStepsDocument, useCreateStepMutation } from "generated/graphql";
import { useToast } from "hooks/toast";
import { useAttachment } from "hooks/upload";
import React from "react";
import { useForm } from "react-hook-form";
import { useTable } from "./context";
import StepForm, { IStepFormData } from "./StepForm";

const AddStep: React.FC = () => {
  const toast = useToast();
  const { setIsCreating, variables } = useTable();
  const [createStep] = useCreateStepMutation({
    refetchQueries: [{ query: ListStepsDocument, variables }],
  });

  const form = useForm<IStepFormData>({
    defaultValues: {
      name: "",
      points: 1,
      properties: [],
      attachments: [],
    },
  });
  const addAttachment = useAttachment();
  const submit = async (data: IStepFormData) => {
    try {
      const resp = await createStep({
        variables: {
          input: {
            name: data?.name,
            description: data?.description,
            points: data?.points,
            promptOperatorToConsumeInventory: data?.promptOperatorToConsumeInventory,
            properties: data?.properties,
          },
        },
      });
      for (const attachment of data?.attachments ?? []) {
        if (!attachment?.value?.file) {
          continue;
        }
        await addAttachment(attachment.value.file.item(0), {
          fileName: attachment.value.name,
          resourceId: resp?.data?.createStep?.id,
          resourceType: AttachmentResourceType.Step,
          onProgress: (e) => console.log(e),
        });
      }
      toast.success(`Added Step "${data?.name}"`);
    } catch (err) {
      toast.error("Unable to add step");
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <ScrollableForm
      title="Add Step"
      onSubmit={form.handleSubmit(submit)}
      onCancel={() => setIsCreating(false)}
      submitLabel="Save"
    >
      <StepForm form={form} />
    </ScrollableForm>
  );
};

export { AddStep as default };
