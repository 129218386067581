import TextInput from "components/Form/TextInput";
import IconButtonMenu from "components/IconButtonMenu";
import { CenterModal } from "components/Modals/CenterModal";
import { SupportedMimeTypes } from "config";
import {
  Attachment,
  AttachmentResourceType,
  GetManufacturingOrderDocument,
  useAddAttachmentToOrderMutation,
  useDeleteAttachmentMutation,
  useGetManufacturingOrderQuery,
} from "generated/graphql";
import { useToast } from "hooks/toast";
import { useUploadFile, useDownloadAttachment } from "hooks/upload";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { H4 } from "styles";
import { AddButton } from "components/Buttons";

const Attachments: React.FC<{ orderId: string }> = ({ orderId }) => {
  const toast = useToast();
  const uploadFile = useUploadFile();
  const downloadAttachment = useDownloadAttachment();
  const [uploadprogress, setuploadprogress] = useState(0);
  const [preview, setpreview] = useState<Attachment | null>(null);

  const { data, variables } = useGetManufacturingOrderQuery({
    variables: { id: orderId },
  });
  const [addAttachment] = useAddAttachmentToOrderMutation({
    refetchQueries: [{ query: GetManufacturingOrderDocument, variables }],
  });

  const [deleteAttachment] = useDeleteAttachmentMutation({
    refetchQueries: [{ query: GetManufacturingOrderDocument, variables }],
  });

  const onSelectFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0];

    try {
      const resp = await uploadFile(file, (progressEvent) => {
        setuploadprogress((progressEvent.loaded / progressEvent.total) * 100);
      });

      await addAttachment({
        variables: {
          input: {
            resourceId: orderId,
            resourceType: AttachmentResourceType.ManufacturingOrder,
            name: file?.name,
            url: resp?.data?.key,
            type: file?.type,
          },
        },
      });
      toast.success(`Successfully uploaded ${file?.name}`);
    } catch (err) {
      toast.error("Unable to upload Attachment");
    }
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (uploadprogress >= 100) {
      setuploadprogress(0);
    }
  }, [uploadprogress]);
  return (
    <>
      <H4 className="mb-2">
        Attachments
        <AddButton onClick={() => fileInputRef.current.click()} />
      </H4>
      <table className="w-full">
        <thead>
          <tr>
            <th className="text-sm text-left">Name</th>
            <th className="text-sm ">Type</th>
          </tr>
        </thead>
        <tbody>
          {data?.manufacturingOrder?.attachments?.map((attachment) => {
            return (
              <tr key={attachment?.id}>
                <td className="w-3/6 truncate text-blue-500" style={{ maxWidth: "1px" }}>
                  {attachment?.name}
                </td>
                <td className="w-2/6 text-center">{attachment?.type}</td>
                <td className="w-1/6 ">
                  <div className="flex justify-center items-center">
                    <IconButtonMenu
                      options={[
                        {
                          label: "Download",
                          onClick: () => downloadAttachment(attachment),
                        },
                        {
                          label: <div className="text-brand">Delete</div>,
                          onClick: () =>
                            deleteAttachment({
                              variables: { id: attachment?.id },
                            }),
                        },
                      ]}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="w-full mb-2">
        <div className="bg-brand h-4" style={{ width: `${uploadprogress}%` }}></div>
      </div>
      <div className="flex justify-end">
        {/* <BlueButton className="text-sm" type="button" onClick={() => fileInputRef.current.click()}>
          Choose File
        </BlueButton> */}
        <TextInput
          ref={fileInputRef}
          style={{ display: "none" }}
          accept={SupportedMimeTypes.join(",")}
          type="file"
          className="w-full"
          onChange={onSelectFile}
        />
      </div>
      <CenterModal isOpen={preview !== null} onRequestClose={() => setpreview(null)}>
        <img alt={preview?.name ?? ""} src={preview?.url} />
      </CenterModal>
    </>
  );
};

export { Attachments as default };
