import React from "react";
import TextInput from "components/Form/TextInput";
import NumberInput from "components/Form/NumberInput";
import { NumberFormatValues } from "react-number-format";
import { ListEmployeesDocument, useCreateEmployeeMutation, CreateEmployeeInput } from "generated/graphql";
import { useToast } from "hooks/toast";
import { useEmployeesTable } from "./context";
import { useForm, Controller } from "react-hook-form";
import { ScrollableForm } from "components/Form/ScrollableForm";
import { PhoneInput } from "components/Form/PhoneInput";
import { gsEvent } from "components/Events/events";

const AddEmployee: React.FC = () => {
  const toast = useToast();
  const { variables, setIsCreating } = useEmployeesTable();
  const [createEmployee] = useCreateEmployeeMutation({
    refetchQueries: [{ query: ListEmployeesDocument, variables }],
  });
  const { register, control, handleSubmit, formState } = useForm<CreateEmployeeInput>();

  const submit = async (formData: CreateEmployeeInput) => {
    try {
      await createEmployee({
        variables: {
          input: {
            name: formData?.name,
            phoneNumber: formData?.phoneNumber,
            email: formData?.email,
            code: formData?.code,
          },
        },
      });
      gsEvent("Created Operator");
      setIsCreating(false);
    } catch (err) {
      console.log(err);
      toast.error("Unable to add Operator");
    }
  };

  return (
    <ScrollableForm
      title="Add Operator"
      onSubmit={handleSubmit(submit)}
      onCancel={() => setIsCreating(false)}
      submitLabel="Save"
      formState={formState}
    >
      <TextInput className="my-2" placeholder="Name" {...register("name")} />
      <Controller
        control={control}
        name="code"
        render={({ field }) => (
          <NumberInput
            className="my-2"
            format="###"
            placeholder="Code"
            name={field.name}
            onValueChange={(value: NumberFormatValues) => field.onChange(value.value)}
          />
        )}
      />
      <Controller
        control={control}
        name="phoneNumber"
        render={({ field }) => <PhoneInput value={field.value} onChange={field.onChange} />}
      />
      <TextInput className="my-2" placeholder="Email" {...register("email")} />
    </ScrollableForm>
  );
};

export { AddEmployee as default };
