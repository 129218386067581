import React from "react";
import { InventoryItemType } from "generated/graphql";
import { Select } from "styles";

export const getInventoryItemTypeLabel = (type: InventoryItemType) => {
  switch (type) {
    case InventoryItemType.RawMaterial:
      return "Raw Material";
    case InventoryItemType.IntermediateGood:
      return "Intermediate Good";
    case InventoryItemType.FinishedGood:
      return "Finished Good";
    case InventoryItemType.Unknown:
      return "Draft";

    default:
      return "";
  }
};

export const getInventoryItemOption = (type: InventoryItemType) => {
  return { label: getInventoryItemTypeLabel(type), value: type };
};

export const SelectInventoryItemTypes: React.FC<{
  value: InventoryItemType[];
  onChange: (opt: InventoryItemType) => void;
  placeholder?: string;
}> = ({ value, onChange, placeholder = "Filter by Type..." }) => {
  return (
    <Select
      placeholder={placeholder}
      isClearable
      value={value}
      isMulti
      options={[
        InventoryItemType.RawMaterial,
        InventoryItemType.IntermediateGood,
        InventoryItemType.FinishedGood,
        InventoryItemType.Unknown,
      ]}
      onChange={onChange}
      getOptionValue={(opt: InventoryItemType) => opt}
      getOptionLabel={(opt: InventoryItemType) => getInventoryItemTypeLabel(opt)}
    />
  );
};

export const SelectInventoryItemType: React.FC<{
  value: InventoryItemType;
  onChange: (opt: InventoryItemType) => void;
  placeholder?: string;
}> = ({ value, onChange, placeholder = "Select Type..." }) => {
  return (
    <Select
      placeholder={placeholder}
      isClearable
      value={getInventoryItemOption(value)}
      options={[
        InventoryItemType.RawMaterial,
        InventoryItemType.IntermediateGood,
        InventoryItemType.FinishedGood,
        InventoryItemType.Unknown,
      ].map(getInventoryItemOption)}
      onChange={(opt: { label: string; value: InventoryItemType }) => onChange(opt?.value)}
    />
  );
};
