import React from "react";
import { SubHeading, GreenBadge, RedBadge } from "styles";
import { useTable } from "./context";
import { useForm, Controller } from "react-hook-form";
import { Tooltip } from "styles";
import TextInput from "components/Form/TextInput";
import { ScrollableForm } from "components/Form/ScrollableForm";
import Checkbox from "components/Form/Checkbox";

interface IFiltersFormData {
  name: string;
  showDeleted: boolean;
}

const defaultValues: IFiltersFormData = {
  name: "",
  showDeleted: false,
};

const FiltersHeader = () => {
  const { filters, removeFilter } = useTable();

  return (
    <div className="flex flex-wrap">
      {filters?.like && (
        <Tooltip arrow title="Remove">
          <GreenBadge
            className="mx-1 my-1 cursor-pointer"
            onClick={() => removeFilter("like")}
          >{`Name contains: ${filters?.like}`}</GreenBadge>
        </Tooltip>
      )}

      {filters?.showDeleted && (
        <Tooltip arrow title="Remove">
          <RedBadge className="mx-1 my-1 cursor-pointer" onClick={() => removeFilter("showDeleted")}>
            Show Deleted
          </RedBadge>
        </Tooltip>
      )}
    </div>
  );
};

const Filters = () => {
  const { setFilters, filters, setShowFilters } = useTable();
  const { handleSubmit, register, control } = useForm<IFiltersFormData>({
    defaultValues: {
      ...defaultValues,
      name: filters?.like,
    },
  });

  const onSubmit = (formData: IFiltersFormData) => {
    setFilters({ like: formData?.name, showDeleted: formData.showDeleted });
    setShowFilters(false);
  };

  return (
    <ScrollableForm
      title="Filters"
      onSubmit={handleSubmit(onSubmit)}
      onCancel={() => setShowFilters(false)}
      submitLabel="Apply Filters"
    >
      <SubHeading>Name</SubHeading>
      <TextInput {...register("name")} placeholder="Step name..." />

      <Controller
        control={control}
        name="showDeleted"
        defaultValue={false}
        render={({ field }) => (
          <Checkbox checked={field.value} onChange={(e) => field.onChange(e.checked)} label="Show Deleted Steps" />
        )}
      />
    </ScrollableForm>
  );
};

export { Filters, FiltersHeader };
