import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage } from "@hookform/error-message";
import { Attachments } from "components/Attachments";
import Checkbox from "components/Form/Checkbox";
import FileInput from "components/Form/FileInput";
import NumberInput from "components/Form/NumberInput";
import TextAreaInput from "components/Form/TextAreaInput";
import TextInput from "components/Form/TextInput";
import SelectPropertyType from "components/Select/PropertyType";
import { PropertyInput, Step } from "generated/graphql";
import React from "react";
import { Controller, useFieldArray, UseFormReturn } from "react-hook-form";
import { NumberFormatValues } from "react-number-format";
import { SubHeading } from "styles";
import { AddButton } from "components/Buttons";
export interface IStepFormData {
  name: string;
  points: number;
  promptOperatorToConsumeInventory: boolean;
  description: string;
  properties: PropertyInput[];
  attachments: { value: { name: string; file: FileList } }[];
}

const StepForm: React.FC<{ step?: Step; form: UseFormReturn<IStepFormData> }> = ({ step, form }) => {
  const { register, control, watch } = form;

  const values = watch();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "properties",
  });

  const attachmentsFieldArray = useFieldArray({
    control,
    name: "attachments",
  });

  return (
    <>
      <SubHeading>Name</SubHeading>
      <div className="my-2">
        <TextInput {...register("name")} />
      </div>

      <SubHeading>Points</SubHeading>
      <div className="my-2">
        <Controller
          control={control}
          name="points"
          rules={{
            min: {
              value: 1,
              message: "must be greater than 0",
            },
          }}
          render={({ field }) => {
            return (
              <NumberInput
                type="tel"
                value={field.value}
                onValueChange={(value: NumberFormatValues) => field.onChange(value.floatValue)}
              />
            );
          }}
        />
        <ErrorMessage name="points" errors={form.formState.errors} />
      </div>

      <SubHeading>Description</SubHeading>
      <TextAreaInput
        // className="form-textarea my-2 p-2 w-full border border-gray-400 rounded"
        rows={6}
        placeholder="Add a description..."
        {...register("description")}
      />

      <div className="my-2">
        <SubHeading>Properties</SubHeading>
        <div className="grid grid-cols-2-w-button gap-y-2 gap-x-2 items-center">
          {fields.map((item, index) => {
            return (
              <React.Fragment key={item.id}>
                <TextInput
                  placeholder="Name"
                  defaultValue={item?.name}
                  {...register(`properties.${index}.name` as const, {
                    required: true,
                  })}
                />

                <Controller
                  control={control}
                  name={`properties.${index}.type` as const}
                  defaultValue={item?.type}
                  render={({ field }) => <SelectPropertyType value={field.value} onChange={field.onChange} />}
                />

                <button type="button" onClick={() => remove(index)}>
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </React.Fragment>
            );
          })}
        </div>

        <button
          type="button"
          onClick={() => append({ name: null, type: null })}
          className="text-blue-500 dark:text-blue-300 my-2 focus:outline-none"
        >
          Add Property...
        </button>
      </div>

      <SubHeading>Options</SubHeading>
      <div className="my-2">
        <Controller
          control={control}
          name="promptOperatorToConsumeInventory"
          defaultValue={false}
          render={({ field }) => {
            return (
              <Checkbox
                label="Prompt operator to consume inventory on completion"
                onChange={(e) => field.onChange(e?.checked)}
                checked={field.value}
              />
            );
          }}
        />

        <ErrorMessage name="points" errors={form.formState.errors} />
      </div>

      <SubHeading>
        Attachments
        <AddButton
          onClick={() =>
            attachmentsFieldArray.append({
              value: null,
            })
          }
        />
      </SubHeading>
      <Attachments data={step?.attachments ?? []} />

      {attachmentsFieldArray.fields.map((attachmentField, index) => {
        const fieldValue = values?.attachments?.[index]?.value;
        return (
          <div className="flex flex-row py-2" key={attachmentField.id}>
            <Controller
              control={control}
              name={`attachments.${index}.value` as const}
              defaultValue={attachmentField?.value}
              render={({ field }) => {
                const selectedFile = fieldValue?.file?.item(0);

                return (
                  <>
                    <div className="flex-1">
                      <TextInput
                        type="text"
                        placeholder="File name"
                        value={fieldValue?.name ?? ""}
                        onChange={(e) => {
                          field.onChange({
                            name: e.target.value,
                            file: fieldValue?.file,
                          });
                        }}
                      />
                      {selectedFile && (
                        <div className="text-xs py-1">{`Selected: ${selectedFile?.name ?? "None"} `}</div>
                      )}
                    </div>
                    <FileInput
                      onChange={(value) => {
                        field.onChange({
                          name: value?.[0]?.name ?? "",
                          file: value,
                        });
                      }}
                      onRemove={() => attachmentsFieldArray.remove(index)}
                    />
                  </>
                );
              }}
            />
          </div>
        );
      })}
      {/* <div className="flex justify-end">
        <button
          type="button"
          className="text-blue-500 focus:outline-none"
          onClick={() =>
            attachmentsFieldArray.append({
              value: null,
            })
          }
        >
          Add attachment...
        </button>
      </div> */}
    </>
  );
};

export { StepForm as default };
