import React, { useState, useCallback } from "react";
import { parse, ParseResult, ParseMeta } from "papaparse";
import { useToast } from "hooks/toast";
import { PlainBlueButton } from "components/Buttons";
import { CenterModal } from "components/Modals/CenterModal";
import { RedButton, PlainButton, SmallBlueButton } from "styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt, faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { useDropzone } from "react-dropzone";
import LinearProgress from "@material-ui/core/LinearProgress";
import { localeNumber } from "utils/formatters";
import tw from "twin.macro";
import styled from "styled-components";
import { useCreateTagsMutation, Location } from "generated/graphql";
import { ButtonLoading } from "components/Loading";
import { SelectLocation } from "../Select/Location";

const StyledLinearProgress = styled(LinearProgress)`
  ${tw`w-full`};
  &.MuiLinearProgress-colorPrimary {
    ${tw`bg-red-100`};
  }
  .MuiLinearProgress-barColorPrimary {
    ${tw`bg-brand`};
  }

  .MuiLinearProgress-colorSecondary {
    ${tw`bg-brand`};
  }
`;

export interface ITemplateRow {
  [x: string]: string;
}

export interface IImportFrom {
  isOpen: boolean;
  onRequestClose: (imported: boolean) => void;
}

export const UpdateFromCSV: React.FC<
  IImportFrom & {
    templateURL: string;
    createTags?: boolean;
    type?: string;
    importFn: (
      data: ParseResult<ITemplateRow> & { file: File },
      tags: Record<string, string>,
      location: Location,
      onProgress: (progress: number) => void
    ) => Promise<void>;
  }
> = ({ isOpen, onRequestClose, templateURL, type = "items", importFn, createTags = false, children }) => {
  const toast = useToast();
  const [createTagsMutation] = useCreateTagsMutation();
  const [location, setlocation] = useState<any>(null);
  const [parseResult, setParsedResult] = useState<ParseResult<any> & { file: File }>(null);
  const [progress, setprogress] = useState<number | null>(null);

  const reset = () => {
    setlocation(null);
    setParsedResult(null);
    setprogress(null);
  };

  const onClose = (imported: boolean) => {
    reset();
    onRequestClose(imported);
  };

  const handleCreateTags = useCallback(
    async (meta: ParseMeta) => {
      const tags = meta.fields.filter((field: string) => field.includes("tag:"));

      const resp = await createTagsMutation({
        variables: {
          input: Array.from(tags).map((tag: string) => {
            return {
              name: tag.replace("tag:", ""),
            };
          }),
        },
      });

      return resp.data.createTags.reduce<Record<string, string>>((acc, tag) => {
        acc[`tag:${tag.name}`] = tag.id;

        return acc;
      }, {});
    },
    [createTagsMutation]
  );

  const handleImport = useCallback(async () => {
    setprogress(0);

    try {
      let tags: Record<string, string> = {};
      if (createTags) {
        tags = await handleCreateTags(parseResult.meta);
      }
      await importFn(parseResult, tags, location, (progress) => setprogress(progress));
    } catch (err) {
      toast.error(`Unable to import ${type}`);
      return;
    }
  }, [location, createTags, importFn, parseResult, handleCreateTags, toast, type]);

  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      return parse<ITemplateRow>(acceptedFiles[0], {
        header: true,
        dynamicTyping: false,
        skipEmptyLines: "greedy",
        complete: ({ data, errors, meta }, file) => {
          if (errors.length > 0) {
            toast.error("Unable to read CSV file");
            return;
          }

          setParsedResult({ data, errors, meta, file });
        },
      });
    },
    [toast]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted,
    accept: [
      ".csv",
      "text/csv",
      "application/vnd.ms-excel",
      "application/csv",
      "text/x-csv",
      "application/x-csv",
      "text/comma-separated-values",
      "text/x-comma-separated-values",
    ],
    onDropRejected: (err) => {
      toast.error("Only CSVs allowed");
    },
  });

  return (
    <CenterModal isOpen={isOpen} onRequestClose={() => {}} style={{ minHeight: 384 }}>
      {isOpen && (
        <>
          <div className="mb-2">{children}</div>

          <SelectLocation value={location} onChange={(value) => setlocation(value)} className="flex-1" />
          {!parseResult?.file && progress === null ? (
            <div className="flex flex-col items-center justify-center my-4">
              <div
                className="cursor-pointer flex flex-col justify-center items-center h-40 border border-dashed bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700 hover:bg-gray-200 text-gray-500 dark:text-gray-300 rounded w-full"
                {...getRootProps()}
              >
                <input autoFocus {...getInputProps()} />{" "}
                {isDragActive ? (
                  <>
                    <FontAwesomeIcon icon={faCloudUploadAlt} size="3x" />
                    <p className="mt-2">Drop the file here...</p>
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faCloudUploadAlt} size="3x" />
                    <p className="mt-2 px-3 text-center">Click here or drag and drop the file you'd like to import</p>
                  </>
                )}
              </div>

              <div className="my-4">or</div>
              <a className="text-blue-500 font-semibold text-lg mb-2" href={templateURL} download>
                Download the template
              </a>
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center h-64">
              <FontAwesomeIcon icon={faFileCsv} size="3x" />
              <div className="font-semibold my-2">{parseResult?.file?.name}</div>
              <div className="font-semibold text-sm ">
                {" "}
                {localeNumber(parseResult?.data?.length)} {type}
              </div>

              {progress !== null ? (
                <div className="w-1/2 flex flex-col items-center mt-4">
                  <StyledLinearProgress variant="determinate" value={progress} />
                  <div className="py-1">{progress}%</div>
                </div>
              ) : (
                <SmallBlueButton className="mt-4" onClick={reset}>
                  Choose a different file
                </SmallBlueButton>
              )}
            </div>
          )}
          {progress === 100 ? (
            <div className="grid grid-cols-2 xl:grid-cols-4 gap-x-2 mt-2">
              <PlainBlueButton className="xl:col-start-3" onClick={reset}>
                Start Over
              </PlainBlueButton>
              <RedButton className="xl:col-start-4" disabled={parseResult === null} onClick={() => onClose(true)}>
                Done
              </RedButton>
            </div>
          ) : (
            <div className="grid grid-cols-2 xl:grid-cols-4 gap-x-2 mt-4">
              <PlainButton
                className="xl:col-start-3"
                onClick={() => {
                  // TODO: this is horrible - but could be fixed by server side processing - or figuring out a way to cancel a promise in react
                  if (progress !== null) {
                    window.location.reload();
                    return;
                  }

                  onClose(false);
                }}
              >
                Cancel
              </PlainButton>
              <RedButton
                disabled={location === null || parseResult === null || progress !== null}
                onClick={handleImport}
              >
                {progress !== null ? <ButtonLoading /> : "Upload"}
              </RedButton>
            </div>
          )}
        </>
      )}
    </CenterModal>
  );
};
