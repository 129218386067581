import React from "react";
import { H2, H3, P } from "styles";

const GettingStarted = () => {
  return (
    <div className="p-4">
      <H2 id="getting-started">Getting Started</H2>
      {/* <HR /> */}
      <section className="my-4 border-t border-gray-400 py-3">
        <P>Follow these steps to start using Factory Finch to track your production.</P>
        <P>
          If you need any assistance please don&#39;t hesitate to reach out to us at{" "}
          <a className="text-brand" href="mailto:info@factoryfinch.com">
            info@factoryfinch.com
          </a>
          .
        </P>
        <P>
          <em>NOTE</em>: These are the basic steps for getting up an running, we will be expanding our onboarding soon.
          😁
        </P>
      </section>
      <section className="my-4 border-t border-gray-400 py-3">
        <H3 id="step-1-create-a-product-optional-">Step 1 - Create an inventory item (optional)</H3>
        <P className="italic">What are you making?</P>
        <P>
          Head over to Production -&gt; Products and click the &quot;Add InventoryItem&quot; Button. Fill out the form
          and click save.
        </P>
      </section>
      <P>
        <em>NOTE</em>: If you have already imported your products (ie. from Shopify) you can skip this step.
      </P>
      <section className="my-4 border-t border-gray-400 py-3">
        <H3 id="step-2-create-a-workflow">Step 2 - Create a Workflow</H3>
        <P className="italic">How do you make it?</P>
        <P>
          Head over to Production -&gt; Workflows and click on the &quot;Add Workflow&quot; button. Use the Workflow
          editor to add and edit steps to match your process for making whatever product you created in step 1.
        </P>

        <P>
          A workflow is a series of steps that you would like to track. For example, if I was making a pair of jeans I
          would like to know when fabric was cut from a roll, when the pieces were sewn together, when the went in the
          wash and when they were placed on the rack before fulfillment (this is an extremely simplified version of how
          to make jeans). In this scenario we would create
        </P>
        <P>Steps:</P>
        <ol className="list-decimal mx-8 text-lg">
          <li>Cut Fabric</li>
          <li>Sew Jeans</li>
          <li>Wash Jeans</li>
          <li>Ready for fulfillment</li>
        </ol>
        <P>
          This is an example of a linear workflow but workflows can have parallel steps and branch many times. There are
          really only two constraints.
        </P>
        <ol className="list-decimal mx-8 text-lg">
          <li>A workflow must have at least 1 start step.</li>
          <li>A workflow must have only 1 end step.</li>
        </ol>
        <P>
          If you have any feedback on our workflow editor we would love to hear it! Please let us know at{" "}
          <a className="text-brand" href="mailto:info@factoryfinch.com">
            info@factoryfinch.com
          </a>
          .
        </P>
      </section>
      <section className="my-4 border-t border-gray-400 py-3">
        <H3 id="step-3-create-a-work-order">Step 3 - Create a Work Order</H3>
        <P>Start making your product!</P>
        <P>
          Head over to Production -&gt; Work Orders and click on the &quot;Create Work Order Button&quot;. Select the
          product you created in Step 1 and the workflow you created in Step 2 then click &quot;Create&quot;.
        </P>
        <P>
          You should see a new work order with the status of &quot;Not Started&quot; show up in your list. Click on the
          vertical ellipsis at the right side of the row and click on &quot;View&quot;. This screen shows you a detailed
          view of this work order. It also gives you the ability to add notes, media, and tags to the order. On the
          right hand side you will see a graph which represents where this order is in the workflow you specified. Click
          on the first step and click &quot;Complete&quot; in the menu that displays at the top of the graph. When this
          step is complete you will see a green check mark, your email address and a time stamp indicating that you
          completed this step at the time indicated.
        </P>
      </section>
      <section className="my-4 border-t border-gray-400 py-3">
        <H3 id="step-4-take-flight">Step 4 - Take flight</H3>
        <P>
          This just scrapes the surface of what you can do with Factory Finch. Get in touch with us at{" "}
          <a className="text-brand" href="mailto:info@factoryfinch.com">
            info@factoryfinch.com
          </a>{" "}
          if you would like a demo and we&#39;d be happy to show you how we are using Factory Finch to gain insight into
          our operations at Dearborn Denim and Apparel.
        </P>
      </section>
      <section className="my-4 border-t border-gray-400 py-3">
        <H3 id="additional-features">Additional Features</H3>
        <ul className="list-disc mx-4 text-lg my-4">
          <li>Print QR codes for easy tracking of everything in your factory.</li>
          <li>
            Mobile app for operators to complete steps, raw materials consumption tracking, and order fulfillment.
          </li>
          <li>Employee performance insights.</li>
          <li>Production insights.</li>
          <li>Manage inventory across locations.</li>
          <li>
            Eliminate &quot;Key person risk&quot; by getting information off of the shop floor into Factory Finch.
          </li>
        </ul>
      </section>
    </div>
  );
};

export { GettingStarted as default };
