import { DateTime } from "luxon";
import { Errors } from "generated/graphql";

export const shortId = (id: string) => id?.substring(0, 7);

export const shortDate = (timestamp?: string) => {
  if (!timestamp) {
    return null;
  }

  return DateTime.fromMillis(parseInt(timestamp, 10)).toLocaleString(DateTime.DATE_SHORT);
};

export const shortDateTime = (timestamp?: string) => {
  if (!timestamp) {
    return null;
  }
  return DateTime.fromMillis(parseInt(timestamp, 10)).toLocaleString(DateTime.DATETIME_SHORT);
};

export const medDateTimeWithSeconds = (timestamp?: string) => {
  if (!timestamp) {
    return null;
  }

  return DateTime.fromMillis(parseInt(timestamp, 10)).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
};

export const fullDateTimeWithSeconds = (timestamp?: string) => {
  if (!timestamp) {
    return null;
  }

  return DateTime.fromMillis(parseInt(timestamp, 10)).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS);
};

export const toDate = (timestamp?: string) => {
  if (!timestamp) {
    return null;
  }

  return DateTime.fromMillis(parseInt(timestamp, 10)).toJSDate();
};

export const getQueryStringErrorText = (key: string) => {
  switch (key) {
    case Errors.ShopifyStoreAlreadyConnected:
      return "This Shopify store has already been connected to another account. Please contact support for assistance.";
    case Errors.UnableToConnectToShopifyStore:
      return "Unable to connect to Shopify Store. Please contact support for assistance.";

    default:
      return null;
  }
};

export const currency = (value: string | number, options = { locale: "en-US", currency: "USD" }) => {
  return new Intl.NumberFormat(options.locale, {
    style: "currency",
    currency: options.currency,
  }).format(Number(value));
};

export const localeNumber = (value: string | number, options = { locale: "en-US" }) => {
  return new Intl.NumberFormat(options.locale, {
    style: "decimal",
  }).format(Number(value));
};

export const camelize = (str: string) => {
  return str
    .replace(/(?:^\w|\[A-Z\]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
};

export const pascalize = (str: string) => {
  return str
    .replace(/(?:^\w|\[A-Z\]|\b\w)/g, (word, index) => {
      return word.toUpperCase();
    })
    .replace(/\s+/g, "");
};

export const capitalizeFirstWord = (str?: string) => {
  if (!str) {
    return "";
  }
  return `${str.substring(0, 1).toUpperCase()}${str.substring(1)}`;
};
