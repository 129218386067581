import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { ButtonWithIcon } from "components/Buttons";
import { BottomModal } from "components/Modals/BottomModal";
import { SideModal } from "components/Modals/SideModal";

import TitleWithLoading from "components/TitleWithLoading";
import { CreateInventoryTransfer } from "components/Views/InventoryTransfer/InventoryTransfers/Create";
import { useListInventoryTransfersLazyQuery } from "generated/graphql";
import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Confirmation } from "components/Modals/Confirmation";
import { notEmpty } from "utils/filters";
import { H4, HR } from "styles";
import { TableProvider, useTable } from "./context";
import Edit from "./Edit";
import { Filters, FiltersHeader } from "./Filters";
import Table from "./Table";
import { LoadingAnimationWithLabel } from "components/Loading";
import { useToast } from "hooks/toast";

const InventoryTransfers = () => {
  const toast = useToast();
  const location = useLocation<{ createNew: boolean }>();
  const {
    loading,
    isEditing,
    setIsEditing,
    isDeleting,
    setIsDeleting,
    showFilters,
    setShowFilters,
    fetch,
    variables,
    deleteInventoryTransfer,
  } = useTable();
  const [createInventoryTransfer, setcreateInventoryTransfer] = useState(false);
  const [ready, setready] = useState(false);
  const onDeleteTransfer = useCallback(async () => {
    try {
      await deleteInventoryTransfer({ variables: { id: isDeleting?.id } });
      setIsDeleting(null);
      toast.success("Inventory transfer deleted");
    } catch (err) {
      console.log(err);
      toast.error("Unable to delete transfer");
    }
  }, [isDeleting, deleteInventoryTransfer, setIsDeleting, toast]);

  useEffect(() => {
    if (location?.state?.createNew) {
      setcreateInventoryTransfer(true);
    }
  }, [location?.state?.createNew]);

  return (
    <>
      <div className="py-2 px-2 flex-1 flex flex-col overflow-hidden">
        <div className="flex justify-between my-2">
          <TitleWithLoading title="Inventory Transfers" loading={loading} refresh={() => fetch({ variables })} />

          <ButtonWithIcon className="bg-brand" icon={faPlusCircle} onClick={() => setcreateInventoryTransfer(true)}>
            Create Transfer
          </ButtonWithIcon>
        </div>
        <FiltersHeader />
        <Table />
      </div>

      <BottomModal
        side="BOTTOM"
        isOpen={notEmpty(isEditing)}
        onRequestClose={() => {
          setIsEditing(null);
          setready(false);
        }}
        disableEscKey
        onOpened={() => setready(true)}
      >
        {ready ? (
          <Edit />
        ) : (
          <div className="mt-20">
            <LoadingAnimationWithLabel message={"Fetching Inventory Transfer"} />
          </div>
        )}
      </BottomModal>

      <BottomModal
        side="BOTTOM"
        isOpen={createInventoryTransfer}
        onRequestClose={() => setcreateInventoryTransfer(false)}
        disableEscKey
      >
        <CreateInventoryTransfer
          onRequestClose={async () => {
            fetch({ variables });
            setcreateInventoryTransfer(false);
          }}
        />
      </BottomModal>

      <SideModal side="RIGHT" isOpen={showFilters} onRequestClose={() => setShowFilters(false)}>
        <Filters />
      </SideModal>

      <Confirmation
        isOpen={isDeleting !== null}
        onRequestClose={() => setIsDeleting(null)}
        cancelButtonLabel="No"
        confirmButtonLabel="Yes"
        onConfirm={onDeleteTransfer}
      >
        <div className="my-4 w-full">
          <H4 className="py-4 flex flex-row justify-center text-default dark:text-white">
            Are you sure you want to delete this transfer?
          </H4>
          <HR />
        </div>
      </Confirmation>
    </>
  );
};

const Container = () => {
  const query = useListInventoryTransfersLazyQuery({
    fetchPolicy: "network-only",
  });

  return (
    <TableProvider query={query} sessionKey="@factoryfinch/inventoryTransfers">
      <InventoryTransfers />
    </TableProvider>
  );
};

export { Container as default };
