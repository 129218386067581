import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { useQueryString } from "hooks/queryString";
import { useVerifyUserMutation } from "generated/graphql";
import { WhiteLogo } from "components/Logo";
import { P } from "styles";
import BackgroundImage from "components/BackgroundImage";
import { Routes } from "routes";

const View: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
  return (
    <div className="flex flex-col items-center w-full h-screen bg-brand">
      <BackgroundImage />
      <div className="mt-32">
        <WhiteLogo width={200} />
      </div>
      {children}
    </div>
  );
};

const VerifyUser: React.FC = () => {
  const qs = useQueryString();
  const [verifying, setverifying] = useState(true);
  const [verified, setverified] = useState(false);

  const [verifyUser, verifyUserMutatation] = useVerifyUserMutation({
    variables: { input: { token: qs.get("token") } },
  });

  useEffect(() => {
    verifyUser()
      .then((resp) => {
        setverifying(false);
        setverified(resp?.data?.verifyUser);
      })
      .catch((err) => {
        setverifying(false);
        setverified(false);
      });
  }, [verifyUser]);

  if (verified) {
    return <Redirect to={Routes.Root} />;
  }

  if (verifying || verifyUserMutatation?.loading) {
    return (
      <View>
        <P>Verifying</P>
      </View>
    );
  }

  if (!verifying || !verified) {
    return (
      <View>
        <P className="text-white">Something went wrong - try logging in and resending the verification link.</P>
        <Link to={Routes.Login} className="text-lg text-blue-400 mt-16">
          Click here to log in
        </Link>
      </View>
    );
  }

  return <View>Verified!</View>;
};

export { VerifyUser as default };
