import { InventoryItemType } from "generated/graphql";
import { isEqual } from "lodash";

interface InventoryItemTypeFields {
  isPurchased: boolean;
  isUsedToMakeSomethingElse: boolean;
  isSold: boolean;
  isMade: boolean;
}

const defaultValues: InventoryItemTypeFields = {
  isPurchased: false,
  isUsedToMakeSomethingElse: false,
  isSold: false,
  isMade: false,
};

const InventoryItemTypeMap: Record<InventoryItemType, InventoryItemTypeFields> = {
  [InventoryItemType.RawMaterial]: {
    isPurchased: true,
    isUsedToMakeSomethingElse: true,
    isSold: false,
    isMade: false,
  },
  [InventoryItemType.IntermediateGood]: {
    isPurchased: false,
    isUsedToMakeSomethingElse: true,
    isSold: false,
    isMade: true,
  },
  [InventoryItemType.FinishedGood]: {
    isPurchased: false,
    isUsedToMakeSomethingElse: false,
    isSold: true,
    isMade: true,
  },

  [InventoryItemType.Unknown]: {
    isPurchased: false,
    isUsedToMakeSomethingElse: false,
    isSold: false,
    isMade: false,
  },
};

export const isValidInventoryItemType = (values: InventoryItemTypeFields) => {
  return [InventoryItemType.RawMaterial, InventoryItemType.IntermediateGood, InventoryItemType.FinishedGood].includes(
    toInventoryItemType(values)
  );
};

export const toInventoryItemType = ({
  isPurchased,
  isUsedToMakeSomethingElse,
  isSold,
  isMade,
}: InventoryItemTypeFields = defaultValues) => {
  if (
    isEqual(InventoryItemTypeMap[InventoryItemType.RawMaterial], {
      isPurchased,
      isUsedToMakeSomethingElse,
      isSold,
      isMade,
    })
  ) {
    return InventoryItemType.RawMaterial;
  }

  if (
    isEqual(InventoryItemTypeMap[InventoryItemType.IntermediateGood], {
      isPurchased,
      isUsedToMakeSomethingElse,
      isSold,
      isMade,
    })
  ) {
    return InventoryItemType.IntermediateGood;
  }

  if (
    isEqual(InventoryItemTypeMap[InventoryItemType.FinishedGood], {
      isPurchased,
      isUsedToMakeSomethingElse,
      isSold,
      isMade,
    })
  ) {
    return InventoryItemType.FinishedGood;
  }

  if (
    isEqual(InventoryItemTypeMap[InventoryItemType.Unknown], {
      isPurchased,
      isUsedToMakeSomethingElse,
      isSold,
      isMade,
    })
  ) {
    return InventoryItemType.Unknown;
  }

  return null;
};

export const fromInventoryType = (type: InventoryItemType) => {
  return InventoryItemTypeMap?.[type] ?? defaultValues;
};
