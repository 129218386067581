import React, { useState } from "react";
import { faCloudUploadAlt, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { ButtonWithIcon } from "components/Buttons";
import { SideModal } from "components/Modals/SideModal";
import TitleWithLoading from "components/TitleWithLoading";
import { useListCustomersLazyQuery } from "generated/graphql";
import { TableProvider, useTable } from "./context";
import CreateCustomer from "./Create";
import Table from "./Table";
import { ImportCustomersFromCSV } from "components/ImportFromCSV/Customers";
import { FiltersModal, FiltersHeader } from "./Filters";

const Customers = () => {
  const [importCustomers, setimportCustomers] = useState(false);
  const { loading, isCreating, setIsCreating, fetch, variables } = useTable();

  return (
    <>
      <div className="py-2 px-2 flex-1 flex flex-col overflow-hidden">
        <div className="flex justify-between my-2">
          <TitleWithLoading title="Customers" loading={loading} refresh={() => fetch({ variables })} />

          <div className="flex items-center">
            <ButtonWithIcon
              data-testid="btn-import-from-csv"
              className="bg-blue-400 mr-2"
              icon={faCloudUploadAlt}
              onClick={() => setimportCustomers(true)}
            >
              Import from CSV
            </ButtonWithIcon>
            <ButtonWithIcon className="bg-brand" icon={faPlusCircle} onClick={() => setIsCreating(true)}>
              Create Customer
            </ButtonWithIcon>
          </div>
        </div>

        <FiltersHeader />
        <Table />
      </div>
      <SideModal side="RIGHT" isOpen={isCreating} onRequestClose={() => setIsCreating(false)}>
        <CreateCustomer />
      </SideModal>
      <FiltersModal />
      <ImportCustomersFromCSV
        isOpen={importCustomers}
        onRequestClose={(imported: boolean) => {
          if (imported) {
            fetch({ variables });
          }
          setimportCustomers(false);
        }}
      />
    </>
  );
};

const Container = () => {
  const query = useListCustomersLazyQuery({
    fetchPolicy: "network-only",
  });
  return (
    <TableProvider query={query} sessionKey="@factoryfinch/customers">
      <Customers />
    </TableProvider>
  );
};

export { Container as default };
