import { ManufacturingOrder, ManufacturingOrderStatus } from "generated/graphql";
import React, { useMemo } from "react";
import { Item, ItemParams, Menu } from "react-contexify";
import { RowActions, useRowActions, useTable } from "./context";

export const MENU_ID = "work-order-row-menu";

export const ContextMenu = () => {
  const { selected, selectedRows } = useTable();
  const handleRowAction = useRowActions();

  const handleItemClick: (args: ItemParams<ManufacturingOrder, any>) => void = ({ event, props: item }) => {
    handleRowAction(event.currentTarget.id as RowActions, item);
  };

  const isChangeWorkflowDisabled = useMemo(() => {
    return !selectedRows.every((row) => row.status === ManufacturingOrderStatus.NotStarted);
  }, [selectedRows]);

  const allSelectedCancelled = useMemo(() => {
    return !selectedRows.every((row) => row.status === ManufacturingOrderStatus.Cancelled);
  }, [selectedRows]);

  return (
    <Menu id={MENU_ID}>
      <Item id="view" onClick={handleItemClick}>
        View
      </Item>
      <Item id="printLabel" onClick={handleItemClick}>
        Print Label
      </Item>
      <Item id="qrCode" onClick={handleItemClick}>
        QR Code
      </Item>

      <Item id="restoreWorkOrder" onClick={handleItemClick} hidden={(row) => row?.props?.deletedAt === null}>
        <div className="text-blue-500">Restore</div>
      </Item>

      <Item id="cancelWorkOrder" onClick={handleItemClick} hidden={(row) => row?.props?.deletedAt !== null}>
        <div className="text-brand">Cancel</div>
      </Item>

      {selected?.size > 0 && (
        <>
          <div
            className="mt-2 w-full cursor-default"
            style={{
              height: "1px",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            }}
          />

          <div className="text-xs text-gray-600 px-3 font-semibold bg-gray-100  py-2">Bulk Actions</div>

          <Item id="generateLabels" onClick={handleItemClick}>
            Generate Labels
          </Item>

          <Item id="exportCSV" onClick={handleItemClick}>
            Export CSV
          </Item>

          <Item id="addGroupIndicator" onClick={handleItemClick}>
            Add group indicator
          </Item>

          <Item id="addTags" onClick={handleItemClick}>
            Add Tags
          </Item>

          <Item id="bulkChangeWorkflow" disabled={isChangeWorkflowDisabled} onClick={handleItemClick}>
            Change Workflow
          </Item>

          <Item id="cancelOrders" hidden={!allSelectedCancelled} onClick={handleItemClick}>
            <div className="text-brand">Cancel Orders</div>
          </Item>

          <Item id="restoreWorkOrders" onClick={handleItemClick} hidden={allSelectedCancelled}>
            <div className="text-blue-500">Restore Orders</div>
          </Item>
        </>
      )}
    </Menu>
  );
};
