import React, { useEffect } from "react";
import Root from "components/Modules/Root";
import PrivateRoute from "components/PrivateRoute";
import Invitation from "components/Views/Admin/Invitation";
import ForgotPassword from "components/Views/Auth/ForgotPassword";
import Login from "components/Views/Auth/Login";
import { MagicLogin } from "components/Views/Auth/MagicLogin";
import ResetPassword from "components/Views/Auth/ResetPassword";
import SignUp from "components/Views/Auth/SignUp";
import TokenLogin from "components/Views/Auth/TokenLogin";
import VerifyUser from "components/Views/Auth/VerifyUser";
import { useQueryString } from "hooks/queryString";
import { ToastContainers, useToast } from "hooks/toast";
import Div100vh from "react-div-100vh";
import { Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Routes } from "routes";
import { getQueryStringErrorText } from "utils/formatters";
import { useTheme } from "hooks/theme";
import { PricingCalculator } from "components/PricingCalculator";

const GlobalErrors = () => {
  const qs = useQueryString();
  const toast = useToast();
  const err = qs.get("err");
  useEffect(() => {
    const text = getQueryStringErrorText(err);
    if (text !== null) {
      toast.error(text);
    }
    // eslint-disable-next-line
  }, [err]);

  return null;
};

const App: React.FC = () => {
  return (
    <Div100vh>
      <GlobalErrors />
      <Switch>
        <Route exact path={Routes.SignUp} component={SignUp} />
        <Route exact path={Routes.PricingCalculator} component={PricingCalculator} />
        <Route exact path={Routes.Login} component={Login} />
        <Route exact path={Routes.MagicLogin} component={MagicLogin} />
        <Route exact path={Routes.TokenLogin} component={TokenLogin} />
        <Route exact path={Routes.ForgotPassword} component={ForgotPassword} />
        <Route exact path={Routes.ResetPassword} component={ResetPassword} />
        <Route exact path={Routes.VerifyUser} component={VerifyUser} />
        <Route exact path={Routes.Invitation} component={Invitation} />
        <PrivateRoute path={Routes.Root} component={Root} />
        <Redirect to={Routes.Root} />
      </Switch>
    </Div100vh>
  );
};

const Container = () => {
  const { isDarkMode } = useTheme();
  return (
    <>
      <App />
      <ToastContainer
        position={ToastContainers.BottomCenter}
        containerId={ToastContainers.BottomCenter}
        enableMultiContainer={true}
        theme={isDarkMode ? "dark" : "light"}
      />
      <ToastContainer
        position={ToastContainers.BottomRight}
        containerId={ToastContainers.BottomRight}
        enableMultiContainer={true}
        theme={isDarkMode ? "dark" : "light"}
        limit={5}
      />
    </>
  );
};

export { Container as default };
