import React from "react";
import { useTable } from "./context";
import { H4, HR, RedButton } from "styles";
import { parse } from "json2csv";

import Checkbox from "components/Form/Checkbox";
import { Controller, useForm } from "react-hook-form";

const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const buildURI = (data: string) => {
  const type = isSafari() ? "application/csv" : "text/csv";
  const blob = new Blob(["", data], { type });
  const dataURI = `data:${type};charset=utf-8,${data}`;

  const URL = window.URL || window.webkitURL;

  return typeof URL.createObjectURL === "undefined" ? dataURI : URL.createObjectURL(blob);
};

interface IExportCSVForm {
  id: boolean;
  productName: boolean;
  quantity: boolean;
  msExcel: boolean;
}

type FormFieldName = "id" | "productName" | "quantity" | "msExcel";

interface FormField {
  fieldName: string;
  label: string;
  formName: FormFieldName;
  disabled: boolean;
}

const ExportCSV: React.FC<{
  onCancel?: () => void;
  onSuccess?: () => void;
}> = ({ onSuccess = () => {} }) => {
  const { selectedRows } = useTable();
  const { handleSubmit, control } = useForm<IExportCSVForm>();
  const formFields: FormField[] = [
    { fieldName: "id", label: "ID", formName: "id", disabled: true },
    {
      fieldName: "product.name",
      label: "InventoryItem Name",
      formName: "productName",
      disabled: false,
    },
    {
      fieldName: "quantity",
      label: "Quantity",
      formName: "quantity",
      disabled: false,
    },
  ];
  const submit = (data: IExportCSVForm) => {
    const fields = formFields.filter((field) => data?.[field.formName]).map((field) => field.fieldName);

    const c = parse(selectedRows, {
      fields,
      excelStrings: data?.msExcel ?? false,
    });

    window.open(buildURI(c));
    onSuccess();
  };

  return (
    <>
      <H4>Select fields</H4>
      <HR />
      <form onSubmit={handleSubmit(submit)}>
        {formFields.map((formField) => {
          return (
            <Controller
              key={formField.formName}
              control={control}
              name={formField.formName}
              defaultValue={true}
              render={({ field }) => (
                <Checkbox
                  disabled={formField?.disabled}
                  onChange={(e) => field.onChange(e?.checked)}
                  label={formField.label}
                  checked={field.value}
                />
              )}
            />
          );
        })}

        <HR />

        <Controller
          control={control}
          name="msExcel"
          defaultValue={false}
          render={({ field }) => (
            <Checkbox
              className="text-sm"
              onChange={(e) => field.onChange(e?.checked)}
              label="Excel Compatible"
              checked={field.value}
            />
          )}
        />

        <RedButton className="w-full mt-3">Download</RedButton>
      </form>
    </>
  );
};

export { ExportCSV as default };
