import React from "react";
import { ButtonLoading } from "components/Loading";
import { H3, IconButton } from "styles";
import { notEmpty } from "utils/filters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";

const TitleWithLoading: React.FC<{
  title: string | React.ReactNode;
  loading?: boolean;
  refresh?: () => void;
}> = ({ title, loading = false, refresh }) => {
  return (
    <H3 className="flex items-center">
      {title}
      {loading && (
        <span className="text-base mx-2">
          <ButtonLoading />
        </span>
      )}
      {notEmpty(refresh) && !loading && (
        <span className="text-sm mx-2">
          <IconButton onClick={refresh}>
            <FontAwesomeIcon icon={faSync} />
          </IconButton>
        </span>
      )}
    </H3>
  );
};

export { TitleWithLoading as default };
