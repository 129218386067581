import React from "react";
import { useWorkflowEditor } from "./context";
import { IPortDefaultProps, PortDefault } from "@mrblenny/react-flow-chart";
import styled from "styled-components";
import tw from "twin.macro";

interface IPortOuter {
  $error: boolean;
}

const CustomPortStyle = styled(PortDefault)<IPortOuter>`
  > div {
    ${(props) => (props?.$error ? tw`bg-brand` : tw`bg-blue-500`)}
  }
`;

const PortCustom = (props: IPortDefaultProps) => {
  const { errors } = useWorkflowEditor();

  return (
    <CustomPortStyle
      {...props}
      $error={errors?.ports.has(
        props?.port?.properties?.nodeId + props?.port?.id
      )}
    />
  );
};

export { PortCustom as default };
