import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { ButtonWithIcon } from "components/Buttons";
import TitleWithLoading from "components/TitleWithLoading";
import {
  useListPurchaseRequestsLazyQuery,
  PurchaseRequestStatus,
  useBulkUpdatePurchaseRequestsMutation,
  ListPurchaseRequestsDocument,
} from "generated/graphql";
import React from "react";
import { Link } from "react-router-dom";
import { Routes } from "routes";
import { TableProvider, useTable } from "./context";
import Table from "./Table";
import { FiltersHeader, Filters } from "./Filters";
import { SideModal } from "components/Modals/SideModal";
import { notEmpty } from "utils/filters";
import { CenterModal } from "components/Modals/CenterModal";
import { SelectPurchaseRequestStatus } from "components/Select/PurchaseRequestStatus";
import { PlainButton, RedButton } from "styles";
import { useToast } from "hooks/toast";
import { ButtonLoading } from "components/Loading";
import { useForm, Controller } from "react-hook-form";
import { shortId } from "utils/formatters";
import { Edit } from "./Edit";

interface IUpdateStatusForm {
  status: PurchaseRequestStatus;
}

const UpdateStatus = () => {
  const toast = useToast();
  const { variables, isChangingStatus, setIsChangingStatus } = useTable();
  const { handleSubmit, control, formState } = useForm<IUpdateStatusForm>();
  const [update] = useBulkUpdatePurchaseRequestsMutation({
    refetchQueries: [{ query: ListPurchaseRequestsDocument, variables }],
    awaitRefetchQueries: true,
  });

  const submit = async (formData: IUpdateStatusForm) => {
    try {
      await update({
        variables: {
          input: {
            ids: isChangingStatus?.map((pr) => pr.id),
            status: formData.status,
          },
        },
      });
    } catch (err) {
      toast.error(`Unable to update status for purchase orders`);
    } finally {
      setIsChangingStatus(null);
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      {isChangingStatus?.length === 1 ? (
        <div className="py-4 text-lg font-semibold">Update purchase request {shortId(isChangingStatus?.[0].id)}</div>
      ) : (
        <div className="py-4 text-lg font-semibold">Update {isChangingStatus?.length} purchase requests</div>
      )}
      <Controller
        control={control}
        name="status"
        render={({ field }) => {
          return <SelectPurchaseRequestStatus value={field.value} onChange={field.onChange} />;
        }}
      />
      <div className="flex justify-end mt-4">
        <PlainButton type="button" className="mr-4" onClick={() => setIsChangingStatus(null)}>
          Cancel
        </PlainButton>
        <RedButton>{formState?.isSubmitting ? <ButtonLoading /> : "Submit"}</RedButton>
      </div>
    </form>
  );
};
const PurchaseRequests = () => {
  const {
    loading,
    fetch,
    variables,
    showFilters,
    setShowFilters,
    isChangingStatus,
    setIsChangingStatus,
    isEditing,
    setIsEditing,
  } = useTable();

  return (
    <div className="flex-1 flex flex-row">
      <div className="py-2 px-2 flex-1 flex flex-col overflow-hidden">
        <div className="flex justify-between my-2">
          <TitleWithLoading title="Purchase Requests" loading={loading} refresh={() => fetch({ variables })} />

          <Link to={Routes.CreatePurchaseRequest}>
            <ButtonWithIcon icon={faPlusCircle} className="bg-brand mx-2">
              Create Request
            </ButtonWithIcon>
          </Link>
        </div>
        <FiltersHeader />
        <Table />
      </div>

      <SideModal side="RIGHT" isOpen={showFilters} onRequestClose={() => setShowFilters(false)}>
        <Filters />
      </SideModal>

      <SideModal side="RIGHT" isOpen={notEmpty(isEditing)} onRequestClose={() => setIsEditing(null)}>
        <Edit />
      </SideModal>

      <CenterModal isOpen={notEmpty(isChangingStatus)} onRequestClose={() => setIsChangingStatus(null)}>
        <UpdateStatus />
      </CenterModal>
    </div>
  );
};

const Container = () => {
  const query = useListPurchaseRequestsLazyQuery({
    fetchPolicy: "network-only",
  });
  return (
    <TableProvider query={query} sessionKey="@factoryfinch/purchaseRequests">
      <PurchaseRequests />
    </TableProvider>
  );
};

export { Container as default };
