import { Link } from "react-router-dom";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faBolt, faCheckSquare, faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import Pagination from "components/Pagination";
import { SelectPagination } from "components/Select/Pagination";
import StyledTable from "components/Table";
import { EmptyRowsRenderer, LoadingRenderer, RowRenderer } from "components/Table/Renderers";
import { SalesOrderInvoice } from "generated/graphql";
import React, { useEffect } from "react";
import { Item, ItemParams, Menu } from "react-contexify";
import { Column, DataGridProps, FormatterProps, HeaderRendererProps } from "react-data-grid";
import { useHistory } from "react-router-dom";
import { getRouteWithParams, Routes } from "routes";
import { IconButton, Tooltip } from "styles";
import { shortDateTime, shortId } from "utils/formatters";
import { generatePdfLabels } from "utils/labels";
import { useTable } from "./context";

const MENU_ID = "sales-order-invoice-row-menu";
const LABEL_TITLE = "SALES ORDER INVOICE";

const columns: Column<SalesOrderInvoice, unknown>[] = [
  {
    headerRenderer: (props: HeaderRendererProps<SalesOrderInvoice>) => {
      return (
        <div
          className="flex justify-center items-center h-full w-full text-brand cursor-pointer text-lg py-2"
          onClick={(e) => props.onAllRowsSelectionChange(!props?.allRowsSelected)}
        >
          <FontAwesomeIcon icon={props?.allRowsSelected ? faCheckSquare : faSquare} />
        </div>
      );
    },
    formatter: (props: FormatterProps<SalesOrderInvoice, unknown>) => {
      return (
        <div
          className={cx("flex justify-center items-center h-full w-full text-brand cursor-pointer text-lg py-2")}
          onClick={(e) => props.onRowSelectionChange(!props?.isRowSelected, e.shiftKey)}
        >
          <FontAwesomeIcon icon={props?.isRowSelected ? faCheckSquare : faSquare} />
        </div>
      );
    },

    key: "select-row",
    name: "",
    resizable: false,
    sortable: false,
    maxWidth: 35,
    width: 35,
  },
  {
    key: "id",
    name: "ID",
    width: 100,
    formatter: (props) => {
      return (
        <div
          className={`${
            props?.row?.deletedAt ? "text-gray-400 dark:text-gray-200 line-through" : ""
          } hover:underline truncate`}
          title={props?.row?.id}
        >
          {shortId(props?.row?.id)}
        </div>
      );
    },
  },
  {
    key: "number",
    name: "Invoice #",
    headerCellClass: "text-center",
    cellClass: "text-center",
  },

  {
    key: "salesOrder",
    name: "Sales Order",
    headerCellClass: "text-center",
    cellClass: "text-center",
    formatter: (props) => {
      const id = props?.row?.salesOrder?.id;
      return (
        <Link
          title={id}
          to={getRouteWithParams(Routes.CustomerOrder, {
            id,
          })}
          className="hover:underline"
        >
          {shortId(id)}
        </Link>
      );
    },
  },

  {
    key: "createdAt",
    name: "Created",
    minWidth: 100,
    sortable: true,
    formatter: (props) => {
      return <div>{`${shortDateTime(props?.row.createdAt)}`}</div>;
    },
  },
];

const ContextMenu = () => {
  const history = useHistory();
  const { selected, selectedRows } = useTable();
  const handleItemClick: (args: ItemParams<SalesOrderInvoice, any>) => void = ({ event, props: item }) => {
    switch (event.currentTarget.id) {
      case "printLabel":
        generatePdfLabels(LABEL_TITLE, [item]);
        break;
      case "generateLabels":
        generatePdfLabels(LABEL_TITLE, selectedRows);
        break;

      case "view":
        history.push(getRouteWithParams(Routes.CustomerOrder, { id: item.id }));
        break;
      default:
        break;
    }
  };

  return (
    <Menu id={MENU_ID}>
      <Item id="view" onClick={handleItemClick}>
        View
      </Item>
      <Item id="printLabel" onClick={handleItemClick}>
        Print Label
      </Item>

      {selected?.size > 0 && (
        <>
          <div
            className="mt-2 w-full cursor-default"
            style={{
              height: "1px",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            }}
          />

          <div className="text-xs text-gray-600 px-3 font-semibold bg-gray-100  py-2">Bulk Actions</div>

          <Item id="generateLabels" onClick={handleItemClick}>
            Generate Labels
          </Item>
        </>
      )}
    </Menu>
  );
};

const TableHeader = () => {
  const { selected, setPagination, pagination, setShowFilters, setShowQuickFilters, showQuickFilters } = useTable();

  return (
    <>
      <div className="flex justify-between items-center py-2">
        <div className="px-2">{selected.size > 0 && <div>{selected.size} rows selected</div>}</div>
        <div className="flex">
          <div className="flex flex-row items-center px-2">
            <Tooltip arrow title={showQuickFilters ? `Hide quick filters` : `Show quick filters`}>
              <IconButton
                className={showQuickFilters ? `text-yellow-500 ` : ``}
                onClick={() => setShowQuickFilters(!showQuickFilters)}
              >
                <FontAwesomeIcon icon={faBolt} size="lg" />
              </IconButton>
            </Tooltip>

            <Tooltip arrow title="Filters">
              <IconButton className="" onClick={() => setShowFilters(true)}>
                <FontAwesomeIcon icon={faSlidersH} size="lg" />
              </IconButton>
            </Tooltip>
          </div>
          <SelectPagination value={pagination?.perPage} onChange={(perPage) => setPagination({ perPage })} />
        </div>
      </div>
    </>
  );
};

const Table = () => {
  const { setPagination, pagination, refetch, data, loading, setselected, selected, variables } = useTable();

  useEffect(() => {
    refetch();
  }, [refetch, variables]);

  return (
    <>
      <TableHeader />
      <div style={{ flex: "1 1 auto" }} className="overflow-auto">
        <StyledTable<React.FC<DataGridProps<SalesOrderInvoice>>>
          rowHeight={56}
          rowKeyGetter={(r: SalesOrderInvoice) => r?.id}
          rowRenderer={(props) => <RowRenderer contextMenuId={MENU_ID} {...props} />}
          columns={columns}
          rows={data?.listSalesOrderInvoices?.invoices ?? []}
          selectedRows={selected}
          onSelectedRowsChange={setselected}
          emptyRowsRenderer={() =>
            loading ? (
              <LoadingRenderer message="Fetching sales order invoices" />
            ) : (
              <EmptyRowsRenderer message="No sales order invoices found" />
            )
          }
        />
      </div>
      <Pagination
        loading={loading}
        currentPage={pagination?.page ?? 1}
        totalPages={data?.listSalesOrderInvoices?.pageInfo?.totalPages}
        canNextPage={pagination?.page < data?.listSalesOrderInvoices?.pageInfo?.totalPages}
        canPreviousPage={data?.listSalesOrderInvoices?.pageInfo?.page > 1 ?? false}
        setPagination={setPagination}
      />
      <ContextMenu />
    </>
  );
};

export { Table as default };
