import MDrawer from "@material-ui/core/Drawer";
import MTooltip from "@material-ui/core/Tooltip";
import React from "react";
import ReactSelect from "react-select";
import ReactAsyncSelect from "react-select/async";
import ReactAsyncCreatableSelect from "react-select/async-creatable";
import styled, { css } from "styled-components";
import tw from "twin.macro";

export const colors = {
  factoryfinchRed: "#d92452",
  shopifyGreen: "#96bf47",
  bigCommerceBlue: "#33313f",
};

export const Drawer = styled(MDrawer)`
  .MuiDrawer-paper {
    z-index: 49;
  }
`;

export const Tooltip = styled((props) => (
  <MTooltip classes={{ popper: props?.className, tooltip: "tooltip", arrow: "arrow" }} {...props} />
))`
  & .arrow {
    ${tw`text-black`}
  }
  & .tooltip {
    ${tw`bg-black`}
  }
`;

interface SelectStyleProps {
  $textSize: "base" | "large" | "sm" | "xs";
  $hasError: boolean;
}

const selectStyle = css`
  ${(props: SelectStyleProps) => {
    switch (props.$textSize) {
      case "large":
        return tw`text-lg`;
      case "base":
        return tw`text-base`;
      case "sm":
        return tw`text-sm`;
      case "xs":
        return tw`text-xs`;
      default:
        return tw`text-base`;
    }
  }};

  .Select__multi-value {
    ${tw`dark:bg-transparent dark:border dark:border-gray-400`}
  }

  .Select__multi-value__remove {
    ${tw`dark:text-gray-300 cursor-pointer`}
  }

  .Select__clear-indicator {
    ${tw`cursor-pointer`}
  }

  .Select__control {
    ${tw`appearance-none leading-normal shadow-none focus:shadow-none rounded bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-400`}
    ${tw`focus-within:border-blue-400 focus:border-blue-400 focus:outline-none focus:shadow-none`}

    ${(props: SelectStyleProps) => (props.$hasError ? tw`border-red-400` : "")};
  }

  .Select__indicator {
    padding-left: 5px;
    padding-right: 5px;
    > svg {
      width: 15px;
    }
  }

  .Select__dropdown-indicator {
    ${tw`cursor-pointer`}
  }

  .Select__value-container {
    ${tw`truncate`}
    min-height: ${(props: SelectStyleProps) => {
      switch (props.$textSize) {
        case "large":
          return "46px";
        case "base":
          return "40px";
        case "xs":
          return "38px";
        default:
          return "40px";
      }
    }}
  }

  .Select__placeholder {
    ${(props: SelectStyleProps) => {
      switch (props.$textSize) {
        case "large":
          return tw`text-lg`;
        case "base":
          return tw`text-base`;
        case "sm":
          return tw`text-sm`;
        case "xs":
          return tw`text-xs`;
        default:
          return tw`text-base`;
      }
    }};
    ${tw`text-gray-400 dark:text-gray-600`};
  }

  .Select__single-value,
  .Select__multi-value {
    ${tw`text-black dark:text-white`}
  }

  .Select__multi-value__label {
    ${tw`text-black dark:text-white`}
  }

  .Select__multi-value__remove {
    ${tw`text-black`}
  }

  .Select__option {
    ${tw`text-left`}
  }

  .Select__input {
    ${tw`text-black dark:text-white`}
  }

  .Select__menu {
    border: 1px solid white;
    ${tw`bg-white dark:bg-gray-800`}
  }

  .Select__option--is-focused {
    ${tw`text-default dark:text-white bg-gray-200 dark:bg-gray-700 cursor-pointer`}
  }

  .Select__control--is-disabled {
    ${tw`opacity-75`}
  }
`;

const smallSelectStyle = css`
  ${tw`text-sm`}
  min-width: 150px;

  .Select__control {
    ${tw`appearance-none leading-normal shadow-none focus:shadow-none rounded bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-400`}
    ${tw`focus-within:border-blue-400 focus:border-blue-400 focus:outline-none focus:shadow-none`}

    ${(props: SelectStyleProps) => (props.$hasError ? tw`border-red-400` : "")};
  }

  .Select__multi-value {
    ${tw`dark:bg-transparent dark:border dark:border-gray-400`}
  }
  .Select__multi-value__label {
    ${tw`text-black dark:text-white`}
  }

  .Select__multi-value__remove {
    ${tw`dark:text-gray-300 cursor-pointer`}
  }

  .Select__clear-indicator {
    ${tw`cursor-pointer`}
  }

  .Select__value-container {
    ${tw`truncate`}
  }

  .Select__placeholder {
    ${tw`text-gray-400 dark:text-gray-600`}
  }

  .Select__single-value,
  .Select__multi-value {
    ${tw`text-black dark:text-white`}
  }

  .Select__multi-value__remove {
    ${tw`text-black`}
  }

  .Select__option {
    ${tw`text-left`}
  }

  .Select__input {
    ${tw`text-black dark:text-white`}
  }

  .Select__menu {
    border: 1px solid white;
    ${tw`bg-white dark:bg-gray-800`}
  }

  .Select__option--is-focused {
    ${tw`text-default dark:text-white bg-gray-200 dark:bg-gray-700 cursor-pointer`}
  }

  .Select__control--is-disabled {
    ${tw`opacity-75`}
  }
`;

export const AsyncSelect = styled((p) => <ReactAsyncSelect {...p} classNamePrefix="Select" />)`
  ${selectStyle}
`;

export const AsyncCreatableSelect = styled((p) => <ReactAsyncCreatableSelect {...p} classNamePrefix="Select" />)`
  ${selectStyle}
`;

export const Select = styled((props) => <ReactSelect {...props} classNamePrefix="Select" />)`
  ${selectStyle}
`;

export const SmallSelect = styled((props) => <ReactSelect {...props} classNamePrefix="Select" />)`
  ${smallSelectStyle}
`;

export const HR = styled.hr`
  ${tw`mb-4`}
`;

export const H1 = styled.div`
  ${tw`text-4xl font-bold`}
`;

export const H2 = styled.div`
  ${tw`text-3xl font-bold`}
`;

export const H3 = styled.div`
  ${tw`text-2xl font-bold`}
`;

export const H4 = styled.div`
  ${tw`text-lg flex items-center font-bold`}
`;

export const SubHeading = styled.div`
  ${tw`text-brand uppercase font-semibold text-sm my-2`}
`;

export const P = styled.div`
  ${tw`text-lg my-2`}
`;

export const PageHeader = styled.div`
  ${tw`py-4 px-4 flex justify-between `}
  > h1 {
    ${tw`text-2xl font-semibold`}
  }
`;

interface ButtonStyleProps {
  $ignoreMinSize?: boolean;
}

export const Button = styled.button<ButtonStyleProps>`
  ${(props) => (props.$ignoreMinSize ? "" : "min-width: 90px;")}
  ${tw`rounded-full px-4 py-2 font-semibold text-white shadow-lg focus:border-blue-400 focus:outline-none focus:ring focus:border-blue-300`}
  &:active {
    filter: brightness(92%);
  }

  &:hover {
    filter: brightness(102%);
  }

  &:disabled {
    ${tw`opacity-75`}
  }
`;

export const SmallButton = styled.button`
  ${tw`rounded-full bg-gray-600 text-white text-xs py-1 px-2 inline text-center font-semibold whitespace-nowrap focus:border-blue-400 focus:outline-none`}
  min-width: 90px;
  &:active {
    filter: brightness(92%);
  }

  &:hover {
    filter: brightness(102%);
  }

  &:disabled {
    ${tw`opacity-75`}
  }
`;

export const SmallBlueButton = styled(SmallButton)`
  ${tw`text-white bg-blue-500`}
`;

export const SmallRedButton = styled(SmallButton)`
  ${tw`text-white bg-brand`}
`;

export const PlainButton = styled(Button)`
  ${tw`bg-white dark:bg-gray-800 text-gray-700 dark:text-gray-100 hover:bg-gray-300 dark:hover:bg-gray-700 hover:text-default dark:hover:text-white shadow-none`}
`;

export const GrayOutlineButton = styled(Button)`
  ${tw`border border-gray-400 bg-white dark:bg-gray-800 text-gray-700 dark:text-gray-100 hover:bg-gray-400 hover:text-gray-700`}
`;

export const RedButton = styled(Button)`
  ${tw`text-white bg-brand`}
`;

export const DarkRedButton = styled(Button)`
  ${tw`text-white bg-red-500`}
`;

export const GreenButton = styled(Button)`
  ${tw`text-white bg-green-400`}
`;

export const BlueButton = styled(Button)`
  ${tw`text-white bg-blue-500`}
`;

export const RedOutlineButton = styled(Button)`
  ${tw`text-brand bg-white dark:bg-gray-800 border border-brand`}

  &:hover {
    ${tw`bg-brand text-white border border-brand`}
  }
`;

export const BlueOutlineButton = styled(Button)`
  ${tw`text-blue-500 bg-white dark:bg-gray-800 border border-blue-500`}

  &:hover {
    ${tw`bg-blue-500 text-white border border-blue-500`}
  }
`;

export const ShopifyGreenButton = styled(Button)`
  background-color: ${colors.shopifyGreen};
`;

export const BigCommerceBlueButton = styled(Button)`
  background-color: ${colors.bigCommerceBlue};
`;

export const IconButton = styled.button`
  min-width: 40px;
  min-height: 40px;
  ${tw`flex justify-center items-center outline-none focus:border-blue-400 focus:outline-none active:opacity-75`}

  &:disabled {
    ${tw`opacity-75 cursor-not-allowed`}
  }
`;

export const Input = styled.input`
  ${tw`bg-white dark:bg-gray-800 border border-gray-300 rounded py-2 px-4 block w-full appearance-none leading-normal focus:border-blue-400 focus:outline-none focus:shadow-none text-lg`}

  &.error {
    ${tw`border border-brand`}
  }

  &:focus {
    ${tw`outline-none shadow-none`}
  }

  &:disabled {
    ${tw`opacity-75 cursor-not-allowed`}
  }
`;

export const SmallInput = styled(Input)`
  ${tw`bg-white dark:bg-gray-800 border border-gray-300 rounded py-1 px-4 block w-full appearance-none leading-normal focus:border-blue-400 focus:outline-none focus:shadow-none text-base`}
  &::placeholder {
    ${tw`text-gray-400 dark:text-gray-600`}
  }
`;

export const XSInput = styled(Input)`
  ${tw`bg-white dark:bg-gray-800 border border-gray-300 rounded py-1 px-4 block w-full appearance-none leading-normal focus:border-blue-400 focus:outline-none focus:shadow-none text-xs`}
  &::placeholder {
    ${tw`text-gray-400 dark:text-gray-600`}
  }
`;

export const Form = styled.form`
  input {
    ${tw`mb-2`}
  }
`;

export const Badge = styled.div`
  ${tw`rounded-full bg-gray-600 text-white text-xs py-1 px-2 inline text-center font-semibold whitespace-nowrap`}
`;

export const BlueBadge = styled(Badge)`
  ${tw`bg-blue-600 text-white`}
`;

export const RedBadge = styled(Badge)`
  ${tw`bg-red-400 text-white`}
`;

export const RedOutlineBadge = styled(Badge)`
  ${tw`bg-white dark:bg-gray-800 text-red-400 border border-red-400`}
`;

export const GreenBadge = styled(Badge)`
  ${tw`bg-green-400 text-white`}
`;

export const GrayBadge = styled(Badge)`
  ${tw`bg-gray-600 text-white`}
`;

export const Spinner = styled.div`
  @keyframes pulse {
    0% {
      opacity: 0.2;
    }

    20% {
      opacity: 0.9;
    }

    100% {
      opacity: 0.2;
    }
  }

  span {
    animation-name: pulse;
    animation-duration: 1.3s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }
`;

export const GroupColorIndicator = styled.div`
  ${tw`rounded-full mx-2`}

  height: 10px;
  width: 10px;
  background-color: ${(props: { $color: string }) => props?.$color ?? "transparent"};
`;

interface ITruncatedText {
  $lines?: number;
}

export const TruncatedText = styled.div<ITruncatedText>`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const Table = styled.table`
  ${tw`table-auto w-full`}

  thead {
    tr:first-child th {
      ${tw`text-base pb-2`}
    }
  }

  thead tr:last-child {
    ${tw`border-b border-gray-600`}
  }

  thead th {
    ${tw`bg-white dark:bg-gray-800 text-default text-left px-4`}
  }

  thead input {
    ${tw`border-0 w-full px-1 py-1`}
  }

  tbody tr:nth-child(even) {
    ${tw`bg-gray-200 dark:bg-gray-700`}
  }

  tbody > tr > td {
    ${tw`py-2 px-4`}
  }
`;
