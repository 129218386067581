import LinearProgress from "@material-ui/core/LinearProgress";
import axios from "axios";
import TextInput from "components/Form/TextInput";
import { ButtonLoading } from "components/Loading";
import { CenterModal } from "components/Modals/CenterModal";
import { LocalStorageKeys } from "config";
import { IntegrationType, useImportDataMutation, useMeQuery, useRemoveIntegrationMutation } from "generated/graphql";
import { useIntegrations } from "hooks/integrations";
import { useShopifySyncJob } from "hooks/jobs";
import React, { useState } from "react";
import { BlueButton, H4, HR, PlainButton } from "styles";
import { Controller, useForm } from "react-hook-form";
import { IntegrationCard } from "./styles";
import Checkbox from "components/Form/Checkbox";
import { gsEvent } from "components/Events/events";

const ImportProgress: React.FC = () => {
  const { job } = useShopifySyncJob();

  return (
    <>
      {job?.status === "failed" && <div className="text-sm py-2 text-brand">Sync failed! Please try again</div>}
      {job?.status === "complete" && <div className="text-sm py-2">Sync Complete!</div>}
      {job?.status === "active" && (
        <div className="w-full py-2">
          <LinearProgress classes={{ root: "w-full" }} variant="determinate" value={job?.progress ?? 0} />
          <div className="text-sm text-center my-2">{job?.msg}</div>
        </div>
      )}
    </>
  );
};

interface SyncFormData {
  webhooks: boolean;
  products: boolean;
  orders: boolean;
  locations: boolean;
}

const SyncForm: React.FC<{ onRequestClose: () => void }> = ({ onRequestClose }) => {
  const [importData] = useImportDataMutation();
  const { setJob } = useShopifySyncJob();
  const { handleSubmit, control, formState } = useForm<SyncFormData>({
    defaultValues: {
      webhooks: true,
      products: true,
      orders: true,
      locations: true,
    },
  });
  const submit = async (formData: SyncFormData) => {
    let resources = [];
    if (formData.locations) {
      resources.push("LOCATIONS");
    }
    if (formData.webhooks) {
      resources.push("WEBHOOKS");
    }
    if (formData.orders) {
      resources.push("ORDERS");
    }
    if (formData.products) {
      resources.push("PRODUCTS");
    }

    const resp = await importData({
      variables: { input: { source: IntegrationType.Shopify, resources } },
    });
    setJob(resp?.data?.importData);

    onRequestClose();
    gsEvent("Connected Shopify");
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <H4 className="my-2">Shopify Sync Options</H4>

      <Controller
        control={control}
        name="webhooks"
        defaultValue={false}
        render={({ field }) => (
          <Checkbox
            data-testid="sync-Webhooks"
            className="text-sm"
            onChange={(e) => field.onChange(e?.checked)}
            label="Webhooks"
            checked={field.value}
          />
        )}
      />

      <Controller
        control={control}
        name="products"
        defaultValue={false}
        render={({ field }) => (
          <Checkbox
            data-testid="sync-Products"
            className="text-sm"
            onChange={(e) => field.onChange(e?.checked)}
            label="Products"
            checked={field.value}
          />
        )}
      />

      <Controller
        control={control}
        name="orders"
        defaultValue={false}
        render={({ field }) => (
          <Checkbox
            data-testid="sync-Orders"
            className="text-sm"
            onChange={(e) => field.onChange(e?.checked)}
            label="Orders"
            checked={field.value}
          />
        )}
      />

      <Controller
        control={control}
        name="locations"
        defaultValue={false}
        render={({ field }) => (
          <Checkbox
            data-testid="sync-Locations"
            className="text-sm"
            onChange={(e) => field.onChange(e?.checked)}
            label="Locations"
            checked={field.value}
          />
        )}
      />
      <div className="flex justify-end items-center">
        <div className="grid grid-cols-2 gap-x-2">
          <PlainButton type="button" onClick={onRequestClose}>
            Cancel
          </PlainButton>

          <BlueButton>{formState?.isSubmitting ? <ButtonLoading /> : "Sync"}</BlueButton>
        </div>
      </div>
    </form>
  );
};

const ConnectShopify = () => {
  const { job } = useShopifySyncJob();
  const meQuery = useMeQuery();

  const connectedApps = useIntegrations();
  const [modalOpen, setmodalOpen] = useState(false);
  const [syncOptionsModal, setsyncOptionsModal] = useState(false);
  const isConnected = connectedApps?.[IntegrationType.Shopify];
  const [removeIntegration, removeIntegrationMutation] = useRemoveIntegrationMutation();
  const { handleSubmit, register, formState } = useForm();

  const remove = async () => {
    await removeIntegration({ variables: { id: isConnected?.id } });
    connectedApps.refetch();
  };

  const connect = async (formData: { shopName: string }) => {
    const resp = await axios.post(
      "/api/oauth/shopify",
      {
        shopName: formData.shopName,
      },
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem(LocalStorageKeys.Token)}`,
        },
      }
    );
    window.location = resp?.data?.redirect;
  };

  return (
    <>
      <IntegrationCard>
        {connectedApps?.loading ? (
          <>
            <img
              alt="shopify logo"
              width="75"
              src="https://cdn.shopify.com/shopifycloud/brochure/assets/brand-assets/shopify-logo-shopping-bag-full-color-66166b2e55d67988b56b4bd28b63c271e2b9713358cb723070a92bde17ad7d63.svg"
            />
            <div className="my-4 font-semibold">
              <ButtonLoading />
            </div>
          </>
        ) : (
          <>
            <img
              alt="shopify logo"
              width="75"
              src="https://cdn.shopify.com/shopifycloud/brochure/assets/brand-assets/shopify-logo-shopping-bag-full-color-66166b2e55d67988b56b4bd28b63c271e2b9713358cb723070a92bde17ad7d63.svg"
            />
            <div className="my-4 font-semibold">Shopify</div>
            {isConnected && (
              <>
                <div className="text-sm font-semibold text-center truncate w-full">{isConnected?.name}</div>
                <div className="text-md text-green-500 mb-2">connected</div>
                {job && <ImportProgress />}
                <div>{}</div>
                <BlueButton onClick={() => setsyncOptionsModal(true)}>
                  {job?.status === "active" ? <ButtonLoading /> : "Sync"}
                </BlueButton>
              </>
            )}
            {meQuery?.data?.me?.organization?.createdBy !== "shopify" && (
              <>
                {isConnected ? (
                  <PlainButton
                    className="mt-4 text-sm"
                    onClick={remove}
                    disabled={removeIntegrationMutation?.loading ?? false}
                  >
                    {removeIntegrationMutation?.loading ? <ButtonLoading /> : "Remove"}
                  </PlainButton>
                ) : (
                  <BlueButton onClick={() => setmodalOpen(true)}>Connect</BlueButton>
                )}
              </>
            )}
          </>
        )}
      </IntegrationCard>
      <CenterModal
        className="bg-white dark:bg-gray-800 p-4 rounded"
        isOpen={modalOpen}
        onRequestClose={() => setmodalOpen(false)}
      >
        <H4 className="my-2">Connect your Shopify Store</H4>
        <HR className="mb-4" />
        <form onSubmit={handleSubmit(connect)}>
          <TextInput {...register("shopName", { required: true })} placeholder="mystore.myshopify.com" />

          <div className="flex justify-end my-2">
            <PlainButton type="button" onClick={() => setmodalOpen(false)} className="mr-2">
              Cancel
            </PlainButton>
            <BlueButton>{formState?.submitCount === 1 ? <ButtonLoading /> : "Connect"}</BlueButton>
          </div>
        </form>
      </CenterModal>

      <CenterModal
        className="bg-white dark:bg-gray-800 p-4 rounded"
        isOpen={syncOptionsModal}
        onRequestClose={() => setsyncOptionsModal(false)}
      >
        <SyncForm onRequestClose={() => setsyncOptionsModal(false)} />
      </CenterModal>
    </>
  );
};

export { ConnectShopify as default };
