import React, { useEffect, useCallback } from "react";
import cx from "classnames";
import { useHistory } from "react-router-dom";
import { FormatterProps, HeaderRendererProps, Column, SortDirection, DataGridProps } from "react-data-grid";
import Pagination from "components/Pagination";
import { useTable } from "./context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt, faCheckSquare, faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { faShopify } from "@fortawesome/free-brands-svg-icons";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { Menu, Item, ItemParams } from "react-contexify";
import { shortDateTime, shortId } from "utils/formatters";
import { Routes, getRouteWithParams } from "routes";
import { CustomerOrder, OrderByDirection } from "generated/graphql";
import { LoadingRenderer, EmptyRowsRenderer, RowRenderer } from "components/Table/Renderers";
import { Link } from "react-router-dom";
import { PickStatus } from "components/Badges/PickStatus";
import { PaidStatus } from "components/Badges/PaidStatus";
import { FulfillmentStatus } from "components/Badges/FulfillmentStatus";
import StyledTable from "components/Table";
import { SelectPagination } from "components/Select/Pagination";
import { IconButton, Tooltip } from "styles";
import { generatePdfLabels } from "utils/labels";

const MENU_ID = "customer-order-row-menu";
const LABEL_TITLE = "CUSTOMER ORDER";

const columns: Column<CustomerOrder, unknown>[] = [
  {
    headerRenderer: (props: HeaderRendererProps<CustomerOrder>) => {
      return (
        <div
          className="flex justify-center items-center h-full w-full text-brand cursor-pointer text-lg py-2"
          onClick={(e) => props.onAllRowsSelectionChange(!props?.allRowsSelected)}
        >
          <FontAwesomeIcon icon={props?.allRowsSelected ? faCheckSquare : faSquare} />
        </div>
      );
    },
    formatter: (props: FormatterProps<CustomerOrder, unknown>) => {
      return (
        <div
          className={cx("flex justify-center items-center h-full w-full text-brand cursor-pointer text-lg py-2")}
          onClick={(e) => props.onRowSelectionChange(!props?.isRowSelected, e.shiftKey)}
        >
          <FontAwesomeIcon icon={props?.isRowSelected ? faCheckSquare : faSquare} />
        </div>
      );
    },

    key: "select-row",
    name: "",
    resizable: false,
    sortable: false,
    maxWidth: 35,
    width: 35,
  },
  {
    key: "id",
    name: "ID",
    width: 100,
    formatter: (props) => {
      return (
        <Link
          title={props?.row?.id}
          to={getRouteWithParams(Routes.CustomerOrder, {
            id: props?.row?.id,
          })}
          className={props?.row?.deletedAt ? "text-gray-400 dark:text-gray-200 line-through" : ""}
        >
          {shortId(props?.row?.id)}
        </Link>
      );
    },
  },
  {
    key: "orderNumber",
    name: "Order #",
    headerCellClass: "text-center",
    formatter: (props) => {
      if (props.row.source === "shopify") {
        return (
          <div className="h-full flex flex-row items-center justify-center">
            {props?.row?.orderNumber}

            <FontAwesomeIcon title="This order was created by Shopify" icon={faShopify} size="sm" className="mx-2" />
          </div>
        );
      }
      return <div className="h-full flex flex-row items-center justify-center">{props?.row?.orderNumber}</div>;
    },
  },
  {
    key: "customerName",
    name: "Customer",
    minWidth: 100,
    formatter: (props) => {
      const customer = props?.row.customer;
      const fullName = `${customer?.firstName ?? ""} ${customer?.lastName ?? ""}`;

      return (
        <div title={fullName} className="truncate">
          {fullName}
        </div>
      );
    },
  },
  {
    key: "createdAt",
    name: "Created",
    minWidth: 100,
    sortable: true,
    cellClass: "text-xs flex items-center",
    formatter: (props) => {
      return <>{`${shortDateTime(props?.row.createdAt)}`}</>;
    },
  },
  {
    key: "paidStatus",
    name: "Paid Status",
    minWidth: 100,
    headerCellClass: "text-center",
    formatter: (props) => {
      return (
        <div className="h-full flex flex-row items-center justify-center ">
          <PaidStatus status={props?.row?.paidStatus} />
        </div>
      );
    },
  },
  {
    key: "status",
    name: "Pick Status",
    minWidth: 100,
    headerCellClass: "text-center",
    formatter: (props) => {
      return (
        <div className="h-full flex flex-row items-center justify-center ">
          <PickStatus status={props?.row?.pickStatus} />
        </div>
      );
    },
  },
  {
    key: "fulfillmentStatus",
    name: "Fulfillment Status",

    minWidth: 100,
    headerCellClass: "text-center",
    formatter: (props) => {
      return (
        <div className="h-full flex flex-row items-center justify-center ">
          <FulfillmentStatus status={props?.row?.fulfillmentStatus} />
        </div>
      );
    },
  },

  {
    key: "canPick",
    name: "Can Pick",
    cellClass: "text-center",
    headerCellClass: "text-center",

    formatter: (props) => {
      return props?.row?.canPick ? <FontAwesomeIcon icon={faCheckSquare} /> : null;
    },
  },
];

const ContextMenu = () => {
  const history = useHistory();
  const { selected, selectedRows, setisDeletingCustomerOrders } = useTable();
  const handleItemClick: (args: ItemParams<CustomerOrder, any>) => void = ({ event, props: item }) => {
    switch (event.currentTarget.id) {
      case "delete":
        setisDeletingCustomerOrders([item]);
        break;
      case "printLabel":
        generatePdfLabels(LABEL_TITLE, [item]);
        break;
      case "generateLabels":
        generatePdfLabels(LABEL_TITLE, selectedRows);
        break;
      case "deleteSelected":
        setisDeletingCustomerOrders(selectedRows);
        break;

      case "view":
        history.push(getRouteWithParams(Routes.CustomerOrder, { id: item.id }));
        break;
      default:
        break;
    }
  };

  return (
    <Menu id={MENU_ID}>
      <Item id="view" onClick={handleItemClick}>
        View
      </Item>
      <Item id="printLabel" onClick={handleItemClick}>
        Print Label
      </Item>
      <Item id="delete" onClick={handleItemClick}>
        <div className="text-brand">Delete</div>
      </Item>

      {selected?.size > 0 && (
        <>
          <div
            className="mt-2 w-full cursor-default"
            style={{
              height: "1px",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            }}
          />

          <div className="text-xs text-gray-600 px-3 font-semibold bg-gray-100  py-2">Bulk Actions</div>

          <Item id="generateLabels" onClick={handleItemClick}>
            Generate Labels
          </Item>
          <Item id="deleteSelected" onClick={handleItemClick}>
            <div className="text-brand">Delete selected</div>
          </Item>
        </>
      )}
    </Menu>
  );
};

const TableHeader = () => {
  const { selected, setPagination, pagination, setShowFilters, setShowQuickFilters, showQuickFilters } = useTable();

  return (
    <>
      <div className="flex justify-between items-center py-2">
        <div className="px-2">{selected.size > 0 && <div>{selected.size} rows selected</div>}</div>
        <div className="flex">
          <div className="flex flex-row items-center px-2">
            <Tooltip arrow title={showQuickFilters ? `Hide quick filters` : `Show quick filters`}>
              <IconButton
                className={showQuickFilters ? `text-yellow-500 ` : ``}
                onClick={() => setShowQuickFilters(!showQuickFilters)}
              >
                <FontAwesomeIcon icon={faBolt} size="lg" />
              </IconButton>
            </Tooltip>

            <Tooltip arrow title="Filters">
              <IconButton className="" onClick={() => setShowFilters(true)}>
                <FontAwesomeIcon icon={faSlidersH} size="lg" />
              </IconButton>
            </Tooltip>
          </div>
          <SelectPagination value={pagination?.perPage} onChange={(perPage) => setPagination({ perPage })} />
        </div>
      </div>
    </>
  );
};

const Table = () => {
  const {
    orderBy,
    setOrderBy,
    setPagination,
    pagination,
    listCustomerOrders,
    data,
    loading,
    setselected,
    selected,
    variables,
  } = useTable();

  const handleSort = useCallback(
    (columnKey: string, direction: SortDirection) => {
      setOrderBy(columnKey, direction === "DESC" ? OrderByDirection.Desc : OrderByDirection.Asc);
    },
    [setOrderBy]
  );

  useEffect(() => {
    listCustomerOrders({
      variables,
    });
  }, [listCustomerOrders, variables]);

  return (
    <>
      <TableHeader />
      <div style={{ flex: "1 1 auto" }} className="overflow-auto">
        <StyledTable<React.FC<DataGridProps<CustomerOrder>>>
          rowHeight={56}
          rowKeyGetter={(r: CustomerOrder) => r?.id}
          rowRenderer={(props) => <RowRenderer contextMenuId={MENU_ID} {...props} />}
          columns={columns}
          rows={data?.listCustomerOrders?.orders ?? []}
          selectedRows={selected}
          onSelectedRowsChange={setselected}
          emptyRowsRenderer={() =>
            loading ? (
              <LoadingRenderer message="Fetching sales orders" />
            ) : (
              <EmptyRowsRenderer message="No sales orders found" />
            )
          }
          onSort={handleSort}
          sortColumn={orderBy.key}
          sortDirection={orderBy.direction}
        />
      </div>
      <Pagination
        loading={loading}
        currentPage={pagination?.page ?? 1}
        totalPages={data?.listCustomerOrders?.pageInfo?.totalPages}
        canNextPage={pagination?.page < data?.listCustomerOrders?.pageInfo?.totalPages}
        canPreviousPage={data?.listCustomerOrders?.pageInfo?.page > 1 ?? false}
        setPagination={setPagination}
      />
      <ContextMenu />
    </>
  );
};

export { Table as default };
