import React, { useMemo } from "react";
import { PurchaseRequestStatus } from "generated/graphql";
import BaseBadge from "./Base";

export const PurchaseRequestStatusBadge: React.FC<{
  status: string;
}> = ({ status }) => {
  const props = useMemo(() => {
    switch (status) {
      case PurchaseRequestStatus.Requested:
        return {
          text: "Requested",
          style: "bg-transparent border border-gray-600 dark:border-gray-400",
          textStyle: "font-semibold text-gray-600 dark:text-gray-300 text-xs text-center",
        };

      case PurchaseRequestStatus.Approved:
        return {
          text: "Approved",
          style: "bg-transparent border border-green-400 text-green-400 dark:border-green-400",
          textStyle: "font-semibold border-green-400 text-xs text-center",
        };

      case PurchaseRequestStatus.Cancelled:
        return {
          text: "Cancelled",
          style: "bg-transparent border border-brand dark:border-brand",
          textStyle: "font-semibold text-brand dark:text-brand text-xs text-center",
        };

      case PurchaseRequestStatus.Submitted:
        return {
          text: "Submitted",
          style: "bg-green-400",
          textStyle: "font-semibold text-white text-xs text-center",
        };

      default:
        return {
          text: "Unknown",
          style: "bg-gray-400",
          textStyle: "font-semibold text-white text-xs text-center",
        };
    }
  }, [status]);

  return <BaseBadge {...props} />;
};
