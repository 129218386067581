import React from "react";
import { ManufacturingOrderStatus } from "generated/graphql";

import { Badge, GreenBadge, BlueBadge, RedBadge } from "styles";

interface OrderStatusProps {
  status: ManufacturingOrderStatus;
}

const OrderStatus: React.FC<OrderStatusProps> = ({ status }) => {
  switch (status) {
    case ManufacturingOrderStatus.NotStarted:
      return <Badge>Not Started</Badge>;
    case ManufacturingOrderStatus.InProgress:
      return <BlueBadge>In Progress</BlueBadge>;
    case ManufacturingOrderStatus.Complete:
      return <GreenBadge>Complete</GreenBadge>;
    case ManufacturingOrderStatus.Cancelled:
      return <RedBadge>Cancelled</RedBadge>;
    default:
      return <Badge>Unknown</Badge>;
  }
};

export { OrderStatus as default };
