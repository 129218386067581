import React, { useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTable } from "../context";
import { toInventoryItemType } from "components/Form/helpers";
import { IBulkInventoryItemFormData } from "./BulkInventoryItemForm";
import InventoryItemTypeBadge from "components/Badges/InventoryItemType";
import Dinero from "dinero.js";

const ConfirmUpdate: React.FC<{ form: UseFormReturn<IBulkInventoryItemFormData> }> = ({ form }) => {
  const { selectedRows } = useTable();
  const values = form.watch();
  // const inventoryItemType = toInventoryItemType(values);

  const typeChanges = useMemo(() => {
    const fields = form?.formState?.dirtyFields;
    return !!fields?.type;
  }, [form?.formState?.dirtyFields]);

  return (
    <>
      <div className="my-4 text-2xl font-semibold text-center">
        Are you sure you want to update {selectedRows.length} items?
      </div>
      <div className="text-sm text-center">This will overwrite the existing values for all selected items</div>

      <div className="grid grid-cols-2 gap-x-2 gap-y-2 py-4">
        {values?.units && (
          <>
            <div>Set units</div>
            <div className="flex justify-center text-center">{values.units}</div>
          </>
        )}
        {values?.valuePerUnit && (
          <>
            <div>Set value per unit</div>
            <div className="flex justify-center text-center">
              {Dinero({
                amount: Math.floor(values.valuePerUnit * 100),
              }).toFormat("$0,0.00")}
            </div>
          </>
        )}

        {typeChanges && (
          <>
            <div>Set type</div>
            <div className="flex justify-center text-center">
              <InventoryItemTypeBadge type={toInventoryItemType(values?.type)} />
            </div>
          </>
        )}

        {values?.defaultVendorProduct && (
          <>
            <div>Set default vendor product</div>
            <div className="flex justify-center text-center">
              {values?.defaultVendorProduct?.vendor?.name} {values?.defaultVendorProduct?.product?.name}
            </div>
          </>
        )}
        {values?.defaultWorkflow && (
          <>
            <div>Set default workflow</div>
            <div className="flex justify-center text-center">{values?.defaultWorkflow?.name}</div>
          </>
        )}

        {values?.ingredients?.length > 0 && (
          <>
            <div>Add ingredients</div>
            <div className="flex justify-center text-center">{values?.ingredients?.length} ingredients</div>
          </>
        )}

        {values?.tags?.length > 0 && (
          <>
            <div>Add tags</div>
            <div className="flex justify-center text-center">{values?.tags?.length} tags</div>
          </>
        )}
      </div>
    </>
  );
};

export { ConfirmUpdate as default };
