import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { UnregisterCallback } from "history";

export const usePrompt = (
  when: boolean,
  message: string = "Would you like to leave this screen without saving your changes?"
) => {
  const history = useHistory();

  const self = useRef<UnregisterCallback | null>();

  const onWindowOrTabClose = (event: BeforeUnloadEvent) => {
    if (!when) {
      return;
    }

    if (typeof event === "undefined") {
      event = window.event as BeforeUnloadEvent;
    }

    if (event) {
      event.returnValue = message;
    }

    return message;
  };

  useEffect(() => {
    if (when) {
      self.current = history.block(message);
    } else {
      self.current = null;
    }

    window.addEventListener("beforeunload", onWindowOrTabClose);

    return () => {
      if (self.current) {
        self.current();
        self.current = null;
      }

      window.removeEventListener("beforeunload", onWindowOrTabClose);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, when, onWindowOrTabClose]);
};
