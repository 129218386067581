import { IChart, IOnLinkCompleteInput } from "@mrblenny/react-flow-chart";
import { IToast } from "hooks/toast";
import { WorkflowStep } from "generated/graphql";

export const getPort = (
  chart: IChart<undefined, WorkflowStep>,
  nId: string,
  pId: string
) => chart.nodes[nId].ports[pId];

export const getNode = (chart: IChart<undefined, WorkflowStep>, nId: string) =>
  chart.nodes[nId];

export const validateLink: (
  toast: IToast
) => (
  props: IOnLinkCompleteInput & {
    chart: IChart<undefined, WorkflowStep>;
  }
) => boolean = (toast) => ({
  linkId,
  fromNodeId,
  fromPortId,
  toNodeId,
  toPortId,
  chart,
}) => {
  const fromPort = getPort(chart, fromNodeId, fromPortId);
  const toPort = getPort(chart, toNodeId, toPortId);

  if (fromNodeId === toNodeId) {
    toast.error("Steps cannot connect to themselves");
    return false;
  }

  if (
    (fromPort.type === "input" && toPort.type === "input") ||
    (fromPort.type === "output" && toPort.type === "output") ||
    (fromPort.type === "input" && toPort.type === "output")
  ) {
    toast.error("Links must be made from outputs to inputs");
    return false;
  }

  return true;
};
