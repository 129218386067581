import React from "react";
import {
  useListVendorProductsQuery,
  VendorProduct,
  useCreateVendorProductMutation,
  ListVendorProductsDocument,
} from "generated/graphql";
import { AsyncCreatableSelect } from "styles";
import { useToast } from "hooks/toast";
import { useDelayedQuery } from "hooks/delayedQuery";

interface ISelectResource<T> {
  name?: string;
  className?: string;
  value?: T[] | T;
  onChange: (m: T | T[]) => void;
  size?: "base" | "large";
  placeholder?: string;
  isMulti?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  hasError?: boolean;
}

const SelectVendorProduct = ({
  value,
  onChange,
  className = "",
  name = "",
  size = "base",
  isMulti = false,
  isDisabled = false,
  hasError = false,
  isLoading = false,
  placeholder = "Select Vendor Product...",
  defaultFilters = {},
}: ISelectResource<VendorProduct> & {
  defaultFilters?: { vendorId?: string };
}) => {
  const toast = useToast();
  const { refetch, loading, variables } = useListVendorProductsQuery({
    variables: { filters: defaultFilters },
  });
  const [create, createMutation] = useCreateVendorProductMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ListVendorProductsDocument, variables }],
  });

  const { delayedQuery, handleInputChange } = useDelayedQuery<VendorProduct>({
    refetch: async (inputValue: string) => {
      if (defaultFilters?.vendorId) {
        const { data } = await refetch({
          filters: { ...defaultFilters, name: inputValue },
        });
        return data?.listVendorProducts?.products ?? [];
      }
      return [];
    },
  });

  const handleCreate = async (inputValue: string) => {
    try {
      const tag = await create({
        variables: {
          input: { name: inputValue.trim(), vendorId: defaultFilters.vendorId },
        },
      });

      onChange(tag?.data?.createVendorProduct ?? null);
      toast.success(`Created Vendor Product ${inputValue}`);
    } catch (err) {
      toast.error("Unable to create vendor product");
    }
  };

  return (
    <AsyncCreatableSelect
      isDisabled={isDisabled}
      isMulti={isMulti}
      isLoading={loading || isLoading || createMutation?.loading}
      $textSize={size}
      $hasError={hasError}
      placeholder={placeholder}
      name={name}
      className={className}
      value={value}
      loadOptions={delayedQuery}
      cacheOptions
      isClearable
      defaultOptions
      onInputChange={handleInputChange}
      onChange={onChange}
      onCreateOption={handleCreate}
      allowCreateWhileLoading={false}
      getOptionLabel={(opt: any) => {
        if (opt?.__isNew__) {
          return opt?.label;
        }

        return opt?.name;
      }}
    />
  );
};

export { SelectVendorProduct as default };
