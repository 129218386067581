import { faPlusCircle, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonWithIcon } from "components/Buttons";
import { ScrollableForm } from "components/Form/ScrollableForm";
import TextInput from "components/Form/TextInput";
import { SideModal } from "components/Modals/SideModal";
import {
  GetOrganizationDocument,
  useCreateInvitationMutation,
  useGetOrganizationQuery,
  UserRole,
} from "generated/graphql";
import { useToast } from "hooks/toast";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { H4, HR, Tooltip } from "styles";
import { Table } from "./styles";
import { gsEvent } from "components/Events/events";

const InviteAdmin: React.FC<{ onRequestClose: () => void }> = ({ onRequestClose }) => {
  const toast = useToast();
  const { handleSubmit, register, formState } = useForm<{ email: string }>();
  const mutationOpts = {
    refetchQueries: [{ query: GetOrganizationDocument }],
  };

  const [createInvite] = useCreateInvitationMutation(mutationOpts);

  const onSubmit = async (input: { email: string }) => {
    try {
      await createInvite({ variables: { input } });
      gsEvent("Invited Admin");
      onRequestClose();
    } catch (err) {
      toast.error(err?.message ?? `unable to invite ${input.email}`);
    }
  };

  return (
    <ScrollableForm
      title="Invite Admin"
      onSubmit={handleSubmit(onSubmit)}
      onCancel={onRequestClose}
      submitLabel="Invite"
      formState={formState}
    >
      <TextInput className="mb-4" placeholder="Email" {...register("email", { required: true })} />
    </ScrollableForm>
  );
};

const Users: React.FC = () => {
  const { data } = useGetOrganizationQuery();

  const [inviteAdmin, setinviteAdmin] = useState(false);

  return (
    <>
      <div>
        <div className="flex justify-between">
          <H4>
            Admins
            <Tooltip title="Employees with access to the Factory Finch admin app" arrow>
              <span className="ml-4 text-base text-gray-600">
                <FontAwesomeIcon icon={faQuestionCircle} />
              </span>
            </Tooltip>
          </H4>
          <ButtonWithIcon className="bg-brand" icon={faPlusCircle} onClick={() => setinviteAdmin(true)}>
            Invite
          </ButtonWithIcon>
        </div>
        <HR className="my-4" />
        <Table>
          <thead>
            <tr>
              <th className="font-semibold">Email</th>
              <th className="font-semibold">Role</th>
            </tr>
          </thead>
          <tbody>
            {data?.organization?.users?.map((user) => {
              return (
                <tr key={user?.id}>
                  <td>{user?.email}</td>
                  <td>
                    <div
                      className={`w-full ${
                        user?.role === UserRole.Admin ? "bg-red-400" : "bg-gray-800"
                      } rounded-full px-1 py-1 text-xs font-semibold text-center text-white w-1/2`}
                    >
                      {user?.role}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <SideModal side="RIGHT" isOpen={inviteAdmin} onRequestClose={() => setinviteAdmin(false)}>
        <InviteAdmin onRequestClose={() => setinviteAdmin(false)} />
      </SideModal>
    </>
  );
};

export { Users as default };
