import { Theme, useTheme } from "hooks/theme";
import React, { useState } from "react";
import Lottie from "react-lottie-light";
import { Spinner } from "styles";
import flapWinkAnimation from "styles/animations/flap-wink-white.json";
import flapWinkRedAnimation from "styles/animations/flap-wink.json";
interface LoadingAnimationProps {
  width?: number;
}

export const OnHoverFlapWink: React.FC = () => {
  const [stopped, setstop] = useState(true);
  const { theme } = useTheme();
  return (
    <div onMouseEnter={() => setstop(false)} onMouseLeave={() => setstop(true)} title="Chirp Chirp!">
      <Lottie
        options={{
          loop: true,
          autoplay: false,
          animationData: theme === Theme.Light ? flapWinkAnimation : flapWinkRedAnimation,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        height={50}
        width={50}
        isStopped={stopped}
        isPaused={false}
      />
    </div>
  );
};

export const LoadingAnimation: React.FC<LoadingAnimationProps> = ({ width = 200 }) => {
  return (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: flapWinkRedAnimation,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      }}
      height={width}
      width={width}
      isStopped={false}
      isPaused={false}
    />
  );
};

interface ButtonLoadingProps {
  className?: string;
}

export const ButtonLoading: React.FC<ButtonLoadingProps> = ({ className = "" }) => (
  <Spinner className={className}>
    <span>• </span>
    <span>• </span>
    <span>•</span>
  </Spinner>
);

export const LoadingWithLabel: React.FC<{ label?: string }> = ({ label = "Loading" }) => {
  return (
    <div className="w-full flex flex-col items-center justify-center my-8">
      <div className="text-sm">{label}</div>
      <div className="text-lg">
        <ButtonLoading />
      </div>
    </div>
  );
};

export const LoadingAnimationWithLabel: React.FC<{ message: string }> = ({ message }) => {
  return (
    <div className="flex flex-col justify-center items-center">
      <LoadingAnimation />
      <div>{message}</div>
      <ButtonLoading />
    </div>
  );
};

export const FullScreenLoading: React.FC<{ message: string }> = ({ message }) => {
  return (
    <div className="h-screen flex flex-col items-center justify-center">
      <LoadingAnimation />
      <div>{message}</div>
      <ButtonLoading />
    </div>
  );
};
