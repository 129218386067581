import {
  PurchaseRequestStatus,
  useUpdatePurchaseRequestMutation,
  ListPurchaseRequestsDocument,
  InventoryItem,
  Vendor,
  VendorProduct,
  InventoryItemType,
  useGetPurchaseRequestQuery,
} from "generated/graphql";
import React, { useEffect } from "react";
import { useTable } from "./context";
import { notEmpty } from "utils/filters";
import { SelectPurchaseRequestStatus } from "components/Select/PurchaseRequestStatus";
import { SubHeading } from "styles";
import { useForm, Controller } from "react-hook-form";
import CurrencyInput from "components/Form/CurrencyInput";
import InputWithUnits from "components/Form/InputWithUnits";
import { SelectInventoryItemModalInput } from "components/Select/InventoryItem";
import { SelectVendor } from "components/Select/Vendor";
import SelectVendorProduct from "components/Select/VendorProduct";
import { ScrollableForm } from "components/Form/ScrollableForm";
import { useToast } from "hooks/toast";
interface IEditPurchaseOrderForm {
  inventoryItem: Pick<InventoryItem, "id" | "name" | "units" | "defaultVendorProduct">;
  quantity: number;
  pricePerUnit: number;
  vendorProduct: { vendor: Pick<Vendor, "id" | "name">; product: Pick<VendorProduct, "id" | "name"> };
  status: PurchaseRequestStatus;
}

export const Edit = () => {
  const toast = useToast();
  const { variables, isEditing, setIsEditing } = useTable();
  const { control, setValue, reset, watch, formState, handleSubmit } = useForm<IEditPurchaseOrderForm>();
  const [update] = useUpdatePurchaseRequestMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ListPurchaseRequestsDocument, variables }],
  });
  const { data, loading } = useGetPurchaseRequestQuery({
    variables: {
      id: isEditing?.id,
    },
  });
  const submit = async (formData: IEditPurchaseOrderForm) => {
    try {
      await update({
        variables: {
          input: {
            id: isEditing?.id,
            status: formData?.status ?? null,
            vendorProductId: formData?.vendorProduct?.product?.id ?? null,
            inventoryItemId: formData?.inventoryItem?.id ?? null,
            quantity: formData?.quantity ?? null,
            pricePerUnit: formData?.pricePerUnit ?? null,
            // delayUntil: String
          },
        },
      });
    } catch (err) {
      toast.error(`Unable to update purchase request ${err?.message}`);
    } finally {
      setIsEditing(null);
    }
  };
  const values = watch();

  useEffect(() => {
    if (data?.purchaseRequest) {
      reset({
        inventoryItem: data?.purchaseRequest?.inventoryItem,
        quantity: data?.purchaseRequest?.quantity,
        pricePerUnit: data?.purchaseRequest?.pricePerUnit ?? 0,
        vendorProduct: {
          product: data?.purchaseRequest?.vendorProduct,
          vendor: data?.purchaseRequest?.vendorProduct?.vendor,
        },
        status: data?.purchaseRequest?.status,
      });
    }
  }, [reset, data]);

  if (loading) {
    return <></>;
  }

  return (
    <ScrollableForm
      title="Edit Purchase Request"
      formState={formState}
      onSubmit={handleSubmit(submit)}
      onCancel={() => setIsEditing(null)}
      submitLabel="Save"
      // classNames={{ buttonContainer: "w-full lg:w-1/3" }}
    >
      <SubHeading>Status</SubHeading>
      <Controller
        control={control}
        name="status"
        render={({ field }) => {
          return <SelectPurchaseRequestStatus value={field.value} onChange={field.onChange} />;
        }}
      />
      <SubHeading>Inventory Item</SubHeading>
      <Controller
        control={control}
        name="inventoryItem"
        render={({ field }) => (
          <SelectInventoryItemModalInput
            classNames={{
              input:
                "w-full flex-1 flex flex-row items-center leading-normal py-2 px-4 rounded bg-white dark:bg-gray-800 text-lg appearance-none border border-gray-300 dark:border-gray-400 focus:border-blue-400 focus:outline-none focus:shadow-none",
            }}
            types={[InventoryItemType.RawMaterial]}
            value={field.value}
            onChange={(e: InventoryItem) => {
              if (e?.defaultVendorProduct) {
                setValue("vendorProduct", {
                  vendor: e?.defaultVendorProduct?.vendor,
                  product: e?.defaultVendorProduct,
                });
              }
              if (e?.valuePerUnit > 0) {
                setValue("pricePerUnit", e?.valuePerUnit);
              }
              field.onChange(e);
            }}
          />
        )}
      />
      <SubHeading>Quantity</SubHeading>
      <Controller
        control={control}
        name="quantity"
        render={({ field }) => (
          <InputWithUnits
            placeholder="0"
            units={values?.inventoryItem?.units ?? "units"}
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
      <SubHeading>Vendor Product</SubHeading>
      <Controller
        name="vendorProduct"
        control={control}
        rules={{
          validate: () => {
            return (
              (notEmpty(values?.vendorProduct?.vendor) && notEmpty(values?.vendorProduct?.product)) ||
              "Must select vendor and product"
            );
          },
        }}
        render={({ field }) => (
          <div className="grid grid-cols-1 gap-x-2 gap-y-2">
            <SelectVendor
              value={values?.vendorProduct?.vendor}
              onChange={(vendor) => {
                field.onChange({
                  vendor,
                  product: null,
                });
              }}
              hasError={notEmpty(formState?.errors?.vendorProduct)}
            />
            <SelectVendorProduct
              isDisabled={!values?.vendorProduct?.vendor?.id}
              value={values?.vendorProduct?.product}
              onChange={(product) => {
                field.onChange({
                  vendor: values?.vendorProduct?.vendor,
                  product,
                });
              }}
              defaultFilters={{
                vendorId: values?.vendorProduct?.vendor?.id,
              }}
              hasError={notEmpty(formState?.errors?.vendorProduct)}
            />
          </div>
        )}
      />
      <SubHeading>Price Per Unit</SubHeading>
      <Controller
        name="pricePerUnit"
        control={control}
        defaultValue={0}
        render={({ field }) => <CurrencyInput value={field.value} onValueChange={field.onChange} placeholder="0" />}
      />
    </ScrollableForm>
  );
};
