import { IconDefinition, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import * as Styles from "styles";

const StyledButtonWithIcon = styled(Styles.Button)`
  display: flex;
  align-items: center;
  span {
    margin-right: 16px;
  }

  &:disabled {
    ${tw`cursor-not-allowed`}
  }
`;

export const ButtonWithIcon: React.FC<
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & { icon: IconDefinition }
> = ({ icon, children, ref, ...rest }) => {
  return (
    <StyledButtonWithIcon {...rest}>
      <span>
        <FontAwesomeIcon icon={icon} />
      </span>
      {children}
    </StyledButtonWithIcon>
  );
};

export const IconButton: React.FC<
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    iconProps: FontAwesomeIconProps;
  }
> = ({ iconProps, children, ref, ...rest }) => {
  return (
    <Styles.IconButton type="button" className="hover:opacity-75" {...rest}>
      <FontAwesomeIcon size="lg" {...iconProps} />
    </Styles.IconButton>
  );
};

const StyledAddButton = styled.button`
  ${tw`text-blue-500 p-3`}
  &:hover {
    ${tw`opacity-90`}
  }
  &:disabled {
  }
  &:active {
    ${tw`opacity-75`}
  }
`;

export const AddButton: React.FC<{ onClick: React.MouseEventHandler<HTMLButtonElement> }> = ({ onClick }) => {
  return (
    <span>
      <StyledAddButton type="button" onClick={onClick}>
        <FontAwesomeIcon icon={faPlusCircle} />
      </StyledAddButton>
    </span>
  );
};

export const PlainBlueButton = styled.button`
  ${tw`text-blue-500`}
`;
