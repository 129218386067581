import React, { useCallback, useEffect, useState } from "react";
import {
  useCompleteWorkOrderStepMutation,
  GetManufacturingOrderDocument,
  WorkflowStep,
  PropertyType,
  useGetManufacturingOrderQuery,
  ConsumeMaterialInput,
  EventPropertyInput,
  GetStreamDocument,
} from "generated/graphql";
import { BlueButton, PlainButton, H4, HR } from "styles";
import { CenterModal } from "components/Modals/CenterModal";
import { useToast } from "hooks/toast";
import { QueueMethods } from "hooks/queue";
import { useUploadFile } from "hooks/upload";
import { useWorkOrderEventGraph } from "./context";
import { ButtonLoading } from "components/Loading";
import { useFieldArray, useForm, Controller } from "react-hook-form";
import NumberInput from "components/Form/NumberInput";
import TextInput from "components/Form/TextInput";
import { AxiosResponse } from "axios";

interface ICompleteStepsInQueue {
  manufacturingOrderId: string;
  queue: QueueMethods<WorkflowStep>;
}

interface IFormData {
  ingredients: ConsumeMaterialInput[];
  properties: {
    stepPropertyId: string;
    value: string | number | FileList;
  }[];
}

const CompleteStepsInQueue: React.FC<ICompleteStepsInQueue> = ({ manufacturingOrderId, queue }) => {
  const [formState, setFormState] = useState({
    currentStep: 0,
    steps: [],
  });

  const { setSelected } = useWorkOrderEventGraph();
  const { data } = useGetManufacturingOrderQuery({
    variables: { id: manufacturingOrderId },
  });
  const toast = useToast();
  const [uploading, setuploading] = useState(false);
  const uploadFile = useUploadFile();
  const form = useForm<IFormData>({
    defaultValues: {
      ingredients: [],
      properties: [],
    },
  });
  const ingredientsFieldArray = useFieldArray({ control: form.control, name: "ingredients" });
  const propertiesFieldArray = useFieldArray({ control: form.control, name: "properties" });

  const [_completeStep, completeStepMutation] = useCompleteWorkOrderStepMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GetManufacturingOrderDocument,
        variables: { id: manufacturingOrderId },
      },
      {
        query: GetStreamDocument,
        variables: {
          id: manufacturingOrderId,
          type: "WORK_ORDER",
        },
      },
    ],
  });
  const workflowStep = queue?.first;
  const hasStepProperties = workflowStep?.step?.properties?.length > 0 ?? false;
  const shouldConsumeInventory = workflowStep?.step?.promptOperatorToConsumeInventory ?? false;
  // const isLastStep = queue?.size === 1;

  const submit = useCallback(
    async (formData: IFormData) => {
      const imageProperties = formData.properties
        .filter((p) => p.value instanceof FileList)
        .map((p) => ({ ...p, value: p.value as FileList }));

      const imagePropertiesSet = new Set(imageProperties.map((p) => p.stepPropertyId));
      setuploading(true);

      let uploadResponses: AxiosResponse<{
        signedRequest: string;
        url: string;
        key: string;
      }>[] = [];
      try {
        uploadResponses = await Promise.all(imageProperties?.map((p) => uploadFile(p?.value?.[0])));
      } catch (err) {
        console.error(err);

        toast.error("Error uploading images");
        return;
      } finally {
        setuploading(false);
      }

      const imageUrls = uploadResponses?.reduce((acc, resp, i) => {
        acc[imageProperties[i]?.stepPropertyId] = resp?.data?.key ?? "";
        return acc;
      }, {} as Record<string, string>);

      const propertyValues = formData.properties.map<EventPropertyInput>((p) => {
        if (imagePropertiesSet.has(p.stepPropertyId)) {
          return {
            stepPropertyId: p.stepPropertyId,
            value: imageUrls[p.stepPropertyId],
          };
        }

        return {
          stepPropertyId: p.stepPropertyId,
          value: p.value as string,
        };
      });

      try {
        await _completeStep({
          variables: {
            input: {
              workOrderId: manufacturingOrderId,
              workflowStepId: workflowStep?.id,
              properties: propertyValues,
              consumeMaterials: formData.ingredients,
            },
          },
        });
        queue.remove();
      } catch (err) {
        toast.error(err?.message || `Unable to complete step ${queue?.first?.step?.name}`);
      }
    },
    [_completeStep, manufacturingOrderId, queue, toast, uploadFile, workflowStep?.id]
  );

  const handleCancel = useCallback(() => {
    queue.purge();
  }, [queue]);

  const next = useCallback(() => {
    setFormState({ ...formState, currentStep: formState.currentStep + 1 });
  }, [formState]);

  const previous = useCallback(() => {
    if (formState.currentStep === 0) {
      handleCancel();
    } else {
      setFormState({ ...formState, currentStep: formState.currentStep - 1 });
    }
  }, [formState, handleCancel]);

  useEffect(() => {
    if (shouldConsumeInventory && hasStepProperties) {
      setFormState({ currentStep: 0, steps: ["consumeInventory", "setStepProperties", "review"] });
    }

    if (shouldConsumeInventory && !hasStepProperties) {
      setFormState({ currentStep: 0, steps: ["consumeInventory", "review"] });
    }

    if (!shouldConsumeInventory && hasStepProperties) {
      setFormState({ currentStep: 0, steps: ["setStepProperties", "review"] });
    }

    if (!shouldConsumeInventory && !hasStepProperties) {
      setFormState({ currentStep: 0, steps: ["review"] });
    }
  }, [shouldConsumeInventory, hasStepProperties]);

  useEffect(() => {
    if (queue.size === 0) {
      setSelected({});
    }
    // eslint-disable-next-line
  }, [queue.size]);

  useEffect(() => {
    form.setValue(
      "ingredients",
      data?.manufacturingOrder?.inventoryItem?.ingredients.map((ing) => ({
        materialId: ing.id,
        quantity: 0,
      })) ?? []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.manufacturingOrder?.inventoryItem?.ingredients]);
  useEffect(() => {
    form.setValue(
      "properties",
      workflowStep?.step?.properties.map((p) => ({
        stepPropertyId: p.id,
        value: "",
      })) ?? []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowStep?.step?.properties]);

  return (
    <CenterModal isOpen={queue?.size > 0} onRequestClose={handleCancel} className="shadow-none">
      {workflowStep && (
        <>
          <H4 className="justify-center py-6">Complete step {workflowStep?.step?.name}</H4>
          <HR />
          <form>
            {formState.steps[formState.currentStep] === "consumeInventory" && (
              <div>
                <div className="text-center">How much did you use?</div>
                <div>
                  {ingredientsFieldArray?.fields?.map((ffield, idx) => {
                    return (
                      <div className="flex items-center my-1" key={ffield?.id}>
                        <div className="w-5/6">{`${data?.manufacturingOrder?.inventoryItem?.ingredients?.[idx]?.units} of ${data?.manufacturingOrder?.inventoryItem?.ingredients?.[idx]?.name}`}</div>
                        <Controller
                          control={form.control}
                          name={`ingredients.${idx}.quantity` as const}
                          render={({ field }) => (
                            <NumberInput
                              placeholder="0"
                              name={field.name}
                              value={field.value}
                              onValueChange={(value) => field.onChange(value.floatValue)}
                            />
                          )}
                        />
                      </div>
                    );
                  })}
                  {ingredientsFieldArray?.fields?.length === 0 && (
                    <div className="text-gray-400 dark:text-gray-600 text-sm my-4 text-center">
                      No Ingredients specified
                    </div>
                  )}
                </div>
                <div className="flex justify-end items-center mt-4">
                  <div className="grid grid-cols-2 gap-x-2">
                    <PlainButton type="button" onClick={previous}>
                      {formState?.currentStep === 0 ? "Cancel" : "Back"}
                    </PlainButton>
                    <BlueButton type="button" onClick={next}>
                      Next
                    </BlueButton>
                  </div>
                </div>
              </div>
            )}

            {formState.steps[formState.currentStep] === "setStepProperties" && (
              <div>
                <div className="grid grid-cols-5 gap-y-2 items-center">
                  {propertiesFieldArray?.fields?.map((ffield, idx) => {
                    const property = workflowStep?.step?.properties?.find((p) => p?.id === ffield?.stepPropertyId);
                    return (
                      <React.Fragment key={ffield?.id}>
                        <div className="col-span-3">{property?.name}</div>
                        <div className="col-span-2">
                          {property?.type === PropertyType.String && (
                            <TextInput
                              placeholder="Add text..."
                              {...form.register(`properties.${idx}.value` as const)}
                            />
                          )}
                          {property?.type === PropertyType.Number && (
                            <Controller
                              control={form.control}
                              name={`properties.${idx}.value` as const}
                              rules={{ required: true }}
                              render={({ field }) => (
                                <NumberInput
                                  placeholder="Add number..."
                                  name={field.name}
                                  value={field.value as number}
                                  onValueChange={(value) => field.onChange(value.value)}
                                />
                              )}
                            />
                          )}
                          {property?.type === PropertyType.Image && (
                            <Controller
                              control={form.control}
                              name={`properties.${idx}.value` as const}
                              rules={{ required: true }}
                              render={({ field }) => (
                                <TextInput
                                  className="w-1/2 truncate"
                                  type="file"
                                  multiple={false}
                                  name={field.name}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    field.onChange(e?.target?.files)
                                  }
                                />
                              )}
                            />
                          )}
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
                <div className="flex justify-end items-center mt-4">
                  <div className="grid grid-cols-2 gap-x-2">
                    <PlainButton type="button" onClick={previous}>
                      {formState?.currentStep === 0 ? "Cancel" : "Back"}
                    </PlainButton>
                    <BlueButton type="button" onClick={next}>
                      Next
                    </BlueButton>
                  </div>
                </div>
              </div>
            )}

            {formState.steps[formState.currentStep] === "review" && (
              <div>
                <div className="flex justify-end items-center mt-4">
                  <div className="grid grid-cols-2 gap-x-2">
                    <PlainButton type="button" onClick={previous}>
                      {formState?.currentStep === 0 ? "Cancel" : "Back"}
                    </PlainButton>
                    <BlueButton type="button" onClick={form.handleSubmit(submit)}>
                      {uploading || completeStepMutation?.loading ? <ButtonLoading /> : "Complete Step"}
                    </BlueButton>
                  </div>
                </div>
              </div>
            )}
          </form>
        </>
      )}
    </CenterModal>
  );
};

export { CompleteStepsInQueue as default };
