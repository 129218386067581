import React from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import { Routes } from "routes";
import { useTheme, Theme } from "hooks/theme";
import DarkModeToggle from "react-dark-mode-toggle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync, faUser } from "@fortawesome/free-solid-svg-icons";
import { H4, Tooltip } from "styles";
import { useMeQuery } from "generated/graphql";
import { useAuth } from "hooks/auth";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useShopifySyncJob } from "hooks/jobs";

const RouteTitle = () => {
  const location = useLocation();
  if (location.pathname === Routes.AnalyticsManufacturing) {
    return <H4 className="mx-2 mt-4 text-gray-500">Birds Eye View</H4>;
  }
  return <div />;
};

const AvatarMenu = () => {
  const history = useHistory();
  const { setLoggingOut } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { data } = useMeQuery();
  const { isDarkMode, theme, settheme } = useTheme();
  const me = data?.me?.__typename === "User" && data?.me;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateTo = (route: Routes) => {
    handleClose();
    history.push(route);
  };

  const handleLogout = () => {
    handleClose();
    setLoggingOut(true);
  };

  return (
    <>
      <button onClick={handleClick} className="rounded-full focus:outline-none flex items-center ">
        <Avatar aria-controls="avatar-menu" aria-haspopup="true" src={me?.avatar}>
          <FontAwesomeIcon icon={faUser} />
        </Avatar>
      </button>

      <Menu id="avatar-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <div className="flex flex-row py-2 px-4">
          <Avatar aria-controls="avatar-menu" aria-haspopup="true" src={me?.avatar} className="mr-2">
            <FontAwesomeIcon icon={faUser} />
          </Avatar>
          <div className="flex flex-col">
            <div className="text-sm font-semibold">{me?.email}</div>
            <div className="text-xs text-gray-400">{me?.organization?.name}</div>
          </div>
        </div>

        <MenuItem onClick={() => navigateTo(Routes.Settings)}>
          <div className="text-sm">My account</div>
        </MenuItem>

        <MenuItem onClick={() => navigateTo(Routes.Support)}>
          <div className="text-sm">Support</div>
        </MenuItem>

        <div className="flex items-center justify-between py-2 px-4">
          <div className="text-sm">Theme</div>
          <DarkModeToggle
            className="focus:outline-none"
            onChange={() => {
              settheme(theme === Theme.Dark ? Theme.Light : Theme.Dark);
            }}
            checked={isDarkMode}
            size={50}
          />
        </div>

        <MenuItem onClick={handleLogout}>
          <div className="text-sm text-brand">Logout</div>
        </MenuItem>
      </Menu>
    </>
  );
};

const Header = () => {
  const { job } = useShopifySyncJob();

  return (
    <div className="flex">
      <div className="flex-1">
        <RouteTitle />
      </div>
      <div className="flex px-4 py-4">
        <div className="flex justify-end px-2 py-2">
          {job?.status === "active" && (
            <Tooltip title={`Shopify: ${job?.msg ?? "syncing"}`} arrow>
              <Link to={Routes.Integrations} className="mx-4 text-base text-default dark:text-white">
                <FontAwesomeIcon icon={faSync} size="lg" spin />
              </Link>
            </Tooltip>
          )}
        </div>
        <AvatarMenu />
      </div>
    </div>
  );
};

export { Header as default };
