import React from "react";
import { Select } from "styles";
import { SalesOrderInvoiceStatus } from "generated/graphql";

const options = [
  SalesOrderInvoiceStatus.Draft,
  SalesOrderInvoiceStatus.Approved,
  SalesOrderInvoiceStatus.Sent,
  SalesOrderInvoiceStatus.Cancelled,
];

export const getSalesOrderInvoiceStatusLabel = (type: SalesOrderInvoiceStatus) => {
  switch (type) {
    case SalesOrderInvoiceStatus.Draft:
      return "Draft";
    case SalesOrderInvoiceStatus.Sent:
      return "Sent";
    case SalesOrderInvoiceStatus.Approved:
      return "Approved";
    case SalesOrderInvoiceStatus.Cancelled:
      return "Cancelled";

    default:
      return type;
  }
};

export const getSalesOrderInvoiceStatusOption = (status: SalesOrderInvoiceStatus) => {
  return { label: getSalesOrderInvoiceStatusLabel(status), value: status };
};

export const SelectSalesOrderInvoiceStatuses: React.FC<{
  value: SalesOrderInvoiceStatus[];
  onChange: (opt: SalesOrderInvoiceStatus[]) => void;
  placeholder?: string;
}> = ({ value, onChange, placeholder = "Filter by Status..." }) => {
  return (
    <Select
      placeholder={placeholder}
      isClearable
      value={value}
      isMulti
      options={options}
      onChange={onChange}
      getOptionValue={(opt: SalesOrderInvoiceStatus) => opt}
      getOptionLabel={(opt: SalesOrderInvoiceStatus) => getSalesOrderInvoiceStatusLabel(opt)}
    />
  );
};

export const SelectSalesOrderInvoiceStatus: React.FC<{
  value: SalesOrderInvoiceStatus;
  onChange: (opt: SalesOrderInvoiceStatus) => void;
  placeholder?: string;
  label?: string;
}> = ({ value, onChange, label, placeholder = "Select Status..." }) => {
  return (
    <div className="w-full">
      {label && <label className="text-xs">{label}</label>}
      <Select
        placeholder={placeholder}
        isClearable
        value={getSalesOrderInvoiceStatusOption(value)}
        options={options.map(getSalesOrderInvoiceStatusOption)}
        onChange={(opt: { label: string; value: SalesOrderInvoiceStatus }) => onChange(opt?.value)}
      />
    </div>
  );
};
