import QRCode from "qrcode";

export const generateQRCodeImage = async (data: string) => {
  const canvas = document.createElement("canvas");
  const qrcode = await QRCode.toDataURL(canvas, data, {
    type: "image/png",
  });

  canvas.remove();

  return qrcode;
};

export const generateQRCodeImages = async (data: string[]) => {
  const canvas = document.createElement("canvas");
  const qrcodes = await Promise.all(
    data.map((item) =>
      QRCode.toDataURL(canvas, item, {
        type: "image/jpeg",
        rendererOpts: { quality: 1 },
      })
    )
  );
  canvas.remove();

  return qrcodes;
};
