import Checkbox from "components/Form/Checkbox";
import React, { useCallback } from "react";

export interface InventoryItemTypeInputValue {
  isMade: boolean;
  isPurchased: boolean;
  isUsedToMakeSomethingElse: boolean;
  isSold: boolean;
}

export const InventoryItemTypeInput: React.FC<{
  value: InventoryItemTypeInputValue;
  onChange: (value: InventoryItemTypeInputValue) => void;
}> = ({ value, onChange }) => {
  const handleChange = useCallback(
    (key: keyof InventoryItemTypeInputValue, checked: boolean) => {
      onChange({
        ...value,
        [key]: checked,
      });
    },
    [onChange, value]
  );

  return (
    <>
      <Checkbox
        data-testid="inventory-item-isMade"
        className="text-sm"
        onChange={(e) => handleChange("isMade", e?.checked)}
        label="I make this"
        checked={value?.isMade ?? false}
      />
      <Checkbox
        data-testid="inventory-item-isPurchased"
        className="text-sm"
        onChange={(e) => handleChange("isPurchased", e?.checked)}
        label="I buy this"
        checked={value?.isPurchased ?? false}
      />
      <Checkbox
        data-testid="inventory-item-isUsedToMakeSomethingElse"
        className="text-sm"
        onChange={(e) => handleChange("isUsedToMakeSomethingElse", e?.checked)}
        label="This is used to make something else"
        checked={value?.isUsedToMakeSomethingElse ?? false}
      />
      <Checkbox
        data-testid="inventory-item-isSold"
        className="text-sm"
        onChange={(e) => handleChange("isSold", e?.checked)}
        label="I sell this"
        checked={value?.isSold ?? false}
      />
    </>
  );
};
