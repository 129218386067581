import { faArrowAltCircleRight, faPlusCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputWithUnits from "components/Form/InputWithUnits";
import { SelectInventoryItemModalInput } from "components/Select/InventoryItem";
import { SelectInventoryTransferStatus } from "components/Select/InventoryTransferStatus";
import { SelectLocation } from "components/Select/Location";
import { InventoryItem, InventoryTransferStatus, Location } from "generated/graphql";
import React, { useState } from "react";
import { Controller, useFieldArray, UseFormReturn } from "react-hook-form";
import styled from "styled-components";
import * as styles from "styles";
import { IconButton, SubHeading } from "styles";
import tw from "twin.macro";
import { notEmpty } from "utils/filters";
import { ImportLineItemsFromCSV } from "components/ImportFromCSV/LineItems";
const StyledTable = styled(styles.Table)`
  ${tw`table-auto w-full`}
  thead {
    tr:last-child {
      ${tw`border-b border-gray-200`}
      th {
        ${tw`py-2 dark:text-white text-center`}

        &:nth-child(2) {
          ${tw`px-4 text-left`}
        }
      }
    }
  }

  tbody {
    tr:nth-child(even) {
      ${tw`bg-gray-200 dark:bg-gray-700`}
    }
  }

  tbody {
    tr {
      td {
        ${tw`px-2`}

        ${tw`py-2 dark:text-white text-center`}
      }
    }
  }

  .po-number {
    max-width: 40px;
  }

  .po-item {
    min-width: 200px;
  }

  .po-quantity {
    min-width: 200px;
  }

  .po-vendorProduct {
    min-width: 200px;
  }

  .po-pricePerUnit {
    min-width: 150px;
  }

  .po-remove-item {
    min-width: 40px;
    max-width: 40px;
  }
`;

export interface LineItem {
  id?: string;
  inventoryItem?: Pick<InventoryItem, "id" | "name" | "units" | "defaultVendorProduct">;
  quantity?: number;
}

export interface IInventoryTransferFormData {
  from: Location;
  to: Location;
  status: InventoryTransferStatus;
  lineItems: LineItem[];
}

export const InventoryTransferForm: React.FC<{
  form: UseFormReturn<IInventoryTransferFormData>;
  disableLocations?: boolean;
  disableStatus?: boolean;
}> = ({ form, disableStatus = false, disableLocations = false }) => {
  const [importLineItems, setImportLineItems] = useState(false);
  const { control, watch, formState, clearErrors } = form;
  const lineItemFields = watch("lineItems");
  const lineItemsFieldArray = useFieldArray({
    control,
    name: "lineItems",
  });

  const addLineItem = () => {
    clearErrors();
    lineItemsFieldArray.append({ inventoryItem: null, quantity: null });
  };

  return (
    <>
      <SubHeading>Locations</SubHeading>
      <div className="flex flex-row items-center">
        <Controller
          control={control}
          name="from"
          rules={{ required: true }}
          render={({ field }) => (
            <SelectLocation
              disabled={disableLocations}
              placeholder="Send inventory from..."
              value={field.value}
              onChange={field.onChange}
              className="flex-1"
            />
          )}
        />

        <div className="mx-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight} size="2x" />
        </div>
        <Controller
          control={control}
          name="to"
          rules={{ required: true }}
          render={({ field }) => (
            <SelectLocation
              disabled={disableLocations}
              value={field.value}
              onChange={field.onChange}
              className="flex-1"
              placeholder="Receive inventory at..."
            />
          )}
        />
      </div>

      <SubHeading>Status</SubHeading>
      <Controller
        control={control}
        name="status"
        render={({ field }) => {
          return (
            <SelectInventoryTransferStatus isDisabled={disableStatus} onChange={field.onChange} value={field.value} />
          );
        }}
      />

      <div className="flex justify-between items-center">
        <SubHeading>
          Inventory
          <span>
            <button type="button" className="text-blue-500 p-3" onClick={addLineItem}>
              <FontAwesomeIcon icon={faPlusCircle} />
            </button>
          </span>
        </SubHeading>
        <div className="text-md">{`${lineItemsFieldArray?.fields?.length ?? 0} items`}</div>
      </div>
      {lineItemsFieldArray?.fields?.length < 1 ? (
        <div className="flex flex-col items-center">
          <button
            type="button"
            className="text-blue-500 dark:text-blue-400 hover:opacity-75 my-4 text-lg"
            onClick={addLineItem}
          >
            Add a line item
          </button>
          <div className="">or</div>

          <button
            type="button"
            className="text-blue-500 dark:text-blue-400 hover:opacity-75 my-4 text-lg"
            onClick={() => setImportLineItems(true)}
          >
            Import from CSV
          </button>
        </div>
      ) : (
        <StyledTable>
          <thead>
            <tr>
              <th>#</th>
              <th>Item</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {lineItemsFieldArray?.fields?.map((formField, index) => {
              const fieldValue = lineItemFields?.[index];
              return (
                <tr key={formField.id}>
                  <td className="po-number">{index + 1}</td>
                  <td className="po-item w-2/3">
                    <Controller
                      control={control}
                      name={`lineItems.${index}.inventoryItem` as const}
                      rules={{ required: true }}
                      defaultValue={formField.inventoryItem}
                      render={({ field }) => (
                        <SelectInventoryItemModalInput value={field.value} onChange={field.onChange} />
                      )}
                    />
                  </td>
                  <td className="po-quantity">
                    <Controller
                      control={control}
                      name={`lineItems.${index}.quantity` as const}
                      rules={{
                        validate: (value) => (notEmpty(value) && value > -1) || "Must be positive",
                      }}
                      defaultValue={formField.quantity}
                      render={({ field }) => (
                        <InputWithUnits
                          hasError={notEmpty(formState?.errors?.lineItems?.[index])}
                          placeholder="0"
                          units={fieldValue?.inventoryItem?.units ?? "units"}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </td>

                  <td className="po-remove-item">
                    <IconButton className="inline" onClick={() => lineItemsFieldArray.remove(index)}>
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </IconButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </StyledTable>
      )}
      <ImportLineItemsFromCSV
        isOpen={importLineItems}
        onRequestClose={() => setImportLineItems(false)}
        onChange={(values) => form.setValue("lineItems", values ?? [])}
      />
    </>
  );
};
