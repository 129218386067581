import { PhoneInput } from "components/Form/PhoneInput";
import { ScrollableForm } from "components/Form/ScrollableForm";
import TextInput from "components/Form/TextInput";
import { ListCustomersDocument, useCreateCustomerMutation } from "generated/graphql";
import { useToast } from "hooks/toast";
import React from "react";
import { Controller, useForm, UseFormReturn } from "react-hook-form";
import { useTable } from "./context";
import { SelectSalesOrderInvoicePaymentTerm } from "components/Select/SalesOrder/SalesOrderInvoicePaymentTerms";

export interface ICreateCustomerForm {
  idempotencyKey?: string;
  email: string;
  company: string;
  firstName: string;
  lastName: string;
  phone: string;
  paymentTerms: string;
  shipVia: string;
}

export const CustomerForm: React.FC<{
  form: UseFormReturn<ICreateCustomerForm>;
}> = ({ form }) => {
  const { control, register } = form;

  return (
    <>
      <TextInput label="Customer #" className="my-2" placeholder="###" {...register("idempotencyKey")} />
      <TextInput className="my-2" label="Email" placeholder="Email" {...register("email")} />
      <TextInput className="my-2" label="Company" placeholder="Company" {...register("company")} />
      <TextInput className="my-2" label="First Name" placeholder="First Name" {...register("firstName")} />
      <TextInput className="my-2" label="Last Name" placeholder="Last Name" {...register("lastName")} />
      <Controller
        control={control}
        name="phone"
        render={({ field }) => <PhoneInput label="Phone Number" value={field.value} onChange={field.onChange} />}
      />

      <TextInput label="Ship Via" className="my-2" placeholder="Ship Via" {...register("shipVia")} />

      <Controller
        control={control}
        name="paymentTerms"
        render={({ field }) => {
          return (
            <SelectSalesOrderInvoicePaymentTerm
              className="my-2"
              label="Payment Terms"
              value={field.value}
              onChange={field.onChange}
            />
          );
        }}
      />
    </>
  );
};

const CreateCustomer: React.FC = () => {
  const { variables, setIsCreating } = useTable();
  const toast = useToast();
  const form = useForm<ICreateCustomerForm>();
  const [createCustomer] = useCreateCustomerMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ListCustomersDocument, variables }],
  });
  const submit = async (data: ICreateCustomerForm) => {
    try {
      await createCustomer({ variables: { input: data } });
      setIsCreating(false);
    } catch (err) {
      toast.error("Unable to create customer");
    }
  };
  return (
    <ScrollableForm
      onSubmit={form.handleSubmit(submit)}
      title="Create Customer"
      submitLabel="Create"
      onCancel={() => setIsCreating(false)}
      formState={form.formState}
    >
      <CustomerForm form={form} />
    </ScrollableForm>
  );
};

export { CreateCustomer as default };
