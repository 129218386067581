import React, { useEffect } from "react";
import Checkbox from "components/Form/Checkbox";
import { ScrollableForm } from "components/Form/ScrollableForm";
import { TagInput } from "components/Form/TagInput";
import TextInput from "components/Form/TextInput";
import { SelectInventoryItem } from "components/Select/InventoryItem";
import { SelectWorkflow } from "components/Select/Workflow";
import Filter from "components/Table/Filter";
import { Customer, InventoryItem, ManufacturingOrderStatus, Workflow, ResourceTag } from "generated/graphql";
import { Controller, useForm } from "react-hook-form";
import { SubHeading } from "styles";
import { removeAtIndex } from "utils/filters";
import { useTable } from "./context";
import { SelectWorkOrderStatuses, getWorkOrderStatusLabel } from "components/Select/WorkOrderStatus";
import { QuickFilters } from "./QuickFilters";
import { MultiTagInputWSuggest } from "components/Form/TagInput";

interface IFiltersFormData {
  ids: string[];
  tags: ResourceTag[];
  inventoryTags: ResourceTag[];
  workflows: Pick<Workflow, "id" | "name">[];
  inventoryItems: Pick<InventoryItem, "id" | "name">[];
  inventoryItemName: string;
  customers: Customer[];
  statuses: ManufacturingOrderStatus[];
  showCancelled: boolean;
}

const defaultValues: IFiltersFormData = {
  ids: [],
  tags: [],
  inventoryTags: [],
  workflows: [],
  inventoryItems: [],
  customers: [],
  statuses: [],
  showCancelled: false,
  inventoryItemName: "",
};

const FiltersHeader = () => {
  const { filters, removeFilter } = useTable();
  return (
    <div>
      <QuickFilters />
      <div className="flex flex-wrap">
        {filters?.ids?.map((id, i) => (
          <Filter
            key={`${id}-${i}`}
            title={`ID: ${id}`}
            onRemove={() => removeFilter("ids", i)}
            className="bg-blue-500"
          />
        ))}

        {filters?.workflows?.map((w, i) => (
          <Filter
            key={w?.id}
            title={`Workflow: ${w?.name}`}
            onRemove={() => removeFilter("workflows", i)}
            className="bg-blue-500"
          />
        ))}

        {filters?.tags?.map((t, i) => {
          return (
            <Filter
              key={t?.tag?.id}
              title={`Tag: ${t?.value ? `${t?.tag?.name}=${t?.value}` : t?.tag?.name}`}
              onRemove={() => removeFilter("tags", i)}
              className="bg-green-500"
            />
          );
        })}

        {filters?.inventoryTags?.length > 0 &&
          filters?.inventoryTags?.map((t, i) => {
            if (!t?.value) {
              return (
                <Filter
                  key={t?.tag?.id}
                  title={`Inventory Tag: ${t?.tag?.name}=(any value)`}
                  onRemove={() => removeFilter("inventoryTags", i)}
                  className="bg-purple-500"
                />
              );
            }

            return (
              <Filter
                key={t?.tag?.id}
                title={`Inventory Tag: ${t?.tag?.name}=${t?.value}`}
                onRemove={() => removeFilter("inventoryTags", i)}
                className="bg-purple-500"
              />
            );
          })}

        {filters?.customers?.map((c, i) => (
          <Filter
            key={c?.id}
            title={`Customer: ${c?.firstName} ${c?.lastName}`}
            onRemove={() => removeFilter("customers", i)}
            className="bg-yellow-500"
          />
        ))}

        {filters?.inventoryItems?.map((p, i) => (
          <Filter
            key={p?.id}
            title={`Inventory Item: ${p?.name}`}
            onRemove={() => removeFilter("inventoryItems", i)}
            className="bg-blue-700"
          />
        ))}

        {filters?.statuses?.map((s, i) => (
          <Filter
            key={s}
            title={`Status: ${getWorkOrderStatusLabel(s)}`}
            onRemove={() => removeFilter("statuses", i)}
            className="bg-gray-400"
          />
        ))}

        {filters?.inventoryItemName && (
          <Filter
            title={`Inventory Item: ${filters?.inventoryItemName}`}
            onRemove={() => removeFilter("inventoryItemName")}
            className="bg-blue-400"
          />
        )}

        {filters?.showCancelled && (
          <Filter title="Show Cancelled" onRemove={() => removeFilter("showCancelled")} className="bg-red-500" />
        )}
      </div>
    </div>
  );
};

const Filters: React.FC = () => {
  const { setShowFilters, setFilters, filters } = useTable();

  const { control, handleSubmit, reset: resetForm, setValue, watch } = useForm<IFiltersFormData>({ defaultValues });
  const tags = watch("tags");
  const inventoryTags = watch("inventoryTags");

  useEffect(() => {
    resetForm({
      ...defaultValues,
      ids: filters?.ids ?? [],
      inventoryItemName: filters?.inventoryItemName ?? "",
      workflows: filters?.workflows,
      inventoryItems: filters?.inventoryItems,
      tags: filters?.tags ?? [],
      inventoryTags: filters?.inventoryTags ?? [],
      customers: filters?.customers,
      statuses: filters?.statuses ?? [],
      showCancelled: filters?.showCancelled,
    });
  }, [filters, resetForm]);

  const onSubmit = (formData: IFiltersFormData) => {
    setFilters({
      ...filters,
      ids: formData?.ids ?? [],
      inventoryItemName: formData?.inventoryItemName !== "" ? formData?.inventoryItemName : null,
      workflows: formData?.workflows ?? [],
      inventoryItems: formData?.inventoryItems ?? [],
      statuses: formData?.statuses ?? [],
      tags: formData?.tags ?? [],
      inventoryTags: formData?.inventoryTags ?? [],
      showCancelled: formData?.showCancelled ?? false,
    });

    setShowFilters(false);
  };

  return (
    <ScrollableForm
      title="Filters"
      onSubmit={handleSubmit(onSubmit)}
      onCancel={() => setShowFilters(false)}
      submitLabel="Apply Filters"
    >
      <SubHeading>Filter by ID</SubHeading>
      <Controller
        name="ids"
        control={control}
        render={({ field }) => <TagInput tags={field.value} onChange={field.onChange} removeOnBackspace={true} />}
      />

      <div className="my-2">
        <SubHeading>Workflows</SubHeading>
        <Controller
          name="workflows"
          control={control}
          render={({ field }) => <SelectWorkflow onChange={field.onChange} value={field.value} isMulti />}
        />
      </div>

      <div className="my-2">
        <SubHeading>Inventory Items</SubHeading>
        <Controller
          name="inventoryItems"
          control={control}
          render={({ field }) => (
            <SelectInventoryItem
              placeholder="Filter by Inventory Item..."
              value={field.value}
              onChange={field.onChange}
              isMulti
            />
          )}
        />
      </div>

      <div className="my-2">
        <SubHeading>Inventory Item Name</SubHeading>
        <Controller
          name="inventoryItemName"
          control={control}
          render={({ field }) => <TextInput onChange={field.onChange} value={field.value} />}
        />
      </div>

      <div className="my-2">
        <SubHeading>Status</SubHeading>
        <Controller
          name="statuses"
          control={control}
          render={({ field }) => <SelectWorkOrderStatuses value={field.value} onChange={field.onChange} />}
        />
      </div>

      <div className="my-2">
        <SubHeading>Work Order Tags</SubHeading>

        <Controller
          name="tags"
          control={control}
          render={({ field }) => {
            return (
              <MultiTagInputWSuggest
                value={field.value}
                onChange={field.onChange}
                allowCreate
                onRemove={(v, i) => setValue("tags", removeAtIndex(tags, i))}
              />
            );
          }}
        />
      </div>

      <div className="my-2">
        <SubHeading>Inventory Tags</SubHeading>
        <Controller
          name="inventoryTags"
          control={control}
          render={({ field }) => {
            return (
              <MultiTagInputWSuggest
                value={field.value}
                onChange={field.onChange}
                allowCreate
                onRemove={(v, i) => setValue("inventoryTags", removeAtIndex(inventoryTags, i))}
              />
            );
          }}
        />
      </div>

      <Controller
        name="showCancelled"
        control={control}
        render={({ field }) => (
          <Checkbox
            onChange={(e) => {
              field.onChange(e.checked);
            }}
            checked={field.value}
            label="Show Cancelled Orders"
          />
        )}
      />
    </ScrollableForm>
  );
};

export { Filters as default, FiltersHeader };
