import React, { useMemo } from "react";
import BaseBadge from "./Base";

const InventoryItemType: React.FC<{
  type: string;
}> = ({ type }) => {
  const props = useMemo(() => {
    switch (type) {
      case "RAW_MATERIAL":
        return {
          text: "Raw Material",
          style: "bg-transparent border border border-gray-600 dark:border-gray-300",
          textStyle: "font-semibold text-gray-600 dark:text-gray-300 text-xs text-center",
        };

      case "INTERMEDIATE_GOOD":
        return {
          text: "Intermediate Good",
          style: "bg-gray-300",
          textStyle: "font-semibold text-gray-800 text-xs text-center",
        };

      case "FINISHED_GOOD":
        return {
          text: "Finished Good",
          style: "bg-green-400",
          textStyle: "font-semibold text-white text-xs text-center",
        };

      default:
        return {
          text: "Draft",
          style: "bg-transparent",
          textStyle: "font-semibold text-gray-600 dark:text-gray-300 text-xs text-center",
        };
    }
  }, [type]);

  return <BaseBadge {...props} />;
};

export { InventoryItemType as default };
