import React from "react";
import { SmallSelect } from "styles";

const getOption = (perPage: number) => {
  return {
    label: `${perPage} per page`,
    value: perPage,
  };
};

export const SelectPagination: React.FC<{ value: number; onChange: (opt: number) => void }> = ({ value, onChange }) => {
  return (
    <SmallSelect
      value={getOption(value)}
      options={[
        { label: "10 per page", value: 10 },
        { label: "100 per page", value: 100 },
      ]}
      onChange={(opt: { label: string; value: number }) => onChange(opt.value)}
    />
  );
};
