import React from "react";
import { Select } from "styles";
import { CustomerOrderPickStatus } from "generated/graphql";

const options = [
  CustomerOrderPickStatus.NotPicked,
  CustomerOrderPickStatus.PartiallyPicked,
  CustomerOrderPickStatus.Picked,
];

export const getCustomerOrderPickStatusLabel = (type: CustomerOrderPickStatus) => {
  switch (type) {
    case CustomerOrderPickStatus.NotPicked:
      return "Not Picked";
    case CustomerOrderPickStatus.PartiallyPicked:
      return "Partially Picked";
    case CustomerOrderPickStatus.Picked:
      return "Picked";

    default:
      return type;
  }
};

export const getCustomerOrderPickStatusOption = (status: CustomerOrderPickStatus) => {
  return { label: getCustomerOrderPickStatusLabel(status), value: status };
};

export const SelectCustomerOrderPickStatuses: React.FC<{
  value: CustomerOrderPickStatus[];
  onChange: (opt: CustomerOrderPickStatus[]) => void;
  placeholder?: string;
  label?: string;
}> = ({ value, onChange, label, placeholder = "Filter by Status..." }) => {
  return (
    <div className="w-full">
      {label && <label className="text-xs">{label}</label>}
      <Select
        placeholder={placeholder}
        isClearable
        value={value}
        isMulti
        options={options}
        onChange={onChange}
        getOptionValue={(opt: CustomerOrderPickStatus) => opt}
        getOptionLabel={(opt: CustomerOrderPickStatus) => getCustomerOrderPickStatusLabel(opt)}
      />
    </div>
  );
};

export const SelectCustomerOrderPickStatus: React.FC<{
  value: CustomerOrderPickStatus;
  onChange: (opt: CustomerOrderPickStatus) => void;
  placeholder?: string;
  label?: string;
}> = ({ value, onChange, label, placeholder = "Select Status..." }) => {
  return (
    <div className="w-full">
      {label && <label className="text-xs">{label}</label>}
      <Select
        placeholder={placeholder}
        isClearable
        value={getCustomerOrderPickStatusOption(value)}
        options={options.map(getCustomerOrderPickStatusOption)}
        onChange={(opt: { label: string; value: CustomerOrderPickStatus }) => onChange(opt?.value)}
      />
    </div>
  );
};
