import TextInput from "components/Form/TextInput";
import { ButtonLoading } from "components/Loading";

import { useResetPasswordMutation } from "generated/graphql";
import { useQueryString } from "hooks/queryString";
import { useToast } from "hooks/toast";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Routes } from "routes";
import { DarkRedButton, Form } from "styles";
import { PreAuthLayout } from "components/Layouts/PreAuth";

const ResetPassword: React.FC = () => {
  const toast = useToast();
  const qs = useQueryString();
  const [submitted, setsubmitted] = useState(false);
  const { formState, register, handleSubmit, watch } = useForm();

  const { errors } = formState;

  const { password, verifyPassword } = watch();
  const formValid = formState.isDirty && errors && password === verifyPassword;
  const [resetPassword, resetPasswordMutation] = useResetPasswordMutation();

  const onSubmit = async (data: { password: string }) => {
    try {
      await resetPassword({
        variables: {
          input: { password: data?.password, token: qs?.get("token") },
        },
      });
      setsubmitted(true);
    } catch (err) {
      toast.error("Unable to reset your password 😭");
    }
  };

  if (submitted) {
    return (
      <PreAuthLayout>
        <div className="my-8">Your password has been reset.</div>
        <Link className="m-auto mt-4 text-blue-600" to={Routes.Login}>
          Click here to login!
        </Link>
      </PreAuthLayout>
    );
  }

  return (
    <PreAuthLayout>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          {...register("password", {
            required: true,
            minLength: 8,
          })}
          placeholder="New Password"
          autoComplete="new-password"
          type="password"
          className="mb-2 input"
        />
        <TextInput
          {...register("verifyPassword", {
            required: true,
            minLength: 8,
          })}
          placeholder="Verify Password"
          autoComplete="new-password"
          type="password"
          className="mb-2 input"
        />
        {errors?.password?.type === "minLength" && <span>Password must be at least 8 characters</span>}

        <DarkRedButton disabled={!formValid}>
          {resetPasswordMutation?.loading ? <ButtonLoading /> : "Submit"}
        </DarkRedButton>

        <Link to={Routes.Login} className="text-center py-2 font-semibold mt-4">
          Go Back
        </Link>
      </Form>
    </PreAuthLayout>
  );
};

export { ResetPassword as default };
