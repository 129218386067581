import React, { useCallback } from "react";
import NumberFormat, { NumberFormatProps } from "react-number-format";
import styled from "styled-components";
import tw from "twin.macro";

const StyledInput: React.FC<NumberFormatProps & { highlightOnFocus?: boolean }> = styled((props: NumberFormatProps) => {
  const onFocus = useCallback(
    (e) => {
      if (props?.highlightOnFocus) e.target.select();
    },
    [props?.highlightOnFocus]
  );
  return <NumberFormat onFocus={onFocus} {...props} allowNegative={false} allowLeadingZeros={false} />;
})`
  ${tw`w-full flex-1 flex flex-row items-center leading-normal py-2 px-4 rounded bg-white dark:bg-gray-800 text-lg appearance-none border border-gray-300 dark:border-gray-400 focus:border-blue-400 focus:outline-none focus:shadow-none `}

  &::placeholder {
    ${tw`text-gray-400 dark:text-gray-600`}
  }

  &:disabled {
    ${tw`opacity-75`}
  }
`;

const StyledText: React.FC<NumberFormatProps> = styled((props: NumberFormatProps) => {
  return <NumberFormat {...props} allowNegative={false} allowLeadingZeros={false} />;
})`
  ${tw`w-full flex-1 flex flex-row items-center leading-normal`}

  &:focus {
    ${tw`shadow-none`}
  }

  &:disabled {
    ${tw`opacity-75`}
  }
`;

const NumberDisplay: React.FC<NumberFormatProps> = ({ label, ...rest }: NumberFormatProps) => {
  if (!label) {
    return <StyledText id={rest.name} {...rest} displayType="text" />;
  }

  return (
    <div>
      <label className="text-xs" htmlFor={rest.name}>
        {label}
      </label>
      <StyledText id={rest.name} displayType="text" {...rest} />
    </div>
  );
};

const NumberInput: React.FC<NumberFormatProps> = ({ label, ...rest }: NumberFormatProps) => {
  if (!label) {
    return <StyledInput id={rest.name} {...rest} />;
  }

  return (
    <div>
      <label className="text-xs" htmlFor={rest.name}>
        {label}
      </label>
      <StyledInput id={rest.name} {...rest} />
    </div>
  );
};

export { NumberInput as default, NumberDisplay };
