import { QueryResult } from "@apollo/client";
import { Exact, GetCustomerOrderQuery, useGetCustomerOrderQuery } from "generated/graphql";
import React, { createContext, useContext } from "react";
import { useParams } from "react-router-dom";
interface CustomerOrderQueryParams {
  id: string;
}

interface CustomerOrderQueryParams {
  id: string;
}

const ctx = createContext<QueryResult<GetCustomerOrderQuery, Exact<{ id: string }>>>(null);

export const CustomerOrderProvider: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
  const { id } = useParams<CustomerOrderQueryParams>();
  const query = useGetCustomerOrderQuery({
    variables: { id },
    fetchPolicy: "network-only",
  });

  return <ctx.Provider value={query}>{children}</ctx.Provider>;
};

export const useCustomerOrder = () => {
  return useContext(ctx);
};
