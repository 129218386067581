import React from "react";
import { Select } from "styles";
import { PurchaseRequestStatus } from "generated/graphql";

export const getPurchaseRequestLabel = (status: PurchaseRequestStatus) => {
  switch (status) {
    case PurchaseRequestStatus.Approved:
      return "Approved";
    case PurchaseRequestStatus.Requested:
      return "Requested";
    case PurchaseRequestStatus.Cancelled:
      return "Cancelled";
    case PurchaseRequestStatus.Submitted:
      return "Submitted";

    default:
      return status;
  }
};

export const SelectPurchaseRequestStatuses: React.FC<{
  placeholder?: string;
  value: PurchaseRequestStatus[];
  onChange: (opt: PurchaseRequestStatus) => void;
}> = ({ value, onChange, placeholder = "Filter by Status..." }) => {
  return (
    <Select
      placeholder={placeholder}
      isClearable
      isMulti
      value={value}
      options={[
        PurchaseRequestStatus.Requested,
        PurchaseRequestStatus.Approved,
        PurchaseRequestStatus.Cancelled,
        PurchaseRequestStatus.Submitted,
      ]}
      onChange={onChange}
      getOptionValue={(opt: PurchaseRequestStatus) => opt}
      getOptionLabel={(opt: PurchaseRequestStatus) => getPurchaseRequestLabel(opt)}
    />
  );
};

export const SelectPurchaseRequestStatus: React.FC<{
  placeholder?: string;
  value: PurchaseRequestStatus;
  onChange: (opt: PurchaseRequestStatus) => void;
}> = ({ value, onChange, placeholder = "Select status..." }) => {
  return (
    <Select
      placeholder={placeholder}
      isClearable
      isMulti={false}
      value={value ? { label: getPurchaseRequestLabel(value), value } : null}
      options={[
        { label: getPurchaseRequestLabel(PurchaseRequestStatus.Requested), value: PurchaseRequestStatus.Requested },
        { label: getPurchaseRequestLabel(PurchaseRequestStatus.Approved), value: PurchaseRequestStatus.Approved },
        { label: getPurchaseRequestLabel(PurchaseRequestStatus.Cancelled), value: PurchaseRequestStatus.Cancelled },
      ]}
      onChange={(opt: { label: string; value: PurchaseRequestStatus }) => onChange(opt?.value)}
    />
  );
};
