import React, { createContext, PropsWithChildren, useContext, useMemo, useCallback, useEffect } from "react";
import {
  Customer,
  ListCustomersQuery,
  Exact,
  Pagination,
  ListCustomersQueryVariables,
  PageInfo,
  ListCustomersFilters,
} from "generated/graphql";
import { useSessionStorage } from "react-use";
import { QueryTuple } from "@apollo/client";
import { useImmerReducer } from "use-immer";
import { removeFilterHelper } from "utils/filters";
import { useHistory } from "react-router";
import { getRouteWithParams, Routes } from "routes";
import { generatePdfLabels } from "utils/labels";

const LABEL_TITLE = "CUSTOMER";

export interface IFilters {
  ids: string[];
  name: string;
  keys: string[];
  company: string;
}

interface StateIFace {
  filters: IFilters;
  pagination: PageInfo;
  showFilters: boolean;
  selected: Set<React.Key>;
  isCreating: boolean;
  isEditing: Customer;
  isDeleting: Customer;
  showQuickFilters: boolean;
}

type SetPagination = {
  type: Actions.SetPagination;
  data: { perPage: number; page: number };
};

type SetSelected = {
  type: Actions.SetSelected;
  data: Set<React.Key>;
};

type SetShowFilters = {
  type: Actions.SetShowFilters;
  data: boolean;
};

type SetFilters = {
  type: Actions.SetFilters;
  data: IFilters;
};

type SetShowQuickFilters = {
  type: Actions.SetShowQuickFilters;
  data: boolean;
};

type SetIsCreating = {
  type: Actions.SetIsCreating;
  data: boolean;
};

type SetIsEditing = {
  type: Actions.SetIsEditing;
  data: Customer;
};

type SetIsDeleting = {
  type: Actions.SetIsDeleting;
  data: Customer;
};

type DispatcherAction =
  | SetShowFilters
  | SetSelected
  | SetPagination
  | SetFilters
  | SetIsCreating
  | SetIsEditing
  | SetIsDeleting
  | SetShowQuickFilters;

enum Actions {
  SetFilters = "SET_FILTERS",
  SetShowFilters = "SET_SHOW_FILTERS",
  SetPagination = "SET_PAGINATION",
  SetSelected = "SET_SELECTED",
  SetIsAdding = "SET_IS",
  SetIsCreating = "SET_IS_CREATING",
  SetIsEditing = "SET_IS_EDITING",
  SetIsDeleting = "SET_IS_DELETING",
  SetShowQuickFilters = "SET_SHOW_QUICK_FILTERS",
}

const initialState: StateIFace = {
  filters: {
    name: null,
    company: null,
    keys: [],
    ids: [],
  },
  showFilters: false,
  selected: new Set<React.Key>(),
  pagination: { perPage: 10, page: 1 },
  isCreating: false,
  isEditing: null,
  isDeleting: null,
  showQuickFilters: true,
};

const ctx = createContext<{
  query: QueryTuple<
    ListCustomersQuery,
    Exact<{
      pagination?: Pagination;
      filters?: ListCustomersFilters;
    }>
  >;
  state: StateIFace;
  dispatch: React.Dispatch<DispatcherAction>;
}>({ query: null, state: initialState, dispatch: () => null });

type ITableProvider<QT, FT> = PropsWithChildren<{
  query: QueryTuple<
    QT,
    Exact<{
      pagination?: Pagination;
      filters?: FT;
    }>
  >;
  sessionKey: string;
}>;

export const TableProvider = <QT, FT>({ children, sessionKey, query }: ITableProvider<QT, FT>) => {
  const [sessionStorage, setFilters] = useSessionStorage<{
    filters: IFilters;
    pagination: PageInfo;
  }>(sessionKey, {
    filters: initialState.filters,
    pagination: initialState.pagination,
  });

  const [state, dispatch] = useImmerReducer(
    (state: StateIFace, action: DispatcherAction) => {
      switch (action.type) {
        case Actions.SetPagination:
          state.pagination = action.data;
          break;

        case Actions.SetSelected:
          state.selected = action.data;
          break;

        case Actions.SetShowFilters:
          state.showFilters = action.data;
          break;

        case Actions.SetShowQuickFilters:
          state.showQuickFilters = action.data;
          break;

        case Actions.SetFilters:
          state.filters = action.data;
          state.pagination = initialState.pagination;
          break;

        case Actions.SetIsCreating:
          state.isCreating = action.data;
          break;

        case Actions.SetIsEditing:
          state.isEditing = action.data;
          break;

        case Actions.SetIsDeleting:
          state.isDeleting = action.data;
          break;
        default:
          throw new Error("problem with Table Provider");
      }
    },
    { ...initialState, ...sessionStorage }
  );

  useEffect(() => {
    setFilters({
      filters: state.filters,
      pagination: state.pagination,
    });
  }, [state, setFilters]);

  return (
    <ctx.Provider
      value={{
        query,
        state,
        dispatch,
      }}
    >
      {children}
    </ctx.Provider>
  );
};

export const useTable = () => {
  const { query, state, dispatch } = useContext(ctx);
  const [fetch, { data, loading }] = query;

  const setPagination = useCallback(
    (pagination: Pagination) => {
      const isChangingPerPage = !!(pagination?.perPage && pagination?.perPage !== state?.pagination?.perPage);
      const page = isChangingPerPage ? 1 : pagination?.page || state?.pagination?.page;
      const perPage = pagination?.perPage || state?.pagination?.perPage || 10;
      dispatch({
        type: Actions.SetPagination,
        data: {
          page,
          perPage,
        },
      });
    },
    [dispatch, state.pagination]
  );

  const setselected = useCallback(
    (rows: Set<React.Key>) => dispatch({ type: Actions.SetSelected, data: rows }),
    [dispatch]
  );

  const setShowFilters = (open: boolean) => {
    dispatch({ type: Actions.SetShowFilters, data: open });
  };

  const setFilters = (filters: IFilters) => {
    dispatch({ type: Actions.SetFilters, data: filters });
  };

  const resetFilters = () => {
    setFilters(initialState.filters);
  };

  const removeFilter = (property: string, i: number = -1) => {
    removeFilterHelper(
      state.filters,
      {
        property,
        i,
      },
      setFilters
    );
  };

  const setIsCreating = (isCreating: boolean) => {
    dispatch({ type: Actions.SetIsCreating, data: isCreating });
  };

  const setIsEditing = (customer: Customer) => {
    dispatch({ type: Actions.SetIsEditing, data: customer });
  };

  const setIsDeleting = (customer: Customer) => {
    dispatch({ type: Actions.SetIsDeleting, data: customer });
  };

  const variables: ListCustomersQueryVariables = useMemo(
    () => ({
      filters: state.filters,
      pagination: state.pagination,
    }),

    [state.pagination, state.filters]
  );

  const setShowQuickFilters = useCallback(
    (show: boolean) => {
      dispatch({ type: Actions.SetShowQuickFilters, data: show });
    },
    [dispatch]
  );

  const selectedRows = useMemo(() => {
    return (
      data?.listCustomers?.customers?.filter((customer) => {
        return state.selected.has(customer.id);
      }) ?? []
    );
  }, [data?.listCustomers?.customers, state.selected]);

  return {
    ...state,
    data,
    loading,
    variables,
    selectedRows,
    setIsCreating,
    setIsEditing,
    setIsDeleting,
    setPagination,
    setselected,
    setShowFilters,
    setFilters,
    removeFilter,
    resetFilters,
    fetch,
    setShowQuickFilters,
  };
};

export type RowActions = "printLabel" | "generateLabels" | "view";

export const useRowActions = () => {
  const { selectedRows } = useTable();
  const history = useHistory();

  const handleAction: (action: RowActions, row: Customer) => void = (action, row: Customer) => {
    switch (action) {
      case "printLabel":
        generatePdfLabels(LABEL_TITLE, [row]);
        break;

      case "generateLabels":
        generatePdfLabels(LABEL_TITLE, selectedRows);
        break;

      case "view":
        history.push(getRouteWithParams(Routes.Customer, { id: row.id }));
        break;

      default:
        break;
    }
  };

  return handleAction;
};
