import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Routes } from "routes";
import { useForm } from "react-hook-form";
import { WhiteLogo } from "components/Logo";
import { useToast } from "hooks/toast";
import { VALID_EMAIL_PATTERN } from "types";

import { useSendResetPasswordLinkMutation } from "generated/graphql";
import { DarkRedButton, Form } from "styles";
import TextInput from "components/Form/TextInput";
import { ButtonLoading } from "components/Loading";
import BackgroundImage from "components/BackgroundImage";

const ForgotPassword: React.FC = () => {
  const toast = useToast();
  const [submitted, setsubmitted] = useState(false);
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm();

  const [sendResetPasswordLink, sendResetPasswordLinkMutation] =
    useSendResetPasswordLinkMutation();
  const onSubmit = async (data: { email: string }) => {
    try {
      await sendResetPasswordLink({
        variables: { input: { email: data?.email } },
      });
      setsubmitted(true);
    } catch (err) {
      toast.error("Unable to send email 😭");
    }
  };

  if (submitted) {
    return (
      <div className="flex flex-col items-center w-full h-screen bg-brand relative">
        <div className="w-full flex flex-col justify-center items-center z-10">
          <div className="mt-32">
            <WhiteLogo width={200} />
          </div>
          <div className="my-8 text-white">
            You'll receive a password reset link in your email shortly!
          </div>
          <Link
            className="m-auto mt-4 text-white font-semibold"
            to={Routes.Login}
          >
            Go Back
          </Link>
        </div>
        <BackgroundImage />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center w-full h-screen bg-brand relative">
      <div className="w-full flex flex-col justify-center items-center z-10">
        <div className="mt-32">
          <WhiteLogo width={200} />
        </div>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col p-4 mt-4 w-full sm:w-4/6 md:w-3/6 lg:w-2/6"
        >
          <TextInput
            {...register("email", {
              required: true,
              pattern: VALID_EMAIL_PATTERN,
            })}
            placeholder="Email"
            className="mb-2"
          />
          {errors.email && errors.email.type === "required" && (
            <span>Email is Required</span>
          )}

          {errors.email && errors.email.type === "pattern" && (
            <span>Invalid email</span>
          )}
          <DarkRedButton>
            {sendResetPasswordLinkMutation?.loading ? (
              <ButtonLoading />
            ) : (
              "Submit"
            )}
          </DarkRedButton>
        </Form>
        <Link className="m-auto mt-4 text-white" to={Routes.Login}>
          Go Back
        </Link>
      </div>
      <BackgroundImage />
    </div>
  );
};

export { ForgotPassword as default };
