import React, { useEffect } from "react";
import { ErrorMessage } from "@hookform/error-message";
import TextInput from "components/Form/TextInput";
import {
  MeDocument,
  // useGetCurrentBillQuery,
  useUpdateMeMutation,
  useMeQuery,
  useResendUserVerificationLinkMutation,
  useUpdateAccountMutation,
} from "generated/graphql";
import { useToast } from "hooks/toast";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { ScrollableForm } from "components/Form/ScrollableForm";
import { UserRoleBadge } from "components/Badges/UserRole";
import { ProfilePicture } from "components/ProfilePicture";
import TitleWithLoading from "components/TitleWithLoading";
import { Tooltip } from "styles";
import { LoadingAnimationWithLabel } from "components/Loading";
import { Subscription } from "./Subscription";

// const CurrentBill = () => {
//   const { data } = useGetCurrentBillQuery();

//   return <pre>{JSON.stringify(data, null, 2)}</pre>;
// };

interface IAccountForm {
  myName: string;
  businessName: string;
  alias: string;
}

const PingIconWithTooltip: React.FC<{ size: number; tooltip: string; onClick?: () => void }> = ({
  size = 24,
  tooltip,
  onClick,
}) => {
  const backgroundSize = Math.floor(size * 0.75);
  return (
    <Tooltip title={tooltip} arrow>
      <div className="flex relative cursor-pointer" style={{ height: size, width: size }} onClick={onClick}>
        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-brand opacity-75"></span>
        <span
          className="absolute bg-white rounded-full"
          style={{ height: backgroundSize, width: backgroundSize, top: 2, left: 2 }}
        ></span>
        <span
          className="relative z-10 inline-flex rounded-full text-brand"
          style={{ height: size, width: size, fontSize: `${size}px` }}
        >
          <FontAwesomeIcon icon={faExclamationCircle} />
        </span>
      </div>
    </Tooltip>
  );
};

const Account = () => {
  const { data, loading } = useMeQuery({ fetchPolicy: "network-only" });
  const toast = useToast();
  const [resendUserVerificationLink] = useResendUserVerificationLinkMutation();

  const [updateMe] = useUpdateMeMutation({ refetchQueries: [{ query: MeDocument }] });
  const [updateAccount] = useUpdateAccountMutation({
    refetchQueries: [{ query: MeDocument }],
  });
  const { handleSubmit, register, formState, reset } = useForm<IAccountForm>({
    defaultValues: {
      businessName: data?.me?.organization?.businessName ?? null,
      alias: data?.me?.organization?.alias ?? null,
    },
  });

  const resendVerificationEmail = async () => {
    try {
      await resendUserVerificationLink();
      toast.success("Email verification sent! 🚀");
    } catch (err) {
      toast.error("Unable to resend verification email");
    }
  };

  const user = data?.me?.__typename === "User" && data?.me;

  const updateAvatar = async (url: string) => {
    try {
      await updateMe({
        variables: {
          input: {
            avatar: url,
          },
        },
      });
    } catch (err) {
      toast.error("Unable to update avatar");
    }
  };

  const submit = async (formData: IAccountForm) => {
    try {
      await updateAccount({
        variables: {
          input: {
            businessName: formData.businessName,
            alias: formData.alias,
          },
        },
      });
      await updateMe({
        variables: { input: { name: formData?.myName ?? "" } },
      });
      toast.success("Updated Account Info");
    } catch (err) {
      toast.error("Unable to update account info");
    }
  };

  useEffect(() => {
    if (user) {
      reset({
        businessName: user?.organization?.businessName,
        alias: user?.organization?.alias,
        myName: user?.name,
      });
    }
  }, [reset, user]);

  if (loading) {
    return (
      <>
        <div className="py-2 px-2 mb-4">
          <TitleWithLoading title="Account" />
        </div>
        <LoadingAnimationWithLabel message="Fetching account details" />;
      </>
    );
  }

  return (
    <>
      <div className="py-2 px-2 mb-4">
        <TitleWithLoading title="Account" />
      </div>

      <ScrollableForm
        formState={formState}
        onSubmit={handleSubmit(submit)}
        onCancel={() => {}}
        submitLabel="Save"
        classNames={{ buttonContainer: "w-full lg:w-1/3" }}
      >
        <div className="grid grid-cols-6">
          <div className="mx-8 flex flex-col items-center col-span-6 lg:col-span-2 ">
            <ProfilePicture url={user?.avatar} onSave={updateAvatar} />
            <div className="mt-2 mb-2 lg:mb-4 flex flex-col items-center">
              <div className="text-lg font-semibold">{user?.name}</div>
              <div className="mb-2 flex items-center">
                <div className="mx-2">{user?.email}</div>
                {user?.verified ? (
                  <Tooltip title="Email address has been verified" arrow>
                    <span>
                      <FontAwesomeIcon icon={faCheck} className="text-green-600 cursor-pointer" />
                    </span>
                  </Tooltip>
                ) : (
                  <PingIconWithTooltip
                    size={20}
                    tooltip="Your email address has not been verified"
                    onClick={resendVerificationEmail}
                  />
                )}
              </div>

              <UserRoleBadge role={user?.role} />
            </div>
            <Subscription />
          </div>

          <div className="col-span-6 lg:col-span-4">
            <TextInput label="My Name" {...register("myName")} placeholder="ie. Carl G. Peters" />
            <TextInput readOnly label="Email" className="font-semibold text-lg" defaultValue={user?.email} />

            <TextInput
              readOnly
              label="Organization Name"
              className="font-semibold text-lg"
              defaultValue={user?.organization?.name}
            />
            <TextInput
              label="Business Name"
              {...register("businessName")}
              placeholder={data?.me?.organization?.name}
              className="mr-2"
            />

            <TextInput
              label="Account Alias"
              {...register("alias", {
                validate: (v) => {
                  if (v === "" || v === null) {
                    return null;
                  }

                  if (!v || v?.length < 3) {
                    return "Must be at least 3 characters";
                  }

                  if (v.includes(" ")) {
                    return "Cannot include spaces";
                  }
                  return null;
                },
              })}
              placeholder="ie. dearborn-1"
              className="mr-2"
              autoCapitalize="off"
              autoComplete="off"
              autoCorrect="off"
            />
            <div className="text-brand text-sm">
              <ErrorMessage name="alias" errors={formState.errors} />
            </div>
          </div>
        </div>

        {/* <CurrentBill /> */}
      </ScrollableForm>
    </>
  );
};

export { Account as default };
