import React from "react";
import {
  LocationInventory,
  UpdateLocationInventoryInput,
  useBulkUpdateLocationInventoryMutation,
} from "generated/graphql";
import { createContext, PropsWithChildren, useCallback, useContext, useMemo } from "react";
import { useImmerReducer } from "use-immer";
import { useToast } from "hooks/toast";

enum Actions {
  EditRow = "EDIT_ROW",
  Reset = "RESET",
  SetEditMode = "SET_EDIT_MODE",
}

type EditRow = {
  type: Actions.EditRow;
  data: LocationInventory;
};

type Reset = {
  type: Actions.Reset;
  data: {
    locationId: string;
  };
};

type SetEditMode = {
  type: Actions.SetEditMode;
  data: boolean;
};

type DispatcherAction = EditRow | Reset | SetEditMode;

interface StateIFace {
  locationId: string;
  editedRows: Record<string, LocationInventory>;
  isEditMode?: boolean;
}

const initialState: StateIFace = {
  locationId: null,
  editedRows: {},
  isEditMode: false,
};

const editTableCtx = createContext<{
  state: StateIFace;
  dispatch: React.Dispatch<DispatcherAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const EditTableProvider: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const [state, dispatch] = useImmerReducer((state: StateIFace, action: DispatcherAction) => {
    switch (action.type) {
      case Actions.EditRow:
        state.editedRows[action.data.id] = action.data;
        break;
      case Actions.Reset:
        state.locationId = action.data.locationId;
        state.editedRows = {};
        state.isEditMode = false;

        break;

      case Actions.SetEditMode:
        state.isEditMode = action.data;
        break;

      default:
        break;
    }
  }, initialState);

  return <editTableCtx.Provider value={{ state, dispatch }}>{children}</editTableCtx.Provider>;
};

export const useEditTable = () => {
  const toast = useToast();

  const { state, dispatch } = useContext(editTableCtx);
  const [update, { loading }] = useBulkUpdateLocationInventoryMutation();
  const queueUpdate = useCallback(
    (row: LocationInventory) => {
      dispatch({ type: Actions.EditRow, data: row });
    },
    [dispatch]
  );

  const reset = useCallback(
    (locationId?: string) => {
      let lid = state.locationId;
      if (locationId) {
        lid = locationId;
      }

      dispatch({ type: Actions.Reset, data: { locationId: lid } });
    },
    [dispatch, state.locationId]
  );

  const setEditMode = useCallback(
    (active: boolean) => {
      dispatch({ type: Actions.SetEditMode, data: active });
    },
    [dispatch]
  );

  const save = useCallback(async () => {
    const input = Object.keys(state?.editedRows ?? {}).map<UpdateLocationInventoryInput>((id) => {
      const item = state?.editedRows[id];

      return {
        id,
        onHand: item?.onHand ?? 0,
        reOrderAt: item?.reOrderAt ?? 0,
        requiresStockTake: item?.requiresStockTake ?? false,
      };
    });

    try {
      await update({
        variables: {
          input,
        },
      });
      reset();
      toast.success(`Updated ${input?.length} items`);
    } catch (err) {
      toast.success(`Unable to update inventory`);
    }
  }, [toast, reset, update, state.editedRows]);

  const hasUnsavedChanges = useMemo(() => Object.keys(state?.editedRows ?? {})?.length > 0, [state]);

  return { ...state, queueUpdate, hasUnsavedChanges, save, reset, setEditMode, isSaving: loading };
};
