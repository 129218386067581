import { ErrorMessage } from "@hookform/error-message";
import { AddButton } from "components/Buttons";
import FileInput from "components/Form/FileInput";
import NumberInput from "components/Form/NumberInput";
import { ScrollableForm } from "components/Form/ScrollableForm";
import { MultiTagInputWSuggest } from "components/Form/TagInput";
import TextInput from "components/Form/TextInput";
import { SelectInventoryItemModalInput } from "components/Select/InventoryItem";
import { SelectWorkflow } from "components/Select/Workflow";
import {
  AttachmentResourceType,
  InventoryItem,
  InventoryItemType,
  ListManufacturingOrdersDocument,
  ResourceTag,
  useCreateManufacturingOrderMutation,
  Workflow,
} from "generated/graphql";
import { useToast } from "hooks/toast";
import { useAttachment } from "hooks/upload";
import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { NumberFormatValues } from "react-number-format";
import { SubHeading } from "styles";
import { notEmpty, removeAtIndex } from "utils/filters";
import { gsEvent } from "components/Events/events";

interface ICreateWorkOrderForm {
  inventoryItem: Pick<InventoryItem, "id" | "name"> & {
    defaultWorkflow: Pick<Workflow, "id" | "name">;
  };
  quantity: number;
  workflow: Pick<Workflow, "id" | "name">;

  tags: ResourceTag[];
  attachments: { value: { name: string; file: FileList } }[];
}

const CreateWorkOrder: React.FC<{
  onRequestClose: () => void;
  queryVariables: { [key: string]: any };
}> = ({ onRequestClose, queryVariables }) => {
  const toast = useToast();
  const [createManufacturingOrder] = useCreateManufacturingOrderMutation({
    refetchQueries: [{ query: ListManufacturingOrdersDocument, variables: queryVariables }],
  });
  const addAttachment = useAttachment();
  const { handleSubmit, control, watch, setValue, formState } = useForm<ICreateWorkOrderForm>();

  const inventoryItem = watch("inventoryItem");
  const tags = watch("tags");

  const attachmentsFieldArray = useFieldArray({
    control,
    name: "attachments",
  });

  const [selectWorkflow, setselectWorkflow] = useState(false);

  const create = async (form: ICreateWorkOrderForm) => {
    try {
      const resp = await createManufacturingOrder({
        variables: {
          input: {
            inventoryItemId: form.inventoryItem?.id,
            quantity: form.quantity,
            workflowId: form?.workflow?.id ?? null,
            tags:
              form?.tags?.map((field) => ({
                tagId: field.tag.id,
                value: field.value,
              })) ?? null,
          },
        },
      });

      for (const attachment of form?.attachments ?? []) {
        await addAttachment(attachment.value.file.item(0), {
          fileName: attachment.value.name,
          resourceId: resp?.data?.createManufacturingOrder?.id,
          resourceType: AttachmentResourceType.ManufacturingOrder,
          onProgress: (e) => console.log(e),
        });
      }
      toast.success(`${form?.quantity} ${form?.inventoryItem?.name} added to orders`);
      gsEvent("Created Work Order");
      onRequestClose();
    } catch (err) {
      console.error(err);
      toast.error(`Unable to create work order`);
    }
  };

  useEffect(() => {
    if (!inventoryItem?.defaultWorkflow) {
      setselectWorkflow(true);
    } else {
      setselectWorkflow(false);
      setValue("workflow", inventoryItem?.defaultWorkflow);
    }
  }, [inventoryItem, setValue]);

  return (
    <ScrollableForm
      title="Create Work Order"
      onSubmit={handleSubmit(create)}
      onCancel={onRequestClose}
      submitLabel="Create"
      formState={formState}
    >
      <SubHeading className="mb-2">What do you need?</SubHeading>

      <div>
        <label className="text-xs" htmlFor={"inventoryItem"}>
          Inventory Item
        </label>
        <Controller
          control={control}
          name="inventoryItem"
          rules={{ required: "Item is required" }}
          render={({ field }) => {
            return (
              <SelectInventoryItemModalInput
                types={[InventoryItemType.IntermediateGood, InventoryItemType.FinishedGood]}
                value={field.value}
                onChange={field.onChange}
                classNames={{
                  input:
                    "w-full flex-1 flex flex-row items-center leading-normal py-2 px-4 rounded bg-white dark:bg-gray-800 text-lg appearance-none border border-gray-300 dark:border-gray-400 focus:border-blue-400 focus:outline-none focus:shadow-none",
                }}
              />
            );
          }}
        />
      </div>
      <div className="text-xs text-brand my-2">
        <ErrorMessage name="inventoryItem" errors={formState.errors} />
      </div>
      <Controller
        control={control}
        name="quantity"
        rules={{
          min: {
            value: 1,
            message: "Quantity must be greater than 0",
          },
        }}
        render={({ field }) => (
          <NumberInput
            label="Quantity"
            className="mt-2"
            type="tel"
            placeholder="ie. 100"
            value={field.value}
            onValueChange={(value: NumberFormatValues) => {
              return field.onChange(value.floatValue);
            }}
          />
        )}
      />
      <div className="text-xs text-brand my-2">
        <ErrorMessage name="quantity" errors={formState.errors} />
      </div>
      <SubHeading className="my-2">Workflow</SubHeading>
      {inventoryItem && !selectWorkflow && (
        <>
          <div className="my-2 text-sm">
            This inventory item uses workflow:
            <span className="font-semibold">{inventoryItem?.defaultWorkflow?.name}</span>
          </div>

          <button type="button" className="my-2 text-blue-600 text-sm" onClick={() => setselectWorkflow(true)}>
            Use custom workflow...
          </button>
        </>
      )}

      {selectWorkflow && (
        <div className="my-2">
          <Controller
            control={control}
            name="workflow"
            rules={{
              required: "Must select a workflow",
            }}
            render={({ field }) => {
              return <SelectWorkflow value={field.value} onChange={field.onChange} />;
            }}
          />

          {inventoryItem?.defaultWorkflow && (
            <button type="button" className="my-2 text-blue-600 text-sm" onClick={() => setselectWorkflow(false)}>
              Cancel
            </button>
          )}

          <div className="text-xs text-brand my-2">
            <ErrorMessage name="workflow" errors={formState.errors} />
          </div>
        </div>
      )}

      <SubHeading>Tags</SubHeading>
      <Controller
        name="tags"
        control={control}
        render={({ field }) => {
          return (
            <MultiTagInputWSuggest
              value={field.value}
              onChange={field.onChange}
              allowCreate
              onRemove={(v, i) => setValue("tags", removeAtIndex(tags, i))}
            />
          );
        }}
      />

      <SubHeading>
        Attachments
        <AddButton
          onClick={() =>
            attachmentsFieldArray.append({
              value: null,
            })
          }
        />
      </SubHeading>

      {attachmentsFieldArray.fields.map((attachmentField, index) => {
        const fieldValue = attachmentField?.value;
        return (
          <div className="flex flex-row py-2" key={attachmentField.id}>
            <Controller
              control={control}
              name={`attachments.${index}.value` as const}
              defaultValue={attachmentField?.value}
              rules={{
                validate: (v) => (notEmpty(v?.file) && notEmpty(v?.name)) || "File name and file required",
              }}
              render={({ field }) => {
                const selectedFile = fieldValue?.file?.item(0);

                return (
                  <div className="w-full">
                    <div className="flex flex-row">
                      <div className="flex-1">
                        <TextInput
                          type="text"
                          placeholder="File name"
                          value={field?.value?.name ?? ""}
                          onChange={(e) => {
                            field.onChange({
                              name: e.target.value,
                              file: fieldValue?.file,
                            });
                          }}
                        />
                        {selectedFile && (
                          <div className="text-xs py-1">{`Selected: ${selectedFile?.name ?? "None"} `}</div>
                        )}
                      </div>
                      <FileInput
                        onChange={(value) => {
                          field.onChange({
                            name: value?.[0]?.name ?? "",
                            file: value,
                          });
                        }}
                        onRemove={() => attachmentsFieldArray.remove(index)}
                      />
                    </div>
                    <div className="text-xs text-brand my-2">
                      <ErrorMessage name={`attachments.${index}.value` as const} errors={formState.errors} />
                    </div>
                  </div>
                );
              }}
            />
          </div>
        );
      })}
    </ScrollableForm>
  );
};

export { CreateWorkOrder as default };
