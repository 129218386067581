import cx from "classnames";
import React from "react";
import * as ReactSpringModal from "react-spring-modal";

interface ICenterModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  className?: string;
  padding?: boolean;
  style?: React.CSSProperties;
}

export const CenterModal: React.FC<React.PropsWithChildren<ICenterModalProps>> = ({
  isOpen,
  onRequestClose,
  className = "",
  style,
  children,
  padding = true,
}) => {
  return (
    <ReactSpringModal.CenterModal
      overlayProps={{
        className: "z-50",
      }}
      contentProps={{
        className: cx(
          `bg-white dark:bg-gray-800 text-default dark:text-white rounded border border-transparent dark:border-gray-700`,
          className,
          padding ? "p-4" : "p-0"
        ),
        style,
      }}
      isOpen={isOpen}
      onDismiss={onRequestClose}
    >
      {children}
    </ReactSpringModal.CenterModal>
  );
};
