import Admins from "components/Views/Admin/Admins";
import Employees from "components/Views/Employees/Employees";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Routes } from "routes";

const Team = () => {
  return (
    <Switch>
      <Route exact path={Routes.Admins} component={Admins} />
      <Route exact path={Routes.Employees} component={Employees} />

      <Redirect to={Routes.Employees} />
    </Switch>
  );
};

export { Team as default };
