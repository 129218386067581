import { faFile, faFileImage, faFilePdf } from "@fortawesome/free-solid-svg-icons";

export const WEBSOCKET_URI = process?.env?.REACT_APP_WEBSOCKET_URI ?? "ws://localhost:4000/graphql";
export const SENTRY_DSN = process?.env?.REACT_APP_SENTRY_DSN ?? "";
export const COMMIT_SHA = process?.env?.REACT_APP_SOURCE_VERSION ?? "dev";
export const APP_NAME = process?.env?.REACT_APP_NAME ?? "dev-ff-app-v1";
export const STRIPE_PUBLISHABLE_KEY =
  process?.env?.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? "pk_test_rU11S2OspfKYqUCFZtHUpUFV";
export const INTERCOM_APP_ID = process?.env?.REACT_APP_INTERCOM_APP_ID ?? "cm0i1h7e";
export const SEGMENT_WRITE_KEY = process?.env?.REACT_APP_SEGMENT_WRITE_KEY ?? "jfQ9YlpC0Fz6ROkX5L3Ik0frEy7q1mjH";

enum MIMETypes {
  Png = "image/png",
  Jpg = "image/jpg",
  Jpeg = "image/jpeg",
  Bmp = "image/bmp",
  Gif = "image/gif",
  Webp = "image/webp",
  Psd = "image/psd",
  Svg = "image/svg+xml",
  Pdf = "application/pdf",
}
const supportedExtensionsPattern = /^.*(png|jpg|jpeg|bmp|gif|webp|psd|svg|pdf)$/;
export const hasExt = (name: string) => {
  return supportedExtensionsPattern.test(name);
};

export const getExt = (name: string) => {
  const match = name.match(supportedExtensionsPattern);
  if (match) {
    return match?.[1] ?? "";
  }
  return "";
};

export const toExt = (type: string | MIMETypes) => {
  switch (type) {
    case MIMETypes.Png:
      return ".png";
    case MIMETypes.Jpg:
      return ".jpg";
    case MIMETypes.Jpeg:
      return ".jpeg";
    case MIMETypes.Bmp:
      return ".bmp";
    case MIMETypes.Gif:
      return ".gif";
    case MIMETypes.Webp:
      return ".webp";
    case MIMETypes.Psd:
      return ".psd";
    case MIMETypes.Svg:
      return ".svg";
    case MIMETypes.Pdf:
      return ".pdf";
    default:
      return "";
  }
};

export const toIcon = (type: string | MIMETypes) => {
  switch (type) {
    case MIMETypes.Png:
      return faFileImage;
    case MIMETypes.Jpg:
      return faFileImage;
    case MIMETypes.Jpeg:
      return faFileImage;
    case MIMETypes.Bmp:
      return faFileImage;
    case MIMETypes.Gif:
      return faFileImage;
    case MIMETypes.Webp:
      return faFileImage;
    case MIMETypes.Psd:
      return faFileImage;
    case MIMETypes.Svg:
      return faFileImage;
    case MIMETypes.Pdf:
      return faFilePdf;
    default:
      return faFile;
  }
};

export const SupportedImageMimeTypes = [
  MIMETypes.Png,
  MIMETypes.Jpg,
  MIMETypes.Jpeg,
  MIMETypes.Bmp,
  MIMETypes.Gif,
  MIMETypes.Webp,
];

export const SupportedMimeTypes = [
  MIMETypes.Png,
  MIMETypes.Jpg,
  MIMETypes.Jpeg,
  MIMETypes.Bmp,
  MIMETypes.Gif,
  MIMETypes.Webp,
  MIMETypes.Psd,
  MIMETypes.Svg,
  MIMETypes.Pdf,
];

export enum SessionStorageKeys {
  InventoryTableColumns = "@factoryfinch/inventory/columns",
}

export enum LocalStorageKeys {
  WorkOrderFilters = "@factoryfinch/WorkOrders/filters",
  WorkOrderBookmark = "@factoryfinch/WorkOrders/bookmark",
  Inventory = "@factoryfinch/Inventory",
  Token = "@factoryfinch/Token",
  WorkflowEditorPosition = "@factoryfinch/WorkflowEditorPosition",
  WorkflowEditor = "@factoryfinch/WorkflowEditor",
  EventGraph = "@factoryfinch/EventGraph",
  Theme = "@factoryfinch/Theme",
  ShopifySyncJobId = "@factoryfinch/shopifySyncJobId",
  ShowGettingStarted = "@factoryfinch/showGettingStarted",
  CollapseSubNav = "@factoryfinch/collapseSubNav",

  InventoryTableColumns = "@factoryfinch/inventory/columns",
  WorkOrderTableColumns = "@factoryfinch/workOrder/columns",
}
