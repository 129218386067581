import React from "react";
import { ButtonLoading } from "components/Loading";
import { TagResourceType, useBulkAddTagsMutation, ResourceTag } from "generated/graphql";
import { useToast } from "hooks/toast";
import { Controller, useForm } from "react-hook-form";
import { H4, HR, PlainButton, RedButton } from "styles";
import { MultiTagInputWSuggest } from "components/Form/TagInput";
import { removeAtIndex } from "utils/filters";

const humanizeTagResourceType = (type: TagResourceType) => {
  switch (type) {
    case TagResourceType.ManufacturingOrder:
      return "Manufacturing Order";
    case TagResourceType.InventoryItem:
      return "InventoryItem";
    case TagResourceType.Workflow:
      return "Workflow";
  }
};

interface IBulkAddTagsForm {
  tags: ResourceTag[];
}

const BulkAddTags: React.FC<{
  resourceIds: string[];
  type: TagResourceType;
  onRequestClose: (e?: any) => void;
}> = ({ resourceIds, type, onRequestClose }) => {
  const toast = useToast();
  const [addTags] = useBulkAddTagsMutation();

  const { control, handleSubmit, formState, watch, setValue } = useForm<IBulkAddTagsForm>({
    defaultValues: {
      tags: [],
    },
  });

  const tags = watch("tags");

  const submit = async (data: IBulkAddTagsForm) => {
    const input = resourceIds?.flatMap((resourceId) => {
      return data.tags.map((option) => {
        return {
          resourceId,
          resourceType: type,
          tagId: option?.tag?.id,
          value: option?.value,
        };
      });
    });

    try {
      await addTags({ variables: { input } });
      onRequestClose();
    } catch (err) {
      toast.error("Unable to add tags to orders");
    }
  };

  return (
    <>
      <H4>{`Add Tags to ${resourceIds?.length} ${humanizeTagResourceType(type)}${
        resourceIds?.length > 1 ? "s" : ""
      }`}</H4>
      <HR />
      <form onSubmit={handleSubmit(submit)} className="flex flex-col">
        <Controller
          name="tags"
          control={control}
          render={({ field }) => {
            return (
              <MultiTagInputWSuggest
                value={field.value}
                onChange={field.onChange}
                allowCreate
                onRemove={(v, i) => setValue("tags", removeAtIndex(tags, i))}
              />
            );
          }}
        />

        <div className="flex justify-end">
          <PlainButton type="button" className="mr-2" onClick={onRequestClose}>
            Cancel
          </PlainButton>
          <RedButton>{formState?.isSubmitting ? <ButtonLoading /> : "Submit"}</RedButton>
        </div>
      </form>
    </>
  );
};

export { BulkAddTags as default };
