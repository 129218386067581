import { INodeDefaultProps } from "@mrblenny/react-flow-chart";
import styled from "styled-components";
import tw from "twin.macro";

const Node = styled.div<INodeDefaultProps>`
  ${tw`absolute bg-white rounded`}
  width: 200px;
  max-width: 200px;
  background: white;
  max-height: 200px;
`;

export { Node as default };
