import { FullScreenLoading } from "components/Loading";
import { useAuth } from "hooks/auth";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import { Routes } from "routes";

const PrivateRoute = ({ component, ...options }) => {
  const { isLoggedIn, loading, isCheckingAuth, isVerifyingToken } = useAuth();

  if (loading || isCheckingAuth || isVerifyingToken) {
    return <FullScreenLoading message="Authenticating" />;
  }

  if (!loading && !isLoggedIn) {
    return <Redirect to={Routes.Login} />;
  }

  return <Route {...options} path={`/p/${options.path}`} component={component} />;
};

export { PrivateRoute as default };
