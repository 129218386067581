import React from "react";
import { useTable } from "./context";
import { RedButton, PlainButton } from "styles";
import { useForm, Controller } from "react-hook-form";
import { ButtonLoading } from "components/Loading";
import { ChromePicker } from "react-color";

const SetGroupColor = () => {
  const { selectedRows, addGroupColor, setaddGroupColorToOrders } = useTable();

  const { handleSubmit, control, formState } = useForm<{
    color: any;
  }>();

  const submit = async (data: { color: any }) => {
    await addGroupColor({
      variables: {
        input: {
          manufacturingOrderIds: selectedRows.map((row) => row.id),
          color: data?.color?.hex,
        },
      },
    });

    setaddGroupColorToOrders(false);
  };

  return (
    <div>
      <div className="text-center mt-2 mb-4 font-semibold">Add Group Indicator to {selectedRows?.length} orders</div>

      <form onSubmit={handleSubmit(submit)} className="flex flex-col">
        <div className="flex justify-center">
          <Controller
            control={control}
            name="color"
            render={({ field }) => <ChromePicker onChange={field.onChange} color={field.value} disableAlpha />}
          />
        </div>

        <div className="flex justify-end mt-4">
          <PlainButton type="button" className="mr-2" onClick={() => setaddGroupColorToOrders(false)}>
            Cancel
          </PlainButton>
          <RedButton>{formState?.isSubmitting ? <ButtonLoading /> : "Submit"}</RedButton>
        </div>
      </form>
    </div>
  );
};

export { SetGroupColor as default };
