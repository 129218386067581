import React, { createContext, useContext } from "react";
import { useLocalStorage } from "react-use";
import { LocalStorageKeys } from "config";

const ctx =
  createContext<{
    showGettingStarted: boolean;
    setshowGettingStarted: React.Dispatch<React.SetStateAction<boolean>>;
  }>(null);

export const AppProvider: React.FC<React.PropsWithChildren<any>> = ({
  children,
}) => {
  const [showGettingStarted, setshowGettingStarted] = useLocalStorage(
    LocalStorageKeys.ShowGettingStarted,
    false
  );

  return (
    <ctx.Provider value={{ showGettingStarted, setshowGettingStarted }}>
      {children}
    </ctx.Provider>
  );
};

export const useApp = () => {
  return useContext(ctx);
};
