import React from "react";
import { Select } from "styles";
import cx from "classnames";

export const SalesOrderInvoicePaymentTerms = {
  PIA: "PIA",
  NET7: "NET7",
  NET10: "NET10",
  NET15: "NET15",
  NET30: "NET30",
  NET60: "NET60",
  NET90: "NET90",
  EOM: "EOM",
  "21MFI": "21MFI",
  COD: "COD",
  CND: "CND",
  CBS: "CBS",
  CIA: "CIA",
  CWO: "CWO",
  "1MD": "1MD",
  "2MD": "2MD",
  STAGE_PAYMENTS: "STAGE_PAYMENTS",
  FORWARD_DATING: "FORWARD_DATING",
  ACCUMULATION_DISCOUNTS: "ACCUMULATION_DISCOUNTS",
  PARTIAL_PAYMENT_DISCOUNT: "PARTIAL_PAYMENT_DISCOUNT",
  REBATE: "REBATE",
  CONTRA: "CONTRA",
};

const options = Object.keys(SalesOrderInvoicePaymentTerms);

export const getSalesOrderInvoicePaymentTermsLabel = (type: string) => {
  switch (type) {
    case "PIA":
      return "PIA";
    case "NET7":
      return "NET7";
    case "NET10":
      return "NET10";
    case "NET15":
      return "NET15";
    case "NET30":
      return "NET30";
    case "NET60":
      return "NET60";
    case "NET90":
      return "NET90";
    case "EOM":
      return "EOM";
    case "21MFI":
      return "21 MFI";
    case "COD":
      return "COD";
    case "CND":
      return "CND";
    case "CBS":
      return "CBS";
    case "CIA":
      return "CIA";
    case "CWO":
      return "CWO";
    case "1MD":
      return "1MD";
    case "2MD":
      return "2MD";
    case "STAGE_PAYMENTS":
      return "Stage payments";
    case "FORWARD_DATING":
      return "Forward dating";
    case "ACCUMULATION_DISCOUNTS":
      return "Accumulation discounts";
    case "PARTIAL_PAYMENT_DISCOUNT":
      return "Partial payment discount";
    case "REBATE":
      return "Rebate";
    case "CONTRA":
      return "Contra";
  }
};

type PaymentTerm = string;

export const getSalesOrderInvoicePaymentTermsOption = (type: PaymentTerm) => {
  return { label: getSalesOrderInvoicePaymentTermsLabel(type), value: type };
};

export const SelectSalesOrderInvoicePaymentTerms: React.FC<{
  value: PaymentTerm[];
  onChange: (opt: PaymentTerm[]) => void;
  placeholder?: string;
}> = ({ value, onChange, placeholder = "Filter by Type..." }) => {
  return (
    <Select
      placeholder={placeholder}
      isClearable
      value={value}
      isMulti
      options={options}
      onChange={onChange}
      getOptionValue={(opt: PaymentTerm) => opt}
      getOptionLabel={(opt: PaymentTerm) => getSalesOrderInvoicePaymentTermsLabel(opt)}
    />
  );
};

export const SelectSalesOrderInvoicePaymentTerm: React.FC<{
  value: PaymentTerm;
  onChange: (opt: PaymentTerm) => void;
  placeholder?: string;
  label?: string;
  className?: string;
}> = ({ value, onChange, label, className = "", placeholder = "Select Type..." }) => {
  return (
    <div className={cx("w-full", className)}>
      {label && <label className="text-xs">{label}</label>}
      <Select
        placeholder={placeholder}
        isClearable
        value={getSalesOrderInvoicePaymentTermsOption(value)}
        options={options.map(getSalesOrderInvoicePaymentTermsOption)}
        onChange={(opt: { label: string; value: PaymentTerm }) => {
          onChange(opt?.value);
        }}
      />
    </div>
  );
};
