import React from "react";
import { ButtonLoading } from "components/Loading";
import { HR, H4, RedButton, PlainButton } from "styles";
import cx from "classnames";
import { FormState } from "react-hook-form";

const defaultClassNames = {
  buttonContainer: "w-full",
};

interface IForm {
  title?: string;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  onCancel: () => void;
  cancelLabel?: string;
  submitLabel?: string;
  classNames?: {
    buttonContainer?: string;
  };
  formState?: FormState<any>;
  ["data-testid"]?: string;
  enableSubmitOnDirty?: boolean;
}

export const Form: React.FC<React.PropsWithChildren<IForm>> = ({
  title,
  onSubmit,
  onCancel,
  cancelLabel,
  submitLabel,
  formState,
  classNames = defaultClassNames,
  enableSubmitOnDirty = true,
  children,
  ...rest
}) => {
  return (
    <div className="bg-white dark:bg-gray-800 flex flex-col h-full">
      {title && (
        <>
          <H4 className="px-2 py-2">{title}</H4>
          <HR />
        </>
      )}
      <form onSubmit={onSubmit} className="flex-1 flex flex-col">
        {children}

        <hr />
        <div className="flex flex-row justify-end">
          <div className={cx("px-2 py-4 text-sm grid grid-cols-2 gap-x-2", classNames.buttonContainer)}>
            <PlainButton type="button" onClick={onCancel}>
              {cancelLabel ?? "Cancel"}
            </PlainButton>
            <RedButton
              disabled={formState?.isSubmitting || (enableSubmitOnDirty && formState && !formState?.isDirty)}
              data-testid={rest["data-testid"] + "-submit-btn"}
            >
              {formState?.isSubmitting ? <ButtonLoading /> : submitLabel ?? "Submit"}
            </RedButton>
          </div>
        </div>
      </form>
    </div>
  );
};

export const ScrollableForm: React.FC<React.PropsWithChildren<IForm>> = ({ children, ...props }) => {
  return (
    <Form {...props}>
      <div className="flex-1 overflow-auto mb-4">
        <div className="h-0 px-2">{children}</div>
      </div>
    </Form>
  );
};
