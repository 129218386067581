import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import styled from "styled-components";
import { IconButton } from "styles";

const Item = styled(MenuItem)`
  font-family: var(--font-family-default) !important;
`;

type MenuOption = {
  disabled?: boolean;
  label: string | React.ReactNode;
  onClick?: (event?: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
};

interface IconButtonMenuProps {
  options: MenuOption[];
}

const IconButtonMenu: React.FC<IconButtonMenuProps> = ({ options }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (onClick: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void) => {
    if (!onClick) {
      return () => {
        setAnchorEl(null);
      };
    }

    return (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      event.stopPropagation();
      setAnchorEl(null);
      onClick(event);
    };
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        type="button"
        className="inline"
        aria-controls="icon-button-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <FontAwesomeIcon icon={faEllipsisV} />
      </IconButton>
      <Menu id="icon-button-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={onClose}>
        {options
          ?.filter((opt) => opt)
          .map((option, i) => {
            return (
              <Item key={`opt-${i}`} onClick={handleClose(option?.onClick)} disabled={option?.disabled}>
                {option?.label}
              </Item>
            );
          })}
      </Menu>
    </>
  );
};

export { IconButtonMenu as default };
