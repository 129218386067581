import React from "react";
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "styles";

const InfoIcon: React.FC<{ text: string; className?: string }> = ({
  text,
  className,
}) => {
  return (
    <Tooltip title={text} arrow>
      <span
        // style={{ height: "40px", width: "40px" }}
        className={cx("flex justify-center items-center", className)}
      >
        <FontAwesomeIcon icon={faQuestionCircle} />
      </span>
    </Tooltip>
  );
};

export { InfoIcon as default };
