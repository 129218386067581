import React, { useState } from "react";
import { useListCustomersQuery, Customer, useCreateCustomerMutation } from "generated/graphql";
import { AsyncSelect, AsyncCreatableSelect } from "styles";
import { useDelayedQuery } from "hooks/delayedQuery";
import { useToast } from "hooks/toast";
import { CenterModal } from "components/Modals/CenterModal";
import { PhoneNumber } from "components/Form/PhoneInput";

export const SelectCustomer: React.FC<{
  name?: string;
  className?: string;
  value?: Customer[] | Customer | string;
  onChange: (v: Customer | Customer[]) => void;
  size?: "base" | "large";
  placeholder?: string;
  isMulti?: boolean;
  allowCreate?: boolean;
}> = ({
  value,
  onChange,
  className = "",
  name = "",
  size = "base",
  placeholder = "Select Customer...",
  isMulti = false,
  allowCreate = false,
}) => {
  const toast = useToast();
  const { data, refetch, loading } = useListCustomersQuery({
    variables: { pagination: { page: 0, perPage: 10 } },
  });
  const { handleInputChange, delayedQuery } = useDelayedQuery({
    refetch: async (inputValue: string) => {
      const resp = await refetch({ filters: { name: inputValue } });
      return resp?.data?.listCustomers?.customers ?? [];
    },
  });
  const [createCustomer] = useCreateCustomerMutation({});

  const handleCreate = async (inputValue: string) => {
    const inputs = inputValue.trim().split(" ");
    const step = await createCustomer({
      variables: {
        input: { firstName: inputs?.[0] ?? "", lastName: inputs?.[1] ?? "" },
      },
    });
    const newCustomer = step.data?.createCustomer;
    onChange(newCustomer);

    toast.success(`Created customer ${inputValue}`);
  };

  if (!allowCreate) {
    return (
      <AsyncSelect
        isClearable
        isMulti={isMulti}
        isLoading={loading}
        $textSize={size}
        placeholder={placeholder}
        name={name}
        className={className}
        cacheOptions
        value={value}
        loadOptions={delayedQuery}
        defaultOptions={data?.listCustomers?.customers ?? []}
        onInputChange={handleInputChange}
        onChange={onChange}
        getOptionLabel={(opt: Customer) => {
          return (
            <div className="flex items-center justify-between">
              <div className="dark:text-gray-300 mr-2">{`${opt?.firstName}  ${opt?.lastName}`}</div>
              <div className="text-xs text-gray-400 dark:text-gray-300">{opt?.email}</div>
            </div>
          );
        }}
        getOptionValue={(opt: Customer) => opt?.id}
      />
    );
  }

  return (
    <AsyncCreatableSelect
      isClearable
      isMulti={isMulti}
      isLoading={loading}
      $textSize={size}
      placeholder={placeholder}
      name={name}
      className={className}
      cacheOptions
      value={value}
      loadOptions={delayedQuery}
      defaultOptions={data?.listCustomers?.customers ?? []}
      onInputChange={handleInputChange}
      onChange={onChange}
      getOptionLabel={(opt: any) => {
        if (opt?.__isNew__) {
          return opt?.label;
        }

        return (
          <div className="flex justify-between">
            <div>{`${opt?.firstName}  ${opt?.lastName}`}</div>
            <div>{opt?.email}</div>
          </div>
        );
      }}
      getOptionValue={(p: Customer) => p?.id}
      onCreateOption={handleCreate}
    />
  );
};

export const SelectCustomerModalInput: React.FC<{ value: Customer; onChange: (v: Customer) => void }> = ({
  value,
  onChange,
}) => {
  const [open, setopen] = useState(false);

  return (
    <>
      {value ? (
        <div className="cursor-pointer opacity-95 hover:opacity-100" onClick={() => setopen(true)}>
          <div className="font-semibold">{`${value?.firstName} ${value?.lastName}`}</div>
          <div>{value?.email ?? ""}</div>
          <PhoneNumber value={value?.phone} />
        </div>
      ) : (
        <button type="button" onClick={() => setopen(true)} className="cursor-pointer opacity-75 hover:opacity-100">
          Select a customer...
        </button>
      )}

      <CenterModal isOpen={open} onRequestClose={() => setopen(false)}>
        <SelectCustomer
          value={value}
          onChange={(v: Customer) => {
            onChange(v);
            setopen(false);
          }}
        />
      </CenterModal>
    </>
  );
};
