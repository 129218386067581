import NumberInput from "components/Form/NumberInput";
import Dinero from "dinero.js";
import React, { useMemo, useState, FC } from "react";
import { Link } from "react-router-dom";
import { DarkRedButton } from "styles";

import { WhiteLogo } from "components/Logo";
import Div100vh from "react-div-100vh";
import { Routes } from "routes";

type PriceKey = "sku" | "workOrder" | "salesOrder" | "event";

const PRICES: Record<PriceKey, Dinero.Dinero> = {
  sku: Dinero({ amount: 3 }),
  workOrder: Dinero({ amount: 3 }),
  salesOrder: Dinero({ amount: 7 }),
  event: Dinero({ amount: 7 }),
};

const PricePerUnit: FC<{ name: PriceKey; count?: number; getLabel?: (price: Dinero.Dinero) => string }> = ({
  name,
  count = 0,
  getLabel,
}) => {
  const price = PRICES[name];
  return (
    <span className="text-sm font-regular">
      {getLabel
        ? getLabel(price)
        : `${price.toFormat("$0,0.00")} x ${count} ${name} = ${price.multiply(count).toFormat("$0,0.00")}`}
    </span>
  );
};

const labelClassName = "sm:text-sm md:text-xl";
const inputClassName = "md:col-span-1 col-span-2";
export const PricingCalculator = () => {
  const [skus, setSKUs] = useState(0);
  const [workOrders, setWorkOrders] = useState(0);
  const [events, setEvents] = useState(0);
  const [salesOrders, setSalesOrders] = useState(0);

  const totalPrice = useMemo(() => {
    const basePrice = Dinero({ amount: 8900 });

    const skusTotal = PRICES["sku"].multiply(skus);
    const workOrdersTotal = PRICES["workOrder"].multiply(workOrders);
    const salesOrdersTotal = PRICES["salesOrder"].multiply(salesOrders);
    const eventsTotal = PRICES["event"].multiply(workOrders).multiply(events);
    return basePrice.add(skusTotal).add(workOrdersTotal).add(salesOrdersTotal).add(eventsTotal);
  }, [skus, workOrders, salesOrders, events]);

  return (
    <Div100vh className="bg-brand overflow-auto">
      <div className="flex flex-col justify-center items-center">
        <WhiteLogo width={200} />
        <span className="text-3xl">Pricing calculator</span>
      </div>
      <div className="flex justify-center my-8">
        <div className="text-5xl font-bold">
          {totalPrice.toFormat("$0,0.00")}
          <span className="text-3xl">/month</span>
        </div>
      </div>
      <div className="flex flex-col items-center my-8">
        <div className="grid grid-cols-6 gap-y-4 gap-x-4 xl:mx-8 md:w-2/3 lg:w-1/2 mx-3">
          <div className="flex flex-col md:col-span-5 col-span-4">
            <div className={labelClassName}>How many SKUs do you manage?</div>
            <PricePerUnit name="sku" count={skus} />
          </div>
          <div className={inputClassName}>
            <NumberInput highlightOnFocus value={skus} onValueChange={(value) => setSKUs(value?.floatValue ?? 0)} />
          </div>

          <div className="flex flex-col md:col-span-5 col-span-4">
            <div className={labelClassName}>How many work orders do you make per month?</div>
            <PricePerUnit name="workOrder" count={workOrders} />
          </div>
          <div className={inputClassName}>
            <NumberInput
              highlightOnFocus
              value={workOrders}
              onValueChange={(value) => setWorkOrders(value?.floatValue ?? 0)}
            />
          </div>
          <div className="flex flex-col md:col-span-5 col-span-4">
            <div className={labelClassName}>
              For each work order how many events do you want to track? (ex. Start, Finish, Complete this critical
              operation)
            </div>
            <PricePerUnit
              name="event"
              count={events}
              getLabel={(p) => {
                return `${p.toFormat("$0,0.00")} x ${events} events x ${workOrders} work orders = ${p
                  .multiply(events)
                  .multiply(workOrders)
                  .toFormat("$0,0.00")}`;
              }}
            />
          </div>
          <div className={inputClassName}>
            <NumberInput highlightOnFocus value={events} onValueChange={(value) => setEvents(value?.floatValue ?? 0)} />
          </div>

          <div className="flex flex-col md:col-span-5 col-span-4">
            <div className={labelClassName}>How many sales orders do you process each month?</div>
            <PricePerUnit name="salesOrder" count={salesOrders} />
          </div>
          <div className={inputClassName}>
            <NumberInput
              highlightOnFocus
              value={salesOrders}
              onValueChange={(value) => setSalesOrders(value?.floatValue ?? 0)}
            />
          </div>
          <div className="flex flex-col md:col-span-5 col-span-4">
            <div className={labelClassName}>All operators are free!</div>
          </div>

          <div className="flex flex-col md:col-span-5 col-span-4">
            <div className={labelClassName}>All additional admins are free!</div>
          </div>
        </div>
        <div className="flex flex-col my-8">
          <Link to={Routes.SignUp}>
            <DarkRedButton type="button" className="w-full">
              Sign Up Now!
            </DarkRedButton>
          </Link>
          <Link className="m-auto mt-4 text-white" to={Routes.Login}>
            Already have an account? Login Here
          </Link>
        </div>
      </div>
    </Div100vh>
  );
};
