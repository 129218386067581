import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import IconButtonMenu from "components/IconButtonMenu";
import Pagination from "components/Pagination";
import StyledTable from "components/Table";
import { EmptyRowsRenderer, LoadingRenderer, RowRenderer } from "components/Table/Renderers";
import { PurchaseRequest, PurchaseRequestStatus } from "generated/graphql";
import React, { useEffect } from "react";
import { Item, ItemParams, Menu } from "react-contexify";
import { Column, DataGridProps, FormatterProps, HeaderRendererProps, RowRendererProps } from "react-data-grid";
import { currency, shortDate, shortDateTime, shortId } from "utils/formatters";
import { RowActions, useRowActions, useTable } from "./context";
import { PurchaseRequestStatusBadge } from "components/Badges/PurchaseRequestStatus";
import { SelectPagination } from "components/Select/Pagination";
const MENU_ID = "PurchaseRequest-row-menu";

const RowMenu: React.FC<{ row: PurchaseRequest }> = ({ row }) => {
  const handleAction = useRowActions();
  return (
    <IconButtonMenu
      options={[
        row?.status !== PurchaseRequestStatus.Submitted &&
          row?.status !== PurchaseRequestStatus.Approved && {
            label: "Edit",
            onClick: () => handleAction("editPurchaseRequest", row),
          },
        {
          label: "Print Label",
          onClick: () => handleAction("printLabel", row),
        },

        {
          label: "Generate PDF",
          onClick: () => handleAction("generatePDF", row),
        },
      ]}
    />
  );
};

const ContextMenu = () => {
  const { selected, selectedRows } = useTable();
  const handleAction = useRowActions();
  const handleItemClick: (args: ItemParams<PurchaseRequest, any>) => void = ({ event, props: item }) => {
    handleAction(event.currentTarget.id as RowActions, item);
  };

  const isChangeStatusHidden = () => {
    return selectedRows?.filter((r) => r.status !== PurchaseRequestStatus.Submitted).length === 0;
  };

  return (
    <Menu id={MENU_ID}>
      <Item
        id="editPurchaseRequest"
        onClick={handleItemClick}
        hidden={({ props }) =>
          props?.status === PurchaseRequestStatus.Submitted || props?.status === PurchaseRequestStatus.Approved
        }
      >
        Edit
      </Item>

      <Item
        id="changeStatusSingle"
        onClick={handleItemClick}
        hidden={({ props }) => props?.status === PurchaseRequestStatus.Submitted}
      >
        Change Status
      </Item>

      <Item id="printLabel" onClick={handleItemClick}>
        Print Label
      </Item>

      <Item id="generatePDF" onClick={handleItemClick}>
        Generate PDF
      </Item>

      <Item id="deletePurchaseRequest" onClick={handleItemClick}>
        <div className="text-brand">Delete</div>
      </Item>

      {selected?.size > 0 && (
        <>
          <div
            className="mt-2 w-full cursor-default"
            style={{
              height: "1px",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            }}
          />

          <div className="text-xs text-gray-600 px-3 font-semibold bg-gray-100  py-2">Bulk Actions</div>

          <Item id="changeStatus" onClick={handleItemClick}>
            Change Selected Statuses
          </Item>

          <Item id="generateLabels" onClick={handleItemClick}>
            Generate Labels
          </Item>

          <Item id="createPurchaseOrders" onClick={handleItemClick} hidden={() => isChangeStatusHidden()}>
            Create Purchase Orders
          </Item>
        </>
      )}
    </Menu>
  );
};

const columns: Column<PurchaseRequest, unknown>[] = [
  {
    headerRenderer: (props: HeaderRendererProps<PurchaseRequest>) => {
      return (
        <div
          className="flex justify-center items-center h-full w-full text-brand cursor-pointer text-lg py-2"
          onClick={(e) => props.onAllRowsSelectionChange(!props?.allRowsSelected)}
        >
          <FontAwesomeIcon icon={props?.allRowsSelected ? faCheckSquare : faSquare} />
        </div>
      );
    },
    formatter: (props: FormatterProps) => {
      return (
        <div
          className={cx("flex justify-center items-center h-full w-full text-brand cursor-pointer text-lg py-2")}
          onClick={(e) => props.onRowSelectionChange(!props?.isRowSelected, e.shiftKey)}
        >
          <FontAwesomeIcon icon={props?.isRowSelected ? faCheckSquare : faSquare} />
        </div>
      );
    },

    key: "select-row",
    name: "",
    resizable: false,
    sortable: false,
    maxWidth: 35,
    width: 35,
  },
  {
    key: "id",
    name: "ID",
    width: 100,

    formatter: ({ row }) => <>{shortId(row?.id)}</>,
  },

  {
    key: "status",
    name: "Status",
    minWidth: 100,
    headerCellClass: "text-center",

    formatter: ({ row }) => (
      <div className="flex justify-center items-center h-full">
        <PurchaseRequestStatusBadge status={row?.status} />
      </div>
    ),
  },

  {
    key: "vendor",
    name: "Vendor",
    minWidth: 100,
    headerCellClass: "text-center",
    cellClass: "text-center",
    formatter: ({ row }) => <>{row?.vendorProduct?.vendor?.name}</>,
  },

  {
    key: "vendorProductName",
    name: "Vendor Product",
    minWidth: 100,
    headerCellClass: "text-center",
    cellClass: "text-center",
    formatter: ({ row }) => <>{row?.vendorProduct?.name}</>,
  },
  {
    key: "quantity",
    name: "Quantity",
    minWidth: 100,
    headerCellClass: "text-center",
    cellClass: "text-center",
  },
  {
    key: "pricePerUnit",
    name: "Price Per Unit",
    minWidth: 100,
    headerCellClass: "text-center",
    cellClass: "text-center",
    formatter: ({ row }) => <>{currency(row?.pricePerUnit)}</>,
  },

  {
    key: "delayUntil",
    name: "Delay Until",
    minWidth: 100,
    headerCellClass: "text-center",
    cellClass: "text-center",
    formatter: ({ row }) => <>{shortDate(row?.delayUntil)}</>,
  },

  {
    key: "createdAt",
    name: "Created",

    formatter: ({ row }) => <>{shortDateTime(row?.createdAt)}</>,
  },

  {
    key: "actions",
    name: "",

    cellClass: "text-center flex justify-center items-center",
    headerCellClass: "text-center flex justify-center items-center",
    formatter: (props) => <RowMenu row={props.row} />,
  },
];

const Table = () => {
  const { setPagination, pagination, fetch, data, loading, setselected, selected, variables } = useTable();

  useEffect(() => {
    fetch({
      variables,
    });
  }, [fetch, variables]);

  return (
    <>
      <div className="flex justify-end items-center">
        <SelectPagination value={pagination?.perPage} onChange={(perPage) => setPagination({ perPage })} />
      </div>
      <div style={{ flex: "1 1 auto" }} className="overflow-auto">
        <StyledTable<React.FC<DataGridProps<PurchaseRequest>>>
          rowHeight={56}
          rowKeyGetter={(r: PurchaseRequest) => r?.id}
          rowRenderer={(props: RowRendererProps<PurchaseRequest, unknown>) => (
            <RowRenderer contextMenuId={MENU_ID} {...props} />
          )}
          columns={columns}
          rows={data?.listPurchaseRequests?.purchaseRequests ?? []}
          selectedRows={selected}
          onSelectedRowsChange={setselected}
          emptyRowsRenderer={() =>
            loading ? (
              <LoadingRenderer message="Fetching purchase requests" />
            ) : (
              <EmptyRowsRenderer message="No purchase requests found" />
            )
          }
        />
      </div>
      <Pagination
        loading={loading}
        currentPage={pagination?.page ?? 1}
        totalPages={data?.listPurchaseRequests?.pageInfo?.totalPages}
        canNextPage={pagination?.page < data?.listPurchaseRequests?.pageInfo?.totalPages}
        canPreviousPage={data?.listPurchaseRequests?.pageInfo?.page > 1 ?? false}
        setPagination={setPagination}
      />
      <ContextMenu />
    </>
  );
};

export { Table as default };
