import { QueryTuple } from "@apollo/client";
import {
  Exact,
  InventoryTransfer,
  InventoryTransferStatus,
  ListInventoryTransferFilters,
  ListInventoryTransfersDocument,
  ListInventoryTransfersQuery,
  ListInventoryTransfersQueryVariables,
  Location,
  PageInfo,
  Pagination,
  useCreateInventoryTransferMutation,
  useDeleteInventoryTransferMutation,
} from "generated/graphql";
import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo } from "react";
import { useSessionStorage } from "react-use";
import { useImmerReducer } from "use-immer";
import { removeFilterHelper } from "utils/filters";
import { generatePdfLabels } from "utils/labels";

const LABEL_TITLE = "INVENTORY TRANSFER";

export interface IFilters {
  from?: Location;
  to?: Location;
  status?: InventoryTransferStatus[];
  showDeleted?: boolean;
}

interface StateIFace {
  filters: IFilters;
  pagination: PageInfo;
  showFilters: boolean;
  selected: Set<React.Key>;
  isCreating: boolean;
  isEditing: InventoryTransfer;
  isDeleting: InventoryTransfer;
}

type SetPagination = {
  type: Actions.SetPagination;
  data: { perPage: number; page: number };
};

type SetSelected = {
  type: Actions.SetSelected;
  data: Set<React.Key>;
};

type SetShowFilters = {
  type: Actions.SetShowFilters;
  data: boolean;
};

type SetFilters = {
  type: Actions.SetFilters;
  data: IFilters;
};

type SetIsCreating = {
  type: Actions.SetIsCreating;
  data: boolean;
};

type SetIsEditing = {
  type: Actions.SetIsEditing;
  data: InventoryTransfer;
};

type SetIsDeleting = {
  type: Actions.SetIsDeleting;
  data: InventoryTransfer;
};

type DispatcherAction =
  | SetShowFilters
  | SetSelected
  | SetPagination
  | SetFilters
  | SetIsCreating
  | SetIsEditing
  | SetIsDeleting;

enum Actions {
  SetFilters = "SET_FILTERS",
  SetShowFilters = "SET_SHOW_FILTERS",
  SetPagination = "SET_PAGINATION",
  SetSelected = "SET_SELECTED",
  SetIsAdding = "SET_IS",
  SetIsCreating = "SET_IS_CREATING",
  SetIsEditing = "SET_IS_EDITING",
  SetIsDeleting = "SET_IS_DELETING",
}

const initialState: StateIFace = {
  filters: null,
  showFilters: false,
  selected: new Set<React.Key>(),
  pagination: { perPage: 10, page: 1 },
  isCreating: false,
  isEditing: null,
  isDeleting: null,
};

const ctx = createContext<{
  query: QueryTuple<
    ListInventoryTransfersQuery,
    Exact<{
      pagination?: Pagination;
      filters?: ListInventoryTransferFilters;
    }>
  >;
  state: StateIFace;
  dispatch: React.Dispatch<DispatcherAction>;
}>({ query: null, state: initialState, dispatch: () => null });

type ITableProvider<QT, FT> = PropsWithChildren<{
  query: QueryTuple<
    QT,
    Exact<{
      pagination?: Pagination;
      filters?: FT;
    }>
  >;
  sessionKey: string;
}>;

export const TableProvider = <QT, FT>({ children, sessionKey, query }: ITableProvider<QT, FT>) => {
  const [filters, setFilters] = useSessionStorage<{
    filters: IFilters;
    pagination: PageInfo;
  }>(sessionKey, {
    filters: initialState.filters,
    pagination: initialState.pagination,
  });

  const [state, dispatch] = useImmerReducer(
    (state: StateIFace, action: DispatcherAction) => {
      switch (action.type) {
        case Actions.SetPagination:
          state.pagination = action.data;
          break;

        case Actions.SetSelected:
          state.selected = action.data;
          break;

        case Actions.SetShowFilters:
          state.showFilters = action.data;
          break;

        case Actions.SetFilters:
          state.filters = action.data;
          break;

        case Actions.SetIsCreating:
          state.isCreating = action.data;
          break;

        case Actions.SetIsEditing:
          state.isEditing = action.data;
          break;

        case Actions.SetIsDeleting:
          state.isDeleting = action.data;
          break;
        default:
          throw new Error("problem with TableProvider");
      }
    },
    { ...initialState, ...filters }
  );

  useEffect(() => {
    setFilters({
      filters: state.filters,
      pagination: state.pagination,
    });
  }, [state, setFilters]);

  return (
    <ctx.Provider
      value={{
        query,
        state,
        dispatch,
      }}
    >
      {children}
    </ctx.Provider>
  );
};

export const useTable = () => {
  const { query, state, dispatch } = useContext(ctx);
  const [fetch, { data, loading, variables: qvariables }] = query;

  const mutationOpts = {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ListInventoryTransfersDocument, variables: qvariables }],
  };

  const [deleteInventoryTransfer] = useDeleteInventoryTransferMutation(mutationOpts);
  const [createInventoryTransfer] = useCreateInventoryTransferMutation(mutationOpts);

  const setPagination = useCallback(
    (pagination: Pagination) => {
      const isChangingPerPage = !!(pagination?.perPage && pagination?.perPage !== state?.pagination?.perPage);
      const page = isChangingPerPage ? 1 : pagination?.page || state?.pagination?.page;
      const perPage = pagination?.perPage || state?.pagination?.perPage || 10;
      dispatch({
        type: Actions.SetPagination,
        data: {
          page,
          perPage,
        },
      });
    },
    [dispatch, state.pagination]
  );

  const setselected = useCallback(
    (rows: Set<React.Key>) => dispatch({ type: Actions.SetSelected, data: rows }),
    [dispatch]
  );

  const setShowFilters = (open: boolean) => {
    dispatch({ type: Actions.SetShowFilters, data: open });
  };

  const setFilters = (filters: IFilters) => {
    dispatch({ type: Actions.SetFilters, data: filters });
  };

  const removeFilter = (property: string, i?: number) => {
    removeFilterHelper(
      state.filters,
      {
        property,
        i,
      },
      setFilters
    );
  };

  const resetFilters = () => {
    dispatch({
      type: Actions.SetFilters,
      data: {},
    });
  };

  const setIsCreating = (isCreating: boolean) => {
    dispatch({ type: Actions.SetIsCreating, data: isCreating });
  };

  const setIsEditing = (inventoryTransfer: InventoryTransfer) => {
    dispatch({ type: Actions.SetIsEditing, data: inventoryTransfer });
  };

  const setIsDeleting = (inventoryTransfer: InventoryTransfer) => {
    dispatch({ type: Actions.SetIsDeleting, data: inventoryTransfer });
  };

  const variables: ListInventoryTransfersQueryVariables = useMemo(
    () => ({
      filters: {
        fromId: state?.filters?.from?.id,
        toId: state?.filters?.to?.id,
        status: state?.filters?.status,
        showDeleted: state?.filters?.showDeleted,
      },
      pagination: state.pagination,
    }),

    [state.pagination, state.filters]
  );

  const selectedRows = useMemo(() => {
    return (
      data?.listInventoryTransfers?.inventoryTransfers?.filter((inventoryTransfer) => {
        return state.selected.has(inventoryTransfer.id);
      }) ?? []
    );
  }, [data?.listInventoryTransfers?.inventoryTransfers, state.selected]);

  return {
    ...state,
    data,
    loading,
    variables,
    selectedRows,
    setIsCreating,
    setIsEditing,
    setIsDeleting,
    setPagination,
    setselected,
    setShowFilters,
    setFilters,
    removeFilter,
    resetFilters,
    fetch,

    deleteInventoryTransfer,
    createInventoryTransfer,
  };
};

export type RowActions =
  | "printLabel"
  | "setPrimary"
  | "generateLabels"
  | "deleteInventoryTransfer"
  | "deleteInventoryTransfers"
  | "editInventoryTransfer";

export const useRowActions = () => {
  const { selectedRows, setIsDeleting, setIsEditing } = useTable();

  const handleAction = (action: RowActions, row: InventoryTransfer) => {
    switch (action) {
      case "printLabel":
        generatePdfLabels(LABEL_TITLE, [row]);
        break;

      case "generateLabels":
        generatePdfLabels(LABEL_TITLE, selectedRows);
        break;

      case "editInventoryTransfer":
        setIsEditing(row);

        break;

      case "deleteInventoryTransfer":
        setIsDeleting(row);
        break;

      case "deleteInventoryTransfers":
        break;

      default:
        break;
    }
  };

  return handleAction;
};
