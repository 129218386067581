import React from "react";
import { toast, ToastContent, ToastOptions } from "react-toastify";

export enum ToastContainers {
  BottomCenter = "bottom-center",
  BottomRight = "bottom-right",
}

export interface IToast {
  dismiss: (id?: string | number) => void;
  info: (message: ToastContent, options?: ToastOptions) => void;
  success: (message: ToastContent, options?: ToastOptions) => void;
  error: (message: ToastContent, options?: ToastOptions) => void;
}

export const useToast: (containerId?: ToastContainers, options?: { noStack: boolean }) => IToast = (
  containerId = ToastContainers.BottomCenter,
  toastOptions = { noStack: true }
) => {
  const toastId = React.useRef(null);

  const notify = (message: ToastContent, options: ToastOptions) => {
    const _options = {
      autoClose: 3000,
      containerId,
      ...options,
    };
    if (!toastOptions.noStack) {
      toast.info(message, _options);
      return;
    }

    if (!toastId.current) {
      toastId.current = toast.info(message, {
        ..._options,
        onClose: () => {
          toastId.current = null;
        },
      });

      return;
    }
    toast.update(toastId.current, {
      ..._options,
      render: message,
    });
  };

  const info = (message: ToastContent, options?: ToastOptions) =>
    notify(message, { type: toast.TYPE.INFO, ...options });

  const success = (message: ToastContent, options?: ToastOptions) =>
    notify(message, { type: toast.TYPE.SUCCESS, ...options });

  const error = (message: ToastContent, options?: ToastOptions) =>
    notify(message, { type: toast.TYPE.ERROR, ...options });

  const dismiss = toast.dismiss;

  return {
    dismiss,
    info,
    success,
    error,
  };
};
