import React from "react";
import cx from "classnames";
import { ScrollableForm } from "components/Form/ScrollableForm";
import { Controller, useForm } from "react-hook-form";
import { SubHeading } from "styles";
import { TagInput } from "components/Form/TagInput";
import { useTable } from "./context";
import TextInput from "components/Form/TextInput";
import { SideModal } from "components/Modals/SideModal";

interface IFiltersFormData {
  ids: string[];
  name: string;
  company: string;
  keys: string[];
}

export const QuickFilters = () => {
  const { filters, showQuickFilters, setFilters } = useTable();

  return (
    <>
      <div className={cx(!showQuickFilters && "hidden", "grid grid-cols-1 lg:grid-cols-4 gap-x-2")}>
        <div>
          <label className="text-xs">Name contains</label>

          <TagInput
            tags={filters.name ? [filters.name] : []}
            maxTags={1}
            onChange={(names: string[]) => setFilters({ ...filters, name: names?.[0] ?? null })}
          />
        </div>

        <div>
          <label className="text-xs">Customer #</label>

          <TagInput
            tags={filters?.keys ?? []}
            maxTags={5}
            onChange={(keys: string[]) => setFilters({ ...filters, keys })}
          />
        </div>

        <div>
          <label className="text-xs">Company name contains</label>

          <TagInput
            tags={filters.company ? [filters.company] : []}
            maxTags={1}
            onChange={(companies: string[]) => setFilters({ ...filters, company: companies?.[0] ?? null })}
          />
        </div>
      </div>
    </>
  );
};

export const FiltersHeader = () => {
  return (
    <div>
      <QuickFilters />
    </div>
  );
};

export const Filters = () => {
  const { setFilters, filters, setShowFilters } = useTable();
  const { control, handleSubmit } = useForm<IFiltersFormData>({
    defaultValues: {
      ids: filters?.ids ?? [],
      name: filters?.name ?? "",
      company: filters?.company ?? "",
      keys: filters?.keys ?? [],
    },
  });

  const onSubmit = (formData: IFiltersFormData) => {
    setFilters({
      company: formData?.company !== "" ? formData?.company : null,
      name: formData?.name !== "" ? formData?.name : null,
      keys: formData?.keys ?? [],
      ids: [],
    });

    setShowFilters(false);
  };

  return (
    <ScrollableForm
      title="Filters"
      onSubmit={handleSubmit(onSubmit)}
      onCancel={() => setShowFilters(false)}
      submitLabel="Apply Filters"
      data-testid="customer-filters-form"
    >
      <SubHeading>Name contains</SubHeading>

      <Controller
        name="name"
        control={control}
        defaultValue={filters?.name ?? ""}
        render={({ field }) => (
          <TextInput value={field.value} onChange={field.onChange} placeholder="ie. Carl G. Peters" />
        )}
      />

      <SubHeading>Customer #</SubHeading>

      <Controller
        name="keys"
        control={control}
        defaultValue={filters?.keys ?? []}
        render={({ field }) => (
          <TagInput tags={field.value} maxTags={5} onChange={(keys: string[]) => field.onChange(keys)} />
        )}
      />

      <SubHeading>Company name contains</SubHeading>

      <Controller
        name="company"
        control={control}
        defaultValue={filters?.company ?? ""}
        render={({ field }) => (
          <TextInput value={field.value} onChange={field.onChange} placeholder="ie. Dearborn Denim" />
        )}
      />
    </ScrollableForm>
  );
};

export const FiltersModal = () => {
  const { setShowFilters, showFilters } = useTable();
  return (
    <SideModal side="RIGHT" isOpen={showFilters} onRequestClose={() => setShowFilters(false)} className="w-1/4">
      <Filters />
    </SideModal>
  );
};
