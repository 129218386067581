import WorkOrderStatus from "components/Badges/WorkOrderStatus";
import EventGraph from "components/EventGraph";
import NumberInput from "components/Form/NumberInput";
import TextAreaInput from "components/Form/TextAreaInput";
import IconButtonMenu from "components/IconButtonMenu";
import { Confirmation } from "components/Modals/Confirmation";
import { SideModal } from "components/Modals/SideModal";
import QRCode from "components/QRCode";
import { SelectWorkflow } from "components/Select/Workflow";
import {
  GetManufacturingOrderDocument,
  GetStreamDocument,
  ManufacturingOrderStatus,
  ResourceTag,
  useDeleteManufacturingOrderMutation,
  useGetManufacturingOrderQuery,
  useUpdateManufacturingOrderMutation,
  Workflow,
  useWorkOrderStepEventsSubscription,
} from "generated/graphql";
import { useToast } from "hooks/toast";
import { MultiTagInputWSuggest } from "components/Form/TagInput";
import React, { useEffect, useState, useCallback } from "react";
import { Controller, DeepMap, FieldError, useForm } from "react-hook-form";
import { NumberFormatValues } from "react-number-format";
import { useParams } from "react-router-dom";
import { BlueBadge, H4, H3 } from "styles";
import { shortId } from "utils/formatters";
import AddTrigger from "./AddTrigger";
import Attachments from "./Attachments";
import { TimelineModal } from "./Timeline";
import { ScrollableForm } from "components/Form/ScrollableForm";
import { removeAtIndex } from "utils/filters";

interface ManufacturingOrderProps {
  id: string;
}
interface ManufacturingOrderParams {
  id: string;
}

interface IWorkOrderForm {
  quantity: number;
  notes: string;
  workflow: Pick<Workflow, "id" | "name">;
  tags: ResourceTag[];
}

const ManufacturingOrder: React.FC<ManufacturingOrderProps> = () => {
  const toast = useToast();
  const [isDeleting, setisDeleting] = useState(false);
  const [addTrigger, setaddTrigger] = useState(false);
  const [viewTimeline, setviewTimeline] = useState(false);

  const { id } = useParams<ManufacturingOrderParams>();
  useWorkOrderStepEventsSubscription({ variables: { id }, shouldResubscribe: true });
  const { data, variables, refetch } = useGetManufacturingOrderQuery({
    variables: { id },
    // pollInterval: 10000,
    fetchPolicy: "cache-and-network",
  });

  const mutationOpts = {
    refetchQueries: [
      { query: GetManufacturingOrderDocument, variables },
      {
        query: GetStreamDocument,
        variables: {
          id,
          type: "WORK_ORDER",
        },
      },
    ],
  };
  const [updateManufacturingOrder] = useUpdateManufacturingOrderMutation(mutationOpts);
  const [deleteManufacturingOrder] = useDeleteManufacturingOrderMutation(mutationOpts);

  const { register, handleSubmit, setValue, control, formState, watch } = useForm<IWorkOrderForm>();
  const tags = watch("tags");
  const submit = useCallback(
    async (formData: IWorkOrderForm) => {
      try {
        await updateManufacturingOrder({
          variables: {
            input: {
              id,
              quantity: formData.quantity,
              notes: formData.notes,
              workflowId: formData?.workflow?.id,
              version: data?.manufacturingOrder?.version,
              tags:
                formData?.tags?.map((field) => ({
                  tagId: field.tag.id,
                  value: field.value,
                })) ?? null,
            },
          },
        });
        toast.success("Changes saved");
      } catch (err) {
        console.error(err);
        toast.error("Unable to save changes");
      }
    },
    [data?.manufacturingOrder?.version, id, toast, updateManufacturingOrder]
  );

  const onFormError = (
    errors: DeepMap<
      {
        quantity: number;
        notes: string;
      },
      FieldError
    >
  ) => {
    if (errors.quantity) {
      toast.error(errors.quantity.message);
    }
  };

  useEffect(() => {
    if (data?.manufacturingOrder) {
      setValue("notes", data?.manufacturingOrder?.notes);
      setValue("quantity", data?.manufacturingOrder?.quantity);
      setValue("workflow", data?.manufacturingOrder?.workflowVersion?.workflow);
      setValue("tags", data?.manufacturingOrder?.tags ?? []);
    }
  }, [setValue, data]);

  return (
    <>
      <div className="py-2 px-2 mb-4">
        <div className="flex items-center">
          <H3>
            Work Order <span className="text-brand">{shortId(data?.manufacturingOrder?.id)}</span>
          </H3>
          <IconButtonMenu
            options={[
              {
                label: "Refresh",
                onClick: () => refetch(),
              },
              {
                label: <div className="text-brand">Cancel Order</div>,
                onClick: () => setisDeleting(true),
              },
            ]}
          />
        </div>
        <WorkOrderStatus status={data?.manufacturingOrder?.status} />
      </div>

      <ScrollableForm
        formState={formState}
        onSubmit={handleSubmit(submit, onFormError)}
        onCancel={() => {}}
        submitLabel="Save"
        classNames={{ buttonContainer: "w-full lg:w-1/3" }}
      >
        <div className="flex flex-wrap">
          <div className="w-full lg:w-1/2">
            <H4 className="mb-2">Details</H4>

            <div className="flex flex-wrap">
              <table className="table-auto w-full xl:w-2/3">
                <thead>
                  <tr>
                    <th className="w-1/5"></th>
                    <th className="w-4/5"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="py-2 text-sm font-semibold">ID:</td>
                    <td className="py-2 text-sm ">{data?.manufacturingOrder?.id}</td>
                  </tr>
                  <tr>
                    <td className="py-2 text-sm font-semibold">Item Name:</td>
                    <td
                      title={data?.manufacturingOrder?.inventoryItem?.name}
                      className="py-2 truncate"
                      style={{ maxWidth: "200px" }}
                    >
                      {data?.manufacturingOrder?.inventoryItem?.name}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 text-sm font-semibold">Quantity:</td>
                    <td>
                      <Controller
                        control={control}
                        name="quantity"
                        rules={{
                          required: "Quantity is required",
                          min: {
                            value: 1,
                            message: "must be greater than 0",
                          },
                        }}
                        render={({ field }) => (
                          <NumberInput
                            className="my-2"
                            placeholder="Quantity"
                            value={field.value}
                            onValueChange={(value: NumberFormatValues) => field.onChange(value.floatValue)}
                          />
                        )}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td className="py-2 font-semibold">Workflow:</td>
                    <td className="py-2">
                      <Controller
                        control={control}
                        name="workflow"
                        render={({ field }) => (
                          <SelectWorkflow
                            disabled={data?.manufacturingOrder?.status !== ManufacturingOrderStatus?.NotStarted}
                            value={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="w-full xl:w-1/3 xl:px-2">
                {/* <QRCode value={`factoryfinch://inventory/${data?.manufacturingOrder?.id}`} /> */}
                <QRCode value={data?.manufacturingOrder?.id} size={150} />
              </div>
            </div>

            <div className="my-4">
              <H4 className="mb-2">Notes</H4>

              <TextAreaInput rows={6} placeholder="Notes..." {...register("notes")} />
            </div>
            <div>
              <H4 className="mb-2">Tags</H4>

              <Controller
                name="tags"
                control={control}
                render={({ field }) => {
                  return (
                    <MultiTagInputWSuggest
                      value={field.value}
                      onChange={field.onChange}
                      onRemove={(_v, i) => setValue("tags", removeAtIndex(tags, i))}
                      allowCreate
                    />
                  );
                }}
              />
            </div>
            <div className="my-4">
              <Attachments orderId={id} />
            </div>
          </div>

          <div className="w-full lg:w-1/2">
            <div className="px-2 w-full flex flex-col flex-1 ">
              <div className="flex items-center justify-between">
                <H4 className="mb-2">Events</H4>
                <button
                  type="button"
                  className="text-blue-500 focus:outline-none"
                  onClick={() => setviewTimeline(true)}
                >
                  View Timeline
                </button>
              </div>

              <div className="mb-4">
                <BlueBadge>{`Workflow: ${data?.manufacturingOrder?.workflowVersion?.name} v${data?.manufacturingOrder?.workflowVersion?.version}`}</BlueBadge>
              </div>

              <div className="overflow-hidden shadow-lg" style={{ height: "600px" }}>
                <EventGraph />
              </div>
            </div>
          </div>
        </div>
      </ScrollableForm>
      <Confirmation
        isOpen={isDeleting}
        onRequestClose={() => setisDeleting(false)}
        onConfirm={async () => {
          try {
            await deleteManufacturingOrder({
              variables: { id: data?.manufacturingOrder?.id },
            });
            setisDeleting(false);
            toast.success("Order cancelled");
          } catch (err) {
            setisDeleting(false);
            toast.error(`Unable to cancel order ${data?.manufacturingOrder?.id}`);
          }
        }}
      >
        <div className="my-4">
          <H3 className="py-4">Are you sure you want to cancel this order?</H3>
        </div>
      </Confirmation>

      <SideModal side="RIGHT" isOpen={addTrigger} onRequestClose={() => setaddTrigger(false)}>
        <AddTrigger onRequestClose={() => setaddTrigger(false)} />
      </SideModal>
      <TimelineModal isOpen={viewTimeline} onRequestClose={() => setviewTimeline(false)} id={id} type="WORK_ORDER" />
    </>
  );
};

export { ManufacturingOrder as default };
