import axios from "axios";
import { useAddAttachmentMutation, AttachmentResourceType, Attachment } from "generated/graphql";
import { LocalStorageKeys, hasExt, toExt } from "config";
import { saveAs } from "file-saver";

export const useUploadFile = () => {
  const token = localStorage.getItem(LocalStorageKeys.Token);
  return async (file: File, onProgress?: (e: ProgressEvent) => void) => {
    const resp = await axios.get<{
      signedRequest: string;
      url: string;
      key: string;
    }>("/api/sign-s3", {
      params: {
        "file-name": file?.name,
        "file-type": file?.type,
      },
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    const onUploadProgress = onProgress ? onProgress : () => {};

    await axios.put(resp?.data?.signedRequest, file, {
      onUploadProgress,
    });

    return resp;
  };
};

export const useDownloadAttachment = () => {
  const token = localStorage.getItem(LocalStorageKeys.Token);
  return async (attachment: Attachment) => {
    const resp = await axios.get<string>(`/api/assets/${attachment?.id}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    if (resp?.data) {
      const name = hasExt(attachment?.name) ? attachment?.name : `${attachment?.name}${toExt(attachment.type)}`;
      saveAs(resp?.data, name);
      return;
    }

    throw new Error("Unable to download file");
  };
};

export const useAttachment = () => {
  const uploadFile = useUploadFile();
  const [add] = useAddAttachmentMutation();

  const addAttachment = async (
    file: File,
    options: {
      fileName?: string;
      resourceId: string;
      resourceType: AttachmentResourceType;
      onProgress?: (e: ProgressEvent) => void;
    }
  ) => {
    const resp = await uploadFile(file, options.onProgress);
    return add({
      variables: {
        input: {
          resourceId: options.resourceId,
          resourceType: options.resourceType,
          name: options?.fileName ?? file?.name,
          url: resp.data.key,
          type: file?.type,
        },
      },
    });
  };

  return addAttachment;
};
