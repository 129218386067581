import { Confirmation } from "components/Modals/Confirmation";

import React from "react";
import { H4, HR } from "styles";
import { notEmpty } from "utils/filters";
import { ITagInputValue } from "components/Form/TagInput";

export const ConfirmRemoveTag: React.FC<{
  resourceTag: ITagInputValue;
  loading: boolean;
  onCancel: () => void;
  onConfirm: () => void | Promise<void>;
}> = ({ resourceTag, loading, onCancel, onConfirm }) => {
  return (
    <Confirmation
      isOpen={notEmpty(resourceTag)}
      loading={loading}
      onRequestClose={onCancel}
      cancelButtonLabel="No"
      confirmButtonLabel="Yes"
      onConfirm={onConfirm}
    >
      <div className="my-4 w-full">
        <H4 className="py-4 flex flex-row justify-center text-default dark:text-white">
          Are you sure you want to remove this tag?
        </H4>

        <div className="text-center my-4 font-semibold text-2xl">
          {resourceTag && <div>{`${resourceTag?.tag?.name} = ${resourceTag?.value}`}</div>}
        </div>
        <HR />
      </div>
    </Confirmation>
  );
};
