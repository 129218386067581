import React from "react";
import { ParseResult } from "papaparse";

import {
  ListInventoryDocument,
  InventoryItem,
  ListInventoryQuery,
  ListInventoryQueryVariables,
} from "generated/graphql";
import { chunk } from "lodash";
import { ImportFromCSV, ITemplateRow, IImportFrom } from "components/ImportFromCSV";
import { useApolloClient } from "@apollo/client";

interface ImportLineItemsTemplateRow extends ITemplateRow {
  inventoryItemId: string;
  quantity: string;
}

export const ImportLineItemsFromCSV: React.FC<IImportFrom & { onChange: (values: any) => void }> = ({
  onChange,
  onRequestClose,
  isOpen,
}) => {
  const client = useApolloClient();

  const handleImport = async (
    parsed: ParseResult<ImportLineItemsTemplateRow> & { file: File },
    _tags: Record<string, string>,
    onProgress: (progress: number) => void
  ) => {
    const { data: rows } = parsed;
    const m = rows.reduce<Record<string, ImportLineItemsTemplateRow>>((acc, r) => {
      acc[r.inventoryItemId] = r;
      return acc;
    }, {});
    const chunkSize = 20;
    const chunks = chunk(rows, chunkSize);

    let lineItems: { inventoryItem: InventoryItem; quantity: number }[] = [];
    for (let i = 0; i < chunks.length; i++) {
      const chunk = chunks[i];
      const resp = await client.query<ListInventoryQuery, ListInventoryQueryVariables>({
        query: ListInventoryDocument,
        variables: {
          filters: {
            ids: chunk.map((row) => row.inventoryItemId),
          },
          pagination: {
            perPage: chunkSize,
          },
        },
      });

      const items = resp?.data?.listInventory?.inventory?.map((i) => ({
        inventoryItem: i,
        quantity: parseFloat(m?.[i?.id]?.quantity) ?? 0,
      }));
      lineItems.push(...items);

      onProgress(Math.floor(((i + 1) / chunks.length) * 100));
    }

    onChange(lineItems);
  };

  return (
    <ImportFromCSV
      type="line items"
      isOpen={isOpen}
      createTags={false}
      onRequestClose={onRequestClose}
      templateURL="/assets/lineitem-import-template.csv"
      importFn={handleImport}
    />
  );
};
