import React from "react";
import { Select } from "styles";
import cx from "classnames";

export const AddressType = {
  SHIPPING: "SHIPPING",
  BILLING: "BILLING",
};

const options = Object.keys(AddressType);

export const getAddressTypeLabel = (type: string) => {
  switch (type) {
    case "SHIPPING":
      return "Shipping";
    case "BILLING":
      return "Billing";
  }
};

export const getAddressTypeOption = (type: string) => {
  return { label: getAddressTypeLabel(type), value: type };
};

export const SelectAddressType: React.FC<{
  value: string;
  onChange: (opt: string) => void;
  placeholder?: string;
  label?: string;
  className?: string;
}> = ({ value, onChange, label, className = "", placeholder = "Select Type..." }) => {
  return (
    <div className={cx("w-full", className)}>
      {label && <label className="text-xs">{label}</label>}
      <Select
        placeholder={placeholder}
        isClearable
        value={getAddressTypeOption(value)}
        options={options.map(getAddressTypeOption)}
        onChange={(opt: { label: string; value: string }) => {
          onChange(opt?.value);
        }}
      />
    </div>
  );
};
