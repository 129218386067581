import React from "react";
import { RedButton } from "styles";

const ErrorFallback = ({ error, componentStack, resetError }) => (
  <div className="w-full h-screen flex flex-col justify-center items-center">
    <div className="text-lg my-2">Oh no! Something went wrong 😭</div>
    <div className="my-2 font-semibold">{error.toString()}</div>
    <pre className="mb-4">{componentStack}</pre>
    <RedButton onClick={() => resetError()}>Click here to reset!</RedButton>
  </div>
);

export { ErrorFallback as default };
