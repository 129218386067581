import { DateRange, DateRangePicker as DDateRangePicker } from "react-date-range";
import styled, { css } from "styled-components";
import tw from "twin.macro";

const dateRangeStyle = css`
  &.rdrCalendarWrapper {
    ${tw`bg-transparent dark:bg-gray-800`}
  }

  .rdrDateDisplayWrapper {
    .rdrDateInput.rdrDateDisplayItem {
      ${tw`bg-transparent dark:bg-gray-800`}
      input {
        ${tw`text-gray-800 dark:text-white font-semibold`}
      }
    }
  }

  .rdrNextPrevButton.rdrNextButton {
    ${tw`bg-transparent dark:bg-gray-800`}

    i {
      ${tw`bg-transparent dark:border-l-white`}
    }
  }

  .rdrNextPrevButton.rdrPprevButton {
    ${tw`bg-transparent dark:bg-gray-800`}

    i {
      ${tw`bg-transparent dark:border-r-white`}
    }
  }

  .rdrDateDisplayWrapper {
    ${tw`bg-transparent dark:bg-gray-800`}
  }

  .rdrDay {
    ${tw`text-gray-800 dark:text-white font-semibold`}
    &.rdrDayPassive .rdrDayNumber span {
      ${tw`text-gray-300 dark:text-gray-400 font-semibold`}
    }

    &.rdrDayDisabled {
      ${tw`bg-transparent dark:bg-gray-700`}
      .rdrDayNumber span {
        ${tw`text-gray-300 dark:text-gray-400 font-semibold`}
      }
    }
  }

  .rdrMonthAndYearPickers .rdrMonthPicker select,
  .rdrMonthAndYearPickers .rdrYearPicker select {
    ${tw`text-gray-800 dark:text-white font-semibold`}
  }

  .rdrWeekDay {
  }

  .rdrDayNumber span {
    ${tw`text-gray-800 dark:text-white font-semibold`}
  }

  .rdrDayToday .rdrDayNumber span:after {
    ${tw`bg-brand`}
  }

  .rdrStaticRange {
    ${tw`bg-transparent dark:bg-gray-800 dark:border-none hover:text-gray-800`}
  }

  .rdrDefinedRangesWrapper {
    ${tw`bg-transparent dark:bg-gray-800 dark:border-none`}
  }

  .rdrCalendarWrapper {
    ${tw`bg-transparent dark:bg-gray-800`}
  }
`;

export const StyledDateRangePicker = styled(DDateRangePicker)`
  ${dateRangeStyle}
`;

export const DateRangePicker = styled(DateRange)`
  ${dateRangeStyle}
`;
