import React from "react";
import { WorkflowVersion } from "generated/graphql";
import { BlueBadge } from "styles";

const Workflow: React.FC<{ workflow: WorkflowVersion; prefix?: string }> = ({
  workflow,
  prefix = "",
}) => {
  return (
    <BlueBadge>{`${prefix}${workflow?.name} v${workflow?.version}`}</BlueBadge>
  );
};

export { Workflow as default };
