import React from "react";
import { Integrations } from "components/Views/Account/Integrations";
import { Redirect, Route, Switch } from "react-router-dom";
import { Routes } from "routes";
import MyAccount from "./MyAccount";
import { Support } from "components/Support";

const Settings: React.FC = () => {
  return (
    <Switch>
      <Route exact path={Routes.MyAccount} component={MyAccount} />
      <Route exact path={Routes.Integrations} component={Integrations} />
      <Route exact path={Routes.Support} component={Support} />
      <Redirect to={Routes.MyAccount} />
    </Switch>
  );
};

export { Settings as default };
