import { useMeQuery, useUpdateUserMutation } from "generated/graphql";
import React, { useMemo } from "react";
import Joyride, { Step as JoyRideStep } from "react-joyride";
import { useLockBodyScroll } from "react-use";

const jrSteps: JoyRideStep[] = [
  {
    content: "Welcome to Factory Finch!",
    placement: "center",
    target: "body",
    disableBeacon: true,
  },

  {
    target: ".joyride-analytics-navitem",
    content: "Factory Finch helps you get data from your shop floor. Analytics is where you digest the results!",
    disableBeacon: true,
    spotlightPadding: 0,
  },

  {
    target: ".joyride-production-navitem",
    content: "Define your production steps, group them into workflows. Import your inventories and then get cracking!",
    disableBeacon: true,
    spotlightPadding: 0,
  },
  {
    target: ".joyride-fulfillment-navitem",
    content: "Keep track of sales orders and manage fulfillment with our pick planner!",
    disableBeacon: true,
    spotlightPadding: 0,
  },

  {
    target: ".joyride-team-navitem",
    content: "Invite admins and grant access to the Factory Finch mobile app to your operators!",
    disableBeacon: true,
    spotlightPadding: 0,
  },

  {
    content: "We're excited to have you on board! 🐤",
    placement: "center",
    target: "body",
    disableBeacon: true,
  },
];

const OnBoarding = () => {
  const { data, loading, refetch } = useMeQuery();
  const [updateConfig] = useUpdateUserMutation();
  useLockBodyScroll(true);

  const showOnboarding = useMemo(
    () => data?.me?.__typename === "User" && data?.me?.config?.onBoardingSeen === false,
    [data?.me]
  );

  if (loading) {
    return null;
  }

  return (
    <>
      <Joyride
        run={showOnboarding}
        continuous
        scrollToFirstStep
        showProgress
        floaterProps={{
          disableAnimation: true,
        }}
        steps={jrSteps}
        callback={async (state) => {
          if (state.action === "close" || state.action === "reset") {
            await updateConfig({
              variables: {
                input: { id: data?.me?.id, config: { onBoardingSeen: true } },
              },
            });
            refetch();
          }
        }}
      />
    </>
  );
};

export { OnBoarding as default };
