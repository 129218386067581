import React from "react";
import { ManufacturingOrderStatus } from "generated/graphql";
import { Select } from "styles";

export const getWorkOrderStatusLabel = (type: ManufacturingOrderStatus) => {
  switch (type) {
    case ManufacturingOrderStatus.NotStarted:
      return "Not started";
    case ManufacturingOrderStatus.Cancelled:
      return "Cancelled";
    case ManufacturingOrderStatus.Complete:
      return "Complete";
    case ManufacturingOrderStatus.InProgress:
      return "In progress";
    case ManufacturingOrderStatus.Unknown:
      return "Unknown";

    default:
      return type;
  }
};

export const getWorkOrderStatusOption = (status: ManufacturingOrderStatus) => {
  return { label: getWorkOrderStatusLabel(status), value: status };
};

export const SelectWorkOrderStatuses: React.FC<{
  value: ManufacturingOrderStatus[];
  onChange: (opt: ManufacturingOrderStatus[]) => void;
  placeholder?: string;
}> = ({ value, onChange, placeholder = "Filter by Status..." }) => {
  return (
    <Select
      placeholder={placeholder}
      isClearable
      value={value}
      isMulti
      options={[
        ManufacturingOrderStatus.NotStarted,
        ManufacturingOrderStatus.Complete,
        ManufacturingOrderStatus.InProgress,
        ManufacturingOrderStatus.Cancelled,
        ManufacturingOrderStatus.Unknown,
      ]}
      onChange={onChange}
      getOptionValue={(opt: ManufacturingOrderStatus) => opt}
      getOptionLabel={(opt: ManufacturingOrderStatus) => getWorkOrderStatusLabel(opt)}
    />
  );
};

export const SelectWorkOrderStatus: React.FC<{
  value: ManufacturingOrderStatus;
  onChange: (opt: ManufacturingOrderStatus) => void;
  placeholder?: string;
}> = ({ value, onChange, placeholder = "Select Status..." }) => {
  return (
    <Select
      placeholder={placeholder}
      isClearable
      value={getWorkOrderStatusOption(value)}
      options={[
        ManufacturingOrderStatus.NotStarted,
        ManufacturingOrderStatus.Complete,
        ManufacturingOrderStatus.InProgress,
        ManufacturingOrderStatus.Cancelled,
        ManufacturingOrderStatus.Unknown,
      ].map(getWorkOrderStatusOption)}
      onChange={(opt: { label: string; value: ManufacturingOrderStatus }) => onChange(opt?.value)}
    />
  );
};
