import { InventoryItemType, LocationInventory } from "generated/graphql";
import React from "react";
import { Item, ItemParams, Menu } from "react-contexify";
import { RowActions, useRowActions, useTable } from "./context";

export const MENU_ID = "inventory-row-menu";

export const ContextMenu = () => {
  const { selected, selectedRows } = useTable();
  const handleAction = useRowActions();

  const handleItemClick: (args: ItemParams<LocationInventory, any>) => void = ({
    event,
    props: item,
    triggerEvent,
    data,
  }) => {
    handleAction(event.currentTarget.id as RowActions, item);
  };

  return (
    <Menu id={MENU_ID}>
      <Item id="qrCode" onClick={handleItemClick}>
        QR Code
      </Item>
      <Item id="editItem" onClick={handleItemClick}>
        Edit
      </Item>
      <Item id="updateOnHand" onClick={handleItemClick}>
        Update Inventory
      </Item>

      <Item id="requestStockTake" onClick={handleItemClick}>
        Request stock take
      </Item>

      <Item id="printLabel" onClick={handleItemClick}>
        Print Label
      </Item>

      {/*
      TODO: Come back to this
      <Item id="viewTimeline" onClick={handleItemClick}>
        View Timeline
      </Item> */}

      <Item
        id="addToPurchaseOrder"
        onClick={handleItemClick}
        hidden={({ props }) => props?.type !== InventoryItemType.RawMaterial}
      >
        Create Purchase Request
      </Item>

      <Item id="addToInventoryTransfer" onClick={handleItemClick}>
        Add To Inventory Transfer
      </Item>

      <Item id="deleteItem" onClick={handleItemClick} hidden={({ props }) => props?.inventoryItem?.deletedAt !== null}>
        <div className="text-brand">Delete</div>
      </Item>

      <Item
        id="restoreInventoryItem"
        onClick={handleItemClick}
        hidden={({ props }) => props?.inventoryItem?.deletedAt === null}
      >
        <div className="text-blue-500">Restore</div>
      </Item>

      {selected?.size > 0 && (
        <>
          <div
            className="mt-2 w-full cursor-default"
            style={{
              height: "1px",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            }}
          />

          <div className="text-xs text-gray-600 px-3 font-semibold bg-gray-100  py-2">Bulk Actions</div>

          <Item id="bulkUpdate" onClick={handleItemClick}>
            Edit Selected
          </Item>

          <Item id="printLabels" onClick={handleItemClick}>
            Print Labels
          </Item>

          <Item id="changeWorkflow" onClick={handleItemClick}>
            Change Workflow
          </Item>

          <Item id="bulkRequestStockTake" onClick={handleItemClick}>
            Request Stock Take
          </Item>

          <Item id="addSelectedToInventoryTransfer" onClick={handleItemClick}>
            Add selected To Inventory Transfer
          </Item>

          <Item
            id="addSelectedToPurchaseOrder"
            onClick={handleItemClick}
            hidden={() => !selectedRows.every((r) => r?.inventoryItem?.type === InventoryItemType.RawMaterial)}
          >
            Add selected To Purchase Request
          </Item>

          <Item id="bulkDelete" onClick={handleItemClick}>
            <div className="text-brand">Delete Selected</div>
          </Item>
        </>
      )}
    </Menu>
  );
};
