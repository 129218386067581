import React from "react";
import { Select } from "styles";
import { CustomerOrderPaidStatus } from "generated/graphql";

const options = [
  CustomerOrderPaidStatus.Authorized,
  CustomerOrderPaidStatus.Paid,
  CustomerOrderPaidStatus.PartiallyPaid,
  CustomerOrderPaidStatus.PartiallyRefunded,
  CustomerOrderPaidStatus.Pending,
  CustomerOrderPaidStatus.Refunded,
  CustomerOrderPaidStatus.Unknown,
  CustomerOrderPaidStatus.Voided,
];

export const getCustomerOrderPaidStatusLabel = (type: CustomerOrderPaidStatus) => {
  switch (type) {
    case CustomerOrderPaidStatus.Authorized:
      return "Authorized";
    case CustomerOrderPaidStatus.Paid:
      return "Paid";
    case CustomerOrderPaidStatus.PartiallyPaid:
      return "Partially Paid";
    case CustomerOrderPaidStatus.PartiallyRefunded:
      return "Partially Refunded";
    case CustomerOrderPaidStatus.Pending:
      return "Pending";
    case CustomerOrderPaidStatus.Refunded:
      return "Refunded";
    case CustomerOrderPaidStatus.Unknown:
      return "Unknown";
    case CustomerOrderPaidStatus.Voided:
      return "Voided";

    default:
      return type;
  }
};

export const getCustomerOrderPaidStatusOption = (status: CustomerOrderPaidStatus) => {
  return { label: getCustomerOrderPaidStatusLabel(status), value: status };
};

export const SelectCustomerOrderPaidStatuses: React.FC<{
  value: CustomerOrderPaidStatus[];
  onChange: (opt: CustomerOrderPaidStatus[]) => void;
  placeholder?: string;
  label?: string;
}> = ({ value, onChange, label, placeholder = "Filter by Status..." }) => {
  return (
    <div className="w-full">
      {label && <label className="text-xs">{label}</label>}
      <Select
        placeholder={placeholder}
        isClearable
        value={value}
        isMulti
        options={options}
        onChange={onChange}
        getOptionValue={(opt: CustomerOrderPaidStatus) => opt}
        getOptionLabel={(opt: CustomerOrderPaidStatus) => getCustomerOrderPaidStatusLabel(opt)}
      />
    </div>
  );
};

export const SelectCustomerOrderPaidStatus: React.FC<{
  value: CustomerOrderPaidStatus;
  onChange: (opt: CustomerOrderPaidStatus) => void;
  placeholder?: string;
  label?: string;
}> = ({ value, onChange, label, placeholder = "Select Status..." }) => {
  return (
    <div className="w-full">
      {label && <label className="text-xs">{label}</label>}
      <Select
        placeholder={placeholder}
        isClearable
        value={getCustomerOrderPaidStatusOption(value)}
        options={options.map(getCustomerOrderPaidStatusOption)}
        onChange={(opt: { label: string; value: CustomerOrderPaidStatus }) => onChange(opt?.value)}
      />
    </div>
  );
};
