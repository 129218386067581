import React, { useEffect, useState, useMemo } from "react";
import {
  MeDocument,
  useUpdateAccountMutation,
  GetOrganizationDocument,
  Location,
  useGetPurchaseOrderLazyQuery,
  useGetLocationLazyQuery,
  useUpdatePurchaseOrderMutation,
  GetPurchaseOrderDocument,
  PurchaseOrderStatus,
  useGetOrganizationQuery,
} from "generated/graphql";
import { ButtonLoading } from "components/Loading";
import { useTable } from "../context";
import { ScrollableForm } from "components/Form/ScrollableForm";
import { Controller, useForm } from "react-hook-form";
import TextInput from "components/Form/TextInput";
import TextAreaInput from "components/Form/TextAreaInput";
import { SelectLocation } from "components/Select/Location";
import NumberInput from "components/Form/NumberInput";
import FileInput from "components/Form/FileInput";
import CurrencyInput from "components/Form/CurrencyInput";
import DateInput from "components/Form/DateInput";
import { PhoneNumber } from "components/Form/PhoneInput";
import { SideModal } from "components/Modals/SideModal";
import { notEmpty } from "utils/filters";
import { EditLocation } from "./EditLocation";
import { EditVendor } from "./EditVendor";
import { useToast } from "hooks/toast";
import { useAttachment } from "hooks/upload";
import { SubHeading, Table } from "styles";
import { SelectPurchaseOrderStatus } from "components/Select/PurchaseOrderStatus";
import Dinero from "dinero.js";
import { AttachmentResourceType } from "generated/graphql";
import { toDate } from "utils/formatters";
import { useFieldArray } from "react-hook-form";
import Checkbox from "components/Form/Checkbox";
import { Address } from "components/Address";
import { Attachments } from "components/Attachments";
import { AddButton } from "components/Buttons";
interface IEditPurchaseOrderForm {
  companyName: string;
  shippingLocation: Location;
  shippingDeliveryDate: Date;
  shippingTerms: string;
  shippingMethod: string;
  note: string;
  priceDiscountPercent: number;
  priceSalesTaxPercent: number;
  priceOtherCost: number;
  shippingAndHandling: number;

  status: PurchaseOrderStatus;
  attachments: { value: { name: string; file: FileList } }[];

  setBusinessName: boolean;
}

export const Edit = () => {
  const toast = useToast();
  const { isEditing, setIsEditing } = useTable();

  const addAttachment = useAttachment();
  const [updateAccount] = useUpdateAccountMutation({
    refetchQueries: [{ query: MeDocument }, { query: GetOrganizationDocument }],
  });
  const [fetch, { data, loading, variables }] = useGetPurchaseOrderLazyQuery();
  const [fetchLocation, fetchLocationQuery] = useGetLocationLazyQuery();
  const [update] = useUpdatePurchaseOrderMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GetPurchaseOrderDocument, variables }],
  });

  const [editVendor, seteditVendor] = useState(false);
  const [editLocation, seteditLocation] = useState(false);

  const orgQuery = useGetOrganizationQuery();

  const { handleSubmit, formState, control, register, watch, reset } = useForm<IEditPurchaseOrderForm>({
    defaultValues: {
      shippingAndHandling: 0,
    },
  });
  const attachmentFields = useFieldArray({
    control,
    name: "attachments",
  });
  const formValues = watch();
  const purchaseOrder = data?.purchaseOrder;

  const onSubmit = async (formData: IEditPurchaseOrderForm) => {
    const purchaseOrder = data?.purchaseOrder;
    try {
      for (const attachment of formData?.attachments ?? []) {
        await addAttachment(attachment.value.file.item(0), {
          fileName: attachment.value.name,
          resourceId: purchaseOrder.id,
          resourceType: AttachmentResourceType.PurchaseOrder,
          onProgress: (e) => console.log(e),
        });
      }
      await update({
        variables: {
          input: {
            id: purchaseOrder?.id,
            status: formData.status,
            companyName: formData?.companyName ?? null,
            shippingLocationId: formData?.shippingLocation?.id ?? null,
            shippingDeliveryDate: formData?.shippingDeliveryDate?.toISOString() ?? null,
            shippingTerms: formData?.shippingTerms ?? null,
            shippingMethod: formData?.shippingMethod ?? null,
            priceDiscountPercent: formData?.priceDiscountPercent ?? null,
            priceSalesTaxPercent: formData?.priceSalesTaxPercent ?? null,
            priceOtherCost: formData?.priceOtherCost ?? null,
            shippingAndHandling: formData?.shippingAndHandling ?? null,
            note: formData?.note ?? null,
          },
        },
      });

      if (formData.setBusinessName && formData?.companyName) {
        await updateAccount({
          variables: {
            input: {
              businessName: formData?.companyName,
            },
          },
        });
      }
      toast.success("Updated purchase order");
      setIsEditing(null);
    } catch (err) {
      toast.error("Unable to update purchase order");
    }
  };

  useEffect(() => {
    if (notEmpty(isEditing)) {
      fetch({ variables: { id: isEditing.id } });
    }
  }, [fetch, isEditing]);

  useEffect(() => {
    if (notEmpty(formValues?.shippingLocation?.id)) {
      fetchLocation({ variables: { id: formValues.shippingLocation.id } });
    }
  }, [fetchLocation, formValues?.shippingLocation?.id]);

  useEffect(() => {
    if (data) {
      reset({
        companyName: (data?.purchaseOrder?.companyName || orgQuery?.data?.organization?.businessName) ?? null,
        shippingLocation: data?.purchaseOrder?.shippingLocation ?? null,
        shippingDeliveryDate: toDate(data?.purchaseOrder?.shippingDeliveryDate) ?? null,
        shippingTerms: data?.purchaseOrder?.shippingTerms ?? null,
        shippingMethod: data?.purchaseOrder?.shippingMethod ?? null,
        priceDiscountPercent: data?.purchaseOrder?.priceDiscountPercent ?? null,
        priceSalesTaxPercent: data?.purchaseOrder?.priceSalesTaxPercent ?? null,
        priceOtherCost: data?.purchaseOrder?.priceOtherCost ?? null,
        shippingAndHandling: data?.purchaseOrder?.shippingAndHandling ?? null,
        note: data?.purchaseOrder?.note ?? null,
        status: data?.purchaseOrder?.status ?? null,
      });
    }
  }, [data, orgQuery?.data, reset]);

  const prices = useMemo(() => {
    const subtotal =
      data?.purchaseOrder?.items?.reduce((total, item) => {
        const lineItemTotal = Dinero({
          amount: Math.round(item.pricePerUnit * 100),
        }).multiply(item.quantity);

        return total.add(lineItemTotal);
      }, Dinero({ amount: 0 })) ?? Dinero({ amount: 0 });
    const discount = subtotal.percentage(formValues?.priceDiscountPercent ?? 0);

    const other = Dinero({ amount: Math.round((formValues?.priceOtherCost ?? 0) * 100) });
    const shippingAndHandling = Dinero({ amount: Math.round((formValues?.shippingAndHandling ?? 0) * 100) });
    const salesTax = subtotal.subtract(discount).percentage(formValues?.priceSalesTaxPercent ?? 0);

    const total = subtotal.subtract(discount).add(salesTax).add(shippingAndHandling).add(other);

    return {
      discount: discount.toFormat("$0,0.00"),
      salesTax: salesTax.toFormat("$0,0.00"),
      subtotal: subtotal.toFormat("$0,0.00"),
      other: other.toFormat("$0,0.00"),
      total: total.toFormat("$0,0.00"),
      shippingAndHandling: shippingAndHandling.toFormat("$0,0.00"),
    };
  }, [formValues, data?.purchaseOrder?.items]);

  return (
    <>
      <ScrollableForm
        title={`Edit Purchase Order: #${isEditing?.number}`}
        onCancel={() => setIsEditing(null)}
        onSubmit={handleSubmit(onSubmit)}
        formState={formState}
        submitLabel="Save"
        classNames={{ buttonContainer: "w-full lg:w-1/3" }}
      >
        <SubHeading>Status</SubHeading>
        <Controller
          control={control}
          name="status"
          defaultValue={data?.purchaseOrder?.status ?? PurchaseOrderStatus.Pending}
          render={({ field }) => (
            <SelectPurchaseOrderStatus
              onChange={(opt) => {
                field.onChange(opt);
              }}
              value={field.value as PurchaseOrderStatus}
            />
          )}
        />

        <SubHeading>Your company</SubHeading>
        <TextInput
          label="Name"
          {...register("companyName")}
          placeholder={`ie. ${orgQuery?.data?.organization?.name}`}
        />
        <Controller
          control={control}
          name="setBusinessName"
          defaultValue={false}
          render={({ field }) => (
            <Checkbox
              className="text-sm"
              label="Save as default"
              checked={field.value}
              onChange={(e) => field.onChange(e.checked)}
            />
          )}
        />

        <SubHeading>Shipping Info</SubHeading>
        <Controller
          control={control}
          name="shippingLocation"
          render={({ field }) => <SelectLocation label="Ship To" onChange={field.onChange} value={field.value} />}
        />

        <div className="py-2">
          <div className="font-semibold text-sm">
            Address
            <span className="px-4">
              <button onClick={() => seteditLocation(true)} type="button" className="text-blue-400 text-xs">
                Edit...
              </button>
            </span>
          </div>
          <Address data={fetchLocationQuery?.data?.location} />
        </div>

        <Controller
          control={control}
          name="shippingDeliveryDate"
          render={({ field }) => (
            <DateInput
              label="Delivery Date"
              placeholderText="Select a date"
              selected={field.value}
              onChange={field.onChange}
              isClearable
            />
          )}
        />

        <TextInput label="Shipping Terms" {...register("shippingTerms")} />

        <TextInput label="Shipping Method" {...register("shippingMethod")} />

        <SubHeading>
          Vendor Info{" "}
          <span className="px-4">
            <button onClick={() => seteditVendor(true)} type="button" className="text-blue-400 text-xs">
              Edit...
            </button>
          </span>
        </SubHeading>

        <div className="font-semibold text-base">{purchaseOrder?.vendor?.name}</div>

        <div className="grid grid-cols-2">
          <div>
            <div className="font-semibold text-sm">Contact</div>
            <div className="text-xs">
              <div>{`${purchaseOrder?.vendor?.contactFirstName} ${purchaseOrder?.vendor?.contactLastName}`}</div>

              <PhoneNumber value={purchaseOrder?.vendor?.contactPhoneOffice} />
              <PhoneNumber value={purchaseOrder?.vendor?.contactPhoneMobile} />

              <div>{purchaseOrder?.vendor?.contactEmail}</div>
            </div>
          </div>
          <div>
            <div className="font-semibold text-sm">Address</div>
            <Address data={purchaseOrder?.vendor} />
          </div>
        </div>

        <SubHeading>Price Info</SubHeading>
        <Controller
          control={control}
          name="priceDiscountPercent"
          render={({ field }) => (
            <NumberInput
              label="Discount %"
              value={field.value}
              onValueChange={(v) => field.onChange(field.value === 0 ? (v?.floatValue ?? 0) / 10 : v?.floatValue ?? 0)}
              allowNegative={false}
              thousandSeparator
              decimalScale={2}
              allowLeadingZeros={false}
              placeholder="0"
            />
          )}
        />
        <Controller
          control={control}
          name="priceSalesTaxPercent"
          render={({ field }) => (
            <NumberInput
              label="Sales Tax %"
              value={field.value}
              onValueChange={(v) => field.onChange(field.value === 0 ? (v?.floatValue ?? 0) / 10 : v?.floatValue ?? 0)}
              allowNegative={false}
              thousandSeparator
              decimalScale={2}
              allowLeadingZeros={false}
              placeholder="0"
            />
          )}
        />
        <Controller
          control={control}
          name="priceOtherCost"
          render={({ field }) => (
            <CurrencyInput label="Other Cost" value={field.value} onValueChange={field.onChange} placeholder="0" />
          )}
        />
        <Controller
          control={control}
          name="shippingAndHandling"
          render={({ field }) => (
            <CurrencyInput
              label="Shipping and Handling"
              value={field.value}
              onValueChange={field.onChange}
              placeholder="0"
            />
          )}
        />
        <TextAreaInput label="Notes" {...register("note")} placeholder="Enter a note..." />

        {/*  */}

        {loading && <ButtonLoading />}
        <SubHeading>Items</SubHeading>
        <Table>
          <thead>
            <tr>
              <th>Item</th>
              <th>Units</th>
              <th>Vendor Item</th>
              <th>Quantity</th>
              <th>Price Per Unit</th>
            </tr>
          </thead>
          <tbody>
            {data?.purchaseOrder?.items?.map((item, index) => {
              return (
                <tr key={`item-${index}`}>
                  <td>{item?.inventoryItem?.name}</td>
                  <td>{item?.inventoryItem?.units}</td>
                  <td>{item?.vendorProduct?.name}</td>
                  <td>{item?.quantity}</td>
                  <td className="text-right">${item?.pricePerUnit}</td>
                </tr>
              );
            })}
          </tbody>
          <tfoot className="border-t  border-gray-400 dark:border-gray-600">
            <tr>
              <td colSpan={4} className="text-right text-sm pt-4">
                SubTotal
              </td>

              <td className="text-right text-sm px-4 pt-4">{prices.subtotal}</td>
            </tr>
            <tr>
              <td colSpan={4} className="text-right text-sm">
                Discount
              </td>
              <td className="text-right text-sm px-4">{prices.discount}</td>
            </tr>
            <tr>
              <td colSpan={4} className="text-right text-sm">
                Sales Tax
              </td>
              <td className="text-right text-sm px-4">{prices.salesTax}</td>
            </tr>
            <tr>
              <td colSpan={4} className="text-right text-sm">
                Other
              </td>
              <td className="text-right text-sm px-4">{prices.other}</td>
            </tr>
            <tr>
              <td colSpan={4} className="text-right text-sm">
                Shipping & Handling
              </td>
              <td className="text-right text-sm px-4">{prices.shippingAndHandling}</td>
            </tr>

            <tr>
              <td colSpan={4} className="text-right font-semibold">
                Total
              </td>
              <td className="text-right font-semibold px-4">{prices.total}</td>
            </tr>
          </tfoot>
        </Table>

        <SubHeading>
          Attachments
          <AddButton
            onClick={() =>
              attachmentFields.append({
                value: null,
              })
            }
          />
        </SubHeading>
        <Attachments data={data?.purchaseOrder?.attachments ?? []} />

        {attachmentFields.fields.map((attachmentField, index) => {
          const fieldValue = formValues?.attachments?.[index]?.value;
          return (
            <div className="flex flex-row py-2" key={attachmentField.id}>
              <Controller
                control={control}
                name={`attachments.${index}.value` as const}
                defaultValue={attachmentField?.value}
                rules={{
                  validate: () =>
                    (notEmpty(fieldValue?.file) && notEmpty(fieldValue?.name)) || "File name and file required",
                }}
                render={({ field }) => {
                  const selectedFile = fieldValue?.file?.item(0);
                  return (
                    <>
                      <div className="flex-1">
                        <TextInput
                          type="text"
                          placeholder="File name"
                          value={fieldValue?.name ?? ""}
                          onChange={(e) => {
                            field.onChange({
                              name: e.target.value,
                              file: fieldValue?.file,
                            });
                          }}
                        />
                        {selectedFile && (
                          <div className="text-xs py-1">{`Selected: ${selectedFile?.name ?? "None"} `}</div>
                        )}
                      </div>
                      <FileInput
                        onChange={(value) => {
                          field.onChange({
                            name: value?.[0]?.name ?? "",
                            file: value,
                          });
                        }}
                        onRemove={() => attachmentFields.remove(index)}
                      />
                    </>
                  );
                }}
              />
            </div>
          );
        })}
      </ScrollableForm>
      <SideModal side="RIGHT" isOpen={editVendor} onRequestClose={() => seteditVendor(true)}>
        <EditVendor vendor={purchaseOrder?.vendor} onRequestClose={() => seteditVendor(false)} />
      </SideModal>

      <SideModal side="RIGHT" isOpen={editLocation} onRequestClose={() => seteditLocation(true)}>
        <EditLocation location={fetchLocationQuery?.data?.location} onRequestClose={() => seteditLocation(false)} />
      </SideModal>
    </>
  );
};
