import { Employee } from "generated/graphql";
import React from "react";
import { Item, ItemParams, Menu } from "react-contexify";
import { RowActions, useEmployeesTable, useRowActions } from "./context";

export const MENU_ID = "employee-row-menu";

export const ContextMenu = () => {
  const { selected } = useEmployeesTable();
  const handleAction = useRowActions();
  const handleItemClick: (args: ItemParams<Employee, any>) => void = ({ event, props: item }) => {
    handleAction(event.currentTarget.id as RowActions, item);
  };

  return (
    <Menu id={MENU_ID}>
      {/* <Item id="getEmployeeAppLink" onClick={handleItemClick} hidden={(row) => !row?.props?.phoneNumber}>
        Get AppLink
      </Item> */}
      <Item id="sendAppLink" onClick={handleItemClick} hidden={(row) => !row?.props?.phoneNumber}>
        Send Link
      </Item>

      <Item id="editEmployee" onClick={handleItemClick}>
        Edit
      </Item>

      <Item id="deleteEmployee" onClick={handleItemClick}>
        <div className="text-brand">Delete</div>
      </Item>

      {selected?.size > 0 && (
        <>
          <div
            className="mt-2 w-full cursor-default"
            style={{
              height: "1px",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            }}
          />

          <div className="text-xs text-gray-600 px-3 font-semibold bg-gray-100  py-2">Bulk Actions</div>

          <Item id="deleteEmployees" onClick={handleItemClick}>
            <div className="text-brand">Delete Operators</div>
          </Item>
        </>
      )}
    </Menu>
  );
};
