import {
  actions,
  IChart,
  IConfig,
  IOnDeleteKey,
  IOnDragCanvasStop,
  IOnDragCanvasStopInput,
  IOnLinkClick,
  IOnLinkComplete,
  IOnNodeClick,
  IOnNodeDoubleClick,
  IOnZoomCanvas,
  ISelectedOrHovered,
} from "@mrblenny/react-flow-chart";
import { WorkflowStep, WorkflowVersion } from "generated/graphql";
import { noop } from "lodash";
import React, { createContext, PropsWithChildren, useCallback, useContext, useMemo } from "react";
import { useImmerReducer } from "use-immer";
import { stepsToChartData } from "./types";

const defaultWorkflow: WorkflowVersion = {
  id: "",
  nodes: [],
  edges: [],
};

enum Actions {
  Init = "INIT",
  UpdateChart = "UPDATE_CHART",
  SetSelected = "SET_SELECTED",
  OnDragCanvasStop = "ON_DRAG_CANVAS_STOP",
  OnZoomCanvas = "ON_ZOOM_CANVAS",
  SetZoom = "SET_ZOOM",
}

type Initialize = {
  type: Actions.Init;
  data: WorkflowVersion;
};

type UpdateChart = {
  type: Actions.UpdateChart;
  data: IChart<undefined, WorkflowStep>;
};

type SetSelected = {
  type: Actions.SetSelected;
  data: ISelectedOrHovered;
};

type OnDragCanvasStop = {
  type: Actions.OnDragCanvasStop;
  data: IOnDragCanvasStopInput;
};

type OnZoomCanvas = {
  type: Actions.OnZoomCanvas;
  data: any;
};

type SetZoom = {
  type: Actions.SetZoom;
  data: number;
};

type DispatcherAction = Initialize | UpdateChart | OnDragCanvasStop | OnZoomCanvas | SetSelected | SetZoom;

interface StateIFace {
  chart?: IChart<undefined, WorkflowStep>;
}

const initialState = {
  chart: stepsToChartData(defaultWorkflow),
};

const flowchartCtx = createContext<{
  state: StateIFace;
  config: IConfig;
  dispatch: React.Dispatch<DispatcherAction>;
}>({
  state: initialState,
  config: null,
  dispatch: () => null,
});

export const EventGraphProvider: React.FC<PropsWithChildren<{ config: IConfig }>> = ({ children, config }) => {
  const [state, dispatch] = useImmerReducer((state: StateIFace, action: DispatcherAction) => {
    switch (action.type) {
      case Actions.Init:
        state.chart = {
          ...stepsToChartData(action.data),
          selected: state?.chart?.selected ?? {},
        };
        break;
      case Actions.SetSelected:
        state.chart.selected = action?.data;
        break;
      case Actions.UpdateChart:
        const { selected, scale, ...rest } = action?.data;
        state.chart = { ...state.chart, ...rest };

        break;

      case Actions.OnDragCanvasStop:
        break;
      case Actions.OnZoomCanvas:
        state.chart.scale = action?.data?.data?.scale;

        break;
      case Actions.SetZoom:
        state.chart.scale = action.data;

        break;
      default:
        break;
    }
  }, initialState);

  return <flowchartCtx.Provider value={{ state, config, dispatch }}>{children}</flowchartCtx.Provider>;
};

export const useWorkOrderEventGraph = () => {
  const { state, config, dispatch } = useContext(flowchartCtx);

  const setChartData = (data: WorkflowVersion) => {
    dispatch({ type: Actions.Init, data });
  };

  const a: Record<string, any> = actions;

  const selected = useMemo(() => {
    if (state?.chart?.selected?.type === "node") {
      return state?.chart?.nodes?.[state?.chart?.selected?.id];
    }

    return null;
  }, [state?.chart?.selected, state?.chart?.nodes]);

  const setSelected = useCallback(
    (s: ISelectedOrHovered) => {
      dispatch({ type: Actions.SetSelected, data: s });
    },
    [dispatch]
  );

  const onDeleteKey: IOnDeleteKey = (chart) => {
    noop();
  };

  const onNodeClick: IOnNodeClick = (input) => {
    setSelected({ type: "node", id: input?.nodeId });
  };

  const onLinkClick: IOnLinkClick = (input) => {
    setSelected({ type: "link", id: input?.linkId });
  };

  const onLinkComplete: IOnLinkComplete = (input) => {
    noop();
  };

  const onNodeDoubleClick: IOnNodeDoubleClick = (input) => {
    noop();
  };

  const onDragCanvasStop: IOnDragCanvasStop = (input) => {
    dispatch({ type: Actions.OnDragCanvasStop, data: input });
  };

  const onZoomCanvas: IOnZoomCanvas = (input) => {
    dispatch({ type: Actions.OnZoomCanvas, data: input });
  };

  const setZoom = useCallback(
    (scale: number) => {
      if (scale >= (config?.zoom?.minScale ?? 0.4) && scale <= (config?.zoom?.maxScale ?? 2)) {
        dispatch({ type: Actions.SetZoom, data: scale });
      }
    },
    [config, dispatch]
  );

  // Set up default callbacks
  const callbacks: any = Object.keys(actions).reduce((obj, key) => {
    obj[key] = (...args: any) => {
      const action = a[key];
      const newChartTransformer = action(...args);
      const newChart = newChartTransformer(state.chart);
      dispatch({ type: Actions.UpdateChart, data: newChart });
      return newChart;
    };
    return obj;
  }, {} as Record<string, any>);

  // override defaults
  callbacks["onLinkComplete"] = onLinkComplete;
  callbacks["onDeleteKey"] = onDeleteKey;
  callbacks["onNodeClick"] = onNodeClick;
  callbacks["onNodeDoubleClick"] = onNodeDoubleClick;
  callbacks["onLinkClick"] = onLinkClick;
  callbacks["onDragCanvasStop"] = onDragCanvasStop;
  callbacks["onZoomCanvas"] = onZoomCanvas;

  return {
    config,
    chart: state.chart,
    callbacks,
    setChartData,
    selected,
    setSelected,
    setZoom,
  };
};
