import React, { createContext, useContext } from "react";
import Analytics, { AnalyticsInstance } from "analytics";
import segmentPlugin from "@analytics/segment";

const ctx = createContext<AnalyticsInstance>(null);

export const AnalyticsProvider: React.FC<
  React.PropsWithChildren<{
    app: string;
    writeKey: string;
  }>
> = ({ children, app, writeKey }) => {
  const analytics = Analytics({
    app,
    debug: true,
    plugins: [
      segmentPlugin({
        writeKey,
      }),
    ],
  });

  return <ctx.Provider value={analytics}>{children}</ctx.Provider>;
};

export const useAnalytics = () => {
  return useContext(ctx);
};
