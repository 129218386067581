import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { ButtonWithIcon } from "components/Buttons";
import { BottomModal } from "components/Modals/BottomModal";
import { Confirmation } from "components/Modals/Confirmation";
import TitleWithLoading from "components/TitleWithLoading";
import { useListPurchaseOrdersLazyQuery } from "generated/graphql";
import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { Routes } from "routes";
import { notEmpty } from "utils/filters";
import { TableProvider, useTable } from "./context";
import { useToast } from "hooks/toast";
import { Edit } from "./Edit";
import Table from "./Table";
import { SideModal } from "components/Modals/SideModal";
import { Filters, FiltersHeader } from "./Filters";

const PurchaseOrders = () => {
  const toast = useToast();
  const {
    loading,
    isEditing,
    setIsEditing,
    fetch,
    isDeleting,
    setIsDeleting,
    variables,
    deletePurchaseOrder,
    showFilters,
    setShowFilters,
  } = useTable();
  const onConfirmDelete = useCallback(async () => {
    try {
      await deletePurchaseOrder({
        variables: {
          id: isDeleting?.id,
        },
      });
    } catch (err) {
      toast.error("Unable to cancel purchase order");
    } finally {
      setIsDeleting(null);
    }
  }, [toast, setIsDeleting, deletePurchaseOrder, isDeleting]);

  return (
    <div className="flex-1 flex flex-row">
      <div className="py-2 px-2 flex-1 flex flex-col overflow-hidden">
        <div className="flex justify-between my-2">
          <TitleWithLoading title="Purchase Orders" loading={loading} refresh={() => fetch({ variables })} />

          <Link to={Routes.CreatePurchaseRequest}>
            <ButtonWithIcon className="bg-brand" icon={faPlusCircle}>
              Create Order
            </ButtonWithIcon>
          </Link>
        </div>
        <FiltersHeader />
        <Table />
      </div>

      <BottomModal isOpen={notEmpty(isEditing)} onRequestClose={() => setIsEditing(null)}>
        <Edit />
      </BottomModal>

      <Confirmation
        isOpen={notEmpty(isDeleting)}
        onRequestClose={() => setIsDeleting(null)}
        onConfirm={onConfirmDelete}
      >
        <div className="p-4 text-center text-2xl">
          <div className="font-semibold py-2">Are you sure you'd like to cancel</div>
          <div>Purchase order #{isDeleting?.number}</div>
        </div>
      </Confirmation>

      <SideModal side="RIGHT" isOpen={showFilters} onRequestClose={() => setShowFilters(false)}>
        <Filters />
      </SideModal>
    </div>
  );
};

const Container = () => {
  const query = useListPurchaseOrdersLazyQuery({
    fetchPolicy: "network-only",
  });
  return (
    <TableProvider query={query} sessionKey="@factoryfinch/purchaseOrders">
      <PurchaseOrders />
    </TableProvider>
  );
};

export { Container as default };
