import React from "react";
import Checkbox from "components/Form/Checkbox";
import { ScrollableForm } from "components/Form/ScrollableForm";
import { TagInput } from "components/Form/TagInput";
import { SelectCustomer } from "components/Select/Customer";
import Filter from "components/Table/Filter";
import { MultiTagInputWSuggest } from "components/Form/TagInput";
import { Controller, useForm } from "react-hook-form";
import { SubHeading } from "styles";
import { IFilters, useTable } from "./context";
import { removeAtIndex } from "utils/filters";
import { QuickFilters } from "./QuickFilters";
import {
  SelectCustomerOrderFulfillmentStatuses,
  getCustomerOrderFulfillmentStatusLabel,
} from "components/Select/SalesOrder/SalesOrderFulfillmentStatus";
import {
  SelectCustomerOrderPickStatuses,
  getCustomerOrderPickStatusLabel,
} from "components/Select/SalesOrder/SalesOrderPickStatus";
import {
  SelectCustomerOrderPaidStatuses,
  getCustomerOrderPaidStatusLabel,
} from "components/Select/SalesOrder/SalesOrderPaidStatus";

const defaultValues: IFilters = {
  customer: null,
  showDeleted: false,

  pickStatus: [],
  paidStatus: [],
  fulfillmentStatus: [],
  orderNumbers: [],
  onlyPickable: false,
  tags: [],
};

const FiltersHeader = () => {
  const { filters, removeFilter } = useTable();

  return (
    <div>
      <QuickFilters />
      <div className="flex flex-wrap">
        {filters?.showDeleted && (
          <Filter title="Show Deleted" onRemove={() => removeFilter("showDeleted")} className="bg-brand" />
        )}

        {filters?.customer && (
          <Filter
            title={`Customer: ${filters?.customer?.firstName} ${filters?.customer?.lastName}`}
            onRemove={() => removeFilter("customer")}
            className="bg-blue-500"
          />
        )}

        {filters?.orderNumbers?.length > 0 && (
          <Filter
            title={`Order #: ${filters?.orderNumbers?.join(", ")}`}
            onRemove={() => removeFilter("orderNumbers")}
            className="bg-blue-600"
          />
        )}

        {filters?.onlyPickable && (
          <Filter title="Show Pickable" onRemove={() => removeFilter("onlyPickable")} className="bg-gray-500" />
        )}

        {filters?.pickStatus?.map((s, i) => {
          return (
            <Filter
              key={s}
              title={`Pick Status: ${getCustomerOrderPickStatusLabel(s)}`}
              onRemove={() => removeFilter("pickStatus", i)}
              className="bg-gray-600"
            />
          );
        })}

        {filters?.fulfillmentStatus?.map((s, i) => {
          return (
            <Filter
              key={s}
              title={`Fulfillment Status: ${getCustomerOrderFulfillmentStatusLabel(s)}`}
              onRemove={() => removeFilter("fulfillmentStatus", i)}
              className="bg-gray-600"
            />
          );
        })}

        {filters?.paidStatus?.map((s, i) => {
          return (
            <Filter
              key={s}
              title={`Paid Status: ${getCustomerOrderPaidStatusLabel(s)}`}
              onRemove={() => removeFilter("paidStatus", i)}
              className="bg-gray-600"
            />
          );
        })}

        {filters?.tags?.map((t, i) => {
          return (
            <Filter
              key={t?.tag?.id}
              title={`Tag: ${t?.value ? `${t?.tag?.name}=${t?.value}` : t?.tag?.name}`}
              onRemove={() => removeFilter("tags", i)}
              className="bg-green-500"
            />
          );
        })}
      </div>
    </div>
  );
};

const Filters = () => {
  const { setFilters, filters, setShowFilters } = useTable();
  const { control, handleSubmit, setValue, watch } = useForm<IFilters>({
    defaultValues: {
      ...defaultValues,
      ...filters,
    },
  });
  const tags = watch("tags");

  const onSubmit = (formData: IFilters) => {
    setFilters(formData);
    setShowFilters(false);
  };

  return (
    <ScrollableForm
      title="Filters"
      submitLabel="Apply Filters"
      onSubmit={handleSubmit(onSubmit)}
      onCancel={() => setShowFilters(false)}
    >
      <SubHeading>Order #s</SubHeading>
      <Controller
        name="orderNumbers"
        control={control}
        render={({ field }) => <TagInput tags={field.value} onChange={field.onChange} removeOnBackspace={true} />}
      />

      <SubHeading>Customer</SubHeading>
      <Controller
        name="customer"
        control={control}
        render={({ field }) => <SelectCustomer value={field.value} onChange={field.onChange} />}
      />

      <SubHeading>Pick Status</SubHeading>
      <Controller
        name="pickStatus"
        control={control}
        render={({ field }) => <SelectCustomerOrderPickStatuses value={field.value} onChange={field.onChange} />}
      />

      <SubHeading>Paid Status</SubHeading>
      <Controller
        name="paidStatus"
        control={control}
        render={({ field }) => <SelectCustomerOrderPaidStatuses value={field.value} onChange={field.onChange} />}
      />

      <SubHeading>Fulfillment Status</SubHeading>
      <Controller
        name="fulfillmentStatus"
        control={control}
        render={({ field }) => <SelectCustomerOrderFulfillmentStatuses value={field.value} onChange={field.onChange} />}
      />

      <SubHeading>Tags</SubHeading>
      <Controller
        name="tags"
        control={control}
        render={({ field }) => {
          return (
            <MultiTagInputWSuggest
              value={field.value}
              onChange={field.onChange}
              allowCreate
              onRemove={(v, i) => setValue("tags", removeAtIndex(tags, i))}
            />
          );
        }}
      />

      <Controller
        name="onlyPickable"
        control={control}
        render={({ field }) => (
          <Checkbox onChange={(e) => field.onChange(e.checked)} checked={field.value} label="Only show pickable" />
        )}
      />

      <Controller
        name="showDeleted"
        control={control}
        render={({ field }) => (
          <Checkbox onChange={(e) => field.onChange(e.checked)} checked={field.value} label="Show Deleted" />
        )}
      />
    </ScrollableForm>
  );
};

export { Filters as default, FiltersHeader };
