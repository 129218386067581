import React from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import styled from "styled-components";
import tw from "twin.macro";

const DateInput = ({ label, className, ...rest }: ReactDatePickerProps & { label?: string }) => {
  if (!label) {
    return (
      <div className={className}>
        <DatePicker id={rest.name} {...rest} wrapperClassName="date-input-wrapper" />
      </div>
    );
  }

  return (
    <div className={className}>
      <label className="text-xs mb-2" htmlFor={rest?.name}>
        {label}
      </label>
      <DatePicker id={rest?.name} {...rest} wrapperClassName="date-input-wrapper" popperPlacement="bottom" />
    </div>
  );
};

const StyledInput = styled(DateInput)`
  ${tw`flex flex-col`}
  .date-input-wrapper {
    input {
      ${tw`w-full bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-400 rounded py-2 px-4 block w-full appearance-none leading-normal focus:border-blue-400 focus:outline-none focus:shadow-none text-lg`}
    }
    .react-datepicker__close-icon {
      ${tw`focus:outline-none `}
      &::after {
        ${tw`bg-transparent text-default dark:text-white text-lg `}
      }
    }
  }

  &.error {
    ${tw`border border-brand`}
  }

  &:focus {
    ${tw`outline-none shadow-none`}
  }

  &:disabled {
    ${tw`opacity-75 cursor-not-allowed`}
  }
`;
export { StyledInput as default };
