import React, { useState } from "react";
import { H3 } from "styles";
import { DateRangeReport, NoInputReport } from "./ReportTypes";

export const Reports: React.FC = () => {
  const [report, setreport] = useState<string | null>(null);

  return (
    <>
      <div className="px-2">
        <H3 className="mb-4">Reports</H3>

        <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-2 gap-y-2 cursor-pointer">
          <DateRangeReport
            title="Employee Productivity"
            subtitle="Export production data per employee over a date range."
            reportName="EmployeeProductivityReport"
            isOpen={report === "EmployeeProductivityReport"}
            onClick={() => {
              setreport("EmployeeProductivityReport");
            }}
            onRequestClose={() => setreport(null)}
          />

          <NoInputReport
            title="Production Forecast"
            subtitle="Some description goes here"
            reportName="ProductionForecastReport"
            isOpen={report === "ProductionForecastReport"}
            onClick={() => {
              setreport("ProductionForecastReport");
            }}
            onRequestClose={() => setreport(null)}
          />

          <DateRangeReport
            title="Throughput"
            subtitle="Some description goes here"
            reportName="ProductionThroughputReport"
            isOpen={report === "ProductionThroughputReport"}
            onClick={() => {
              setreport("ProductionThroughputReport");
            }}
            onRequestClose={() => setreport(null)}
          />
        </div>
      </div>
    </>
  );
};
