import React from "react";
import { HR, H4 } from "styles";
import { useLocationsTable } from "./context";
import { useToast } from "hooks/toast";
import { Confirmation } from "components/Modals/Confirmation";

const DeleteLocation = () => {
  const { isDeleting, setIsDeleting, loading, deleteLocation } = useLocationsTable();

  const toast = useToast();
  const location = isDeleting;
  return (
    <Confirmation
      loading={loading}
      isOpen={Boolean(isDeleting)}
      onRequestClose={() => setIsDeleting(null)}
      onConfirm={async () => {
        try {
          await deleteLocation({
            variables: { id: isDeleting?.id },
          });
        } catch (err) {
          console.log(err);
          toast.error("Unable to delete locations");
        } finally {
          setIsDeleting(null);
        }
      }}
    >
      <div className="my-4">
        <H4 className="py-4">Remove location?</H4>
        <HR />

        <div className="text-center text-lg">{location?.name}</div>
      </div>
    </Confirmation>
  );
};

export { DeleteLocation as default };
