import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyledDateRangePicker } from "components/Form/DateRangePicker";
import { CenterModal } from "components/Modals/CenterModal";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { H4, IconButton, PlainButton } from "styles";

export interface DateRange {
  from: Date;
  to: Date;
}

export const SelectDateRange: React.FC<{ label?: string; value: DateRange; onChange: (v: DateRange) => void }> = ({
  label,
  value,
  onChange,
}) => {
  const [range, setrange] = useState<{
    startDate?: Date;
    endDate?: Date;
    key?: string;
  }>({
    startDate: value?.from ?? new Date(),
    endDate: value?.to ?? new Date(),
    key: "selection",
  });

  const [open, setopen] = useState(false);
  const fromLabel = value?.from ? DateTime.fromJSDate(value?.from).toISODate() : "";
  const toLabel = value?.to ? DateTime.fromJSDate(value?.to).toISODate() : "";
  const dateLabel = fromLabel === "" && toLabel === "" ? "" : `Between ${fromLabel} and ${toLabel}`;

  const handleClear = () => {
    setopen(false);
    onChange({
      from: null,
      to: null,
    });
  };

  const handleChange = () => {
    setopen(false);
    onChange({
      from: range?.startDate,
      to: range?.endDate,
    });
  };

  return (
    <>
      <label className="text-xs">{label}</label>
      <div className="flex items-center bg-white dark:bg-gray-800 rounded w-full appearance-none leading-normal border border-gray-300 dark:border-gray-400 active:border-blue-400 focus:outline-none focus:shadow-none">
        <input
          className="placeholder-gray-400 dark:placeholder-gray-600 text-default dark:text-white bg-transparent rounded-none appearance-none leading-normal px-2 border border-transparent focus:outline-none focus:shadow-none w-full cursor-pointer"
          placeholder="Select a date range"
          readOnly
          onClick={() => setopen(true)}
          value={dateLabel}
        />
        <IconButton type="button" onClick={handleClear}>
          <FontAwesomeIcon icon={faTimes} />
        </IconButton>
      </div>
      <CenterModal isOpen={open} onRequestClose={() => setopen(false)}>
        <H4>Download Employee Productivity Report</H4>

        <div className="text-center my-4">Select a date range</div>

        <div className="flex justify-center">
          <StyledDateRangePicker
            ranges={[range]}
            onChange={(ranges) => setrange(ranges["selection"])}
            rangeColors={["#d92452"]}
            color="#d92452"
            maxDate={new Date()}
            months={1}
          />
        </div>
        <div className="flex flex-row justify-end">
          <PlainButton type="button" onClick={handleClear}>
            Clear
          </PlainButton>
          <PlainButton type="button" onClick={handleChange}>
            OK
          </PlainButton>
        </div>
      </CenterModal>
    </>
  );
};
