export enum Routes {
  PricingCalculator = "/pricing-calculator",
  Login = "/login",
  MagicLogin = "/magic-login",
  TokenLogin = "/tokenLogin",
  SignUp = "/sign-up",
  ForgotPassword = "/forgot-password",
  ResetPassword = "/reset-password",
  VerifyUser = "/verify",
  Invitation = "/invitation",
  Root = "/p/",

  BirdsEyeView = "/p/birds-eye-view",
  AnalyticsManufacturing = "/p/birds-eye-view/manufacturing",
  AnalyticsReports = "/p/birds-eye-view/reports",

  Production = "/p/production",
  Steps = "/p/production/steps",
  Workflows = "/p/production/workflows",
  Workflow = "/p/production/workflows/:id",
  CreateWorkflow = "/p/production/workflows/new",
  WorkOrders = "/p/production/orders",
  WorkOrder = "/p/production/orders/:id",
  Inventory = "/p/production/inventory",
  InventoryItem = "/p/production/products/:id",
  InventoryTransfers = "/p/production/inventory-transfers",
  CreateInventoryTransfer = "/p/production/inventory-transfers/new",

  Locations = "/p/production/locations",

  Fulfillment = "/p/fulfillment",
  Customers = "/p/fulfillment/customers",
  Customer = "/p/fulfillment/customers/:id",
  CustomerOrders = "/p/fulfillment/customer-orders",
  SalesOrderInvoices = "/p/fulfillment/customer-orders/invoices",
  CustomerOrder = "/p/fulfillment/customer-orders/:id",
  CreateCustomerOrder = "/p/fulfillment/customer-orders/new",

  Purchasing = "/p/purchasing",
  PurchasingInventory = "/p/purchasing/inventory",
  PurchasingLocations = "/p/purchasing/locations",
  PurchaseOrders = "/p/purchasing/purchase-orders",
  PurchaseRequests = "/p/purchasing/purchase-requests",

  CreatePurchaseRequest = "/p/purchasing/purchase-request/new",
  Vendors = "/p/purchasing/vendors",

  Team = `/p/team`,
  Admins = `/p/team/admins`,
  Employees = `/p/team/employees`,

  GettingStarted = "/p/support/getting-started",
  Settings = "/p/settings",
  Support = "/p/settings/support",
  MyAccount = "/p/settings/myaccount",
  Integrations = "/p/settings/integrations",
  Subscription = "/p/settings/subscription",

  PaymentInfo = "/p/payment-info",
}

export const getRouteWithParams = (route: Routes, params: Record<string, string>) => {
  return Object.keys(params).reduce((r, key) => {
    return r.replace(`:${key}`, params[key]);
  }, route);
};
