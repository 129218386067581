import React from "react";
import Users from "./Users";
import Invitations from "./Invitations";

const Admins: React.FC = () => {
  return (
    <div className="py-2 px-2 w-full">
      <div className="flex flex-wrap overflow-hidden lg:-mx-1 xl:-mx-1">
        <div className="w-full overflow-hidden lg:my-1 lg:px-4 lg:w-1/2 xl:my-1 xl:px-4 xl:w-1/2">
          <Users />
        </div>

        <div className="w-full overflow-hidden lg:my-1 lg:px-4 lg:w-1/2 xl:my-1 xl:px-4 xl:w-1/2">
          <Invitations />
        </div>
      </div>
    </div>
  );
};

export { Admins as default };
