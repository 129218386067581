import React, { useRef, useState } from "react";
import NumberFormat from "react-number-format";
import styled from "styled-components";
import tw from "twin.macro";

const StyledDiv = styled.div<{ $isFocussed: boolean; $hasError: boolean }>`
  ${(props) => (props.$isFocussed ? tw`border-blue-400` : tw`border-gray-300 dark:border-gray-400`)}
  ${(props) => props.$hasError && tw`border-red-400`}
  ${tw`bg-white dark:bg-gray-800 border rounded py-2 px-4 flex w-full appearance-none leading-normal  focus:outline-none focus:shadow-none text-lg`}
`;

const InputWithUnits: React.FC<{
  units: string;
  hasError?: boolean;
  value: number;
  placeholder: string;
  onChange: (value: number) => void;
}> = ({ units, value, onChange, hasError, placeholder = "0" }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocussed, setisFocussed] = useState(false);
  const onFocus = () => {
    inputRef?.current?.select();
    setisFocussed(true);
  };
  return (
    <StyledDiv onClick={onFocus} $isFocussed={isFocussed} $hasError={hasError}>
      <NumberFormat
        className="flex-1 text-right focus:outline-none dark:bg-gray-800 dark:text-white w-full"
        getInputRef={inputRef}
        placeholder={placeholder}
        value={value}
        onValueChange={(v) => onChange(v?.floatValue)}
        autoComplete="off"
        data-lpignore="true"
        onBlur={() => setisFocussed(false)}
      />
      <div className="pl-4 text-center lowercase">{units}</div>
    </StyledDiv>
  );
};

export { InputWithUnits as default };
