import { ScrollableForm } from "components/Form/ScrollableForm";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { IFilters, useTable } from "./context";
import { QuickFilters } from "./QuickFilters";
import { Customer } from "generated/graphql";
import { SelectCustomer } from "components/Select/Customer";
import Filter from "components/Table/Filter";

const defaultValues: IFilters = {
  customers: [],
  createdAt: {
    from: null,
    to: null,
  },
};

const FiltersHeader = () => {
  const { filters, removeFilter } = useTable();

  return (
    <div>
      <QuickFilters />
      <div className="flex flex-wrap">
        {filters?.customers?.map((customer, i) => (
          <Filter
            key={customer?.id}
            title={`Customer: ${customer?.firstName} ${customer?.lastName}`}
            onRemove={() => removeFilter("customers", i)}
            className="bg-blue-500"
          />
        ))}
      </div>
    </div>
  );
};

const Filters = () => {
  const { setFilters, filters, setShowFilters } = useTable();
  const { handleSubmit, control } = useForm<IFilters>({
    defaultValues: {
      ...defaultValues,
      ...filters,
    },
  });

  const onSubmit = (formData: IFilters) => {
    setFilters(formData);
    setShowFilters(false);
  };

  return (
    <ScrollableForm
      title="Filters"
      submitLabel="Apply Filters"
      onSubmit={handleSubmit(onSubmit)}
      onCancel={() => setShowFilters(false)}
    >
      <Controller
        control={control}
        name="customers"
        render={({ field }) => (
          <SelectCustomer value={field.value} onChange={(values: Customer[]) => field.onChange(values)} isMulti />
        )}
      />
    </ScrollableForm>
  );
};

export { Filters as default, FiltersHeader };
