import { PurchaseOrder, Location } from "generated/graphql";
import { PDFDocument, TextAlignment, rgb } from "pdf-lib";
import { generateQRCodeImages } from "utils/qrcode";
import Dinero from "dinero.js";

interface IGeneratePDF {
  purchaseOrder: PurchaseOrder;
  location?: Location;
  companyName?: string;
  vendorContactName?: string;
  vendorCompanyName?: string;
  vendorAddress?: string;
  vendorPhoneNumber?: string;
  vendorEmail?: string;
  shippingTerms?: string;
  shippingMethod?: string;
  shippingDeliveryDate?: string;
  priceOther?: number;
  priceDiscountPercent?: number;
  priceSalesTaxPercent?: number;
  shippingAndHandling?: number;
  note?: string;
}

// TODO: DEFUNCT MOVE TO new pdf library SEE components/Views/CustomerOrder/CustomerOrder/SalesOrder.pdf.tsx
export const generatePDF = async (fields: IGeneratePDF) => {
  const formUrl = "/template.pdf";
  const formPdfBytes = await fetch(formUrl, {
    headers: {
      "Content-Type": "application/pdf",
      Accept: "application/pdf",
    },
  }).then((res) => res.arrayBuffer());
  const qrcodes = await generateQRCodeImages([fields?.purchaseOrder?.id]);

  const pdfDoc = await PDFDocument.load(formPdfBytes);
  pdfDoc.setTitle(`${fields?.vendorCompanyName} Purchase Order`);

  const jpgImage = await pdfDoc.embedJpg(qrcodes[0]);
  const page = pdfDoc.getPage(0);
  const form = pdfDoc.getForm();

  const companyName = form.getTextField("company.name");
  const companyContactInfo = form.getTextField("company.contactInfo");
  const poNumber = form.getTextField("poNumber");
  const date = form.getTextField("date");
  const vendorContactName = form.getTextField("vendor.contactName");
  const vendorCompanyName = form.getTextField("vendor.companyName");
  const vendorAddress = form.getTextField("vendor.address");
  const vendorPhoneNumber = form.getTextField("vendor.phoneNumber");
  const vendorEmail = form.getTextField("vendor.email");
  const shippingTerms = form.getTextField("shipping.terms");
  const shippingMethod = form.getTextField("shipping.method");
  const shippingDeliveryDate = form.getTextField("shipping.deliveryDate");
  const priceSubtotal = form.getTextField("price.subtotal");
  const priceTotal = form.getTextField("price.total");
  const priceShipping = form.getTextField("price.shipping");
  const priceOther = form.getTextField("price.other");
  const priceDiscountTotal = form.getTextField("price.discount.total");
  const priceDiscountPercent = form.getTextField("price.discount.percent");
  const priceSalesTaxTotal = form.getTextField("price.salesTax.total");
  const priceSalesTaxPercent = form.getTextField("price.salesTax.percent");
  const note = form.getTextField("note");

  const lineItems = fields?.purchaseOrder?.items?.map((li) => {
    return {
      code: li?.vendorProduct?.name ?? "",
      description: "",
      quantity: li?.quantity ?? 0,
      unitPrice: li?.pricePerUnit ?? 0, // in dollars
    };
  });

  const subTotal = lineItems.reduce((acc, lineItem) => {
    const amount = Dinero({
      amount: Math.round(lineItem.unitPrice * 100),
    }).multiply(lineItem.quantity);
    return acc.add(amount);
  }, Dinero({ amount: 0 }));

  const shippingAndHandling = Dinero({
    amount: Math.round((fields?.shippingAndHandling ?? 0) * 100),
  });
  const discountAmount = subTotal.percentage(fields?.priceDiscountPercent ?? 0);
  const salesTaxAmount = subTotal.percentage(fields?.priceSalesTaxPercent ?? 0);
  const total = subTotal.subtract(discountAmount).add(salesTaxAmount).add(shippingAndHandling);

  const leftMargin = 89;
  lineItems.forEach((lineItem, i) => {
    const height = 17;
    const y = 377 - i * height;
    const $amount = Dinero({
      amount: Math.round(lineItem.unitPrice * 100),
    }).multiply(lineItem.quantity);
    // TODO: DO THIS DYNAMICALLY
    // const fields = [
    //   { name: "code", width: 58, textAlignment: TextAlignment.Left },
    // ];

    // let fieldLeftMargin = leftMargin
    // fields?.forEach((field,k) => {
    // for (let k = 0; k < fields?.length; k++) {
    //   const field = fields[k]
    //       const textField = form.createTextField(`lineItems.${i}.${field.name}`);

    //       textField.setText(lineItem?.[field.name])
    //       const x = leftMargin
    //       textField.addToPage(page, {
    //         x: leftMargin,
    //         y: y,
    //         width: 58,
    //         height,
    //         borderColor: rgb(1, 1, 1),
    //       });

    //     })

    const code = form.createTextField(`lineItems.${i}.code`);
    const description = form.createTextField(`lineItems.${i}.description`);
    const quantity = form.createTextField(`lineItems.${i}.quantity`);
    const unitPrice = form.createTextField(`lineItems.${i}.unitPrice`);
    const amount = form.createTextField(`lineItems.${i}.amount`);
    code.setText(lineItem?.code ?? "");
    description.setText(lineItem?.description ?? "");
    quantity.setText(`${lineItem?.quantity ?? ""}`);
    unitPrice.setText(`${lineItem?.unitPrice ?? ""}`);
    amount.setText($amount.toFormat("$0,0.00"));

    code.addToPage(page, {
      x: leftMargin,
      y: y,
      width: 58,
      height,
      borderColor: rgb(1, 1, 1),
    });

    description.addToPage(page, {
      x: leftMargin + 58,
      y: y,
      width: 193,
      height,
      borderColor: rgb(1, 1, 1),
    });

    quantity.addToPage(page, {
      x: leftMargin + 58 + 193,
      y: y,
      width: 62,
      height,
      borderColor: rgb(1, 1, 1),
    });

    unitPrice.addToPage(page, {
      x: leftMargin + 58 + 193 + 62,
      y: y,
      width: 67,
      height,
      borderColor: rgb(1, 1, 1),
    });

    amount.addToPage(page, {
      x: leftMargin + 58 + 193 + 62 + 67,
      y: y,
      width: 80,
      height,
      borderColor: rgb(1, 1, 1),
    });

    code.setFontSize(10);
    description.setFontSize(10);
    quantity.setFontSize(10);
    quantity.setAlignment(TextAlignment.Center);
    unitPrice.setFontSize(10);
    unitPrice.setAlignment(TextAlignment.Right);
    amount.setFontSize(10);
    amount.setAlignment(TextAlignment.Right);
  });

  companyName.setText(fields?.companyName ?? "");

  companyContactInfo.setText(
    [
      fields?.location?.address1 ?? "",
      fields?.location?.address2 ?? "",
      fields?.location?.city ?? "",
      fields?.location?.postalCode ?? "",
      `${fields?.location?.region ?? ""} ${fields?.location?.country ?? ""}`,
    ].join(`\n`)
  );

  poNumber.setText(`${fields?.purchaseOrder?.number ?? ""} `);
  date.setText(new Date().toLocaleDateString());
  vendorContactName.setText(fields?.vendorContactName ?? "");
  vendorCompanyName.setText(fields?.vendorCompanyName ?? "");
  vendorAddress.setText(fields?.vendorAddress ?? "");
  vendorPhoneNumber.setText(fields?.vendorPhoneNumber ?? "");
  vendorEmail.setText(fields?.vendorEmail ?? "");
  shippingTerms.setText(fields?.shippingTerms ?? "");
  shippingMethod.setText(fields?.shippingMethod ?? "");
  shippingDeliveryDate.setText(fields?.shippingDeliveryDate ?? "");
  priceSubtotal.setText(subTotal.toFormat("$0,0.00"));
  priceTotal.setText(total.toFormat("$0,0.00"));
  priceShipping.setText(shippingAndHandling.toFormat("$0,0.00"));
  priceOther.setText(`${fields?.priceOther ?? 0}`);
  priceDiscountTotal.setText(discountAmount.toFormat("$0,0.00"));
  priceDiscountPercent.setText(`${fields?.priceDiscountPercent ?? ""}`);
  priceSalesTaxTotal.setText(salesTaxAmount.toFormat("$0,0.00"));
  priceSalesTaxPercent.setText(`${fields?.priceSalesTaxPercent ?? ""}`);
  note.setText(fields?.note ?? "");

  page.drawImage(jpgImage, {
    x: page.getWidth() - 250,
    y: page.getHeight() / 2 + 75,
    width: 150,
    height: 150,
  });

  const pdf = await pdfDoc.save();

  const blob = new Blob([pdf.buffer], { type: "application/pdf" });

  return URL.createObjectURL(blob);
};
