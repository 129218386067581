import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

type ITextAreaInputProps = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> & { label?: string };

const StyledTextAreaInput = styled.textarea`
  ${tw`form-textarea bg-white dark:bg-gray-800 dark:text-white p-2 w-full rounded border border-gray-300 dark:border-gray-400 dark:focus:border-blue-400 focus:border-blue-400 focus:outline-none focus:shadow-none`}

  &.error {
    ${tw`border border-brand`}
  }

  &::placeholder {
    ${tw`text-gray-400 dark:text-gray-600 text-lg`}
  }

  &:disabled {
    ${tw`opacity-75 cursor-not-allowed`}
  }
`;

const TextAreaInput = React.forwardRef<HTMLTextAreaElement, ITextAreaInputProps>(
  ({ label, ...rest }: ITextAreaInputProps, ref) => {
    if (!label) {
      return <StyledTextAreaInput id={rest.name} {...rest} ref={ref} />;
    }

    return (
      <div>
        <label className="text-xs" htmlFor={rest.name}>
          {label}
        </label>
        <StyledTextAreaInput id={rest.name} {...rest} ref={ref} />
      </div>
    );
  }
);

export { TextAreaInput as default };
