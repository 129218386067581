import React, { useEffect } from "react";
import { BaseModal } from "react-spring-modal";

type SideModalPosition = "LEFT" | "RIGHT";

interface SideModalProps {
  side: SideModalPosition;
  className?: string;
  onRequestClose: () => void;
  isOpen: boolean;
}

export const SideModal: React.FC<SideModalProps> = ({
  isOpen,
  onRequestClose,
  children,
  side,
  className = "",
}) => {
  const translate = side === "LEFT" ? "-100%" : "100%";
  const baseClassName = `absolute top-0 bottom-0 ${
    side === "LEFT" ? "left-0" : "right-0"
  } w-full md:w-1/2 lg:w-1/3 xl:w-1/4 bg-white dark:bg-gray-800 text-default dark:text-white`;

  // Close on Escape
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onRequestClose();
      }
    };
    if (globalThis.window) {
      window.addEventListener("keyup", listener);
    }

    return () => {
      if (globalThis.window) {
        window.removeEventListener("keyup", listener);
      }
    };
  }, [onRequestClose]);

  return (
    <BaseModal
      isOpen={isOpen}
      onDismiss={
        // onRequestClose
        () => {}
      }
      overlayProps={{
        className: "z-50",
      }}
      contentTransition={{
        from: { transform: `translateX(${translate})` },
        enter: { transform: "translateX(0%)" },
        leave: { transform: `translateX(${translate})` },
      }}
      contentProps={{ className: baseClassName }}
    >
      {children}
    </BaseModal>
  );
};
