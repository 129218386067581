import React from "react";
import { HR, SubHeading } from "styles";
import { useTable, IFilters } from "./context";
import { useForm, Controller } from "react-hook-form";
import { InventoryItemType } from "generated/graphql";
import { ScrollableForm } from "components/Form/ScrollableForm";
import { getPurchaseOrderLabel, SelectPurchaseOrderStatuses } from "components/Select/PurchaseOrderStatus";
import { SelectVendor } from "components/Select/Vendor";
import Filter from "components/Table/Filter";
import { SelectInventoryItem } from "components/Select/InventoryItem";

export const FiltersHeader = () => {
  const { filters, resetFilters, removeFilter, setShowFilters } = useTable();

  return (
    <div>
      <div className="flex items-center">
        <div className="font-semibold">Filters</div>
        <button onClick={() => setShowFilters(true)} className="text-blue-500 text-xs ml-4">
          Edit...
        </button>
        <button
          className="ml-4 text-xs text-brand"
          onClick={() => {
            resetFilters();
          }}
        >
          Reset
        </button>
      </div>
      <HR />

      <div className="flex flex-wrap">
        {filters?.status?.length > 0 &&
          filters?.status.map((s, i) => {
            return (
              <Filter
                key={s}
                title={`Status: ${getPurchaseOrderLabel(s)}`}
                onRemove={() => removeFilter("status", i)}
                className="bg-gray-600"
              />
            );
          })}

        {filters?.vendors?.length > 0 &&
          filters?.vendors.map((s, i) => {
            return (
              <Filter
                key={s?.id}
                title={`Vendor: ${s?.name}`}
                onRemove={() => removeFilter("vendors", i)}
                className="bg-blue-500"
              />
            );
          })}

        {filters?.inventoryItems?.length > 0 &&
          filters?.inventoryItems.map((s, i) => {
            return (
              <Filter
                key={s?.id}
                title={`Item: ${s?.name}`}
                onRemove={() => removeFilter("inventoryItems", i)}
                className="bg-brand"
              />
            );
          })}
      </div>
    </div>
  );
};

export const Filters = () => {
  const { setFilters, filters, setShowFilters } = useTable();
  const { control, handleSubmit } = useForm<IFilters>({
    defaultValues: {
      ...filters,
    },
  });

  const onSubmit = (formData: IFilters) => {
    setFilters(formData);
    setShowFilters(false);
  };

  return (
    <ScrollableForm
      title="Filters"
      submitLabel="Apply Filters"
      onSubmit={handleSubmit(onSubmit)}
      onCancel={() => setShowFilters(false)}
    >
      <SubHeading>Status</SubHeading>
      <Controller
        name="status"
        control={control}
        defaultValue={filters?.status ?? []}
        render={({ field }) => {
          return <SelectPurchaseOrderStatuses value={field.value} onChange={field.onChange} />;
        }}
      />

      <SubHeading>Vendor</SubHeading>
      <Controller
        name="vendors"
        control={control}
        defaultValue={filters?.vendors ?? []}
        render={({ field }) => {
          return <SelectVendor isMulti value={field.value} onChange={field.onChange} />;
        }}
      />

      <SubHeading>Inventory Item</SubHeading>
      <Controller
        name="inventoryItems"
        control={control}
        defaultValue={filters?.inventoryItems ?? []}
        render={({ field }) => {
          return (
            <SelectInventoryItem
              types={[InventoryItemType.RawMaterial]}
              isMulti
              value={field.value}
              onChange={field.onChange}
            />
          );
        }}
      />
    </ScrollableForm>
  );
};
