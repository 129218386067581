import { QueryTuple } from "@apollo/client";
import {
  Customer,
  CustomerOrder,
  Exact,
  ListCustomerOrdersFilters,
  ListCustomerOrdersQuery,
  ListCustomerOrdersQueryVariables,
  OrderBy,
  OrderByDirection,
  PageInfo,
  Pagination,
  useListCustomerOrdersLazyQuery,
  ResourceTag,
  CustomerOrderFulfillmentStatus,
  CustomerOrderPickStatus,
  CustomerOrderPaidStatus,
} from "generated/graphql";
import React, { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo } from "react";
import { useSessionStorage } from "react-use";
import { useImmerReducer } from "use-immer";
import { removeFilterHelper } from "utils/filters";

export interface IFilters {
  customer?: Customer;
  onlyPickable?: boolean;
  showDeleted?: boolean;
  // showFulfilled?: boolean;
  pickStatus?: CustomerOrderPickStatus[];
  paidStatus?: CustomerOrderPaidStatus[];
  fulfillmentStatus?: CustomerOrderFulfillmentStatus[];
  tags?: ResourceTag[];
  orderNumbers?: string[];
}

interface StateIFace {
  filters: IFilters;
  pagination: PageInfo;
  orderBy: OrderBy;
  showFilters: boolean;
  selected: Set<React.Key>;
  isAddingCustomerOrder: boolean;
  isEditingCustomerOrder: CustomerOrder;
  isDeletingCustomerOrders: CustomerOrder[];
  importOrder: boolean;
  showQuickFilters: boolean;
}

type SetPagination = {
  type: Actions.SetPagination;
  data: { perPage: number; page: number };
};

type SetSelected = {
  type: Actions.SetSelected;
  data: Set<React.Key>;
};

type SetIsAddingCustomerOrder = {
  type: Actions.SetIsAddingCustomerOrder;
  data: boolean;
};
type SetIsEditingCustomerOrder = {
  type: Actions.SetIsEditingCustomerOrder;
  data: CustomerOrder;
};

type SetIsDeletingCustomerOrders = {
  type: Actions.SetIsDeletingCustomerOrders;
  data: CustomerOrder[];
};

type SetShowFilters = {
  type: Actions.SetShowFilters;
  data: boolean;
};

type SetFilters = {
  type: Actions.SetFilters;
  data: IFilters;
};

type SetOrderBy = {
  type: Actions.SetOrderBy;
  data: OrderBy;
};

type SetImportOrder = {
  type: Actions.SetImportOrder;
  data: boolean;
};

type SetShowQuickFilters = {
  type: Actions.SetShowQuickFilters;
  data: boolean;
};

type DispatcherAction =
  | SetShowFilters
  | SetSelected
  | SetIsAddingCustomerOrder
  | SetIsEditingCustomerOrder
  | SetIsDeletingCustomerOrders
  | SetPagination
  | SetFilters
  | SetOrderBy
  | SetImportOrder
  | SetShowQuickFilters;

enum Actions {
  SetFilters = "SET_FILTERS",
  SetShowFilters = "SET_SHOW_FILTERS",
  SetPagination = "SET_PAGINATION",
  SetOrderBy = "SET_ORDER_BY",
  SetSelected = "SET_SELECTED",
  SetIsAddingCustomerOrder = "SET_IS_ADDING_CUSTOMER_ORDER",
  SetIsEditingCustomerOrder = "SET_IS_EDITING_CUSTOMER_ORDER",
  SetIsDeletingCustomerOrders = "SET_IS_DELETING_CUSTOMER_ORDERS",
  SetImportOrder = "SET_IMPORT_ORDER",
  SetShowQuickFilters = "SET_SHOW_QUICK_FILTERS",
}

const initialState: StateIFace = {
  filters: null,
  showFilters: false,
  isAddingCustomerOrder: false,
  isEditingCustomerOrder: null,
  isDeletingCustomerOrders: null,
  selected: new Set<React.Key>(),
  pagination: { perPage: 10, page: 1 },
  orderBy: { key: "createdAt", direction: OrderByDirection.Asc },
  importOrder: false,
  showQuickFilters: true,
};

const ctx = createContext<{
  query: QueryTuple<
    ListCustomerOrdersQuery,
    Exact<{
      pagination?: Pagination;
      filters?: ListCustomerOrdersFilters;
    }>
  >;
  state: StateIFace;
  dispatch: React.Dispatch<DispatcherAction>;
}>({ query: null, state: initialState, dispatch: () => null });

const FILTERS_KEY = "@factoryfinch/customerOrderFilters";

export const CustomerOrdersProvider: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const query = useListCustomerOrdersLazyQuery({
    fetchPolicy: "network-only",
  });

  const [filters, setFilters] = useSessionStorage<{
    filters: IFilters;
    pagination: PageInfo;
    orderBy: OrderBy;
    showQuickFilters: boolean;
  }>(FILTERS_KEY, {
    filters: initialState.filters,
    pagination: initialState.pagination,
    orderBy: initialState.orderBy,
    showQuickFilters: initialState.showQuickFilters,
  });

  const [state, dispatch] = useImmerReducer(
    (state: StateIFace, action: DispatcherAction) => {
      switch (action.type) {
        case Actions.SetOrderBy:
          state.orderBy = action.data;
          break;
        case Actions.SetPagination:
          state.pagination = action.data;
          break;

        case Actions.SetSelected:
          state.selected = action.data;
          break;
        case Actions.SetIsAddingCustomerOrder:
          state.isAddingCustomerOrder = action.data;
          break;
        case Actions.SetIsEditingCustomerOrder:
          state.isEditingCustomerOrder = action.data;
          break;

        case Actions.SetIsDeletingCustomerOrders:
          state.isDeletingCustomerOrders = action.data;
          break;

        case Actions.SetShowFilters:
          state.showFilters = action.data;
          break;

        case Actions.SetShowQuickFilters:
          state.showQuickFilters = action?.data;
          break;
        case Actions.SetImportOrder:
          state.importOrder = action.data;
          break;

        case Actions.SetFilters:
          state.filters = action.data;
          break;
        default:
          throw new Error("problem with CustomerOrderProvider");
      }
    },
    { ...initialState, ...filters }
  );

  useEffect(() => {
    setFilters({
      filters: state.filters,
      pagination: state.pagination,
      orderBy: state.orderBy,
      showQuickFilters: state.showQuickFilters,
    });
  }, [state, setFilters]);

  return (
    <ctx.Provider
      value={{
        query,
        state,
        dispatch,
      }}
    >
      {children}
    </ctx.Provider>
  );
};

export const useTable = () => {
  const { query, state, dispatch } = useContext(ctx);
  const [listCustomerOrders, { data, loading }] = query;

  const setPagination = useCallback(
    (pagination: Pagination) => {
      const isChangingPerPage = !!(pagination?.perPage && pagination?.perPage !== state?.pagination?.perPage);
      const page = isChangingPerPage ? 1 : pagination?.page || state?.pagination?.page;
      const perPage = pagination?.perPage || state?.pagination?.perPage || 10;
      dispatch({
        type: Actions.SetPagination,
        data: {
          page,
          perPage,
        },
      });
    },
    [dispatch, state.pagination]
  );

  const setselected = useCallback(
    (rows: Set<React.Key>) => dispatch({ type: Actions.SetSelected, data: rows }),
    [dispatch]
  );
  const setisAddingCustomerOrder = (open: boolean) => {
    dispatch({ type: Actions.SetIsAddingCustomerOrder, data: open });
  };

  const setisEditingCustomerOrder = (customerOrder: CustomerOrder) => {
    dispatch({ type: Actions.SetIsEditingCustomerOrder, data: customerOrder });
  };

  const setisDeletingCustomerOrders = (customerOrders: CustomerOrder[]) => {
    dispatch({
      type: Actions.SetIsDeletingCustomerOrders,
      data: customerOrders,
    });
  };

  const setShowFilters = (open: boolean) => {
    dispatch({ type: Actions.SetShowFilters, data: open });
  };

  const setFilters = (filters: IFilters) => {
    dispatch({ type: Actions.SetFilters, data: filters });
  };

  const removeFilter = (property: string, i?: number) => {
    removeFilterHelper(
      state.filters,
      {
        property,
        i,
      },
      setFilters
    );
  };

  const setShowQuickFilters = (show: boolean) => dispatch({ type: Actions.SetShowQuickFilters, data: show });

  const resetFilters = () => {
    dispatch({
      type: Actions.SetFilters,
      data: null,
    });
  };

  const setOrderBy = useCallback(
    (key: string, direction: OrderByDirection) => {
      dispatch({ type: Actions.SetOrderBy, data: { key, direction } });
    },
    [dispatch]
  );

  const setimportOrder = (open: boolean) => dispatch({ type: Actions.SetImportOrder, data: open });

  const variables: ListCustomerOrdersQueryVariables = useMemo(
    () => ({
      filters: {
        showDeleted: state?.filters?.showDeleted,
        showFulfilled: true, // TODO: REMOVE THIS
        customerId: state?.filters?.customer?.id,
        onlyPickable: state?.filters?.onlyPickable,
        orderNumbers: state?.filters?.orderNumbers,
        pickStatus: state?.filters?.pickStatus,
        fulfillmentStatus: state?.filters?.fulfillmentStatus,
        paidStatus: state?.filters?.paidStatus,
        tags:
          state.filters?.tags?.map((tag) => {
            return { tagId: tag.tag.id, value: tag?.value ?? null };
          }) ?? [],
      },
      pagination: state.pagination,
      orderBy: state.orderBy,
    }),

    [state.pagination, state.filters, state.orderBy]
  );

  const selectedRows = useMemo(() => {
    return (
      data?.listCustomerOrders?.orders?.filter((order) => {
        return state.selected.has(order.id);
      }) ?? []
    );
  }, [data?.listCustomerOrders?.orders, state.selected]);

  return {
    ...state,
    setOrderBy,
    setPagination,
    setisAddingCustomerOrder,
    setisEditingCustomerOrder,
    setisDeletingCustomerOrders,
    setselected,
    setShowFilters,
    setFilters,
    removeFilter,
    listCustomerOrders,
    data,
    loading,
    variables,
    selectedRows,
    resetFilters,
    setimportOrder,
    setShowQuickFilters,
  };
};
