import * as generated from "generated/graphql";
import { DateTime } from "luxon";
import React from "react";
import { capitalizeFirstWord, shortDate } from "utils/formatters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import {
  faCcVisa,
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
} from "@fortawesome/free-brands-svg-icons";
import { StripeLogo } from "components/Payment/StripeLogo";

const getCardLogo = (brand: string) => {
  // https://stripe.com/docs/api/payment_methods/object#payment_method_object-card
  switch (brand) {
    case "amex":
      return faCcAmex;
    case "diners":
      return faCcDinersClub;
    case "discover":
      return faCcDiscover;
    case "jcb":
      return faCcJcb;
    case "mastercard":
      return faCcMastercard;
    case "unionpay":
      return faCreditCard;
    case "visa":
      return faCcVisa;
    case "unknown":
      return faCreditCard;
    default:
      return faCreditCard;
  }
};

const FactoryFinchSubscription: React.FC<{ name: string; subscription: generated.FactoryFinchSubscription }> = ({
  name,
  subscription,
}) => {
  return (
    <div className="text-black dark:text-white rounded p-2 col-span-6 lg:col-span-2">
      <div className="text-lg font-semibold mb-2 flex items-center">{capitalizeFirstWord(name)}'s Subscription</div>

      <div>
        <div>Factory Finch Invitee 🦅 🎉</div>
        <div className="text-xs text-gray-400 dark:text-gray-200">Created {shortDate(subscription?.createdAt)}</div>
      </div>
    </div>
  );
};

// TODO: Come back to this!
// const ShopifySubscription: React.FC<{ subscription: generated.FactoryFinchSubscription }> = ({ subscription }) => {
//   // const { data } = useMeQuery();

//   // const isShopifyAccount = subscription?.type === "shopify";
//   // const shopifyIntegration = data?.me?.organization?.integrations.find(
//   //   (i) => i.type === generated.IntegrationType.Shopify
//   // );

//   // const subscription =
//   //   data?.me?.organization?.subscription?.__typename === "FactoryFinchSubscription" &&
//   //   data?.me?.organization?.subscription;

//   // if (!subscription) {
//   //   const s =
//   //     data?.me?.organization?.subscription?.__typename === "StripeSubscription" && data?.me?.organization?.subscription;
//   //   return <>{JSON.stringify(s, null, 2)}</>;
//   // }

//   return (
//     <div className="p-4">
//       <H4 className="mb-4">Subscription</H4>

//       <div>
//         <div>Type: {subscription?.type}</div>
//         <div>Created: {shortDateTime(subscription?.createdAt)}</div>
//         <div>Last Updated: {shortDateTime(subscription?.updatedAt)}</div>
//       </div>

//       <>
//         <table>
//           <tbody>
//             <tr>
//               <td>Type</td>
//               <td>{subscription?.data?.name}</td>
//             </tr>
//             {subscription?.data?.activated_on && (
//               <tr>
//                 <td>Activated On</td>
//                 <td>{subscription?.data?.activated_on}</td>
//               </tr>
//             )}
//             {subscription?.data?.activated_on && (
//               <tr>
//                 <td>Billing Date</td>
//                 <td>{subscription?.data?.billing_on}</td>
//               </tr>
//             )}
//             <tr>
//               <td>Status</td>
//               <td>{subscription?.data?.status}</td>
//             </tr>
//             {subscription?.data?.trial_days && (
//               <>
//                 <tr>
//                   <td>Trial length</td>
//                   <td>{subscription?.data?.trial_days} days</td>
//                 </tr>
//                 <tr>
//                   <td>Trial Ends On</td>
//                   <td>{subscription?.data?.trial_ends_on}</td>
//                 </tr>
//               </>
//             )}
//           </tbody>
//         </table>

//         {subscription?.data?.confirmation_url && (
//           <a
//             href={subscription?.data?.confirmation_url}
//             target="_blank"
//             rel="noreferrer"
//             className="text-brand underline"
//           >
//             Confirm Charge
//           </a>
//         )}
//         {/* <a
//           href={`https://${shopifyIntegration?.name}/admin/apps`}
//           target="_blank"
//           rel="noreferrer"
//           className="text-brand underline"
//         >
//           Manage your subscription
//         </a> */}
//       </>
//     </div>
//   );
// };

const StripeSubscription: React.FC<{ name: string; subscription: generated.StripeSubscription }> = ({
  name,
  subscription,
}) => {
  const created = DateTime.fromSeconds(parseInt(subscription?.created, 10)).toLocaleString();
  const periodStart = DateTime.fromSeconds(parseInt(subscription?.currentPeriodStart, 10)).toLocaleString();
  const periodEnd = DateTime.fromSeconds(parseInt(subscription?.currentPeriodEnd, 10)).toLocaleString();

  return (
    <div className="text-black dark:text-white rounded p-2 col-span-6 lg:col-span-2">
      <div className="text-lg font-semibold mb-2 flex items-center">
        {capitalizeFirstWord(name)}'s Subscription
        <span className="mx-2">
          <StripeLogo width={80} />
        </span>
      </div>

      <div className="text-xs text-gray-400 dark:text-gray-200">Created {created}</div>
      <div className="text-xs text-gray-400 dark:text-gray-200">Period Start {periodStart}</div>
      <div className="text-xs text-gray-400 dark:text-gray-200">Period End {periodEnd}</div>
      <div className="text-sm font-semibold mt-2">Payment Info</div>
      <div className="my-2">
        <div className="text-sm font-semibold flex items-center">
          <FontAwesomeIcon icon={getCardLogo(subscription?.paymentInfo?.type)} size="2x" />
          ﹒﹒﹒﹒ {subscription?.paymentInfo?.last4}
        </div>
        <div className="my-1 text-xs text-gray-400 dark:text-gray-200">
          Expires {subscription?.paymentInfo?.expMonth}/{subscription?.paymentInfo?.expYear}
        </div>
      </div>
    </div>
  );
};

export const Subscription: React.FC = () => {
  const { data } = generated.useGetOrganizationQuery();

  const sub = data?.organization?.subscription;
  if (sub?.__typename === "FactoryFinchSubscription") {
    return <FactoryFinchSubscription name={data?.organization?.name ?? "Your"} subscription={sub} />;
  }

  if (sub?.__typename === "StripeSubscription") {
    return <StripeSubscription name={data?.organization?.name ?? "Your"} subscription={sub} />;
  }

  return null;
};
