import { QueryTuple } from "@apollo/client";
import {
  CustomerOrder,
  Exact,
  ListSalesOrderInvoicesFilters,
  ListSalesOrderInvoicesQuery,
  PageInfo,
  Pagination,
  ListSalesOrderInvoicesQueryVariables,
  useListSalesOrderInvoicesLazyQuery,
  Customer,
} from "generated/graphql";

import React, { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo } from "react";
import { useSessionStorage } from "react-use";
import { useImmerReducer } from "use-immer";
import { removeFilterHelper } from "utils/filters";

export interface IFilters {
  customers: Pick<Customer, "id" | "firstName" | "lastName" | "email">[];
  createdAt: {
    from: Date;
    to: Date;
  };
}

interface StateIFace {
  filters: IFilters;
  pagination: PageInfo;
  showFilters: boolean;
  selected: Set<React.Key>;
  showQuickFilters: boolean;

  isAddingCustomerOrder: boolean;
  isEditingCustomerOrder: CustomerOrder;
  isDeletingCustomerOrders: CustomerOrder[];
}

type SetPagination = {
  type: Actions.SetPagination;
  data: { perPage: number; page: number };
};

type SetSelected = {
  type: Actions.SetSelected;
  data: Set<React.Key>;
};

type SetIsAddingCustomerOrder = {
  type: Actions.SetIsAddingCustomerOrder;
  data: boolean;
};
type SetIsEditingCustomerOrder = {
  type: Actions.SetIsEditingCustomerOrder;
  data: CustomerOrder;
};

type SetIsDeletingCustomerOrders = {
  type: Actions.SetIsDeletingCustomerOrders;
  data: CustomerOrder[];
};

type SetShowFilters = {
  type: Actions.SetShowFilters;
  data: boolean;
};

type SetFilters = {
  type: Actions.SetFilters;
  data: IFilters;
};

type SetShowQuickFilters = {
  type: Actions.SetShowQuickFilters;
  data: boolean;
};

type DispatcherAction =
  | SetShowFilters
  | SetSelected
  | SetPagination
  | SetFilters
  | SetShowQuickFilters
  | SetIsAddingCustomerOrder
  | SetIsEditingCustomerOrder
  | SetIsDeletingCustomerOrders;

enum Actions {
  SetFilters = "SET_FILTERS",
  SetShowFilters = "SET_SHOW_FILTERS",
  SetPagination = "SET_PAGINATION",
  SetSelected = "SET_SELECTED",
  SetShowQuickFilters = "SET_SHOW_QUICK_FILTERS",
  SetIsAddingCustomerOrder = "SET_IS_ADDING_CUSTOMER_ORDER",
  SetIsEditingCustomerOrder = "SET_IS_EDITING_CUSTOMER_ORDER",
  SetIsDeletingCustomerOrders = "SET_IS_DELETING_CUSTOMER_ORDERS",
}

const initialState: StateIFace = {
  filters: null,
  showFilters: false,
  isAddingCustomerOrder: false,
  isEditingCustomerOrder: null,
  isDeletingCustomerOrders: null,
  selected: new Set<React.Key>(),
  pagination: { perPage: 10, page: 1 },
  showQuickFilters: true,
};

const ctx = createContext<{
  query: QueryTuple<
    ListSalesOrderInvoicesQuery,
    Exact<{
      pagination?: Pagination;
      filters?: ListSalesOrderInvoicesFilters;
    }>
  >;
  state: StateIFace;
  dispatch: React.Dispatch<DispatcherAction>;
}>({ query: null, state: initialState, dispatch: () => null });

const STORAGE_KEY = "@factoryfinch/salesOrderInvoices";

export const TableProvider: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const query = useListSalesOrderInvoicesLazyQuery({
    fetchPolicy: "network-only",
  });

  const [stored, setstored] = useSessionStorage<Pick<StateIFace, "filters" | "pagination">>(STORAGE_KEY, {
    filters: initialState.filters,
    pagination: initialState.pagination,
  });

  if (stored?.filters?.createdAt) {
    if (stored?.filters?.createdAt?.from) {
      stored.filters.createdAt.from = new Date(stored.filters.createdAt.from);
    }

    if (stored?.filters?.createdAt?.to) {
      stored.filters.createdAt.to = new Date(stored.filters.createdAt.to);
    }
  }

  const [state, dispatch] = useImmerReducer(
    (state: StateIFace, action: DispatcherAction) => {
      switch (action.type) {
        case Actions.SetPagination:
          state.pagination = action.data;
          break;

        case Actions.SetSelected:
          state.selected = action.data;
          break;

        case Actions.SetShowFilters:
          state.showFilters = action.data;
          break;

        case Actions.SetShowQuickFilters:
          state.showQuickFilters = action?.data;
          break;

        case Actions.SetFilters:
          state.filters = action.data;
          break;

        case Actions.SetIsAddingCustomerOrder:
          state.isAddingCustomerOrder = action.data;
          break;
        case Actions.SetIsEditingCustomerOrder:
          state.isEditingCustomerOrder = action.data;
          break;

        case Actions.SetIsDeletingCustomerOrders:
          state.isDeletingCustomerOrders = action.data;
          break;

        default:
          throw new Error("problem with TableProvider");
      }
    },
    { ...initialState, ...stored }
  );

  useEffect(() => {
    setstored({
      filters: state?.filters,
      pagination: state?.pagination,
    });
  }, [state.filters, state.pagination, setstored]);

  return (
    <ctx.Provider
      value={{
        query,
        state,
        dispatch,
      }}
    >
      {children}
    </ctx.Provider>
  );
};

export const useTable = () => {
  const { query, state, dispatch } = useContext(ctx);
  const [fetch, { data, loading }] = query;

  const setPagination = useCallback(
    (pagination: Pagination) => {
      const isChangingPerPage = !!(pagination?.perPage && pagination?.perPage !== state?.pagination?.perPage);
      const page = isChangingPerPage ? 1 : pagination?.page || state?.pagination?.page;
      const perPage = pagination?.perPage || state?.pagination?.perPage || 10;
      dispatch({
        type: Actions.SetPagination,
        data: {
          page,
          perPage,
        },
      });
    },
    [dispatch, state.pagination]
  );
  const setselected = useCallback(
    (rows: Set<React.Key>) => dispatch({ type: Actions.SetSelected, data: rows }),
    [dispatch]
  );
  const setisAddingCustomerOrder = (open: boolean) => {
    dispatch({ type: Actions.SetIsAddingCustomerOrder, data: open });
  };

  const setisEditingCustomerOrder = (customerOrder: CustomerOrder) => {
    dispatch({ type: Actions.SetIsEditingCustomerOrder, data: customerOrder });
  };

  const setisDeletingCustomerOrders = (customerOrders: CustomerOrder[]) => {
    dispatch({
      type: Actions.SetIsDeletingCustomerOrders,
      data: customerOrders,
    });
  };

  const setShowFilters = (open: boolean) => {
    dispatch({ type: Actions.SetShowFilters, data: open });
  };

  const setFilters = (filters: IFilters) => {
    dispatch({ type: Actions.SetFilters, data: filters });
  };

  const removeFilter = (property: string, i?: number) => {
    removeFilterHelper(
      state.filters,
      {
        property,
        i,
      },
      setFilters
    );
  };

  const setShowQuickFilters = (show: boolean) => dispatch({ type: Actions.SetShowQuickFilters, data: show });

  const resetFilters = () => {
    dispatch({
      type: Actions.SetFilters,
      data: null,
    });
  };

  const variables: ListSalesOrderInvoicesQueryVariables = useMemo(
    () => ({
      filters: {
        customerIds: state?.filters?.customers?.map((c) => c.id) ?? [],
        createdAt: {
          from: state?.filters?.createdAt?.from?.toISOString(),
          to: state?.filters?.createdAt?.to?.toISOString(),
        },
      },
      pagination: state.pagination,
    }),

    [state.pagination, state.filters]
  );

  const selectedRows = useMemo(() => {
    return data?.listSalesOrderInvoices?.invoices?.filter((order) => state.selected.has(order.id)) ?? [];
  }, [data?.listSalesOrderInvoices?.invoices, state.selected]);

  const refetch = useCallback(() => fetch({ variables }), [fetch, variables]);

  return {
    ...state,
    data,
    loading,
    variables,
    selectedRows,
    setPagination,
    setisAddingCustomerOrder,
    setisEditingCustomerOrder,
    setisDeletingCustomerOrders,
    setselected,
    setShowFilters,
    setFilters,
    removeFilter,
    fetch,
    resetFilters,
    setShowQuickFilters,
    refetch,
  };
};
