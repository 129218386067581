import React, { useMemo, useState } from "react";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe, StripeCardElementOptions } from "@stripe/stripe-js";
import { CountryOption, RegionOption, SelectCountry, SelectRegion } from "components/Form/CountryAndRegion";
import TextInput from "components/Form/TextInput";
import { ButtonLoading } from "components/Loading";
import RedLogo, { WhiteLogo } from "components/Logo";
import { STRIPE_PUBLISHABLE_KEY } from "config";
import { MeDocument, useCreateSubscriptionMutation, useUpdateMeMutation } from "generated/graphql";
import { useAuth } from "hooks/auth";
import { Theme, useTheme } from "hooks/theme";
import { useToast } from "hooks/toast";
import DarkModeToggle from "react-dark-mode-toggle";
import { Controller, useForm } from "react-hook-form";
import { Button, PlainButton, RedButton, SubHeading } from "styles";
import { notEmpty } from "utils/filters";
import { StripeLogo } from "./StripeLogo";

// // Make sure to call `loadStripe` outside of a component’s render to avoid
// // recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

interface IPaymentInfoForm {
  name: string;
  paymentMethodId: string;
  city: string;
  country: CountryOption;
  line1: string;
  line2: string;
  postalCode: string;
  state: RegionOption;
  cc: boolean;
}

const PaymentInfoForm = () => {
  const { logout } = useAuth();
  const [updateMe] = useUpdateMeMutation();
  const stripe = useStripe();
  const elements = useElements();
  const { isDarkMode, theme, settheme } = useTheme();
  const toast = useToast();
  const [createSubscription] = useCreateSubscriptionMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: MeDocument }],
  });
  const [formView, setformView] = useState<"billingAddress" | "paymentInfo">("billingAddress");
  const { handleSubmit, register, formState, setValue, control, watch, trigger } = useForm<IPaymentInfoForm>();
  const country = watch("country");
  const CARD_OPTIONS = useMemo(
    (): StripeCardElementOptions => ({
      iconStyle: "solid",
      style: {
        base: {
          iconColor: isDarkMode ? "#fff" : "#E5E7EB",
          color: isDarkMode ? "#fff" : "#E5E7EB",
          fontWeight: 400,

          fontSize: "1.5rem",
          fontSmoothing: "antialiased",
          ":-webkit-autofill": {
            color: "#fce883",
          },
          "::placeholder": {
            color: "#9CA3AF",
          },
        },
        invalid: {
          iconColor: "#d92452",
          color: "#d92452",
        },
      },
    }),
    [isDarkMode]
  );

  const submit = async (formData: IPaymentInfoForm) => {
    // Block native form submission.
    // event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    try {
      // Use your card Element with other Stripe.js APIs
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        throw error;
      }

      await createSubscription({
        variables: {
          input: {
            paymentMethodId: paymentMethod.id,
            billingInfo: {
              customerName: formData.name,
              city: formData.city,
              country: formData.country.countryShortCode,
              line1: formData.line1,
              line2: formData.line2,
              postalCode: formData.postalCode,
              state: formData.state.name,
            },
          },
        },
      });
    } catch (err) {
      toast.error("Unable to create payment method");
    }

    try {
      await updateMe({ variables: { input: { name: formData.name } } });
    } catch (err) {
      console.debug("Unable to update user info", err);
    }
  };

  return (
    <div className="h-screen flex flex-col">
      <div className="p-4 flex flex-row justify-between items-center">
        {isDarkMode ? <WhiteLogo width={100} height={35} /> : <RedLogo width={100} height={35} />}
        <DarkModeToggle
          className="focus:outline-none"
          onChange={() => {
            settheme(theme === Theme.Dark ? Theme.Light : Theme.Dark);
          }}
          checked={isDarkMode}
          size={50}
        />
      </div>
      <div className="flex-1 flex flex-col justify-center items-center">
        <form onSubmit={handleSubmit(submit)} className="w-full sm:w-1/2 xl:w-1/4">
          {formView === "billingAddress" && (
            <>
              {" "}
              <SubHeading>Billing Address</SubHeading>
              <TextInput
                label="City"
                {...register("city", { required: true })}
                className={formState?.errors?.city && "error"}
              />
              <TextInput
                label="Address 1"
                {...register("line1", { required: true })}
                className={formState?.errors?.line1 && "error"}
              />
              <TextInput
                label="Address 2"
                {...register("line2", { required: true })}
                className={formState?.errors?.line2 && "error"}
              />
              <TextInput
                label="Zip/Postal Code"
                {...register("postalCode", { required: true })}
                className={formState?.errors?.postalCode && "error"}
              />
              <Controller
                control={control}
                name="country"
                rules={{ required: true }}
                render={({ field }) => {
                  return (
                    <SelectCountry
                      hasError={notEmpty(formState?.errors?.country)}
                      label="Country"
                      onChange={(v) => {
                        setValue("state", null);
                        field.onChange(v);
                      }}
                      value={field.value}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="state"
                rules={{ required: true }}
                render={({ field }) => {
                  return (
                    <SelectRegion
                      hasError={notEmpty(formState?.errors?.state)}
                      label="State/Region"
                      country={country}
                      onChange={(v) => field.onChange(v)}
                      value={field.value}
                    />
                  );
                }}
              />
              <RedButton
                type="button"
                className="w-full my-4"
                onClick={async () => {
                  const isValid = await trigger();
                  if (isValid) {
                    setformView("paymentInfo");
                  }
                  // setformView("paymentInfo");
                }}
              >
                Next
              </RedButton>
            </>
          )}

          {formView === "paymentInfo" && (
            <>
              <div className="flex justify-between">
                <SubHeading>Credit Card</SubHeading>
                <StripeLogo />
              </div>

              <TextInput
                className={formState?.errors?.name && "error"}
                placeholder="ie. Carl G. Peters"
                label="Name on Card"
                {...register("name", { required: true })}
              />
              <div className="py-2">
                <label className="text-xs">Card Info</label>
                <div
                  className={`h-12 py-3 px-4 rounded ${
                    formState?.errors?.cc ? "border border-brand" : "border border-gray-300 dark:border-white"
                  }`}
                >
                  <Controller
                    control={control}
                    name="cc"
                    rules={{
                      validate: (v) => v === true || "invalid",
                    }}
                    render={({ field }) => {
                      return (
                        <CardElement
                          options={CARD_OPTIONS}
                          onChange={(e) => {
                            field.onChange(e?.complete ?? false);
                          }}
                        />
                      );
                    }}
                  />
                </div>
              </div>

              <RedButton type="submit" disabled={!stripe} className="w-full my-4">
                {formState?.isSubmitting ? <ButtonLoading /> : "Submit"}
              </RedButton>

              <div className="flex justify-center">
                <PlainButton
                  disabled={formState?.isSubmitting}
                  type="button"
                  onClick={() => setformView("billingAddress")}
                >
                  Back
                </PlainButton>
              </div>
            </>
          )}
        </form>
      </div>

      <div className="flex justify-end">
        <Button onClick={logout} className="text-xs font-light">
          Logout
        </Button>
      </div>
    </div>
  );
};

export const PaymentForm = () => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentInfoForm />
    </Elements>
  );
};
