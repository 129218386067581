import { omit } from "lodash";

export const notEmpty = <TValue>(value: TValue | null | undefined): value is TValue => {
  return value !== null && value !== undefined;
};

export const isEmpty = <TValue>(value: TValue | null | undefined): value is TValue => {
  return !notEmpty(value);
};

export const removeAtIndex = (items: any[], idx: number) => {
  return [...items.slice(0, idx), ...items.slice(idx + 1)];
};

export const removeFilterHelper = <T extends object>(
  filters: T,
  remove: {
    property: string;
    i?: number;
  },
  cb: (filters: Partial<T>) => void
) => {
  if (filters[remove.property] instanceof Array && notEmpty(remove.i)) {
    cb({
      ...filters,
      [remove.property]: removeAtIndex(filters[remove.property], remove.i),
    });
    return;
  }

  cb(omit(filters, [remove.property]));
};
