import React from "react";
import { Select } from "styles";
import { SalesOrderInvoiceType } from "generated/graphql";

const options = [SalesOrderInvoiceType.Proforma, SalesOrderInvoiceType.Final];

export const getSalesOrderInvoiceTypeLabel = (type: SalesOrderInvoiceType) => {
  switch (type) {
    case SalesOrderInvoiceType.Proforma:
      return "Pro Forma";
    case SalesOrderInvoiceType.Final:
      return "Final";

    default:
      return type;
  }
};

export const getSalesOrderInvoiceTypeOption = (type: SalesOrderInvoiceType) => {
  return { label: getSalesOrderInvoiceTypeLabel(type), value: type };
};

export const SelectSalesOrderInvoiceTypes: React.FC<{
  value: SalesOrderInvoiceType[];
  onChange: (opt: SalesOrderInvoiceType[]) => void;
  placeholder?: string;
}> = ({ value, onChange, placeholder = "Filter by Type..." }) => {
  return (
    <Select
      placeholder={placeholder}
      isClearable
      value={value}
      isMulti
      options={options}
      onChange={onChange}
      getOptionValue={(opt: SalesOrderInvoiceType) => opt}
      getOptionLabel={(opt: SalesOrderInvoiceType) => getSalesOrderInvoiceTypeLabel(opt)}
    />
  );
};

export const SelectSalesOrderInvoiceType: React.FC<{
  value: SalesOrderInvoiceType;
  onChange: (opt: SalesOrderInvoiceType) => void;
  placeholder?: string;
  label?: string;
}> = ({ value, onChange, label, placeholder = "Select Type..." }) => {
  return (
    <div className="w-full">
      {label && <label className="text-xs">{label}</label>}
      <Select
        placeholder={placeholder}
        isClearable
        value={getSalesOrderInvoiceTypeOption(value)}
        options={options.map(getSalesOrderInvoiceTypeOption)}
        onChange={(opt: { label: string; value: SalesOrderInvoiceType }) => onChange(opt?.value)}
      />
    </div>
  );
};
