import { faMinusCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { AddButton, IconButton } from "components/Buttons";
import CurrencyInput from "components/Form/CurrencyInput";
import NumberInput from "components/Form/NumberInput";
import { PercentInput } from "components/Form/PercentInput";
import { ScrollableForm } from "components/Form/ScrollableForm";
import TextAreaInput from "components/Form/TextAreaInput";
import { LoadingAnimationWithLabel } from "components/Loading";
import { SelectInventoryItemModalInput } from "components/Select/InventoryItem";
import { SelectSalesOrderInvoicePaymentTerm } from "components/Select/SalesOrder/SalesOrderInvoicePaymentTerms";
import { SelectSalesOrderInvoiceStatus } from "components/Select/SalesOrder/SalesOrderInvoiceStatus";
import { SelectSalesOrderInvoiceType } from "components/Select/SalesOrder/SalesOrderInvoiceType";
import {
  GetSalesOrderInvoiceDocument,
  InventoryItem,
  SalesOrderInvoiceLineItemInput,
  SalesOrderInvoiceStatus,
  SalesOrderInvoiceType,
  useCreateSalesOrderInvoiceMutation,
  useGetSalesOrderInvoiceQuery,
  useUpdateSalesOrderInvoiceMutation,
} from "generated/graphql";
import { useToast } from "hooks/toast";
import React, { useCallback, useEffect } from "react";
import { Controller, FormProvider, useFieldArray, useForm, useFormContext } from "react-hook-form";
import NumberFormat from "react-number-format";
import { colors, H4 } from "styles";
export interface IEditInvoiceFormLineItem {
  id?: string;
  inventoryItem?: Pick<InventoryItem, "id" | "name">;
  pricePerUnit?: number;
  quantity?: number;
  units?: string;
  description?: string;
  modifiers?: { name?: string; pricePerUnit?: number }[];
}

export interface IEditInvoiceForm {
  number: string;
  status: SalesOrderInvoiceStatus;
  type: SalesOrderInvoiceType;
  taxPercent: number;
  shippingAmount: number;
  paymentTerms: string;
  message: string;
  lineItems: IEditInvoiceFormLineItem[];
}

const SalesOrderInvoiceForm: React.FC = () => {
  const { control, setValue, watch } = useFormContext<IEditInvoiceForm>();
  const { append, remove, fields } = useFieldArray({
    name: "lineItems",
    control,
    keyName: "_id",
  });
  const values = watch();

  const setModifierValue = useCallback(
    (liIdx: number, midx: number, name: string, val: any) => {
      const modifiers = values?.lineItems?.[liIdx]?.modifiers?.map((item, idx) => {
        if (idx === midx) {
          return {
            ...item,
            [name]: val,
          };
        }

        return item;
      });

      setValue(`lineItems.${liIdx}.modifiers` as const, modifiers);
    },
    [setValue, values?.lineItems]
  );

  const removeModifierValue = useCallback(
    (liIdx: number, midx: number) => {
      const modifiers = values?.lineItems?.[liIdx]?.modifiers?.filter((_, idx) => idx !== midx) ?? [];
      setValue(`lineItems.${liIdx}.modifiers` as const, modifiers);
    },
    [setValue, values?.lineItems]
  );

  return (
    <>
      <div className="grid grid-cols-2 gap-x-2 ">
        <Controller
          control={control}
          name="number"
          render={({ field }) => <NumberInput label="Invoice #" onChange={field.onChange} value={field.value} />}
        />
        <Controller
          control={control}
          name="status"
          render={({ field }) => {
            return <SelectSalesOrderInvoiceStatus label="Status" value={field.value} onChange={field.onChange} />;
          }}
        />

        <Controller
          control={control}
          name="type"
          render={({ field }) => {
            return <SelectSalesOrderInvoiceType label="Type" value={field.value} onChange={field.onChange} />;
          }}
        />
      </div>

      <div className="grid grid-cols-8 gap-x-2 ">
        <div className="col-span-2">
          <Controller
            control={control}
            name="paymentTerms"
            render={({ field }) => {
              return (
                <SelectSalesOrderInvoicePaymentTerm
                  label="Payment Terms"
                  value={field.value}
                  onChange={field.onChange}
                />
              );
            }}
          />
        </div>

        <div className="col-span-2">
          <Controller
            control={control}
            name="shippingAmount"
            render={({ field }) => {
              return (
                <CurrencyInput
                  label="Shipping Amount"
                  value={field.value}
                  onValueChange={field.onChange}
                  placeholder="0"
                />
              );
            }}
          />
        </div>
        <div className="col-span-2">
          <Controller
            control={control}
            name="taxPercent"
            render={({ field }) => {
              return <PercentInput label="Tax" onChange={field.onChange} value={field.value} />;
            }}
          />
        </div>
      </div>

      <Controller
        control={control}
        name="message"
        render={({ field }) => {
          return <TextAreaInput label="Message" value={field.value} onChange={field.onChange} />;
        }}
      />

      <H4 className="mb-2">
        Items
        <AddButton
          onClick={() =>
            append({
              inventoryItem: null,
              pricePerUnit: 0,
              quantity: 0,
              units: "",
              description: "",
            })
          }
        />
      </H4>

      <table className="table-fixed">
        <thead>
          <tr className="border-b border-gray-700 ">
            <th className="px-2 py-2 resize-x w-1/12 text-xs text-left">#</th>
            <th className="py-2 text-xs text-left" style={{ maxWidth: "20px" }}></th>
            <th className="px-2 py-2 resize-x w-4/12 text-xs text-left">Item</th>
            <th className="px-2 py-2 text-xs text-left">Price per unit</th>
            <th className="px-2 py-2 text-xs text-left">Quantity</th>
            <th className="px-2 py-2 text-xs text-left">Units</th>
            <th className="px-2 py-2 w-3/12 text-xs text-left">Description</th>
            <th className="px-2 py-2 text-xs text-left"></th>
          </tr>
        </thead>
        <tbody>
          {fields?.map((field, index) => {
            const even = index % 2 === 0;
            const modifiers = values?.lineItems?.[index]?.modifiers;

            return (
              <React.Fragment key={field?._id}>
                <tr className={even ? "bg-gray-200 dark:bg-gray-700" : ""}>
                  <td className="px-2">
                    <div>{index + 1}. </div>
                  </td>
                  <td className="p-0">
                    <AddButton
                      onClick={() => {
                        setValue(`lineItems.${index}.modifiers` as const, [
                          ...modifiers,
                          { name: "", pricePerUnit: 0 },
                        ]);
                      }}
                    />
                  </td>
                  <td className="">
                    <Controller
                      control={control}
                      name={`lineItems.${index}.inventoryItem` as const}
                      render={({ field }) => {
                        return <SelectInventoryItemModalInput value={field.value} onChange={field.onChange} />;
                      }}
                    />
                  </td>
                  <td className="">
                    <Controller
                      control={control}
                      name={`lineItems.${index}.pricePerUnit` as const}
                      defaultValue={0}
                      render={({ field }) => {
                        return (
                          <NumberFormat
                            className="dark:text-white bg-transparent rounded-none appearance-none leading-normal px-2 border border-transparent focus:border-blue-400 focus:outline-none focus:shadow-none"
                            allowNegative={false}
                            allowLeadingZeros={false}
                            fixedDecimalScale
                            decimalScale={2}
                            onValueChange={(v) => field.onChange(v?.floatValue ?? 0)}
                            value={field.value}
                            prefix="$"
                          />
                        );
                      }}
                    />
                  </td>
                  <td className="">
                    <Controller
                      control={control}
                      name={`lineItems.${index}.quantity` as const}
                      defaultValue={0}
                      render={({ field }) => {
                        return (
                          <NumberFormat
                            className="dark:text-white bg-transparent rounded-none appearance-none leading-normal px-2 border border-transparent focus:border-blue-400 focus:outline-none focus:shadow-none"
                            allowNegative={false}
                            allowLeadingZeros={false}
                            onValueChange={(v) => field.onChange(v?.floatValue ?? 0)}
                            value={field.value}
                          />
                        );
                      }}
                    />
                  </td>
                  <td className="">
                    <Controller
                      control={control}
                      name={`lineItems.${index}.units` as const}
                      render={({ field }) => {
                        return (
                          <input
                            className="dark:text-white bg-transparent rounded-none appearance-none leading-normal px-2 border border-transparent focus:border-blue-400 focus:outline-none focus:shadow-none"
                            onChange={field.onChange}
                            value={field.value}
                          />
                        );
                      }}
                    />
                  </td>
                  <td className="">
                    <Controller
                      control={control}
                      name={`lineItems.${index}.description` as const}
                      render={({ field }) => {
                        return (
                          <textarea
                            className="dark:text-white bg-transparent rounded-none appearance-none leading-normal px-2 border border-transparent focus:border-blue-400 focus:outline-none focus:shadow-none w-full"
                            onChange={field.onChange}
                            value={field.value}
                          />
                        );
                      }}
                    />
                  </td>
                  <td>
                    <IconButton onClick={() => remove(index)} iconProps={{ icon: faTimes, size: "sm" }} />
                  </td>
                </tr>
                {modifiers?.map((m, midx) => {
                  return (
                    <tr key={`${field?._id}-${midx}-mod`}>
                      <td className=""></td>
                      <td className=""></td>
                      <td className="">
                        <input
                          value={m?.name ?? ""}
                          className="dark:text-white bg-transparent rounded-none appearance-none leading-normal px-2 border border-transparent focus:border-blue-400 focus:outline-none focus:shadow-none"
                          placeholder="Modifier name"
                          onChange={(e) => {
                            setModifierValue(index, midx, "name", e?.target?.value);
                          }}
                        />
                      </td>
                      <td className="">
                        <NumberFormat
                          className="dark:text-white bg-transparent rounded-none appearance-none leading-normal px-2 border border-transparent focus:border-blue-400 focus:outline-none focus:shadow-none"
                          allowNegative={false}
                          allowLeadingZeros={false}
                          fixedDecimalScale
                          decimalScale={2}
                          prefix="$"
                          placeholder="Price per unit"
                          onValueChange={(v) => {
                            setModifierValue(index, midx, "pricePerUnit", v?.floatValue);
                          }}
                          value={m?.pricePerUnit}
                        />
                      </td>
                      <td className="">
                        <IconButton
                          type="button"
                          onClick={() => removeModifierValue(index, midx)}
                          iconProps={{
                            color: colors.factoryfinchRed,
                            size: "xs",
                            icon: faMinusCircle,
                          }}
                        />
                      </td>
                      <td className=""></td>
                      <td className=""></td>
                      <td className=""></td>
                    </tr>
                  );
                })}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export const EditInvoice: React.FC<{ id: string; onCancel: () => void; onEdit: () => void }> = ({
  id,
  onCancel,
  onEdit,
}) => {
  const toast = useToast();
  const { data, loading } = useGetSalesOrderInvoiceQuery({ fetchPolicy: "network-only", variables: { id } });
  const mutationOpts = { refetchQueries: [{ query: GetSalesOrderInvoiceDocument, variables: { id } }] };
  const [update] = useUpdateSalesOrderInvoiceMutation(mutationOpts);

  const form = useForm<IEditInvoiceForm>({
    defaultValues: {
      lineItems: [],
    },
  });
  const invoice = data?.getSalesOrderInvoice;

  const save = useCallback(
    async (formData: IEditInvoiceForm) => {
      try {
        await update({
          variables: {
            input: {
              id: invoice?.id,
              version: invoice?.version,
              number: `${formData.number}`,
              type: formData.type,
              status: formData.status,
              message: formData.message,
              shippingAmount: formData.shippingAmount,
              taxPercent: formData.taxPercent,
              paymentTerms: formData.paymentTerms,
              lineItems:
                formData?.lineItems.map<SalesOrderInvoiceLineItemInput>((li, lidx) => ({
                  id: li.id,
                  inventoryItemId: li?.inventoryItem?.id,
                  pricePerUnit: li.pricePerUnit,
                  quantity: li.quantity,
                  units: li.units,
                  description: li.description,
                  modifiers: li?.modifiers?.map((m) => ({ name: m?.name, pricePerUnit: m?.pricePerUnit })) ?? [],
                })) ?? [],
            },
          },
        });
        onEdit();
      } catch (err) {
        toast.error(`Unable to edit invoice: ${err.message}`);
      }
    },
    [update, invoice?.id, invoice?.version, onEdit, toast]
  );

  useEffect(() => {
    if (invoice) {
      form.reset({
        number: invoice?.number,
        status: invoice?.status,
        type: invoice?.type,
        paymentTerms: invoice?.paymentTerms,
        taxPercent: invoice?.taxPercent,
        shippingAmount: invoice?.shippingAmount,
        message: invoice?.message,
        lineItems: invoice?.lineItems ?? [],
      });
    }
  }, [form, form.reset, invoice]);

  if (loading) {
    return <LoadingAnimationWithLabel message="Fetching Invoice" />;
  }

  return (
    <FormProvider {...form}>
      <ScrollableForm
        enableSubmitOnDirty={false}
        formState={form.formState}
        onSubmit={form.handleSubmit(save)}
        onCancel={onCancel}
        cancelLabel="Close"
        submitLabel="Save"
        classNames={{ buttonContainer: "w-full lg:w-1/3" }}
      >
        <H4>Edit Invoice </H4>
        <SalesOrderInvoiceForm />
      </ScrollableForm>
    </FormProvider>
  );
};

export const CreateInvoice: React.FC<{
  orderId: string;
  defaults: { lineItems: IEditInvoiceFormLineItem[] };
  onCancel: () => void;
  onCreate: () => void;
}> = ({ orderId, defaults, onCancel, onCreate }) => {
  const toast = useToast();
  const [create] = useCreateSalesOrderInvoiceMutation();

  const form = useForm<IEditInvoiceForm>({
    defaultValues: {
      status: SalesOrderInvoiceStatus.Draft,
      type: SalesOrderInvoiceType.Proforma,
      shippingAmount: 0,
      taxPercent: 0,
      lineItems: defaults?.lineItems ?? [],
    },
  });

  const save = useCallback(
    async (formData: IEditInvoiceForm) => {
      try {
        await create({
          variables: {
            input: {
              salesOrderId: orderId,
              type: formData.type,
              status: formData.status,
              message: formData.message,
              shippingAmount: formData.shippingAmount,
              taxPercent: formData.taxPercent,
              paymentTerms: formData.paymentTerms,
              lineItems:
                formData?.lineItems.map<SalesOrderInvoiceLineItemInput>((li, lidx) => ({
                  id: li.id,
                  inventoryItemId: li?.inventoryItem?.id,
                  pricePerUnit: li.pricePerUnit,
                  quantity: li.quantity,
                  units: li.units,
                  description: li.description,
                  modifiers: li?.modifiers ?? [],
                })) ?? [],
            },
          },
        });
        onCreate();
      } catch (err) {
        toast.error("Unable to create invoice");
      }
    },
    [create, onCreate, orderId, toast]
  );

  return (
    <FormProvider {...form}>
      <ScrollableForm
        formState={form.formState}
        onSubmit={form.handleSubmit(save)}
        onCancel={onCancel}
        submitLabel="Save"
        classNames={{ buttonContainer: "w-full lg:w-1/3" }}
      >
        <H4>New Invoice</H4>
        <SalesOrderInvoiceForm />
      </ScrollableForm>
    </FormProvider>
  );
};
