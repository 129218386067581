import {
  useGetOrganizationQuery,
  IntegrationType,
  Integration,
} from "generated/graphql";

export const useIntegrations = () => {
  const { data, loading, refetch } = useGetOrganizationQuery();

  const integrations = data?.organization?.integrations?.reduce(
    (acc, integration) => {
      acc[integration.type] = integration;
      return acc;
    },
    {} as Record<IntegrationType, Integration>
  );

  return {
    refetch,
    loading,
    hasConnectedAccounts: data?.organization?.integrations?.length > 0,
    [IntegrationType?.Shopify]:
      integrations?.[IntegrationType?.Shopify] ?? null,
    [IntegrationType?.BigCommerce]:
      integrations?.[IntegrationType?.BigCommerce] ?? null,
  };
};
