import React from "react";
import { PhoneNumber } from "components/Form/PhoneInput";
import { getRegion, getCountry } from "components/Form/CountryAndRegion";

interface IAddress {
  address1?: string;
  address2?: string;
  address3?: string;
  city?: string;
  region?: string;
  state?: string;
  country?: string;
  postalCode?: string;
  phoneNumber?: string;
  nickname?: string;
}

export const Address: React.FC<{ data?: IAddress }> = ({ data }) => {
  return (
    <div className="text-xs">
      <div className="text-base font-semibold">{data?.nickname !== "" ? data?.nickname : ""}</div>
      <div>{data?.address1 !== "" ? data?.address1 : "<Address1>"}</div>
      <div>{data?.address2 ?? "<Address2>"}</div>
      {data?.address3 && <div>{data?.address3 ?? ""}</div>}
      <div>{`${data?.city !== "" ? data?.city : "<City>"}, ${
        getRegion(data?.country, data?.state || data?.region)?.shortCode ?? "<State>"
      } ${getCountry(data?.country)?.countryShortCode ?? "<Country>"}`}</div>
      <div>{data?.postalCode}</div>
      <PhoneNumber value={data?.phoneNumber} />
    </div>
  );
};
