import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { CenterModal } from "components/Modals/CenterModal";
import { Confirmation } from "components/Modals/Confirmation";
import { SideModal } from "components/Modals/SideModal";
import TitleWithLoading from "components/TitleWithLoading";
import {
  ListCustomerOrdersDocument,
  useDeleteCustomerOrdersMutation,
  useGetOrganizationQuery,
} from "generated/graphql";
import { useToast } from "hooks/toast";
import React, { useCallback } from "react";
import { H4, HR } from "styles";
import { CustomerOrdersProvider, useTable } from "./context";
import Filters, { FiltersHeader } from "./Filters";
import { ImportOrders } from "./ImportOrders";
import Table from "./Table";

const MoreActionsMenu = () => {
  const getOrgQuery = useGetOrganizationQuery();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { setimportOrder } = useTable();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showButton = !!(getOrgQuery?.data?.organization?.integrations?.length > 0);

  if (!showButton) {
    return null;
  }

  return (
    <>
      <button
        type="button"
        data-testid="btn-import-from-csv"
        className="mx-3 px-3 py-2 border border-gray-700 rounded"
        onClick={handleClick}
      >
        More Actions{" "}
        <span className="ml-2">
          <FontAwesomeIcon icon={faCaretDown} />
        </span>
      </button>
      <Menu
        id="inventory-more-actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {getOrgQuery?.data?.organization?.integrations?.length > 0 && (
          <MenuItem onClick={() => setimportOrder(true)}>Sync Orders</MenuItem>
        )}
      </Menu>
    </>
  );
};

const CustomerOrders = () => {
  const toast = useToast();

  const {
    setisDeletingCustomerOrders,
    isDeletingCustomerOrders,
    variables,
    setShowFilters,
    showFilters,
    loading,
    listCustomerOrders,
    importOrder,
    setimportOrder,
  } = useTable();
  const [deleteCustomerOrders, deleteCustomerOrdersMutation] = useDeleteCustomerOrdersMutation({
    refetchQueries: [{ query: ListCustomerOrdersDocument, variables }],
  });

  const onConfirmDelete = useCallback(async () => {
    const ids = isDeletingCustomerOrders.map((order) => order?.id);
    try {
      await deleteCustomerOrders({
        variables: {
          ids,
        },
      });
    } catch (err) {
      toast.error("Unable to delete products");
    } finally {
      setisDeletingCustomerOrders(null);
    }
  }, [deleteCustomerOrders, isDeletingCustomerOrders, setisDeletingCustomerOrders, toast]);

  return (
    <>
      <div className="py-2 px-2 flex-1 flex flex-col overflow-hidden">
        <div className="flex justify-between my-2">
          <TitleWithLoading title="Sales Orders" loading={loading} refresh={() => listCustomerOrders({ variables })} />
          <div className="flex items-center">
            <MoreActionsMenu />

            {/* <Link to={Routes.CreateCustomerOrder}>
              <ButtonWithIcon className="bg-brand" icon={faPlusCircle}>
                Create Order
              </ButtonWithIcon>
            </Link> */}
          </div>
        </div>

        <FiltersHeader />
        <Table />
      </div>

      <CenterModal isOpen={importOrder} onRequestClose={() => setimportOrder(false)}>
        <ImportOrders
          onRequestClose={() => {
            setimportOrder(false);
            listCustomerOrders({ variables });
          }}
        />
      </CenterModal>

      <Confirmation
        loading={deleteCustomerOrdersMutation?.loading}
        isOpen={isDeletingCustomerOrders?.length > 0}
        onRequestClose={() => setisDeletingCustomerOrders(null)}
        onConfirm={onConfirmDelete}
      >
        <div className="my-4">
          <H4 className="py-4">Delete {isDeletingCustomerOrders?.length} sales orders?</H4>
          <HR />
        </div>
      </Confirmation>
      <SideModal side="RIGHT" isOpen={showFilters} onRequestClose={() => setShowFilters(false)} className="w-1/4">
        <Filters />
      </SideModal>
    </>
  );
};

const Container = () => {
  return (
    <CustomerOrdersProvider>
      <CustomerOrders />
    </CustomerOrdersProvider>
  );
};

export { Container as default };
