import React, { useEffect } from "react";
import { BaseModal } from "react-spring-modal";

type BottomModalPosition = "BOTTOM" | "TOP";

interface BottomModalProps {
  side?: BottomModalPosition;
  className?: string;
  onRequestClose: () => void;
  isOpen: boolean;
  disableEscKey?: boolean;
  onOpened?: () => void;
  onClosed?: () => void;
}

export const BottomModal: React.FC<BottomModalProps> = ({
  isOpen,
  onRequestClose,
  children,
  side = "BOTTOM",
  className = "",
  disableEscKey = false,
  onOpened = () => {},
  onClosed = () => {},
}) => {
  const translate = side === "TOP" ? "-100%" : "100%";
  const baseClassName = `h-screen bg-white dark:bg-gray-800 text-default dark:text-white px-4 py-6 w-full xl:w-5/6`;

  // Close on Escape
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onRequestClose();
      }
    };
    if (globalThis.window && !disableEscKey) {
      window.addEventListener("keyup", listener);
    }

    return () => {
      if (globalThis.window && !disableEscKey) {
        window.removeEventListener("keyup", listener);
      }
    };
  }, [onRequestClose, disableEscKey]);

  return (
    <BaseModal
      isOpen={isOpen}
      onDismiss={() => {}}
      overlayProps={{
        className: "z-50 flex justify-center",
      }}
      contentTransition={{
        from: { transform: `translateY(${translate})` },
        enter: { transform: "translateY(0%)" },
        leave: { transform: `translateY(${translate})` },
      }}
      transitionConfig={{
        onRest: (isOpen, animationStatus) => {
          if (isOpen && animationStatus === "update") {
            onOpened();
          }

          if (isOpen === false && animationStatus === "update") {
            onClosed();
          }
        },
      }}
      contentProps={{ className: baseClassName }}
    >
      {children}
    </BaseModal>
  );
};
