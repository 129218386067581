import React, { useCallback } from "react";
import cx from "classnames";
import Checkbox from "components/Form/Checkbox";
import { SelectLocations } from "components/Select/Location";
import { InventoryItemType } from "generated/graphql";
import { TagInput } from "components/Form/TagInput";
import { useTable } from "./context";
import { TagInputWSuggest } from "components/Form/TagInput";

export const QuickFilters = () => {
  const { filters, showQuickFilters, setFilters } = useTable();

  const toggleType = useCallback(
    (type: InventoryItemType) => {
      const types = new Set(filters.types ?? []);
      if (types.has(type)) {
        types.delete(type);
      } else {
        types.add(type);
      }

      setFilters({ ...filters, types: Array.from(types) });
    },
    [setFilters, filters]
  );

  return (
    <>
      <div className={cx(!showQuickFilters && "hidden", "grid grid-cols-1 lg:grid-cols-4 gap-x-2 gap-y-2 py-2")}>
        <div>
          <label className="text-xs">Location</label>
          <SelectLocations value={filters.locations} onChange={(locations) => setFilters({ ...filters, locations })} />
        </div>

        <div>
          <label className="text-xs">Name contains</label>

          <TagInput tags={filters.names} onChange={(names: string[]) => setFilters({ ...filters, names })} />
        </div>

        <TagInputWSuggest
          label="Has tag"
          onChange={(tag) => {
            setFilters({
              ...filters,
              tags: [...(filters?.tags ?? []), tag],
            });
          }}
        />

        <div className="col-span-1 lg:col-span-2">
          <label className="text-xs">Item Type</label>
          <div className="flex flex-col md:flex-row">
            <Checkbox
              data-testid="quickFilter-Products-raw"
              className="text-sm"
              onChange={(e) => toggleType(InventoryItemType.RawMaterial)}
              label="Raw Materials"
              checked={filters?.types?.includes(InventoryItemType.RawMaterial)}
            />

            <Checkbox
              data-testid="quickFilter-Products-int"
              className="text-sm"
              onChange={(e) => toggleType(InventoryItemType.IntermediateGood)}
              label="Int. Goods"
              checked={filters?.types?.includes(InventoryItemType.IntermediateGood)}
            />

            <Checkbox
              data-testid="quickFilter-Products-fin"
              className="text-sm"
              onChange={(e) => toggleType(InventoryItemType.FinishedGood)}
              label="Finished Goods"
              checked={filters?.types?.includes(InventoryItemType.FinishedGood)}
            />

            <Checkbox
              data-testid="quickFilter-Products-draft"
              className="text-sm"
              onChange={(e) => toggleType(InventoryItemType.Unknown)}
              label="Draft"
              checked={filters?.types?.includes(InventoryItemType.Unknown)}
            />
          </div>
        </div>
      </div>
    </>
  );
};
