import React, { useState } from "react";
import { GetManufacturingOrderDocument, useCreateTriggerMutation, TriggerResourceType } from "generated/graphql";
import { useParams } from "react-router-dom";
import { BlueButton, PlainButton } from "styles";
import TextInput from "components/Form/TextInput";
import { triggerOptions, TriggerOption } from "./triggerOptions";
import { ManufacturingOrderParams } from "./types";
import { Select } from "styles";
import { useToast } from "hooks/toast";
import { PhoneInput } from "components/Form/PhoneInput";

interface AddTriggerProps {
  onRequestClose: () => void;
}

const AddTrigger: React.FC<AddTriggerProps> = ({ onRequestClose }) => {
  const toast = useToast();
  const { id } = useParams<ManufacturingOrderParams>();
  const [createTrigger] = useCreateTriggerMutation({
    refetchQueries: [{ query: GetManufacturingOrderDocument, variables: { id } }],
  });
  const [newTrigger, setnewTrigger] = useState<any>(null);
  const [newTriggerData, setnewTriggerData] = useState<any>({});
  const [newTriggerDescription, setnewTriggerDescription] = useState("");

  const create = async () => {
    try {
      await createTrigger({
        variables: {
          input: {
            resourceId: id,
            resourceType: TriggerResourceType.ManufacturingOrder,
            description: newTriggerDescription,
            type: newTrigger?.type,
            data: Object.keys(newTriggerData).map((key) => ({
              key,
              value: newTriggerData[key],
            })),
          },
        },
      });
      onRequestClose();
    } catch (err) {
      toast.error("unable to add trigger");
    }
  };

  return (
    <div className="p-4">
      <div className="py-4 mb-4 text-lg border-b border-gray-400">Add Trigger</div>
      <Select
        className="mb-2"
        onChange={(trigger: TriggerOption) => {
          setnewTriggerData({});
          setnewTrigger(trigger);
        }}
        value={newTrigger}
        options={triggerOptions}
        getOptionLabel={(o: TriggerOption) => o.label}
        getOptionValue={(o: TriggerOption) => o.type}
      />

      <div className="text-xs font-semibold">Description</div>
      <TextInput
        placeholder="Add a description"
        className="mb-2"
        onChange={(e) => setnewTriggerDescription(e.target.value)}
      />
      {newTrigger?.data?.map((d: any, i: number) => {
        if (d?.type === "phoneNumber") {
          return (
            <React.Fragment key={`trigger-opt-${i}`}>
              <div className="text-xs font-semibold mt-2">{d?.label}</div>
              <PhoneInput
                className="mb-2"
                placeholder={d?.placeholder}
                value={newTriggerData?.[d?.key]}
                onChange={(value) => {
                  setnewTriggerData({ ...newTriggerData, [d?.key]: value });
                }}
              />
            </React.Fragment>
          );
        }

        return (
          <React.Fragment key={`trigger-opt-${i}`}>
            <div className="text-xs font-semibold mt-2">{d?.label}</div>
            <TextInput
              className="mb-2"
              key={`trg-key-${d?.key}`}
              placeholder={d?.placeholder}
              onChange={(e) => {
                setnewTriggerData({
                  ...newTriggerData,
                  [d?.key]: e.target.value,
                });
              }}
            />
          </React.Fragment>
        );
      })}

      <div className="flex">
        <PlainButton type="button" onClick={onRequestClose} className="w-1/2">
          Cancel
        </PlainButton>

        <BlueButton className="w-1/2 ml-2" onClick={create}>
          Create
        </BlueButton>
      </div>
    </div>
  );
};

export { AddTrigger as default };
