import React from "react";
import cx from "classnames";
import { IconButton, Tooltip } from "styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleRight,
  faAngleLeft,
} from "@fortawesome/free-solid-svg-icons";
interface IPagination {
  loading?: boolean;
  totalPages: number;
  canNextPage: boolean;
  canPreviousPage: boolean;
  currentPage: number;
  setPagination: ({ page: number }) => void;
}

const Pagination: React.FC<IPagination> = ({
  loading = false,
  totalPages = 1,
  canNextPage = false,
  canPreviousPage = false,
  currentPage = 1,
  setPagination,
}) => {
  if (totalPages === 0) {
    return null;
  }

  return (
    <div className="flex justify-between items-center px-2">
      <div className="flex flex-row">
        <Tooltip arrow title="First Page">
          <IconButton
            className={cx(
              "px-2 py-1 opacity-75 hover:opacity-100",
              canPreviousPage ? "text-black dark:text-white" : "text-gray-400"
            )}
            onClick={() =>
              canPreviousPage ? setPagination({ page: 1 }) : null
            }
          >
            <FontAwesomeIcon icon={faAngleDoubleLeft} />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="Previous Page">
          <IconButton
            className={cx(
              "px-2 py-1 opacity-75 hover:opacity-100",
              canPreviousPage ? "text-black dark:text-white" : "text-gray-400"
            )}
            onClick={() =>
              canPreviousPage ? setPagination({ page: currentPage - 1 }) : null
            }
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </IconButton>
        </Tooltip>
      </div>
      <span>
        Page{" "}
        <strong>
          {currentPage} of {!loading ? totalPages : "--"}
        </strong>{" "}
      </span>

      <div className="flex flex-row">
        <Tooltip arrow title="Next Page">
          <IconButton
            className={cx(
              "px-2 py-1 opacity-75 hover:opacity-100",
              canNextPage ? "text-black dark:text-white" : "text-gray-400"
            )}
            onClick={() =>
              canNextPage ? setPagination({ page: currentPage + 1 }) : null
            }
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </IconButton>
        </Tooltip>

        <Tooltip arrow title="Last Page">
          <IconButton
            className={cx(
              "px-2 py-1",
              canNextPage ? "text-black dark:text-white" : "text-gray-400"
            )}
            onClick={() =>
              canNextPage ? setPagination({ page: totalPages }) : null
            }
          >
            <FontAwesomeIcon icon={faAngleDoubleRight} />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

export { Pagination as default };
