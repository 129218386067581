import { faCheckCircle, faEllipsisH, faShareSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StepNodeProps } from "components/EventGraph/types";
import { ManufacturingOrderStepStatus, useGetManufacturingOrderQuery } from "generated/graphql";
import React from "react";
import { useParams } from "react-router-dom";
import { Tooltip } from "styles";
import { medDateTimeWithSeconds } from "utils/formatters";
import { getWorkOrderStepByWorkflowStepId } from "./types";

const StepNode: React.FC<StepNodeProps> = ({ node }) => {
  const { id } = useParams<{ id: string }>();
  const { data } = useGetManufacturingOrderQuery({
    variables: { id },
  });

  const thisStep = getWorkOrderStepByWorkflowStepId(node?.properties.id, data);

  const isComplete = thisStep?.status === ManufacturingOrderStepStatus.Complete;
  const createdBy = thisStep?.event?.createdBy;

  return (
    <div className="px-2 py-2 rounded relative">
      <div className="font-semibold mr-2 dark:text-gray-800">{node?.properties?.step?.name}</div>
      <div className="text-sm cursor-pointer text-blue-400 focus:outline-none ">
        {node?.properties?.step?.properties?.length} properties
      </div>

      <div className="my-2 flex justify-end">
        {node?.properties?.step?.promptOperatorToConsumeInventory && (
          <Tooltip arrow placement="bottom" title="This step consumes inventory">
            <span>
              <FontAwesomeIcon size="lg" icon={faShareSquare} className="text-gray-400" />
            </span>
          </Tooltip>
        )}
      </div>
      {isComplete && (
        <div className="text-xs my-2">
          <div className="text-gray-600 ">{medDateTimeWithSeconds(thisStep?.event?.createdAt)}</div>
          <div
            className="text-gray-600 truncate"
            title={createdBy?.__typename === "Employee" ? createdBy?.name : createdBy?.email}
          >
            {createdBy?.__typename === "Employee" ? createdBy?.name : createdBy?.email}
          </div>
        </div>
      )}
      {isComplete && (
        <div className="absolute top-0 right-0 text-sm p-1 text-green-600">
          <FontAwesomeIcon icon={isComplete ? faCheckCircle : faEllipsisH} />
        </div>
      )}
    </div>
  );
};

export { StepNode as default };
