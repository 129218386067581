import React from "react";
import ConnectShopify from "components/Views/Account/Integrations/Shopify";
import TitleWithLoading from "components/TitleWithLoading";

export const Integrations = () => {
  return (
    <div className="py-2 px-2 w-full">
      <TitleWithLoading title="Authorized Apps" />

      <ConnectShopify />
    </div>
  );
};
