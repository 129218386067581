import { QueryTuple } from "@apollo/client";
import { LocalStorageKeys } from "config";
import {
  Customer,
  Exact,
  InventoryItem,
  ListManufacturingOrdersDocument,
  ListManufacturingOrdersFilters,
  ListManufacturingOrdersQuery,
  ListManufacturingOrdersQueryVariables,
  ManufacturingOrder,
  ManufacturingOrderStatus,
  OrderBy,
  OrderByDirection,
  PageInfo,
  Pagination,
  ResourceTag,
  useAddGroupColorToOrdersMutation,
  useDeleteManufacturingOrderMutation,
  useListManufacturingOrdersLazyQuery,
  useRestoreManufacturingOrderMutation,
  useRestoreManufacturingOrdersMutation,
  Workflow,
} from "generated/graphql";
import { useToast } from "hooks/toast";
import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useLocalStorage, useSessionStorage } from "react-use";
import { getRouteWithParams, Routes } from "routes";
import { useImmerReducer } from "use-immer";
import { notEmpty } from "utils/filters";
import { shortId } from "utils/formatters";
import { generatePdfLabels } from "utils/labels";

const LABEL_TITLE = "WORK ORDER";

interface IFilters {
  ids?: string[];
  workflows?: Workflow[];
  inventoryItems?: InventoryItem[];
  inventoryItemName?: string;
  customers?: Customer[];
  statuses?: ManufacturingOrderStatus[];
  showCancelled?: boolean;
  tags?: ResourceTag[];
  inventoryTags?: ResourceTag[];
}

interface StateIFace {
  filters: IFilters;
  orderBy: OrderBy;
  pagination: PageInfo;
  showFilters: boolean;
  bookmark: string;
  selected: Set<React.Key>;
  addTagsToOrders: boolean;
  addGroupColorToOrders: boolean;
  exportAsCSV: boolean;
  viewQR: ManufacturingOrder;
  isBulkChangingWorkflow: boolean;
  showQuickFilters: boolean;
}

type SetFilters = {
  type: Actions.SetFilters;
  data: IFilters;
};

type SetOrderBy = {
  type: Actions.SetOrderBy;
  data: OrderBy;
};

type SetBookmark = {
  type: Actions.SetBookmark;
  data: string;
};

type SetSelected = {
  type: Actions.SetSelected;
  data: Set<React.Key>;
};

type Reset = {
  type: Actions.Reset;
};

type SetExportAsCSV = {
  type: Actions.SetExportAsCSV;
  data: boolean;
};

type SetAddTagsToOrders = {
  type: Actions.SetAddTagsToOrders;
  data: boolean;
};

type SetAddGroupColorToOrders = {
  type: Actions.SetAddGroupColorToOrders;
  data: boolean;
};

type SetShowFilters = {
  type: Actions.SetShowFilters;
  data: boolean;
};

type SetPagination = {
  type: Actions.SetPagination;
  data: { perPage: number; page: number };
};

type SetViewQR = {
  type: Actions.SetViewQR;
  data: ManufacturingOrder;
};

type SetBulkChangeWorkflow = {
  type: Actions.SetBulkChangeWorkflow;
  data: boolean;
};

type SetShowQuickFilters = {
  type: Actions.SetShowQuickFilters;
  data: boolean;
};

type DispatcherAction =
  | SetExportAsCSV
  | SetFilters
  | SetShowFilters
  | SetAddGroupColorToOrders
  | SetAddTagsToOrders
  | SetSelected
  | SetBookmark
  | SetPagination
  | SetViewQR
  | SetBulkChangeWorkflow
  | Reset
  | SetOrderBy
  | SetShowQuickFilters;

enum Actions {
  SetExportAsCSV = "SET_EXPORT_AS_CSV",
  SetFilters = "SET_FILTERS",
  SetPagination = "SET_PAGINATION",
  SetShowFilters = "SET_SHOW_FILTERS",
  SetViewQR = "SET_VIEW_QR",
  SetAddGroupColorToOrders = "SET_ADD_GROUP_COLOR_TO_ORDERS",
  SetAddTagsToOrders = "SET_ADD_TAGS_TO_ORDERS",
  SetBulkChangeWorkflow = "SET_BULK_CHANGE_WORKFLOW",
  SetSelected = "SET_SELECTED_ROWS",
  SetBookmark = "SET_BOOKMARK",
  SetPerPage = "SET_PER_PAGE",
  SetPage = "SET_PAGE",
  Reset = "RESET",
  SetOrderBy = "SET_ORDER_BY",
  SetShowQuickFilters = "SET_SHOW_QUICK_FILTERS",
}

const removeAtIndex = (items: any[], idx: number) => {
  return [...items.slice(0, idx), ...items.slice(idx + 1)];
};

const defaultFilters: IFilters = {
  ids: [],
  workflows: [],
  inventoryItems: [],
  customers: [],
  tags: [],
  inventoryTags: [],
  inventoryItemName: null,
  showCancelled: false,
  statuses: [ManufacturingOrderStatus.InProgress, ManufacturingOrderStatus.NotStarted],
};

const initialState: StateIFace = {
  bookmark: "",
  filters: defaultFilters,
  pagination: { perPage: 10, page: 1 },
  selected: new Set<React.Key>(),
  addTagsToOrders: false,
  addGroupColorToOrders: false,
  showFilters: false,
  exportAsCSV: false,
  isBulkChangingWorkflow: false,
  viewQR: null,
  showQuickFilters: true,
  orderBy: {
    key: "createdAt",
    direction: OrderByDirection.Desc,
  },
};

const FILTERS_KEY = LocalStorageKeys.WorkOrderFilters;
const BOOKMARK_KEY = LocalStorageKeys.WorkOrderBookmark;

const ordersCtx = createContext<{
  query: QueryTuple<
    ListManufacturingOrdersQuery,
    Exact<{
      pagination?: Pagination;
      filters?: ListManufacturingOrdersFilters;
    }>
  >;
  state: StateIFace;

  dispatch: React.Dispatch<DispatcherAction>;
}>({ query: null, state: initialState, dispatch: () => null });

export const WorkOrdersProvider: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const query = useListManufacturingOrdersLazyQuery({
    fetchPolicy: "network-only",
  });

  const [filters, setFilters] = useSessionStorage<{
    filters: IFilters;
    pagination: PageInfo;
    showQuickFilters: boolean;
  }>(FILTERS_KEY, {
    filters: initialState.filters,
    pagination: initialState.pagination,
    showQuickFilters: initialState.showQuickFilters,
  });
  const [bookmark, setBookmark, removeBookmark] = useLocalStorage(BOOKMARK_KEY, "");

  const [state, dispatch] = useImmerReducer(
    (state: StateIFace, action: DispatcherAction) => {
      switch (action.type) {
        case Actions.SetExportAsCSV:
          state.exportAsCSV = action.data;
          break;
        case Actions.SetFilters:
          state.filters = action.data;
          break;
        case Actions.SetShowQuickFilters:
          state.showQuickFilters = action.data;
          break;
        case Actions.SetPagination:
          state.pagination = action.data;
          break;
        case Actions.SetShowFilters:
          state.showFilters = action.data;
          break;

        case Actions.SetBookmark:
          state.bookmark = action.data;
          break;

        case Actions.SetOrderBy:
          state.orderBy = action.data;
          break;

        case Actions.SetSelected:
          state.selected = action.data;
          break;

        case Actions.SetAddTagsToOrders:
          state.addTagsToOrders = action.data;
          break;

        case Actions.SetAddGroupColorToOrders:
          state.addGroupColorToOrders = action.data;
          break;

        case Actions.Reset:
          state.selected = initialState.selected;
          break;

        case Actions.SetBulkChangeWorkflow:
          state.isBulkChangingWorkflow = action.data;
          break;

        case Actions.SetViewQR:
          state.viewQR = action.data;
          break;
        default:
          throw new Error("problem with ManufacturingOrdersProvider");
      }
    },
    { ...initialState, bookmark, ...filters }
  );

  useEffect(() => {
    if (state?.bookmark === "") {
      removeBookmark();
    } else {
      setBookmark(state?.bookmark);
    }
  }, [state, setBookmark, removeBookmark]);

  useEffect(() => {
    setFilters({
      filters: state.filters,
      pagination: state.pagination,
      showQuickFilters: state.showQuickFilters,
    });
  }, [state, setFilters]);

  return (
    <ordersCtx.Provider
      value={{
        query,
        state,
        dispatch,
      }}
    >
      {children}
    </ordersCtx.Provider>
  );
};

export const useTable = () => {
  const { query, state, dispatch } = useContext(ordersCtx);

  const [listManufacturingOrders, { data, loading, variables: qvariables }] = query;
  const mutationOpts = {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ListManufacturingOrdersDocument, variables: qvariables }],
  };
  const [addGroupColor] = useAddGroupColorToOrdersMutation(mutationOpts);
  const [restoreManufacturingOrder] = useRestoreManufacturingOrderMutation(mutationOpts);
  const [deleteManufacturingOrder] = useDeleteManufacturingOrderMutation(mutationOpts);
  const [restoreManufacturingOrders] = useRestoreManufacturingOrdersMutation(mutationOpts);

  const setBookmark = (id: string) => {
    dispatch({ type: Actions.SetBookmark, data: id });
  };

  const setselected = useCallback(
    (rows: Set<React.Key>) => dispatch({ type: Actions.SetSelected, data: rows }),
    [dispatch]
  );

  const setexportAsCSV = (open: boolean) => {
    dispatch({ type: Actions.SetExportAsCSV, data: open });
  };

  const setaddTagsToOrders = (open: boolean) => {
    dispatch({ type: Actions.SetAddTagsToOrders, data: open });
  };

  const setaddGroupColorToOrders = (open: boolean) => {
    dispatch({ type: Actions.SetAddGroupColorToOrders, data: open });
  };

  const setBulkChangeWorkflow = (open: boolean) => {
    dispatch({ type: Actions.SetBulkChangeWorkflow, data: open });
  };

  const setShowFilters = useCallback(
    (show: boolean) => {
      dispatch({ type: Actions.SetShowFilters, data: show });
    },
    [dispatch]
  );

  const setShowQuickFilters = useCallback(
    (show: boolean) => {
      dispatch({ type: Actions.SetShowQuickFilters, data: show });
    },
    [dispatch]
  );

  const resetFilters = () => {
    setFilters(defaultFilters);
  };

  const setPagination = useCallback(
    (pagination: Pagination) => {
      const isChangingPerPage = !!(pagination?.perPage && pagination?.perPage !== state?.pagination?.perPage);
      const page = isChangingPerPage ? 1 : pagination?.page || state?.pagination?.page;
      const perPage = pagination?.perPage || state?.pagination?.perPage || 10;
      dispatch({
        type: Actions.SetPagination,
        data: {
          page,
          perPage,
        },
      });
    },
    [dispatch, state.pagination]
  );

  const setOrderBy = useCallback(
    (key: string, direction: OrderByDirection) => {
      dispatch({ type: Actions.SetOrderBy, data: { key, direction } });
    },
    [dispatch]
  );

  const setFilters = (filters: IFilters) => {
    dispatch({ type: Actions.SetFilters, data: filters });
  };

  const removeFilter = (type: string, idx: number = -1) => {
    if (state?.filters?.[type]?.length < 1) {
      return;
    }
    let defaultValue: string | boolean = "";
    if (type === "showCancelled") {
      defaultValue = false;
    }
    setFilters({
      ...state?.filters,
      [type]: idx > -1 ? removeAtIndex(state?.filters?.[type], idx) : defaultValue,
    });
  };

  const setViewQR = (order: ManufacturingOrder) => {
    dispatch({ type: Actions.SetViewQR, data: order });
  };

  const variables: ListManufacturingOrdersQueryVariables = useMemo(
    (): ListManufacturingOrdersQueryVariables => ({
      orderBy: state?.orderBy ?? null,
      filters: {
        ids: state.filters?.ids ?? [],
        workflowIds: state.filters?.workflows?.map((workflow) => workflow?.id)?.filter(notEmpty) ?? [],
        customerIds: state.filters?.customers?.map((customer) => customer?.id)?.filter(notEmpty) ?? [],
        inventoryItemName: state?.filters?.inventoryItemName,
        inventoryItemIds: state.filters?.inventoryItems?.map((product) => product?.id)?.filter(notEmpty) ?? [],
        statuses: state.filters?.statuses ?? [],
        showCancelled: state.filters?.showCancelled ?? false,
        tags:
          state.filters?.tags?.map((tag) => {
            return { tagId: tag.tag.id, value: tag?.value ?? null };
          }) ?? [],
        inventoryTags:
          state.filters?.inventoryTags?.map((tag) => {
            return { tagId: tag.tag.id, value: tag?.value ?? null };
          }) ?? [],
      },
      pagination: state.pagination,
    }),

    [state.pagination, state.filters, state.orderBy]
  );

  const selectedRows = useMemo(() => {
    return (
      data?.listManufacturingOrders?.orders?.filter((order) => {
        return state.selected.has(order.id);
      }) ?? []
    );
  }, [data?.listManufacturingOrders?.orders, state.selected]);

  return {
    ...state,
    selectedRows,
    setFilters,
    setPagination,
    removeFilter,
    listManufacturingOrders,
    addGroupColor,
    data,
    loading,
    variables,
    setexportAsCSV,
    setaddGroupColorToOrders,
    setaddTagsToOrders,
    setBookmark,
    setselected,
    setShowFilters,
    resetFilters,
    setViewQR,
    restoreManufacturingOrder,
    restoreManufacturingOrders,
    deleteManufacturingOrder,
    setBulkChangeWorkflow,
    setOrderBy,
    setShowQuickFilters,
  };
};

export type RowActions =
  | "view"
  | "printLabel"
  | "qrCode"
  | "generateLabels"
  | "exportCSV"
  | "addGroupIndicator"
  | "addTags"
  | "cancelOrders"
  | "cancelWorkOrder"
  | "restoreWorkOrder"
  | "bulkChangeWorkflow"
  | "updateWorkOrders"
  | "restoreWorkOrders";

const transformLabel = (o: ManufacturingOrder) => ({
  title: LABEL_TITLE,
  qrcode: o?.id,
  line1: `ID: ${shortId(o?.id)}`,
  line2: `Quantity: ${o.quantity.toString(10)}`,
  line3: `Item: ${o?.inventoryItem?.name ?? "Unknown"}`,
});

export const useRowActions = () => {
  const {
    selectedRows,
    setexportAsCSV,
    setaddGroupColorToOrders,
    setaddTagsToOrders,
    setViewQR,
    restoreManufacturingOrder,
    deleteManufacturingOrder,
    setBulkChangeWorkflow,
    restoreManufacturingOrders,
  } = useTable();

  const history = useHistory();
  const toast = useToast();
  const deleteWorkOrders = async (orders: ManufacturingOrder[]) => {
    const deleteRequests = orders.map((order) =>
      deleteManufacturingOrder({
        variables: { id: order.id },
      })
    );
    if (deleteRequests.length < 1) {
      return;
    }

    try {
      await Promise.all(deleteRequests);
      toast.success(`${deleteRequests?.length} orders cancelled`);
    } catch (err) {
      toast.error(err?.message ?? "Unable to cancel orders");
    }
  };

  const handleAction: (action: RowActions, order: ManufacturingOrder) => void = (action, order: ManufacturingOrder) => {
    switch (action) {
      case "view":
        history.push(getRouteWithParams(Routes.WorkOrder, { id: order.id }));
        break;
      case "printLabel":
        generatePdfLabels(LABEL_TITLE, [order], transformLabel);
        break;
      case "qrCode":
        setViewQR(order);
        break;
      case "generateLabels":
        generatePdfLabels(LABEL_TITLE, selectedRows, transformLabel);

        break;
      case "exportCSV":
        setexportAsCSV(true);
        break;
      case "addGroupIndicator":
        setaddGroupColorToOrders(true);
        break;
      case "addTags":
        setaddTagsToOrders(true);
        break;
      case "cancelOrders":
        deleteWorkOrders(
          selectedRows?.filter(
            (s) => s.status !== ManufacturingOrderStatus.Cancelled && s.status !== ManufacturingOrderStatus.Complete
          )
        );
        break;

      case "cancelWorkOrder":
        deleteWorkOrders([order]);
        break;

      case "restoreWorkOrder":
        restoreManufacturingOrder({ variables: { id: order.id } });
        break;

      case "restoreWorkOrders":
        restoreManufacturingOrders({ variables: { ids: selectedRows?.map((s) => s.id) } });
        break;

      case "bulkChangeWorkflow":
        setBulkChangeWorkflow(true);

        break;

      default:
        break;
    }
  };

  return handleAction;
};
