import React from "react";
import {
  useCreateStepMutation,
  Step,
  useListStepsQuery,
} from "generated/graphql";
import { useToast } from "hooks/toast";
import { AsyncSelect, AsyncCreatableSelect } from "styles";
import { useDelayedQuery } from "hooks/delayedQuery";
interface ISelectStep {
  disabled?: boolean;
  name?: string;
  className?: string;
  placeholder?: string;
  onChange: (v: Step | Step[]) => void;
  size?: "base" | "large";
  allowCreate?: boolean;
  isMulti?: boolean;
  value?: Step | Step[];
}

const SelectStep: React.FC<ISelectStep> = ({
  disabled = false,
  placeholder,
  onChange,
  className = "",
  name = "",
  size = "base",
  allowCreate = false,
  isMulti = false,
  value,
}) => {
  const toast = useToast();

  const { refetch } = useListStepsQuery({
    variables: { pagination: { page: 0, perPage: 10 } },
  });
  const [createStep, createStepMutation] = useCreateStepMutation();

  const handleCreate = async (inputValue: string) => {
    const step = await createStep({
      variables: { input: { name: inputValue.trim() } },
    });
    const newStep = step.data?.createStep;
    if (Array.isArray(value)) {
      onChange([...value, newStep]);
    } else {
      onChange(newStep);
    }
    toast.success(`Created step ${inputValue}`);
  };

  const { handleInputChange, delayedQuery } = useDelayedQuery({
    refetch: async (inputValue: string) => {
      const resp = await refetch({ filters: { like: inputValue } });
      return resp?.data?.listSteps?.steps ?? [];
    },
  });
  const placeholderText =
    placeholder || (isMulti ? "Select Steps..." : "Select a Step...");

  if (!allowCreate) {
    return (
      <AsyncSelect
        isDisabled={disabled}
        value={value}
        placeholder={placeholderText}
        isMulti={isMulti}
        name={name}
        isClearable
        cacheOptions
        defaultOptions
        isLoading={createStepMutation?.loading}
        loadOptions={delayedQuery}
        className={className}
        onInputChange={handleInputChange}
        onChange={onChange}
        getOptionLabel={(opt: any) => {
          if (opt?.__isNew__) {
            return opt?.label;
          }

          return opt?.name;
        }}
        getOptionValue={(opt: Step) => opt?.id}
        $textSize={size}
      />
    );
  }

  return (
    <AsyncCreatableSelect
      isDisabled={disabled}
      placeholder={placeholderText}
      name={name}
      value={value}
      isClearable
      cacheOptions={false}
      defaultOptions
      isLoading={createStepMutation?.loading}
      loadOptions={delayedQuery}
      className={className}
      onInputChange={handleInputChange}
      onCreateOption={handleCreate}
      onChange={onChange}
      allowCreateWhileLoading={false}
      $textSize={size}
      getOptionLabel={(opt: any) => {
        if (opt?.__isNew__) {
          return opt?.label;
        }

        return opt?.name;
      }}
      getOptionValue={(opt: Step) => opt?.id}
    />
  );
};

export { SelectStep as default };
