import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faBolt, faCheckSquare, faCog, faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import WorkflowBadge from "components/Badges/Workflow";
import OrderStatus from "components/Badges/WorkOrderStatus";
import Pagination from "components/Pagination";
import { SelectPagination } from "components/Select/Pagination";
import StyledTable from "components/Table";
import { EmptyRowsRenderer, LoadingRenderer, RowRenderer } from "components/Table/Renderers";
import { TextWithTooltip } from "components/Tooltip";
import { LocalStorageKeys } from "config";
import { ManufacturingOrder, OrderByDirection, ReservedTagNames } from "generated/graphql";
import {
  OptionalColumnsProvider,
  useColumns,
  ITableColumnDefinitions,
  buildDynamicColumn,
} from "hooks/useOptionalColumns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Column, DataGridProps, SortDirection, FormatterProps } from "react-data-grid";
import { Link } from "react-router-dom";
import { getRouteWithParams, Routes } from "routes";
import { IconButton, Tooltip } from "styles";
import { shortDateTime, shortId } from "utils/formatters";
import { useTable } from "./context";
import { ContextMenu, MENU_ID } from "./ContextMenu";
import { EditColumns } from "components/Table/EditColumns";

const columnDefinitions: ITableColumnDefinitions<ManufacturingOrder> = {
  required: [
    {
      headerRenderer: (props) => {
        return (
          <div
            className="flex justify-center items-center h-full w-full text-brand cursor-pointer text-lg py-2"
            onClick={(e) => props.onAllRowsSelectionChange(!props?.allRowsSelected)}
          >
            <FontAwesomeIcon icon={props?.allRowsSelected ? faCheckSquare : faSquare} />
          </div>
        );
      },
      formatter: (props) => {
        return (
          <div
            className={cx("flex justify-center items-center h-full w-full text-brand cursor-pointer text-lg py-2")}
            onClick={(e) => props.onRowSelectionChange(!props?.isRowSelected, e.shiftKey)}
          >
            <FontAwesomeIcon icon={props?.isRowSelected ? faCheckSquare : faSquare} />
          </div>
        );
      },
      frozen: true,
      key: "select-row",
      name: "",
      resizable: false,
      sortable: false,

      width: 35,
      maxWidth: 35,
    },
    {
      key: "group-color",
      name: "",
      width: 35,
      maxWidth: 35,
      frozen: true,
      resizable: false,
      cellClass: "flex justify-center items-center",
      formatter: (props) => {
        const backgroundColor = props?.row.tags?.find((t) => t.name === ReservedTagNames.FfGroupColor)?.value;

        const groupStyle = backgroundColor ? { height: "10px", width: "10px", backgroundColor } : {};
        return <div className="rounded-full" style={groupStyle} />;
      },
    },
    {
      key: "id",
      name: "ID",
      width: 100,
      frozen: true,
      resizable: false,
      formatter: (props) => {
        return (
          <Link
            title={props?.row?.id}
            to={getRouteWithParams(Routes.WorkOrder, {
              id: props?.row?.id,
            })}
            className={`${
              props?.row?.deletedAt ? "text-gray-400 dark:text-gray-200 line-through" : ""
            } hover:underline truncate`}
          >
            {shortId(props?.row?.id)}
          </Link>
        );
      },
    },
  ],
  optional: {
    "product-name": {
      key: "product-name",
      name: "Item Name",
      resizable: true,
      formatter: (props) => {
        return (
          <div className="truncate" title={props?.row?.inventoryItem?.name}>
            {props?.row?.inventoryItem?.name}
          </div>
        );
      },
    },
    quantity: {
      key: "quantity",
      name: "Quantity",
      resizable: true,
      cellClass: "text-center",
      headerCellClass: "text-center",
    },
    status: {
      key: "status",
      name: "Status",
      resizable: true,
      cellClass: "text-center",
      headerCellClass: "text-center",
      formatter: (props) => {
        return <OrderStatus status={props?.row?.status} />;
      },
    },
    createdAt: {
      key: "createdAt",
      name: "Created",
      resizable: false,
      cellClass: "text-center text-xs flex justify-center items-center",
      headerCellClass: "text-center",

      sortable: true,

      formatter: (props) => {
        return <>{shortDateTime(props?.row?.createdAt)}</>;
      },
    },
    workflow: {
      key: "workflow",
      name: "Workflow",
      resizable: true,
      cellClass: "text-center",
      headerCellClass: "text-center",
      formatter: (props) => {
        return <WorkflowBadge workflow={props.row.workflowVersion} />;
      },
    },
    "last-event": {
      key: "last-event",
      name: "Last Event",
      resizable: true,
      cellClass: "",
      headerCellClass: "text-center",
      width: 100,
      maxWidth: 300,

      formatter: (props) => {
        const lastEvent = props.row?.lastEvent;
        const msg = `${lastEvent?.type ?? ""}  ${lastEvent?.name ?? ""}`;
        return (
          <div>
            <TextWithTooltip title={msg} />
          </div>
        );
      },
    },
  },
};

const TableHeader = () => {
  const { setPagination, pagination, setShowFilters, setShowQuickFilters, showQuickFilters, selected } = useTable();
  const { setIsModifying } = useColumns();
  return (
    <div className="flex justify-between items-center py-2">
      <div className="px-2">{selected.size > 0 && <div>{selected.size} rows selected</div>}</div>
      <div className="flex">
        <div className="flex flex-row items-center px-2">
          <Tooltip arrow title={showQuickFilters ? `Hide quick filters` : `Show quick filters`}>
            <IconButton
              className={showQuickFilters ? `text-yellow-500 ` : ``}
              onClick={() => setShowQuickFilters(!showQuickFilters)}
            >
              <FontAwesomeIcon icon={faBolt} size="lg" />
            </IconButton>
          </Tooltip>

          <Tooltip arrow title="Filters">
            <IconButton className="" onClick={() => setShowFilters(true)}>
              <FontAwesomeIcon icon={faSlidersH} size="lg" />
            </IconButton>
          </Tooltip>

          <Tooltip arrow title="Edit Columns">
            <IconButton className="" onClick={() => setIsModifying(true)}>
              <FontAwesomeIcon icon={faCog} size="lg" />
            </IconButton>
          </Tooltip>
        </div>
        <SelectPagination value={pagination?.perPage} onChange={(perPage) => setPagination({ perPage })} />
      </div>
    </div>
  );
};

const Table = () => {
  const {
    setselected,
    pagination,
    setPagination,
    selected,
    data,
    loading,
    listManufacturingOrders,
    variables,
    setOrderBy,
    orderBy,
  } = useTable();
  const { keys, columns } = useColumns();

  useEffect(() => {
    listManufacturingOrders({
      variables,
    });
  }, [listManufacturingOrders, variables]);

  const [rows, setrows] = useState<ManufacturingOrder[]>([]);

  const handleSort = useCallback(
    (columnKey: string, direction: SortDirection) => {
      setOrderBy(columnKey, direction === "DESC" ? OrderByDirection.Desc : OrderByDirection.Asc);
    },
    [setOrderBy]
  );

  useEffect(() => {
    setrows(data?.listManufacturingOrders?.orders ?? []);
  }, [data?.listManufacturingOrders?.orders]);

  const c = useMemo<Column<ManufacturingOrder, unknown>[]>(() => {
    const opt = Array.from(keys)
      .filter((k) => columnDefinitions.optional?.[k] && !columns?.[k]?.hidden)
      .map((k) => columnDefinitions.optional?.[k]);
    const dynamic = Array.from(keys)
      .filter((k) => !columnDefinitions.optional?.[k] && !columns?.[k]?.hidden)
      .map((k) => columns[k]);

    return [
      ...columnDefinitions.required,
      ...opt,
      ...dynamic.map((col, idx) =>
        buildDynamicColumn(col, idx, (props: React.PropsWithChildren<FormatterProps<ManufacturingOrder, unknown>>) => {
          const t = props?.row?.tags.find((t) => t?.tagId === col?.key);

          return <div className="truncate">{t?.value ?? ""}</div>;
        })
      ),
    ];
  }, [keys, columns]);
  return (
    <>
      <TableHeader />
      <div style={{ flex: "1 1 auto" }} className="overflow-auto">
        <StyledTable<React.FC<DataGridProps<ManufacturingOrder>>>
          rowHeight={56}
          rowKeyGetter={(r: ManufacturingOrder) => r?.id}
          rowRenderer={(props) => <RowRenderer contextMenuId={MENU_ID} {...props} />}
          columns={c}
          onSort={handleSort}
          sortColumn={orderBy.key}
          sortDirection={orderBy.direction}
          rows={rows}
          selectedRows={selected}
          onSelectedRowsChange={setselected}
          emptyRowsRenderer={() =>
            loading ? (
              <LoadingRenderer message="Fetching work orders" />
            ) : (
              <EmptyRowsRenderer message="No work orders found" />
            )
          }
        />
      </div>
      <Pagination
        loading={loading}
        currentPage={pagination?.page ?? 1}
        totalPages={data?.listManufacturingOrders?.pageInfo?.totalPages}
        canNextPage={pagination?.page < data?.listManufacturingOrders?.pageInfo?.totalPages}
        canPreviousPage={data?.listManufacturingOrders?.pageInfo?.page > 1 ?? false}
        setPagination={setPagination}
      />
      <ContextMenu />
      <EditColumns<ManufacturingOrder> optionalColumns={columnDefinitions.optional} />
    </>
  );
};

const Container = () => {
  return (
    <OptionalColumnsProvider
      storageKey={LocalStorageKeys.WorkOrderTableColumns}
      keys={["product-name", "quantity", "status", "createdAt", "workflow", "last-event"]}
    >
      <Table />
    </OptionalColumnsProvider>
  );
};

export { Container as default };
