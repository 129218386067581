import React, { useEffect } from "react";
import { useGetInvitationByKeyQuery, useAcceptInvitationMutation } from "generated/graphql";
import { useQueryString } from "hooks/queryString";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Routes } from "routes";
import { useForm } from "react-hook-form";
import { useToast } from "hooks/toast";
import { DarkRedButton, Form, P } from "styles";
import TextInput from "components/Form/TextInput";
import { WhiteLogo } from "components/Logo";
import BackgroundImage from "components/BackgroundImage";
import { ButtonLoading } from "components/Loading";
import { useAuth } from "hooks/auth";

const Invitation: React.FC = () => {
  const history = useHistory();
  const toast = useToast();
  const qs = useQueryString();
  const { isLoggedIn, logout } = useAuth();
  const { data, loading, error } = useGetInvitationByKeyQuery({
    variables: { key: qs.get("key") },
  });
  const [acceptInvitation, acceptInvitationMutation] = useAcceptInvitationMutation();
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    shouldFocusError: true,
  });

  const onSubmit = async (formData: { password: string }) => {
    try {
      await acceptInvitation({
        variables: {
          input: {
            invitationId: data?.getInvitationByKey?.id,
            password: formData?.password,
          },
        },
      });
      history.push(Routes.Login);
    } catch (err) {
      console.log(err);
      toast.error("Unable to accept invitation 😭");
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      logout();
    }
  }, [isLoggedIn, logout]);

  if (loading) {
    return (
      <div className="flex flex-col items-center w-full h-screen bg-brand">
        <BackgroundImage />
        <div className="mt-32">
          <WhiteLogo width={200} />
        </div>
        <ButtonLoading className="text-white" />
      </div>
    );
  }

  if ((!loading && !data) || error) {
    return <Redirect to={Routes.Login} />;
  }

  return (
    <div className="flex flex-col items-center w-full h-screen bg-brand">
      <BackgroundImage />
      <div className="mt-32">
        <WhiteLogo width={200} />
      </div>
      <P className="text-white">{`Hi ${data?.getInvitationByKey?.email}!`}</P>

      <P className="text-white">Set your password below.</P>
      <Form onSubmit={handleSubmit(onSubmit)} className="flex flex-col p-4 mt-4 w-full sm:w-4/6 md:w-3/6 lg:w-2/6">
        <TextInput type="hidden" {...register("username")} value={data?.getInvitationByKey?.email ?? ""} />
        <TextInput
          autoComplete="new-password"
          type="password"
          {...register("password", {
            minLength: {
              value: 6,
              message: "Password must be at least 6 characters long",
            },
          })}
          placeholder="Password"
          className="mb-2"
        />
        {errors.password && errors.password.type === "required" && <span>Password is Required</span>}

        <DarkRedButton>{loading || acceptInvitationMutation?.loading ? <ButtonLoading /> : "Submit"}</DarkRedButton>
      </Form>
      <Link className="m-auto mt-4 text-white" to={Routes.Login}>
        Cancel
      </Link>
    </div>
  );
};

export { Invitation as default };
