import { faTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { omit } from "lodash";
import React, { createContext, useCallback, useContext, useEffect } from "react";
import { Column, FormatterProps, HeaderRendererProps } from "react-data-grid";
import { useLocalStorage, useSessionStorage } from "react-use";
import { useImmerReducer } from "use-immer";
import { removeAtIndex } from "utils/filters";

export interface ITableColumnDefinitions<T> {
  required: Column<T, unknown>[];
  optional: Record<string, Column<T, unknown>>;
}

export const buildDynamicColumn = <T,>(
  col: any,
  idx: number,
  formatter: (props: React.PropsWithChildren<FormatterProps<T, unknown>>) => JSX.Element
) => {
  return {
    key: col?.key || `col-${idx}`,
    name: col?.label,
    minWidth: 100,
    resizable: true,
    headerRenderer: (props: HeaderRendererProps<T>) => {
      return (
        <div className="truncate">
          <FontAwesomeIcon icon={faTag} className="text-sm mr-2" />
          {props?.column?.name}
        </div>
      );
    },
    formatter,
  };
};

enum Actions {
  SetIsModifying = "SET_IS_MODIFYING",
  ShowColumn = "SHOW_COLUMN",
  HideColumn = "HIDE_COLUMN",
  AddColumn = "ADD_COLUMN",
  RemoveColumn = "REMOVE_COLUMN",
  Reset = "RESET",
}

type ShowColumn = {
  type: Actions.ShowColumn;
  data: string;
};

type HideColumn = {
  type: Actions.HideColumn;
  data: string;
};

type AddColumn = {
  type: Actions.AddColumn;
  data: {
    key: string;
    label: string;
  };
};

type RemoveColumn = {
  type: Actions.RemoveColumn;
  data: string;
};

type SetIsModifying = {
  type: Actions.SetIsModifying;
  data: boolean;
};

type Reset = {
  type: Actions.Reset;
};

type DispatcherAction = ShowColumn | HideColumn | AddColumn | RemoveColumn | SetIsModifying | Reset;

interface StateIFace {
  keys: string[];
  columns: Record<string, { label?: string; hidden?: boolean; key?: string; added?: boolean }>;
  isModifying: boolean;
}

const initialState: StateIFace = {
  keys: [],
  columns: {},
  isModifying: false,
};

const ctx = createContext<{
  save: Function;
  state: StateIFace;
  dispatch: React.Dispatch<DispatcherAction>;
}>({
  save: null,
  state: initialState,
  dispatch: () => null,
});

export const OptionalColumnsProvider: React.FC<React.PropsWithChildren<{ storageKey: string; keys: string[] }>> = ({
  storageKey,
  keys,
  children,
}) => {
  const [sessionStorage, setColumnConfig] = useSessionStorage<StateIFace>(storageKey, initialState);
  const [stored, save] = useLocalStorage<StateIFace>(storageKey, null);

  const cols = {
    ...sessionStorage,
    ...stored,
  };

  const [state, dispatch] = useImmerReducer(
    (state: StateIFace, action: DispatcherAction) => {
      switch (action.type) {
        case Actions.AddColumn:
          if (state.keys.indexOf(action.data.key) === -1) {
            state.keys.push(action.data.key);
            state.columns[action.data.key] = { ...action.data, added: true, hidden: false };
          }
          break;
        case Actions.RemoveColumn:
          if (state.columns[action.data]?.added) {
            state.columns = omit(state.columns, action.data);
            state.keys = removeAtIndex(state.keys, state.keys.indexOf(action.data));
          }
          break;
        case Actions.ShowColumn:
          if (!state.columns[action.data]) {
            state.columns[action.data] = { hidden: false };
          } else {
            state.columns[action.data].hidden = false;
          }
          break;
        case Actions.HideColumn:
          if (!state.columns[action.data]) {
            state.columns[action.data] = { hidden: true };
          } else {
            state.columns[action.data].hidden = true;
          }
          break;

        case Actions.SetIsModifying:
          state.isModifying = action.data;
          break;

        case Actions.Reset:
          state.keys = keys;
          state.columns = {};

          break;

        default:
          break;
      }
    },
    { ...cols, keys: cols?.keys?.length > 0 ? cols.keys : keys }
  );

  useEffect(() => {
    setColumnConfig(state);
  }, [state, setColumnConfig]);

  return (
    <ctx.Provider
      value={{
        save,
        state,
        dispatch,
      }}
    >
      {children}
    </ctx.Provider>
  );
};

export const useColumns = () => {
  const { state, dispatch, save } = useContext(ctx);

  const add = (column: { label: string; key: string }) => {
    dispatch({ type: Actions.AddColumn, data: column });
  };

  const remove = (key: string) => {
    dispatch({ type: Actions.RemoveColumn, data: key });
  };

  const show = (key: string) => {
    dispatch({ type: Actions.ShowColumn, data: key });
  };

  const hide = (key: string) => {
    dispatch({ type: Actions.HideColumn, data: key });
  };

  const reset = () => {
    dispatch({ type: Actions.Reset });
  };

  const setIsModifying = (open: boolean) => {
    dispatch({ type: Actions.SetIsModifying, data: open });
  };

  const saveDefaultColumns = useCallback(() => {
    save({ ...state, isModifying: false });
  }, [state, save]);

  return {
    ...state,
    add,
    show,
    hide,
    remove,
    setIsModifying,
    reset,
    saveDefaultColumns,
  };
};
