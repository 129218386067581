import React from "react";
import { PropertyType } from "generated/graphql";
import { Select } from "styles";

interface SelectPropertyTypeOption {
  label: PropertyType;
  value: PropertyType;
}

const toSelectPropertyTypeOption = (type: PropertyType) => {
  if (!type) {
    return null;
  }
  return {
    label: type,
    value: type,
  };
};

const fromSelectPropertyTypeOption = (option: SelectPropertyTypeOption) => {
  return option.value;
};

const SelectPropertyType: React.FC<{
  value: PropertyType;
  onChange: (value: PropertyType) => void;
}> = ({ value, onChange }) => {
  return (
    <Select
      placeholder="Select Type..."
      value={toSelectPropertyTypeOption(value)}
      options={[
        {
          label: PropertyType.String,
          value: PropertyType.String,
        },
        {
          label: PropertyType.Number,
          value: PropertyType.Number,
        },
        { label: PropertyType.Image, value: PropertyType.Image },
      ]}
      onChange={(value: SelectPropertyTypeOption) =>
        onChange(fromSelectPropertyTypeOption(value))
      }
    />
  );
};

export { SelectPropertyType as default };
