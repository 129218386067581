import React from "react";
import { ParseResult } from "papaparse";
import { fromInventoryType } from "components/Form/helpers";
import { InventoryItemType, useBulkCreateInventoryItemsMutation, CreateInventoryItemInput } from "generated/graphql";
import { chunk } from "lodash";
import { ImportFromCSV, ITemplateRow, IImportFrom } from "./index";

interface ImportInventoryTemplateRow extends ITemplateRow {
  name: string;
  sku: string;
  units: string;
  type: InventoryItemType;
  onHand: string;
  valuePerUnit: string;
}

export const ImportInventoryFromCSV: React.FC<IImportFrom> = ({ isOpen, onRequestClose }) => {
  const [createInventoryItems] = useBulkCreateInventoryItemsMutation();
  const importInventoryItems = async (
    parsed: ParseResult<ImportInventoryTemplateRow> & { file: File },
    tags: Record<string, string>,
    onProgress: (progress: number) => void
  ) => {
    const { data: rows } = parsed;

    const inventoryItems: CreateInventoryItemInput[] = rows.map((row) => {
      return {
        name: row.name,
        units: row?.units,
        tags:
          Object.keys(tags)
            ?.filter((name) => row?.[name] !== "")
            ?.map((name) => ({
              tagId: tags[name],
              value: row[name],
            })) ?? null,
        valuePerUnit: row?.valuePerUnit ? parseFloat(row?.valuePerUnit) : 0,
        onHand: row?.onHand ? parseFloat(row?.onHand) : 0,
        sku: row?.sku,
        idempotencyKey: row?.sku,
        ...fromInventoryType(row?.type),
      };
    });
    const chunks = chunk(inventoryItems, 100);
    const requestchunks = chunk(chunks, 1);
    for (let i = 0; i < requestchunks.length; i++) {
      const requests = requestchunks[i];
      const waitFor = requests.map((items) =>
        createInventoryItems({
          variables: {
            input: items,
          },
        })
      );
      await Promise.all(waitFor);
      onProgress(Math.floor(((i + 1) / requestchunks.length) * 100));
    }
  };

  return (
    <ImportFromCSV
      createTags
      type="inventory items"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      templateURL="/assets/inventory-import-template.csv"
      importFn={importInventoryItems}
    />
  );
};
