import React, { useState, useCallback } from "react";
import {
  useGetManufacturingOrderQuery,
  GetManufacturingOrderDocument,
  ManufacturingOrderStepStatus,
  WorkflowStep,
  PropertyType,
  useUndoWorkOrderStepMutation,
  Property,
  GetStreamDocument,
  GetStepEventPropertyUrlQuery,
  GetStepEventPropertyUrlQueryVariables,
  GetStepEventPropertyUrlDocument,
} from "generated/graphql";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCloudDownloadAlt, faEllipsisH, faEye } from "@fortawesome/free-solid-svg-icons";
import { useWorkOrderEventGraph } from "components/EventGraph/context";
import { animated, useSpring } from "react-spring";
import { BlueButton, IconButton, SmallRedButton, Tooltip } from "styles";
import { ButtonLoading } from "components/Loading";
import { useToast } from "hooks/toast";
import { useQueue } from "hooks/queue";
import CompleteStepsInQueue from "./CompleteStepsInQueue";
import { getWorkOrderStepByWorkflowStepId } from "./types";
import { getExt } from "config";
import { saveAs } from "file-saver";
import { useApolloClient } from "@apollo/client";
import { CenterModal } from "components/Modals/CenterModal";

const PropertyValue: React.FC<{ eventId: string; property: Property }> = ({ eventId, property }) => {
  const client = useApolloClient();
  const [view, setview] = useState<string>(null);
  const toast = useToast();
  const fetchSignedUrl = useCallback(
    async (stepPropertyId: string) => {
      try {
        const resp = await client.query<GetStepEventPropertyUrlQuery, GetStepEventPropertyUrlQueryVariables>({
          query: GetStepEventPropertyUrlDocument,
          variables: { eventId, stepPropertyId },
          fetchPolicy: "network-only",
        });
        return resp?.data?.getStepPropertyUrl;
      } catch (err) {
        toast.error("Unable to download image");
      }
    },
    [client, eventId, toast]
  );
  const downloadImage = useCallback(
    async (stepPropertyId: string) => {
      const signedURL = await fetchSignedUrl(stepPropertyId);
      if (signedURL) {
        const url = new URL(signedURL);
        saveAs(signedURL, `${property?.name}.${getExt(url?.pathname ?? "")}`);
      } else {
        toast.error("Unable to download image");
      }
    },
    [fetchSignedUrl, property?.name, toast]
  );

  const viewImage = useCallback(
    async (stepPropertyId: string) => {
      const signedURL = await fetchSignedUrl(stepPropertyId);
      if (signedURL) {
        setview(signedURL);
      } else {
        toast.error("Unable to view image");
      }
    },
    [fetchSignedUrl, toast]
  );

  return (
    <div className="py-2 col-span-2 text-center">
      {property?.value === "" ? (
        <div></div>
      ) : (
        <>
          {property?.type === PropertyType.String && (
            <div className="py-2 col-span-2 text-center truncate">{property.value}</div>
          )}
          {property?.type === PropertyType.Number && (
            <div className="py-2 col-span-2 text-center truncate">{property.value}</div>
          )}
          {property?.type === PropertyType.Image && (
            <div className="flex justify-center">
              <Tooltip title="View Image" arrow>
                <IconButton type="button" className="hover:opacity-75" onClick={() => viewImage(property?.id)}>
                  <FontAwesomeIcon icon={faEye} size="lg" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Download Image" arrow>
                <IconButton type="button" className="hover:opacity-75" onClick={() => downloadImage(property?.id)}>
                  <FontAwesomeIcon icon={faCloudDownloadAlt} size="lg" />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </>
      )}
      <CenterModal isOpen={view !== null} onRequestClose={() => setview(null)}>
        <div className="flex flex-col justify-center items-center">
          {view && <img src={view} alt={property?.name} style={{ minWidth: 200, maxWidth: 500 }} />}
          <div>{property?.name}</div>
        </div>
      </CenterModal>
    </div>
  );
};

const StepActionMenu: React.FC = () => {
  const toast = useToast();

  const { selected, setSelected } = useWorkOrderEventGraph();
  const { id } = useParams<{ id: string }>();
  const { data } = useGetManufacturingOrderQuery({
    variables: { id },
  });

  const [_undoStep, undoStepMutation] = useUndoWorkOrderStepMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GetManufacturingOrderDocument,
        variables: { id },
      },
      {
        query: GetStreamDocument,
        variables: {
          id,
          type: "WORK_ORDER",
        },
      },
    ],
  });

  const undoStep = async () => {
    try {
      await _undoStep({
        variables: {
          input: {
            workOrderId: id,
            workflowStepId: selected.properties.id,
          },
        },
      });
      setSelected({});
    } catch (err) {
      console.log(err);
      toast.error(`Unable to undo "${selected?.properties?.step?.name}"`);
    }
  };

  const selectedStyle = useSpring({
    transform: selected !== null ? "translateY(0%)" : "translateY(-150%)",
  });
  const thisStep = getWorkOrderStepByWorkflowStepId(selected?.properties?.id, data);

  const isComplete = thisStep?.status === ManufacturingOrderStepStatus.Complete;

  const completeQueue = useQueue<WorkflowStep>();

  const completeStep = async () => {
    completeQueue.add(selected?.properties);
  };

  return (
    <>
      <animated.div
        className="absolute top-0 left-0 right-0 z-10 bg-gray-100 py-4 px-2 shadow-lg items-center"
        style={selectedStyle}
      >
        <div className="flex flex-col">
          <div className="flex flex-row justify-between w-full">
            <div title={selected?.properties?.step?.name} className="text-lg dark:text-gray-800 font-semibold">
              {selected?.properties?.step?.name}
            </div>
            {selected && !isComplete && (
              <BlueButton type="button" onClick={completeStep}>
                Complete Step
              </BlueButton>
            )}
            {selected && isComplete && (
              <div className="flex items-center">
                <div className="mx-4 flex items-center">
                  <div className="font-semibold text-gray-600 mr-2">Step Complete</div>
                  <span className="text-2xl text-green-600">
                    <FontAwesomeIcon icon={isComplete ? faCheckCircle : faEllipsisH} />
                  </span>
                </div>
                <SmallRedButton
                  type="button"
                  onClick={async () => {
                    try {
                      undoStep();
                    } catch (err) {
                      toast.error(err?.message ?? "Unable to undo event");
                    }
                  }}
                >
                  {undoStepMutation?.loading ? <ButtonLoading /> : "Undo"}
                </SmallRedButton>
              </div>
            )}
          </div>
          {thisStep?.event?.properties?.length > 0 && (
            <>
              <div className="grid grid-cols-3 py-4 dark:text-gray-800 items-center">
                <div className="border-b border-gray-600 dark:border-gray-300 text-xs font-semibold">Property Name</div>
                <div className="border-b border-gray-600 dark:border-gray-300 col-span-2 text-center text-xs font-semibold">
                  Value
                </div>
                {thisStep?.event?.properties?.map((property) => {
                  return (
                    <React.Fragment key={property?.id}>
                      <div className="py-2">{property.name}</div>
                      <PropertyValue eventId={thisStep?.event?.id} property={property} />
                    </React.Fragment>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </animated.div>

      <CompleteStepsInQueue queue={completeQueue} manufacturingOrderId={id} />
    </>
  );
};

export { StepActionMenu as default };
