import { QueryTuple } from "@apollo/client";
import {
  Exact,
  InventoryItem,
  InventoryItemType,
  ListLocationInventoryDocument,
  ListLocationInventoryFilters,
  ListLocationInventoryQuery,
  ListLocationInventoryQueryVariables,
  Location,
  LocationInventory,
  Pagination,
  Tag,
  useDeleteInventoryItemMutation,
  useListLocationInventoryLazyQuery,
  useRestoreInventoryItemMutation,
  useUpdateLocationInventoryMutation,
  Workflow,
} from "generated/graphql";
import { useToast } from "hooks/toast";
import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo } from "react";
import { useHistory } from "react-router";
import { useSessionStorage } from "react-use";
import { Routes } from "routes";
import { useImmerReducer } from "use-immer";
import { removeFilterHelper } from "utils/filters";
import { shortId } from "utils/formatters";
import { generatePdfLabels } from "utils/labels";

const LABEL_TITLE = "INVENTORY ITEM";

interface ISetUpdateLocationInventory {
  item: LocationInventory;
  location: Location;
}

enum Actions {
  SetPagination = "SET_PAGINATION",
  SetFilters = "SET_FILTERS",
  // SetAddIngredientsToProducts = "SET_ADD_INGREDIENTS_TO_PRODUCTS",
  SetAddTagsToProducts = "SET_ADD_TAGS_TO_PRODUCTS",
  SetIsBulkDeleting = "SET_IS_BULK_DELETING",
  SetShowFilters = "SET_SHOW_FILTERS",
  SetSelected = "SET_SELECTED",
  SetIsAddingProduct = "ADD_PRODUCT",
  SetUpdateLocationInventory = "SET_UPDATE_LOCATION_INVENTORY",
  SetIsBulkUpdatingWorkflow = "BULK_UPDATE_WORKFLOW",
  SetIsEditingProduct = "EDIT_PRODUCT",
  SetIsDeleting = "SET_IS_DELETING",
  SetIsBulkUpdating = "SET_IS_BULK_UPDATING",
  SetShowQuickFilters = "SET_SHOW_QUICK_FILTERS",
  SetViewItemTimeline = "SET_VIEW_ITEM_TIMELINE",
  SetImportFromCsv = "SET_IMPORT_FROM_CSV",
  SetViewQRCode = "SET_VIEW_QR_CODE",
  SetUpdateInventoryItemsFromCsv = "SET_UPDATE_INVENTORY_ITEMS",
}

type SetAddTagsToProducts = {
  type: Actions.SetAddTagsToProducts;
  data: boolean;
};

type SetViewQRCode = {
  type: Actions.SetViewQRCode;
  data: InventoryItem;
};

type SetIsBulkDeleting = {
  type: Actions.SetIsBulkDeleting;
  data: boolean;
};

type SetSelected = {
  type: Actions.SetSelected;
  data: Set<React.Key>;
};

type SetIsAddingProduct = {
  type: Actions.SetIsAddingProduct;
  data: boolean;
};

type SetIsEditingProduct = {
  type: Actions.SetIsEditingProduct;
  data: InventoryItem;
};

type SetIsBulkUpdatingWorkflow = {
  type: Actions.SetIsBulkUpdatingWorkflow;
  data: boolean;
};

type SetViewItemTimeline = {
  type: Actions.SetViewItemTimeline;
  data: LocationInventory;
};

type SetShowFilters = {
  type: Actions.SetShowFilters;
  data: boolean;
};

type SetFilters = {
  type: Actions.SetFilters;
  data: IFilters;
};

type SetPagination = {
  type: Actions.SetPagination;
  data: any;
};

type SetIsDeleting = {
  type: Actions.SetIsDeleting;
  data: InventoryItem;
};

type SetUpdateLocationInventory = {
  type: Actions.SetUpdateLocationInventory;
  data: ISetUpdateLocationInventory;
};

type SetIsBulkUpdating = {
  type: Actions.SetIsBulkUpdating;
  data: boolean;
};

type SetShowQuickFilters = {
  type: Actions.SetShowQuickFilters;
  data: boolean;
};

type SetImportFromCsv = {
  type: Actions.SetImportFromCsv;
  data: boolean;
};

type SetUpdateInventoryItemsFromCsv = {
  type: Actions.SetUpdateInventoryItemsFromCsv;
  data: boolean;
};

type DispatcherAction =
  | SetFilters
  | SetIsDeleting
  | SetPagination
  | SetViewQRCode
  | SetAddTagsToProducts
  | SetIsBulkDeleting
  | SetShowFilters
  | SetSelected
  | SetIsAddingProduct
  | SetIsBulkUpdatingWorkflow
  | SetIsEditingProduct
  | SetUpdateLocationInventory
  | SetIsBulkUpdating
  | SetShowQuickFilters
  | SetViewItemTimeline
  | SetImportFromCsv
  | SetUpdateInventoryItemsFromCsv;

interface IFilters {
  name?: string;
  names?: string[];
  skus?: string[];
  tags?: Array<{ tag?: Tag; value?: string | null }>;
  types?: InventoryItemType[];
  workflows?: Workflow[];
  locations?: Location[];
  showDeleted?: boolean;
}

interface StateIFace {
  selected: Set<React.Key>;
  isBulkDeleting?: boolean;
  isEditingProduct?: InventoryItem;
  isDeleting?: InventoryItem;
  isAddingProduct?: boolean;
  isBulkUpdatingWorkflow?: boolean;
  isBulkUpdating?: boolean;
  showFilters?: boolean;
  addTagsToProducts?: boolean;
  viewQRCode: InventoryItem;
  filters: IFilters;
  pagination: Pagination;
  isUpdatingLocationInventory: ISetUpdateLocationInventory;
  showQuickFilters?: boolean;
  isViewingTimeline?: LocationInventory;
  isImportingFromCsv?: boolean;
  isUpdatingFromCsv?: boolean;
}

const initialState: StateIFace = {
  selected: new Set<React.Key>(),
  isBulkDeleting: false,
  isDeleting: null,
  showFilters: false,
  isEditingProduct: null,
  isAddingProduct: false,
  isBulkUpdatingWorkflow: false,
  isBulkUpdating: false,
  addTagsToProducts: false,
  viewQRCode: null,
  filters: {
    name: null,
    names: [],
    tags: null,
    types: null,
    workflows: null,
    locations: null,
    showDeleted: false,
  },
  pagination: { perPage: 10, page: 1 },
  isUpdatingLocationInventory: null,
  showQuickFilters: true,
  isViewingTimeline: null,
  isImportingFromCsv: false,
  isUpdatingFromCsv: false,
};

const productListContext = createContext<{
  listInventoryQuery: QueryTuple<
    ListLocationInventoryQuery,
    Exact<{
      pagination?: Pagination;
      filters?: ListLocationInventoryFilters;
    }>
  >;
  state: StateIFace;
  dispatch: React.Dispatch<DispatcherAction>;
}>({
  listInventoryQuery: null,
  state: initialState,
  dispatch: () => null,
});

const FILTERS_KEY = "@factoryfinch/productFilters";

export const ProductListProvider: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const listInventoryQuery = useListLocationInventoryLazyQuery({
    fetchPolicy: "network-only",
  });

  const [filters, setFilters] = useSessionStorage<{
    filters: IFilters;
    pagination: Pagination;
    showQuickFilters: boolean;
  }>(FILTERS_KEY, {
    filters: initialState.filters,
    pagination: initialState.pagination,
    showQuickFilters: initialState.showQuickFilters,
  });

  const [state, dispatch] = useImmerReducer(
    (state: StateIFace, action: DispatcherAction) => {
      switch (action.type) {
        case Actions.SetPagination:
          state.pagination = action.data;
          state.selected = new Set<React.Key>();
          break;
        case Actions.SetFilters:
          state.filters = action.data;
          state.pagination.page = 1;
          state.selected = new Set<React.Key>();
          break;

        case Actions.SetIsDeleting:
          state.isDeleting = action.data;
          break;

        case Actions.SetViewQRCode:
          state.viewQRCode = action.data;
          break;
        case Actions.SetSelected:
          state.selected = action?.data;
          break;
        case Actions.SetIsAddingProduct:
          state.isAddingProduct = action?.data;
          break;
        case Actions.SetIsBulkUpdatingWorkflow:
          state.isBulkUpdatingWorkflow = action?.data;
          break;
        case Actions.SetIsEditingProduct:
          state.isEditingProduct = action?.data;
          break;
        case Actions.SetShowFilters:
          state.showFilters = action?.data;
          break;
        case Actions.SetShowQuickFilters:
          state.showQuickFilters = action?.data;
          break;
        case Actions.SetIsBulkDeleting:
          state.isBulkDeleting = action?.data;
          break;
        case Actions.SetAddTagsToProducts:
          state.addTagsToProducts = action.data;
          break;
        case Actions.SetUpdateLocationInventory:
          state.isUpdatingLocationInventory = action.data;
          break;
        case Actions.SetIsBulkUpdating:
          state.isBulkUpdating = action.data;
          break;

        case Actions.SetViewItemTimeline:
          state.isViewingTimeline = action.data;
          break;
        case Actions.SetImportFromCsv:
          state.isImportingFromCsv = action.data;
          break;
        case Actions.SetUpdateInventoryItemsFromCsv:
          state.isUpdatingFromCsv = action.data;
          break;

        default:
          break;
      }
    },
    { ...initialState, ...filters }
  );

  useEffect(() => {
    setFilters({ filters: state.filters, pagination: state.pagination, showQuickFilters: state.showQuickFilters });
  }, [state, setFilters]);

  return (
    <productListContext.Provider value={{ listInventoryQuery, state, dispatch }}>
      {children}
    </productListContext.Provider>
  );
};

export const useTable = () => {
  const { listInventoryQuery, state, dispatch } = useContext(productListContext);
  const [listInventory, { data, loading, variables: qvariables }] = listInventoryQuery;

  const mutationOpts = {
    refetchQueries: [{ query: ListLocationInventoryDocument, variables: qvariables }],
  };
  const [deleteInventoryItem] = useDeleteInventoryItemMutation(mutationOpts);
  const [restoreInventoryItem] = useRestoreInventoryItemMutation(mutationOpts);

  const setSelected = useCallback(
    (rows: Set<React.Key>) => dispatch({ type: Actions.SetSelected, data: rows }),
    [dispatch]
  );

  const setIsAddingProduct = useCallback(
    (open: boolean) => dispatch({ type: Actions.SetIsAddingProduct, data: open }),
    [dispatch]
  );

  const setFilters = useCallback(
    (filterInput: IFilters) => {
      dispatch({
        type: Actions.SetFilters,
        data: filterInput,
      });
    },
    [dispatch]
  );

  const setFilter = useCallback(
    (key: keyof IFilters, value: any) => {
      setFilters({ ...state.filters, [key]: value });
    },
    [setFilters, state.filters]
  );

  const setPagination = useCallback(
    (pagination: Pagination) => {
      const isChangingPerPage = !!(pagination?.perPage && pagination?.perPage !== state?.pagination?.perPage);
      const page = isChangingPerPage ? 1 : pagination?.page || state?.pagination?.page;
      const perPage = pagination?.perPage || state?.pagination?.perPage || 10;
      dispatch({
        type: Actions.SetPagination,
        data: {
          page,
          perPage,
        },
      });
    },
    [dispatch, state.pagination]
  );

  const setEditProduct = useCallback(
    (product: InventoryItem) => dispatch({ type: Actions.SetIsEditingProduct, data: product }),
    [dispatch]
  );

  const setUpdateLocationInventoryInput = useCallback(
    (input: ISetUpdateLocationInventory) => dispatch({ type: Actions.SetUpdateLocationInventory, data: input }),
    [dispatch]
  );

  const setShowFilters = (show: boolean) => dispatch({ type: Actions.SetShowFilters, data: show });
  const setShowQuickFilters = (show: boolean) => dispatch({ type: Actions.SetShowQuickFilters, data: show });

  const setIsBulkDeleting = (isDeleting: boolean) => dispatch({ type: Actions.SetIsBulkDeleting, data: isDeleting });

  const setIsDeleting = (isDeleting: InventoryItem) => dispatch({ type: Actions.SetIsDeleting, data: isDeleting });

  const setIsBulkUpdating = (isBulkUpdating: boolean) =>
    dispatch({ type: Actions.SetIsBulkUpdating, data: isBulkUpdating });

  const setaddTagsToProducts = (open: boolean) => {
    dispatch({ type: Actions.SetAddTagsToProducts, data: open });
  };

  // const setIsBulkAddingIngredients = (open: boolean) => {
  //   dispatch({ type: Actions.SetAddIngredientsToProducts, data: open });
  // };

  const resetFilters = () => {
    setFilters(initialState.filters);
  };

  const removeFilter = (property: string, i: number = -1) => {
    removeFilterHelper(
      state.filters,
      {
        property,
        i,
      },
      setFilters
    );
  };

  const setViewTimeline = useCallback(
    (item: LocationInventory) => {
      dispatch({ type: Actions.SetViewItemTimeline, data: item });
    },
    [dispatch]
  );

  const setimportInventoryItems = useCallback(
    (open: boolean) => {
      dispatch({ type: Actions.SetImportFromCsv, data: open });
    },
    [dispatch]
  );

  const setUpdateInventoryItems = useCallback(
    (open: boolean) => {
      dispatch({ type: Actions.SetUpdateInventoryItemsFromCsv, data: open });
    },
    [dispatch]
  );

  const setViewQRCode = (data: InventoryItem) => {
    dispatch({ type: Actions.SetViewQRCode, data });
  };

  const variables: ListLocationInventoryQueryVariables = useMemo(
    () => ({
      filters: {
        name: state.filters?.name ?? null,
        names: state.filters?.names ?? null,
        skus: state.filters?.skus ?? null,
        types: state.filters?.types ?? null,
        tags:
          state.filters?.tags?.map((tag) => {
            return { tagId: tag.tag.id, value: tag?.value ?? null };
          }) ?? [],

        workflowIds: state.filters?.workflows?.filter((w) => w?.id).map((w) => w?.id) ?? [],
        locationIds: state.filters?.locations?.map((l) => l.id) ?? [],
        showDeleted: state.filters?.showDeleted,
      },
      pagination: state.pagination,
    }),
    [state.pagination, state.filters]
  );

  const selectedRows = useMemo(() => {
    return (
      data?.listLocationInventory?.inventory?.filter((product) => {
        return state.selected.has(product.id);
      }) ?? []
    );
  }, [data?.listLocationInventory?.inventory, state.selected]);

  const refresh = () => listInventory({ variables });

  return {
    ...state,

    selectedRows,
    variables,
    data,
    loading,
    refresh,
    listInventory,
    setSelected,
    setEditProduct,
    setIsAddingProduct,
    setFilter,
    setFilters,
    setShowFilters,
    resetFilters,
    setIsBulkDeleting,
    setaddTagsToProducts,
    removeFilter,
    setViewQRCode,
    setPagination,
    setUpdateLocationInventoryInput,
    setIsDeleting,
    setIsBulkUpdating,
    deleteInventoryItem,
    setShowQuickFilters,
    setViewTimeline,
    setimportInventoryItems,
    restoreInventoryItem,
    setUpdateInventoryItems,
  };
};

export type RowActions =
  | "updateOnHand"
  | "printLabel"
  | "editItem"
  | "checkoutItem"
  | "deleteItem"
  | "printLabels"
  | "changeWorkflow"
  | "checkoutInventory"
  | "addIngredients"
  | "addTags"
  | "bulkDelete"
  | "requestStockTake"
  | "addToPurchaseOrder"
  | "bulkUpdate"
  | "addSelectedToPurchaseOrder"
  | "addToInventoryTransfer"
  | "addSelectedToInventoryTransfer"
  | "bulkRequestStockTake"
  | "viewTimeline"
  | "restoreInventoryItem"
  | "qrCode";

const transformLabel = (o: InventoryItem) => ({
  title: LABEL_TITLE,
  qrcode: o?.id,
  line1: `ID: ${shortId(o?.id)}`,
  line2: `${o?.name ?? "Unknown"}`,
  line3: "",
});

export const useRowActions = () => {
  const {
    setUpdateLocationInventoryInput,
    setEditProduct,
    setIsDeleting,
    setViewQRCode,
    setaddTagsToProducts,
    setIsBulkDeleting,
    selectedRows,
    variables,
    setIsBulkUpdating,
    setViewTimeline,
    restoreInventoryItem,
  } = useTable();
  const history = useHistory();
  const toast = useToast();

  const [updateLocationInventory] = useUpdateLocationInventoryMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ListLocationInventoryDocument, variables }],
  });

  const requestStockTake = useCallback(
    async ({ item, location }: { item: LocationInventory; location: Location }) => {
      try {
        await updateLocationInventory({
          variables: {
            input: {
              id: item.id,
              requiresStockTake: true,
            },
          },
        });
      } catch (err) {
        toast.error(`Unable to request stock take for ${item?.inventoryItem?.name}`);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const bulkRequestStockTake = useCallback(async () => {
    const requests = selectedRows.map((item) => {
      return requestStockTake({ item, location: item?.location });
    });

    await Promise.all(requests);
  }, [selectedRows, requestStockTake]);

  const handleAction: (action: RowActions, row: LocationInventory) => void = (action, row: LocationInventory) => {
    switch (action) {
      case "addToPurchaseOrder":
        history.push(Routes.CreatePurchaseRequest, {
          items: [row],
        });
        break;
      case "updateOnHand":
        setUpdateLocationInventoryInput({
          item: row,
          location: row?.location,
        });
        break;
      case "requestStockTake":
        requestStockTake({
          item: row,
          location: row?.location,
        });
        break;
      case "printLabel":
        generatePdfLabels(LABEL_TITLE, [row.inventoryItem], transformLabel);
        break;
      case "editItem":
        setEditProduct(row.inventoryItem);
        break;
      case "deleteItem":
        setIsDeleting(row.inventoryItem);
        break;
      case "restoreInventoryItem":
        restoreInventoryItem({ variables: { id: row?.inventoryItem?.id } });
        break;
      case "printLabels":
        generatePdfLabels(
          LABEL_TITLE,
          selectedRows?.map((r) => r?.inventoryItem),
          transformLabel
        );
        break;

      case "qrCode":
        setViewQRCode(row?.inventoryItem);
        break;

      case "addTags":
        setaddTagsToProducts(true);
        break;

      case "bulkDelete":
        setIsBulkDeleting(true);
        break;

      case "bulkUpdate":
        setIsBulkUpdating(true);
        break;

      case "bulkRequestStockTake":
        bulkRequestStockTake();
        break;

      case "addSelectedToPurchaseOrder":
        history.push(Routes.CreatePurchaseRequest, {
          items: selectedRows,
        });
        break;

      case "addToInventoryTransfer":
        history.push(Routes.InventoryTransfers, {
          createNew: true,
          items: [row],
        });
        break;

      case "addSelectedToInventoryTransfer":
        history.push(Routes.InventoryTransfers, {
          createNew: true,
          items: selectedRows,
        });
        break;

      case "viewTimeline":
        setViewTimeline(row);
        break;
      default:
        break;
    }
  };

  return handleAction;
};
