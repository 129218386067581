import React from "react";
import { IntegrationType, useGetOrganizationQuery } from "generated/graphql";

export const SelectIntegration: React.FC<{ value: IntegrationType; onChange: (value: IntegrationType) => void }> = ({
  value,
  onChange,
}) => {
  const { data } = useGetOrganizationQuery();
  const integrations = data?.organization?.integrations ?? [];
  return (
    <>
      {integrations?.map((integration, idx) => {
        if (integration?.type === IntegrationType.Shopify) {
          return (
            <div
              key={integration?.id}
              onClick={() => onChange(integration?.type)}
              className={`cursor-pointer flex flex-col justify-center items-center border ${
                value === IntegrationType.Shopify ? "border-shopify" : "border-gray-400"
              }  px-4 py-2 rounded`}
            >
              <img
                alt="shopify logo"
                width="44"
                src="https://cdn.shopify.com/shopifycloud/brochure/assets/brand-assets/shopify-logo-shopping-bag-full-color-66166b2e55d67988b56b4bd28b63c271e2b9713358cb723070a92bde17ad7d63.svg"
              />
              <div className="pt-3 text-sm font-semibold">Shopify</div>
            </div>
          );
        }
        return <div key={`int-${idx}`}></div>;
      })}
    </>
  );
};
