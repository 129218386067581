import styled from "styled-components";
import * as styles from "styles";
import tw from "twin.macro";

export const Table = styled(styles.Table)`
  ${tw`table-auto w-full`}

  thead {
    tr:first-child th {
      ${tw`text-lg pb-2`}
    }
  }

  thead tr:last-child {
    ${tw`border-b border-gray-600`}
  }

  thead th {
    ${tw`bg-white dark:bg-gray-800 text-default text-left`}
  }

  thead input {
    ${tw`border-0 w-full px-1 py-1`}
  }

  tbody tr:nth-child(even) {
    ${tw`bg-gray-200 dark:bg-gray-700`}
  }

  tbody > tr > td {
    ${tw`py-2`}
  }
`;
