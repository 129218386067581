import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Confirmation } from "components/Modals/Confirmation";
import { SideModal } from "components/Modals/SideModal";
import TitleWithLoading from "components/TitleWithLoading";
import { ListEmployeesDocument, useDeleteEmployeesMutation, useListEmployeesLazyQuery } from "generated/graphql";
import { useToast } from "hooks/toast";
import React from "react";
import { H4, HR, RedButton } from "styles";
import AddEmployee from "./AddEmployee";
import { TableProvider, useEmployeesTable } from "./context";
import EditEmployee from "./EditEmployee";
import Table from "./Table";

const Employees: React.FC = () => {
  const toast = useToast();
  const { loading, isCreating, isEditing, setIsCreating, setIsEditing, isDeleting, setIsDeleting, fetch, variables } =
    useEmployeesTable();
  const [deleteEmployees, deleteEmployeesMutation] = useDeleteEmployeesMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ListEmployeesDocument, variables }],
  });
  return (
    <>
      <div className="py-2 px-2 flex-1 flex flex-col overflow-hidden">
        <div className="flex justify-between my-2">
          <TitleWithLoading title="Operators" loading={loading} refresh={() => fetch({ variables })} />

          <RedButton onClick={() => setIsCreating(true)}>
            <span className="mr-2">
              <FontAwesomeIcon icon={faPlusCircle} />
            </span>
            Add Operator
          </RedButton>
        </div>
        <Table />
      </div>

      <SideModal side="RIGHT" isOpen={isCreating} onRequestClose={() => setIsCreating(false)}>
        <AddEmployee />
      </SideModal>

      <SideModal isOpen={isEditing !== null} onRequestClose={() => setIsEditing(null)} side="RIGHT">
        <EditEmployee />
      </SideModal>

      <Confirmation
        loading={deleteEmployeesMutation?.loading}
        isOpen={isDeleting?.length > 0}
        onRequestClose={() => setIsDeleting(null)}
        onConfirm={async () => {
          const ids = isDeleting?.map((emp) => emp.id);
          try {
            await deleteEmployees({
              variables: {
                ids,
              },
            });
          } catch (err) {
            console.error(err);
            toast.error("Unable to delete employees");
          } finally {
            setIsDeleting(null);
          }
        }}
      >
        <div className="my-4">
          <H4 className="py-4">Delete {isDeleting?.length} employees?</H4>
          <HR />
        </div>
      </Confirmation>
    </>
  );
};

const Container = () => {
  const query = useListEmployeesLazyQuery();
  return (
    <TableProvider query={query} sessionKey="@factoryfinch/employees">
      <Employees />
    </TableProvider>
  );
};

export { Container as default };
