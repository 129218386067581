import React, { useEffect } from "react";
import Checkbox from "components/Form/Checkbox";
import { ScrollableForm } from "components/Form/ScrollableForm";
import { SelectLocations } from "components/Select/Location";
import { SelectWorkflow } from "components/Select/Workflow";
import Filter from "components/Table/Filter";
import { InventoryItemType, Location, Workflow, Tag, useGetOrganizationQuery } from "generated/graphql";
import { SelectInventoryItemTypes, getInventoryItemTypeLabel } from "components/Select/InventoryItemType";
import { Controller, useForm } from "react-hook-form";
import { GreenBadge, RedBadge, SubHeading, Tooltip } from "styles";
import { TagInput } from "components/Form/TagInput";
import { useTable } from "./context";
import { removeAtIndex } from "utils/filters";
import { QuickFilters } from "./QuickFilters";
import { MultiTagInputWSuggest } from "components/Form/TagInput";

interface IFiltersFormData {
  name: string;
  names: string[];
  skus: string[];
  tags: Array<{ tag: Tag; value: string | null }>;
  workflows: Pick<Workflow, "id" | "name">[];
  types: InventoryItemType[];
  locations: Location[];
  showDeleted: boolean;
}

const defaultValues: IFiltersFormData = {
  name: "",
  names: [],
  skus: [],
  tags: [],
  types: [],
  workflows: [],
  locations: [],
  showDeleted: false,
};

const FiltersHeader = () => {
  const getOrgQuery = useGetOrganizationQuery();
  const { filters, removeFilter, setFilter } = useTable();
  useEffect(() => {
    if (filters.locations === null && getOrgQuery?.data?.organization?.primaryLocation) {
      setFilter("locations", [getOrgQuery?.data?.organization?.primaryLocation]);
    }
  }, [filters, setFilter, getOrgQuery?.data?.organization?.primaryLocation]);
  return (
    <div>
      <QuickFilters />
      <div className="flex flex-wrap">
        {filters?.locations?.length > 0 &&
          filters?.locations?.map((location, i) => {
            return (
              <Filter
                key={`${i}-location`}
                title={`Location: ${location?.name}`}
                onRemove={() => removeFilter("locations", i)}
                className="bg-green-600"
              />
            );
          })}

        <Filter
          title={`Name contains: ${filters?.names?.join(", ")}`}
          onRemove={() => setFilter("names", [])}
          className="bg-blue-600"
          isVisible={filters?.names?.length > 0}
        />

        <div className="flex flex-wrap">
          {filters?.showDeleted && (
            <Tooltip arrow title="Remove" key="showDeletedFilter">
              <RedBadge className="mx-1 my-1 cursor-pointer" onClick={() => removeFilter("showDeleted")}>
                Show Deleted
              </RedBadge>
            </Tooltip>
          )}
          {filters?.types?.length > 0 &&
            filters?.types?.map((type, i) => {
              return (
                <Filter
                  key={`${i}-type.value`}
                  title={getInventoryItemTypeLabel(type)}
                  onRemove={() => removeFilter("types", i)}
                  className="bg-gray-600"
                />
              );
            })}

          {filters?.workflows?.length > 0 &&
            filters?.workflows?.map((w, i) => (
              <Tooltip arrow title="Remove" key={w?.id}>
                <GreenBadge className="mx-1 my-1 cursor-pointer" onClick={() => removeFilter("workflows", i)}>
                  {`Workflow: ${w?.name}`}
                </GreenBadge>
              </Tooltip>
            ))}

          {filters?.tags?.map((t, i) => {
            return (
              <Filter
                key={t?.tag?.id}
                title={`Tag: ${t?.tag?.name}=${t?.value ?? "(any value)"}`}
                onRemove={() => removeFilter("tags", i)}
                className="bg-green-500"
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

const Filters = () => {
  const { setFilters, filters, setShowFilters } = useTable();
  const { control, handleSubmit, setValue, watch } = useForm<IFiltersFormData>({
    defaultValues: {
      ...defaultValues,
      name: filters?.name,
      skus: filters?.skus ?? [],
      names: filters?.names ?? [],
      tags: filters?.tags ?? [],
      types: filters?.types,
      locations: filters?.locations ?? [],
    },
  });

  const tags = watch("tags");

  const onSubmit = (formData: IFiltersFormData) => {
    setFilters({
      name: formData.name,
      names: formData?.names ?? [],
      skus: formData?.skus ?? [],
      workflows: formData.workflows,
      tags: formData?.tags,
      types: formData?.types ?? [],
      locations: formData?.locations ?? [],
      showDeleted: formData?.showDeleted,
    });

    setShowFilters(false);
  };

  return (
    <ScrollableForm
      title="Filters"
      onSubmit={handleSubmit(onSubmit)}
      onCancel={() => setShowFilters(false)}
      submitLabel="Apply Filters"
      data-testid="inventory-filters-form"
    >
      <SubHeading>Name contains</SubHeading>

      <Controller
        name="names"
        control={control}
        defaultValue={filters.names}
        render={({ field }) => <TagInput tags={field.value} onChange={field.onChange} />}
      />

      <SubHeading>SKU</SubHeading>

      <Controller
        name="skus"
        control={control}
        defaultValue={filters.skus}
        render={({ field }) => <TagInput tags={field.value} onChange={field.onChange} />}
      />

      <SubHeading>Tags</SubHeading>
      <Controller
        control={control}
        name="tags"
        render={({ field }) => {
          return (
            <MultiTagInputWSuggest
              value={field.value}
              onChange={field.onChange}
              onRemove={(v, i) => setValue("tags", removeAtIndex(tags, i))}
            />
          );
        }}
      />

      <SubHeading>Workflows</SubHeading>

      <Controller
        name="workflows"
        control={control}
        render={({ field }) => <SelectWorkflow value={field.value} onChange={field.onChange} isMulti />}
      />

      <SubHeading>Location</SubHeading>
      <Controller
        name="locations"
        control={control}
        render={({ field }) => <SelectLocations value={field.value} onChange={field.onChange} />}
      />
      <SubHeading>Types</SubHeading>
      <Controller
        name="types"
        control={control}
        render={({ field }) => <SelectInventoryItemTypes value={field.value} onChange={field.onChange} />}
      />

      <Controller
        name="showDeleted"
        control={control}
        render={({ field }) => (
          <Checkbox onChange={(e) => field.onChange(e.checked)} checked={field.value} label="Show Deleted" />
        )}
      />
    </ScrollableForm>
  );
};

export { Filters as default, FiltersHeader };
