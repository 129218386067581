import React, { useState, useCallback } from "react";
import { ScrollableForm } from "components/Form/ScrollableForm";
import {
  AttachmentResourceType,
  ListLocationInventoryDocument,
  useGetInventoryItemLazyQuery,
  useUpdateInventoryItemMutation,
  useRemoveTagFromResourceMutation,
  GetInventoryItemDocument,
} from "generated/graphql";
import { toInventoryItemType } from "components/Form/helpers";
import { useToast } from "hooks/toast";
import { useAttachment } from "hooks/upload";
import { DeepMap, FieldError, useForm } from "react-hook-form";
import { notEmpty } from "utils/filters";
import { useTable } from "./context";
import { InventoryItemForm, IInventoryItemFormData } from "./InventoryItemForm";
import { removeAtIndex } from "utils/filters";
import { ITagInputValue } from "components/Form/TagInput";
import { ConfirmRemoveTag } from "components/Tags/ConfirmRemove";

const EditItem: React.FC = () => {
  const toast = useToast();
  const [confirmRemoveTag, setconfirmRemoveTag] = useState<ITagInputValue>(null);
  const { isEditingProduct, setEditProduct, variables } = useTable();
  const addAttachment = useAttachment();
  const [fetchProduct, { data }] = useGetInventoryItemLazyQuery();
  const [removeTag, removeTagMutation] = useRemoveTagFromResourceMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GetInventoryItemDocument, variables: { id: isEditingProduct?.id } }],
  });
  const [updateInventoryItem] = useUpdateInventoryItemMutation({
    refetchQueries: [{ query: ListLocationInventoryDocument, variables }],
  });

  const inventoryItem = data?.inventoryItem;

  const form = useForm<IInventoryItemFormData>({
    defaultValues: {
      name: "",
      sku: "",
      units: "",
      isMade: false,
      isPurchased: false,
      isUsedToMakeSomethingElse: false,
      isSold: false,
      onHand: 0,
      reserved: 0,
      valuePerUnit: 0,
      defaultWorkflow: null,
      tags: [],
      defaultVendorProduct: null,
      ingredients: [],
    },
  });

  const tags = form.watch("tags");

  React.useEffect(() => {
    if (isEditingProduct?.id) {
      fetchProduct({ variables: { id: isEditingProduct?.id } });
    }
  }, [fetchProduct, isEditingProduct]);

  const submit = useCallback(
    async (form: IInventoryItemFormData) => {
      const attachments = form?.attachments?.filter((att) => att?.value) ?? [];
      for (const attachment of attachments) {
        await addAttachment(attachment.value.file.item(0), {
          fileName: attachment.value.name,
          resourceId: inventoryItem?.id,
          resourceType: AttachmentResourceType.InventoryItem,
          onProgress: (e) => {},
        });
      }

      try {
        await updateInventoryItem({
          variables: {
            input: {
              id: inventoryItem?.id,
              version: inventoryItem?.version,
              name: form.name,
              sku: form?.sku ?? null,
              units: form?.units?.toLowerCase(),
              type: toInventoryItemType(form),
              valuePerUnit: form.valuePerUnit,
              defaultWorkflowId: form?.defaultWorkflow?.id ?? null,
              tags:
                form?.tags?.map((field) => ({
                  tagId: field.tag.id,
                  value: field.value,
                })) ?? null,
              defaultVendorProductId: form?.defaultVendorProduct?.product?.id ?? null,
              ingredientIds: form?.ingredients?.map((fields) => fields?.inventoryItem?.id)?.filter(notEmpty) ?? [],
            },
          },
        });
        setEditProduct(null);
      } catch (err) {
        toast.error(`Unable to update inventory item: ${err?.message}`);
      }
    },
    [addAttachment, inventoryItem?.id, inventoryItem?.version, setEditProduct, toast, updateInventoryItem]
  );

  const onError = (errors: DeepMap<IInventoryItemFormData, FieldError>) => {
    toast.error("Please make sure you've filled out all the required fields");
  };

  return (
    <>
      <ScrollableForm
        title="Edit Item"
        onSubmit={form.handleSubmit(submit, onError)}
        onCancel={() => setEditProduct(null)}
        submitLabel="Save"
        formState={form.formState}
      >
        <InventoryItemForm
          form={form}
          inventoryItem={inventoryItem}
          onRemoveTag={(v, i) => {
            if (v.id) {
              setconfirmRemoveTag(v);
            } else {
              form.setValue("tags", removeAtIndex(tags, i));
            }
          }}
        />
      </ScrollableForm>
      <ConfirmRemoveTag
        resourceTag={confirmRemoveTag}
        loading={removeTagMutation.loading}
        onCancel={() => setconfirmRemoveTag(null)}
        onConfirm={async () => {
          try {
            await removeTag({ variables: { id: confirmRemoveTag.id } });
          } catch (err) {
            toast.error("Unable to remove tag");
          } finally {
            setconfirmRemoveTag(null);
          }
        }}
      />
    </>
  );
};

export { EditItem as default };
