import cx from "classnames";
import { ITagInputValue, SmallMultiTagInputWSuggest } from "components/Form/TagInput";
import { CenterModal } from "components/Modals/CenterModal";
import { InventoryItem, InventoryItemType, ResourceTagFilter, useListInventoryQuery } from "generated/graphql";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { AsyncSelect } from "styles";
import { removeAtIndex } from "utils/filters";

export const SelectInventoryItem: React.FC<{
  name?: string;
  classNames?: { root?: string; input?: string };
  value?: InventoryItem[] | InventoryItem;
  onChange: (m: InventoryItem | InventoryItem[]) => void;
  types?: InventoryItemType[];
  size?: "base" | "large";
  placeholder?: string;
  isMulti?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  hasError?: boolean;
}> = ({
  value,
  types,
  onChange,
  classNames = { root: "", input: "" },
  name = "",
  size = "base",
  isMulti = false,
  isDisabled = false,
  isLoading = false,
  placeholder = "Select Item...",
  hasError = false,
}) => {
  const [showfilters, setshowfilters] = useState(false);
  const [tags, settags] = useState<ITagInputValue[]>([]);
  const { data, refetch, loading } = useListInventoryQuery({
    variables: {
      filters: {
        types,
      },
    },
  });
  const [inputValue, setinputValue] = useState("");

  const handleInputChange = (newValue: string) => {
    setinputValue(newValue);
  };

  const fetchData = async (
    inputValue: string,
    tags: ITagInputValue[],
    callback: (inventoryItems: InventoryItem[]) => void
  ) => {
    const { data } = await refetch({
      filters: {
        types,
        name: inputValue,
        tags:
          tags?.map<ResourceTagFilter>((tag) => ({
            tagId: tag?.tag?.id,
            value: tag?.value ?? null,
          })) ?? [],
      },
    });
    callback(data?.listInventory?.inventory ?? []);
  };

  useEffect(() => {
    refetch({
      filters: {
        types,
        name: inputValue,
        tags:
          tags?.map<ResourceTagFilter>((tag) => ({
            tagId: tag?.tag?.id,
            value: tag?.value ?? null,
          })) ?? [],
      },
    });
  }, [tags, refetch, types, inputValue]);

  // eslint-disable-next-line
  const delayedQuery = useCallback(
    debounce((inputValue, callback) => fetchData(inputValue, tags, callback), 500),
    [inputValue, tags]
  );

  return (
    <div className={classNames?.root}>
      <AsyncSelect
        $hasError={hasError}
        isClearable
        isDisabled={isDisabled}
        isMulti={isMulti}
        isLoading={loading || isLoading}
        $textSize={size}
        placeholder={placeholder}
        name={name}
        className={classNames?.input}
        cacheOptions={false}
        value={value}
        loadOptions={delayedQuery}
        defaultOptions={data?.listInventory?.inventory ?? []}
        onInputChange={handleInputChange}
        onChange={onChange}
        getOptionLabel={(opt: InventoryItem) => opt?.name}
        getOptionValue={(opt: InventoryItem) => opt?.id}
      />
      <div className="px-1 flex">
        {showfilters ? (
          <div>
            <div className="py-2 flex">
              <button
                type="button"
                className="text-right text-xs text-blue-500"
                onClick={() => setshowfilters(false)}
              >{`with ${tags?.length} tags`}</button>
              {tags?.length > 0 && (
                <button
                  type="button"
                  className="mx-2  text-right text-xs text-brand"
                  onClick={() => {
                    settags([]);
                    setshowfilters(false);
                  }}
                >
                  reset
                </button>
              )}
            </div>

            <SmallMultiTagInputWSuggest
              value={tags}
              onChange={settags}
              onRemove={(v, i) => settags(removeAtIndex(tags, i))}
            />
          </div>
        ) : (
          <div className="py-2 flex">
            <button type="button" className="text-right text-xs text-blue-500" onClick={() => setshowfilters(true)}>
              {tags?.length > 0 ? `with ${tags?.length} tags` : "Filter items by tags..."}
            </button>
            {tags?.length > 0 && (
              <button
                type="button"
                className="mx-2  text-right text-xs text-brand"
                onClick={() => {
                  settags([]);
                  setshowfilters(false);
                }}
              >
                reset
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export const SelectInventoryItemModalInput: React.FC<{
  value: InventoryItem;
  types?: InventoryItemType[];
  onChange: (v: InventoryItem) => void;
  classNames?: { input?: string };
}> = ({ value, onChange, classNames, types }) => {
  const [open, setopen] = useState(false);

  return (
    <>
      <input
        className={cx(
          "dark:text-white bg-transparent rounded-none appearance-none leading-normal px-2 border border-transparent focus:border-blue-400 focus:outline-none focus:shadow-none w-full cursor-pointer",
          classNames?.input
        )}
        placeholder="Select an item"
        readOnly
        onClick={() => setopen(true)}
        value={value?.name ?? ""}
      />
      <CenterModal isOpen={open} onRequestClose={() => setopen(false)}>
        <SelectInventoryItem
          value={value}
          types={types}
          onChange={(v: InventoryItem) => {
            onChange(v);
            setopen(false);
          }}
        />
      </CenterModal>
    </>
  );
};
