import { faPercent } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import NumberFormat from "react-number-format";
import styled from "styled-components";
import tw from "twin.macro";

const MAX_VAL = 100;
const withValueLimit = ({ floatValue }) => floatValue <= MAX_VAL;

export const PercentInput = styled(
  ({
    className,
    label,
    onChange,
    value,
  }: {
    className?: string;
    label?: string;
    onChange: (val: number) => void;
    value: number;
  }) => {
    return (
      <div>
        {label && <label className="text-xs">{label}</label>}
        <div className={className}>
          <NumberFormat
            className="input"
            allowNegative={false}
            thousandSeparator
            decimalScale={2}
            allowEmptyFormatting={true}
            allowLeadingZeros={false}
            defaultValue={0}
            isAllowed={withValueLimit}
            onValueChange={(v) => onChange(v?.floatValue)}
            value={value}
          />
          <div className="icon">
            <FontAwesomeIcon icon={faPercent} />
          </div>
        </div>
      </div>
    );
  }
)`
  ${tw`rounded py-2 px-4 block flex flex-row items-center overflow-hidden bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-400 focus-within:border-blue-400`}

  .icon {
    ${tw`pr-2 dark:text-white text-black`}
  }

  .input {
    ${tw`appearance-none leading-normal focus:outline-none focus:shadow-none text-lg flex-1 bg-transparent dark:text-white text-black w-full`}
    &:focus {
      ${tw`outline-none shadow-none`}
    }

    &::placeholder {
      ${tw`text-gray-400 dark:text-gray-600 text-lg`}
    }

    &:disabled {
      ${tw`opacity-75`}
    }
  }
`;
