import { faExclamationCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GettingStarted from "components/GettingStarted";
import LeftNav from "components/LeftNav";
import { FullScreenLoading } from "components/Loading";
import { Confirmation } from "components/Modals/Confirmation";
import Settings from "components/Modules/Account";
import Fulfillment from "components/Modules/Fulfillment";
import Production from "components/Modules/Production";
import Purchasing from "components/Modules/Purchasing";
import Team from "components/Modules/Team";
import OnBoarding from "components/OnBoarding";
import { PaymentForm } from "components/Payment";
import { useMeQuery, UserRole } from "generated/graphql";
import { useApp } from "hooks/app";
import { useAuth } from "hooks/auth";
import { JobsProvider } from "hooks/jobs";
import { ToastContainers, useToast } from "hooks/toast";
import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Routes } from "routes";
import { Drawer, H4, HR, Button } from "styles";
import Header from "./Header";
import { useAnalytics } from "hooks/analytics";
import { Reports } from "components/Views/Reports";

const Root: React.FC = () => {
  const { logout, setLoggingOut, isLoggingOut } = useAuth();

  const { track } = useAnalytics();
  const { showGettingStarted, setshowGettingStarted } = useApp();
  const { data, loading } = useMeQuery();
  const subscription =
    data?.me?.organization?.subscription?.__typename === "FactoryFinchSubscription" &&
    data?.me?.organization?.subscription;

  const me = data?.me?.__typename === "User" && data?.me;
  const showPaymentForm =
    data?.me?.organization?.subscription?.__typename === "StripeSubscription" &&
    data?.me?.organization?.subscription?.status !== "active" &&
    data?.me?.organization?.subscription?.status !== "trialing";
  const toast = useToast(ToastContainers.BottomRight, {
    noStack: false,
  });

  const onConfirmLogout = async () => {
    track("Logout Button Clicked");
    setLoggingOut(false);
    await logout();
  };

  useEffect(() => {
    if (subscription?.data?.status === "pending") {
      toast.info(
        <a href={subscription?.data?.confirmation_url}>
          You have a pending charge for your subscription. Click here to accept.
        </a>,

        {
          onClick: () => {
            window.location.href = subscription?.data?.confirmation_url;
          },
          toastId: "pending-charge",
          autoClose: false,
          closeButton: false,
        }
      );
    }
  }, [subscription, toast]);

  if (loading) {
    return <FullScreenLoading message="Authenticating" />;
  }

  // "incomplete" "incomplete_expired" "trialing" "active" "past_due" "canceled" "unpaid"
  if (showPaymentForm && me.role === UserRole.Owner) {
    return <PaymentForm />;
  }

  if (showPaymentForm && me.role !== UserRole.Owner) {
    return (
      <div className="h-full flex flex-col justify-center items-center">
        <FontAwesomeIcon icon={faExclamationCircle} size="4x" className="my-4" />
        <div className="text-2xl">Your Organization's subscription needs to be updated.</div>
        <div className="text-lg">Please contact your account owner.</div>

        <Button onClick={logout}>Logout</Button>
      </div>
    );
  }

  return (
    <>
      <div className="h-screen flex">
        <LeftNav />
        <OnBoarding />
        <div className="w-full overflow-y-auto flex flex-col">
          <JobsProvider>
            <Header />

            <Switch>
              <Route path={Routes.BirdsEyeView} component={Reports} />
              <Route path={Routes.Production} component={Production} />
              <Route path={Routes.Fulfillment} component={Fulfillment} />
              <Route path={Routes.Purchasing} component={Purchasing} />
              <Route path={Routes.Team} component={Team} />
              <Route path={Routes.Settings} component={Settings} />
              <Redirect to={Routes.Production} />
            </Switch>
          </JobsProvider>
        </div>
        {showGettingStarted && (
          <Drawer
            className="w-1/3"
            variant="persistent"
            anchor="right"
            open={showGettingStarted}
            classes={{
              paper: "w-1/3",
            }}
          >
            <div className="overflow-scroll">
              <div className="flex justify-end ">
                <button onClick={() => setshowGettingStarted(false)} className="p-4 text-lg focus:outline-none">
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <GettingStarted />
            </div>
          </Drawer>
        )}
      </div>

      <Confirmation
        isOpen={isLoggingOut}
        onRequestClose={() => setLoggingOut(false)}
        cancelButtonLabel="Cancel"
        confirmButtonLabel="Logout"
        onConfirm={onConfirmLogout}
      >
        <div className="my-4 w-full">
          <H4 className="py-4 flex flex-row justify-center text-default dark:text-white">
            Are you sure you want to log out?
          </H4>
          <HR />
        </div>
      </Confirmation>
    </>
  );
};

export { Root as default };
