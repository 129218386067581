import cx from "classnames";
import { useCombinedRefs } from "hooks/combinedRefs";
import React, { useMemo } from "react";
import { TriggerEvent, useContextMenu } from "react-contexify";
import { FormatterProps, Row as GridRow, RowRendererProps } from "react-data-grid";
import { useDrag, useDrop } from "react-dnd";
import { useHotkeys } from "react-hotkeys-hook";
import { useCopyToClipboard } from "react-use";

export const EmptyRowsRenderer: React.FC<{ message?: string }> = ({ message = "No rows" }) => {
  return <div className="text-center text-lg py-12 text-gray-800 dark:text-gray-300">{message}</div>;
};

export const LoadingRenderer: React.FC<{ message?: string }> = ({ message = "Loading" }) => {
  return <div className="text-center text-lg py-12 text-gray-800 dark:text-gray-300">{message}</div>;
};

interface DraggableRowRenderProps<R, SR> extends RowRendererProps<R, SR> {
  onRowReorder: (sourceIndex: number, targetIndex: number) => void;
  contextMenuId?: string;
}

export const DraggableRowRenderer = <R, SR = unknown>({
  rowIdx,
  isRowSelected,
  className,
  onRowReorder,
  contextMenuId = "context-menu-id",
  ...props
}: DraggableRowRenderProps<R, SR>) => {
  const [{ isDragging }, drag] = useDrag({
    type: "ROW_DRAG",
    item: { index: rowIdx },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop({
    accept: "ROW_DRAG",
    drop({ index }: { index: number }) {
      onRowReorder(index, rowIdx);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const { show } = useContextMenu({
    id: contextMenuId,
  });
  const displayMenu = (e: TriggerEvent) => {
    show(e, { props: props.row });
  };

  return (
    <GridRow
      ref={useCombinedRefs(drag, drop)}
      rowIdx={rowIdx}
      isRowSelected={isRowSelected}
      className={cx(className, isDragging && "opacity-0", isOver && "border border-white")}
      onContextMenu={displayMenu}
      {...props}
    />
  );
};

export const RowRenderer = <R, SR = unknown>({
  contextMenuId = "",
  ...rest
}: RowRendererProps<R, SR> & { contextMenuId?: string }) => {
  const { show } = useContextMenu({
    id: contextMenuId,
  });
  const displayMenu = (e: TriggerEvent) => {
    show(e, { props: rest.row });
  };

  return <GridRow onContextMenu={displayMenu} {...rest} />;
};

export const FormatterWithCopy = <TRow extends { id: string }, TSummaryRow = unknown>({
  render,
  getValue,
  row,
  column,
  isCellSelected,
}: FormatterProps<TRow, TSummaryRow> & { getValue?: (row: TRow) => string; render?: (r: TRow) => any }) => {
  const [, copy] = useCopyToClipboard();

  const val = useMemo(
    () => (row?.[column?.key as keyof TRow] as unknown as string) || (getValue && getValue(row)) || "",
    [row, column, getValue]
  );

  useHotkeys(
    "ctrl+c,cmd+c",
    () => {
      if (isCellSelected) {
        copy(val);
      }
    },
    [isCellSelected, val]
  );

  if (!render) {
    return <>{val}</>;
  }

  return render(row);
};
