import React from "react";
import { WhiteLogo } from "components/Logo";
import BackgroundImage from "components/BackgroundImage";

export const PreAuthLayout: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
  return (
    <div className="flex">
      <div className="relative flex flex-col items-center justify-center w-full lg:w-1/2 h-screen bg-brand">
        <WhiteLogo width={200} />

        {children}

        <BackgroundImage />
      </div>
      <div className="hidden lg:flex flex-col justify-center items-center invisible lg:visible lg:w-1/2">
        <div className="text-4xl text-center">
          <u>Elegant</u> Manufacturing Management
        </div>
        <div className="text-2xl mt-2">Designed For SMB.</div>
      </div>
    </div>
  );
};
