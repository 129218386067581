import React from "react";
import { Select } from "styles";
import { PurchaseOrderStatus } from "generated/graphql";

export const getPurchaseOrderLabel = (status: PurchaseOrderStatus) => {
  switch (status) {
    case PurchaseOrderStatus.Pending:
      return "Pending";
    case PurchaseOrderStatus.Sent:
      return "Sent";
    case PurchaseOrderStatus.Received:
      return "Received";
    case PurchaseOrderStatus.Cancelled:
      return "Cancelled";
    default:
      return status;
  }
};

export const SelectPurchaseOrderStatuses: React.FC<{
  value: PurchaseOrderStatus[];
  onChange: (opt: PurchaseOrderStatus) => void;
  placeholder?: string;
}> = ({ value, onChange, placeholder = "Filter by Status..." }) => {
  return (
    <Select
      placeholder={placeholder}
      isClearable
      value={value}
      isMulti
      options={[
        PurchaseOrderStatus.Pending,
        PurchaseOrderStatus.Sent,
        PurchaseOrderStatus.Received,
        PurchaseOrderStatus.Cancelled,
      ]}
      onChange={onChange}
      getOptionValue={(opt: PurchaseOrderStatus) => opt}
      getOptionLabel={(opt: PurchaseOrderStatus) => getPurchaseOrderLabel(opt)}
    />
  );
};

export const SelectPurchaseOrderStatus: React.FC<{
  value: PurchaseOrderStatus;
  onChange: (opt: PurchaseOrderStatus) => void;
  placeholder?: string;
}> = ({ value, onChange, placeholder = "Select Status..." }) => {
  return (
    <Select
      placeholder={placeholder}
      isClearable
      value={{ label: getPurchaseOrderLabel(value), value }}
      options={[
        { label: getPurchaseOrderLabel(PurchaseOrderStatus.Pending), value: PurchaseOrderStatus.Pending },
        { label: getPurchaseOrderLabel(PurchaseOrderStatus.Sent), value: PurchaseOrderStatus.Sent },
        { label: getPurchaseOrderLabel(PurchaseOrderStatus.Received), value: PurchaseOrderStatus.Received },
        { label: getPurchaseOrderLabel(PurchaseOrderStatus.Cancelled), value: PurchaseOrderStatus.Cancelled },
      ]}
      onChange={(opt: { label: string; value: PurchaseOrderStatus }) => onChange(opt?.value)}
    />
  );
};
