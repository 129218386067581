import Inventory from "components/Views/Inventory/Inventory";
import InventoryTransfers from "components/Views/InventoryTransfer/InventoryTransfers";
import Locations from "components/Views/Location/Locations";
import Steps from "components/Views/Step/Steps";
import Workflow from "components/Views/Workflow/Workflow";
import Workflows from "components/Views/Workflow/Workflows";
import WorkOrder from "components/Views/WorkOrder/WorkOrder";
import WorkOrders from "components/Views/WorkOrder/WorkOrders";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Routes } from "routes";

const Production = () => {
  return (
    <Switch>
      <Route exact path={Routes.Workflows} component={Workflows} />

      <Route exact path={Routes.Workflow} component={Workflow} />
      <Route exact path={Routes.WorkOrders} component={WorkOrders} />
      <Route exact path={Routes.WorkOrder} component={WorkOrder} />
      <Route exact path={Routes.Inventory} component={Inventory} />
      <Route exact path={Routes.Steps} component={Steps} />

      <Route exact path={Routes.Locations} component={Locations} />

      <Route exact path={Routes.InventoryTransfers} component={InventoryTransfers} />

      {/* <Route
        exact
        path={Routes.CreateInventoryTransfer}
        component={CreateInventoryTransfer}
      /> */}

      <Redirect to={Routes.WorkOrders} />
    </Switch>
  );
};

export { Production as default };
