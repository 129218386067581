import React from "react";
import { Link, Redirect } from "react-router-dom";
import { Routes } from "routes";
import { useForm } from "react-hook-form";
import { useToast } from "hooks/toast";
import { useAuth } from "hooks/auth";
import { WhiteLogo } from "components/Logo";
import { DarkRedButton, Form } from "styles";
import TextInput from "components/Form/TextInput";
import BackgroundImage from "components/BackgroundImage";
import { ButtonLoading } from "components/Loading";
import { useAnalytics } from "hooks/analytics";

interface SignUpForm {
  email: string;
  password: string;
  organization: string;
  // inviteCode: string;
}

const SignUp: React.FC = () => {
  const toast = useToast();
  const { track } = useAnalytics();
  const { isLoggedIn, createAccount, loading } = useAuth();
  const { register, handleSubmit } = useForm<SignUpForm>();

  const onSubmit = async (input: SignUpForm) => {
    try {
      await createAccount({ input });
      track("Account created", {
        organization: input.organization,
        email: input.email,
      });
    } catch (err) {
      if (err?.message) {
        toast.error(err?.message);
        return;
      }
      toast.error("Unable to sign up");
    }
  };

  if (isLoggedIn) {
    return <Redirect to={Routes.Root} />;
  }

  return (
    <div className="flex">
      <div className="relative flex flex-col items-center justify-center w-full lg:w-1/2 h-screen bg-brand">
        <div className="w-full flex flex-col justify-center items-center z-10">
          <WhiteLogo width={200} />

          <Form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col p-4 mt-4 w-full sm:w-4/6 md:w-3/6 lg:w-4/6 xl:w-3/6"
          >
            <TextInput
              {...register("organization", { required: true, minLength: 1 })}
              placeholder="Organization name"
            />
            {/* {errors.organization && "Organization name is required"} */}
            <TextInput
              {...register("email", {
                required: true,
                pattern:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
              placeholder="Email"
            />{" "}
            {/* {errors.email && "Email is required"} */}
            <TextInput
              {...register("password", { required: true, minLength: 8 })}
              type="password"
              placeholder="Password"
            />
            {/* <TextInput {...register("inviteCode")} placeholder="Invite Code" /> */}
            {/* {errors.password && "Password is required"} */}
            <DarkRedButton disabled={loading} type="submit">
              {loading ? <ButtonLoading /> : "Sign Up"}
            </DarkRedButton>
            <Link className="m-auto mt-4 text-white" to={Routes.Login}>
              Already have an account? Login Here
            </Link>
            <div className="text-center my-4 text-sm">
              {`By clicking "Sign Up" you agree to `}
              <a href="https://factoryfinch.com/privacy" rel="noreferrer" target="_blank" className="underline">
                Factory Finch Terms
              </a>
              {` and `}
              <a href="https://factoryfinch.com/privacy" target="_blank" className="underline" rel="noreferrer">
                {` Privacy Policy`}
              </a>
              .
            </div>
          </Form>
        </div>
        <BackgroundImage />
      </div>
      <div className="hidden lg:flex flex-col justify-center items-center invisible lg:visible lg:w-1/2">
        <div className="text-4xl text-center">
          <u>Elegant</u> Manufacturing Management
        </div>
        <div className="text-2xl mt-2">Designed For SMB.</div>
      </div>
    </div>
  );
};

export { SignUp as default };
