import MTooltip from "@material-ui/core/Tooltip";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import tw from "twin.macro";

export const StyledTooltip = styled((props) => (
  <MTooltip
    classes={{
      popper: props?.className,
      tooltip: "tooltip",
      arrow: "arrow",
      tooltipPlacementBottom: "tooltipPlacementBottom",
    }}
    {...props}
  />
))`
  & .arrow {
    ${tw`text-black`}
  }
  & .tooltip {
    ${tw`bg-black`}
  }

  & .tooltipPlacementBottom {
    position: relative;
    top: -15px;
  }
`;

export const Tooltip: React.FC<
  React.PropsWithChildren<{
    title: string;
  }>
> = ({ title, children }) => {
  return (
    <StyledTooltip title={title} arrow>
      <span className="cursor-pointer">{children}</span>
    </StyledTooltip>
  );
};

export const TextWithTooltip: React.FC<{
  title: string;
}> = ({ title }) => {
  return (
    <StyledTooltip title={title} arrow>
      <div className="truncate cursor-pointer">{title}</div>
    </StyledTooltip>
  );
};

export const IconWithTooltip: React.FC<{
  title: string;
  icon: IconDefinition;
  iconProps?: Omit<FontAwesomeIconProps, "icon">;
}> = ({ title, icon, iconProps }) => {
  return (
    <StyledTooltip title={title} arrow>
      <span>
        <FontAwesomeIcon icon={icon} className="cursor-pointer" {...iconProps} />
      </span>
    </StyledTooltip>
  );
};
