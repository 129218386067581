import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CurrencyInput from "components/Form/CurrencyInput";
import InputWithUnits from "components/Form/InputWithUnits";
import { SelectInventoryItemModalInput } from "components/Select/InventoryItem";
import { SelectVendor } from "components/Select/Vendor";
import SelectVendorProduct from "components/Select/VendorProduct";
import { InventoryItem, InventoryItemType, Vendor, VendorProduct } from "generated/graphql";
import React, { useEffect } from "react";
import { Controller, useFieldArray, UseFormReturn } from "react-hook-form";
import styled from "styled-components";
import * as styles from "styles";
import { IconButton } from "styles";
import tw from "twin.macro";
import { notEmpty } from "utils/filters";

const StyledTable = styled(styles.Table)`
  ${tw`table-auto w-full`}
  thead {
    tr:last-child {
      ${tw`border-b border-gray-200`}
      th {
        ${tw`py-2 dark:text-white text-center`}

        &:nth-child(2) {
          ${tw`px-4 text-left`}
        }
      }
    }
  }

  tbody {
    tr:nth-child(even) {
      ${tw`bg-gray-200 dark:bg-gray-700`}
    }
  }

  tbody {
    tr {
      td {
        ${tw`px-2`}

        ${tw`py-2 dark:text-white text-center`}
      }
    }
  }

  .po-number {
    max-width: 40px;
  }

  .po-item {
    min-width: 200px;
  }

  .po-quantity {
    width: 200px;
  }

  .po-vendorProduct {
    min-width: 200px;
  }

  .po-pricePerUnit {
    min-width: 150px;
  }

  .po-remove-item {
    min-width: 40px;
    max-width: 40px;
  }
`;

interface FormRow {
  item: Pick<InventoryItem, "id" | "name" | "units" | "defaultVendorProduct">;
  quantity: number;
  pricePerUnit: number;
  vendorProduct: { vendor: Pick<Vendor, "id" | "name">; product: Pick<VendorProduct, "id" | "name"> };
}

export interface IPurchaseRequestItemsForm {
  items: FormRow[];
}

export const PurchaseRequestForm: React.FC<{
  form: UseFormReturn<IPurchaseRequestItemsForm>;
}> = ({ form }) => {
  const { control, setValue, watch, formState } = form;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  const values = watch("items");

  useEffect(() => {
    if (fields.length < 1) {
      append({
        item: null,
        quantity: null,
        pricePerUnit: null,
        vendorProduct: null,
      });
    }
  }, [fields, append]);

  return (
    <>
      <StyledTable>
        <thead>
          <tr>
            <th>#</th>
            <th>Item</th>
            <th>Quantity</th>

            <th>Vendor</th>
            <th>Price per unit</th>
          </tr>
        </thead>
        <tbody>
          {fields.map((formField, index) => {
            const fieldValue = values?.[index];

            return (
              <tr key={formField.id}>
                <td className="po-number">{index + 1}</td>
                <td className="po-item">
                  <Controller
                    control={control}
                    name={`items.${index}.item` as const}
                    defaultValue={formField?.item}
                    render={({ field }) => (
                      <SelectInventoryItemModalInput
                        classNames={{
                          input:
                            "w-full flex-1 flex flex-row items-center leading-normal py-2 px-4 rounded bg-white dark:bg-gray-800 text-lg appearance-none border border-gray-300 dark:border-gray-400 focus:border-blue-400 focus:outline-none focus:shadow-none",
                        }}
                        types={[InventoryItemType.RawMaterial]}
                        value={field.value}
                        onChange={(e: InventoryItem) => {
                          if (e?.defaultVendorProduct) {
                            setValue(`items.${index}.vendorProduct` as const, {
                              vendor: e?.defaultVendorProduct?.vendor,
                              product: e?.defaultVendorProduct,
                            });
                          }
                          if (e?.valuePerUnit > 0) {
                            setValue(`items.${index}.pricePerUnit` as const, e?.valuePerUnit);
                          }
                          field.onChange(e);
                        }}
                      />
                    )}
                  />
                </td>
                <td className="po-quantity">
                  <Controller
                    control={control}
                    name={`items.${index}.quantity` as const}
                    defaultValue={formField?.quantity}
                    render={({ field }) => (
                      <InputWithUnits
                        placeholder="0"
                        units={fieldValue?.item?.units ?? "units"}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </td>

                <td className="po-vendorProduct">
                  <Controller
                    name={`items.${index}.vendorProduct` as const}
                    control={control}
                    rules={{
                      validate: () => {
                        return (
                          (notEmpty(fieldValue?.vendorProduct?.vendor) &&
                            notEmpty(fieldValue?.vendorProduct?.product)) ||
                          "Must select vendor and product"
                        );
                      },
                    }}
                    render={({ field }) => {
                      const error = notEmpty((formState?.errors?.items?.[index] as Partial<FormRow>)?.vendorProduct);
                      return (
                        <div className="grid grid-cols-1 gap-x-2 gap-y-2">
                          <SelectVendor
                            value={fieldValue?.vendorProduct?.vendor}
                            onChange={(vendor) => {
                              field.onChange({
                                vendor,
                                product: null,
                              });
                            }}
                            hasError={error}
                          />
                          <SelectVendorProduct
                            isDisabled={!fieldValue?.vendorProduct?.vendor?.id}
                            value={fieldValue?.vendorProduct?.product}
                            onChange={(product) => {
                              field.onChange({
                                vendor: fieldValue?.vendorProduct?.vendor,
                                product,
                              });
                            }}
                            defaultFilters={{
                              vendorId: fieldValue?.vendorProduct?.vendor?.id,
                            }}
                            hasError={error}
                          />
                        </div>
                      );
                    }}
                  />
                </td>
                <td className="po-pricePerUnit">
                  <Controller
                    name={`items.${index}.pricePerUnit` as const}
                    control={control}
                    render={({ field }) => (
                      <CurrencyInput value={field.value} onValueChange={field.onChange} placeholder="0" />
                    )}
                  />
                </td>

                <td className="po-remove-item">
                  {index > 0 && (
                    <IconButton className="inline" onClick={() => remove(index)}>
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </IconButton>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </StyledTable>
      {values?.length < 1 ? (
        <div className="flex justify-center">
          <div className="text-gray-300 text-2xl font-semibold my-8">No items</div>
        </div>
      ) : (
        <div className="flex flex-row justify-end py-3">
          <button
            type="button"
            className="text-blue-500 focus:outline-none"
            onClick={() => append({ item: null, quantity: 0, vendorProduct: null })}
          >
            Add item...
          </button>
        </div>
      )}
    </>
  );
};
