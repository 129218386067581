import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import { PhoneNumber } from "components/Form/PhoneInput";
import IconButtonMenu from "components/IconButtonMenu";
import Pagination from "components/Pagination";
import { SelectPagination } from "components/Select/Pagination";
import StyledTable from "components/Table";
import { EmptyRowsRenderer, LoadingRenderer, RowRenderer } from "components/Table/Renderers";
import { Vendor } from "generated/graphql";
import React, { useEffect } from "react";
import { Item, ItemParams, Menu } from "react-contexify";
import { Column, DataGridProps, FormatterProps, HeaderRendererProps } from "react-data-grid";
import { shortId } from "utils/formatters";
import { RowActions, useRowActions, useTable } from "./context";

const MENU_ID = "vendor-row-menu";

const RowMenu: React.FC<{ row: Vendor }> = ({ row }) => {
  const handleAction = useRowActions();
  return (
    <IconButtonMenu
      options={[
        {
          label: "Edit",
          onClick: () => handleAction("editVendor", row),
        },
        {
          label: "Print Label",
          onClick: () => handleAction("printLabel", row),
        },
      ]}
    />
  );
};

const ContextMenu = () => {
  const { selected } = useTable();
  const handleAction = useRowActions();
  const handleItemClick: (args: ItemParams<Vendor, any>) => void = ({ event, props: item }) => {
    handleAction(event.currentTarget.id as RowActions, item);
  };

  return (
    <Menu id={MENU_ID}>
      <Item id="editVendor" onClick={handleItemClick}>
        Edit
      </Item>
      <Item id="printLabel" onClick={handleItemClick}>
        Print Label
      </Item>

      <Item id="deleteVendor" onClick={handleItemClick}>
        <div className="text-brand">Delete</div>
      </Item>

      {selected?.size > 0 && (
        <>
          <div
            className="mt-2 w-full cursor-default"
            style={{
              height: "1px",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            }}
          />

          <div className="text-xs text-gray-600 px-3 font-semibold bg-gray-100  py-2">Bulk Actions</div>

          <Item id="generateLabels" onClick={handleItemClick}>
            Generate Labels
          </Item>
        </>
      )}
    </Menu>
  );
};

const columns: Column<Vendor, unknown>[] = [
  {
    headerRenderer: (props: HeaderRendererProps<Vendor>) => {
      return (
        <div
          className="flex justify-center items-center h-full w-full text-brand cursor-pointer text-lg py-2"
          onClick={(e) => props.onAllRowsSelectionChange(!props?.allRowsSelected)}
        >
          <FontAwesomeIcon icon={props?.allRowsSelected ? faCheckSquare : faSquare} />
        </div>
      );
    },
    formatter: (props: FormatterProps) => {
      return (
        <div
          className={cx("flex justify-center items-center h-full w-full text-brand cursor-pointer text-lg py-2")}
          onClick={(e) => props.onRowSelectionChange(!props?.isRowSelected, e.shiftKey)}
        >
          <FontAwesomeIcon icon={props?.isRowSelected ? faCheckSquare : faSquare} />
        </div>
      );
    },

    key: "select-row",
    name: "",
    resizable: false,
    sortable: false,
    maxWidth: 35,
    width: 35,
  },
  {
    key: "id",
    name: "ID",
    width: 100,
    formatter: (props) => {
      return (
        <div
          className={`${props?.row?.deletedAt ? "text-gray-400 dark:text-gray-200 line-through" : ""} truncate`}
          title={props?.row?.id}
        >
          {shortId(props?.row?.id)}
        </div>
      );
    },
  },

  {
    key: "name",
    name: "Company Name",
    minWidth: 200,
    formatter: (props) => {
      return (
        <div className={props?.row?.deletedAt !== null ? "line-through text-gray-600 dark:text-gray-300" : ""}>
          {props?.row?.name}
        </div>
      );
    },
  },

  {
    key: "companyPhone",
    name: "Phone",
    minWidth: 200,
    cellClass: "flex",
    formatter: (props) => {
      return <PhoneNumber value={props?.row?.companyPhone} />;
    },
  },

  {
    key: "contactEmail",
    name: "Contact Email",
    minWidth: 200,
  },

  {
    key: "actions",
    name: "",
    width: 50,
    cellClass: "text-center flex justify-center items-center",
    headerCellClass: "text-center flex justify-center items-center",
    formatter: (props) => <RowMenu row={props.row} />,
  },
];

const Table = () => {
  const { setPagination, pagination, fetch, data, loading, setselected, selected, variables } = useTable();

  useEffect(() => {
    fetch({
      variables,
    });
  }, [fetch, variables]);

  return (
    <>
      <div className="flex justify-end items-center">
        <SelectPagination value={pagination?.perPage} onChange={(perPage) => setPagination({ perPage })} />
      </div>
      <div style={{ flex: "1 1 auto" }} className="overflow-auto">
        <StyledTable<React.FC<DataGridProps<Vendor>>>
          rowHeight={56}
          rowKeyGetter={(r: Vendor) => r?.id}
          rowRenderer={(props) => <RowRenderer contextMenuId={MENU_ID} {...props} />}
          columns={columns}
          rows={data?.listVendors?.vendors ?? []}
          selectedRows={selected}
          onSelectedRowsChange={setselected}
          emptyRowsRenderer={() =>
            loading ? <LoadingRenderer message="Fetching vendors" /> : <EmptyRowsRenderer message="No vendors found" />
          }
        />
      </div>
      <Pagination
        loading={loading}
        currentPage={pagination?.page ?? 1}
        totalPages={data?.listVendors?.pageInfo?.totalPages}
        canNextPage={pagination?.page < data?.listVendors?.pageInfo?.totalPages}
        canPreviousPage={data?.listVendors?.pageInfo?.page > 1 ?? false}
        setPagination={setPagination}
      />
      <ContextMenu />
    </>
  );
};

export { Table as default };
