import React from "react";
import { SubHeading } from "styles";
import { SelectLocation } from "components/Select/Location";
import { useTable, IFilters } from "./context";
import { useForm, Controller } from "react-hook-form";
import Filter from "components/Table/Filter";
import { ScrollableForm } from "components/Form/ScrollableForm";
import { notEmpty } from "utils/filters";
import { getInventoryTransferLabel, SelectInventoryTransferStatuses } from "components/Select/InventoryTransferStatus";
import Checkbox from "components/Form/Checkbox";

const FiltersHeader = () => {
  const { filters, removeFilter } = useTable();

  return (
    <div className="flex flex-wrap">
      {filters?.showDeleted && (
        <Filter title="Show Deleted" onRemove={() => removeFilter("showDeleted")} className="bg-brand" />
      )}

      <Filter
        isVisible={notEmpty(filters?.from?.name)}
        title={`From=${filters?.from?.name}`}
        onRemove={() => removeFilter("from")}
        className="bg-purple-500"
      />

      <Filter
        isVisible={notEmpty(filters?.to?.name)}
        title={`To=${filters?.to?.name}`}
        onRemove={() => removeFilter("to")}
        className="bg-blue-500"
      />

      {filters?.status?.map((status, i) => {
        return (
          <Filter
            title={`Status: ${getInventoryTransferLabel(status)}`}
            onRemove={() => removeFilter("status", i)}
            className="bg-gray-400"
          />
        );
      })}
    </div>
  );
};

const Filters = () => {
  const { setFilters, filters, setShowFilters } = useTable();
  const { control, handleSubmit } = useForm<IFilters>({
    defaultValues: {
      from: filters?.from,
      to: filters?.to,
      status: filters?.status,
      showDeleted: filters?.showDeleted,
    },
  });

  const onSubmit = (formData: IFilters) => {
    setFilters(formData);
    setShowFilters(false);
  };

  return (
    <ScrollableForm
      title="Filters"
      submitLabel="Apply Filters"
      onSubmit={handleSubmit(onSubmit)}
      onCancel={() => setShowFilters(false)}
    >
      <SubHeading>From</SubHeading>
      <Controller
        name="from"
        control={control}
        render={({ field }) => <SelectLocation value={field.value} onChange={field.onChange} />}
      />

      <SubHeading>To</SubHeading>
      <Controller
        name="to"
        control={control}
        render={({ field }) => <SelectLocation value={field.value} onChange={field.onChange} />}
      />

      <SubHeading>Status</SubHeading>
      <Controller
        name="status"
        control={control}
        render={({ field }) => <SelectInventoryTransferStatuses value={field.value} onChange={field.onChange} />}
      />

      <Controller
        name="showDeleted"
        control={control}
        render={({ field }) => (
          <Checkbox onChange={(e) => field.onChange(e.checked)} checked={field.value} label="Show Deleted" />
        )}
      />
    </ScrollableForm>
  );
};

export { Filters, FiltersHeader };
