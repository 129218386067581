import React from "react";
import Customer from "components/Views/Customer/Customer";
import Customers from "components/Views/Customer/Customers";
import CreateCustomerOrder from "components/Views/CustomerOrder/CreateCustomerOrder";
import CustomerOrder from "components/Views/CustomerOrder/CustomerOrder";
import CustomerOrders from "components/Views/CustomerOrder/CustomerOrders";
import { SalesOrderInvoices } from "components/Views/CustomerOrder/Invoices";
import { Redirect, Route, Switch } from "react-router-dom";
import { Routes } from "routes";

const Fulfillment = () => {
  return (
    <Switch>
      <Route exact path={Routes.CreateCustomerOrder} component={CreateCustomerOrder} />
      <Route exact path={Routes.Customers} component={Customers} />
      <Route exact path={Routes.Customer} component={Customer} />
      <Route exact path={Routes.CustomerOrders} component={CustomerOrders} />
      <Route exact path={Routes.SalesOrderInvoices} component={SalesOrderInvoices} />
      <Route exact path={Routes.CustomerOrder} component={CustomerOrder} />
      <Redirect to={Routes.CustomerOrders} />
    </Switch>
  );
};

export { Fulfillment as default };
