import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { ButtonWithIcon } from "components/Buttons";
import { SideModal } from "components/Modals/SideModal";
import TitleWithLoading from "components/TitleWithLoading";
import { useListVendorsLazyQuery } from "generated/graphql";
import React from "react";
import { TableProvider, useTable } from "./context";
import AddVendor from "./Create";
import DeleteVendor from "./Delete";
import EditVendor from "./EditVendor";
import Filters, { FiltersHeader } from "./Filters";
import Table from "./Table";

const Vendors = () => {
  const { setIsCreating, isCreating, setIsEditing, isEditing, loading, fetch, variables, showFilters, setShowFilters } =
    useTable();

  return (
    <>
      <div className="py-2 px-2 flex-1 flex flex-col overflow-hidden">
        <div className="flex justify-between my-2">
          <TitleWithLoading title="Vendors" loading={loading} refresh={() => fetch({ variables })} />

          <ButtonWithIcon className="bg-brand" icon={faPlusCircle} onClick={() => setIsCreating(true)}>
            Add Vendor
          </ButtonWithIcon>
        </div>

        <FiltersHeader />
        <Table />
      </div>

      <SideModal side="RIGHT" isOpen={isCreating} onRequestClose={() => setIsCreating(false)}>
        <AddVendor />
      </SideModal>

      <SideModal side="RIGHT" isOpen={isEditing !== null} onRequestClose={() => setIsEditing(null)}>
        <EditVendor />
      </SideModal>
      <DeleteVendor />

      <SideModal side="RIGHT" isOpen={showFilters} onRequestClose={() => setShowFilters(false)} className="w-1/4">
        <Filters />
      </SideModal>
    </>
  );
};

const Container = () => {
  const query = useListVendorsLazyQuery({
    fetchPolicy: "network-only",
  });
  return (
    <TableProvider query={query} sessionKey="@factoryfinch/vendors">
      <Vendors />
    </TableProvider>
  );
};

export { Container as default };
