import React, { useState, useCallback } from "react";
import { useListVendorsQuery, Vendor } from "generated/graphql";
import { AsyncSelect } from "styles";
import { debounce } from "lodash";

export const SelectVendor: React.FC<{
  name?: string;
  className?: string;
  value?: Vendor[] | Vendor;
  onChange: (m: Vendor | Vendor[]) => void;
  size?: "base" | "large";
  placeholder?: string;
  isMulti?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  hasError?: boolean;
}> = ({
  value,

  onChange,
  className = "",
  name = "",
  size = "base",
  isMulti = false,
  isDisabled = false,
  isLoading = false,
  placeholder = "Select Vendor...",
  hasError = false,
}) => {
  const { data, refetch, loading } = useListVendorsQuery({
    variables: {
      filters: {
        name,
      },
    },
  });
  const [inputValue, setinputValue] = useState("");

  const handleInputChange = (newValue: string) => {
    setinputValue(newValue);
  };

  const fetchData = async (inputValue: string, callback: (products: Vendor[]) => void) => {
    const { data } = await refetch({ filters: { name: inputValue } });
    callback(data?.listVendors?.vendors);
  };

  // eslint-disable-next-line
  const delayedQuery = useCallback(
    debounce((inputValue, callback) => fetchData(inputValue, callback), 500),
    [inputValue]
  );

  return (
    <AsyncSelect
      isClearable
      isDisabled={isDisabled}
      isMulti={isMulti}
      isLoading={loading || isLoading}
      $textSize={size}
      $hasError={hasError}
      placeholder={placeholder}
      name={name}
      className={className}
      cacheOptions
      value={value}
      loadOptions={delayedQuery}
      defaultOptions={data?.listVendors?.vendors ?? []}
      onInputChange={handleInputChange}
      onChange={onChange}
      getOptionLabel={(p: Vendor) => p?.name}
      getOptionValue={(p: Vendor) => p?.id}
    />
  );
};
