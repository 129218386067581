import React, { createContext, PropsWithChildren, useContext, useCallback, useEffect } from "react";
import { Job, useListenForJobEventsSubscription, useGetJobLazyQuery } from "generated/graphql";
import { useImmerReducer } from "use-immer";
import { useLocalStorage } from "react-use";
import { LocalStorageKeys } from "config";

interface StateIFace {
  jobs: Record<string, Job>;

  job: Job;
}

enum Actions {
  SetJob = "SET_JOB",
}

type SetJob = {
  type: Actions.SetJob;
  data: Job;
};

type DispatcherAction = SetJob;

const initialState: StateIFace = {
  jobs: {},
  job: null,
};

const ctx = createContext<{
  state: StateIFace;
  dispatch: React.Dispatch<DispatcherAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const JobsProvider: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const [stored] = useLocalStorage<Job>(LocalStorageKeys.ShopifySyncJobId, null);
  const [state, dispatch] = useImmerReducer(
    (state: StateIFace, action: DispatcherAction) => {
      switch (action.type) {
        case Actions.SetJob:
          state.job = action.data;
          break;

        default:
          throw new Error("problem with Provider");
      }
    },
    { ...initialState, job: stored }
  );

  return (
    <ctx.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </ctx.Provider>
  );
};

export const useShopifySyncJob = () => {
  const { state, dispatch } = useContext(ctx);
  const [, set, remove] = useLocalStorage<Job>(LocalStorageKeys.ShopifySyncJobId, null);
  const [getJob, { data }] = useGetJobLazyQuery();
  const setJob = useCallback(
    (job: Job) => {
      set(job);
      dispatch({ type: Actions.SetJob, data: job });
    },
    [dispatch, set]
  );

  useListenForJobEventsSubscription({
    shouldResubscribe: true,
    variables: { id: state?.job?.id },
  });

  useEffect(() => {
    if (state?.job) {
      getJob({ variables: { queueName: "shopify", id: state?.job?.id } });
    }
  }, [getJob, state?.job]);

  useEffect(() => {
    if (data?.job?.status === "complete" || data?.job?.status === "failed") {
      remove();
    }
  }, [data, remove]);

  return {
    // ...state,
    job: data?.job ?? null,
    setJob,
    remove,
  };
};
