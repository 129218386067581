import React, { useCallback } from "react";
import { faCaretDown, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import BulkAddTags from "components/BulkAddTags";
import { ButtonWithIcon } from "components/Buttons";
import { ButtonLoading } from "components/Loading";
import { CenterModal } from "components/Modals/CenterModal";
import { Confirmation } from "components/Modals/Confirmation";
import { SideModal } from "components/Modals/SideModal";
import TitleWithLoading from "components/TitleWithLoading";
import { TagResourceType, useGetOrganizationQuery } from "generated/graphql";
import { useToast } from "hooks/toast";
import { H4, HR, P } from "styles";
import AddInventoryItem from "./AddItem";
import BulkEditItems from "./BulkEditItems";
import { ProductListProvider, useTable } from "./context";
import EditItem from "./EditItem";
import Filters, { FiltersHeader } from "./Filters";
import Table from "./Table";
import { UpdateLocationInventory } from "./UpdateLocationInventory";
import { EditTableProvider } from "./useEditTable";
import { ImportInventoryFromCSV } from "components/ImportFromCSV/Inventory";
import { UpdateInventoryFromCSV } from "components/UpdateFromCSV/Inventory";
import { TimelineModal } from "components/Views/WorkOrder/WorkOrder/Timeline";
import { notEmpty } from "utils/filters";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import useAxios from "axios-hooks";
import { LocalStorageKeys } from "config";
import { saveAs } from "file-saver";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { DateTime } from "luxon";
import QRCode from "components/QRCode";

const MoreActionsMenu = () => {
  const getOrgQuery = useGetOrganizationQuery();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { setimportInventoryItems, setUpdateInventoryItems, variables } = useTable();
  const token = localStorage.getItem(LocalStorageKeys.Token);

  const [{ loading }, send] = useAxios({ method: "POST", url: "/api/export" }, { manual: true });

  const handleExportCsv = useCallback(async () => {
    const resp = await send({
      data: {
        type: "location-inventory",
        filters: variables.filters,
      },
      responseType: "blob",
      headers: { authorization: `Bearer ${token}` },
    });

    const orgName = getOrgQuery?.data?.organization?.name?.replaceAll(" ", "-")?.toLowerCase();
    const filename = `${orgName}_inventory_${DateTime.now().toLocaleString(DateTime.DATE_SHORT)}.csv`;

    saveAs(resp.data, filename);
    handleClose();
  }, [send, token, variables.filters, getOrgQuery?.data?.organization]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <button
        type="button"
        data-testid="btn-import-from-csv"
        className="mx-3 px-3 py-2 border border-gray-700 rounded"
        onClick={handleClick}
      >
        More Actions{" "}
        <span className="ml-2">
          <FontAwesomeIcon icon={faCaretDown} />
        </span>
      </button>
      <Menu
        id="inventory-more-actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            setimportInventoryItems(true);
            handleClose();
          }}
        >
          Import from CSV
        </MenuItem>
        <MenuItem
          onClick={() => {
            setUpdateInventoryItems(true);
            handleClose();
          }}
        >
          Adjust with CSV
        </MenuItem>
        <MenuItem disabled={loading} onClick={handleExportCsv}>
          {loading ? <ButtonLoading /> : "Export CSV"}
        </MenuItem>
      </Menu>
    </>
  );
};

const Inventory: React.FC = () => {
  const toast = useToast();
  const table = useTable();

  return (
    <>
      <div className="py-2 px-2 flex-1 flex flex-col overflow-hidden">
        <div className="flex justify-between my-2">
          <TitleWithLoading title="Inventory" loading={table.loading} refresh={table.refresh} />

          <div className="flex items-center">
            <MoreActionsMenu />

            <ButtonWithIcon
              data-testid="btn-add-inventory-item"
              className="bg-brand"
              icon={faPlusCircle}
              onClick={() => table.setIsAddingProduct(true)}
            >
              Add Item
            </ButtonWithIcon>
          </div>
        </div>
        <FiltersHeader />

        <Table />
      </div>

      <SideModal
        side="RIGHT"
        isOpen={table.isEditingProduct !== null}
        onRequestClose={() => table.setEditProduct(null)}
        className="w-2/5"
      >
        <EditItem />
      </SideModal>

      <SideModal
        side="RIGHT"
        isOpen={table.showFilters}
        onRequestClose={() => table.setShowFilters(false)}
        className="w-1/4"
      >
        <Filters />
      </SideModal>

      <SideModal side="RIGHT" isOpen={table.isAddingProduct} onRequestClose={() => table.setIsAddingProduct(false)}>
        <AddInventoryItem />
      </SideModal>

      <CenterModal
        isOpen={table.addTagsToProducts}
        onRequestClose={() => table.setaddTagsToProducts(false)}
        className="bg-white dark:bg-gray-800 p-4 rounded"
      >
        <BulkAddTags
          resourceIds={Array.from(table.selected) as string[]}
          type={TagResourceType.InventoryItem}
          onRequestClose={() => table.setaddTagsToProducts(false)}
        />
      </CenterModal>

      <CenterModal
        isOpen={table.isUpdatingLocationInventory !== null}
        onRequestClose={() => table.setUpdateLocationInventoryInput(null)}
        className="bg-white dark:bg-gray-800 p-4 rounded"
      >
        <UpdateLocationInventory />
      </CenterModal>

      <Confirmation
        isOpen={table.isBulkDeleting}
        onRequestClose={() => table.setIsBulkDeleting(false)}
        onConfirm={async () => {
          try {
            const inventoryItemIds = Array.from(new Set(table.selectedRows.map((r) => r?.inventoryItem?.id)));
            await Promise.all(inventoryItemIds.map((id) => table.deleteInventoryItem({ variables: { id } })));
          } catch (err) {
            console.error(err);
            toast.error("Unable to delete products");
          } finally {
            table.setIsBulkDeleting(false);
          }
        }}
      >
        <div className="my-4">
          <H4 className="py-4 justify-center">{`Delete ${table.selected?.size} inventory item${
            table.selected?.size > 1 ? "s" : ""
          }?`}</H4>
          <HR />
          <P className="text-center">Click "Yes" to delete this inventory at all locations</P>
          {table.selected?.size < 20 && (
            <div className="flex flex-col items-center ">
              {Array.from(new Set(table.selectedRows?.map((row) => row?.inventoryItem?.name))).map((name, i) => (
                <div key={`${name}-${i}`} className="text-sm">
                  {name}
                </div>
              ))}
            </div>
          )}
        </div>
      </Confirmation>

      <Confirmation
        isOpen={table.isDeleting !== null}
        onRequestClose={() => table.setIsDeleting(null)}
        onConfirm={async () => {
          try {
            await table.deleteInventoryItem({ variables: { id: table.isDeleting?.id } });
          } catch (err) {
            console.error(err);
            toast.error("Unable to delete product");
          } finally {
            table.setIsDeleting(null);
          }
        }}
      >
        {table.isDeleting && (
          <div className="my-4">
            <H4 className="py-4">{`Delete ${table.isDeleting?.name ?? ""}?`}</H4>
            <HR />
          </div>
        )}
      </Confirmation>

      <SideModal side="RIGHT" isOpen={table.isBulkUpdating} onRequestClose={() => table.setIsBulkUpdating(false)}>
        <BulkEditItems />
      </SideModal>

      <TimelineModal
        isOpen={notEmpty(table.isViewingTimeline)}
        onRequestClose={() => table.setViewTimeline(null)}
        id={table.isViewingTimeline?.id}
        type="LOCATION_INVENTORY_ITEM"
      />

      <ImportInventoryFromCSV
        isOpen={table.isImportingFromCsv}
        onRequestClose={(imported) => {
          if (imported) {
            table.refresh();
          }
          table.setimportInventoryItems(false);
        }}
      />

      <UpdateInventoryFromCSV
        isOpen={table.isUpdatingFromCsv}
        onRequestClose={(imported) => {
          if (imported) {
            table.refresh();
          }
          table.setUpdateInventoryItems(false);
        }}
      />

      <CenterModal
        isOpen={table.viewQRCode !== null}
        onRequestClose={() => table.setViewQRCode(null)}
        className="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4"
        padding={false}
      >
        {/* TODO: ONCE MOBILE APP 1.3.0 goes out use this */}
        {/* <QRCode value={`factoryfinch://inventory/${table.viewQRCode?.id}`} /> */}
        <QRCode value={table?.viewQRCode?.id} />
      </CenterModal>
    </>
  );
};

const Container = () => (
  <ProductListProvider>
    <EditTableProvider>
      <Inventory />
    </EditTableProvider>
  </ProductListProvider>
);

export { Container as default };
