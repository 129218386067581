import cx from "classnames";
import { ButtonLoading } from "components/Loading";
import { CenterModal } from "components/Modals/CenterModal";
import React from "react";
import { PlainButton, RedButton } from "styles";

interface ConfirmationProps {
  loading?: boolean;
  className?: string;
  isOpen: boolean;
  onConfirm: () => void;
  onRequestClose: () => void;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
}

export const Confirmation: React.FC<ConfirmationProps> = ({
  loading = false,
  className,
  isOpen,
  onRequestClose,
  onConfirm,
  cancelButtonLabel = "No",
  confirmButtonLabel = "Yes",
  children,
}) => {
  return (
    <CenterModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={cx("pt-0 pb-4 px-4 bg-white dark:bg-gray-800 rounded", className)}
    >
      {children}
      <div className="flex justify-end">
        <PlainButton className="mr-4" onClick={onRequestClose}>
          {cancelButtonLabel}
        </PlainButton>
        <RedButton onClick={onConfirm}>{loading ? <ButtonLoading /> : confirmButtonLabel}</RedButton>
      </div>
    </CenterModal>
  );
};
