import useAxios from "axios-hooks";
import { StyledDateRangePicker } from "components/Form/DateRangePicker";
import { ButtonLoading } from "components/Loading";
import { CenterModal } from "components/Modals/CenterModal";
import { LocalStorageKeys } from "config";
import { saveAs } from "file-saver";
import { useGetOrganizationQuery } from "generated/graphql";
import { useToast } from "hooks/toast";
import { DateTime } from "luxon";
import React, { useCallback, useEffect, useState } from "react";
import { H4, P, PlainButton, RedButton } from "styles";

const initialDateRange = {
  startDate: DateTime.now().toUTC().toJSDate(),
  endDate: DateTime.now().toUTC().toJSDate(),
  key: "selection",
};

export const DateRangeReport: React.FC<{
  title: string;
  subtitle: string;
  reportName: string;
  isOpen: boolean;
  onClick: () => void;
  onRequestClose: () => void;
}> = ({ title, subtitle, reportName, isOpen, onRequestClose, onClick }) => {
  const toast = useToast();
  const getOrgQuery = useGetOrganizationQuery();
  const [{ loading }, send] = useAxios({ method: "POST", url: "/api/report" }, { manual: true });
  const token = localStorage.getItem(LocalStorageKeys.Token);

  const [daterange, setdaterange] = useState<{
    startDate?: Date;
    endDate?: Date;
    key?: string;
  }>(initialDateRange);

  const handleExportCsv = useCallback(
    async (range: { startDate?: Date; endDate?: Date; key?: string }) => {
      const from = DateTime.fromJSDate(range.startDate);
      const to = DateTime.fromJSDate(range.endDate);

      try {
        const resp = await send({
          data: {
            name: reportName,
            params: { from: from.toJSDate(), to: to.toJSDate() },
          },
          responseType: "blob",
          headers: { authorization: `Bearer ${token}` },
        });

        const orgName = getOrgQuery?.data?.organization?.name?.replaceAll(" ", "-")?.toLowerCase();
        const filename = `${orgName}_${reportName}_${from.toFormat("y-LL-dd")}-${to.toFormat("y-LL-dd")}.csv`;

        saveAs(resp.data, filename);
        onRequestClose();
      } catch (err) {
        toast.error("Unable to download report");
      }
    },
    [send, reportName, token, getOrgQuery?.data?.organization?.name, onRequestClose, toast]
  );

  useEffect(() => {
    if (!isOpen) {
      setdaterange(initialDateRange);
    }
  }, [isOpen]);

  return (
    <>
      <div
        className="border shadow-md dark:border-gray-700 dark:hover:border-gray-600 rounded transition duration-150 ease-in-out transform scale-95 hover:scale-100 flex"
        onClick={onClick}
      >
        <div className="bg-brand h-full rounded-tl rounded-bl" style={{ width: "8px" }} />
        <div className="p-4">
          <H4>{title}</H4>
          <P>{subtitle}</P>
        </div>
      </div>
      <CenterModal isOpen={isOpen} onRequestClose={onRequestClose}>
        <H4>Download {title} Report</H4>

        <div className="text-center my-4">Select a date range</div>

        <div className="flex justify-center">
          <StyledDateRangePicker
            ranges={[daterange]}
            onChange={(ranges) => setdaterange(ranges["selection"])}
            rangeColors={["#d92452"]}
            color="#d92452"
            maxDate={new Date()}
            months={1}
          />
        </div>
        <div className="flex flex-row justify-end">
          <div className={"px-2 py-4 text-sm grid grid-cols-2 gap-x-2"}>
            <PlainButton type="button" onClick={onRequestClose}>
              Cancel
            </PlainButton>
            <RedButton type="button" onClick={() => handleExportCsv(daterange)}>
              {loading ? <ButtonLoading /> : "Submit"}
            </RedButton>
          </div>
        </div>
      </CenterModal>
    </>
  );
};

export const NoInputReport: React.FC<{
  title: string;
  subtitle: string;
  reportName: string;
  isOpen: boolean;
  onClick: () => void;
  onRequestClose: () => void;
}> = ({ title, subtitle, reportName, isOpen, onRequestClose, onClick }) => {
  const toast = useToast();
  const getOrgQuery = useGetOrganizationQuery();
  const [{ loading }, send] = useAxios({ method: "POST", url: "/api/report" }, { manual: true });
  const token = localStorage.getItem(LocalStorageKeys.Token);

  const handleExportCsv = useCallback(async () => {
    try {
      const resp = await send({
        data: {
          name: reportName,
          params: {},
        },
        responseType: "blob",
        headers: { authorization: `Bearer ${token}` },
      });

      const orgName = getOrgQuery?.data?.organization?.name?.replaceAll(" ", "-")?.toLowerCase();
      const filename = `${orgName}_${reportName}_${DateTime.fromJSDate(new Date()).toFormat("y-LL-dd")}.csv`;

      saveAs(resp.data, filename);
      onRequestClose();
    } catch (err) {
      toast.error("Unable to download report");
    }
  }, [send, reportName, token, getOrgQuery?.data?.organization?.name, onRequestClose, toast]);

  return (
    <>
      <div
        className="border shadow-md dark:border-gray-700 dark:hover:border-gray-600 rounded transition duration-150 ease-in-out transform scale-95 hover:scale-100 flex"
        onClick={onClick}
      >
        <div className="bg-brand h-full rounded-tl rounded-bl" style={{ width: "8px" }} />
        <div className="p-4">
          <H4>{title}</H4>
          <P>{subtitle}</P>
        </div>
      </div>
      <CenterModal isOpen={isOpen} onRequestClose={onRequestClose}>
        <H4>Download {title} Report</H4>

        <div className="flex justify-center"></div>
        <div className="flex flex-row justify-end">
          <div className={"px-2 py-4 text-sm grid grid-cols-2 gap-x-2"}>
            <PlainButton type="button" onClick={onRequestClose}>
              Cancel
            </PlainButton>
            <RedButton type="button" onClick={handleExportCsv}>
              {loading ? <ButtonLoading /> : "Submit"}
            </RedButton>
          </div>
        </div>
      </CenterModal>
    </>
  );
};
