import { QueryTuple } from "@apollo/client";
import {
  Exact,
  ListLocationsDocument,
  ListLocationsFilters,
  ListLocationsQuery,
  ListLocationsQueryVariables,
  Location,
  PageInfo,
  Pagination,
  useCreateLocationMutation,
  useDeleteLocationMutation,
  useSetPrimaryLocationMutation,
} from "generated/graphql";
import { useToast } from "hooks/toast";
import { omit } from "lodash";
import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo } from "react";
import { useSessionStorage } from "react-use";
import { useImmerReducer } from "use-immer";
import { generatePdfLabels } from "utils/labels";

const LABEL_TITLE = "LOCATION";

interface StateIFace {
  filters: ListLocationsFilters;
  pagination: PageInfo;
  showFilters: boolean;
  selected: Set<React.Key>;
  isCreating: boolean;
  isEditing: Location;
  isDeleting: Location;
}

type SetPagination = {
  type: Actions.SetPagination;
  data: { perPage: number; page: number };
};

type SetSelected = {
  type: Actions.SetSelected;
  data: Set<React.Key>;
};

type SetShowFilters = {
  type: Actions.SetShowFilters;
  data: boolean;
};

type SetFilters = {
  type: Actions.SetFilters;
  data: ListLocationsFilters;
};

type SetIsCreating = {
  type: Actions.SetIsCreating;
  data: boolean;
};

type SetIsEditing = {
  type: Actions.SetIsEditing;
  data: Location;
};

type SetIsDeleting = {
  type: Actions.SetIsDeleting;
  data: Location;
};

type DispatcherAction =
  | SetShowFilters
  | SetSelected
  | SetPagination
  | SetFilters
  | SetIsCreating
  | SetIsEditing
  | SetIsDeleting;

enum Actions {
  SetFilters = "SET_FILTERS",
  SetShowFilters = "SET_SHOW_FILTERS",
  SetPagination = "SET_PAGINATION",
  SetSelected = "SET_SELECTED",
  SetIsAdding = "SET_IS",
  SetIsCreating = "SET_IS_CREATING",
  SetIsEditing = "SET_IS_EDITING",
  SetIsDeleting = "SET_IS_DELETING",
}

const initialState: StateIFace = {
  filters: null,
  showFilters: false,
  selected: new Set<React.Key>(),
  pagination: { perPage: 10, page: 1 },
  isCreating: false,
  isEditing: null,
  isDeleting: null,
};

const ctx = createContext<{
  query: QueryTuple<
    ListLocationsQuery,
    Exact<{
      pagination?: Pagination;
      filters?: ListLocationsFilters;
    }>
  >;
  state: StateIFace;
  dispatch: React.Dispatch<DispatcherAction>;
}>({ query: null, state: initialState, dispatch: () => null });

type ITableProvider<QT, FT> = PropsWithChildren<{
  query: QueryTuple<
    QT,
    Exact<{
      pagination?: Pagination;
      filters?: FT;
    }>
  >;
  sessionKey: string;
}>;

export const TableProvider = <QT, FT>({ children, sessionKey, query }: ITableProvider<QT, FT>) => {
  const [filters, setFilters] = useSessionStorage<{
    filters: ListLocationsFilters;
    pagination: PageInfo;
  }>(sessionKey, {
    filters: initialState.filters,
    pagination: initialState.pagination,
  });

  const [state, dispatch] = useImmerReducer(
    (state: StateIFace, action: DispatcherAction) => {
      switch (action.type) {
        case Actions.SetPagination:
          state.pagination = action.data;
          break;

        case Actions.SetSelected:
          state.selected = action.data;
          break;

        case Actions.SetShowFilters:
          state.showFilters = action.data;
          break;

        case Actions.SetFilters:
          state.filters = action.data;
          break;

        case Actions.SetIsCreating:
          state.isCreating = action.data;
          break;

        case Actions.SetIsEditing:
          state.isEditing = action.data;
          break;

        case Actions.SetIsDeleting:
          state.isDeleting = action.data;
          break;
        default:
          throw new Error("problem with Table Provider");
      }
    },
    { ...initialState, ...filters }
  );

  useEffect(() => {
    setFilters({
      filters: state.filters,
      pagination: state.pagination,
    });
  }, [state, setFilters]);

  return (
    <ctx.Provider
      value={{
        query,
        state,
        dispatch,
      }}
    >
      {children}
    </ctx.Provider>
  );
};

export const useLocationsTable = () => {
  const { query, state, dispatch } = useContext(ctx);
  const [fetch, { data, loading, variables: qvariables }] = query;

  const mutationOpts = {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ListLocationsDocument, variables: qvariables }],
  };
  const [setPrimaryLocation] = useSetPrimaryLocationMutation(mutationOpts);
  const [deleteLocation] = useDeleteLocationMutation(mutationOpts);
  const [createLocation] = useCreateLocationMutation(mutationOpts);

  const setPagination = useCallback(
    (pagination: Pagination) => {
      const isChangingPerPage = !!(pagination?.perPage && pagination?.perPage !== state?.pagination?.perPage);
      const page = isChangingPerPage ? 1 : pagination?.page || state?.pagination?.page;
      const perPage = pagination?.perPage || state?.pagination?.perPage || 10;
      dispatch({
        type: Actions.SetPagination,
        data: {
          page,
          perPage,
        },
      });
    },
    [dispatch, state.pagination]
  );

  const setselected = useCallback(
    (rows: Set<React.Key>) => dispatch({ type: Actions.SetSelected, data: rows }),
    [dispatch]
  );

  const setShowFilters = (open: boolean) => {
    dispatch({ type: Actions.SetShowFilters, data: open });
  };

  const setFilters = (filters: ListLocationsFilters) => {
    dispatch({ type: Actions.SetFilters, data: filters });
  };

  const removeFilter = (property: string, i?: number) => {
    setFilters(omit(state.filters, [property]));
  };

  const resetFilters = () => {
    dispatch({
      type: Actions.SetFilters,
      data: {},
    });
  };

  const setIsCreating = (isCreating: boolean) => {
    dispatch({ type: Actions.SetIsCreating, data: isCreating });
  };

  const setIsEditing = (location: Location) => {
    dispatch({ type: Actions.SetIsEditing, data: location });
  };

  const setIsDeleting = (location: Location) => {
    dispatch({ type: Actions.SetIsDeleting, data: location });
  };

  const variables: ListLocationsQueryVariables = useMemo(
    () => ({
      filters: state.filters,
      pagination: state.pagination,
    }),

    [state.pagination, state.filters]
  );

  const selectedRows = useMemo(() => {
    return (
      data?.listLocations?.locations?.filter((location) => {
        return state.selected.has(location.id);
      }) ?? []
    );
  }, [data?.listLocations?.locations, state.selected]);

  return {
    ...state,
    data,
    loading,
    variables,
    selectedRows,
    setIsCreating,
    setIsEditing,
    setIsDeleting,
    setPagination,
    setselected,
    setShowFilters,
    setFilters,
    removeFilter,
    resetFilters,
    fetch,
    setPrimaryLocation,
    deleteLocation,
    createLocation,
  };
};

export type RowActions =
  | "printLabel"
  | "setPrimary"
  | "generateLabels"
  | "deleteLocation"
  | "deleteLocations"
  | "editLocation";

export const useRowActions = () => {
  const { selectedRows, setPrimaryLocation, setIsDeleting, setIsEditing } = useLocationsTable();
  const toast = useToast();
  const _setPrimaryLocation = async (location: Location) => {
    try {
      setPrimaryLocation({ variables: { id: location.id } });
    } catch (err) {
      toast.error("Unable to set primary location");
    }
  };

  const handleAction = (action: RowActions, row: Location) => {
    switch (action) {
      case "printLabel":
        generatePdfLabels(LABEL_TITLE, [row]);
        break;
      case "setPrimary":
        _setPrimaryLocation(row);
        break;
      case "generateLabels":
        generatePdfLabels(LABEL_TITLE, selectedRows);
        break;

      case "editLocation":
        setIsEditing(row);
        break;

      case "deleteLocation":
        setIsDeleting(row);
        break;

      case "deleteLocations":
        break;

      default:
        break;
    }
  };

  return handleAction;
};
