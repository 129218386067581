import React from "react";
import { Link, Redirect } from "react-router-dom";
import { Routes } from "routes";
import { useForm } from "react-hook-form";
import { useAuth } from "hooks/auth";
import { useToast } from "hooks/toast";
import { DarkRedButton, Form } from "styles";
import { ButtonLoading } from "components/Loading";
import TextInput from "components/Form/TextInput";
import { PreAuthLayout } from "components/Layouts/PreAuth";
import { useAnalytics } from "hooks/analytics";
import { gsIdentify, nodeEvent } from "components/Events/events";

interface ILoginForm {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const { track } = useAnalytics();
  const { isLoggedIn, login } = useAuth();
  const { register, handleSubmit, formState } = useForm<ILoginForm>();

  const { errors } = formState;

  const toast = useToast();

  const onSubmit = async (formData: ILoginForm) => {
    track("Login Button Clicked", { type: "Email and Password" });
    try {
      await login(formData);
      track("Logged In");
      gsIdentify(formData.email);
      nodeEvent("Logged In", formData.email);
    } catch (err) {
      toast.error(err?.message ?? "unable to sign in");
    }
  };

  if (isLoggedIn) {
    return <Redirect to={Routes.Root} />;
  }

  return (
    <PreAuthLayout>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col p-4 mt-4 w-full sm:w-4/6 md:w-3/6 lg:w-4/6 xl:w-3/6 relative z-10"
      >
        <TextInput
          {...register("email", { required: true })}
          placeholder="Email"
          data-testid="email"
          autoComplete="username"
        />
        {errors.email && "Email is required"}
        <TextInput
          {...register("password", { required: true, minLength: "8" })}
          placeholder="Password"
          type="password"
          data-test
          autoComplete="current-password"
        />
        {errors.password && "Password is required"}
        <DarkRedButton disabled={formState.isSubmitting} type="submit" data-testid="submit">
          {formState.isSubmitting ? <ButtonLoading /> : "Submit"}
        </DarkRedButton>

        <Link className="m-auto my-4 text-sm text-white font-semibold" to={Routes.MagicLogin}>
          Send me a magic link instead
        </Link>

        <div className="flex flex-col">
          <Link className="m-auto mt-4 text-white text-sm" to={Routes.SignUp}>
            No Account? Sign up here
          </Link>
          <Link
            className="m-auto mt-4 text-white text-sm"
            to={Routes.ForgotPassword}
            onClick={() => track("Forgot Password Button Clicked")}
          >
            Forgot your password?
          </Link>
        </div>
      </Form>
    </PreAuthLayout>
  );
};

export { Login as default };
