import React, { useCallback, useRef, useState } from "react";
import { ButtonLoading } from "components/Loading";
import { PlainButton, RedButton } from "styles";
import AvatarEditor from "react-avatar-editor";
import { CenterModal } from "components/Modals/CenterModal";
import { useUploadFile } from "hooks/upload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { SupportedImageMimeTypes } from "config";
import { BirdOnlyLogo } from "components/Logo";
import { theme } from "twin.macro";

export const ProfilePicture: React.FC<{ url: string; onSave: (url: string) => Promise<void> }> = ({ url, onSave }) => {
  const uploadFile = useUploadFile();
  const [isOpen, setisOpen] = useState(false);
  const [file, setFile] = useState<File>(null);
  const ref = useRef<AvatarEditor>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploading, setUploading] = useState<number>(null);
  const [scale, setscale] = useState(1.0);

  const closeEditor = () => {
    setFile(null);
    setUploading(null);
    setscale(1.0);
    setisOpen(false);
  };

  const onClickSave = async () => {
    const editor = ref.current;
    const canvas = editor.getImage().toDataURL();
    const file = await fetch(canvas).then(async (res) => {
      const blob = await res.blob();

      return new File([blob], "avatar.png", {
        type: blob.type,
      });
    });

    try {
      const resp = await uploadFile(file, (e) => {
        setUploading(e.loaded / e.total);
      });
      await onSave(resp?.data?.url);
      closeEditor();
    } catch (err) {
      console.log(err);
    }
  };

  const onZoom = useCallback(
    (e: React.WheelEvent<HTMLDivElement>) => {
      if (Math.floor(scale) <= 2.0 && e.deltaY > 0) {
        setscale(scale + 0.1);
      }

      if (Math.floor(scale) >= 1.0 && e.deltaY < 0) {
        setscale(scale - 0.1);
      }
    },
    [scale]
  );

  const onLoadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];
    if (file.size > 5000000) {
      console.log("file too large");
    } else {
      setFile(file);
    }
  };

  const toggleEditor = () => {
    if (isOpen) {
      closeEditor();
    } else {
      setisOpen(true);
    }
  };

  const openFilePicker = () => {
    fileInputRef?.current?.click();
  };

  return (
    <>
      <div className="relative h-64 w-64">
        <div
          className="cursor-pointer absolute bottom-0 right-0 rounded-full text-white bg-gray-600 hover:bg-gray-400 w-12 h-12 flex justify-center items-center"
          onClick={toggleEditor}
        >
          <FontAwesomeIcon icon={faCamera} size="lg" />
        </div>
        <div
          className="rounded-full h-64 w-64 bg-gray-400 flex justify-center items-center"
          style={{
            backgroundImage: `url(${url})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          {!url && <BirdOnlyLogo width={100} color={theme`colors.gray.300`} />}
        </div>
      </div>

      <CenterModal isOpen={isOpen} onRequestClose={closeEditor} className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
        {file === null ? (
          <div>
            <div
              onClick={openFilePicker}
              className="cursor-pointer flex flex-col justify-center items-center h-32 border border-dashed bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700 hover:bg-gray-200 text-gray-500 dark:text-gray-300 rounded"
            >
              <FontAwesomeIcon icon={faCloudUploadAlt} size="3x" />
              <div className="text-lg">Click here to select a picture</div>
            </div>
            <input
              className="hidden"
              type="file"
              ref={fileInputRef}
              onChange={onLoadFile}
              accept={SupportedImageMimeTypes.join(",")}
            />
          </div>
        ) : (
          <div className="flex flex-col">
            <div className="w-full flex flex-col justify-center items-center">
              <div className="font-semibold">Position your photo how you'd like it</div>

              <div onWheel={onZoom}>
                <AvatarEditor
                  on
                  ref={ref}
                  image={file}
                  width={250}
                  height={250}
                  border={25}
                  color={[255, 255, 255, 0.6]} // RGBA
                  scale={scale}
                  rotate={0}
                  borderRadius={1000}
                />
              </div>
              <div className="text-xs">Scroll to zoom</div>
              <div className="text-xs">Click and Drag to move</div>
            </div>
            <div className="flex justify-end">
              <PlainButton className="mr-4" onClick={closeEditor}>
                Cancel
              </PlainButton>
              <RedButton onClick={onClickSave}>{uploading ? <ButtonLoading /> : "Upload"}</RedButton>
            </div>
          </div>
        )}
      </CenterModal>
    </>
  );
};
