import { ScrollableForm } from "components/Form/ScrollableForm";
import { AttachmentResourceType, ListStepsDocument, Step, useUpdateStepMutation } from "generated/graphql";
import { useToast } from "hooks/toast";
import { useAttachment } from "hooks/upload";
import React from "react";
import { useForm } from "react-hook-form";
import { useTable } from "./context";
import StepForm, { IStepFormData } from "./StepForm";
import { omit } from "lodash";

export const EditStepForm: React.FC<{
  step: Step;
  onSubmit: (data: IStepFormData) => Promise<void>;
  onCancel: () => void;
}> = ({ step, onSubmit, onCancel }) => {
  const form = useForm<IStepFormData>({
    defaultValues: {
      name: step?.name,
      points: step?.points,
      description: step?.description,
      promptOperatorToConsumeInventory: step?.promptOperatorToConsumeInventory,
      properties: step?.properties ?? [],
    },
  });

  return (
    <ScrollableForm
      title="Edit Step"
      onSubmit={form.handleSubmit(onSubmit)}
      onCancel={onCancel}
      submitLabel="Save"
      formState={form?.formState}
    >
      <StepForm step={step} form={form} />
    </ScrollableForm>
  );
};

const EditStep: React.FC = () => {
  const toast = useToast();
  const { isEditing, variables, setIsEditing } = useTable();
  const step = isEditing;
  const [updateStep] = useUpdateStepMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ListStepsDocument, variables }],
  });
  const addAttachment = useAttachment();
  const submit = async (data: IStepFormData) => {
    try {
      for (const attachment of data?.attachments ?? []) {
        if (!attachment?.value?.file) {
          continue;
        }
        await addAttachment(attachment.value.file.item(0), {
          fileName: attachment.value.name,
          resourceId: step?.id,
          resourceType: AttachmentResourceType.Step,
          onProgress: (e) => console.log(e),
        });
      }

      await updateStep({
        variables: {
          input: {
            id: step?.id,
            name: data?.name,
            description: data?.description,
            points: data?.points,
            promptOperatorToConsumeInventory: data?.promptOperatorToConsumeInventory,
            properties: data?.properties.map((p) => omit(p, "__typename")),
          },
        },
      });
      setIsEditing(null);
    } catch (err) {
      toast.error("Unable to update step");
    }
  };

  return <EditStepForm step={step} onSubmit={submit} onCancel={() => setIsEditing(null)} />;
};

export { EditStep as default };
