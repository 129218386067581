import cx from "classnames";
import Checkbox from "components/Form/Checkbox";
import { TagInput } from "components/Form/TagInput";
import { SelectCustomerOrderFulfillmentStatuses } from "components/Select/SalesOrder/SalesOrderFulfillmentStatus";
import { SelectCustomerOrderPaidStatuses } from "components/Select/SalesOrder/SalesOrderPaidStatus";
import { SelectCustomerOrderPickStatuses } from "components/Select/SalesOrder/SalesOrderPickStatus";
import React, { useCallback } from "react";
import { useTable } from "./context";

export const QuickFilters = () => {
  const { filters, showQuickFilters, setFilters } = useTable();

  const setValue = useCallback(
    (v: any) => {
      setFilters({
        ...filters,
        ...v,
      });
    },
    [filters, setFilters]
  );
  return (
    <div className={cx(!showQuickFilters && "hidden")}>
      <div className={"grid grid-cols-1 lg:grid-cols-4 gap-x-2 gap-y-2 py-2"}>
        <div>
          <label className="text-xs">Order numbers</label>
          <TagInput
            tags={filters?.orderNumbers ?? []}
            onChange={(values) => setValue({ orderNumbers: values })}
            removeOnBackspace={true}
          />
        </div>

        <SelectCustomerOrderFulfillmentStatuses
          label="Fulfillment Status"
          value={filters?.fulfillmentStatus}
          onChange={(v) => setValue({ fulfillmentStatus: v })}
        />
        <SelectCustomerOrderPickStatuses
          label="Pick Status"
          value={filters?.pickStatus}
          onChange={(v) => setValue({ pickStatus: v })}
        />
        <SelectCustomerOrderPaidStatuses
          label="Paid Status"
          value={filters?.paidStatus}
          onChange={(v) => setValue({ paidStatus: v })}
        />
      </div>
      <Checkbox
        onChange={({ checked }) => setValue({ onlyPickable: checked })}
        checked={filters?.onlyPickable}
        label="Only show pickable"
      />
    </div>
  );
};
