import { EditLocationForm, IEditLocationForm } from "components/Views/Location/Locations/EditLocation";
import { Location, useUpdateLocationMutation } from "generated/graphql";
import React from "react";

export const EditLocation: React.FC<{
  location: Location;
  onRequestClose: () => void;
}> = ({ location, onRequestClose }) => {
  const [updateLocation] = useUpdateLocationMutation();
  const onSubmit = async (input: IEditLocationForm) => {
    try {
      await updateLocation({
        variables: {
          input: {
            id: input.id,
            name: input.name,
            address1: input.address1,
            address2: input.address2,
            city: input.city,
            postalCode: input.postalCode,
            region: input?.region?.name,
            country: input?.country?.countryName,
            phone: input.phone,
            primary: input.primary,
          },
        },
      });
      onRequestClose();
    } catch (err) {
      console.log(err);
    }
  };

  return <EditLocationForm location={location} onSubmit={onSubmit} onCancel={onRequestClose} />;
};
