import React from "react";
import ReactDOM from "react-dom";
import {
  ApolloClient,
  ApolloClientOptions,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import * as Sentry from "@sentry/react";
import App from "components/App";
import ErrorFallback from "components/ErrorFallback";
import { AppProvider } from "hooks/app";
import { AuthProvider } from "hooks/auth";
import { ThemeProvider } from "hooks/theme";
import { enableMapSet, setAutoFreeze } from "immer";
import { BrowserRouter as Router } from "react-router-dom";
import {
  APP_NAME,
  COMMIT_SHA,
  SENTRY_DSN,
  WEBSOCKET_URI,
  LocalStorageKeys,
  INTERCOM_APP_ID,
  SEGMENT_WRITE_KEY,
} from "./config";
import { IntercomProvider } from "react-use-intercom";
import * as serviceWorker from "./serviceWorker";
import "./tailwind.generated.css";
import { AnalyticsProvider } from "hooks/analytics";
import { relayStylePagination } from "@apollo/client/utilities";

Sentry.init({ dsn: SENTRY_DSN, release: `${APP_NAME}@${COMMIT_SHA}` });
enableMapSet();
setAutoFreeze(false);

const createApolloClientConfig: () => ApolloClientOptions<NormalizedCacheObject> = () => {
  const cache = new InMemoryCache({
    possibleTypes: {
      AppSubscription: ["StripeSubscription", "FactoryFinchSubscription"],
      Integration: ["ShopifyIntegration"],
      Node: ["WorkflowStep", "ManufacturingOrderStep"],
      Trigger: ["WebhookTrigger", "EmailTrigger", "TextMessageTrigger", "MicrosoftTeamsTrigger"],
      Me: ["User", "Employee"],
      CreatedBy: ["User", "Employee"],
    },
    typePolicies: {
      Query: {
        fields: {
          getStream: relayStylePagination(["id", "type"]),
        },
      },
    },
  });

  const linkError = onError((err: any) => {
    if (err.graphQLErrors)
      err.graphQLErrors.map(({ message, locations, path }) =>
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      );
    if (err.networkError) {
      console.log(`[Network error]:`, err.networkError);
    }
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem(LocalStorageKeys.Token);
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const httpLink = new HttpLink({ uri: "/api/graphql" });
  const token = localStorage.getItem(LocalStorageKeys.Token);
  const wsLink = new WebSocketLink({
    uri: WEBSOCKET_URI,
    options: {
      reconnect: true,
      connectionParams: {
        authToken: token,
      },
    },
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    },
    wsLink,
    httpLink
  );

  const link = ApolloLink.from([authLink, linkError, splitLink]);

  return {
    cache,
    link,
    connectToDevTools: true,
  };
};

const client = new ApolloClient(createApolloClientConfig());

ReactDOM.render(
  <Sentry.ErrorBoundary showDialog fallback={ErrorFallback}>
    <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={false}>
      <ApolloProvider client={client}>
        <Router>
          <AnalyticsProvider writeKey={SEGMENT_WRITE_KEY} app={APP_NAME}>
            <AuthProvider>
              <AppProvider>
                <ThemeProvider>
                  <App />
                </ThemeProvider>
              </AppProvider>
            </AuthProvider>
          </AnalyticsProvider>
        </Router>
      </ApolloProvider>
    </IntercomProvider>
  </Sentry.ErrorBoundary>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
