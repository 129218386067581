import { TriggerType } from "generated/graphql";

export type TriggerOption = {
  label: string;
  type: TriggerType;
  data: any;
};

export const triggerOptions: TriggerOption[] = [
  {
    label: "Email",
    type: TriggerType.Email,
    data: [
      {
        label: "Email",
        placeholder: "carrier-pigeon@factoryfinch.com",
        key: "recipient",
        type: "email",
      },
    ],
  },
  {
    label: "Text Message",
    type: TriggerType.TextMessage,
    data: [
      {
        label: "Phone Number",
        placeholder: "() - ",
        key: "recipient",
        type: "phoneNumber",
      },
    ],
  },
  {
    label: "Webhook",
    type: TriggerType.Webhook,
    data: [
      {
        label: "URL",
        placeholder: "https://example.com",
        key: "url",
        type: "url",
      },
    ],
  },
  {
    label: "Microsoft Teams",
    type: TriggerType.MicrosoftTeams,
    data: [
      {
        label: "URL",
        placeholder: "https://example.com",
        key: "url",
        type: "url",
      },
    ],
  },
];
