import { ScrollableForm } from "components/Form/ScrollableForm";
import { Confirmation } from "components/Modals/Confirmation";
import {
  IInventoryTransferFormData,
  InventoryTransferForm,
} from "components/Views/InventoryTransfer/InventoryTransfers/Form";
import { InventoryItem, InventoryTransferStatus, useCreateInventoryTransferMutation } from "generated/graphql";
import { usePrompt } from "hooks/prompt";
import { useToast } from "hooks/toast";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { H4, HR, P } from "styles";

export const CreateInventoryTransfer: React.FC<{
  onRequestClose: () => void;
}> = ({ onRequestClose }) => {
  const [confirmCancel, setconfirmCancel] = useState(false);
  const location = useLocation<{ items: InventoryItem[] }>();
  const [createInventoryTransfer] = useCreateInventoryTransferMutation();
  const form = useForm<IInventoryTransferFormData>({
    defaultValues: {
      status: InventoryTransferStatus.Draft,
      lineItems: [],
    },
  });
  usePrompt(form?.formState?.isDirty ?? false);
  const toast = useToast();

  const submit = async (formData: IInventoryTransferFormData) => {
    if (formData?.from?.id === formData?.to?.id) {
      toast.error("You cannot send inventory to the same location");
      return;
    }

    try {
      await createInventoryTransfer({
        variables: {
          input: {
            fromId: formData.from.id,
            toId: formData.to.id,
            lineItems: formData.lineItems.map((row) => ({
              inventoryId: row.inventoryItem.id,
              quantity: row.quantity,
            })),
          },
        },
      });
      toast.success("Inventory transfer created");
      onRequestClose();
    } catch (err) {
      toast.error("unable to create inventory transfer");
    }
  };

  const onError = (errors: any) => {
    console.log(errors);
  };

  const onConfirmCancel = useCallback(() => {
    setconfirmCancel(false);
    onRequestClose();
  }, [onRequestClose]);

  const onCancel = useCallback(() => {
    if (form.formState.isDirty) {
      setconfirmCancel(true);
      return;
    }

    onRequestClose();
  }, [form.formState.isDirty, onRequestClose]);

  useEffect(() => {
    form.setValue(
      "lineItems",
      location?.state?.items?.map((item, i) => {
        return {
          id: `_${i}`,
          inventoryItem: item,
          quantity: 0,
        };
      }) ?? []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, form.setValue]);

  return (
    <>
      <ScrollableForm
        title="Create Inventory Transfer"
        formState={form.formState}
        onSubmit={form.handleSubmit(submit, onError)}
        onCancel={onCancel}
        submitLabel="Save"
        classNames={{ buttonContainer: "w-full lg:w-1/3" }}
      >
        <InventoryTransferForm form={form} disableStatus />
      </ScrollableForm>
      <Confirmation
        isOpen={confirmCancel}
        cancelButtonLabel="Go back"
        confirmButtonLabel="I'm sure"
        onRequestClose={() => setconfirmCancel(false)}
        onConfirm={onConfirmCancel}
      >
        <div className="my-4 w-full">
          <H4 className="py-4 flex flex-row justify-center text-default dark:text-white">You have unsaved changes.</H4>
          <P className="text-center pb-4">Are you sure you'd like to cancel?</P>
          <HR />
        </div>
      </Confirmation>
    </>
  );
};
