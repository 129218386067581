import React from "react";
import TextInput from "components/Form/TextInput";
import { useCreateCustomerAddressMutation, GetCustomerDocument } from "generated/graphql";
import { useForm, Controller } from "react-hook-form";
import { useToast } from "hooks/toast";
import { ScrollableForm } from "components/Form/ScrollableForm";
import { CountryOption, RegionOption, SelectCountry, SelectRegion } from "components/Form/CountryAndRegion";
import { SelectAddressType } from "components/Select/AddressType";
export interface IAddressFormData {
  address1: string;
  address2: string;
  address3: string;
  city: string;
  region: Pick<RegionOption, "name">;
  country: Pick<CountryOption, "countryName">;
  postalCode: string;
  nickname: string;
  type: string;
}

export const AddAddress: React.FC<{ customerId: string; onRequestClose: () => void }> = ({
  customerId,
  onRequestClose,
}) => {
  const toast = useToast();
  const [createAddress] = useCreateCustomerAddressMutation({
    refetchQueries: [{ query: GetCustomerDocument, variables: { id: customerId } }],
  });
  const { handleSubmit, register, control, setValue, watch, formState } = useForm<IAddressFormData>({
    defaultValues: {
      address1: "",
      address2: "",
      city: "",
      region: {
        name: "",
      },
      country: null,
      postalCode: "",
      nickname: "",
      type: "",
    },
  });
  const country = watch("country");
  const save = async (formData: IAddressFormData) => {
    try {
      await createAddress({
        variables: {
          input: {
            customerId,
            address1: formData?.address1 ?? null,
            address2: formData?.address2 ?? null,
            city: formData?.city ?? null,
            state: formData?.region?.name ?? null,
            country: formData?.country?.countryName ?? null,
            postalCode: formData?.postalCode ?? null,
            nickname: formData?.nickname ?? null,
            type: formData?.type ?? null,
            // shipVia: formData?.nickname ?? null,
          },
        },
      });
      onRequestClose();
    } catch (err) {
      console.log(err);
      toast.error("Unable to add address");
    }
  };

  return (
    <ScrollableForm title="Add Address" onCancel={onRequestClose} onSubmit={handleSubmit(save)} formState={formState}>
      <TextInput label="Nickname" placeholder="ie. Headquarters" {...register("nickname")} />
      <TextInput label="Address 1" placeholder="ie. 123 Fake St." {...register("address1")} />
      <TextInput label="Address 2" placeholder="ie. Unit B" {...register("address2")} />
      <TextInput label="Address 3" placeholder="" {...register("address3")} />
      <TextInput label="City" placeholder="ie. Austin" {...register("city")} />

      <Controller
        control={control}
        name="country"
        render={({ field }) => {
          return (
            <SelectCountry
              label="Country"
              onChange={(v) => {
                setValue("region", null);
                field.onChange(v);
              }}
              value={field.value}
            />
          );
        }}
      />

      <Controller
        control={control}
        name="region"
        render={({ field }) => {
          return (
            <SelectRegion label="Region" country={country} onChange={(v) => field.onChange(v)} value={field.value} />
          );
        }}
      />

      <TextInput label="Postal Code/Zip" placeholder="ie. 78702" {...register("postalCode")} />

      <Controller
        control={control}
        name="type"
        render={({ field }) => {
          return <SelectAddressType label="Type" onChange={field.onChange} value={field.value} />;
        }}
      />
    </ScrollableForm>
  );
};
