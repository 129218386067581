import React from "react";
import { Page, Document, Image, StyleSheet, View, Text } from "@react-pdf/renderer";
import { Table, TableBody, TableHeader, DataTableCell, TableCell } from "@david.kucsai/react-pdf-table";
import { shortId } from "utils/formatters";
import { CustomerOrder, LineItem } from "generated/graphql";
import { theme } from "twin.macro";
import { generateQRCodeImage } from "utils/qrcode";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 40,
    paddingLeft: 60,
    paddingRight: 60,
    paddingBottom: 40,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  logo: {
    width: 74,
    height: 66,
    marginLeft: "auto",
    marginRight: "auto",
  },
  tableHeader: {
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 8,
    fontFamily: "Helvetica-Bold",
    backgroundColor: theme`colors.gray.200`,
  },
  tableCell: {
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 5,
  },
  itemName: {
    fontSize: 10,
    fontFamily: "Helvetica-Bold",
  },

  itemTags: {
    fontSize: 8,
  },

  pageHeader: {
    position: "absolute",
    top: 20,
    left: 20,
    fontSize: 10,
  },

  pageNumber: {
    position: "absolute",
    bottom: 20,
    right: 20,
  },
  title: {
    fontFamily: "Helvetica-Bold",
    fontSize: 20,
  },

  customerHeader: {
    fontFamily: "Helvetica-Bold",
    fontSize: 10,
  },

  customerName: {
    fontSize: 10,
  },

  customerEmail: {
    fontSize: 10,
  },

  qrCode: {
    height: 75,
    width: 75,
  },
  orderDetails: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  sku: {
    fontSize: 8,
  },
  quantity: {},
});

export const SalesOrderPDF = ({ order }: { order: CustomerOrder }) => (
  <Document>
    <Page size="A4" style={styles.page} wrap>
      <View>
        <Text style={styles.title}>{`Sales Order ${shortId(order?.id)}`}</Text>
        <View style={styles.orderDetails}>
          <View>
            <Text style={styles.customerHeader}>Customer</Text>
            <Text style={styles.customerName}>{`${order?.customer?.firstName} ${order?.customer?.lastName}`}</Text>
            <Text style={styles.customerEmail}>{order?.customer?.email}</Text>
          </View>
          <Image style={styles.qrCode} source={generateQRCodeImage(order?.id)} />
        </View>
      </View>
      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
      <Text
        style={styles.pageHeader}
        fixed
        render={({ pageNumber, totalPages }) => pageNumber > 1 && `Sales Order ${shortId(order?.id)}`}
      />

      <Table data={order?.lineItems ?? []}>
        <TableHeader>
          <TableCell style={styles.tableHeader} weighting={0.5}>
            Item
          </TableCell>
          <TableCell style={styles.tableHeader} weighting={0.25}>
            SKU
          </TableCell>
          <TableCell style={styles.tableHeader} weighting={0.25}>
            Quantity
          </TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell
            weighting={0.5}
            style={styles.tableCell}
            getContent={(item: LineItem) => {
              return (
                <View>
                  <View>
                    <Text style={styles.itemName}>{item?.inventoryItem?.inventoryItem?.name}</Text>
                  </View>
                  <View>
                    {item?.inventoryItem?.inventoryItem?.tags?.map((t) => (
                      <View key={t?.id}>
                        <Text style={styles.itemTags}>
                          {t?.name}={t?.value}
                        </Text>
                      </View>
                    ))}
                  </View>
                </View>
              );
            }}
          />
          <DataTableCell
            weighting={0.25}
            style={[styles.tableCell, styles.sku]}
            getContent={(item: LineItem) => item?.inventoryItem?.inventoryItem?.sku}
          />
          <DataTableCell
            weighting={0.25}
            style={[styles.tableCell, styles.quantity]}
            getContent={(item: LineItem) => `${item?.quantity} ${item?.inventoryItem?.inventoryItem?.units}`}
          />
        </TableBody>
      </Table>
    </Page>
  </Document>
);
