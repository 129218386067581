import Checkbox from "components/Form/Checkbox";
import { ScrollableForm } from "components/Form/ScrollableForm";
import TextInput from "components/Form/TextInput";
import Filter from "components/Table/Filter";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { HR, SubHeading } from "styles";
import { notEmpty } from "utils/filters";
import { useTable } from "./context";

interface IFiltersFormData {
  name: string;
  showDeleted: boolean;
}

const FiltersHeader = () => {
  const { setShowFilters, filters, resetFilters, removeFilter } = useTable();

  return (
    <div>
      <div className="flex items-center">
        <div className="font-semibold">Filters</div>
        <button onClick={() => setShowFilters(true)} className="text-blue-500 text-xs ml-4 focus:outline-none">
          Edit
        </button>

        <button className="ml-4 text-xs text-brand" onClick={() => resetFilters()}>
          Reset
        </button>
      </div>
      <HR />
      <div className="flex flex-wrap">
        <Filter
          isVisible={notEmpty(filters?.name) && filters?.name !== ""}
          title={`Name contains: ${filters?.name}`}
          onRemove={() => removeFilter("name")}
          className="bg-blue-500"
        />

        <Filter
          isVisible={filters?.showDeleted === true}
          title={`Show Deleted`}
          onRemove={() => removeFilter("showDeleted")}
          className="bg-red-500"
        />
      </div>
    </div>
  );
};

const Filters: React.FC = () => {
  const { setShowFilters, setFilters, filters } = useTable();

  const { control, handleSubmit, register } = useForm<IFiltersFormData>({ defaultValues: filters });

  const onSubmit = (formData: IFiltersFormData) => {
    setFilters({
      name: formData.name,
      showDeleted: formData.showDeleted,
    });

    setShowFilters(false);
  };

  return (
    <ScrollableForm
      title="Filters"
      onSubmit={handleSubmit(onSubmit)}
      onCancel={() => setShowFilters(false)}
      submitLabel="Apply Filters"
    >
      <SubHeading>Name</SubHeading>

      <TextInput defaultValue={filters.name} placeholder="ie. Dearborn Denim" {...register("name")} />

      <Controller
        name="showDeleted"
        control={control}
        defaultValue={filters.showDeleted}
        render={({ field }) => {
          return (
            <Checkbox
              onChange={(e) => {
                field.onChange(e.checked);
              }}
              checked={field.value}
              label="Show Deleted Vendors"
            />
          );
        }}
      />
    </ScrollableForm>
  );
};

export { Filters as default, FiltersHeader };
