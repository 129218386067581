import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import IconButtonMenu from "components/IconButtonMenu";
import Pagination from "components/Pagination";
import { SelectPagination } from "components/Select/Pagination";
import StyledTable from "components/Table";
import { EmptyRowsRenderer, LoadingRenderer, RowRenderer } from "components/Table/Renderers";
import { Workflow } from "generated/graphql";
import React, { useEffect } from "react";
import { Item, ItemParams, Menu } from "react-contexify";
import { Column, DataGridProps, FormatterProps, HeaderRendererProps } from "react-data-grid";
import { Link } from "react-router-dom";
import { getRouteWithParams, Routes } from "routes";
import { isEmpty, notEmpty } from "utils/filters";
import { shortDateTime, shortId } from "utils/formatters";

import { RowActions, useRowActions, useWorkflowsTable } from "./context";

const MENU_ID = "workflow-row-menu";

const RowMenu: React.FC<{ row: Workflow }> = ({ row }) => {
  const handleAction = useRowActions();
  return (
    <IconButtonMenu
      options={[
        {
          label: "Edit",
          onClick: () => handleAction("editWorkflow", row),
        },

        {
          label: "Copy",
          onClick: () => handleAction("copyWorkflow", row),
        },

        isEmpty(row?.deletedAt) && {
          label: <div className="text-brand">Delete</div>,
          onClick: () => handleAction("deleteWorkflow", row),
        },
        notEmpty(row?.deletedAt) && {
          label: <div className="text-blue-500">Restore</div>,
          onClick: () => handleAction("restoreWorkflow", row),
        },
      ]}
    />
  );
};

const ContextMenu = () => {
  const { selected } = useWorkflowsTable();
  const handleAction = useRowActions();
  const handleItemClick: (args: ItemParams<Workflow, any>) => void = ({ event, props: item }) => {
    handleAction(event.currentTarget.id as RowActions, item);
  };

  return (
    <Menu id={MENU_ID}>
      <Item id="editWorkflow" onClick={handleItemClick}>
        Edit
      </Item>

      <Item id="copyWorkflow" onClick={handleItemClick}>
        Copy
      </Item>

      <Item id="deleteWorkflow" onClick={handleItemClick} hidden={(r) => notEmpty(r?.props?.deletedAt)}>
        <div className="text-brand">Delete</div>
      </Item>

      <Item id="restoreWorkflow" onClick={handleItemClick} hidden={(r) => !notEmpty(r?.props?.deletedAt)}>
        <div className="text-blue-500">Restore</div>
      </Item>

      {selected?.size > 0 && (
        <>
          <div
            className="mt-2 w-full cursor-default"
            style={{
              height: "1px",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            }}
          />

          <div className="text-xs text-gray-600 px-3 font-semibold bg-gray-100  py-2">Bulk Actions</div>

          <Item id="generateLabels" onClick={handleItemClick}>
            Generate Labels
          </Item>

          <Item id="deleteWorkflows" onClick={handleItemClick}>
            <div className="text-brand">Delete Workflows</div>
          </Item>
        </>
      )}
    </Menu>
  );
};

const columns: Column<Workflow, unknown>[] = [
  {
    headerRenderer: (props: HeaderRendererProps<Workflow>) => {
      return (
        <div
          className="flex justify-center items-center h-full w-full text-brand cursor-pointer text-lg py-2"
          onClick={(e) => props.onAllRowsSelectionChange(!props?.allRowsSelected)}
        >
          <FontAwesomeIcon icon={props?.allRowsSelected ? faCheckSquare : faSquare} />
        </div>
      );
    },
    formatter: (props: FormatterProps) => {
      return (
        <div
          className={cx("flex justify-center items-center h-full w-full text-brand cursor-pointer text-lg py-2")}
          onClick={(e) => props.onRowSelectionChange(!props?.isRowSelected, e.shiftKey)}
        >
          <FontAwesomeIcon icon={props?.isRowSelected ? faCheckSquare : faSquare} />
        </div>
      );
    },

    key: "select-row",
    name: "",
    resizable: false,
    sortable: false,
    maxWidth: 35,
    width: 35,
  },
  {
    key: "id",
    name: "ID",
    width: 100,
    formatter: (props) => {
      return (
        <Link
          title={props?.row?.id}
          to={getRouteWithParams(Routes.Workflow, {
            id: props?.row?.id,
          })}
          className={`${
            props?.row?.deletedAt ? "text-gray-400 dark:text-gray-200 line-through" : ""
          } hover:underline truncate`}
        >
          {shortId(props?.row?.id)}
        </Link>
      );
    },
  },

  {
    key: "name",
    name: "Name",
    minWidth: 200,
    formatter: (props) => {
      const worklfow = props?.row;
      const name = worklfow?.name ?? "No Name";

      return (
        <div className={`${props?.row?.deletedAt ? "text-gray-400 line-through" : ""} truncate`} title={name}>
          {name}
        </div>
      );
    },
  },

  {
    key: "version",
    name: "Version",
    minWidth: 200,
    formatter: (props) => {
      const workflow = props?.row;

      return <div>{workflow?.latestVersion?.version}</div>;
    },
  },

  {
    key: "updatedAt",
    name: "Updated",
    formatter: (props) => {
      return <>{shortDateTime(props?.row?.createdAt)}</>;
    },
  },

  {
    key: "actions",
    name: "",
    width: 50,
    cellClass: "text-center flex justify-center items-center",
    headerCellClass: "text-center flex justify-center items-center",
    formatter: (props) => <RowMenu row={props.row} />,
  },
];

const Table = () => {
  const { setPagination, pagination, fetch, data, loading, setselected, selected, variables } = useWorkflowsTable();

  useEffect(() => {
    fetch({
      variables,
    });
  }, [fetch, variables]);

  return (
    <>
      <div className="flex justify-end items-center">
        <SelectPagination value={pagination?.perPage} onChange={(perPage) => setPagination({ perPage })} />
      </div>
      <div style={{ flex: "1 1 auto" }} className="overflow-auto">
        <StyledTable<React.FC<DataGridProps<Workflow>>>
          rowHeight={56}
          rowKeyGetter={(r: Workflow) => r?.id}
          rowRenderer={(props) => <RowRenderer contextMenuId={MENU_ID} {...props} />}
          columns={columns}
          rows={data?.listWorkflows?.workflows ?? []}
          selectedRows={selected}
          onSelectedRowsChange={setselected}
          emptyRowsRenderer={() =>
            loading ? (
              <LoadingRenderer message="Fetching workflows" />
            ) : (
              <EmptyRowsRenderer message="No workflows found" />
            )
          }
        />
      </div>
      <Pagination
        loading={loading}
        currentPage={pagination?.page ?? 1}
        totalPages={data?.listWorkflows?.pageInfo?.totalPages}
        canNextPage={pagination?.page < data?.listWorkflows?.pageInfo?.totalPages}
        canPreviousPage={data?.listWorkflows?.pageInfo?.page > 1 ?? false}
        setPagination={setPagination}
      />
      <ContextMenu />
    </>
  );
};

export { Table as default };
