import TextInput from "components/Form/TextInput";
import { ButtonLoading } from "components/Loading";
import { Link } from "react-router-dom";
import { useToast } from "hooks/toast";
import React from "react";
import { useForm } from "react-hook-form";
import { DarkRedButton, Form } from "styles";
import useAxios from "axios-hooks";
import { PreAuthLayout } from "components/Layouts/PreAuth";
import { Routes } from "routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { useAnalytics } from "hooks/analytics";

export const MagicLogin: React.FC = () => {
  const toast = useToast();
  const { track } = useAnalytics();
  const { formState, register, handleSubmit } = useForm<{ email: string }>();

  const { isSubmitting, isSubmitSuccessful } = formState;

  const [{ loading }, send] = useAxios({ method: "POST", url: "/api/auth/magiclogin" }, { manual: true });

  const onSubmit = async (data: { email: string }) => {
    track("Login Button Clicked", { type: "Magic Link" });
    try {
      await send({
        data: { destination: data?.email },
      });
    } catch (err) {
      toast.error("Unable to send magic link");
    }
  };

  if (isSubmitSuccessful) {
    return (
      <PreAuthLayout>
        <div className="my-3 flex flex-col justify-center items-center">
          <FontAwesomeIcon icon={faPaperPlane} size={"4x"} />
          <div className="text-2xl font-semibold my-4">Your magic link is on the way!</div>
          <div className="my-4 text-lg">Click on the link in the email we sent to login.</div>
        </div>

        <Link to={Routes.Login} className="text-center py-2 font-semibold mt-4">
          Go Back
        </Link>
      </PreAuthLayout>
    );
  }

  return (
    <PreAuthLayout>
      <div className="my-2 text-center text-lg w-1/2">
        Enter the email address you normally use to login and we'll send you a magic link.
      </div>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col p-4 w-full sm:w-4/6 md:w-3/6 lg:w-4/6 xl:w-3/6 relative z-10"
      >
        <TextInput
          {...register("email", {
            required: true,
          })}
          placeholder="Email"
          autoComplete="username"
          type="email"
          className="mb-2 input"
        />

        <DarkRedButton disabled={isSubmitting}>{loading ? <ButtonLoading /> : "Send"}</DarkRedButton>
        <Link to={Routes.Login} className="text-center py-2 font-semibold mt-4">
          Go Back
        </Link>
      </Form>
    </PreAuthLayout>
  );
};
