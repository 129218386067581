import React, { useMemo, forwardRef } from "react";
import TextInput from "components/Form/TextInput";
import ReactPhoneInput from "react-phone-number-input/input";

export const PhoneInput: React.FC<{
  label?: string;
  name?: string;

  className?: string;
  placeholder?: string;

  value: string;
  onChange: (value: string) => void;
}> = ({
  label,
  name,
  className = "",
  placeholder,

  value,
  onChange,
}) => {
  const val = useMemo(() => (value && value.charAt(0) !== "+" ? `+1${value}` : value), [value]);
  return (
    <div className={className}>
      <label className="text-xs" htmlFor={name}>
        {label}
      </label>
      <ReactPhoneInput
        name={name}
        className="my-2"
        defaultCountry="US"
        useNationalFormatForDefaultCountryValue
        value={val}
        onChange={onChange}
        inputComponent={TextInput}
        placeholder={placeholder}
      />
    </div>
  );
};

const PhoneNumberDisplay = forwardRef<HTMLInputElement, { value: string }>(({ value }, ref) => (
  <div ref={ref}>{value}</div>
));

export const PhoneNumber: React.FC<{
  className?: string;
  placeholder?: string;
  countryCode?: string;
  value: string;
}> = ({ className, placeholder, value }) => {
  const val = useMemo(() => (value && value.charAt(0) !== "+" ? `+1${value}` : value), [value]);
  return (
    <ReactPhoneInput
      defaultCountry="US"
      value={val}
      onChange={() => {}}
      inputComponent={PhoneNumberDisplay}
      placeholder={placeholder}
    />
  );
};
