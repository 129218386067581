import { ScrollableForm } from "components/Form/ScrollableForm";
import {
  AttachmentResourceType,
  ListLocationInventoryDocument,
  useCreateInventoryItemMutation,
} from "generated/graphql";
import { useToast } from "hooks/toast";
import { useAttachment } from "hooks/upload";
import React from "react";
import { Controller, DeepMap, FieldError, useForm } from "react-hook-form";
import { notEmpty, removeAtIndex } from "utils/filters";
import { useTable } from "./context";
import { InventoryItemForm, IInventoryItemFormData } from "./InventoryItemForm";
import { SelectLocation } from "components/Select/Location";
import { SubHeading } from "styles";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { IconWithTooltip } from "components/Tooltip";
import { gsEvent } from "components/Events/events";

const AddProduct: React.FC = () => {
  const toast = useToast();
  const { setIsAddingProduct, variables } = useTable();
  const [createProduct] = useCreateInventoryItemMutation({
    refetchQueries: [{ query: ListLocationInventoryDocument, variables }],
    awaitRefetchQueries: true,
  });
  const form = useForm<IInventoryItemFormData>();
  const tags = form.watch("tags");
  const addAttachment = useAttachment();
  const submit = async (form: IInventoryItemFormData) => {
    try {
      const resp = await createProduct({
        variables: {
          input: {
            name: form.name,
            units: form?.units?.toLowerCase(),
            sku: form?.sku ?? null,
            isMade: form.isMade,
            isPurchased: form.isPurchased,
            isUsedToMakeSomethingElse: form.isUsedToMakeSomethingElse,
            isSold: form.isSold,
            onHand: form?.onHand ?? 0,
            defaultWorkflowId: form?.defaultWorkflow?.id ?? null,
            locationId: form?.location?.id ?? null,
            tags:
              form?.tags?.map((field) => ({
                tagId: field.tag.id,
                value: field.value,
              })) ?? null,
            valuePerUnit: form?.valuePerUnit ?? 0,
            defaultVendorProductId: form?.defaultVendorProduct?.product?.id ?? null,
            ingredientIds: form?.ingredients?.map((fields) => fields?.inventoryItem?.id)?.filter(notEmpty) ?? [],
          },
        },
      });

      const attachments = form?.attachments?.filter((att) => att?.value) ?? [];
      for (const attachment of attachments) {
        await addAttachment(attachment.value.file.item(0), {
          fileName: attachment.value.name,
          resourceId: resp?.data?.createInventoryItem?.id,
          resourceType: AttachmentResourceType.InventoryItem,
          onProgress: (e) => console.log(e),
        });
      }
      gsEvent("Added Product");
      toast.success(`"${form.name}"  created`);

      setIsAddingProduct(false);
    } catch (err) {
      toast.error("Unable to add inventory");
      setIsAddingProduct(false);
    }
  };

  const onError = (errors: DeepMap<IInventoryItemFormData, FieldError>) => {
    toast.error("Please make sure you've filled out all the required fields");
  };

  return (
    <ScrollableForm
      title="Add Item"
      onSubmit={form.handleSubmit(submit, onError)}
      onCancel={() => setIsAddingProduct(false)}
      submitLabel="Save"
      formState={form.formState}
      data-testid="add-inventory-item-form"
    >
      <InventoryItemForm
        form={form}
        onRemoveTag={(v, i) => {
          form.setValue("tags", removeAtIndex(tags, i));
        }}
      />
      <SubHeading>
        Location
        <span className="dark:text-white mx-2">
          <IconWithTooltip title="Defaults to primary location" icon={faInfoCircle} />
        </span>
      </SubHeading>
      <Controller
        control={form.control}
        name="location"
        render={({ field }) => {
          return <SelectLocation value={field.value} onChange={field.onChange} />;
        }}
      />
    </ScrollableForm>
  );
};

export { AddProduct as default };
