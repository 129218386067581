import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import BulkAddTags from "components/BulkAddTags";
import { ButtonWithIcon } from "components/Buttons";
import { CenterModal } from "components/Modals/CenterModal";
import { SideModal } from "components/Modals/SideModal";
import QRCode from "components/QRCode";
import { SelectWorkflow } from "components/Select/Workflow";
import TitleWithLoading from "components/TitleWithLoading";
import {
  TagResourceType,
  UpdateManufacturingOrderInput,
  useBulkUpdateWorkOrdersMutation,
  Workflow,
} from "generated/graphql";
import { useToast } from "hooks/toast";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHotkeys } from "react-hotkeys-hook";
import { PlainButton, RedButton } from "styles";
import { useTable, WorkOrdersProvider } from "./context";
import CreateWorkOrder from "./CreateWorkOrder";
import ExportCSV from "./ExportCsv";
import Filters, { FiltersHeader } from "./Filters";
import SetGroupColor from "./SetGroupColor";
import Table from "./Table";

const BulkChangeWorkflow = () => {
  const { selectedRows, setBulkChangeWorkflow } = useTable();
  const toast = useToast();

  const [bulkUpdate] = useBulkUpdateWorkOrdersMutation();
  const { handleSubmit, control, formState } = useForm<{ workflow: Pick<Workflow, "id" | "name"> }>();

  const onSubmit = async (formData: { workflow: Pick<Workflow, "id" | "name"> }) => {
    const input = selectedRows?.map<UpdateManufacturingOrderInput>((row) => ({
      id: row.id,
      workflowId: formData.workflow.id,
      version: row.version,
    }));

    try {
      await bulkUpdate({ variables: { input } });
      toast.success(`Successfully updated ${selectedRows?.length} work orders`);
    } catch (err) {
      toast.error(`Unable to update work orders`);
    } finally {
      setBulkChangeWorkflow(false);
    }
  };

  return (
    <div>
      <div>Change Workflow for {selectedRows?.length} orders</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="workflow"
          render={({ field }) => <SelectWorkflow value={field.value} onChange={field.onChange} />}
        />
        <PlainButton type="button" onClick={() => setBulkChangeWorkflow(false)}>
          Cancel
        </PlainButton>
        <RedButton disabled={formState.isSubmitting}>Save</RedButton>
      </form>
    </div>
  );
};

const WorkOrders: React.FC = () => {
  const {
    setBulkChangeWorkflow,
    isBulkChangingWorkflow,
    addTagsToOrders,
    addGroupColorToOrders,
    setaddGroupColorToOrders,
    setexportAsCSV,
    exportAsCSV,
    setaddTagsToOrders,
    listManufacturingOrders,
    loading,
    variables,
    setShowFilters,
    showFilters,
    selectedRows,
    viewQR,
    setViewQR,
  } = useTable();
  useHotkeys("ctrl+f", () => setShowFilters(!showFilters), [setShowFilters, showFilters]);
  const [createOrder, setcreateOrder] = useState(false);

  return (
    <>
      <div className="py-2 px-2 flex-1 flex flex-col overflow-hidden">
        <div className="flex justify-between my-2">
          <TitleWithLoading
            title="Work Orders"
            loading={loading}
            refresh={() => listManufacturingOrders({ variables })}
          />

          <ButtonWithIcon className="bg-brand" icon={faPlusCircle} onClick={() => setcreateOrder(true)}>
            Create Work Order
          </ButtonWithIcon>
        </div>

        <FiltersHeader />

        <Table />
      </div>

      <SideModal side="RIGHT" isOpen={showFilters} onRequestClose={() => setShowFilters(false)} className="w-1/4">
        <Filters />
      </SideModal>

      <SideModal side="RIGHT" isOpen={createOrder} onRequestClose={() => setcreateOrder(false)}>
        <CreateWorkOrder queryVariables={variables} onRequestClose={() => setcreateOrder(false)} />
      </SideModal>

      <CenterModal isOpen={addTagsToOrders} onRequestClose={() => setaddTagsToOrders(false)}>
        <BulkAddTags
          resourceIds={selectedRows?.map((r) => r.id)}
          type={TagResourceType.ManufacturingOrder}
          onRequestClose={() => setaddTagsToOrders(false)}
        />
      </CenterModal>

      <CenterModal
        isOpen={addGroupColorToOrders}
        onRequestClose={() => setaddGroupColorToOrders(false)}
        className="bg-white dark:bg-gray-800 p-4 rounded w-1/3 xl:w-1/4"
      >
        <SetGroupColor />
      </CenterModal>

      <CenterModal
        isOpen={exportAsCSV}
        onRequestClose={() => setexportAsCSV(false)}
        className="bg-white dark:bg-gray-800 p-4 rounded"
      >
        <ExportCSV onSuccess={() => setexportAsCSV(false)} />
      </CenterModal>

      <CenterModal isOpen={isBulkChangingWorkflow} onRequestClose={() => setBulkChangeWorkflow(false)}>
        <BulkChangeWorkflow />
      </CenterModal>

      <CenterModal
        isOpen={viewQR !== null}
        onRequestClose={() => setViewQR(null)}
        className="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4"
        padding={false}
      >
        {/* TODO: ONCE MOBILE APP 1.3.0 goes out use this */}
        {/* <QRCode value={`factoryfinch://workOrders/${viewQR?.id}`} /> */}
        <QRCode value={viewQR?.id} />
      </CenterModal>
    </>
  );
};

const Container = () => {
  return (
    <WorkOrdersProvider>
      <WorkOrders />
    </WorkOrdersProvider>
  );
};

export { Container as default };
