import { shortId } from "utils/formatters";
import { generateQRCodeImages } from "utils/qrcode";
import { Labels, ILabelData } from "../components/pdf/templates/labels.pdf";
import { pdf } from "@react-pdf/renderer";

export const generatePdfLabels = async <T extends { id: string }>(
  title: string,
  rows: T[],
  transform?: (row: T) => ILabelData
) => {
  const data = rows.map<ILabelData>((r) => {
    if (transform) {
      return transform(r);
    }

    return { title, qrcode: r?.id, line1: `ID: ${shortId(r?.id)}`, line2: "", line3: "" };
  });

  try {
    const qrcodes = await generateQRCodeImages(data.map((item) => item.qrcode));
    const inputs = data.map((d, idx) => ({ ...d, qrcode: qrcodes[idx] }));
    const url = await pdf(Labels({ labels: inputs }))
      .toBlob()
      .then(URL.createObjectURL);

    window.open(url, "_blank");
  } catch (err) {
    console.log(err);
  }
};
