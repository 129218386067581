import { useApolloClient } from "@apollo/client";
import { LocalStorageKeys } from "config";
import {
  MeDocument,
  MeQuery,
  MeQueryVariables,
  useLoginMutation,
  useVerifyLoginKeyMutation,
  useSignupMutation,
  SignupMutationVariables,
  LoginResponse,
} from "generated/graphql";
import React, { useCallback, useContext, useEffect } from "react";
import { useLocalStorage } from "react-use";
import { useIntercom } from "react-use-intercom";
import { useAnalytics } from "./analytics";
import { useQueryString } from "./queryString";
import { useToast } from "./toast";

interface StateIFace {
  isCheckingAuth: boolean;
  isAuthenticated: boolean;
  isVerifyingToken: boolean;
  isLoggingOut: boolean;
}

type CheckAuth = {
  type: Actions.CheckAuth;
};

type VerifyingToken = {
  type: Actions.VerifyingToken;
};

type AuthenticationSuccess = {
  type: Actions.AuthenticationSuccess;
};

type AuthenticationFailure = {
  type: Actions.AuthenticationFailure;
};

type Logout = {
  type: Actions.Logout;
};

type SetConfirmLogout = {
  type: Actions.SetConfirmLogout;
  data: boolean;
};

type DispatcherAction =
  | CheckAuth
  | VerifyingToken
  | AuthenticationSuccess
  | AuthenticationFailure
  | Logout
  | SetConfirmLogout;

enum Actions {
  CheckAuth = "CHECK_AUTH",
  VerifyingToken = "VERIFYING_TOKEN",
  AuthenticationSuccess = "AUTHENTICATION_SUCCESS",
  AuthenticationFailure = "AUTHENTICATION_FAILURE",
  Logout = "LOGOUT",
  SetConfirmLogout = "SET_CONFIRM_LOGOUT",
}
const initialState = {
  isCheckingAuth: true,
  isAuthenticated: false,
  isVerifyingToken: false,
  isLoggingOut: false,
};

const authCtx = React.createContext<{
  onLogin: (data: LoginResponse) => Promise<void>;
  onLogout: () => Promise<void>;
  state: StateIFace;
  dispatch: React.Dispatch<DispatcherAction>;
}>({
  onLogin: null,
  onLogout: null,
  state: initialState,
  dispatch: () => null,
});

export const AuthProvider: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
  const toast = useToast();
  const qs = useQueryString();
  const authToken = qs.get("authToken");
  const { boot: bootIntercom, shutdown: shutdownIntercom, update: updateIntercom } = useIntercom();
  const { reset: resetAnalytics, identify } = useAnalytics();
  const [verifyLoginKey] = useVerifyLoginKeyMutation();
  const client = useApolloClient();
  const [, setToken, removeToken] = useLocalStorage(LocalStorageKeys.Token, "", {
    raw: true,
  });

  const [state, dispatch] = React.useReducer(
    (state: StateIFace, action: DispatcherAction): StateIFace => {
      switch (action.type) {
        case Actions.CheckAuth:
          return { ...state, isCheckingAuth: true, isAuthenticated: false };
        case Actions.AuthenticationSuccess:
          return {
            ...state,
            isCheckingAuth: false,
            isAuthenticated: true,
            isVerifyingToken: false,
          };
        case Actions.AuthenticationFailure:
          return {
            ...state,
            isCheckingAuth: false,
            isAuthenticated: false,
            isVerifyingToken: false,
          };
        case Actions.Logout:
          return { ...state, isCheckingAuth: false, isAuthenticated: false };
        case Actions.VerifyingToken:
          return { ...state, isVerifyingToken: true, isAuthenticated: false };
        case Actions.SetConfirmLogout:
          return { ...state, isLoggingOut: action.data };
        default:
          return initialState;
      }
    },
    { ...initialState }
  );

  const clearStores = useCallback(() => {
    removeToken();
    window.localStorage.clear();
    window.sessionStorage.clear();
    return client.clearStore();
  }, [client, removeToken]);

  const onLogin = useCallback(
    async (data: LoginResponse) => {
      const user = data.user;
      const org = data.organization;

      await identify(user?.id, {
        email: user?.email,
        organizationName: org?.name,
      });

      updateIntercom({
        email: user?.email,
        userId: user?.id,
        name: user?.name,
        company: {
          companyId: org?.id,
          name: org?.name,
        },
      });
      setToken(data?.token);
      await client.resetStore();
      dispatch({ type: Actions.AuthenticationSuccess });
    },
    [dispatch, client, updateIntercom, setToken, identify]
  );

  const onLogout = useCallback(async () => {
    shutdownIntercom();
    await resetAnalytics();
    await clearStores();
    bootIntercom();
    dispatch({ type: Actions.Logout });
  }, [dispatch, shutdownIntercom, bootIntercom, clearStores, resetAnalytics]);

  const checkAuth = useCallback(async () => {
    dispatch({ type: Actions.CheckAuth });
    try {
      const resp = await client.query<MeQuery, MeQueryVariables>({
        query: MeDocument,
      });
      const user = resp?.data?.me;
      if (!user) {
        dispatch({ type: Actions.AuthenticationFailure });
        return;
      }

      await identify(user?.id, {
        email: user?.email,
        organizationName: user?.organization?.name,
      });
      dispatch({ type: Actions.AuthenticationSuccess });
    } catch (err) {
      dispatch({ type: Actions.AuthenticationFailure });
    }
  }, [dispatch, client, identify]);

  const verifyToken = useCallback(async () => {
    dispatch({ type: Actions.VerifyingToken });
    try {
      const resp = await verifyLoginKey({ variables: { key: authToken } });
      onLogin(resp?.data?.verifyLoginKey);
      dispatch({ type: Actions.AuthenticationSuccess });
    } catch (err) {
      toast.error("Unable to verify token");
      dispatch({ type: Actions.AuthenticationFailure });
    }
  }, [toast, verifyLoginKey, authToken, onLogin]);

  useEffect(() => {
    if (authToken) {
      verifyToken();
    } else {
      checkAuth();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <authCtx.Provider value={{ state, dispatch, onLogin, onLogout }}>{children}</authCtx.Provider>;
};

export const useAuth = () => {
  const { state, dispatch, onLogin, onLogout } = useContext(authCtx);
  const [loginAttempt] = useLoginMutation();
  const [signup, signupMutation] = useSignupMutation();

  const logout = useCallback(() => {
    return onLogout();
  }, [onLogout]);

  const login = useCallback(
    async (data: { email: string; password: string }) => {
      try {
        const resp = await loginAttempt({ variables: data });

        await onLogin(resp?.data?.login);
      } catch (err) {
        dispatch({ type: Actions.AuthenticationFailure });
        throw err;
      }
    },
    [dispatch, loginAttempt, onLogin]
  );

  const createAccount = useCallback(
    async (variables: SignupMutationVariables) => {
      try {
        const resp = await signup({ variables });
        await onLogin(resp?.data?.signup);
      } catch (err) {
        dispatch({ type: Actions.AuthenticationFailure });
        throw err;
      }
    },
    [dispatch, onLogin, signup]
  );

  const setLoggingOut = (logout: boolean) => {
    dispatch({ type: Actions.SetConfirmLogout, data: logout });
  };

  const isLoading = state.isCheckingAuth || signupMutation?.loading;

  return {
    ...state,
    isLoggedIn: state.isAuthenticated,
    loading: isLoading,
    logout,
    login,
    setLoggingOut,
    createAccount,
  };
};
