import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faCheckSquare, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import { PhoneNumber } from "components/Form/PhoneInput";
import IconButtonMenu from "components/IconButtonMenu";
import Pagination from "components/Pagination";
import { SelectPagination } from "components/Select/Pagination";
import StyledTable from "components/Table";
import { EmptyRowsRenderer, LoadingRenderer, RowRenderer } from "components/Table/Renderers";
import { Employee } from "generated/graphql";
import React, { useEffect, useState } from "react";
import { Column, DataGridProps, FormatterProps, HeaderRendererProps } from "react-data-grid";
import { shortId } from "utils/formatters";
import { useEmployeesTable, useRowActions } from "./context";
import { ContextMenu, MENU_ID } from "./ContextMenu";

const CodeCell: React.FC<{ code?: string }> = ({ code }) => {
  const [showCode, setshowCode] = useState(false);
  return (
    <>
      {showCode ? code : "· · ·"}
      <span className="ml-2 text-gray-600 dark:text-gray-300 cursor-pointer" onClick={() => setshowCode(!showCode)}>
        <FontAwesomeIcon icon={faEye} />
      </span>
    </>
  );
};

const RowMenu: React.FC<{ row: Employee }> = ({ row }) => {
  const handleAction = useRowActions();
  return (
    <IconButtonMenu
      options={[
        {
          label: "Get AppLink",
          onClick: () => handleAction("getEmployeeAppLink", row),
        },
        {
          label: "Send Link",
          onClick: () => handleAction("sendAppLink", row),
        },
        {
          label: "Edit",
          onClick: () => handleAction("editEmployee", row),
        },
        {
          label: <div className="text-brand">Delete</div>,
          onClick: () => handleAction("deleteEmployee", row),
        },
      ]}
    />
  );
};

const columns: Column<Employee, unknown>[] = [
  {
    headerRenderer: (props: HeaderRendererProps<Employee>) => {
      return (
        <div
          className="flex justify-center items-center h-full w-full text-brand cursor-pointer text-lg py-2"
          onClick={(e) => props.onAllRowsSelectionChange(!props?.allRowsSelected)}
        >
          <FontAwesomeIcon icon={props?.allRowsSelected ? faCheckSquare : faSquare} />
        </div>
      );
    },
    formatter: (props: FormatterProps) => {
      return (
        <div
          className={cx("flex justify-center items-center h-full w-full text-brand cursor-pointer text-lg py-2")}
          onClick={(e) => props.onRowSelectionChange(!props?.isRowSelected, e.shiftKey)}
        >
          <FontAwesomeIcon icon={props?.isRowSelected ? faCheckSquare : faSquare} />
        </div>
      );
    },

    key: "select-row",
    name: "",
    resizable: false,
    sortable: false,
    maxWidth: 35,
    width: 35,
  },
  {
    key: "id",
    name: "ID",
    width: 100,
    formatter: (props) => {
      return <div>{shortId(props?.row?.id)}</div>;
    },
  },

  {
    key: "name",
    name: "Name",
    minWidth: 200,
  },
  {
    key: "email",
    name: "Email",
    minWidth: 200,
  },
  {
    key: "phonenumber",
    name: "Phone",
    cellClass: "flex",
    formatter: (props) => {
      const employee = props?.row;
      if (!employee?.phoneNumber) {
        return <>--</>;
      }

      return <PhoneNumber value={employee?.phoneNumber} />;
    },
  },
  {
    key: "code",
    name: "Code",
    formatter: (props) => {
      return <CodeCell code={props?.row?.code} />;
    },
  },

  {
    key: "actions",
    name: "",
    width: 50,
    cellClass: "text-center flex justify-center items-center",
    headerCellClass: "text-center flex justify-center items-center",
    formatter: (props) => <RowMenu row={props.row} />,
  },
];

const Table = () => {
  const { setPagination, pagination, fetch, data, loading, setselected, selected, variables } = useEmployeesTable();

  useEffect(() => {
    fetch({
      variables,
    });
  }, [fetch, variables]);

  return (
    <>
      <div className="flex justify-end items-center">
        <SelectPagination value={pagination?.perPage} onChange={(perPage) => setPagination({ perPage })} />
      </div>
      <div style={{ flex: "1 1 auto" }} className="overflow-auto">
        <StyledTable<React.FC<DataGridProps<Employee>>>
          rowHeight={56}
          rowKeyGetter={(r: Employee) => r?.id}
          rowRenderer={(props) => <RowRenderer contextMenuId={MENU_ID} {...props} />}
          columns={columns}
          rows={data?.listEmployees?.employees ?? []}
          selectedRows={selected}
          onSelectedRowsChange={setselected}
          emptyRowsRenderer={() =>
            loading ? (
              <LoadingRenderer message="Fetching employees" />
            ) : (
              <EmptyRowsRenderer message="No employees found" />
            )
          }
        />
      </div>
      <Pagination
        loading={loading}
        currentPage={pagination?.page ?? 1}
        totalPages={data?.listEmployees?.pageInfo?.totalPages}
        canNextPage={pagination?.page < data?.listEmployees?.pageInfo?.totalPages}
        canPreviousPage={data?.listEmployees?.pageInfo?.page > 1 ?? false}
        setPagination={setPagination}
      />
      <ContextMenu />
    </>
  );
};

export { Table as default };
