import { CountryOption, RegionOption, SelectCountry, SelectRegion } from "components/Form/CountryAndRegion";
import { PhoneInput } from "components/Form/PhoneInput";
import { ScrollableForm } from "components/Form/ScrollableForm";
import TextAreaInput from "components/Form/TextAreaInput";
import TextInput from "components/Form/TextInput";
import { useToast } from "hooks/toast";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { SubHeading } from "styles";

import { useTable } from "./context";

type CreateVendorForm = {
  name: string;
  address1: string;
  address2: string;
  city: string;
  region: RegionOption;
  country: CountryOption;
  postalCode: string;
  companyPhone: string;
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  contactPhoneOffice: string;
  contactPhoneMobile: string;
  notes: string;
};

const CreateVendor = () => {
  const toast = useToast();
  const { setIsCreating, createVendor } = useTable();
  const { handleSubmit, register, formState, control, setValue, watch } = useForm<CreateVendorForm>();
  const onRequestClose = () => setIsCreating(false);
  const country = watch("country");
  const onSubmit = async (input: CreateVendorForm) => {
    try {
      await createVendor({
        variables: {
          input: {
            name: input.name,
            companyPhone: input.companyPhone,
            address1: input.address1,
            address2: input.address2,
            city: input.city,
            state: input?.region?.name,
            country: input?.country?.countryName,
            postalCode: input.postalCode,
            contactFirstName: input.contactFirstName,
            contactLastName: input.contactLastName,
            contactEmail: input.contactEmail,
            contactPhoneOffice: input.contactPhoneOffice,
            contactPhoneMobile: input.contactPhoneMobile,
            notes: input.notes,
          },
        },
      });
      onRequestClose();
    } catch (err) {
      toast.error("Unable to create Vendor");
    }
  };

  return (
    <ScrollableForm
      title="Add Vendor"
      onSubmit={handleSubmit(onSubmit)}
      onCancel={onRequestClose}
      formState={formState}
    >
      <SubHeading>Company</SubHeading>
      <TextInput {...register("name")} placeholder="Factory Finch" label="Company Name*" />

      <Controller
        control={control}
        name="companyPhone"
        render={({ field }) => <PhoneInput label="Company Phone" value={field.value} onChange={field.onChange} />}
      />

      <TextInput {...register("address1")} placeholder="123 Fake St." label="Address" />

      <TextInput {...register("address2")} placeholder="Unit A" label="Address 2" />

      <TextInput {...register("city")} placeholder="Austin" label="City" />

      <Controller
        control={control}
        name="country"
        render={({ field }) => {
          return (
            <SelectCountry
              label="Country"
              onChange={(v) => {
                setValue("region", null);
                field.onChange(v);
              }}
              value={field.value}
            />
          );
        }}
      />

      <Controller
        control={control}
        name="region"
        render={({ field }) => {
          return (
            <SelectRegion label="Region" country={country} onChange={(v) => field.onChange(v)} value={field.value} />
          );
        }}
      />

      <TextInput {...register("postalCode")} label="ZIP/Postal code" placeholder="78702" />

      <SubHeading className="pt-3">Company Contact</SubHeading>
      <div className="grid grid-cols-2 gap-x-2 gap-y-2 py-2">
        <TextInput {...register("contactFirstName")} label="First Name" placeholder="Alex" />

        <TextInput {...register("contactLastName")} label="Last Name" placeholder="Smith" />
      </div>

      <TextInput {...register("contactEmail")} placeholder="alex@factoryfinch.com" label="Email" />

      <Controller
        control={control}
        name="contactPhoneOffice"
        render={({ field }) => (
          <PhoneInput
            //countryCode={country?.countryShortCode}
            label="Office Phone"
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />

      <Controller
        control={control}
        name="contactPhoneMobile"
        render={({ field }) => (
          <PhoneInput
            //countryCode={country?.countryShortCode}
            label="Mobile Phone"
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />

      <TextAreaInput rows={6} label="Notes" placeholder="Anything else...." {...register("notes")} />
    </ScrollableForm>
  );
};

export { CreateVendor as default };
