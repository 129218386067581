import { ICanvasOuterDefaultProps } from "@mrblenny/react-flow-chart";
import styled from "styled-components";
import tw from "twin.macro";

const CanvasOuterCustom = styled.div<ICanvasOuterDefaultProps>`
  position: relative;
  background-size: 20px 20px;
  ${tw`bg-gray-400 dark:bg-gray-600`}
  background-image: linear-gradient(
      90deg,
      hsla(0, 0%, 100%, 0.2) 1px,
      transparent 0
    ),
    linear-gradient(180deg, hsla(0, 0%, 100%, 0.2) 1px, transparent 0);

  overflow: hidden;
  cursor: not-allowed;
`;

export { CanvasOuterCustom as default };
