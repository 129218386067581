import React, { useEffect } from "react";
import cx from "classnames";
import { IconButton, Tooltip } from "styles";
import { FormatterProps, HeaderRendererProps, Column, DataGridProps } from "react-data-grid";
import NumberFormat from "react-number-format";
import Pagination from "components/Pagination";
import { useTable, useRowActions, RowActions } from "./context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSlidersH } from "@fortawesome/free-solid-svg-icons";
import IconButtonMenu from "components/IconButtonMenu";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { Menu, Item, ItemParams } from "react-contexify";
import { shortId } from "utils/formatters";
import { Step } from "generated/graphql";
import { faShareSquare } from "@fortawesome/free-solid-svg-icons";
import { LoadingRenderer, EmptyRowsRenderer, RowRenderer } from "components/Table/Renderers";

import StyledTable from "components/Table";

import { isEmpty, notEmpty } from "utils/filters";
import { SelectPagination } from "components/Select/Pagination";

const MENU_ID = "step-row-menu";

const RowMenu: React.FC<{ row: Step }> = ({ row }) => {
  const handleAction = useRowActions();
  return (
    <IconButtonMenu
      options={[
        {
          label: "Print Label",
          onClick: () => handleAction("printLabel", row),
        },
        {
          label: "Edit",
          onClick: () => handleAction("editStep", row),
        },
        !row?.deletedAt && {
          label: <div className="text-brand">Delete</div>,
          onClick: () => handleAction("deleteStep", row),
        },
        row?.deletedAt && {
          label: <div className="text-blue-500">Restore</div>,
          onClick: () => handleAction("restoreStep", row),
        },
      ]}
    />
  );
};

const ContextMenu = () => {
  const { selected } = useTable();
  const handleAction = useRowActions();
  const handleItemClick: (args: ItemParams<Step, any>) => void = ({ event, props: item }) => {
    handleAction(event.currentTarget.id as RowActions, item);
  };

  return (
    <Menu id={MENU_ID}>
      <Item id="editStep" onClick={handleItemClick}>
        Edit
      </Item>

      <Item id="printLabel" onClick={handleItemClick}>
        Print Label
      </Item>

      <Item
        id="deleteStep"
        hidden={(row) => {
          return notEmpty(row?.props?.deletedAt);
        }}
        onClick={handleItemClick}
      >
        <div className="text-brand">Delete</div>
      </Item>

      <Item
        id="restoreStep"
        hidden={(row) => {
          return isEmpty(row?.props?.deletedAt);
        }}
        onClick={handleItemClick}
      >
        <div className="text-blue-500">Restore</div>
      </Item>

      {selected?.size > 0 && (
        <>
          <div
            className="mt-2 w-full cursor-default"
            style={{
              height: "1px",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            }}
          />

          <div className="text-xs text-gray-600 px-3 font-semibold bg-gray-100  py-2">Bulk Actions</div>

          <Item id="generateLabels" onClick={handleItemClick}>
            Generate Labels
          </Item>

          <Item id="deleteSteps" onClick={handleItemClick}>
            <div className="text-brand">Delete Steps</div>
          </Item>
        </>
      )}
    </Menu>
  );
};

const columns: Column<Step, unknown>[] = [
  {
    headerRenderer: (props: HeaderRendererProps<Step>) => {
      return (
        <div
          className="flex justify-center items-center h-full w-full text-brand cursor-pointer text-lg py-2"
          onClick={(e) => props.onAllRowsSelectionChange(!props?.allRowsSelected)}
        >
          <FontAwesomeIcon icon={props?.allRowsSelected ? faCheckSquare : faSquare} />
        </div>
      );
    },
    formatter: (props: FormatterProps) => {
      return (
        <div
          className={cx("flex justify-center items-center h-full w-full text-brand cursor-pointer text-lg py-2")}
          onClick={(e) => props.onRowSelectionChange(!props?.isRowSelected, e.shiftKey)}
        >
          <FontAwesomeIcon icon={props?.isRowSelected ? faCheckSquare : faSquare} />
        </div>
      );
    },

    key: "select-row",
    name: "",
    resizable: false,
    sortable: false,
    maxWidth: 35,
    width: 35,
  },
  {
    key: "id",
    name: "ID",
    width: 100,
    formatter: (props) => {
      return (
        <div
          className={`${props?.row?.deletedAt ? "text-gray-400 dark:text-gray-200 line-through" : ""} truncate`}
          title={props?.row?.id}
        >
          {shortId(props?.row?.id)}
        </div>
      );
    },
  },

  {
    key: "name",
    name: "Name",
    minWidth: 200,
    formatter: (props) => {
      const step = props?.row;
      const name = step?.name ?? "No Name";

      return (
        <div className={`${props?.row?.deletedAt ? "text-gray-400 line-through" : ""} truncate`} title={name}>
          {name}
        </div>
      );
    },
  },
  {
    key: "points",
    name: "Points",
    formatter: (props) => {
      return <NumberFormat thousandSeparator value={props?.row?.points} displayType="text" />;
    },
  },
  {
    key: "consumesMaterials",
    name: "Uses Inventory?",
    formatter: (props) => {
      const step = props?.row;
      if (step?.promptOperatorToConsumeInventory) {
        return (
          <Tooltip arrow placement="bottom" title="This step consumes inventory">
            <span>
              <FontAwesomeIcon size="lg" icon={faShareSquare} className="text-gray-400 dark:text-gray-300" />
            </span>
          </Tooltip>
        );
      }

      return null;
    },
  },
  {
    key: "properties",
    name: "Properties",
    formatter: (props) => {
      return <>{props?.row?.properties?.length ?? 0}</>;
    },
  },

  {
    key: "actions",
    name: "",
    width: 50,
    cellClass: "text-center flex justify-center items-center",
    headerCellClass: "text-center flex justify-center items-center",
    formatter: (props) => <RowMenu row={props.row} />,
  },
];

const TableHeader = () => {
  const { setPagination, pagination, setShowFilters } = useTable();

  return (
    <div className="flex justify-end items-center">
      <div className="flex">
        <div className="flex flex-row items-center px-2">
          <Tooltip arrow title="Filters">
            <IconButton className="" onClick={() => setShowFilters(true)}>
              <FontAwesomeIcon icon={faSlidersH} size="lg" />
            </IconButton>
          </Tooltip>
        </div>
        <SelectPagination value={pagination?.perPage} onChange={(perPage) => setPagination({ perPage })} />
      </div>
    </div>
  );
};

const Table = () => {
  const { setPagination, pagination, fetch, data, loading, setselected, selected, variables } = useTable();

  useEffect(() => {
    fetch({
      variables,
    });
  }, [fetch, variables]);

  return (
    <>
      <TableHeader />
      <div style={{ flex: "1 1 auto" }} className="overflow-auto">
        <StyledTable<React.FC<DataGridProps<Step>>>
          rowHeight={56}
          rowKeyGetter={(r: Step) => r?.id}
          rowRenderer={(props) => <RowRenderer contextMenuId={MENU_ID} {...props} />}
          columns={columns}
          rows={data?.listSteps?.steps ?? []}
          selectedRows={selected}
          onSelectedRowsChange={setselected}
          emptyRowsRenderer={() =>
            loading ? <LoadingRenderer message="Fetching steps" /> : <EmptyRowsRenderer message="No steps found" />
          }
        />
      </div>
      <Pagination
        loading={loading}
        currentPage={pagination?.page ?? 1}
        totalPages={data?.listSteps?.pageInfo?.totalPages}
        canNextPage={pagination?.page < data?.listSteps?.pageInfo?.totalPages}
        canPreviousPage={data?.listSteps?.pageInfo?.page > 1 ?? false}
        setPagination={setPagination}
      />
      <ContextMenu />
    </>
  );
};

export { Table as default };
