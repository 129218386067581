import React from "react";
import cx from "classnames";
import { InventoryItem, Workflow } from "generated/graphql";
import { TagInput } from "components/Form/TagInput";
import { useTable } from "./context";
import { SelectWorkOrderStatuses } from "components/Select/WorkOrderStatus";
import { SelectInventoryItem } from "components/Select/InventoryItem";
import { SelectWorkflow } from "components/Select/Workflow";

export const QuickFilters = () => {
  const { filters, showQuickFilters, setFilters } = useTable();

  return (
    <>
      <div className={cx(!showQuickFilters && "hidden", "grid grid-cols-1 lg:grid-cols-4 gap-x-2")}>
        <div>
          <label className="text-xs">Status</label>
          <SelectWorkOrderStatuses
            value={filters.statuses}
            onChange={(statuses) => setFilters({ ...filters, statuses })}
          />
        </div>
        <div>
          <label className="text-xs">ID</label>
          <TagInput tags={filters.ids} onChange={(ids) => setFilters({ ...filters, ids })} removeOnBackspace={true} />
        </div>
        <div>
          <label className="text-xs">Inventory Item</label>
          <SelectInventoryItem
            placeholder="Filter by Inventory Item..."
            value={filters.inventoryItems}
            onChange={(items: InventoryItem[]) => setFilters({ ...filters, inventoryItems: items })}
            isMulti
          />
        </div>
        <div>
          <label className="text-xs">Workflow</label>
          <SelectWorkflow
            onChange={(workflows: Workflow[]) => setFilters({ ...filters, workflows })}
            value={filters.workflows}
            isMulti
          />
        </div>
      </div>
    </>
  );
};
