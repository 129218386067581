import React from "react";
import { LoadingAnimationWithLabel } from "components/Loading";
import { useAuth } from "hooks/auth";
import { Redirect } from "react-router-dom";
import { Routes } from "routes";

const TokenLogin = () => {
  const { isVerifyingToken, isCheckingAuth, isLoggedIn } = useAuth();

  if (!isVerifyingToken && !isCheckingAuth && isLoggedIn) {
    return <Redirect to={Routes.Root} />;
  }

  return (
    <div className="h-screen flex flex-col items-center justify-center">
      <LoadingAnimationWithLabel message="Signing In" />
    </div>
  );
};

export { TokenLogin as default };
