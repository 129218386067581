import React, { useEffect } from "react";
import {
  IntegrationType,
  ReferenceType,
  useSyncMutation,
  useListenForJobEventsSubscription,
  useGetJobLazyQuery,
} from "generated/graphql";
import { useToast } from "hooks/toast";
import { H4, RedButton, PlainButton } from "styles";
import { Controller, useForm } from "react-hook-form";
import { SelectIntegration } from "components/Select/Integration";
import { ButtonLoading } from "components/Loading";
import { TagInput } from "components/Form/TagInput";
import { ErrorMessage } from "@hookform/error-message";

interface IImportOrdersForm {
  integration: IntegrationType;
  orderNumbers: string[];
}

const ListenForJobStatus: React.FC<{ jobId: string }> = ({ jobId }) => {
  useListenForJobEventsSubscription({
    shouldResubscribe: true,
    variables: { id: jobId },
  });

  return <></>;
};

export const ImportOrders: React.FC<{ onRequestClose: () => void }> = ({ onRequestClose }) => {
  const toast = useToast();

  const [fetchJob, { data, loading }] = useGetJobLazyQuery();
  const [sync] = useSyncMutation();
  const { handleSubmit, control, formState } = useForm<IImportOrdersForm>({
    defaultValues: {
      integration: IntegrationType.Shopify,
      orderNumbers: [],
    },
  });

  const submit = async (formData: IImportOrdersForm) => {
    try {
      const resp = await sync({
        variables: {
          input: {
            integrationType: formData.integration,
            referenceType: ReferenceType.Orders,
            references: formData?.orderNumbers,
          },
        },
      });

      fetchJob({ variables: { queueName: "shopify", id: resp?.data?.sync?.id } });
    } catch (err) {
      toast.error(`Unable to sync orders #${formData?.orderNumbers}`);
    }
  };

  useEffect(() => {
    if (data?.job?.status === "complete") {
      onRequestClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.job?.status]);

  return (
    <div>
      <H4 className="mb-4">Import orders from...</H4>
      <form onSubmit={handleSubmit(submit)}>
        <div className="flex justify-center py-1">
          <Controller
            control={control}
            name="integration"
            render={({ field }) => <SelectIntegration value={field.value} onChange={field.onChange} />}
          />
        </div>

        <Controller
          name="orderNumbers"
          control={control}
          rules={{
            validate: (orderNumbers) =>
              orderNumbers?.length > 0 || "Must enter at least one order number. Did you forget enter to hit enter?",
          }}
          render={({ field }) => (
            <TagInput
              label="Order Numbers"
              tags={field.value}
              onChange={field.onChange}
              removeOnBackspace={true}
              maxTags={10}
            />
          )}
        />

        <div className="text-xs text-brand font-semibold py-2">
          <ErrorMessage name="orderNumbers" errors={formState?.errors} />
        </div>
        <div className="flex flex-row justify-end">
          <div className={"px-2 py-4 text-sm grid grid-cols-2 gap-x-2"}>
            <PlainButton type="button" onClick={onRequestClose}>
              Cancel
            </PlainButton>
            <RedButton disabled={formState.isSubmitting} data-testid="import-order-submit-btn">
              {formState.isSubmitting || loading || data?.job?.status === "active" ? <ButtonLoading /> : "Sync"}
            </RedButton>
          </div>
        </div>
      </form>
      {data?.job?.id && <ListenForJobStatus jobId={data?.job?.id} />}
    </div>
  );
};
