import { useState, useCallback } from "react";
import { debounce } from "lodash";

export const useDelayedQuery = <T,>({
  refetch,
}: {
  refetch: (inputValue: string) => Promise<T[]>;
}) => {
  const [inputValue, setinputValue] = useState("");

  const handleInputChange = (newValue: string) => {
    setinputValue(newValue);
  };

  const fetchData = async (
    inputValue: string,
    callback: (data: T[]) => void
  ) => {
    const data = await refetch(inputValue);
    callback(data);
  };

  // eslint-disable-next-line
  const delayedQuery = useCallback(
    debounce((inputValue, callback) => fetchData(inputValue, callback), 500),
    [inputValue]
  );

  return {
    delayedQuery,
    handleInputChange,
  };
};
