import React, { useCallback } from "react";
import NumberInput from "components/Form/NumberInput";
import { ButtonLoading } from "components/Loading";
import {
  ListLocationInventoryDocument,
  UpdateLocationInventoryInput,
  useUpdateLocationInventoryMutation,
} from "generated/graphql";
import { Controller, useForm } from "react-hook-form";
import { NumberFormatValues } from "react-number-format";
import { PlainButton, RedButton, SubHeading } from "styles";

import { useTable } from "./context";

export const UpdateLocationInventory = () => {
  const { variables, isUpdatingLocationInventory, setUpdateLocationInventoryInput } = useTable();

  const location = isUpdatingLocationInventory?.location;
  const item = isUpdatingLocationInventory?.item;

  const [updateLocationInventory] = useUpdateLocationInventoryMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ListLocationInventoryDocument, variables }],
  });

  const { handleSubmit, control, formState } = useForm<UpdateLocationInventoryInput>({
    defaultValues: {
      onHand: item?.onHand ?? 0,
      reOrderAt: item?.reOrderAt ?? 0,
    },
  });
  const onRequestClose = useCallback(() => setUpdateLocationInventoryInput(null), [setUpdateLocationInventoryInput]);
  const submit = useCallback(
    async (input: UpdateLocationInventoryInput) => {
      try {
        await updateLocationInventory({
          variables: { input: { id: item?.id, ...input, requiresStockTake: false } },
        });
        onRequestClose();
      } catch (err) {
        console.log(err);
      }
    },
    [item?.id, onRequestClose, updateLocationInventory]
  );

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="mb-2">
        Update {item?.inventoryItem?.name} at {location?.name}
      </div>

      <SubHeading>On Hand</SubHeading>
      <Controller
        control={control}
        name="onHand"
        render={({ field }) => (
          <NumberInput
            placeholder="0"
            value={field.value}
            onValueChange={(value: NumberFormatValues) => field.onChange(value.floatValue)}
          />
        )}
      />

      <>
        <SubHeading>Re-Order At</SubHeading>
        <Controller
          control={control}
          name="reOrderAt"
          render={({ field }) => (
            <NumberInput
              placeholder="0"
              value={field.value}
              onValueChange={(value: NumberFormatValues) => field.onChange(value.floatValue)}
            />
          )}
        />
      </>

      <div className="px-2 py-4 text-sm grid grid-cols-2 gap-x-2">
        <PlainButton type="button" onClick={onRequestClose}>
          Cancel
        </PlainButton>
        <RedButton>{formState?.isSubmitting ? <ButtonLoading /> : "Update"}</RedButton>
      </div>
    </form>
  );
};
