import countries, { Country, Region } from "country-region-data";
import React from "react";
import { Select } from "styles";

export type CountryOption = Partial<Country>;
export type RegionOption = Partial<Region>;

export const getCountry = (name: string) => {
  return countries?.find((c) => c.countryName === name);
};

export const getRegion = (country: string, region: string) => {
  const c = getCountry(country);
  return c?.regions?.find((r) => r.name === region);
};

const SelectCountry: React.FC<{
  label?: string;
  onChange: (country: CountryOption) => void;
  value: CountryOption;
  hasError?: boolean;
}> = ({ value, onChange, label, hasError = false }) => {
  return (
    <div>
      {label && <label className="text-xs">{label}</label>}
      <Select
        isClearable
        $hasError={hasError}
        value={value}
        options={countries}
        onChange={onChange}
        getOptionLabel={(opt: CountryOption) => opt?.countryName}
        getOptionValue={(opt: CountryOption) => opt?.countryName}
        placeholder="Select Country"
      />
    </div>
  );
};

const SelectRegion: React.FC<{
  country: CountryOption;
  label?: string;
  onChange: (region: RegionOption) => void;
  value: RegionOption;
  hasError?: boolean;
}> = ({ value, onChange, country, label, hasError = false }) => {
  return (
    <div>
      {label && <label className="text-xs">{label}</label>}
      <Select
        isClearable
        $hasError={hasError}
        isDisabled={!country}
        value={value?.name ? value : null}
        options={country?.regions ?? []}
        onChange={onChange}
        getOptionLabel={(opt: RegionOption) => opt?.name}
        getOptionValue={(opt: RegionOption) => opt?.name}
        placeholder={"Select Region"}
      />
    </div>
  );
};

export { SelectCountry, SelectRegion };
