import React, { useEffect } from "react";
import cx from "classnames";
import { FormatterProps, HeaderRendererProps, Column, DataGridProps } from "react-data-grid";
import Pagination from "components/Pagination";
import { useTable, useRowActions, RowActions } from "./context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import IconButtonMenu from "components/IconButtonMenu";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { Menu, Item, ItemParams } from "react-contexify";
import { shortDateTime, shortId } from "utils/formatters";
import { PurchaseOrder } from "generated/graphql";
import { LoadingRenderer, EmptyRowsRenderer, RowRenderer } from "components/Table/Renderers";
import StyledTable from "components/Table";
import PurchaseOrderStatusBadge from "components/Badges/PurchaseOrderStatus";
import { SelectPagination } from "components/Select/Pagination";

const MENU_ID = "purchaseOrder-row-menu";

const RowMenu: React.FC<{ row: PurchaseOrder }> = ({ row }) => {
  const handleAction = useRowActions();
  return (
    <IconButtonMenu
      options={[
        {
          label: "Edit",
          onClick: () => handleAction("editPurchaseOrder", row),
        },
        {
          label: "Print Label",
          onClick: () => handleAction("printLabel", row),
        },
        {
          label: "Generate PDF",
          onClick: () => handleAction("generatePDF", row),
        },
      ]}
    />
  );
};

const ContextMenu = () => {
  const { selected } = useTable();
  const handleAction = useRowActions();
  const handleItemClick: (args: ItemParams<PurchaseOrder, any>) => void = ({ event, props: item }) => {
    handleAction(event.currentTarget.id as RowActions, item);
  };

  return (
    <Menu id={MENU_ID}>
      <Item id="editPurchaseOrder" onClick={handleItemClick}>
        Edit
      </Item>

      <Item id="printLabel" onClick={handleItemClick}>
        Print Label
      </Item>

      <Item id="generatePDF" onClick={handleItemClick}>
        Generate PDF
      </Item>

      <Item id="deletePurchaseOrder" onClick={handleItemClick}>
        <div className="text-brand">Cancel</div>
      </Item>

      {selected?.size > 0 && (
        <>
          <div
            className="mt-2 w-full cursor-default"
            style={{
              height: "1px",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            }}
          />

          <div className="text-xs text-gray-600 px-3 font-semibold bg-gray-100  py-2">Bulk Actions</div>

          <Item id="generateLabels" onClick={handleItemClick}>
            Generate Labels
          </Item>
        </>
      )}
    </Menu>
  );
};

const columns: Column<PurchaseOrder, unknown>[] = [
  {
    headerRenderer: (props: HeaderRendererProps<PurchaseOrder>) => {
      return (
        <div
          className="flex justify-center items-center h-full w-full text-brand cursor-pointer text-lg py-2"
          onClick={(e) => props.onAllRowsSelectionChange(!props?.allRowsSelected)}
        >
          <FontAwesomeIcon icon={props?.allRowsSelected ? faCheckSquare : faSquare} />
        </div>
      );
    },
    formatter: (props: FormatterProps) => {
      return (
        <div
          className={cx("flex justify-center items-center h-full w-full text-brand cursor-pointer text-lg py-2")}
          onClick={(e) => props.onRowSelectionChange(!props?.isRowSelected, e.shiftKey)}
        >
          <FontAwesomeIcon icon={props?.isRowSelected ? faCheckSquare : faSquare} />
        </div>
      );
    },

    key: "select-row",
    name: "",
    resizable: false,
    sortable: false,
    maxWidth: 35,
    width: 35,
  },
  {
    key: "id",
    name: "ID",
    width: 100,
    formatter: (props) => {
      return <div>{shortId(props?.row?.id)}</div>;
    },
  },

  {
    key: "number",
    name: "PO #",
    minWidth: 100,
    formatter: (props) => {
      return <div>{props?.row?.number}</div>;
    },
  },

  {
    key: "vendor",
    name: "Vendor",
    minWidth: 200,
    formatter: (props) => {
      return <div>{props?.row?.vendor?.name}</div>;
    },
  },

  {
    key: "status",
    name: "Status",
    minWidth: 200,
    headerCellClass: "text-center",
    formatter: (props) => {
      return (
        <div className="h-full flex flex-row items-center justify-center ">
          <PurchaseOrderStatusBadge status={props?.row?.status} />
        </div>
      );
    },
  },

  {
    key: "createdAt",
    name: "Created",
    minWidth: 200,
    formatter: (props) => {
      return <div>{shortDateTime(props?.row?.createdAt)}</div>;
    },
  },

  {
    key: "actions",
    name: "",
    width: 50,
    cellClass: "text-center flex justify-center items-center",
    headerCellClass: "text-center flex justify-center items-center",
    formatter: (props) => <RowMenu row={props.row} />,
  },
];

const Table = () => {
  const { setPagination, pagination, fetch, data, loading, setselected, selected, variables } = useTable();

  useEffect(() => {
    fetch({
      variables,
    });
  }, [fetch, variables]);

  return (
    <>
      <div className="flex justify-end items-center">
        <SelectPagination value={pagination?.perPage} onChange={(perPage) => setPagination({ perPage })} />
      </div>
      <div style={{ flex: "1 1 auto" }} className="overflow-auto">
        <StyledTable<React.FC<DataGridProps<PurchaseOrder>>>
          rowHeight={56}
          rowKeyGetter={(r: PurchaseOrder) => r?.id}
          rowRenderer={(props) => <RowRenderer contextMenuId={MENU_ID} {...props} />}
          columns={columns}
          rows={data?.listPurchaseOrders?.purchaseOrders ?? []}
          selectedRows={selected}
          onSelectedRowsChange={setselected}
          emptyRowsRenderer={() =>
            loading ? (
              <LoadingRenderer message="Fetching purchase orders" />
            ) : (
              <EmptyRowsRenderer message="No purchase orders found" />
            )
          }
        />
      </div>
      <Pagination
        loading={loading}
        currentPage={pagination?.page ?? 1}
        totalPages={data?.listPurchaseOrders?.pageInfo?.totalPages}
        canNextPage={pagination?.page < data?.listPurchaseOrders?.pageInfo?.totalPages}
        canPreviousPage={data?.listPurchaseOrders?.pageInfo?.page > 1 ?? false}
        setPagination={setPagination}
      />
      <ContextMenu />
    </>
  );
};

export { Table as default };
