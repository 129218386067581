import React from "react";
import { ParseResult } from "papaparse";
import { useBulkCreateCustomersMutation, CreateCustomerInput } from "generated/graphql";
import { chunk } from "lodash";
import { ImportFromCSV, ITemplateRow, IImportFrom } from "./index";

interface ImportCustomersITemplateRow extends ITemplateRow {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  company: string;
  customerNumber: string;
  paymentTerms: string;
  shipVia: string;
}

export const ImportCustomersFromCSV: React.FC<IImportFrom> = ({ isOpen, onRequestClose }) => {
  const [createCustomers] = useBulkCreateCustomersMutation();
  const importInventoryItems = async (
    parsed: ParseResult<ImportCustomersITemplateRow> & { file: File },
    tags: Record<string, string>,
    onProgress: (progress: number) => void
  ) => {
    const { data: rows, meta } = parsed;

    const shippingAddressFields = meta.fields.filter((field) => field.includes("shippingAddress:"));
    const billingAddressFields = meta.fields.filter((field) => field.includes("billingAddress:"));

    const customers: CreateCustomerInput[] = rows.map((row) => {
      return {
        email: row.email,
        company: row.company,
        phone: row.phoneNumber.replaceAll(/[^\w\d]/gi, ""),
        firstName: row.firstName,
        lastName: row.lastName,
        idempotencyKey: row.customerNumber,
        paymentTerms: row.paymentTerms,
        shipVia: row.shipVia,
        tags:
          Object.keys(tags)?.map((name) => ({
            tagId: tags[name],
            value: row[name],
          })) ?? null,
        shippingAddress: shippingAddressFields.reduce<Record<string, string>>((acc, field) => {
          const key = field.replace("shippingAddress:", "");
          acc[key] = row[field];
          return acc;
        }, {}),
        billingAddress: billingAddressFields.reduce<Record<string, string>>((acc, field) => {
          const key = field.replace("billingAddress:", "");
          acc[key] = row[field];
          return acc;
        }, {}),
      };
    });

    const chunks = chunk(customers, 100);
    const requestchunks = chunk(chunks, 5);

    for (let i = 0; i < requestchunks.length; i++) {
      const requests = requestchunks[i];
      const waitFor = requests.map((items) =>
        createCustomers({
          variables: {
            input: items,
          },
        })
      );

      await Promise.all(waitFor);

      onProgress(Math.floor(((i + 1) / requestchunks.length) * 100));
    }
  };

  return (
    <ImportFromCSV
      type="customers"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      templateURL="/assets/customer-import-template.csv"
      importFn={importInventoryItems}
    />
  );
};
