import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage } from "@hookform/error-message";
import { AddButton } from "components/Buttons";
import { ScrollableForm } from "components/Form/ScrollableForm";
import { ImportLineItemsFromCSV } from "components/ImportFromCSV/LineItems";
import { SideModal } from "components/Modals/SideModal";
import { SelectCustomerModalInput } from "components/Select/Customer";
import { SelectInventoryItemModalInput } from "components/Select/InventoryItem";
import { Customer, InventoryItem, ListCustomerOrdersDocument, useCreateCustomerOrderMutation } from "generated/graphql";
import { useToast } from "hooks/toast";
import React, { useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";
import { Routes } from "routes";
import { H4, IconButton } from "styles";
import { CreateCustomer } from "./CreateCustomer";
interface CustomerOrderLineItem {
  inventoryItem: Pick<InventoryItem, "id" | "name" | "valuePerUnit">;
  quantity: number;
}

interface CreateCustomerOrderInput {
  customer: Pick<
    Customer,
    | "id"
    | "firstName"
    | "lastName"
    | "company"
    | "email"
    | "phone"
    | "addresses"
    | "defaultAddress"
    | "shippingAddress"
    | "billingAddress"
  >;
  lineItems: CustomerOrderLineItem[];
}

const CreateCustomerOrder = () => {
  const [importLineItems, setImportLineItems] = useState(false);
  const [createCustomer, setCreateCustomer] = useState(false);
  const history = useHistory();
  const toast = useToast();

  const [createCustomerOrder] = useCreateCustomerOrderMutation({
    refetchQueries: [{ query: ListCustomerOrdersDocument }],
  });
  const { handleSubmit, control, formState, setValue, watch } = useForm<CreateCustomerOrderInput>({
    defaultValues: {
      customer: null,
      lineItems: [],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "lineItems",
  });
  const lineItemFields = watch("lineItems");
  const submit = async (formData: CreateCustomerOrderInput) => {
    const lineItems =
      formData?.lineItems
        ?.filter((li) => li?.inventoryItem?.id && li?.quantity > 0)
        ?.map((lineItem) => ({
          inventoryItemId: lineItem?.inventoryItem?.id,
          quantity: lineItem?.quantity,
        })) ?? [];

    if (lineItems.length < 1) {
      toast.error("You must add at least one line item to this customer order");
      return;
    }

    try {
      await createCustomerOrder({
        variables: {
          input: {
            customerId: formData.customer.id,
            lineItems,
          },
        },
      });
      toast.success(`Order for ${formData?.customer?.firstName} ${formData?.customer?.lastName} created`);
      history.push(Routes.CustomerOrders);
    } catch (err) {
      console.log(err);
      toast.error("Unable to create customer order");
    }
  };

  return (
    <>
      <ScrollableForm
        onSubmit={handleSubmit(submit)}
        title="Create Sales Order"
        submitLabel="Create"
        onCancel={() => history.push(Routes.CustomerOrders)}
        classNames={{ buttonContainer: "w-full lg:w-1/3" }}
        formState={formState}
        enableSubmitOnDirty={false}
      >
        <div className="flex flex-col items-start ">
          <H4 className="my-2">
            Customer
            <AddButton onClick={() => setCreateCustomer(true)} />
          </H4>
          <Controller
            control={control}
            name="customer"
            rules={{ required: "Customer is required" }}
            render={({ field }) => <SelectCustomerModalInput value={field.value} onChange={field.onChange} />}
          />
          <div className="text-xs text-brand">
            <ErrorMessage name="customer" errors={formState.errors} />
          </div>
        </div>

        <H4 className="mb-2">
          Items{" "}
          <AddButton
            onClick={() =>
              append({
                inventoryItem: null,
                quantity: 0,
              })
            }
          />
          <div className="text-xs bg-gray-600 text-white mx-2 px-4 rounded-full">{lineItemFields?.length ?? null}</div>
        </H4>

        {lineItemFields?.length < 1 ? (
          <div className="flex flex-col items-center">
            <button
              type="button"
              className="text-blue-500 dark:text-blue-400 hover:opacity-75 my-4 text-lg"
              onClick={() =>
                append({
                  inventoryItem: null,
                  quantity: 0,
                })
              }
            >
              Add a line item
            </button>
            <div className="">or</div>

            <button
              type="button"
              className="text-blue-500 dark:text-blue-400 hover:opacity-75 my-4 text-lg"
              onClick={() => setImportLineItems(true)}
            >
              Import from CSV
            </button>
          </div>
        ) : (
          <table className="table-fixed">
            <thead>
              <tr className="border-b border-gray-700 ">
                <th className="w-1/12 text-xs px-2 py-2 text-left">#</th>
                <th className="w-8/12 text-xs px-2 py-2 text-left">Item</th>
                <th className="w-3/12 text-xs px-2 py-2 text-left">Quantity</th>
              </tr>
            </thead>
            <tbody>
              {fields?.map((field, index) => {
                return (
                  <tr key={field?.id} className="even:bg-gray-300 dark:even:bg-gray-700">
                    <td className="px-2">{index + 1}.</td>
                    <td className="">
                      <Controller
                        control={control}
                        name={`lineItems.${index}.inventoryItem` as const}
                        render={({ field }) => (
                          <SelectInventoryItemModalInput value={field.value} onChange={field.onChange} />
                        )}
                      />
                    </td>

                    <td>
                      <Controller
                        control={control}
                        name={`lineItems.${index}.quantity` as const}
                        defaultValue={0}
                        render={({ field }) => (
                          <NumberFormat
                            className="dark:text-white bg-transparent rounded-none appearance-none leading-normal px-2 border border-transparent focus:border-blue-400 focus:outline-none focus:shadow-none h-full"
                            allowNegative={false}
                            allowLeadingZeros={false}
                            onValueChange={(v) => field.onChange(v?.floatValue)}
                            value={field.value}
                          />
                        )}
                      />
                    </td>

                    <td>
                      <IconButton type="button" onClick={() => remove(index)}>
                        <FontAwesomeIcon icon={faTimes} size="sm" />
                      </IconButton>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </ScrollableForm>

      <SideModal side="RIGHT" isOpen={createCustomer} onRequestClose={() => setCreateCustomer(false)}>
        <CreateCustomer
          onCancel={() => setCreateCustomer(false)}
          onCreate={(customer) => {
            setValue("customer", customer);
            setCreateCustomer(false);
          }}
        />
      </SideModal>
      <ImportLineItemsFromCSV
        isOpen={importLineItems}
        onRequestClose={() => setImportLineItems(false)}
        onChange={(values) => setValue("lineItems", values ?? [])}
      />
    </>
  );
};

export { CreateCustomerOrder as default };
