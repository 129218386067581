import React, { useEffect } from "react";
import { ScrollableForm } from "components/Form/ScrollableForm";
import { IPurchaseRequestItemsForm, PurchaseRequestForm } from "components/Views/PurchaseRequest/Form";
import { InventoryItem, useBulkCreatePurchaseRequestsMutation } from "generated/graphql";
import { useToast } from "hooks/toast";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { Routes } from "routes";

const CreatePurchaseRequest = () => {
  const history = useHistory();
  const toast = useToast();

  const location = useLocation<{ items: InventoryItem[] }>();
  const [createPurchaseRequest] = useBulkCreatePurchaseRequestsMutation();
  const form = useForm<IPurchaseRequestItemsForm>({
    defaultValues: {
      items: [],
    },
  });

  const submit = async (formData: IPurchaseRequestItemsForm) => {
    try {
      await createPurchaseRequest({
        variables: {
          input: formData.items.map((row) => {
            return {
              inventoryItemId: row.item.id,
              vendorProductId: row.vendorProduct.product.id,
              quantity: row.quantity,
              pricePerUnit: row.pricePerUnit,
            };
          }),
        },
      });

      history.push(Routes.PurchaseRequests);
    } catch (err) {
      toast.error(err);
    }
  };

  useEffect(() => {
    form.reset({
      items:
        location?.state?.items?.map((item) => {
          return {
            item: item,
            quantity: 0,
            pricePerUnit: item?.valuePerUnit ?? 0,
            vendorProduct: {
              vendor: item?.defaultVendorProduct?.vendor ?? null,
              product: item?.defaultVendorProduct ?? null,
            },
          };
        }) ?? [],
    });
    // eslint-disable-next-line
  }, [location.state, form.reset]);

  return (
    <ScrollableForm
      title="Create Purchase Request"
      formState={form.formState}
      onSubmit={form.handleSubmit(submit)}
      onCancel={() => history.push(Routes.PurchaseRequests)}
      submitLabel="Save"
      classNames={{ buttonContainer: "w-full lg:w-1/3" }}
    >
      <PurchaseRequestForm form={form} />
    </ScrollableForm>
  );
};

export { CreatePurchaseRequest as default };
