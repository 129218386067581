import React from "react";
import { P } from "styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faComments, faVideo, faBook } from "@fortawesome/free-solid-svg-icons";
import { useIntercom } from "react-use-intercom";
import TitleWithLoading from "components/TitleWithLoading";

export const Support = () => {
  const { show } = useIntercom();

  return (
    <div className="py-2 px-2 w-full">
      <TitleWithLoading title="Support" />
      <P className="text-center py-6">What kind of support do you need?</P>
      <div className="flex justify-center">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-4 gap-y-4">
          <a
            href="https://factoryfinch.com/tutorials"
            target="_blank"
            rel="noreferrer"
            className="p-12 border border-gray-400 hover:border-brand rounded flex flex-col items-center cursor-pointer"
          >
            <FontAwesomeIcon icon={faVideo} size="6x" />
            <div className="py-4">Video Tutorials</div>
          </a>

          <a
            href="mailto:help@factoryfinch.com"
            className="p-12 border border-gray-400 hover:border-brand rounded flex flex-col items-center cursor-pointer"
          >
            <FontAwesomeIcon icon={faEnvelope} size="6x" />
            <div className="py-4">Email</div>
            <div className="text-sm">help@factoryfinch.com</div>
          </a>

          <div
            className="p-12 border border-gray-400 hover:border-brand rounded flex flex-col items-center cursor-pointer"
            onClick={show}
          >
            <FontAwesomeIcon icon={faComments} size="6x" />
            <div className="py-4">Chat with us!</div>
          </div>

          <a
            href="https://help.factoryfinch.com"
            className="p-12 border border-gray-400 hover:border-brand rounded flex flex-col items-center cursor-pointer"
          >
            <FontAwesomeIcon icon={faBook} size="6x" />
            <div className="py-4">Knowledge Base</div>
          </a>
        </div>
      </div>
    </div>
  );
};
