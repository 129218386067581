import { EditVendorForm, IEditVendorForm } from "components/Views/Vendor/Vendors/EditVendor";
import { useUpdateVendorMutation, Vendor } from "generated/graphql";
import React from "react";

export const EditVendor: React.FC<{
  vendor: Vendor;
  onRequestClose: () => void;
}> = ({ vendor, onRequestClose }) => {
  const [updateVendor] = useUpdateVendorMutation();

  const onSubmit = async (input: IEditVendorForm) => {
    try {
      await updateVendor({
        variables: {
          input: {
            id: input.id,
            name: input.name,
            companyPhone: input.companyPhone,
            address1: input.address1,
            address2: input.address2,
            city: input.city,
            state: input.region.name,
            country: input.country.countryName,
            postalCode: input.postalCode,
            contactFirstName: input.contactFirstName,
            contactLastName: input.contactLastName,
            contactEmail: input.contactEmail,
            contactPhoneOffice: input.contactPhoneOffice,
            contactPhoneMobile: input.contactPhoneMobile,
            notes: input.notes,
          },
        },
      });
      onRequestClose();
    } catch (err) {
      console.log(err);
    }
  };

  return <EditVendorForm vendor={vendor} onSubmit={onSubmit} onCancel={onRequestClose} />;
};
