import cx from "classnames";
import React, { useCallback } from "react";
import { useTable } from "./context";
import { Customer } from "generated/graphql";
import { SelectCustomer } from "components/Select/Customer";
import { SelectDateRange } from "components/Select/DateRange";
export const QuickFilters = () => {
  const { showQuickFilters, filters, setFilters } = useTable();
  const setValue = useCallback(
    (v: any) => {
      setFilters({
        ...filters,
        ...v,
      });
    },
    [filters, setFilters]
  );

  return (
    <div className={cx(!showQuickFilters && "hidden", "grid grid-cols-1 lg:grid-cols-4 gap-x-2 gap-y-2 py-2")}>
      <div>
        <label className="text-xs">Customers</label>
        <SelectCustomer
          value={filters?.customers}
          onChange={(values: Customer[]) => setValue({ customers: values })}
          isMulti
        />
      </div>
      <div>
        <SelectDateRange
          label="Created"
          value={filters?.createdAt}
          onChange={({ from, to }) => {
            setValue({
              createdAt: {
                from,
                to,
              },
            });
          }}
        />
      </div>
    </div>
  );
};
