import cx from "classnames";
import React from "react";
import { Tooltip } from "styles";

const Filter: React.FC<{
  className?: string;
  title: string;
  isVisible?: boolean;

  onRemove: () => void;
}> = ({ onRemove, title, className = "", isVisible = true }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <Tooltip arrow title="Remove">
      <div
        className={cx(
          "mx-1 my-1 cursor-pointer rounded-full  text-white text-xs py-1 px-2 inline text-center font-semibold whitespace-nowrap truncate",
          className ?? "bg-gray-600"
        )}
        style={{ maxWidth: "300px" }}
        onClick={onRemove}
      >
        {title}
      </div>
    </Tooltip>
  );
};

export { Filter as default };
