import React, { useRef } from "react";
import IconButtonMenu from "components/IconButtonMenu";
import { SupportedMimeTypes } from "config";

const FileInput: React.FC<{
  onChange: (value: FileList) => void;
  onRemove: () => void;
}> = ({ onChange, onRemove }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const handleChooseFile = () => {
    inputRef?.current?.click();
  };

  return (
    <div>
      <IconButtonMenu
        options={[
          {
            label: "Choose File",
            onClick: handleChooseFile,
          },
          {
            label: <div className="text-brand">Remove</div>,
            onClick: onRemove,
          },
        ]}
      />
      <input
        ref={inputRef}
        style={{ display: "none" }}
        type="file"
        accept={SupportedMimeTypes.join(",")}
        onChange={(e) => onChange(e.target.files)}
      />
    </div>
  );
};

export { FileInput as default };
