import React, { useMemo } from "react";
import { BrowserQRCodeSvgWriter } from "@zxing/browser";

const QRCode: React.FC<{ value: string; size?: number }> = ({ value, size = 300 }) => {
  const qrCode = useMemo(() => {
    if (value) {
      return new BrowserQRCodeSvgWriter().write(value, size, size).outerHTML;
    }

    return "";
  }, [value, size]);
  return (
    <div className="bg-white flex flex-col justify-center items-center h-full">
      <div dangerouslySetInnerHTML={{ __html: qrCode }} />

      <div className="text-xs text-center mb-4 dark:text-gray-800">{value}</div>
    </div>
  );
};

export { QRCode as default };
