import { faCheck, faTimes, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ITagInputValue, TagInputWSuggest } from "components/Form/TagInput";
import { CenterModal } from "components/Modals/CenterModal";
import { useToast } from "hooks/toast";
import { useColumns } from "hooks/useOptionalColumns";
import React, { useEffect } from "react";
import { Column } from "react-data-grid";
import { H4, Tooltip } from "styles";

export const EditColumns = <T,>({ optionalColumns }: { optionalColumns: Record<string, Column<T, unknown>> }) => {
  const toast = useToast();
  const { keys, columns, hide, show, isModifying, setIsModifying, add, remove, reset, saveDefaultColumns } =
    useColumns();
  const scrollRef = React.useRef<HTMLDivElement>(null);

  const addColumn = (t: ITagInputValue) => {
    add({
      key: t?.tag?.id,
      label: t?.tag?.name,
    });
  };

  useEffect(() => {
    scrollRef?.current?.scrollTo(0, scrollRef?.current?.scrollHeight);
  }, [keys]);

  return (
    <CenterModal isOpen={isModifying} onRequestClose={() => setIsModifying(false)} className="lg:w-1/5 xl:w-1/5">
      <div className="flex justify-between items-center">
        <H4>Edit Columns</H4>
        <button type="button" onClick={() => setIsModifying(false)} className="px-2">
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      <div className="flex flex-col py-4">
        <div className="h-72 overflow-y-auto" ref={scrollRef}>
          {keys?.map((key, idx) => {
            const c = optionalColumns[key];
            const cdef = columns[key];
            return (
              <div
                className="flex items-center justify-between cursor-pointer hover:opacity-75"
                key={c?.key || cdef?.key || `col-${key}-${idx}`}
                onClick={() => {
                  if (columns[key]?.hidden) {
                    show(key);
                  } else {
                    hide(key);
                  }
                }}
              >
                <div className="flex items-center py-2">
                  <div className="mx-2">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className={cdef?.hidden ? "text-gray-300 dark:text-gray-700" : "dark:text-gray-300"}
                    />
                  </div>
                  <div>{c?.name || cdef?.label || key} </div>
                </div>
                {cdef?.added && (
                  <Tooltip arrow title="Remove">
                    <div className="text-xs mx-4 cursor-pointer hover:text-brand" onClick={() => remove(key)}>
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </div>
                  </Tooltip>
                )}
              </div>
            );
          })}
        </div>
        <div className="py-2">
          <label className="text-xs">Add Column</label>
          <TagInputWSuggest tagOnly onChange={addColumn} />
        </div>

        <div className="flex flex-col justify-center py-2">
          <button
            className="text-blue-500 hover:opacity-75 active:opacity-90 text-xs my-2"
            type="button"
            onClick={() => {
              saveDefaultColumns();
              toast.success("Saved default columns");
            }}
          >
            Save as default
          </button>
          <button className="text-brand text-sm" type="button" onClick={reset}>
            Reset
          </button>
        </div>
      </div>
    </CenterModal>
  );
};
