import styled from "styled-components";
import tw from "twin.macro";
import DataGrid from "react-data-grid";

const StyledTable = styled(DataGrid)`
  ${tw`h-full bg-transparent border-none`}

  .rdg-header-row {
    ${tw`bg-white dark:bg-gray-800 text-black dark:text-white border-b border-gray-600`}
  }

  .rdg-row {
    ${tw`bg-white dark:bg-gray-800 text-black dark:text-white`}
    &:nth-child(even) {
      ${tw`bg-gray-200 dark:bg-gray-700`}
    }

    &:nth-child(odd) {
      ${tw`bg-white dark:bg-gray-800`}
    }

    &:hover {
      opacity: 0.9;
    }
  }

  .rdg-cell {
    border: none;
    box-shadow: none;
    /* user-select: text; */
  }
  .rdg-cell-selected {
    box-shadow: inset 0 0 0 2px var(--selection-color);
  }

  .rdg-cell-resizable[role="columnheader"] {
    position: relative;
    /* remove ability to interact with parent element */
    pointer-events: none;

    /* apply pointer cursor to parent element */
    cursor: col-resize;
    &::after {
      content: "|";

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 10px;
      pointer-events: auto;
      ${tw`text-gray-300 dark:text-gray-600`}
    }
  }

  .rdg-text-editor {
    background: transparent;
  }
`;

export { StyledTable as default };
