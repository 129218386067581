import React, { useMemo } from "react";
import BaseBadge from "./Base";

export const FulfillmentStatus: React.FC<{
  status: string;
}> = ({ status }) => {
  const props = useMemo(() => {
    switch (status) {
      case "NOT_FULFILLED":
        return {
          text: "Not Fulfilled",
          style: "bg-transparent border border-gray-600 dark:border-gray-400",
          textStyle: "font-semibold text-gray-600 dark:text-gray-300 text-xs text-center",
        };

      case "PARTIAL":
        return {
          text: "Partially Fulfilled",
          style: "bg-gray-300",
          textStyle: "font-semibold text-gray-800 text-xs text-center",
        };

      case "FULFILLED":
        return {
          text: "Fulfilled",
          style: "bg-green-400",
          textStyle: "font-semibold text-white text-xs text-center",
        };

      case "RESTOCKED":
        return {
          text: "Restocked",
          style: "bg-yellow-400",
          textStyle: "font-semibold text-yellow-400 text-xs text-center",
        };

      default:
        return {
          text: "Unknown",
          style: "bg-gray-400",
          textStyle: "font-semibold text-white text-xs text-center",
        };
    }
  }, [status]);

  return <BaseBadge {...props} />;
};
