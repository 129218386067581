import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import { Attachments } from "components/Attachments";
import { AddButton } from "components/Buttons";
import Checkbox from "components/Form/Checkbox";
import CurrencyInput from "components/Form/CurrencyInput";
import FileInput from "components/Form/FileInput";
import { fromInventoryType, isValidInventoryItemType, toInventoryItemType } from "components/Form/helpers";
import { ITagInputValue, MultiTagInputWSuggest } from "components/Form/TagInput";
import TextInput from "components/Form/TextInput";
import { SelectInventoryItemModalInput } from "components/Select/InventoryItem";
import { getInventoryItemTypeLabel } from "components/Select/InventoryItemType";
import { SelectVendor } from "components/Select/Vendor";
import SelectVendorProduct from "components/Select/VendorProduct";
import { SelectWorkflow } from "components/Select/Workflow";
import { InventoryItem, Location, ResourceTag, Vendor, VendorProduct, Workflow } from "generated/graphql";
import React, { useCallback } from "react";
import { Controller, useFieldArray, UseFormReturn } from "react-hook-form";
import { IconButton, SubHeading } from "styles";

interface IVendorProductField {
  vendor: Vendor;
  product: VendorProduct;
}

interface IIngredient {
  inventoryItem: Pick<InventoryItem, "id" | "name">;
}

export interface IInventoryItemFormData {
  name: string;
  units: string;
  isMade: boolean;
  isPurchased: boolean;
  isUsedToMakeSomethingElse: boolean;
  isSold: boolean;
  onHand: number;
  reserved: number;
  valuePerUnit: number;
  defaultWorkflow: Pick<Workflow, "id" | "name">;
  tags: ResourceTag[];
  sku: string;

  defaultVendorProduct: IVendorProductField;
  ingredients: IIngredient[];
  attachments: { value: { name: string; file: FileList } }[];
  location?: Location;
}

export const InventoryItemForm: React.FC<
  React.PropsWithChildren<{
    form: UseFormReturn<IInventoryItemFormData>;
    inventoryItem?: InventoryItem;
    onRemoveTag?: (val: ITagInputValue, i?: number) => void;
  }>
> = ({ form, inventoryItem, onRemoveTag = () => {}, children }) => {
  const { control, watch, register, formState, reset } = form;

  const ingredientsFieldArray = useFieldArray({
    control,
    name: "ingredients",
  });

  const attachmentsFieldArray = useFieldArray({
    control,
    name: "attachments",
  });

  const values = watch();
  const shouldSelectWorkflow = values.isMade;
  const shouldSelectVendorProduct = values.isPurchased;
  const shouldShowIngredients = values.isMade;

  const validateType = useCallback(() => {
    return (
      [values.isPurchased, values.isUsedToMakeSomethingElse, values.isSold, values.isMade].every((v) => v === false) ||
      isValidInventoryItemType(values) ||
      "Invalid combination"
    );
  }, [values]);

  React.useEffect(() => {
    if (inventoryItem) {
      reset({
        ...inventoryItem,
        ...fromInventoryType(inventoryItem.type),
        attachments: [],
        defaultVendorProduct: {
          vendor: inventoryItem?.defaultVendorProduct?.vendor ?? null,
          product: inventoryItem?.defaultVendorProduct ?? null,
        },
        tags: inventoryItem.tags,

        ingredients: inventoryItem?.ingredients.map((ing) => ({
          inventoryItem: ing,
        })),
      });
    } else {
      reset({
        isMade: false,
        isPurchased: false,
        isUsedToMakeSomethingElse: false,
        isSold: false,
      });
    }
  }, [reset, inventoryItem]);

  return (
    <>
      <SubHeading>Name*</SubHeading>
      <TextInput
        data-testid="inventory-item-name"
        {...register("name", { required: true })}
        className={cx("mb-2", formState?.errors?.name && "error")}
        placeholder="ie. Tailored Fit Dark Wash Jeans"
      />
      <SubHeading>SKU</SubHeading>
      <TextInput
        data-testid="inventory-item-sku"
        {...register("sku")}
        className={cx("mb-2", formState?.errors?.name && "error")}
        placeholder="ie. SKU000001"
      />
      <SubHeading>Units</SubHeading>
      <TextInput
        data-testid="inventory-item-units"
        placeholder="ie. Units"
        {...register("units")}
        className={cx("my-2", formState?.errors?.units && "error")}
      />

      <SubHeading>Value per unit</SubHeading>
      <Controller
        name="valuePerUnit"
        control={control}
        render={({ field }) => (
          <CurrencyInput
            data-testid="inventory-item-value-per-unit"
            value={field.value}
            onValueChange={field.onChange}
            placeholder="0"
          />
        )}
      />

      <div className="flex items-center">
        <SubHeading>Type*</SubHeading>
        <div className="ml-2 text-xs text-gray-400">{getInventoryItemTypeLabel(toInventoryItemType(values))}</div>
      </div>
      <div className="text-sm text-brand">
        {formState?.errors?.isMade && "Invalid type - try a different combination"}
      </div>

      <div className={cx(formState?.errors?.isMade && "border border-brand")}>
        <Controller
          control={control}
          name="isMade"
          defaultValue={false}
          rules={{
            validate: validateType,
          }}
          render={({ field }) => (
            <Checkbox
              data-testid="inventory-item-isMade"
              className="text-sm"
              onChange={(e) => field.onChange(e?.checked)}
              label="I make this"
              checked={field.value}
            />
          )}
        />

        <Controller
          control={control}
          name="isPurchased"
          defaultValue={false}
          rules={{
            validate: validateType,
          }}
          render={({ field }) => (
            <Checkbox
              data-testid="inventory-item-isPurchased"
              className="text-sm"
              onChange={(e) => field.onChange(e?.checked)}
              label="I buy this"
              checked={field.value}
            />
          )}
        />

        <Controller
          control={control}
          name="isUsedToMakeSomethingElse"
          defaultValue={false}
          rules={{
            validate: validateType,
          }}
          render={({ field }) => (
            <Checkbox
              data-testid="inventory-item-isUsedToMakeSomethingElse"
              className="text-sm"
              onChange={(e) => field.onChange(e?.checked)}
              label="This is used to make something else"
              checked={field.value}
            />
          )}
        />

        <Controller
          control={control}
          name="isSold"
          defaultValue={false}
          rules={{
            validate: validateType,
          }}
          render={({ field }) => (
            <Checkbox
              data-testid="inventory-item-isSold"
              className="text-sm"
              onChange={(e) => field.onChange(e?.checked)}
              label="I sell this"
              checked={field.value}
            />
          )}
        />
      </div>

      {shouldSelectVendorProduct && (
        <>
          <SubHeading>Default Vendor Product</SubHeading>

          <Controller
            name="defaultVendorProduct"
            control={control}
            render={({ field }) => (
              <div className="grid grid-cols-2 gap-x-2">
                <SelectVendor
                  value={values?.defaultVendorProduct?.vendor}
                  onChange={(vendor) => {
                    field.onChange({
                      vendor,
                      product: null,
                    });
                  }}
                  className={formState?.errors?.defaultVendorProduct ? "border border-brand rounded" : ""}
                />
                <SelectVendorProduct
                  placeholder="Select Product..."
                  isDisabled={!values?.defaultVendorProduct?.vendor?.id}
                  value={values?.defaultVendorProduct?.product}
                  onChange={(product) => {
                    field.onChange({
                      vendor: values?.defaultVendorProduct?.vendor,
                      product,
                    });
                  }}
                  defaultFilters={{
                    vendorId: values?.defaultVendorProduct?.vendor?.id,
                  }}
                  className={formState?.errors?.defaultVendorProduct ? "border border-brand rounded" : ""}
                />
              </div>
            )}
          />
        </>
      )}

      {shouldSelectWorkflow && (
        <div className="my-2">
          <SubHeading className="my-2">Default Workflow</SubHeading>
          <Controller
            name="defaultWorkflow"
            control={control}
            render={({ field }) => {
              return <SelectWorkflow value={field.value} onChange={field.onChange} />;
            }}
          />
        </div>
      )}

      {shouldShowIngredients && (
        <>
          <SubHeading>
            Ingredients <AddButton onClick={() => ingredientsFieldArray.append({ inventoryItem: null })} />
          </SubHeading>

          {ingredientsFieldArray.fields.map((ingField, index) => {
            return (
              <div className="flex flex-row my-1" key={ingField.id}>
                <Controller
                  name={`ingredients.${index}.inventoryItem` as const}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <SelectInventoryItemModalInput
                      value={field.value}
                      onChange={field.onChange}
                      classNames={{ input: "border-gray-300 dark:border-gray-400 rounded" }}
                    />
                  )}
                />
                <div>
                  <IconButton type="button" className="p-0" onClick={() => ingredientsFieldArray.remove(index)}>
                    <FontAwesomeIcon icon={faTimes} />
                  </IconButton>
                </div>
              </div>
            );
          })}
        </>
      )}
      <SubHeading>Tags</SubHeading>
      <Controller
        name="tags"
        control={control}
        render={({ field }) => {
          return (
            <MultiTagInputWSuggest
              value={field.value}
              onChange={field.onChange}
              allowCreate
              onRemove={(v, i) => onRemoveTag(v, i)}
            />
          );
        }}
      />

      <SubHeading>
        Attachments
        <AddButton
          onClick={() =>
            attachmentsFieldArray.append({
              value: null,
            })
          }
        />
      </SubHeading>
      <Attachments data={inventoryItem?.attachments ?? []} />

      {attachmentsFieldArray.fields.map((attachmentField, index) => {
        const fieldValue = values?.attachments?.[index]?.value;
        return (
          <div className="flex flex-row py-2" key={attachmentField.id}>
            <Controller
              control={control}
              name={`attachments.${index}.value` as const}
              defaultValue={attachmentField?.value}
              // rules={{
              //   validate: () =>
              //     (notEmpty(fieldValue?.file) && notEmpty(fieldValue?.name)) || "File name and file required",
              // }}
              render={({ field }) => {
                const selectedFile = fieldValue?.file?.item(0);
                return (
                  <>
                    <div className="flex-1">
                      <TextInput
                        type="text"
                        placeholder="File name"
                        value={fieldValue?.name ?? ""}
                        onChange={(e) => {
                          field.onChange({
                            name: e.target.value,
                            file: fieldValue?.file,
                          });
                        }}
                      />
                      {selectedFile && (
                        <div className="text-xs py-1">{`Selected: ${selectedFile?.name ?? "None"} `}</div>
                      )}
                    </div>
                    <FileInput
                      onChange={(value) => {
                        field.onChange({
                          name: value?.[0]?.name ?? "",
                          file: value,
                        });
                      }}
                      onRemove={() => attachmentsFieldArray.remove(index)}
                    />
                  </>
                );
              }}
            />
          </div>
        );
      })}

      {children}
    </>
  );
};
