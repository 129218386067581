import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toIcon } from "config";
import { Attachment } from "generated/graphql";
import { useDownloadAttachment } from "hooks/upload";

export const Attachments: React.FC<{ data: Attachment[] }> = ({ data }) => {
  const download = useDownloadAttachment();
  return (
    <>
      {data?.map((attachment) => {
        return (
          <div
            key={attachment.id}
            className="py-2 flex flex-row items-center cursor-pointer hover:opacity-75"
            onClick={() => download(attachment)}
          >
            <div className="flex-1 truncate text-left" title={attachment?.name}>
              {attachment?.name}
            </div>
            <div className="text-sm" title={attachment?.type}>
              <FontAwesomeIcon icon={toIcon(attachment?.type)} />
            </div>
          </div>
        );
      })}
    </>
  );
};
