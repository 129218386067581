import React, { useEffect } from "react";
import { useGetManufacturingOrderQuery } from "generated/graphql";

import { useParams } from "react-router-dom";
import { useWorkOrderEventGraph, EventGraphProvider } from "./context";
import { FlowChart } from "@mrblenny/react-flow-chart";
import StepActionMenu from "./StepActionMenu";
import StepNode from "./StepNode";
import CanvasOuter from "components/Flowchart/CanvasOuter";
import Node from "components/Flowchart/Node";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

const EventGraph = () => {
  const { config, chart, callbacks, setChartData, setZoom } = useWorkOrderEventGraph();
  const { id } = useParams<{ id: string }>();
  const { data } = useGetManufacturingOrderQuery({
    variables: { id },
  });

  useEffect(() => {
    if (data) {
      setChartData(data?.manufacturingOrder?.workflowVersion);
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <div className="w-full h-full bg-gray-400 overflow-hidden relative mt-2">
      <StepActionMenu />
      <div className="absolute z-50 flex" style={{ bottom: 20, right: 20 }}>
        <button
          type="button"
          className="bg-gray-300 dark:bg-gray-800 active:bg-gray-200 dark:active:bg-gray-400 text-gray-700 dark:text-white px-3 py-2 border border-gray-100 rounded-tl rounded-bl flex items-center justify-center"
          onClick={() => setZoom((chart?.scale ?? 1) - 0.1)}
        >
          <FontAwesomeIcon icon={faMinus} size="xs" />
        </button>
        <button
          type="button"
          className="bg-gray-300 dark:bg-gray-800 active:bg-gray-200 dark:active:bg-gray-400 text-gray-700 dark:text-white px-3 py-2 border-r border-t border-b border-gray-100 rounded-tr rounded-br flex items-center justify-center"
          onClick={() => setZoom((chart?.scale ?? 1) + 0.1)}
        >
          <FontAwesomeIcon icon={faPlus} size="xs" />
        </button>
      </div>
      <FlowChart
        chart={chart}
        callbacks={callbacks}
        config={config}
        Components={{
          NodeInner: StepNode,
          Node: Node,
          CanvasOuter: CanvasOuter,
        }}
      />
    </div>
  );
};

const Container = () => {
  const chartConfig = {
    readonly: false,
    selectable: true,
    zoom: {
      minScale: 0.4,
      maxScale: 2,
    },
  };

  return (
    <EventGraphProvider config={chartConfig}>
      <EventGraph />
    </EventGraphProvider>
  );
};

export { Container as default };
