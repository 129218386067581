import React from "react";
import { useTheme } from "hooks/theme";
import PoweredByStripeWhite from "styles/images/poweredByStripeWhite.svg";
import PoweredByStripeBlurple from "styles/images/poweredByStripeBlurple.svg";
// import PoweredByStripeBlack from "styles/images/poweredByStripeBlack.svg";

export const StripeLogo: React.FC<{ width?: number }> = ({ width = 100 }) => {
  const { isDarkMode } = useTheme();
  return <img width={width} src={isDarkMode ? PoweredByStripeWhite : PoweredByStripeBlurple} alt="powered by stripe" />;
};
