import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faCheckSquare, faSlidersH, faBolt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import { PhoneNumber } from "components/Form/PhoneInput";
import IconButtonMenu from "components/IconButtonMenu";
import Pagination from "components/Pagination";
import StyledTable from "components/Table";
import { EmptyRowsRenderer, LoadingRenderer, RowRenderer } from "components/Table/Renderers";
import { Customer } from "generated/graphql";
import React, { useEffect } from "react";
import { Item, ItemParams, Menu } from "react-contexify";
import { Column, DataGridProps, FormatterProps, HeaderRendererProps } from "react-data-grid";
import { Link } from "react-router-dom";
import { Routes, getRouteWithParams } from "routes";
import { shortId } from "utils/formatters";
import { IconButton, Tooltip } from "styles";
import { SelectPagination } from "components/Select/Pagination";
import { useTable, useRowActions, RowActions } from "./context";

const MENU_ID = "customer-row-menu";

const RowMenu: React.FC<{ row: Customer }> = ({ row }) => {
  const handleAction = useRowActions();
  return (
    <IconButtonMenu
      options={[
        {
          label: "View",
          onClick: () => handleAction("view", row),
        },
        {
          label: "Print Label",
          onClick: () => handleAction("printLabel", row),
        },
      ]}
    />
  );
};

const columns: Column<Customer, unknown>[] = [
  {
    headerRenderer: (props: HeaderRendererProps<Customer>) => {
      return (
        <div
          className="flex justify-center items-center h-full w-full text-brand cursor-pointer text-lg py-2"
          onClick={(e) => props.onAllRowsSelectionChange(!props?.allRowsSelected)}
        >
          <FontAwesomeIcon icon={props?.allRowsSelected ? faCheckSquare : faSquare} />
        </div>
      );
    },
    formatter: (props: FormatterProps) => {
      return (
        <div
          className={cx("flex justify-center items-center h-full w-full text-brand cursor-pointer text-lg py-2")}
          onClick={(e) => props.onRowSelectionChange(!props?.isRowSelected, e.shiftKey)}
        >
          <FontAwesomeIcon icon={props?.isRowSelected ? faCheckSquare : faSquare} />
        </div>
      );
    },

    key: "select-row",
    name: "",
    resizable: false,
    sortable: false,
    maxWidth: 35,
    width: 35,
  },
  {
    key: "id",
    name: "ID",
    minWidth: 100,
    formatter: (props) => {
      return (
        <Link
          title={props?.row?.id}
          to={getRouteWithParams(Routes.Customer, {
            id: props?.row?.id,
          })}
        >
          {shortId(props?.row?.id)}
        </Link>
      );
    },
  },
  {
    key: "idempotencyKey",
    name: "Customer #",

    minWidth: 100,
  },

  {
    key: "firstName",
    name: "First Name",

    minWidth: 100,
  },
  {
    key: "lastName",
    name: "Last Name",

    minWidth: 100,
  },
  {
    key: "phone",
    name: "Phone",
    cellClass: "flex",
    formatter: (props) => {
      return <PhoneNumber value={props?.row?.phone} />;
    },
  },
  {
    key: "email",
    name: "Email",
    minWidth: 200,
  },
  {
    key: "company",
    name: "Company",
    minWidth: 200,
  },

  {
    key: "actions",
    name: "",
    width: 50,
    cellClass: "text-center flex justify-center items-center",
    headerCellClass: "text-center flex justify-center items-center",
    formatter: (props) => <RowMenu row={props.row} />,
  },
];

const ContextMenu = () => {
  const { selected } = useTable();
  const handleAction = useRowActions();
  const handleItemClick: (args: ItemParams<Customer, any>) => void = ({ event, props: item }) => {
    handleAction(event.currentTarget.id as RowActions, item);
  };

  return (
    <Menu id={MENU_ID}>
      <Item id="view" onClick={handleItemClick}>
        View
      </Item>
      <Item id="printLabel" onClick={handleItemClick}>
        Print Label
      </Item>

      {selected?.size > 0 && (
        <>
          <div
            className="mt-2 w-full cursor-default"
            style={{
              height: "1px",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            }}
          />

          <div className="text-xs text-gray-600 px-3 font-semibold bg-gray-100  py-2">Bulk Actions</div>

          <Item id="generateLabels" onClick={handleItemClick}>
            Generate Labels
          </Item>
        </>
      )}
    </Menu>
  );
};

const TableHeader = () => {
  const { setPagination, pagination, setShowFilters, setShowQuickFilters, showQuickFilters } = useTable();

  return (
    <>
      <div className="flex">
        <div className="flex flex-row items-center px-2">
          <Tooltip arrow title={showQuickFilters ? `Hide quick filters` : `Show quick filters`}>
            <IconButton
              className={showQuickFilters ? `text-yellow-500 ` : ``}
              onClick={() => setShowQuickFilters(!showQuickFilters)}
            >
              <FontAwesomeIcon icon={faBolt} size="lg" />
            </IconButton>
          </Tooltip>

          <Tooltip arrow title="Filters">
            <IconButton className="" onClick={() => setShowFilters(true)}>
              <FontAwesomeIcon icon={faSlidersH} size="lg" />
            </IconButton>
          </Tooltip>
        </div>
        <SelectPagination value={pagination?.perPage} onChange={(perPage) => setPagination({ perPage })} />
      </div>
    </>
  );
};

const Table = () => {
  const { setPagination, pagination, fetch, data, loading, setselected, selected, variables } = useTable();

  useEffect(() => {
    fetch({
      variables,
    });
  }, [fetch, variables]);

  return (
    <>
      <div className="flex justify-between items-center py-2">
        <div className="px-2">{selected.size > 0 && <div>{selected.size} rows selected</div>}</div>
        <TableHeader />
      </div>
      <div style={{ flex: "1 1 auto" }} className="overflow-auto">
        <StyledTable<React.FC<DataGridProps<Customer>>>
          rowHeight={56}
          rowKeyGetter={(r: Customer) => r?.id}
          rowRenderer={(props) => <RowRenderer contextMenuId={MENU_ID} {...props} />}
          columns={columns}
          rows={data?.listCustomers?.customers ?? []}
          selectedRows={selected}
          onSelectedRowsChange={setselected}
          emptyRowsRenderer={() =>
            loading ? (
              <LoadingRenderer message="Fetching customers" />
            ) : (
              <EmptyRowsRenderer message="No customers found" />
            )
          }
        />
      </div>
      <Pagination
        loading={loading}
        currentPage={pagination?.page ?? 1}
        totalPages={data?.listCustomers?.pageInfo?.totalPages}
        canNextPage={pagination?.page < data?.listCustomers?.pageInfo?.totalPages}
        canPreviousPage={data?.listCustomers?.pageInfo?.page > 1 ?? false}
        setPagination={setPagination}
      />
      <ContextMenu />
    </>
  );
};

export { Table as default };
