import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SideModal } from "components/Modals/SideModal";
import SelectStep from "components/Select/Step";
import { PropertyType, Step } from "generated/graphql";
import React from "react";
import { animated, useSpring } from "react-spring";
import { H4, HR, PlainButton, RedButton, SubHeading, Table, Tooltip } from "styles";
import { notEmpty } from "utils/filters";
import { useWorkflowEditor } from "./context";
import EditStep from "./EditStep";
import { IStepNode, StepTypes } from "./types";

const humanizeProperty = (t: PropertyType) => {
  switch (t) {
    case PropertyType.String:
      return "Quality";
    case PropertyType.Number:
      return "Quantity";
    case PropertyType.Image:
      return "Image";
  }
};

const SubHeadingWithTooltip: React.FC<{
  title: string;
  description: string;
}> = ({ title, description }) => {
  return (
    <SubHeading className="my-2 flex items-center">
      {title}
      <Tooltip title={description} arrow>
        <span className="ml-2 text-sm text-gray-600">
          <FontAwesomeIcon icon={faQuestionCircle} />
        </span>
      </Tooltip>
    </SubHeading>
  );
};

const Sidebar: React.FC = () => {
  const { chart, selected, updateStep, setSelected, removeStep, isEditingStep, setIsEditingStep } = useWorkflowEditor();
  const node = selected as IStepNode;
  const step = [null, "first-step", "last-step"].includes(node?.properties?.step?.id) ? null : node?.properties?.step;
  const styleProps = useSpring({
    transform: chart?.selected?.type !== "node" ? "translateX(150%)" : "translateX(0%)",
  });

  const save = (step: Step) => {
    updateStep({
      ...node,
      properties: {
        ...node.properties,
        step,
      },
    });
  };

  const hasProperties = step?.properties?.length > 0;

  return (
    <>
      <animated.div
        style={styleProps}
        className="absolute right-0 bottom-0 top-0 my-4 mr-2 w-1/2 xl:w-1/4 bg-white dark:bg-gray-800 rounded p-2 flex flex-col z-50"
      >
        <div className="flex-1 flex flex-col">
          <div className="flex-1 overflow-auto">
            {node && (
              <>
                <div className="flex justify-between">
                  <H4>Step Details</H4>
                  {node?.type !== StepTypes.End && (
                    <button
                      onClick={() => {
                        removeStep(node);
                        setSelected({});
                      }}
                      className="text-brand text-xs"
                    >
                      Delete
                    </button>
                  )}
                </div>
                <HR />
                <SelectStep
                  allowCreate
                  placeholder="Select a Step..."
                  value={step}
                  onChange={(value: Step) => save(value)}
                />

                <button
                  type="button"
                  className="text-sm text-blue-500 dark:text-blue-400"
                  onClick={() => setIsEditingStep(step)}
                >
                  Edit Step...
                </button>

                <SubHeadingWithTooltip
                  title="Properties"
                  description="Require employees to input information when this step is completed."
                />
                {hasProperties ? (
                  <Table>
                    <thead>
                      <tr>
                        <th className="text-sm">Name</th>
                        <th className="text-sm">Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {step?.properties?.map((p, i) => {
                        return (
                          <tr key={`st-prop-${i}`}>
                            <td>{p?.name}</td>
                            <td>{humanizeProperty(p?.type)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : (
                  <div>No Step Properties</div>
                )}
              </>
            )}
          </div>
          <div className="mt-4 w-full grid grid-cols-2 gap-x-2">
            <PlainButton type="button" onClick={() => setSelected({})}>
              Cancel
            </PlainButton>
            <RedButton type="button" onClick={() => setSelected({})}>
              Save
            </RedButton>
          </div>
        </div>
      </animated.div>

      <SideModal
        side="RIGHT"
        isOpen={notEmpty(isEditingStep)}
        onRequestClose={() => setIsEditingStep(null)}
        className="w-2/6 bg-white dark:bg-gray-800 p-4 flex flex-col"
      >
        <EditStep />
      </SideModal>
    </>
  );
};

export { Sidebar as default };
