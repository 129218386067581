import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import NumberFormat, { NumberFormatProps } from "react-number-format";
import styled from "styled-components";
import tw from "twin.macro";

const CurrencyInput: React.FC<NumberFormatProps & { label?: string }> = styled(({ className, label, ...rest }) => {
  return (
    <div>
      {label && (
        <label className="text-xs" htmlFor={rest?.name}>
          {label}
        </label>
      )}
      <div className={className}>
        <div className="currency-icon">
          <FontAwesomeIcon icon={faDollarSign} />
        </div>
        <NumberFormat
          className="currency-input"
          {...rest}
          allowNegative={false}
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          allowEmptyFormatting={true}
          // defaultValue={0}
          allowLeadingZeros={false}
          onValueChange={(v) => {
            rest.onValueChange(v?.floatValue);
          }}
        />
      </div>
    </div>
  );
})`
  ${tw`border border-gray-300 dark:border-gray-400 rounded py-2 px-4 block flex flex-row items-center overflow-hidden bg-white dark:bg-gray-800 focus-within:border-blue-400`}

  .currency-icon {
    ${tw`pr-2 dark:text-white text-black`}
  }

  .currency-input {
    ${tw`appearance-none leading-normal focus:outline-none focus:shadow-none text-lg flex-1 bg-transparent dark:text-white text-black w-full`}
    &:focus {
      ${tw`outline-none shadow-none`}
    }

    &:disabled {
      ${tw`opacity-75`}
    }
  }
`;

export { CurrencyInput as default };
