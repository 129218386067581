import React, { useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Timeline, TimelineEvent } from "react-event-timeline";
import { faPencilAlt, faPlus, faReply, faTimes, faWrench } from "@fortawesome/free-solid-svg-icons";
import { theme } from "twin.macro";
import { StreamEvent, useGetStreamQuery, WorkOrderEvent } from "generated/graphql";
import { shortDateTime } from "utils/formatters";
import styled from "styled-components";
import tw from "twin.macro";
import { SmallBlueButton } from "styles";
import { SideModal } from "components/Modals/SideModal";
import { IconButton, H4, HR } from "styles";

const StyledTimelineEvent = styled(TimelineEvent)`
  ${tw`my-4`}

  div:nth-child(3) {
    background-color: transparent !important;
  }
`;

const getStyle = (streamEvent: StreamEvent & { __typename?: string }) => {
  if (streamEvent?.__typename === "WorkOrderEvent") {
    const event = streamEvent as WorkOrderEvent;
    switch (event?.type) {
      case "STEP_COMPLETED":
        return {
          title: (
            <div className="text-base">
              {`${event?.user?.name || event?.user?.email} completed`}
              <span className="mx-1 text-brand font-semibold">{event?.step?.name}</span>step
            </div>
          ),
          iconStyle: {
            color: theme`colors.white`,
            fontSize: 16,
          },
          bubbleStyle: { backgroundColor: theme`colors.brand`, borderColor: theme`colors.brand` },
          icon: <FontAwesomeIcon icon={faWrench} />,
        };
      case "STEP_UNDONE":
        return {
          title: (
            <div className="text-base">
              {`${event?.user?.name || event?.user?.email} reverted`}
              <span className="mx-1 text-brand font-semibold">{event?.step?.name}</span>step
            </div>
          ),
          iconStyle: {
            color: theme`colors.white`,
            fontSize: 16,
          },
          bubbleStyle: { backgroundColor: theme`colors.gray.700`, borderColor: theme`colors.gray.700` },
          icon: <FontAwesomeIcon icon={faReply} />,
        };
      case "UPDATED":
        return {
          title: <div className="text-base">{`${event?.user?.name || event?.user?.email} updated work order`}</div>,
          iconStyle: {
            color: theme`colors.white`,
            fontSize: 16,
          },
          bubbleStyle: { backgroundColor: theme`colors.blue.500`, borderColor: theme`colors.blue.500` },
          icon: <FontAwesomeIcon icon={faPencilAlt} />,
        };

      case "CREATED":
        return {
          title: <div className="text-base">{`${event?.user?.name || event?.user?.email} created work order`}</div>,
          iconStyle: {
            color: theme`colors.white`,
            fontSize: 16,
          },
          bubbleStyle: { backgroundColor: theme`colors.green.500`, borderColor: theme`colors.green.500` },
          icon: <FontAwesomeIcon icon={faPlus} />,
        };
    }
  }

  return {
    title: <pre>{JSON.stringify(streamEvent, null, 2)}</pre>,
    iconStyle: {
      color: theme`colors.white`,
      fontSize: 16,
    },
    bubbleStyle: { backgroundColor: theme`colors.brand`, borderColor: theme`colors.brand` },
    icon: <FontAwesomeIcon icon={faWrench} />,
  };
};

const StreamEventBody: React.FC<{ data: StreamEvent & { __typename?: string } }> = ({ data }) => {
  if (data?.__typename === "WorkOrderEvent") {
    const event = data as WorkOrderEvent;
    return (
      <>
        {event?.properties?.length > 0 && (
          <div className="w-full bg-transparent">
            <div className="text-sm font-semibold">Properties</div>
            {event?.properties?.map((property, i) => {
              return (
                <div key={`${event?.id}-${i}`} className="flex items-center">
                  <div className="text-xs font-semibold mr-2">{property?.name}: </div>
                  <div className="text-xs truncate" title={property?.value}>
                    {property?.value ?? "--"}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </>
    );
  }

  return null;
};

export const EventTimeline: React.FC<{ id: string; type: string }> = ({ id, type }) => {
  const { data, fetchMore } = useGetStreamQuery({
    fetchPolicy: "network-only",
    variables: {
      id,
      type,
      first: 10,
    },
  });
  const nodes = data?.getStream?.edges?.map((edge) => edge.node) ?? [];
  const pageInfo = data?.getStream?.pageInfo;

  const fetchMoreEvents = useCallback(() => {
    if (pageInfo?.hasNextPage) {
      fetchMore({
        variables: {
          after: pageInfo?.endCursor,
        },
      });
    }
  }, [fetchMore, pageInfo?.endCursor, pageInfo?.hasNextPage]);

  return (
    <>
      <Timeline>
        {nodes.length > 0 ? (
          nodes?.map((event) => {
            return (
              <StyledTimelineEvent
                key={event?.id}
                title=""
                createdAt={<div className="font-semibold dark:text-gray-300">{shortDateTime(event?.timestamp)}</div>}
                contentStyle={{ padding: "none", boxShadow: "none" }}
                {...getStyle(event)}
              >
                <StreamEventBody data={event} />
              </StyledTimelineEvent>
            );
          })
        ) : (
          <div></div>
        )}
      </Timeline>
      <div className="flex justify-center my-4">
        {pageInfo?.hasNextPage && <SmallBlueButton onClick={fetchMoreEvents}>Fetch More</SmallBlueButton>}
      </div>
    </>
  );
};

export const TimelineModal: React.FC<{
  isOpen: boolean;
  onRequestClose: () => void;
  id: string;
  type: string;
}> = ({ isOpen, onRequestClose, id, type }) => {
  return (
    <SideModal side="RIGHT" isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className="flex flex-col h-full overflow-auto">
        <div className="flex justify-between">
          <H4 className="px-2 py-2">Timeline</H4>
          <IconButton onClick={onRequestClose}>
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        </div>
        <HR />
        <EventTimeline id={id} type={type} />
      </div>
    </SideModal>
  );
};
