import React, { useMemo } from "react";
import { PurchaseOrderStatus } from "generated/graphql";
import BaseBadge from "./Base";

const PurchaseOrderStatusBadge: React.FC<{
  status: string;
}> = ({ status }) => {
  const props = useMemo(() => {
    switch (status) {
      case PurchaseOrderStatus.Pending:
        return {
          text: "Pending",
          style: "bg-transparent border border-gray-600 dark:border-gray-400",
          textStyle: "font-semibold text-gray-600 dark:text-gray-300 text-xs text-center",
        };

      case PurchaseOrderStatus.Sent:
        return {
          text: "Sent",
          style: "bg-transparent border border-green-400 dark:border-green-400",
          textStyle: "font-semibold border-green-400 text-xs text-center",
        };

      case PurchaseOrderStatus.Cancelled:
        return {
          text: "Cancelled",
          style: "bg-transparent border border-brand dark:border-brand",
          textStyle: "font-semibold text-brand dark:text-brand text-xs text-center",
        };

      case PurchaseOrderStatus.Received:
        return {
          text: "Received",
          style: "bg-green-400",
          textStyle: "font-semibold text-white text-xs text-center",
        };

      default:
        return {
          text: "Unknown",
          style: "bg-gray-400",
          textStyle: "font-semibold text-white text-xs text-center",
        };
    }
  }, [status]);

  return <BaseBadge {...props} />;
};

export { PurchaseOrderStatusBadge as default };
