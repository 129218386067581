import React, { useCallback, useState, useEffect } from "react";
import {
  ListLocationInventoryDocument,
  useBulkUpdateInventoryItemsMutation,
  BulkUpdateInventoryItemsInput,
} from "generated/graphql";
import { useForm } from "react-hook-form";
import { useTable } from "../context";
import { useToast } from "hooks/toast";
import { ScrollableForm } from "components/Form/ScrollableForm";
import { notEmpty } from "utils/filters";
import { BulkInventoryItemForm, IBulkInventoryItemFormData } from "./BulkInventoryItemForm";
import { Confirmation } from "components/Modals/Confirmation";
import ConfirmUpdate from "./ConfirmUpdate";
import { isEqual } from "lodash";
import { fromInventoryType, toInventoryItemType } from "components/Form/helpers";

const defaultValues: IBulkInventoryItemFormData = {
  defaultVendorProduct: null,
  defaultWorkflow: null,
  ingredients: [],
  type: { isMade: false, isPurchased: false, isSold: false, isUsedToMakeSomethingElse: false },
  tags: [],
  units: "",
  valuePerUnit: undefined,
};

const BulkEditInventoryItems: React.FC = () => {
  const toast = useToast();
  const { selectedRows, variables, setIsBulkUpdating } = useTable();
  const [confirm, setconfirm] = useState(false);
  const [bulkUpdateInventoryItems] = useBulkUpdateInventoryItemsMutation({
    refetchQueries: [{ query: ListLocationInventoryDocument, variables }],
  });

  const form = useForm<IBulkInventoryItemFormData>({ defaultValues });

  const submit = useCallback(
    async (formData: IBulkInventoryItemFormData) => {
      const ids = Array.from(new Set(selectedRows.map((row) => row?.inventoryItem?.id)));
      const dirtyFields = form?.formState?.dirtyFields;

      const input: BulkUpdateInventoryItemsInput = {
        ids,
        units: (dirtyFields["units"] && formData?.units?.toLowerCase()) || null,
        valuePerUnit: (dirtyFields["valuePerUnit"] && formData.valuePerUnit) || null,
        defaultWorkflowId: (dirtyFields["defaultWorkflow"] && formData?.defaultWorkflow?.id) || null,
        tags:
          (dirtyFields["tags"] &&
            formData?.tags?.map((tag) => ({
              tagId: tag.tag.id,
              value: tag?.value ?? null,
            }))) ||
          null,
        defaultVendorProductId:
          (dirtyFields["defaultVendorProduct"] && formData?.defaultVendorProduct?.product?.id) || null,
        ingredientIds:
          (dirtyFields["ingredients"] &&
            formData?.ingredients?.map((fields) => fields?.inventoryItem?.id)?.filter(notEmpty)) ||
          [],
        type: (dirtyFields["type"] && toInventoryItemType(formData?.type)) || null,
      };

      try {
        await bulkUpdateInventoryItems({ variables: { input } });
        toast.success(`Updated ${selectedRows.length} inventory items`);
        setIsBulkUpdating(false);
      } catch (err) {
        toast.error("Unable to update inventory items");
      }
    },
    [bulkUpdateInventoryItems, form?.formState?.dirtyFields, selectedRows, setIsBulkUpdating, toast]
  );

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formValues = form.getValues();

    if (isEqual(formValues, defaultValues)) {
      return;
    }

    const validationResult = await form.trigger();
    if (!validationResult) {
      toast.error("Please make sure you've filled out all the required fields");
      return;
    }

    setconfirm(true);
  };

  useEffect(() => {
    const type = selectedRows?.[0]?.inventoryItem?.type;
    if (selectedRows.every((r) => r?.inventoryItem?.type === type)) {
      form.setValue("type", fromInventoryType(type));
    }
  }, [selectedRows, form]);

  return (
    <>
      <ScrollableForm
        title="Bulk Edit Items"
        onSubmit={onSubmit}
        onCancel={() => setIsBulkUpdating(false)}
        submitLabel="Save"
        formState={form.formState}
      >
        <BulkInventoryItemForm form={form} />
      </ScrollableForm>
      <Confirmation
        loading={form.formState.isSubmitting}
        isOpen={confirm}
        onRequestClose={() => setconfirm(false)}
        onConfirm={form.handleSubmit(submit)}
      >
        <ConfirmUpdate form={form} />
      </Confirmation>
    </>
  );
};

export { BulkEditInventoryItems as default };
