import styled from "styled-components";
import * as styles from "styles";
import tw from "twin.macro";

export const Table = styled(styles.Table)`
  ${tw`table-fixed w-full`}

  thead {
    tr:first-child th {
      ${tw`text-xs pb-1 px-1`}

      &:first-child {
        ${tw`text-left`}
      }
    }
  }

  tbody > tr > td {
    ${tw`py-2 px-1 text-center text-xs`}
    &:first-child {
      ${tw`text-left`}
    }
  }

  thead tr:last-child {
    ${tw`border-b border-gray-300 dark:border-gray-600`}
  }

  thead th {
    ${tw`bg-white dark:bg-gray-800 text-default px-1 text-xs`}

    ${tw`text-center`}
    &:first-child {
      ${tw`text-left`}
    }
  }

  thead input {
    ${tw`border-0 w-full px-1 py-1`}
  }

  tbody tr:nth-child(even) {
    ${tw`bg-gray-200 dark:bg-gray-700`}
  }
`;
