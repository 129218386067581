import { faLifeRing } from "@fortawesome/free-regular-svg-icons";
import {
  faBox,
  faBoxes,
  faChartLine,
  faExchangeAlt,
  faFileExport,
  faFileInvoice,
  faLocationArrow,
  faMinusCircle,
  faPlug,
  faPlusCircle,
  faProjectDiagram,
  faShoePrints,
  faUserFriends,
  faUserLock,
  faUsers,
  faUserShield,
  faWrench,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import { OnHoverFlapWink } from "components/Loading";
import { LocalStorageKeys } from "config";
import React from "react";
import { NavLink, Route } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import { useLocalStorage } from "react-use";
import { Routes } from "routes";

const CollapsableSubNav: React.FC<{ subNav: SubNavItem }> = ({ subNav }) => {
  const [collapsed, setcollapsed] = useLocalStorage(LocalStorageKeys.CollapseSubNav, false);

  const props = useSpring({
    width: collapsed ? 80 : 250,
    overflow: "hidden",
  });
  return (
    <animated.div
      style={props}
      className="flex flex-col justify-between bg-gray-100 text-gray-500 dark:bg-gray-700 dark:text-white "
    >
      <div className="h-full flex flex-col pt-8" style={{ minWidth: collapsed ? "50px" : "200px" }}>
        {subNav.routes.map((subnavItem, k) => {
          return (
            <NavLink
              data-testid={subnavItem?.testId}
              key={`sbnav-${k}`}
              to={subnavItem.route}
              className="text-lg font-semibold transition duration-300 ease-in-out px-3 py-3 hover:bg-gray-200 dark:hover:bg-gray-600"
              activeClassName="text-brand"
              exact
            >
              {collapsed ? (
                <div
                  className="flex justify-center items-center"
                  style={{ minHeight: "42px" }}
                  title={subnavItem.label}
                >
                  <FontAwesomeIcon icon={subnavItem?.icon} size="lg" />
                </div>
              ) : (
                subnavItem.label
              )}
            </NavLink>
          );
        })}
      </div>
      <div className="flex justify-start">
        <button onClick={() => setcollapsed(!collapsed)} className="focus:outline-none py-2 px-4">
          <FontAwesomeIcon icon={collapsed ? faPlusCircle : faMinusCircle} />
        </button>
      </div>
    </animated.div>
  );
};

const CollapsableNav: React.FC = () => {
  const [collapsed, setcollapsed] = useLocalStorage("@factoryfinch/collapsedNav", false);

  const props = useSpring({
    width: collapsed ? 80 : 125,
    overflow: "hidden",
  });
  return (
    <animated.div style={props} className="flex flex-col bg-brand dark:bg-gray-800 items-center justify-between">
      <div className="flex flex-col items-center w-full">
        <div className="cursor-pointer bg-transparent text-white dark:text-gray-300 h-20 items-center  flex flex-col justify-center">
          <OnHoverFlapWink />
        </div>
        {NavItems.map((item) => {
          return (
            <NavLink
              data-testid={item?.testId}
              key={item?.route}
              to={item.route}
              className={cx(
                "transition-colors duration-200 ease-in-out w-full text-white dark:text-gray-300 h-20 items-center text-3xl flex flex-col justify-center",
                item?.className
              )}
              activeClassName="bg-gray-100 dark:bg-gray-700 text-red-400 dark:text-red-400"
              title={item.label}
            >
              <FontAwesomeIcon icon={item.icon} />
              {collapsed ? null : <div className="text-sm my-1">{item.label}</div>}
            </NavLink>
          );
        })}
      </div>
      <div className="flex justify-start">
        <button
          onClick={() => setcollapsed(!collapsed)}
          className="focus:outline-none py-2 px-4 text-white dark:text-gray-200"
        >
          <FontAwesomeIcon icon={collapsed ? faPlusCircle : faMinusCircle} />
        </button>
      </div>
    </animated.div>
  );
};

type SubNavRoute = {
  label: string;
  route: Routes;
  testId?: string;
  icon?: IconDefinition;
};

interface SubNavItem {
  route: Routes;

  routes: SubNavRoute[];
}

const SubNavs: SubNavItem[] = [
  {
    route: Routes.BirdsEyeView,

    routes: [
      // {
      //   testId: "left-subnavlink-AnalyticsManufacturing",
      //   label: "Production",
      //   route: Routes.AnalyticsManufacturing,
      //   icon: faIndustry,
      // },
      {
        testId: "left-subnavlink-AnalyticsReports",
        label: "Reports",
        route: Routes.AnalyticsReports,
        icon: faFileExport,
      },
    ],
  },
  {
    route: Routes.Production,

    routes: [
      {
        testId: "left-subnavlink-WorkOrders",
        label: "Work Orders",
        route: Routes.WorkOrders,
        icon: faFileInvoice,
      },
      {
        testId: "left-subnavlink-Inventory",
        label: "Inventory",
        route: Routes.Inventory,
        icon: faBoxes,
      },
      {
        testId: "left-subnavlink-Workflows",
        label: "Workflows",
        route: Routes.Workflows,
        icon: faProjectDiagram,
      },
      {
        testId: "left-subnavlink-Steps",
        label: "Steps",
        route: Routes.Steps,
        icon: faShoePrints,
      },
      {
        testId: "left-subnavlink-Locations",
        label: "Locations",
        route: Routes.Locations,
        icon: faLocationArrow,
      },
      {
        testId: "left-subnavlink-InventoryTransfers",
        label: "Transfer Inventory",
        route: Routes.InventoryTransfers,
        icon: faExchangeAlt,
      },
    ],
  },
  {
    route: Routes.Team,

    routes: [
      {
        testId: "left-subnavlink-Employees",
        label: "Operators",
        route: Routes.Employees,
        icon: faUsers,
      },
      {
        testId: "left-subnavlink-Admins",
        label: "Admins",
        route: Routes.Admins,
        icon: faUserShield,
      },
    ],
  },
  // {
  //   route: Routes.Purchasing,

  //   routes: [
  //     {
  //       testId: "left-subnavlink-PurchaseRequests",
  //       label: "Purchase Requests",
  //       route: Routes.PurchaseRequests,
  //       icon: faTasks,
  //     },
  //     {
  //       testId: "left-subnavlink-PurchaseOrders",
  //       label: "Purchase Orders",
  //       route: Routes.PurchaseOrders,
  //       icon: faFileInvoiceDollar,
  //     },
  //     {
  //       testId: "left-subnavlink-PurchasingInventory",
  //       label: "Inventory",
  //       route: Routes.PurchasingInventory,
  //       icon: faBoxes,
  //     },
  //     {
  //       testId: "left-subnavlink-Vendors",
  //       label: "Vendors",
  //       route: Routes.Vendors,
  //       icon: faStore,
  //     },
  //     {
  //       testId: "left-subnavlink-Locations",
  //       label: "Locations",
  //       route: Routes.PurchasingLocations,
  //       icon: faLocationArrow,
  //     },
  //   ],
  // },
  {
    route: Routes.Fulfillment,

    routes: [
      {
        testId: "left-subnavlink-CustomerOrders",
        label: "Sales Orders",
        route: Routes.CustomerOrders,
        icon: faFileInvoice,
      },
      // {
      //   testId: "left-subnavlink-SalesOrderInvoices",
      //   label: "Invoices",
      //   route: Routes.SalesOrderInvoices,
      //   icon: faFileInvoiceDollar,
      // },
      // {
      //   testId: "left-subnavlink-Customers",
      //   label: "Customers",
      //   route: Routes.Customers,
      //   icon: faUserCheck,
      // },
    ],
  },

  {
    route: Routes.Settings,

    routes: [
      {
        testId: "left-subnavlink-MyAccount",
        label: "My Account",
        route: Routes.MyAccount,
        icon: faUserLock,
      },
      {
        testId: "left-subnavlink-Integrations",
        label: "Integrations",
        route: Routes.Integrations,
        icon: faPlug,
      },
      {
        testId: "left-subnavlink-Support",
        label: "Support",
        route: Routes.Support,
        icon: faLifeRing,
      },
    ],
  },
  // {
  //   route: Routes.Support,

  //   routes: [
  //     {
  //       testId: "left-subnavlink-GettingStarted",
  //       label: "Getting Started",
  //       route: Routes.GettingStarted,
  //       icon: faGlasses,
  //     },
  //     {
  //       testId: "left-subnavlink-Support",
  //       label: "Support",
  //       route: Routes.Support,
  //       icon: faLifeRing,
  //     },
  //   ],
  // },
];

interface NavItem {
  icon: IconDefinition;
  route: Routes;
  label: string;
  testId?: string;
  className?: string;
}

const NavItems: NavItem[] = [
  {
    icon: faWrench,
    route: Routes.Production,
    testId: Routes.Production,
    label: "Production",
    className: "joyride-production-navitem",
  },
  {
    icon: faBox,
    route: Routes.Fulfillment,
    testId: Routes.Fulfillment,
    label: "Fulfillment",
    className: "joyride-fulfillment-navitem",
  },
  // {
  //   icon: faCreditCard,
  //   route: Routes.Purchasing,
  //   testId: Routes.Purchasing,
  //   label: "Purchasing",
  //   className: "joyride-purchasing-navitem",
  // },
  {
    icon: faChartLine,
    route: Routes.BirdsEyeView,
    testId: Routes.BirdsEyeView,
    label: "Reports",
    className: "joyride-analytics-navitem",
  },
  {
    icon: faUserFriends,
    route: Routes.Team,
    testId: Routes.Team,
    label: "Team",
    className: "joyride-team-navitem",
  },
];

const LeftNav = () => {
  return (
    <>
      <CollapsableNav />

      {SubNavs.map((subNav, i) => {
        return <Route key={`pg-${i}`} path={subNav.route} render={() => <CollapsableSubNav subNav={subNav} />} />;
      })}
    </>
  );
};

export { LeftNav as default };
