import React, { useEffect, useState, useCallback } from "react";
import { ScrollableForm } from "components/Form/ScrollableForm";
import { Confirmation } from "components/Modals/Confirmation";
import {
  GetInventoryTransferDocument,
  InventoryTransferStatus,
  useGetInventoryTransferLazyQuery,
  useUpdateInventoryTransferMutation,
} from "generated/graphql";
import { useToast } from "hooks/toast";
import { useForm } from "react-hook-form";
import { H4, HR, P, RedBadge } from "styles";
import { notEmpty } from "utils/filters";
import { useTable } from "./context";
import {
  InventoryTransferForm,
  IInventoryTransferFormData,
} from "components/Views/InventoryTransfer/InventoryTransfers/Form";
import { LoadingAnimationWithLabel } from "components/Loading";
import { usePrompt } from "hooks/prompt";

const Edit = () => {
  const toast = useToast();

  const [confirmCancel, setconfirmCancel] = useState(false);
  const { isEditing, setIsEditing } = useTable();
  const [isConfirming, setIsConfirming] = useState<IInventoryTransferFormData>(null);
  const form = useForm<IInventoryTransferFormData>();
  usePrompt(form?.formState?.isDirty ?? false);
  const [fetch, { data, loading, variables }] = useGetInventoryTransferLazyQuery();
  const [update] = useUpdateInventoryTransferMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GetInventoryTransferDocument, variables }],
  });
  const inventoryTransfer = data?.getInventoryTransfer;
  useEffect(() => {
    if (notEmpty(isEditing)) {
      fetch({ variables: { id: isEditing?.id } });
    }
  }, [fetch, isEditing]);

  const submit = async (formData: IInventoryTransferFormData) => {
    setIsConfirming(null);
    if (formData?.lineItems?.length === 0) {
      toast.error("Inventory Transfer must have at least 1 item");
      return;
    }

    try {
      await update({
        variables: {
          input: {
            id: inventoryTransfer?.id,
            status: formData.status,
            lineItems:
              formData?.lineItems?.map((li) => ({
                id: li?.id ?? null,
                quantity: li?.quantity ?? 0,
                inventoryId: li?.inventoryItem?.id,
              })) ?? [],
          },
        },
      });
      form.reset();
      setIsEditing(null);
    } catch (err) {
      toast.error(err?.message ?? "Unable to update inventory transfer");
    }
  };

  const onSubmit = (formData: IInventoryTransferFormData) => {
    if (formData.status === InventoryTransferStatus.Received) {
      setIsConfirming(formData);
      return;
    }

    return submit(formData);
  };

  const onConfirmCancel = useCallback(() => {
    setconfirmCancel(false);
    setIsEditing(null);
  }, [setIsEditing]);

  const onCancel = useCallback(() => {
    if (form.formState.isDirty) {
      setconfirmCancel(true);
      return;
    }

    setIsEditing(null);
  }, [form.formState.isDirty, setIsEditing]);

  useEffect(() => {
    if (notEmpty(inventoryTransfer)) {
      form.reset(inventoryTransfer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.reset, inventoryTransfer]);

  if (loading) {
    return (
      <div className="mt-20">
        <LoadingAnimationWithLabel message={"Fetching Inventory Transfer"} />
      </div>
    );
  }

  return (
    <>
      <ScrollableForm
        onSubmit={form.handleSubmit(onSubmit)}
        onCancel={onCancel}
        submitLabel="Save"
        formState={form.formState}
        classNames={{ buttonContainer: "w-full lg:w-1/3" }}
      >
        <div className="flex items-center">
          <H4 className="px-2 py-2">Edit Inventory Transfer</H4>
          <span>{inventoryTransfer?.deletedAt && <RedBadge>Deleted</RedBadge>}</span>
        </div>
        <HR />

        <InventoryTransferForm form={form} disableLocations />
      </ScrollableForm>
      <Confirmation
        isOpen={notEmpty(isConfirming)}
        onRequestClose={() => setIsConfirming(null)}
        onConfirm={form.handleSubmit(submit)}
      >
        <div className="my-4 flex flex-col justify-center items-center">
          <H4 className="py-4 text-center">Are you sure you would like to save?</H4>
          <P className="py-2 text-gray-700 dark:text-gray-300">This action cannot be undone</P>
          <HR />
        </div>
      </Confirmation>

      <Confirmation
        isOpen={confirmCancel}
        cancelButtonLabel="Go back"
        confirmButtonLabel="I'm sure"
        onRequestClose={() => setconfirmCancel(false)}
        onConfirm={onConfirmCancel}
      >
        <div className="my-4 w-full">
          <H4 className="py-4 flex flex-row justify-center text-default dark:text-white">You have unsaved changes.</H4>
          <P className="text-center pb-4">Are you sure you'd like to cancel?</P>
          <HR />
        </div>
      </Confirmation>
    </>
  );
};

export { Edit as default };
