import React from "react";
import { Select } from "styles";
import { InventoryTransferStatus } from "generated/graphql";

export const getInventoryTransferLabel = (status: InventoryTransferStatus) => {
  switch (status) {
    case InventoryTransferStatus.InTransit:
      return "In Transit";
    case InventoryTransferStatus.Pending:
      return "Pending";
    case InventoryTransferStatus.Draft:
      return "Draft";
    case InventoryTransferStatus.Received:
      return "Received";
    case InventoryTransferStatus.Cancelled:
      return "Cancelled";
    default:
      return status;
  }
};

const options = [
  InventoryTransferStatus.Draft,
  InventoryTransferStatus.Pending,
  InventoryTransferStatus.InTransit,
  InventoryTransferStatus.Received,
  InventoryTransferStatus.Cancelled,
];

export const SelectInventoryTransferStatuses: React.FC<{
  value: InventoryTransferStatus[];
  onChange: (opt: InventoryTransferStatus) => void;
  placeholder?: string;
  isDisabled?: boolean;
}> = ({ value, onChange, isDisabled = false, placeholder = "Filter by Status..." }) => {
  return (
    <Select
      disabled={isDisabled}
      placeholder={placeholder}
      isClearable
      value={value}
      isMulti
      options={options}
      onChange={onChange}
      getOptionValue={(opt: InventoryTransferStatus) => opt}
      getOptionLabel={(opt: InventoryTransferStatus) => getInventoryTransferLabel(opt)}
    />
  );
};

export const SelectInventoryTransferStatus: React.FC<{
  value: InventoryTransferStatus;
  onChange: (opt: InventoryTransferStatus) => void;
  placeholder?: string;
  isDisabled?: boolean;
}> = ({ value, onChange, isDisabled = false, placeholder = "Select Status..." }) => {
  return (
    <Select
      placeholder={placeholder}
      isClearable
      isDisabled={isDisabled}
      value={{ label: getInventoryTransferLabel(value), value }}
      options={options?.map((opt) => ({ label: getInventoryTransferLabel(opt), value: opt }))}
      onChange={(opt: { label: string; value: InventoryTransferStatus }) => onChange(opt?.value)}
    />
  );
};
