import { CountryOption, RegionOption, SelectCountry, SelectRegion } from "components/Form/CountryAndRegion";
import { PhoneInput } from "components/Form/PhoneInput";
import { ScrollableForm } from "components/Form/ScrollableForm";
import TextAreaInput from "components/Form/TextAreaInput";
import TextInput from "components/Form/TextInput";
import { ListVendorsDocument, useUpdateVendorMutation, Vendor } from "generated/graphql";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { SubHeading } from "styles";
import { useTable } from "./context";

export interface IEditVendorForm {
  id: string;
  name: string;
  address1: string;
  address2: string;
  city: string;
  region: RegionOption;
  country: CountryOption;
  postalCode: string;
  companyPhone: string;
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  contactPhoneOffice: string;
  contactPhoneMobile: string;
  notes: string;
}

export const EditVendorForm: React.FC<{
  vendor: Vendor;
  onSubmit: (data: IEditVendorForm) => Promise<void>;
  onCancel: () => void;
}> = ({ vendor, onSubmit, onCancel }) => {
  const { handleSubmit, register, formState, control, setValue, watch } = useForm<IEditVendorForm>({
    defaultValues: {
      id: vendor?.id,
      name: vendor?.name,
      companyPhone: vendor?.companyPhone,
      address1: vendor?.address1,
      address2: vendor?.address2,
      city: vendor?.city,
      region: {
        name: vendor?.state,
      },
      country: { countryName: vendor?.country },
      postalCode: vendor?.postalCode,
      contactFirstName: vendor?.contactFirstName,
      contactLastName: vendor?.contactLastName,
      contactEmail: vendor?.contactEmail,
      contactPhoneOffice: vendor?.contactPhoneOffice,
      contactPhoneMobile: vendor?.contactPhoneMobile,
      notes: vendor?.notes,
    },
  });
  const country = watch("country");

  return (
    <ScrollableForm
      title="Edit Vendor"
      onSubmit={handleSubmit(onSubmit)}
      onCancel={onCancel}
      formState={formState}
      submitLabel="Update"
    >
      <TextInput type="hidden" {...register("id")} />
      <SubHeading>Company</SubHeading>
      <TextInput {...register("name")} placeholder="Factory Finch" label="Company Name*" />

      <Controller
        control={control}
        name="companyPhone"
        render={({ field }) => <PhoneInput label="Company Phone" value={field.value} onChange={field.onChange} />}
      />

      <TextInput {...register("address1")} placeholder="123 Fake St." label="Address" />

      <TextInput {...register("address2")} placeholder="Unit A" label="Address 2" />

      <TextInput {...register("city")} placeholder="Austin" label="City" />

      <Controller
        control={control}
        name="country"
        render={({ field }) => {
          return (
            <SelectCountry
              label="Country"
              onChange={(v) => {
                setValue("region", null);
                field.onChange(v);
              }}
              value={field.value}
            />
          );
        }}
      />

      <Controller
        control={control}
        name="region"
        render={({ field }) => {
          return (
            <SelectRegion label="Region" country={country} onChange={(v) => field.onChange(v)} value={field.value} />
          );
        }}
      />

      <TextInput {...register("postalCode")} label="ZIP/Postal code" placeholder="78702" />

      <SubHeading className="pt-3">Company Contact</SubHeading>
      <div className="grid grid-cols-2 gap-x-2 gap-y-2 py-2">
        <TextInput {...register("contactFirstName")} label="First Name" placeholder="Alex" />

        <TextInput {...register("contactLastName")} label="Last Name" placeholder="Smith" />
      </div>

      <TextInput {...register("contactEmail")} placeholder="alex@factoryfinch.com" label="Email" />

      <Controller
        control={control}
        name="contactPhoneOffice"
        render={({ field }) => <PhoneInput label="Office Phone" value={field.value} onChange={field.onChange} />}
      />

      <Controller
        control={control}
        name="contactPhoneMobile"
        render={({ field }) => <PhoneInput label="Mobile Phone" value={field.value} onChange={field.onChange} />}
      />

      <TextAreaInput rows={6} label="Notes" placeholder="Anything else...." {...register("notes")} />
    </ScrollableForm>
  );
};

const EditVendor = () => {
  const { isEditing, setIsEditing } = useTable();
  const [updateVendor] = useUpdateVendorMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ListVendorsDocument, variables: {} }],
  });

  const onRequestClose = () => setIsEditing(null);

  const onSubmit = async (input: IEditVendorForm) => {
    try {
      await updateVendor({
        variables: {
          input: {
            id: input.id,
            name: input.name,
            companyPhone: input.companyPhone,
            address1: input.address1,
            address2: input.address2,
            city: input.city,
            state: input?.region?.name,
            country: input?.country?.countryName,
            postalCode: input.postalCode,
            contactFirstName: input.contactFirstName,
            contactLastName: input.contactLastName,
            contactEmail: input.contactEmail,
            contactPhoneOffice: input.contactPhoneOffice,
            contactPhoneMobile: input.contactPhoneMobile,
            notes: input.notes,
          },
        },
      });
      onRequestClose();
    } catch (err) {
      console.log(err);
    }
  };

  return <EditVendorForm vendor={isEditing} onSubmit={onSubmit} onCancel={onRequestClose} />;
};

export { EditVendor as default };
