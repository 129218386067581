import React from "react";
import { ParseResult } from "papaparse";
import { useAdjustInventoryCountMutation, AdjustInventoryCountInputItem, Location } from "generated/graphql";
import { chunk } from "lodash";
import { UpdateFromCSV, ITemplateRow, IImportFrom } from "./index";
import { H4 } from "styles";
interface AdjustInventoryTemplateRow extends ITemplateRow {
  sku: string;
  onHand: string;
}

export const UpdateInventoryFromCSV: React.FC<IImportFrom> = ({ isOpen, onRequestClose }) => {
  const [adjustInventoryCount] = useAdjustInventoryCountMutation();

  const update = async (
    parsed: ParseResult<AdjustInventoryTemplateRow> & { file: File },
    tags: Record<string, string>,
    location: Location,
    onProgress: (progress: number) => void
  ) => {
    const { data: rows } = parsed;

    const adjustments: AdjustInventoryCountInputItem[] = rows.map((row) => {
      return {
        onHand: row?.onHand ? parseFloat(row?.onHand) : 0,
        sku: row?.sku,
      };
    });
    const chunks = chunk(adjustments, 100);
    const requestchunks = chunk(chunks, 1);
    for (let i = 0; i < requestchunks.length; i++) {
      const requests = requestchunks[i];
      const waitFor = requests.map((adj) =>
        adjustInventoryCount({
          variables: {
            input: {
              locationId: location.id,
              items: adj,
            },
          },
        })
      );
      await Promise.all(waitFor);
      onProgress(Math.floor(((i + 1) / requestchunks.length) * 100));
    }
  };

  return (
    <UpdateFromCSV
      type="inventory items"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      templateURL="/assets/inventory-adjust-template.csv"
      importFn={update}
    >
      <H4>Adjust Inventory from CSV</H4>
      <p className="text-sm my-2">Adjust your inventory at the selected location using a CSV file.</p>
    </UpdateFromCSV>
  );
};
