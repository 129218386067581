import { faInfoCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import { AddButton } from "components/Buttons";
import CurrencyInput from "components/Form/CurrencyInput";
import { isValidInventoryItemType, toInventoryItemType } from "components/Form/helpers";
import { InventoryItemTypeInput, InventoryItemTypeInputValue } from "components/Form/InventoryItemTypeInput";
import { ITagInputValue, MultiTagInputWSuggest } from "components/Form/TagInput";
import TextInput from "components/Form/TextInput";
import { SelectInventoryItemModalInput } from "components/Select/InventoryItem";
import { getInventoryItemTypeLabel } from "components/Select/InventoryItemType";
import { SelectVendor } from "components/Select/Vendor";
import SelectVendorProduct from "components/Select/VendorProduct";
import { SelectWorkflow } from "components/Select/Workflow";
import { IconWithTooltip } from "components/Tooltip";
import { InventoryItemType, ResourceTag, Vendor, VendorProduct, Workflow, InventoryItem } from "generated/graphql";
import React, { useCallback, useEffect, useMemo } from "react";
import { Controller, useFieldArray, UseFormReturn } from "react-hook-form";
import { IconButton, SubHeading } from "styles";

interface IIngredient {
  inventoryItem: Pick<InventoryItem, "id" | "name">;
}

interface IVendorProductField {
  vendor: Vendor;
  product: VendorProduct;
}

export interface IBulkInventoryItemFormData {
  units: string;
  type: InventoryItemTypeInputValue;
  valuePerUnit: number;
  defaultWorkflow: Pick<Workflow, "id" | "name">;
  tags: ResourceTag[];
  defaultVendorProduct: IVendorProductField;
  ingredients: IIngredient[];
}

export const BulkInventoryItemForm: React.FC<{
  form: UseFormReturn<IBulkInventoryItemFormData>;
  onRemoveTag?: (val: ITagInputValue, i?: number) => void;
}> = ({ form, onRemoveTag }) => {
  const { control, watch, register, formState, clearErrors } = form;
  const ingredientsFieldArray = useFieldArray({
    control,
    name: "ingredients",
  });

  const values = watch();
  const type = toInventoryItemType(values?.type);
  const shouldSelectWorkflow = useMemo(
    () => [InventoryItemType.IntermediateGood, InventoryItemType.FinishedGood].includes(type),
    [type]
  );
  const shouldSelectVendorProduct = useMemo(() => [InventoryItemType.RawMaterial].includes(type), [type]);
  const shouldShowIngredients = useMemo(
    () => [InventoryItemType.IntermediateGood, InventoryItemType.FinishedGood].includes(type),
    [type]
  );

  const validateType = useCallback(() => {
    return (
      [
        values?.type?.isPurchased,
        values?.type?.isUsedToMakeSomethingElse,
        values?.type?.isSold,
        values?.type?.isMade,
      ].every((v) => v === false) ||
      isValidInventoryItemType(values.type) ||
      "Invalid combination"
    );
  }, [values]);

  useEffect(() => {
    if (!shouldSelectWorkflow) {
      form.setValue("defaultWorkflow", null);
    }
    if (!shouldSelectVendorProduct) {
      form.setValue("defaultVendorProduct", null);
    }
    if (!shouldShowIngredients) {
      form.setValue("ingredients", []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldSelectWorkflow, shouldSelectVendorProduct, shouldShowIngredients]);

  useEffect(() => {
    clearErrors("type");
  }, [clearErrors, values?.type]);

  return (
    <>
      <SubHeading>Units</SubHeading>
      <TextInput
        data-testid="inventory-item-units"
        placeholder="Units"
        {...register("units")}
        className={cx("my-2", formState?.errors?.units && "error")}
      />

      <SubHeading>Value per unit</SubHeading>
      <Controller
        name="valuePerUnit"
        control={control}
        render={({ field }) => (
          <CurrencyInput
            data-testid="inventory-item-value-per-unit"
            value={field.value}
            onValueChange={field.onChange}
            placeholder="0"
          />
        )}
      />

      <div className="flex items-center">
        <SubHeading>Type*</SubHeading>
        <div className="ml-2 text-xs text-gray-400">{getInventoryItemTypeLabel(type)}</div>
      </div>
      <div className="text-sm text-brand">
        {formState?.errors?.type && "Invalid type - try a different combination"}
      </div>

      <div className={cx(formState?.errors?.type && "border border-brand")}>
        <Controller
          control={control}
          name="type"
          rules={{
            validate: validateType,
          }}
          render={({ field }) => <InventoryItemTypeInput value={field.value} onChange={field.onChange} />}
        />
      </div>

      {shouldSelectVendorProduct && (
        <>
          <SubHeading>Default Vendor Product</SubHeading>

          <Controller
            name="defaultVendorProduct"
            control={control}
            render={({ field }) => (
              <div className="grid grid-cols-2 gap-x-2">
                <SelectVendor
                  value={values?.defaultVendorProduct?.vendor}
                  onChange={(vendor) => {
                    field.onChange({
                      vendor,
                      product: null,
                    });
                  }}
                  className={formState?.errors?.defaultVendorProduct ? "border border-brand rounded" : ""}
                />
                <SelectVendorProduct
                  placeholder="Select Product..."
                  isDisabled={!values?.defaultVendorProduct?.vendor?.id}
                  value={values?.defaultVendorProduct?.product}
                  onChange={(product) => {
                    field.onChange({
                      vendor: values?.defaultVendorProduct?.vendor,
                      product,
                    });
                  }}
                  defaultFilters={{
                    vendorId: values?.defaultVendorProduct?.vendor?.id,
                  }}
                  className={formState?.errors?.defaultVendorProduct ? "border border-brand rounded" : ""}
                />
              </div>
            )}
          />
        </>
      )}

      {shouldSelectWorkflow && (
        <div className="my-2">
          <SubHeading className="my-2">Default Workflow</SubHeading>
          <Controller
            name="defaultWorkflow"
            control={control}
            render={({ field }) => {
              return <SelectWorkflow value={field.value} onChange={field.onChange} />;
            }}
          />
        </div>
      )}

      {shouldShowIngredients && (
        <>
          <SubHeading>
            Ingredients
            <AddButton onClick={() => ingredientsFieldArray.append({ inventoryItem: null })} />
          </SubHeading>

          {ingredientsFieldArray.fields.map((ingField, index) => {
            return (
              <div className="flex flex-row my-1" key={ingField.id}>
                <Controller
                  name={`ingredients.${index}.inventoryItem` as const}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <SelectInventoryItemModalInput
                      value={field.value}
                      onChange={field.onChange}
                      classNames={{ input: "border-gray-300 dark:border-gray-400 rounded" }}
                    />
                  )}
                />
                <div>
                  <IconButton type="button" className="p-0" onClick={() => ingredientsFieldArray.remove(index)}>
                    <FontAwesomeIcon icon={faTimes} />
                  </IconButton>
                </div>
              </div>
            );
          })}
          {/* <div className="col-span-3 flex justify-end">
            <button
              type="button"
              className="text-blue-500 focus:outline-none"
              onClick={() => ingredientsFieldArray.append({ inventoryItem: null })}
            >
              Add ingredient...
            </button>
          </div> */}
        </>
      )}
      <SubHeading>
        Tags
        <span className="dark:text-white mx-2">
          <IconWithTooltip title="Will replace all existing tags" icon={faInfoCircle} />
        </span>
      </SubHeading>
      <Controller
        name="tags"
        control={control}
        render={({ field }) => {
          return (
            <MultiTagInputWSuggest
              value={field.value}
              onChange={field.onChange}
              allowCreate
              onRemove={(v, i) => onRemoveTag(v, i)}
            />
          );
        }}
      />
    </>
  );
};
